import React, { useState, useEffect } from 'react';

import { serveImage } from '../../systemConfig/components/labelManagement/label.utils';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentMediaPlan, getCampaign, selectAdGroup, selectCampaign } from '../../../actions/mediaActions';
import { FiCornerDownRight } from "react-icons/fi";

// Const
import { MEDIA_NAV } from '../../../constants/media';

// Styling
import { FaPlus } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { PageNavigationContainer, NavigationBtn, SubNavigationBtn, MultiBtnContainer, PlusNavigationBtn, PlusNavigationBtnContainer, StyleMediaPlanNavigator} from '../../../components/Sidebar/styles';
import { IconDropdownButton } from '../../../ui/button';
import GoogleIcon from '../../../assets/images/socialMediaIcons/GoogleIcon.png';
import FbIcon from '../../../assets/images/socialMediaIcons/FbIcon.png';
import LinkedInIcon from '../../../assets/images/socialMediaIcons/LinkedInIcon.png'

import BackButton from '../../../ui/backBtn';
import { buildMultiTemplateCampaigns } from './utils';
import { shortString } from '../../../utils/strings';
import { useTheme } from 'styled-components';
import ChannelSetupModal from './modal/channelSetupModal/ChannelSetupModal';

const Navigation = ({setIsEdit, currSelector, setCurrSelector, currCampaign, setCurrCampaign, setIsEditMedia}) => {
    const [isChannelSetupOpen, setIsChannelSetupOpen] = useState(false);
    const [showGoogleCampaignsList, setShowGoogleCampaignsList] = useState(true);
    const [showFbCampaignsList, setShowFbCampaignsList] = useState(true);
    const [showLnCampaignsList, setShowLnCampaignsList] = useState(true);
    const [dummyNetworkCampaignsList, setDummyNetworkCampaignsList] = useState({});

    const mediaPlan = useSelector(state => state.media.currentMediaPlan);
    const dummyNetworks = useSelector(state => state.org?.organization?.dummyNetworks);
    const campaigns = mediaPlan?.campaigns

    const dispatch = useDispatch();
    const theme = useTheme()

    useEffect(() => {
        const dummyObj = {}

        for (const dummy in dummyNetworks) {
            dummyObj[dummyNetworks[dummy].name] = true;
        }
        setDummyNetworkCampaignsList(dummyObj);
    }, [])

    const handleSelectCampaign = (campaign) => {
        dispatch(getCampaign({websafe: campaign?.websafe}));
        setCurrCampaign(campaign);
        setCurrSelector(MEDIA_NAV.CAMPAIGN_DETAILS);
        dispatch(selectAdGroup({}));
        setIsEditMedia(false);
    }

    const renderDummyNetworks = () => {
        const dummyNetworksWithCampaigns = dummyNetworks?.filter(dummyNetwork => campaigns?.find(campaign => campaign.network === dummyNetwork.name));

        return (dummyNetworksWithCampaigns || []).map((network) => {
            return (
                <React.Fragment key={network.websafe}>
                <div className='media-channel'>
                    <IconDropdownButton onClick={() => handleDummyStateUpdate(network.name)}>
                        <img src={serveImage(network.logo)} alt='' />
                        {network.name}
                        {dummyNetworkCampaignsList[network.name] ? <IoIosArrowDown /> : <IoIosArrowForward />}
                    </IconDropdownButton>
                </div>
                { dummyNetworkCampaignsList[network.name] && <RenderTemplates network={network.name} /> }
                </React.Fragment>
            )
        })
    }

    const handleDummyStateUpdate = (name) => {
        const dummyObj = {...dummyNetworkCampaignsList};
        dummyObj[name] = !dummyNetworkCampaignsList[name];
        setDummyNetworkCampaignsList(dummyObj);
    }

    const RenderTemplates = ({network}) => {
        if (!campaigns?.length || (network === 'google' && !showGoogleCampaignsList) || (network === 'facebook' && !showFbCampaignsList) || (network === 'linkedin' && !showLnCampaignsList)) return;
        const filteredCampaigns = campaigns?.filter(campaign => campaign.network === network);
        const templateCamapigns = buildMultiTemplateCampaigns(filteredCampaigns);

        return templateCamapigns.map(([templateName, campaigns], idx) => {
            return (
                <StyleMediaPlanNavigator key={idx}>
                    <div className='render-template-wrapper' key={templateName}>
                        <FiCornerDownRight color={theme.card.gray}/>
                        <div className='render-template-name'>{shortString(templateName, 17)}</div>
                    </div>
                    {
                        campaigns.map((campaign, idx) => {
                            return (
                                <SubNavigationBtn
                                    key={campaign.websafe}
                                    onClick={() => handleSelectCampaign(campaign)}
                                    isSelected={campaign.websafe === currCampaign?.websafe}
                                >

                                    Campaign {idx + 1}
                                </SubNavigationBtn>
                            )
                        })
                    }
                </StyleMediaPlanNavigator>
            )
        })
    }

    const handleCurrSelector = (nav) => {
        setCurrSelector(nav);
        setCurrCampaign(null);
    }

    const handleBackToOverview = () => {
        setIsEdit(false);
        setCurrSelector(null);
        setCurrCampaign({});
        dispatch(setCurrentMediaPlan({}))
        dispatch(selectCampaign());
        dispatch(selectAdGroup());
    }

    return (
        <>
        <PageNavigationContainer>
            <BackButton handle={handleBackToOverview}/>
            <NavigationBtn onClick={() => handleCurrSelector(MEDIA_NAV.MEDIA_PLAN_SETUP)} isSelected={currSelector === MEDIA_NAV.MEDIA_PLAN_SETUP}>
                Media Plan Setup
            </NavigationBtn>

            <MultiBtnContainer>
                <NavigationBtn isSelected={currSelector === MEDIA_NAV.CAMPAIGN_DETAILS} onClick={() => console.log(!showGoogleCampaignsList)} >
                    Channel Setup
                </NavigationBtn>

                <div className='media-channel'>
                <IconDropdownButton onClick={() => setShowGoogleCampaignsList(!showGoogleCampaignsList)}>
                    <img src={GoogleIcon} alt='' />
                    Google Search
                    {showGoogleCampaignsList ? <IoIosArrowDown /> : <IoIosArrowForward />}
                </IconDropdownButton>
                </div>
                <RenderTemplates network="google" />
                <div className='media-channel'>
                <IconDropdownButton onClick={() => setShowFbCampaignsList(!showFbCampaignsList)}>
                    <img src={FbIcon} alt='' />
                    Facebook
                    {showFbCampaignsList ? <IoIosArrowDown /> : <IoIosArrowForward />}
                </IconDropdownButton>
                </div>
                <RenderTemplates network="facebook"/>
                    <>
                        <div className='media-channel'>
                            <IconDropdownButton onClick={() => setShowLnCampaignsList(!showLnCampaignsList)}>
                                <img src={LinkedInIcon} alt='' />
                                LinkedIn
                                {showLnCampaignsList ? <IoIosArrowDown /> : <IoIosArrowForward />}
                            </IconDropdownButton>
                        </div>
                        <RenderTemplates network="linkedin" />
                    </>
                {renderDummyNetworks()}

                {mediaPlan?.lob &&
                <PlusNavigationBtnContainer>
                    <PlusNavigationBtn isSelected={currSelector === MEDIA_NAV.CHANNEL_SETUP} onClick={() => setIsChannelSetupOpen(true)}>
                        <FaPlus /> Add Channel
                    </PlusNavigationBtn>
                </PlusNavigationBtnContainer>
                }
            </MultiBtnContainer>
        </PageNavigationContainer>
            {
                isChannelSetupOpen &&
                <ChannelSetupModal
                    onClose={() => setIsChannelSetupOpen(false)}
                />
            }
        </>
    )
}

export default Navigation;