import React, { useEffect, useMemo, useState } from "react";
import DeleteModal from "../../../components/Modal/DeleteModal";

// Hooks
import useFadeAnimation from "../../../hooks/useFadeAnimation";
import useLinkedinCampaign from "../useLinkedinCampaign";

// Redux
import { useSelector } from "react-redux";


// Styles
import Loader from "../../../ui/Loader";
import DropDownField from "../../../ui/input/fields/DropdownField";
import CheckBoxField from "../../../ui/input/fields/CheckboxField";
import { toast } from "react-toastify";
import { Input } from "../../../ui/input/input";
import { SubHeader } from "../../../ui/headers";
import {RequiredStar} from '../../../ui/ReusableComponents'
import { linkedinUpdateController } from "../templates.util";
import { DeleteTextBtn, EditSaveBtn, HeaderIconBtnContainer } from "../../../ui/button";
import { FieldContainer, OneThirdGridColContainer, StyledFieldsWrapper } from "../../../ui/containers";

const LinkedinCampaignDetails = ({ handleUpdate, handleDeleteCampaign }) => {
  const campaign = useSelector((state) => state.template.selectedCampaign);
  const org = useSelector(state => state.org.organization);

  const [loading, setLoading] = useState(false);
  const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [name, setName] = useState("");
  const [audienceType, setAudienceType] = useState(null);
  const [objective, setObjective] = useState(null);
  const [optimization, setOptimization] = useState(null);
  const [bidStrategy, setBidStrategy] = useState(null);
  const [audienceNetwork, setAudienceNetwork] = useState(null)


  const {
    _M,
    disabledProps,
    INITIAL_DESABLE_PROPS,
    selectObjective,
    setDisabledProps,
    selectOptimization,
    showPlacements,
    isLoading
  } = useLinkedinCampaign({campaign})


  const currentState =  {
    name,
    objective: objective?.value || objective,
    optimization: optimization?.value || optimization,
    bidStrategy: bidStrategy?.value || bidStrategy,
    audienceType: audienceType?.value || audienceType,
    audienceNetwork
  }


  useEffect(() => {
    clear()
    setDisabledProps(INITIAL_DESABLE_PROPS)
    handleSelectedObjective(campaign.objective);
    handleSelectedOptimization(campaign.optimization);
    handleBitStrategy(campaign.bidStrategy);
    setAudienceNetwork(campaign.audienceNetwork)
    setAudienceType(campaign.audienceType)
    setName(campaign.name)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[campaign.id, isEdit])

  const mappedAudienceType = useMemo(() => {
    return (org?.audienceTypes || []).map((it) => ({ label: it, value: it }))
  },[org?.audienceTypes]);

  const handleEdit = () => {
    if(isEdit){
      const updateObj = linkedinUpdateController(campaign, currentState);
      if (!objective) return toast.error("Please select an Objective");
      if (objective && !optimization) return toast.error("Please select an Optimization");
      if (objective && optimization && !bidStrategy) return toast.error("Please select a Bid Strategy.");
      if (!audienceType) return toast.error('Please select a Targeting Type')
      let shouldUpdate = Object.keys(updateObj).length;
      if (shouldUpdate) {
        handleUpdate({ ...updateObj, websafe: campaign.websafe });
      } else {
        toast.info("No Changes Made")
      }
    }
    setIsEdit(prev => !prev);
  };

  const handleModalDeleteCampaign = () => {
    handleDeleteCampaign(campaign, () => {
      setIsDeleteCampaignModalOpen(false);
      setLoading(false);
    });
  };
  const handleSelectedObjective = (value) => {
    if (!value) return;
    setObjective(prew => {
      if(prew && prew?.label !== value?.label) {
        setOptimization(null);
        setBidStrategy(null);
        setDisabledProps({ ...INITIAL_DESABLE_PROPS, optimization: false });
      }
      //Insert code here
      selectObjective(value)
      return value
    })
  }
  const handleSelectedOptimization = (value) => {
    if (!value) return;
    setOptimization((prew) => {
      if (prew && prew?.label !== value?.label) {
        setBidStrategy(null);
        setDisabledProps({ ...disabledProps, bidStrategy: false });
      }
      selectOptimization(value);
      return value;
    });
  };
  const handleBitStrategy = (value) => {
    setBidStrategy(value)
  }


  const clear = () => {
    setName('');
    setBidStrategy(null);
    setObjective(null);
    setOptimization(null);
    setAudienceNetwork(null)
    setAudienceType(null)
  };

  const ref = useFadeAnimation([isEdit, campaign]);

  if (isLoading) return <Loader size="55" fullViewHeight/>

  return (
    <StyledFieldsWrapper ref={ref}>
      <SubHeader>
        Campaign Group Details for {campaign?.name}

        <HeaderIconBtnContainer>
          <DeleteTextBtn onClick={() => setIsDeleteCampaignModalOpen(true)} btnText={"Delete Campaign Group"} />
          {EditSaveBtn(isEdit, handleEdit)}
        </HeaderIconBtnContainer>

      </SubHeader>
      <OneThirdGridColContainer>

        <FieldContainer>
          <h3> Campaign Group Name </h3>
          {isEdit ? <Input value={name} onChange={(e) => setName(e.target.value)} /> : <p> {campaign?.name} </p>}
        </FieldContainer>

        <DropDownField
            isEdit={isEdit}
            text={{ header: "Targeting Type", RequiredStar }}
            options={mappedAudienceType}
            value={audienceType}
            localState={setAudienceType}
          />
        <DropDownField
            isEdit={isEdit}
            text={{ header: "Objective", RequiredStar }}
            options={_M.mapedObjectives}
            value={objective}
            localState={handleSelectedObjective}
          />

      </OneThirdGridColContainer>
      <OneThirdGridColContainer>

      <DropDownField
            isEdit={isEdit}
            isDisabled={disabledProps.optimization}
            text={{ header: "Optimization", RequiredStar }}
            options={_M.mapedOptimization}
            value={optimization}
            localState={handleSelectedOptimization}
          />

        <DropDownField
            isEdit={isEdit}
            isDisabled={disabledProps.bidStrategy}
            text={{ header: "Bidding Strategy", RequiredStar }}
            options={_M.mapedBidStrategies}
            value={bidStrategy}
            localState={handleBitStrategy}
          />

      {
        showPlacements &&
        <CheckBoxField
            isEdit={isEdit}
            text={{ header: "Placements", body: "Linkedin Audience Network" }}
            localState={setAudienceNetwork}
            value={audienceNetwork}
        />
      }

      </OneThirdGridColContainer>

      {isDeleteCampaignModalOpen && (
        <DeleteModal
          header="Delete Campaign Group"
          modalText={`Deleting ${campaign?.name} campaign`}
          deleteBtnText="Delete Campaign Group"
          setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
          handleDelete={handleModalDeleteCampaign}
          loading={loading}
        />
      )}
    </StyledFieldsWrapper>
  );
};

export default LinkedinCampaignDetails;
