import styled, {css} from 'styled-components';

export const UploadKeywordsContainer = styled.div`
    border: 2px solid ${props => props.theme.color.green};
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;

    ${({ isNegative }) => isNegative && css`
        border: 2px solid ${props => props.theme.color.red};
    `}

    h1 {
        font-family: 'GothamMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        margin: 10px 0 20px 0;
    }

    h2 {
        font-family: 'GothamLight';
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    p {
        margin-top: 20px;
    }


    @media only screen and (min-width: 768px) {
        min-width: 500px;
    }
`;

export const RadioFilterContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(217, 217, 216, 0.2);
    width: 100%;
    height: 40px;
    margin-top: 20px;
    font-family: 'GothamBook';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-radius: 5px 5px 0 0;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;