import React from 'react';
import { BlackIconBtn } from '../../../ui/button';
import { OverviewSearchContainer } from '../styles';
import { FaPlus } from 'react-icons/fa';
import Search from '../../../ui/search';


const LobListTop = ({handleClick, handleFilter}) => {
    return (
        <OverviewSearchContainer>
            <Search text='Search for a Line of Business' handleFilter={handleFilter} />
            <BlackIconBtn onClick={handleClick}>
                <FaPlus />
                Add New Line of Business
            </BlackIconBtn>
        </OverviewSearchContainer>
    )
}

export default LobListTop;