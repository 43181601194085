import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";
import MediaPlanSetup from "./MediaPlanSetup";
import GoogleCampaignDetails from "./GoogleCampaignDetails";
import FacebookCampaignDetails from "./FacebookCampaignDetails";
import LinkedInCampaignDetails from './linkedin/LinkedInCampaignDetails';
import DummyCampaignDetails from "./DummyCampaignDetails";
import LaunchMediaPlan from "./modal/LaunchMediaPlan";
import DeleteModal from "../../../components/Modal/DeleteModal";
import GoogleSheetsModal from "./modal/GoogleSheetsModal";
import Loader from "../../../ui/Loader";

import { MEDIA_NAV } from "../../../constants/media";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateMediaPlan, deleteMediaPlan, setCurrentMediaPlan } from "../../../actions/mediaActions";
import { getAdvertiser } from "../../../actions/systemConfigActions";

// Helpers
import { toast } from "react-toastify";
import useGoogleSheets from "../useGoogleSheets";

// Styles
import { CreateMediaPlanContainer } from "../styles";
import { PageContainer } from "../../../ui/containers";
import { PageHeaderContainer } from "../../../ui/headers";
import { CenterBtnContainer, RedBtn, BlackBtnLg } from "../../../ui/button";
import CenterElement from "../../../ui/CenterElement";
import { serveImage } from "../../systemConfig/components/labelManagement/label.utils";

const EditMediaPlans = ({ setIsEdit }) => {
  const [isEditMedia, setIsEditMedia] = useState(false);
  const [currCampaign, setCurrCampaign] = useState();
  const [currSelector, setCurrSelector] = useState(MEDIA_NAV.MEDIA_PLAN_SETUP);
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false);
  const [isDeleteMediaPlanModalOpen, setIsDeleteMediaPlanModalOpen] = useState(false);
  const [googleSheetModal, setGoogleSheetModal] = useState(false);
  const [advertiser, setAdvertiser] = useState({});
  const [loader, setLoader] = useState(true)
  const [deleteLoader, setDeleteLoader] = useState(false);

  const { updateMediaPlans } = useGoogleSheets();

  const mediaPlan = useSelector(state => state.media.currentMediaPlan);

  const dispatch = useDispatch();

  useEffect(() => {
    if (mediaPlan?.lob?.advertiser?.websafe) {
      getAdvertiser({
        websafe: mediaPlan.lob.advertiser.websafe,
        callback: (res) => {
          setAdvertiser(res);
          setLoader(false);
        }
      })
    } else {
      setLoader(false);
    }
  }, [mediaPlan]);

  const handleUpdate = (updateObj) => {
    if (Object.keys(updateObj).length < 1) return;

    dispatch(
      updateMediaPlan({
        websafe: mediaPlan.websafe,
        updateObj,
        callback: () => {
          toast.success(`${mediaPlan.name} Media Plan updated`);
        },
      })
    );
  };

  const handleExportGoogleSheets = async () => {
    if (mediaPlan.sheetsUrl) {
      setIsLaunchModalOpen(false);
      setGoogleSheetModal(true);
      return;
    }
    try {
      await updateMediaPlans(mediaPlan);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const resetPage = (type) => {
    if (type === "media") {
      setIsEdit(false);
      setCurrSelector(null);
      setCurrCampaign({});
      dispatch(setCurrentMediaPlan({}));
    }

    if (type === "campaign") setCurrSelector(MEDIA_NAV.MEDIA_PLAN_SETUP);
  };

  const renderSelectedComponent = () => {
    switch (currSelector) {
      case MEDIA_NAV.MEDIA_PLAN_SETUP:
        return (
          <MediaPlanSetup handleUpdate={handleUpdate} resetPage={resetPage} isEdit={isEditMedia} setIsEdit={setIsEditMedia} />
        );
      case MEDIA_NAV.CAMPAIGN_DETAILS:
        if (currCampaign?.network === 'google') {
          return (
            <GoogleCampaignDetails
              mediaPlan={mediaPlan}
              websafe={currCampaign.websafe}
              resetPage={resetPage}
              isEdit={isEditMedia}
              setIsEdit={setIsEditMedia}
          />
          )
        }

        if (currCampaign?.network === 'facebook') {
          return (
            <FacebookCampaignDetails
              mediaPlan={mediaPlan}
              websafe={currCampaign.websafe}
              resetPage={resetPage}
              isEdit={isEditMedia}
              setIsEdit={setIsEditMedia}
              currCampaign={currCampaign}
            />
          )
        }

        if (currCampaign?.network === 'linkedin') {
          return (
            <LinkedInCampaignDetails
              mediaPlan={mediaPlan}
              websafe={currCampaign.websafe}
              resetPage={() => setCurrSelector(MEDIA_NAV.MEDIA_PLAN_SETUP)}
              isEdit={isEditMedia}
              setIsEdit={setIsEditMedia}
              currCampaign={currCampaign}
            />
          )
        }

        return (
          <DummyCampaignDetails
              mediaPlan={mediaPlan}
              websafe={currCampaign.websafe}
              resetPage={resetPage}
              isEdit={isEditMedia}
              setIsEdit={setIsEditMedia}
            />
        )
      default:
        console.error("No selection made");
        break;
    }
  };

  const handleDeleteMediaPlan = () => {
    setDeleteLoader(true);

    dispatch(
      deleteMediaPlan({
        websafe: mediaPlan?.websafe,
        callback: (res) => {
          toast.success("Deleted Media Plan");
          setDeleteLoader(false);
          resetPage("media");
        },
      })
    );
  };

  if(loader) return <CenterElement height="100vh" width="80vw"><Loader size={55}/></CenterElement>

  return (
    <CreateMediaPlanContainer>
      <Navigation
        setIsEdit={setIsEdit}
        currSelector={currSelector}
        setCurrSelector={setCurrSelector}
        mediaPlan={mediaPlan}
        currCampaign={currCampaign}
        setCurrCampaign={setCurrCampaign}
        setIsEditMedia={setIsEditMedia}
        resetPage={resetPage}
      />
      <PageContainer>
        <PageHeaderContainer>
          <div>
            <h1>
              {isEditMedia && "Edit "} {mediaPlan?.name}{" "}
            </h1>
            <div>
              <h2> <span>LOB</span>: {mediaPlan?.lob?.name || 'Deleted'} </h2>
              {Object.keys(advertiser).length > 0 &&
              <h2> <span>Advertiser</span>: {advertiser?.name} {advertiser?.logo && <img src={serveImage(advertiser?.logo)} style={{width: '14px'}}/>} </h2>
              }
            </div>
          </div>
          <CenterBtnContainer>
            <RedBtn onClick={() => setIsDeleteMediaPlanModalOpen(true)}> Delete Media Plan </RedBtn>
            {mediaPlan?.lob && <BlackBtnLg onClick={() => setIsLaunchModalOpen(true)}> Share / Launch Media Plan </BlackBtnLg>}
          </CenterBtnContainer>
        </PageHeaderContainer>
        {renderSelectedComponent()}
      </PageContainer>
      {isDeleteMediaPlanModalOpen && (
        <DeleteModal
          header="Delete Media Plan"
          modalText={`Deleting ${mediaPlan?.name} Media Plan`}
          deleteBtnText="Delete Media"
          setIsDeleteModalOpen={() => setIsDeleteMediaPlanModalOpen(false)}
          handleDelete={handleDeleteMediaPlan}
          loading={deleteLoader}
        />
      )}
      {googleSheetModal && <GoogleSheetsModal onClose={setGoogleSheetModal.bind(null, false)} mediaPlan={mediaPlan} />}

      {isLaunchModalOpen && (
        <LaunchMediaPlan
          onClose={() => setIsLaunchModalOpen(false)}
          mediaPlan={mediaPlan}
          handleExportGoogleSheets={handleExportGoogleSheets}
        />
      )}
    </CreateMediaPlanContainer>
  );
};

export default EditMediaPlans;
