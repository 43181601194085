import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import CurrencyInput from '../../../../ui/input/fields/CurrencyInput'

const CpcBidLimit = ({ googleIcon, isEdit, cpcBidLimit, setCpcBidLimit, currency, showRequired }) => {
    const customStyles = {
        display: 'flex',
        gap: '8px',
        alignItems: 'center'
    }

    return (
        <FieldContainer>
            <h3>{googleIcon} Maximum CPC Bid Limit <span className='asterisk'>*</span></h3>
            {
                isEdit ? <div style={{ ...customStyles }}>
                    <CurrencyInput
                        value={cpcBidLimit}
                        onChange={(e) => setCpcBidLimit(e.target.value)}
                        currency={currency}
                    />
                </div> : <p>{currency} {cpcBidLimit}</p>
            }
        </FieldContainer>
    )
}

export default CpcBidLimit