/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useRef } from 'react'
import gsap from "gsap";

const useFadeAnimation = (dependency=[]) => {
    const ref = useRef()
    useLayoutEffect(() => {
        gsap.timeline().fromTo(ref?.current, { opacity: 0 }, { opacity: 1, ease: "linear" });
        gsap.config({ nullTargetWarn: false });
      }, dependency );


    return ref
}
export default useFadeAnimation