import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const normalize = (string) => {
    if (!string) return;
    const word = string?.toLowerCase()?.split('_')?.join(' ');
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
}

const BudgetSchedule = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { budgetSchedule, budgetDuration } = campaignObj;

    const [budgetOptions, setBudgetOptions] = useState([]);

    useEffect(() => {
        window.eulerity.makeApiCall('/api/linkedin/budgetSchedules', 'GET', null, (res) => {
            const optionsList = res.map(option => {
                let sentence = normalize(option);

                return ({
                    label: sentence,
                    value: option
                })
            })

            const filterOptions = optionsList.filter(option => !option.label.includes('budget'));

            setBudgetOptions(filterOptions);
        })
    }, [])

    const defaultValue = {label: normalize(budgetSchedule), value: budgetSchedule};

    if (budgetDuration !== 'DAILY') return;

    return (
        <FieldContainer>
            <h3> Budget Schedule <span className='asterisk'>*</span> </h3>
            {
                isEdit ?
                <Select
                    isClearable
                    options={budgetOptions}
                    defaultValue={defaultValue}
                    styles={selectTheme}
                    onChange={e => handleUpdate({key: 'budgetSchedule', value: e.value})}
                /> :
                <p> {defaultValue?.label} </p>
            }
        </FieldContainer>
    )
}

export default BudgetSchedule;