import React, { useContext, useEffect, useMemo } from 'react'
import Modal from '../../../../../../../components/Modal/modals/Modal'
import { AttributeSearchWrapper, ResultItem, SearchResults, StyledAttributeWrapper } from './components/styles'
import { BlackBtnLg, OutlineBtnLg } from '../../../../../../../ui/button'
import Select from 'react-select'
import { selectTheme } from '../../../../../../../ui/themes'
import { useState } from 'react'
import SearchInput from '../../../../../../../ui/input/textinputs/SearchInput'
import { LinkedInAudienceContext } from './LinkedAudienceModal'
import { StyledInputWrapper } from './styles'
import useLinkedInTargets from '../../hooks/useLinkedInTargets'
import { ENTITY_FINDERS, linkedinCategoryOptions } from './linkedInTargets.utils'
import useDelayedInputCallback from '../../../../../../../hooks/useDelayedInput'
import { LinkedInTargetContext } from './LinkedInAttributesStep'

const LinkedInAttributeModal = () => {
    const { linkedInTargetFacets, category, subCategory, isAddMore, groupIndex, handleOnClose, handleOnSubmit } = useContext(LinkedInTargetContext)

    // * Category States
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null)
    const [localSelectedAttributes, setLocalSelectedAttributes] = useState([])
    const [loader, setLoader] = useState(false)
    const { selectedAttributes } = useContext(LinkedInAudienceContext)
    const { getSubCategoryOptions } = useLinkedInTargets({selectedCategory: selectedCategory || category})

    useEffect(() => {
        if (category && subCategory) {
            const matchedSubCategory = getSubCategoryOptions(linkedInTargetFacets)?.find(subCat => subCat.value === subCategory)
            handleSelectCategory(category)
            handleSelectSubCategory(matchedSubCategory)
        }
    }, [category, subCategory])

    // * Disable options that were selected before
    const disabledOptions = useMemo(() => {
        return selectedAttributes.flat()
    }, [selectedAttributes])

    // * Query State
    const [results, setResults] = useState([])

    const handleSelectCategory = (e) => {
        setSelectedCategory(e)
        setSelectedSubcategory(null) // * We want to reset subcategory if the user changes the main category
        setQuery('')
    }

    // * When the user selects/reselects a subcategory
    const handleSelectSubCategory = (subItem) => {
        const { availableEntityFinders } = subItem
        setSelectedSubcategory(subItem)
        setQuery('')
        setResults([])

        // * This will fire off only if the subcategory is a fixed list rather than being query based
        if (availableEntityFinders.includes(ENTITY_FINDERS.AD_TARGETING_FACET) && !availableEntityFinders.includes(ENTITY_FINDERS.TYPEAHEAD)) {
            setLoader(true)
            window.eulerity.makeApiCall({
                url: `/api/linkedin/getAllTargets?facetUrn=${subItem?.urn}`,
                method: 'GET',
                callback: (resp) => {
                    setResults(resp)
                    setLoader(false)
                },
            })
        }
    }

    const { value: query, setValue: setQuery, onChange: onLinkedInQueryChange } = useDelayedInputCallback((val) => {
        if (!val.trim()) return
        setLoader(true)
        window.eulerity.makeApiCall({
            url: `/api/linkedin/queryTargets?facetUrn=${selectedSubcategory.urn}&query=${encodeURIComponent(val)}`,
            method: 'GET',
            callback: (resp) => {
                setResults(resp)
                setLoader(false)
            },
        });
    });

    const handleSelectAttribute = (attribute) => {
        // * This will enable the user to DESELECT an attribute
        if (localSelectedAttributes?.find(att => att?.urn === attribute?.urn)) {
            const filteredAttributes = localSelectedAttributes?.filter(att => att?.urn !== attribute?.urn)
            setLocalSelectedAttributes(filteredAttributes)
            return
        }

        setLocalSelectedAttributes(prev => [...prev, attribute])
    }

    return (
        <Modal
            onClose={handleOnClose}
            header='Add Audience Attribute'
            height='95vh'
            width='736px'
            buttons={[<OutlineBtnLg onClick={handleOnClose}>Cancel</OutlineBtnLg>, <BlackBtnLg onClick={() => handleOnSubmit(localSelectedAttributes, isAddMore && groupIndex)}>Confirm</BlackBtnLg>]}
        >
            <StyledAttributeWrapper>
                <p>Select a targeting category to add audience attributes.</p>
                <StyledInputWrapper>
                    <p>Targeting Category<span className='asterisk'>*</span></p>
                    <Select
                        isClearable
                        styles={selectTheme}
                        placeholder={'Select a Category'}
                        options={linkedinCategoryOptions}
                        onChange={(e) => handleSelectCategory(e)}
                        value={selectedCategory}
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <p>Targeting Subcategory<span className='asterisk'>*</span></p>
                    <Select
                        isClearable
                        styles={selectTheme}
                        placeholder={'Select a Subcategory'}
                        options={getSubCategoryOptions(linkedInTargetFacets)}
                        onChange={(e) => handleSelectSubCategory(e)}
                        value={selectedSubcategory}
                        isDisabled={!selectedCategory} // * disabled until user selects a category
                    />
                </StyledInputWrapper>
                {
                    selectedSubcategory &&
                    <AttributeSearchWrapper>
                        <p style={{marginBottom: '6px'}}>{selectedSubcategory?.desc}</p>
                        <p>Search for attributes</p>
                        {
                            selectedSubcategory?.availableEntityFinders.includes(ENTITY_FINDERS.TYPEAHEAD)
                            &&
                            <SearchInput
                                placeholder='Search Attribute'
                                inputWidth='495px'
                                handleOnChange={onLinkedInQueryChange}
                                textValue={query}
                            />
                        }
                        <SearchResults>
                            {
                                loader ? <p style={{ margin: 'auto' }}>Searching...</p> :
                                    !results.length ? <p style={{ margin: 'auto' }}>No results found</p> :
                                        results.map(result => {
                                            const isAttributeSelected = localSelectedAttributes.some(att => att?.urn === result?.urn)
                                            const isAttributeDisabled = disabledOptions.some(att => att?.urn === result?.urn)

                                            return <ResultItem key={result?.urn} isSelected={isAttributeSelected}>
                                                <input type='checkbox'
                                                    onChange={() => handleSelectAttribute(result)}
                                                    checked={isAttributeSelected || isAttributeDisabled}
                                                    disabled={isAttributeDisabled}
                                                />
                                                <p style={{ flex: '1' }}>{result?.name}</p>
                                            </ResultItem>
                                        })
                            }
                        </SearchResults>
                    </AttributeSearchWrapper>
                }
            </StyledAttributeWrapper>
        </Modal>
    )
}

export default LinkedInAttributeModal