import React from 'react'
import { ModalContentContainer } from './styles'

const ModalContent = ({children}) => {
    return (
        <ModalContentContainer>
            { children }
        </ModalContentContainer>
    )
}

export default ModalContent
