import styled from "styled-components";

export const StyledTargetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    background: rgba(217, 217, 216, 0.20);
    padding: 24px 16px;
`

export const StyledAudienceContainer = styled.div`
    background: ${props => props.theme.background.white};
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    gap: 16px;

    .meta-data {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 12px;

        span {
            color: #717171;
        }
    }
`

export const StyledTargetList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .target-group {
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        display: flex;
        flex-direction: column;
        gap: 18px;
        padding: 16px 10px;
    }

    .target-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .target-items {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
    }

    .item {
        display: flex;
        padding: 4px 12px;
        align-items: center;
        background: ${props => props.theme.card.yellow};
        border-radius: 8px;

        p {
            font-size: 12px;
            font-weight: bold;
        }
    }
`