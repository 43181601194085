import React, { useEffect, useState } from 'react'
import Loader from '../../../../../ui/Loader'

// Redux
import { getListConnectedAccounts } from '../../../../../actions/socialActions'

// Styles
import { TableModalContainer } from '../../../../../ui/table/styles'
import { ModalListContainer, ModalListHeader, ModalListItemWithBtn, ModalListWrapper } from '../../../../../ui/list'
import { toast } from 'react-toastify'

const AddSocialNetworkAccountStep = ({socialLoginId, setSelectedPages, selectedPages, socialType, tableHeader}) => {
    const [loader, setLoader] = useState(false)
    const [pagesList, setPagesList] = useState([]);

    // * Fetches list of connected accounts - depending on selected Social Account
    useEffect(() => {
        setLoader(true)
        getListConnectedAccounts({
            socialLoginId,
            socialType,
            callback: (list) => {
                setLoader(false)
                setPagesList(list);
            },
            errorCallback: () => {
                toast.error(`Something went wrong with ${socialType} account`);
            },
        })
    }, []);

    // * Selection Functions
    const isPageSelected = (pages, id) => pages?.some(page => page.id === id || page.accountId === id);

    const handleSelectPage = (pages, page) => {
        if (isPageSelected(pages, page.id || page.accountId)) {
            const removedPagesList = pages.filter(currPage => currPage.id !== page.id || currPage.accountId !== page.accountId);
            setSelectedPages(removedPagesList);
        } else {
            setSelectedPages([page])
        }
    }

    // * Function returns list of selectable social pages
    const socialPagesList = () => {
        if (loader) return <div className='center-account-loader'><Loader /></div>
        if (!pagesList.length) return <p style={{textAlign: 'center'}}>No Pages Found</p>;

        return pagesList?.map(page => {
            const { id, name, displayName, accountId, currency, error } = page;
            return (
                <ModalListItemWithBtn
                    key={id || accountId}
                    isSelected={isPageSelected(selectedPages, id || accountId)}
                    onClick={() => handleSelectPage(selectedPages, page)}
                    isDisabled={error}
                >
                    <div>
                        {name || displayName}
                        { currency && <span> Currency: {currency} </span>}
                    </div>
                    <span>ID: {id || accountId} </span>
                </ModalListItemWithBtn>
            )
        })
    }

    return (
        <TableModalContainer>
            <ModalListWrapper>
                <ModalListHeader> {tableHeader} </ModalListHeader>
                <ModalListContainer>
                    {socialPagesList()}
                </ModalListContainer>
            </ModalListWrapper>
        </TableModalContainer>
    )
}

export default AddSocialNetworkAccountStep