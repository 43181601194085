import React, { useState, useRef } from 'react';
import Modal from './Modal';
import useCsv from '../../hooks/useCsv';
import Loader from '../../ui/Loader';

// Utils
import { uploadBlob } from '../../utils';

// Styles
import { OutlineBtn, BlackBtn, CenterBtnOutterContainer, CenterBtnContainer } from '../../ui/button';
import { CsvSelectContainer } from '../../ui/table/styles';
import { ListItem, InitialCreateModalContainer, SubHeader } from './styles';
import { FaFileUpload } from 'react-icons/fa';
import { TbCircleMinus } from 'react-icons/tb';

const UploadFileModal = ({ onClose, files, header, handleUpdate }) => {
    const [selectedFiles, setSelectedFiles] = useState(files || []);
    const [isLoading, setIsLoading] = useState(false);

    const CsvUploader = useCsv();
    const fileInputRef = useRef(null);

    const handleExit = () => {
        onClose();
    }

    const handleSave = () => {
        handleUpdate(selectedFiles)
        onClose();
    }

    const handleDeleteItem = (websafe) => {
        const filteredArray = selectedFiles.filter(item => item.websafe !== websafe);
        setSelectedFiles(filteredArray);
    }

    const renderSelectedFiles = () => {
        if (!selectedFiles?.length) return;
        return selectedFiles?.map((file, idx) => <ListItem key={idx}>
            {file.filename} <TbCircleMinus size={18} onClick={() => handleDeleteItem(file.websafe)}/>
        </ListItem>);
    }

    const handleUploadFile = (e) => {
        setIsLoading(true);
        uploadBlob(
            {
                file: e.target.files[0],
                callback: (file) => {
                    setSelectedFiles(selectedFiles?.length ? [...selectedFiles, file] : [file] );
                    setIsLoading(false);
                }
            }
        )
    }

    return (
        <Modal
            header={header}
            onClose={onClose}
            style={{width: '600px'}}
        >
            <InitialCreateModalContainer>
                <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(fileInputRef)}>
                    {isLoading ? <Loader /> :
                        <>
                            <FaFileUpload size={50}/>
                            <br />
                            <p> Select file(s) to upload </p>
                        </>
                    }
                </CsvSelectContainer>
                <input
                    type='file'
                    hidden
                    multiple={true}
                    onChange={(e) => handleUploadFile(e)}
                    ref={fileInputRef}
                />

            <SubHeader> Selected Files </SubHeader>
            {renderSelectedFiles()}

                <CenterBtnOutterContainer>
                <CenterBtnContainer>
                    <OutlineBtn onClick={handleExit}> Cancel </OutlineBtn>
                    <BlackBtn onClick={handleSave} isDisabled={isLoading}> Save File(s) </BlackBtn>
                </CenterBtnContainer>
                </CenterBtnOutterContainer>
            </InitialCreateModalContainer>
        </Modal>
    )
}

export default UploadFileModal;