import React, { useState } from 'react';
import NamingConventionModal from '../../systemConfig/components/modals/NamingConventionModal';

// Styles
import { OneThirdGridColContainer, FieldContainer } from '../../../ui/containers';
import { Text, GrayText, GrayTextLink } from '../../../ui/text';
import { SubHeader } from '../../../ui/headers';
import { Input, Textarea } from '../../../ui/input/input';

const NamingConfiguration = ({network, isEdit, namingConvention, setNamingConvention, groupNamingConvention, setGroupNamingConvention}) => {
    const [isNamingVariableOpen, setIsNamingVariableOpen] = useState(false);

    const networkObj = {
        Facebook: {
            text: 'Campaign Ad Set',
            header: 'Ad Set Naming Convention'
        },
        Google: {
            text: 'Campaign Ad Group',
            header: 'Campaign Ad Group Naming Convention'
        },
        LinkedIn: {
            text: 'Campaign Group Names',
            header: 'Campaign Group Naming Convention'
        }
    }

    return (
        <>
            <SubHeader> Naming Configuration </SubHeader>
            <Text> If this LOB should use a non-system default naming convention for {networkObj[network].text} and/or Campaign Names, please provide the desired convention below </Text>
            <div style={{margin: '20px auto'}} />
            <GrayText> e.g. A campaign naming convention of "[ini: %initiative%] [plt: %platform%]" would resolve to a campaign name of "[ini: Winter Sale] [plt: {network}]" given the specific campaign's initiative is "Winter Sale" on {network}'s platform. </GrayText>
            <div style={{margin: '20px auto'}} />
            <GrayTextLink onClick={() => setIsNamingVariableOpen(true)}> Naming Variable Key </GrayTextLink>

            <OneThirdGridColContainer>
                <FieldContainer>
                    <h3> Campaign Naming Convention <span className='asterisk'>*</span></h3>
                    {
                        isEdit ?
                        <Textarea rows={5} value={namingConvention} onChange={e => setNamingConvention(e.target.value)} placeholder='Campaign Naming Convention'/> :
                        <p> {namingConvention || 'N/A'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3 style={{whiteSpace: 'nowrap'}}> {networkObj[network].header} <span className='asterisk'>*</span> </h3>
                    {
                        isEdit ?
                        <Textarea rows={5} value={groupNamingConvention} onChange={e => setGroupNamingConvention(e.target.value)} placeholder={networkObj[network].header} /> :
                        <p> {groupNamingConvention || 'N/A'} </p>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>
            {
                isNamingVariableOpen && <NamingConventionModal onClose={() => setIsNamingVariableOpen(false)} />
            }
        </>
    )
}

export default NamingConfiguration;