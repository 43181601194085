import React, { useEffect, useState } from 'react'
import { CheckBoxContainer } from './styles';

const CheckBox = ({ label, callback, checked, isDisabled, notClickable}) => {
    const disabled = isDisabled ? isDisabled : false;
    const isDefChecked = checked ? true : false;
    const [isChecked, setIsChecked] = useState(isDefChecked);

    useEffect(() => {
        setIsChecked(isDefChecked)
    }, [checked, isDefChecked])

    const handleClick = () => {
        if(notClickable) return
        setIsChecked(!checked)
        callback()
    }

    return (
        <CheckBoxContainer isDisabled={disabled} >
            <input
                type='checkbox'
                className='checkbox__input'
                disabled={disabled}
                checked={isChecked}
                onChange={handleClick}
            />
            <div className='checkbox__box'>
                { isChecked &&
                    <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <polyline fill="none" stroke="#fff" strokeWidth="3" points="2 14 9 20 22 4"/>
                    </svg>
                }
            </div>
            {label && <p className='checkbox__label'>{label}</p>}
        </CheckBoxContainer>
    )
}

export default CheckBox