import React, { useState } from 'react';
import DeleteTargetingModal from '../../pages/lineofbusiness/components/modal/DeleteTargetingModal';

// Styles
import { TableContainer, TableHeader, EmptyDataContainer } from './styles';
import { BiTrash } from 'react-icons/bi';
import { FiPlusCircle } from 'react-icons/fi';


const FileTable = ({title, data, isEdit, handleUpdate, setIsModalOpen}) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [item, setItem] = useState();

    const handleClick = (val) => {
        setItem(val);
        setIsDeleteModalOpen(true);
    }

    const handleDelete = () => {
        const filteredArr = data.filter(file => file.websafe !== item.websafe);
        handleUpdate(filteredArr);
        setIsDeleteModalOpen(false);
    }

    const filesList = () => {
        return data?.map(file =>
            <li key={file.websafe}>
                    <span className='csvNames'> {file.filename} </span>
                    {isEdit && <BiTrash onClick={() => handleClick(file)}/>}
            </li>)
    }

    return (
        <TableContainer>
            <TableHeader> <h3> {title} </h3> {isEdit && <FiPlusCircle onClick={() => setIsModalOpen(true)} />} </TableHeader>
            {
                data?.length ?
                filesList() :
                <EmptyDataContainer>
                    <p> No {title} Available </p>
                </EmptyDataContainer>
            }
            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={handleDelete}
                    onClose={() => setIsDeleteModalOpen(false)}
                    useLoader={true}
                    title={title}
                    item={item}
                />
            }
        </TableContainer>
    )

}

export default FileTable;