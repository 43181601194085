import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

// Redux
import { useSelector } from 'react-redux'
import { updateCampaign } from '../../../../../actions/mediaActions'

const useLinkedInCampaignGroup = ({}) => {
    const campaign = useSelector(state => state.media.currentCampaign);
    const [isEdit, setIsEdit] = useState(false);
    const [campaignObj, setCampaignObj] = useState({});

    useEffect(() => {
        setCampaignObj(campaign);
    }, [campaign.websafe]);

    const dispatch = useDispatch();

    const currency = campaign?.adAccount?.currency;

    const handleUpdate = ({key, value}) => {
        const updatedCampaignObj = {
            ...campaignObj,
            [key]: value
        }

        setCampaignObj(updatedCampaignObj);
    }

    const handleEdit = () => {
        if (isEdit) {
            // * Required Field Check
            if (!campaignObj?.name?.length) return toast.error('Campaign Group Name is required!')
            if (!campaignObj?.budgetSchedule) return toast.error('Campaign Budget Schedule is required!')
            if ((campaignObj?.budgetSchedule === 'START_AND_END_DATE' || campaignObj?.budgetSchedule === 'START_AND_END_DATE_WITH_BUDGET') && !campaignObj?.end) return toast.error("End Date is required!")
            if (!campaignObj?.start) return toast.error('Start Date is required!')

            const updateObj = {};

            for (const key in campaignObj) {
                if (campaign[key] !== campaignObj[key]) {
                    updateObj[key] = campaignObj[key];
                }
            }

            // * Don't make call if nothing changed
            if (!Object.values(updateObj).length) {
                toast.info('No changed detected!')
                setIsEdit(prev => !prev);
                return
            }

            dispatch(updateCampaign({
                mediaPlanCampaign: campaign.websafe,
                obj: updateObj,
                callback: () => toast.success('Successfully Updated Campaign Group Setup'),
                errorCallback: () => toast.error('Something went wrong')
            }))
        }

        setIsEdit(prev => !prev);
    }

    return {
        isEdit, handleEdit, campaign, campaignObj, handleUpdate, currency
    }
}

export default useLinkedInCampaignGroup;