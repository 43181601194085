import { gmtToLocaleString } from "../../utils/dates";

export const STATUS = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    REJECTED: 'DISAPPROVED',
    DRAFT: 'DRAFT'
}

export const getToolTipHeader = (status) => {
    switch (status) {
        case STATUS.APPROVED:
            return 'Approved';
        case STATUS.REJECTED:
            return 'Disapproved';
        default:
            return 'Waiting Approval'
    }
}

export const generateStatusText = (data) => {
    switch (data?.approval?.status) {
        case STATUS.APPROVED:
            return `The “${data?.name}” was approved by ${data?.approval?.user?.email || data?.approval?.email} on ${gmtToLocaleString(data?.approval?.date)}. `;
        case STATUS.REJECTED:
            return `The “${data?.name}” was rejected by ${data?.approval?.user?.email || data?.approval?.email} on ${gmtToLocaleString(data?.approval?.date)}. `;
        default:
            return `The “${data?.name}” is waiting approval from ${data?.approval?.user?.email || data?.approval?.email} on ${gmtToLocaleString(data?.approval?.date)}. `;
    }
}