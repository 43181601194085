import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateNetworkConfig } from "../../../../../../actions/lobActions"
import { toast } from "react-toastify"

const useLinkedInAudience = ({initialData, liConfigId, onClose, isEditingAudience, selectedAudience, adAccounts, selectedIndex}) => {
    const _D = useDispatch()

    // * Step-1 State
    const [audienceName, setAudienceName] = useState('')
    const [adAccount, setAdAccount] = useState('')
    const [targetType, setTargetType] = useState('')
    const [audienceDesc, setAudienceDesc] = useState(null)
    const [enableExpansion, setEnableExpansion] = useState(false)
    // * Step-2 State
    const [selectedAttributes, setSelectedAttributes] = useState([])
    // * Generic State
    const [btnLoader, setBtnLoader] = useState(false)

    useEffect(() => {
        if (!isEditingAudience) return

        setAudienceName(selectedAudience?.name)
        setAudienceDesc({label: selectedAudience?.description, value: selectedAudience?.description})
        setTargetType({label: selectedAudience?.type, value: selectedAudience?.type})
        setEnableExpansion(selectedAudience?.audienceExpansion)
        setSelectedAttributes(selectedAudience?.includedTargets)
        const matchedAdAccount = adAccounts?.find(adAccount => adAccount?.accountId === selectedAudience?.accountId)
        setAdAccount({label: matchedAdAccount?.displayName, value: matchedAdAccount?.accountId})
    }, [isEditingAudience, selectedAudience, adAccounts])

    // * Submit Function
    const onSubmit = () => {
        const linkedAudienceObject = {
            name: audienceName,
            accountId: adAccount?.value,
            type: targetType?.value,
            description: audienceDesc?.value,
            audienceExpansion: enableExpansion,
            includedTargets: selectedAttributes && selectedAttributes // * only add if the user fills the step out
        }

        setBtnLoader(true)
        _D(updateNetworkConfig({
            obj: { audienceTargets: [...initialData, linkedAudienceObject]}, // * Spread what we have and add new audience
            socialConfig: `liconfig=${liConfigId}`,
            callback: () => {
                toast.success('Added LinkedIn Audience Target')
                setBtnLoader(false)
                onClose()
            },
            handleErrorCallback: () => {
                toast.error('Something went wrong!')
                setBtnLoader(false)
            }
        }))
    }

    // * On Edit Submission
    const onEdit = () => {
        const linkedAudienceObject = {
            name: audienceName,
            accountId: adAccount?.value,
            type: targetType?.value,
            description: audienceDesc?.value,
            audienceExpansion: enableExpansion,
            includedTargets: selectedAttributes && selectedAttributes // * only add if the user fills the step out
        }
        const audienceCopy = [...initialData]
        audienceCopy[selectedIndex] = linkedAudienceObject
        setBtnLoader(true)
        _D(updateNetworkConfig({
            obj: { audienceTargets: [...audienceCopy]}, // * Spread what we have and add new audience
            socialConfig: `liconfig=${liConfigId}`,
            callback: () => {
                toast.success('Updated LinkedIn Audience Target')
                setBtnLoader(false)
                onClose()
            },
            handleErrorCallback: () => {
                toast.error('Something went wrong!')
                setBtnLoader(false)
            }
        }))
    }

    return {
        audienceName,
        setAudienceName,
        adAccount,
        setAdAccount,
        targetType,
        setTargetType,
        audienceDesc,
        setAudienceDesc,
        enableExpansion,
        setEnableExpansion,
        selectedAttributes,
        setSelectedAttributes,
        btnLoader,
        onSubmit,
        onEdit
    }
}

export default useLinkedInAudience