import React, { useState } from 'react';
import DeleteModal from '../../components/Modal/DeleteModal';
import CloneModal from '../../components/Modal/CloneModal';
import { dateNormalizer } from '../../utils/dates';
import { serveImage } from '../../pages/systemConfig/components/labelManagement/label.utils';
import { toast } from 'react-toastify';

//Redux
import { useDispatch, useSelector } from 'react-redux';

// Styles
import { CardContainer, IconContainer, Label, LabelsContainer, DetailsContainer, Detail, StyleTemplateCardName, DummyLogo, StatusContainer } from './styles';

// Icons
import HoverEffect from './IconHoverEffect';
import { BiPencil, BiTrash, BiCopy } from 'react-icons/bi';
import { IoRocketOutline } from 'react-icons/io5';
import { useTheme } from 'styled-components';
import { FacebookI, GoogleI, LinkedinI } from '../icons';
import StatusTag from '../statusTag/StatusTag';
import { NETWORKS } from '../../constants/networks';

const Card = ({
        data, setIsEdit, type, deleteItem, selectItem, deleteTextObj,
        launch, handleLaunch, cloneTemplateObj, googleSheetsLinkURL,
        setOverviewLoader
    }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const { name, labels, ownerName, created, goal, priority, websafe} = data;
    const dispatch = useDispatch();
    const theme = useTheme()
    const [loader, setLoader] = useState(false)

    const dummyNetworks = useSelector(state => state?.org?.organization?.dummyNetworks);

    const renderLogo = () => {
        if (type !== 'temp') return;
        if (data?.network?.toLowerCase() === NETWORKS.GOOGLE.toLowerCase())   return <GoogleI size="22px" />;
        if (data?.network?.toLowerCase() === NETWORKS.FACEBOOK.toLowerCase()) return <FacebookI size="22px" />;
        if (data?.network?.toLowerCase() === NETWORKS.LINKEDIN.toLowerCase()) return <LinkedinI size="22px" />;
        const currDummyNetwork = dummyNetworks?.find(dummy => dummy.name === data.network);
        return <DummyLogo src={serveImage(currDummyNetwork?.logo)} alt='logo'/>;
    }

    const renderLabels = labels?.map((label, idx) => <Label key={idx}> {label} </Label>);
    const renderDetails = () => {
        return (
            <>
                <Detail> Created By: <span>{ownerName}</span> </Detail>
                <Detail> Created On: <span>{dateNormalizer(created)}</span> </Detail>
                {type === 'temp' &&
                    <>
                        {goal && <Detail> Goal: <span className='yellow'>{goal}</span></Detail>}
                        {priority && <Detail> Priority: <span className='yellow'>{priority}</span></Detail>}
                    </>
                }
                {type === 'media' &&
                    <Detail> LOB Used: <span> {data?.lob?.name || 'Deleted'} </span> </Detail>
                }
                {googleSheetsLinkURL &&
                    <div className='google-sheet_link'><a style={{color:"inherit"}} href={googleSheetsLinkURL} target="_blank" rel="noreferrer" >Google Sheets Link</a></div>
                }
            </>
        )
    }

    const handleDelete = () => {
        setLoader(true)
        dispatch(deleteItem({
            websafe,
            callback: () => {
                setIsDeleteModalOpen(false);
                toast.success(`Deleted ${name}`);
                setLoader(false);
            },
            errorCallback: () => {
                toast.error('Something went wrong');
            }
        }))
    }

    const handleEdit = () => {
        setOverviewLoader(true);
        dispatch(selectItem({
            websafe: websafe,
            callback: () => {
                setIsEdit(true);
                setOverviewLoader(false);
            },
            errorCallback: () => toast.error('Something went wrong')
        }))
    }

    return (
        <CardContainer>
            <IconContainer>
                {(type === 'temp' || type ==='lob') &&
                    <HoverEffect color={theme.color.gray}> <BiCopy onClick={() => setIsCopyModalOpen(true)} />
                </HoverEffect>}
                {launch && data?.lob?.name && <HoverEffect color={theme.color.green}><IoRocketOutline onClick={() => {
                    dispatch(selectItem({
                        websafe: websafe
                    }))
                    handleLaunch(websafe)
                }} /></HoverEffect> }
                <HoverEffect color={theme.color.yellow}> <BiPencil onClick={handleEdit}/></HoverEffect>
                <HoverEffect color={theme.color.red}><BiTrash onClick={() => setIsDeleteModalOpen(true)} /></HoverEffect>
            </IconContainer>
                { type === 'media' &&
                    <StatusContainer>
                        <StatusTag data={data} showTooltips/>
                    </StatusContainer>
                }
            <StyleTemplateCardName>
                {renderLogo()}
                <h3> { name } </h3>
            </StyleTemplateCardName>


            <DetailsContainer>
                {renderDetails()}
            </DetailsContainer>

            {
                labels?.length > 0 &&
                <LabelsContainer>
                    {renderLabels}
                </LabelsContainer>
            }

            { isDeleteModalOpen &&
                <DeleteModal
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    handleDelete={handleDelete}
                    header={deleteTextObj?.header}
                    deleteBtnText={deleteTextObj?.deleteBtnText}
                    modalText={deleteTextObj?.modalText + name + '?'}
                    loading={loader}
                />
            }
            { isCopyModalOpen &&
                <CloneModal
                    cloneTemplateObj={cloneTemplateObj}
                    setIsCopyModalOpen={setIsCopyModalOpen}
                    setIsEdit={setIsEdit}
                    data={data}
                />
            }
        </CardContainer>
    )
}

export default Card;