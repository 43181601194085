import React, { useState, useEffect } from 'react';
import FileTable from '../../../ui/table/FileTable';
import CreatableSelect from 'react-select/creatable';
import UploadFileModal from '../../../components/Modal/UploadFileModal'

// Helpers
import { EditSaveBtn } from '../../../ui/button';
import { isDuplicateName } from '../../../utils';
import { toast } from 'react-toastify';
import { shouldUpdateString } from '../../../utils/payload';
import { arraysEqual } from '../../../utils/arrays';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getLobList } from '../../../actions/lobActions';

// Styles
import { SubHeader, ColumnHeader } from '../../../ui/headers';
import { FieldColContainer, StyledFieldsWrapper } from '../../../ui/containers';
import { FieldContainer } from '../../../ui/containers';
import { Input } from '../../../ui/input/input';
import { selectTheme } from '../../../ui/themes';

const MediaPlanSetup = ({handleUpdate, isEdit, setIsEdit}) => {
    const [name, setName] = useState('');
    const [initiative, setInitiative] = useState('');
    const [labels, setLabels] = useState([]);
    const [lob, setLob] = useState('');
    const [isClientBriefsModalOpen, setIsClientBriefsModalOpen] = useState(false);

    const dispatch = useDispatch();

    const mediaPlanList = useSelector(state => state.media.mediaList);
    const mediaPlan = useSelector(state => state.media.currentMediaPlan);

    useEffect(() => {
        dispatch(getLobList());
    }, []);

    useEffect(() => {
        if (mediaPlan?.name) setName(mediaPlan.name);
        if (mediaPlan?.lob) setLob(mediaPlan.lob);
        if (mediaPlan?.labels) setLabels(mediaPlan.labels);
        if (mediaPlan?.initiative) setInitiative(mediaPlan?.initiative)
    }, [mediaPlan])

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {}

            if (shouldUpdateString(name, mediaPlan.name)) updateObj.name = name;
            if (isDuplicateName(updateObj?.name, mediaPlanList, mediaPlan.websafe)) {
                toast.error(`A media plan with the name ${name} already exists`);
                return;
            }

            if (!arraysEqual(labels, mediaPlan?.labels)) updateObj.labels = labels;

            if (shouldUpdateString(initiative, mediaPlan?.initiative)) updateObj.initiative = initiative;

            if (Object.keys(updateObj).length > 0) handleUpdate(updateObj);
        }
        setIsEdit(!isEdit);
    }

    const renderLabels = () => {
        return labels?.map(label => {
            return (
                {
                    label: label,
                    value: label
                }
            )
        })
    }

    const handleLabelChange = selectedOptions => {
        const labels = selectedOptions?.map(opt => opt.value) || [];
        setLabels(labels)
    }

    const handleUpdateClientBriefs = (arr) => {
        handleUpdate({clientBriefs: arr});
    }

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                Media Plan Setup
                {Object.keys(lob)?.length > 0 && <div>
                    {EditSaveBtn(isEdit, handleEdit)}
                </div>}
            </SubHeader>

            <ColumnHeader> Setup Details </ColumnHeader>
            <FieldColContainer>
                <FieldContainer>
                    <h3> Name of Media Plan {<span className='asterisk'>*</span>} </h3>
                    {
                        isEdit ?
                        <Input
                            value={name || 'Enter Media Plan Name'}
                            onChange={e => setName(e.target.value)}
                        /> :
                        <p> {mediaPlan?.name || 'N/A'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> Line of Business Selection </h3>
                    <p> {mediaPlan?.lob?.name || 'Deleted'} </p>
                </FieldContainer>

                <FieldContainer>
                    <h3> Labels </h3>
                    {
                        isEdit ?
                        <CreatableSelect
                            isClearable
                            isMulti
                            options={renderLabels()}
                            styles={selectTheme}
                            defaultValue={renderLabels()}
                            onChange={e => handleLabelChange(e)}
                        />
                        :
                        <p> {labels?.length ? labels.join(', ') : 'N/A'} </p>
                    }
                </FieldContainer>
            </FieldColContainer>

            <FieldColContainer>
                <FileTable
                    title='Client Brief File(s)'
                    data={mediaPlan?.clientBriefs}
                    isEdit={isEdit}
                    handleUpdate={handleUpdateClientBriefs}
                    setIsModalOpen={setIsClientBriefsModalOpen}
                />
            </FieldColContainer>

            <ColumnHeader>Campaign Naming Convention Variables</ColumnHeader>

            <FieldColContainer>
                <FieldContainer>
                    <h3>Initiative</h3>
                    {
                        isEdit ?
                        <Input value={initiative} placeholder='Enter Initiative' onChange={(e) => setInitiative(e.target.value)}/> : <p>{initiative || 'N/A'}</p>
                    }
                </FieldContainer>
            </FieldColContainer>

            {
                isClientBriefsModalOpen &&
                <UploadFileModal
                    header='Upload Client Brief'
                    primaryText='Upload Client Brief'
                    onClose={() => setIsClientBriefsModalOpen(false)}
                    files={mediaPlan?.clientBriefs}
                    handleUpdate={handleUpdateClientBriefs}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default MediaPlanSetup;