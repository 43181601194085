import React, { useContext, useEffect, useState } from 'react'
import EditContext from '../../Context/EditContext'
import LinkedInNetworkTable from './tables/LinkedInNetworkTable'
import LinkedInAudienceTable from './tables/LinkedInAudienceTable'
import NamingConfiguration from '../../NamingConfiguration'
import { OneThirdGridColContainer, StyledFieldsWrapper } from '../../../../../ui/containers'
import { SubHeader } from '../../../../../ui/headers'
import { EditSaveBtn } from '../../../../../ui/button'
import LinkedInNetworkModal from '../linkedIn/modals/linkedinNetwork/LinkedInNetworkModal'
import { useDispatch, useSelector } from 'react-redux'
import { selectLiConfig, updateNetworkConfig } from '../../../../../actions/lobActions'
import Loader from '../../../../../ui/Loader'
import { shouldUpdateString } from '../../../../../utils/payload'
import { toast } from 'react-toastify'
import LinkedInIcon from '../../../../../assets/images/socialMediaIcons/LinkedInIcon.png';


const LinkedInNetworkConfig = () => {
    const _D = useDispatch()
    // * States
    const lob = useSelector(s => s.lob.currentLineOfBusiness)
    const currNetworkConfig = useSelector(s => s.lob.currentNetworkConfig)

    const [campaignNamingConvention, setCampaignNamingConvention] = useState('');
    const [campaignGroupNamingConvention, setCampaignGroupNamingConvention] = useState('')
    const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false);
    const [adAccounts, setAdAccounts] = useState([]);
    const [liAudiences, setLiAudiences] = useState([]);
    const [liConfigId, setLiConfigId] = useState();
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (!liConfigId) {
            setLoader(true);
            return;
        }

        _D(selectLiConfig({
            liConfigId,
            callback: () => setLoader(false)
        }))

    }, [liConfigId]);

    useEffect(() => {
        const liKey = lob?.networkConfigs.find(network => network.keyParam === 'liconfig');
        setLiConfigId(liKey?.websafe)
    }, [])

    useEffect(() => {
        setAdAccounts(currNetworkConfig?.adAccounts)
        setCampaignNamingConvention(currNetworkConfig?.campaignNamingConvention)
        setCampaignGroupNamingConvention(currNetworkConfig?.campaignGroupNamingConvention)
        setLiAudiences(currNetworkConfig?.audienceTargets)
    }, [currNetworkConfig, liConfigId])

    const { isEdit, setIsEdit } = useContext(EditContext);

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};
            if (!campaignNamingConvention) return toast.error('Campaign Naming Convention is required')
            if (!campaignGroupNamingConvention) return toast.error('Campaign Naming Group Convention is required')


            if (shouldUpdateString(campaignNamingConvention, currNetworkConfig?.campaignNamingConvention)) updateObj.campaignNamingConvention = campaignNamingConvention;
            if (shouldUpdateString(campaignGroupNamingConvention, currNetworkConfig?.campaignGroupNamingConvention)) updateObj.campaignGroupNamingConvention = campaignGroupNamingConvention;

            if (Object.keys(updateObj).length > 0) {
                _D(updateNetworkConfig({
                    obj: updateObj,
                    socialConfig: `liconfig=${liConfigId}`,
                    callback: () => {
                        toast.success('Updating LinkedIn Network Config');
                    }
                }))
            }

        }

        setIsEdit(!isEdit)
    }

    if (loader) return <Loader size={55}/>;

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                <span>
                    <img src={LinkedInIcon} alt='linkedin icon'/>
                    Network Configurations for LinkedIn
                </span>
                {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>

            <OneThirdGridColContainer>
                <LinkedInNetworkTable
                    isEdit={isEdit}
                    tableData={adAccounts}
                    handleOnCreate={() => setIsNetworkModalOpen(true)}
                    updateTableData={setAdAccounts}
                    liConfigId={liConfigId}
                />
                <LinkedInAudienceTable
                    isEdit={isEdit}
                    liAudiences={liAudiences}
                    setLiAudiences={setLiAudiences}
                    liConfigId={liConfigId}
                    adAccounts={adAccounts}
                />
            </OneThirdGridColContainer>

            <NamingConfiguration
                network='LinkedIn'
                isEdit={isEdit}
                namingConvention={campaignNamingConvention}
                setNamingConvention={setCampaignNamingConvention}
                groupNamingConvention={campaignGroupNamingConvention}
                setGroupNamingConvention={setCampaignGroupNamingConvention}
            />
            {
                isNetworkModalOpen &&
                <LinkedInNetworkModal
                    onClose={() => setIsNetworkModalOpen(false)}
                    data={adAccounts}
                    setData={setAdAccounts}
                    configId={liConfigId}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default LinkedInNetworkConfig