import styled, { css } from "styled-components";

const sharedModalStyles = css`
    background: ${props => props.theme.background.white};
    box-shadow: 0px 4px 5px ${props => props.theme.modal.boxShadow};
    border-radius: 4.2px;
    padding: 40px 48px;
    z-index: 1000;
`

// * Entire modal itself - more of a wrapper
export const ModalContainer = styled.div`
    ${sharedModalStyles}
    width: ${props => props.width};
    height: ${props => props.height};
    min-width: ${props => props.minWidth};
    max-width: ${props => props.maxWidth};
`


// * Modal Content - actual container that holds content
export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

// * Modal Header (Will contain title and "x" button, and an image if used)
export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .header__left {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header__image__wrapper {
        margin-right: 10px;
    }

    .header__right {
        cursor: pointer;
    }
`

// * Text used for modal headers
export const ModalHeaderText = styled.h1`
    color: ${props => props.theme.background.black};
    white-space: nowrap;
    font-family: 'GothamMedium';
    font-weight: 500;
    font-size: 20px;
`

// * Seperator for header/body
export const ModalSeparator = styled.div`
    height: 1px;
    border: 0.5px solid #D9D9D8;
    margin-top: 15px;
    margin-bottom: 10px;
`

// * Modal Body Wrapper - container for modal content, will be scrollable if it exceeds modal height.
export const ModalScrollableBody = styled.div`
    flex: 1;
    overflow-y: scroll;

    ::-webkit-scrollbar {
    display: none;
}
`

// * Modal Footer Wrapper - lower section of modal, should be used for buttons
export const ModalFooter = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: center;
`

// * Holds all buttons for given modal
export const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    button {
        width: fit-content;
        height: fit-content;
        padding: 10px 20px;
    }
`

// ****************************** BASIC MODAL ****************************** //
export const BasicModalContainer = styled.div`
    ${sharedModalStyles}
    width: ${props => props.width};
    height: ${props => props.height};
`

export const BasicModalContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const BasicModalHeader = styled.div`
    display: flex;
    align-content: center;
    align-items: center;

    .header__left {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header__image__wrapper {
        margin-right: 10px;
    }

    .header__right {
        cursor: pointer;
        margin-left: auto;
    }
`

export const BasicModalBody = styled.div`
    flex: 1;
    overflow-y: scroll;
`

export const BasicModalFooter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`