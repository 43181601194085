import styled from "styled-components";

export const OverviewSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  button {
    width: 200px;

    svg {
      font-size: 14px;
    }
  }

  .search-filter-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

  @media only screen and (min-width: 768px) {
    button {
      width: 260px;

      svg {
        font-size: 16px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    button {
      width: 300px;

      svg {
        font-size: 18px;
      }
    }
  }
`;

export const StyleMainBtnWrapper = styled.div`
  position: relative;
`;
export const StyleHiddenDropdown = styled.div`
  position: absolute;
  top: 3.4rem;
  max-height: 580px;
  overflow: scroll;
  background-color: ${props => props.theme.background.white};
  width: 120%;
  box-sizing: border-box;
  visibility: 0;
  opacity: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: ${props => props.theme.background.boxShadow} 0px 12px 12px;
  .tm-item {
    padding-left: 0.5rem;
    height: 54px;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    color: ${props => props.theme.card.gray};
    font-weight: 500;

    img {
      width: 22px;
      height: 22px;
    }

    :hover {
        background-color:  ${props => props.theme.background.hover};
    }
  }
  .tm-item-1 {
    border-bottom: 2px solid ${props => props.theme.background.gray};
  }
`;
