import React from 'react';
import { FieldContainer } from '../../../../ui/containers';
import CurrencyInput from '../../../../ui/input/fields/CurrencyInput';

const Budget = ({campaign, budget, setBudget, isEdit}) => {
    const currency = campaign?.adAccount?.currency;
    return (
        <FieldContainer>
            {
                isEdit ?
                <>
                <div className='edit_text_label'>
                    <CurrencyInput
                        onChange={e => setBudget(e.target.value)}
                        value={budget}
                        currency={currency}
                    />
                </div>
                {!budget && <p className="error">Enter Budget to Launch Campaign</p>}
                </>
                :
                <>
                    <p> {currency} {budget || 'N/A'} </p>
                    {!budget && <p className="error">Enter Budget to Launch Campaign</p>}
                </>
            }
        </FieldContainer>
    )
};

export default Budget;
