import React, { useState } from 'react';
import BuildModal from '../../../../components/Modal/modals/BuildModal';
import NameStep from '../CreateTemplateSteps/NameStep';
import AdvertiserStep from '../CreateTemplateSteps/AdvertiserStep';

// Utils
import { toast } from 'react-toastify';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { OutlineBtn, BlackBtn } from '../../../../ui/button';

const CreateTemplateModal = ({onClose, register, handleSubmit, setValue, getValues, setIsEdit, network}) => {
    const [step, setStep] = useState('name');
    const [tempName, setTempName] = useState('');
    const [tempAdvertiser, setTempAdvertiser] = useState('');

    const templateList = useSelector(state => state.template.templateList);

    const isDuplicateName = (name) => templateList.find(template => template.name.toLowerCase() === name.toLowerCase());
    document.body.style.overflow = 'hidden';

    const handleExit = () => {
        setValue('name', '');
        setValue('advertiser', '');
        document.body.style.overflow = 'auto';
        onClose();
    }

    const handeSaveAndExit = () => {
        const { name } = getValues();

        if (isDuplicateName(name)) {
            toast.error(`${name} template already exists`)
            return
        }

        handleSubmit();
        setValue('network', network);
        document.body.style.overflow = 'auto';
        onClose();
    }

    const handleSaveAndContinue = () => {
        const { name } = getValues();

        if (isDuplicateName(name)) {
            toast.error(`${name} template already exists`)
            return;
        }

        setValue('network', network)
        handleSubmit();
        setIsEdit(true);
        document.body.style.overflow = 'auto';
        onClose();
    }

    const nameButtonsStepArray = [
        <OutlineBtn onClick={handleExit} key='cancel'> Cancel </OutlineBtn>,
        <BlackBtn onClick={() => setStep('advertiser')} key='continue' isDisabled={!tempName}> Continue </BlackBtn>,
    ]

    const advertiserButtonsStepArray = [
        <OutlineBtn onClick={handleExit} key='cancel'> Cancel </OutlineBtn>,
        <OutlineBtn onClick={handeSaveAndExit} key='saveAndExit' isDisabled={!tempAdvertiser}> Save & Exit </OutlineBtn>,
        <BlackBtn onClick={handleSaveAndContinue} key='saveAndContinue' isDisabled={!tempAdvertiser}> Save & Continue </BlackBtn>
    ]

    const buttonStep = () => {
        switch (step) {
            case 'name':
                return nameButtonsStepArray;
            case 'advertiser':
                return advertiserButtonsStepArray;
            default: break;
        }
    }

    const componentSteps = () => {
        switch (step) {
            case 'name':
                return (
                    <NameStep
                        register={register}
                        setTempName={setTempName}
                    />
                )
            case 'advertiser':
                return (
                    <AdvertiserStep
                        network={network}
                        register={register}
                        setValue={setValue}
                        setTempAdvertiser={setTempAdvertiser}
                    />
                )
            default: break;
        }
    };

    return (
        <BuildModal
            onClose={handleExit}
            header='Create New Network Template'
            minWidth='650px'
            maxWidth='1000px'
            buttons={buttonStep()}
        >
           {componentSteps()}
        </BuildModal>
    )
}

export default CreateTemplateModal;