import { makeGETPromiseApiCall, makePOSTPromiseApiCall } from '../pages/systemConfig/utils';
import { SET_ORG } from './types';

export const getOrg = () => dispatch => {
    try {
        window.eulerity.makeApiCall('/api/org/get', 'GET', null, res => {
            dispatch({
                type: SET_ORG,
                payload: res
            })
        })
    } catch (err) {
        console.error('Something went wrong', err);
    }
}

export const setOrganization = (orgData = {}, options = {shouldMakeApiCall: false}) => async dispatch => {
    if(!options.shouldMakeApiCall) {
        dispatch({
            type: SET_ORG,
            payload: orgData
        })
        return
    }
    try {
        const org = await makeGETPromiseApiCall('/api/org/get')
        dispatch({
            type: SET_ORG,
            payload: org
        })
    } catch (error) {
        throw new Error(error)
    }
}

//! This function should be deprecated because it lacks error handling.
export const updateOrg = ({obj, callback}) => { // mostly used manually atm until system config is ready
    window.eulerity.makeApiCall('/api/org/update', 'POST', obj, res => {
        callback?.(res);
    })
}

export const updateOrganization = (updatedOrg) => dispatch => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await makePOSTPromiseApiCall('/api/org/update', updatedOrg);
            resolve(response)
            dispatch({
                type: SET_ORG,
                payload: response
            })
        } catch (error) {
            reject(error)
        }
    })
}

export const getDataFile = ({query, callback}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await window.eulerity.makeApiCall(`/api/org/getDataFile?filename=${query}`, 'GET', null, res => {
                callback?.(res);
                resolve(response);
            })
        } catch (err) {
            reject(err);
        }
    })
}

export const createDummyNetwork = ({obj, callback}) => dispatch => {
    return new Promise(async(resolve, reject) => {
        try {
            const res = await makePOSTPromiseApiCall(`/api/org/createDummyNetwork`, obj);
            resolve(res);
            callback?.(res);
            dispatch({
                type: SET_ORG,
                payload: res
            });
        } catch (error) {
            reject(error);
        }
    })
}

export const updateDummyNetwork = ({websafe, obj, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/org/updateDummyNetwork?dummyNetwork=${websafe}`, 'POST', obj, res => {
        callback?.(res);
        dispatch({
            type: SET_ORG,
            payload: res
        })
    })
}

export const createDummyProperties = ({websafe, obj, callback, errorCallback}) => {
    return new Promise(async(resolve, reject) => {
        try {
            const res = await makePOSTPromiseApiCall(`/api/org/createDummyProperties?dummyNetwork=${websafe}`, obj);
            resolve(res);
            callback?.(res);
        } catch (err) {
            reject(err);
            errorCallback?.(err);
        }
    })
}

export const updateDummyProperty = ({websafe, obj, callback}) => {
    return new Promise(async(resolve, reject) => {
        try {
            const res = await makePOSTPromiseApiCall(`/api/org/updateDummyProperty?dummyProperty=${websafe}`, obj);
            resolve(res);
            callback?.(res);
        } catch (err) {
            reject(err);
        }
    })
}


export const updateSettings = ({obj, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/org/updateSettings`, 'POST', obj, res => {
        callback?.(res);
        dispatch({
            type: SET_ORG,
            payload: res
        })
    })
}