import React, { useState } from 'react'
import { FiPlusCircle } from 'react-icons/fi'
import DeleteTargetingModal from '../modal/DeleteTargetingModal'
import { BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { updateLob } from '../../../../actions/lobActions'
import { toast } from 'react-toastify'
import { EmptyDataContainer, TableContainer, TableHeader, TableListItem } from './styles'

// * This table is meant for non-object values for LoB (expects to utilize - update lob endpoint)
const LobItemTable = ({ title, isEdit, handleCreateItem, tableData, fieldName, successText }) => {
    const _D = useDispatch()
    const currentLob = useSelector(s => s.lob.currentLineOfBusiness)

    const [loader, setLoader] = useState(false);
    const [selectedItem, setSelectedItem] = useState()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    // * Function will select item and will handle a given action (delete/edit)
    const handleSelectItem = (val) => {
        setSelectedItem(val)
        setIsDeleteModalOpen(true)
    }

    const renderData = () => tableData?.map((val, idx) => {
        return (
            <TableListItem key={idx}>
                <div>
                    {typeof val === 'string' ? val : val?.name || val?.displayName}
                    {val?.currency && <span>Currency: {val?.currency} </span>}
                </div>
                {isEdit && <BiTrash onClick={() => handleSelectItem(val)} />}
            </TableListItem>
        )
    })

    const handleDeleteItem = () => {
        const updatedValues = tableData.filter(data => data !== selectedItem)
        setLoader(true)
        _D(updateLob({
            websafe: currentLob.websafe,
            updateObj: { [fieldName]: updatedValues  }, // * What field within lob is changing
            callback: () => {
                setIsDeleteModalOpen(false)
                toast.success(successText)
                setLoader(false)
            },
        }))
    }


    return (
        <TableContainer>
            <TableHeader>
                <h3> {title} </h3>
                {isEdit && <FiPlusCircle onClick={handleCreateItem} />}
            </TableHeader>

            {
                tableData?.length > 0 ?
                    renderData() :
                    <EmptyDataContainer>
                        <p> No {title} Available </p>
                    </EmptyDataContainer>
            }

            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={handleDeleteItem}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title={title}
                    item={selectedItem}
                    loading={loader}
                    useLoader
                />
            }
        </TableContainer>
    )
}

export default LobItemTable