import React from 'react';
import AsyncMultiSelect from '../../../../ui/AsyncMultiSelector';

// Styles
import { FieldContainer } from '../../../../ui/containers';

const LocationTargets = ({locationTargets, setLocationTargets, isEdit}) => {
    const handleChange = (e) => {
        const list = e?.map(location => location.value);
        setLocationTargets(list);
    }
    return (
        <FieldContainer>
            <h3> Location Targets </h3>
            {isEdit ?
                <AsyncMultiSelect
                    query={`/api/lob/locationTargets`}
                    localState={handleChange}
                    defaultOptions={locationTargets.map(location => {
                        return ({label: location.canonicalName, value: location})
                    })}
                    isBatchCall={true}
                    label='canonicalName'
                />
            : <p> {locationTargets?.length ? locationTargets.map(location => location.name).join(', ') : 'N/A'} </p>}
        </FieldContainer>
    )
}

export default LocationTargets;