import React from 'react';
import Select from 'react-select';

// Styles
import { FieldContainer } from '../../../../ui/containers';
import { selectTheme } from '../../../../ui/themes';

const ConversionEvent = ({isEdit, conversionEvent, setConversionEvent, conversionEventList, currAdGroup}) => {
    const options = conversionEventList?.map(item => { return {label: item, value: item} });

    return (
        <FieldContainer>
            <h3> Conversion Event </h3>
            {
                isEdit ?
                <Select
                    isClearable
                    options={options}
                    defaultValue={{
                        label: currAdGroup?.conversionEvent,
                        value: currAdGroup?.conversionEvent
                    }}
                    styles={selectTheme}
                    onChange={e => setConversionEvent(e.label)}
                /> :
                <p> {conversionEvent || 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default ConversionEvent;