import React from 'react'
import { FieldContainer } from './style';
import CreatableSelect from "react-select";
import { selectTheme } from '../../../ui/themes';
import useInputColors from '../../../hooks/useInputColors';
const MultiSelectField = ({text, isEdit,localState, options, isDisabled, value, menuPlacement = "bottom"}) => {
   const {alertStyles} = useInputColors(isDisabled)
   if(value) isDisabled = false
   if(value === undefined) value = null
    return (
        <FieldContainer>
        <h3>{text.header} </h3>
        {
            isEdit ?
            <CreatableSelect
                menuPlacement={menuPlacement}
                isDisabled={isDisabled}
                placeholder={isDisabled ? "": options?.length ? "Select" : "No Options to Select"}
                isClearable
                isMulti
                options={options}
                styles={{...selectTheme,
                    control: provided => ({
                      ...provided,
                      ...alertStyles
                  })
                  }}

                onChange={e => localState(e)}
                value={value}
            />
            :
            <p> {value?.length ? value.map(it => it.label)?.join(', ') : 'N/A'} </p>
        }
    </FieldContainer>
    )
}

export default MultiSelectField

