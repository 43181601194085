import React, { useState, useEffect } from 'react';
import GoogleCampaignSetup from './GoogleCampaignSetup';
import GoogleAdGroupSetup from './GoogleAdGroupSetup';
import SubNavigation from '../../../components/SubNavigation/SubNavigation';
import DeleteModal from '../../../components/Modal/DeleteModal';
import Loader from '../../../ui/Loader';

// Helpers
import { shouldUpdateString } from '../../../utils/payload';
import { isObjArraysEqual, arraysEqual, generateDropdownOptions } from '../../../utils/arrays';
import { validateBidAdjustment, validateImpressionData } from '../../../utils/validation';
import { dateCheck, stringToDateconverter } from './utils';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getLob } from '../../../actions/lobActions';
import { getNetworkTemplateList, getTemplate } from '../../../actions/tempActions';
import { deleteCampaign, updateCampaign } from '../../../actions/mediaActions';
import { getCampaign } from '../../../actions/mediaActions';

// Styles
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn, HeaderIconBtnContainer, RedOutlineIconBtn, StyleCenterElement } from '../../../ui/button';
import { TemplateCard } from '../styles';
import { Label } from '../../../ui/card/styles';
import { Text } from '../../../ui/text';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { WarningIcon } from '../../../ui/icons';
import { StyledFieldsWrapper } from '../../../ui/containers';
import { getAdvertiser } from '../../../actions/systemConfigActions';

const GoogleCampaignDetails = ({websafe, mediaPlan, resetPage, isEdit, setIsEdit}) => {
    // const [campaign, setCampaign] = useState();

    // Dropdown values
    const [countryOptions, setCountryOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);

    // Flight Details
    const [name, setName] = useState('');
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [budget, setBudget] = useState();
    const [adAccount, setAdAccount] = useState();

    // Naming Convention (Campaign)
    const [campaignCountries, setCampaignCountries] = useState([]);
    const [campaignLanguages, setCampaignLanguages] = useState([]);

    // Originally Derived from Template
    const [objective, setObjective] = useState('');
    const [bidStrategy, setBidStrategy] = useState();

    // Google
    const [campaignType, setCampaignType] = useState('');
    const [searchNetwork, setSearchNetwork] = useState(false);
    const [useAdRotation, setUseAdRotation] = useState(false);
    const [desktopBid, setDesktopBid] = useState(0);
    const [mobileBid, setMobileBid] = useState(0);
    const [tabletBid, setTabletBid] = useState(0);
    const [audienceTargeting, setAudienceTargeting] = useState(false);
    const [displayNetwork, setDisplayNetwork] = useState(false)
    const [searchPartners, setSearchPartners] = useState(false);
    const [useEnhancedCpc, setUseEnhancedCpc] = useState(false);
    const [targetCpa, setTargetCpa] = useState(0);
    const [targetRoas, setTargetRoas] = useState(0);
    const [cpcBidLimit, setCpcBidLimit] = useState(0);
    const [adLocation, setAdLocation] = useState('');
    const [percentImpression, setPercentImpression] = useState(0)

    // Originally Derived from LOB
    const [minAge, setMinAge] = useState();
    const [maxAge, setMaxAge] = useState();
    const [genderTargets, setGenderTargets] = useState([]);
    const [maxIncomePercentage, setMaxIncomePercentage] = useState();
    const [minIncomePercentage, setMinIncomePercentage] = useState();
    const [locationTargets, setLocationTargets] = useState([]);
    const [languageTargets, setLanguageTargets] = useState([]);
    const [trackingCodes, setTrackingCodes] = useState();
    const [searchSite, setSearchSite] = useState('');

    const [lob, setLob] = useState();
    const [template, setTemplate] = useState();
    const [currNav, setCurrNav] = useState('Campaign Setup');
    const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [adSetLoader, setAdSetLoader] = useState(false)

    // Advertiser
    const [advertiser, setAdvertiser] = useState({});

    const isEditable = mediaPlan?.lob?.name;

    const campaign = useSelector(state => state.media.currentCampaign);
    const networkList = useSelector(state => state.template.templateList);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoader(true);

        dispatch(getCampaign({
            websafe,
            callback: (res) => {
                dispatch(getNetworkTemplateList(() => {
                    setLoader(false);
                }));
            }
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [websafe]);

    useEffect(() => {
        if (!campaign?.websafe) return;
        const network = networkList.find(net => net.websafe === campaign?.networkTemplateCampaign?.networkTemplate?.websafe);
        if (!campaign?.websafe || !network?.websafe) return;

        getTemplate(network?.websafe).then(res => {
            setTemplate(res);
            if (res?.advertiser?.websafe) {
                getAdvertiser({
                    websafe: res.advertiser.websafe,
                    callback: advertiserData => setAdvertiser(advertiserData)
                })
            }
        }).catch(err => console.error(err))
        if (!campaign?.adGroups?.length) setCurrNav('Campaign Setup');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign, networkList]);

    useEffect(() => {
        if (!mediaPlan?.websafe || !mediaPlan?.lob) return;

        getLob({
            lobId: mediaPlan?.lob?.websafe,
            callback: (res) => setLob(res)
        })
    }, [mediaPlan])

    // * Get Countrty and Language Options
    useEffect(() => {
        const codeKey = 'code';

        window.eulerity.makeApiCall("/api/org/getDataFile?filename=countries", 'GET', null, response => {
            let countryOptions = generateDropdownOptions(response, codeKey)
            setCountryOptions(countryOptions)
        })

        window.eulerity.makeApiCall("/api/org/getDataFile?filename=languages", 'GET', null, response => {
            let languageOptions = generateDropdownOptions(response, codeKey);
            setLanguageOptions(languageOptions)
        })
    }, [])

    useEffect(() => {
        clearCampaignFields();
        if (campaign?.name) setName(campaign?.name);
        if (campaign?.start) setStart(campaign?.start);
        if (campaign?.end) setEnd(campaign?.end);
        if (campaign?.budget) setBudget(campaign?.budget);

        if (campaign?.countryCodes?.length) setCampaignCountries(campaignCountries.length > 0 ? campaignCountries : campaign?.countryCodes);
        if (campaign?.languageCodes) setCampaignLanguages(campaign?.languageCodes);

        if (campaign?.objective) setObjective(campaign?.objective);
        if (campaign?.campaignType) setCampaignType(campaign?.campaignType);
        if (campaign?.useAdRotation) setUseAdRotation(campaign?.useAdRotation);
        if (campaign?.audienceTargeting) setAudienceTargeting(campaign?.audienceTargeting);
        if (campaign?.targetSearchNetwork) setSearchNetwork(campaign?.targetSearchNetwork);
        if (campaign?.desktopBidModifier) setDesktopBid(campaign?.desktopBidModifier);
        if (campaign?.mobileBidModifier) setMobileBid(campaign?.mobileBidModifier);
        if (campaign?.tabletBidModifier) setTabletBid(campaign?.tabletBidModifier);
        if (campaign?.targetDisplayNetwork) setDisplayNetwork(campaign?.targetDisplayNetwork);
        if (campaign?.targetSearchPartners) setSearchPartners(campaign?.targetSearchPartners);
        if (campaign?.networkTemplateCampaign?.bidStrategy) setBidStrategy(campaign?.networkTemplateCampaign?.bidStrategy)
        if (campaign?.enhancedCpc) setUseEnhancedCpc(campaign?.enhancedCpc)
        if (campaign?.cpcBidLimit) setCpcBidLimit(campaign?.cpcBidLimit)
        if (campaign?.targetCpa) setTargetCpa(campaign?.targetCpa)
        if (campaign?.targetRoas) setTargetRoas(campaign?.targetRoas)
        if (campaign?.impressionShareLocation) setAdLocation(campaign?.impressionShareLocation)
        if (campaign?.targetPercentImpressionShare) setPercentImpression(campaign?.targetPercentImpressionShare)

        campaign?.minAge ? setMinAge(campaign?.minAge) : setMinAge(lob?.minAge);
        campaign?.maxAge ? setMaxAge(campaign?.maxAge) : setMaxAge(lob?.maxAge);
        campaign?.genderTargets ? setGenderTargets(campaign?.genderTargets) : setGenderTargets(lob?.genderTargets);
        campaign?.minIncomePercentage ? setMinIncomePercentage(campaign?.minIncomePercentage) : setMinIncomePercentage(lob?.minIncomePercentage);
        campaign?.maxIncomePercentage ? setMaxIncomePercentage(campaign?.maxIncomePercentage) : setMaxIncomePercentage(lob?.maxIncomePercentage);
        campaign?.maxAge ? setMaxAge(campaign?.maxAge) : setMaxAge(lob?.maxAge);

        // inherits from LOB initially
        if (campaign?.locationTargets) setLocationTargets(campaign?.locationTargets || lob?.locationTargets);
        if (campaign?.languageTargets) setLanguageTargets(campaign?.languageTargets || lob?.languageTargets);

        if (campaign?.searchSite) setSearchSite(campaign.searchSite);

    }, [template, campaign, lob])

    let isCampaignRunnable = () => true //TODO write condition to check if campaign is runnable (ask nirma)

    const audienceType = () => {
        const networkTemplateCampaignId = campaign?.networkTemplateCampaign?.id;
        return template?.campaigns?.find(camp => camp.id === networkTemplateCampaignId)?.audienceType;
    }

    const networkCampaignName = () => {
        const networkTemplateCampaignId = campaign?.networkTemplateCampaign?.id;
        return template?.campaigns?.find(camp => camp.id === networkTemplateCampaignId)?.name;
    }

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};
            const errorMessages = [];

            const { startDate, endDate } = stringToDateconverter(start, end);

            //* REQUIRED FIELDS
            if (!start) errorMessages.push({ message: "Please select Start Date" })
            if (!end) errorMessages.push({ message: "Please select End Date" })
            if (!budget) errorMessages.push({ message: "Enter Budget to Launch Campaign" })
            if (budget < 0) errorMessages.push({ message: "Budget should be greater than zero" })
            if(!dateCheck(startDate, endDate)) errorMessages.push({message: 'End Date should be greater than Start Date'})
            const { startDate: campStartDate, endDate: campEndDate } = stringToDateconverter(campaign?.start, campaign?.end)


            if (shouldUpdateString(name, campaign?.name)) updateObj.name = name;
            if (startDate && startDate !== campStartDate) updateObj.start = startDate;
            if (endDate && endDate !== campEndDate) updateObj.end = endDate;
            if (budget && budget !== campaign?.budget) updateObj.budget = budget;

            if (campaignCountries && !isObjArraysEqual(campaignCountries, campaign?.countryCodes)) updateObj.countryCodes = campaignCountries;
            if (campaignLanguages && !isObjArraysEqual(campaignLanguages, campaign?.languageCodes)) updateObj.languageCodes = campaignLanguages;

            if (shouldUpdateString(objective, campaign?.objective)) updateObj.objective = objective;
            if (campaign?.useAdRotation !== useAdRotation) updateObj.useAdRotation = useAdRotation;
            if (campaign?.audienceTargeting !== audienceTargeting) updateObj.audienceTargeting = audienceTargeting;
            if (campaign?.targetSearchNetwork !== searchNetwork) updateObj.targetSearchNetwork = searchNetwork;
            if (campaign?.targetDisplayNetwork !== displayNetwork) updateObj.targetDisplayNetwork = displayNetwork;
            if (campaign?.targetSearchPartners !== searchPartners) updateObj.targetSearchPartners = searchPartners;
            if (campaign?.desktopBidModifier !== desktopBid) updateObj.desktopBidModifier = desktopBid;
            if (campaign?.tabletBidModifier !== tabletBid) updateObj.tabletBidModifier = tabletBid;
            if (campaign?.mobileBidModifier !== mobileBid) updateObj.mobileBidModifier = mobileBid;
            if (campaign?.targetCpa !== targetCpa) updateObj.targetCpa = targetCpa;
            if (campaign?.targetRoas !== targetRoas) updateObj.targetRoas = targetRoas;
            if (campaign?.enhancedCpc !== useEnhancedCpc) updateObj.enhancedCpc = useEnhancedCpc
            if (campaign?.cpcBidLimit !== cpcBidLimit) updateObj.cpcBidLimit = cpcBidLimit
            if (campaign?.targetPercentImpressionShare !== percentImpression) updateObj.targetPercentImpressionShare = percentImpression
            if (minAge && minAge !== campaign?.minAge) updateObj.minAge = minAge;
            if (maxAge && maxAge !== campaign?.maxAge) updateObj.maxAge = maxAge;
            if (!arraysEqual(campaign?.genderTargets, genderTargets)) updateObj.genderTargets = genderTargets;
            if (minIncomePercentage && campaign.minIncomePercentage !== minIncomePercentage) updateObj.minIncomePercentage = minIncomePercentage;
            if (maxIncomePercentage && campaign.maxIncomePercentage !== maxIncomePercentage) updateObj.maxIncomePercentage = maxIncomePercentage;
            if (!isObjArraysEqual(campaign?.languageTargets, languageTargets)) updateObj.languageTargets = languageTargets;
            if (!isObjArraysEqual(campaign?.locationTargets, locationTargets)) updateObj.locationTargets = locationTargets;
            if (minAge > maxAge) errorMessages.push({message: "Minimum age has to be less than maximum age"});
            if (minIncomePercentage > maxIncomePercentage) errorMessages.push({message: "Minimum income has to be less than maximum income"});

            if (shouldUpdateString(searchSite, campaign?.searchSite)) updateObj.searchSite = searchSite;

            // * Validation
            if (validateBidAdjustment(desktopBid) || validateBidAdjustment(mobileBid) || validateBidAdjustment(tabletBid)) return
            if (validateImpressionData(bidStrategy, cpcBidLimit)) return

            if (errorMessages.length > 0) {
                toast.error(errorMessages[0].message)
                return;
            }

            if (Object.keys(updateObj).length) {
                setLoader(true)
                dispatch(updateCampaign({
                    mediaPlanCampaign: websafe,
                    obj: updateObj,
                    callback: (_) => {
                        setLoader(false)
                        toast.success(`Updated campaign`);
                    }
                }))
            }
        }
        setIsEdit(!isEdit);
    }

    const handleModalDeleteCampaign = () => {
        setDeleteLoader(true)
        dispatch(deleteCampaign({
            mediaPlanCampaign: websafe,
            callback: () => {
                setIsDeleteCampaignModalOpen(false);
                toast.success(`Deleted campaign`);
                resetPage('campaign');
                //handle reset page after deleting campaign
                setDeleteLoader(false)
            }
        }))
    }

    const clearCampaignFields = () => {
        setName();
        setObjective();
        setUseAdRotation(false);
        setAudienceTargeting(false);
        setStart();
        setEnd();
        setBudget();
        setMinAge();
        setMaxAge();
        setGenderTargets();
        setMinIncomePercentage();
        setMaxIncomePercentage();
        setLanguageTargets([]);
        setLocationTargets([]);
        setSearchSite();
        setCampaignCountries([]);
        setCampaignLanguages([]);
        setSearchNetwork(false);
        setDesktopBid(0);
        setMobileBid(0);
        setTabletBid(0);
        setDisplayNetwork(false);
        setSearchPartners(false);
        setBidStrategy()
        setUseEnhancedCpc(false);
        setTargetCpa(0)
        setTargetRoas(0)
        setCpcBidLimit(0)
        setAdLocation('')
        setPercentImpression(0)
    }

    const navigationObj = [
        {
            name: 'Campaign Setup',
            component: <GoogleCampaignSetup
                isEdit={isEdit} handleEdit={handleEdit}
                lobName={lob?.name} templateName={template?.name}
                campaign={campaign}
                name={name} setName={setName}
                start={start} setStart={setStart}
                end={end} setEnd={setEnd}
                budget={budget} setBudget={setBudget}
                adAccount={adAccount} setAdAccount={setAdAccount}
                objective={objective} setObjective={setObjective}
                campaignType={campaignType} setCampaignType={setCampaignType}
                bidStrategy={bidStrategy} setBidStrategy={setBidStrategy}
                useAdRotation={useAdRotation} setUseAdRotation={setUseAdRotation}
                minAge={minAge} setMinAge={setMinAge}
                maxAge={maxAge} setMaxAge={setMaxAge}
                genderTargets={genderTargets} setGenderTargets={setGenderTargets}
                maxIncomePercentage={maxIncomePercentage} setMaxIncomePercentage={setMaxIncomePercentage}
                minIncomePercentage={minIncomePercentage} setMinIncomePercentage={setMinIncomePercentage}
                audienceTargeting={audienceTargeting} setAudienceTargeting={setAudienceTargeting}
                locationTargets={locationTargets} setLocationTargets={setLocationTargets}
                languageTargets={languageTargets} setLanguageTargets={setLanguageTargets}
                trackingCodes={trackingCodes} setTrackingCodes={setTrackingCodes}
                searchSite={searchSite} setSearchSite={setSearchSite}
                setCountries={setCampaignCountries} countries={campaignCountries} setLanguages={setCampaignLanguages} languages={campaignLanguages}
                countryOptions={countryOptions} languageOptions={languageOptions}
                searchNetwork={searchNetwork} setSearchNetwork={setSearchNetwork} desktopBid={desktopBid} setDesktopBid={setDesktopBid}
                mobileBid={mobileBid} setMobileBid={setMobileBid} tabletBid={tabletBid} setTabletBid={setTabletBid}
                displayNetwork={displayNetwork} setDisplayNetwork={setDisplayNetwork}
                searchPartners={searchPartners} setSearchPartners={setSearchPartners}
                targetCpa={targetCpa} setTargetCpa={setTargetCpa}
                targetRoas={targetRoas} setTargetRoas={setTargetRoas}
                useEnhancedCpc={useEnhancedCpc} setUseEnhancedCpc={setUseEnhancedCpc}
                cpcBidLimit={cpcBidLimit} setCpcBidLimit={setCpcBidLimit}
                adLocation={adLocation} percentImpression={percentImpression} setPercentImpression={setPercentImpression}
            />
        }
    ]

    if (campaign?.adGroups?.length) navigationObj.push(
        {
            name: 'Ad Group Setup',
            component: <GoogleAdGroupSetup
                campaign={campaign}
                mediaPlan={mediaPlan}
                resetPage={resetPage}
                adSetLoader={adSetLoader}
                setAdSetLoader={setAdSetLoader}
            />
        }
    )
    if(loader) return <StyleCenterElement><Loader size={50} /></StyleCenterElement>
    return (
        <StyledFieldsWrapper>
            <SubHeader>
                <HeaderIconBtnContainer>
                    Google Campaign
                    {!isCampaignRunnable() && <RedOutlineIconBtn style={{pointerEvents: 'none'}}><WarningIcon /> Unable to run Campaign </RedOutlineIconBtn>}</HeaderIconBtnContainer>
                {isEditable && <HeaderIconBtnContainer>
                    <RedOutlineIconBtn onClick={() => setIsDeleteCampaignModalOpen(true)}> <BiTrash /> Delete Campaign </RedOutlineIconBtn>
                    {currNav === 'Campaign Setup' && EditSaveBtn(isEdit, handleEdit)}
                </HeaderIconBtnContainer>}
            </SubHeader>

            {name && <Text> CAMPAIGN NAME: {name} </ Text>}

            <TemplateCard>
                <Text> <span>NETWORK TEMPLATE</span>: {template?.name} </Text>
                { networkCampaignName() && <Text> <span>NETWORK CAMPAIGN NAME</span>: {networkCampaignName()} </Text> }
                <Text> <span>NETWORK TEMPLATE TYPE</span>: Advertiser - {advertiser?.name || 'All Advertisers'} </Text>
                <div>
                    <Label> Goal : {template?.goal} </Label>
                    <Label> Priority : {template?.priority} </Label>
                </div>
                {audienceType() && <div>
                    <Label> Targeting Type: {audienceType()} </Label>
                </div>}
                {template?.description && <Text> <span>DESCRIPTION</span>: {template?.description} </Text>}
            </TemplateCard>

            <SubNavigation navigationObj={navigationObj} currNav={currNav} setCurrNav={setCurrNav} loader={loader}/>

            {
                isDeleteCampaignModalOpen &&
                <DeleteModal
                    header='Delete Campaign'
                    modalText={`Deleting ${campaign?.name} campaign`}
                    deleteBtnText='Delete Campaign'
                    setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
                    handleDelete={handleModalDeleteCampaign}
                    loading={deleteLoader}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default GoogleCampaignDetails;