import React from "react";
import CreatableSelect from "react-select";
import { joinObjectLabels } from "../../utils/arrays";
import { selectTheme } from "../themes";
import Header from "./Header";
import { StyleSelectorWrapper } from "./styles";

const Multiselect = (props) => {
  return (
    <StyleSelectorWrapper>
      <Header headerText={props.name} handleOnChange={props.handleHowItWorksModal} howToData={props.howToObject} logo={props.logo} />
      {props.isEdit ? (
        <CreatableSelect {...props} isMulti isClearable styles={{ ...selectTheme }} />
      ) : (
        <p>{joinObjectLabels(props.state) || "N/A"}</p>
      )}
    </StyleSelectorWrapper>
  );
};

export default Multiselect;
