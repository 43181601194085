import React, { useState, useEffect } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Utils
import { propertyTypeOptions, propertySourceOptions } from '../../../utils';
import { arraysEqual } from '../../../../../utils/arrays';

// Styles
import { OutlineBtn, CenterButtons, BlackBtn } from '../../../../../ui/button';
import { SubHeader, ModalTextSecondary, ModalFieldContainer, InnerModalContainer } from '../../../../../components/Modal/styles';
import { Input, Textarea } from '../../../../../ui/input/input';
import { selectTheme } from '../../../../../ui/themes';

const EditPropertyModal = ({onClose, modalData, properties, setProperties, handlePropertiesToEdit}) => {
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [source, setSource] = useState('');
    const [values, setValues] = useState([]);
    const [bulkUploadInstructions, setBulkUploadInstructions] = useState('');
    const [networkManagerInstructions, setNetworkManagerInstructions] = useState('');

    useEffect(() => {
        const { name, type, source, values, bulkUploadInstructions, networkManagerInstructions } = modalData.property;

        setName(name);
        setType(type);
        setSource(source);
        setValues(values);
        setBulkUploadInstructions(bulkUploadInstructions);
        setNetworkManagerInstructions(networkManagerInstructions);
    }, []);

    const isNewProperty = () => {
        if (!properties.length) return false;

        const result =  properties.find(property => {
            return (
                    (modalData.property.name === property.name) &&
                    (modalData.property.type === property.type) &&
                    (modalData.property.source === property.source) &&
                    (modalData.property.bulkUploadInstructions === property.bulkUploadInstructions) &&
                    (modalData.property.networkManagerInstructions === property.networkManagerInstructions)
                )
        })
        return result;
    }

    const handleUpdateProperty = () => {
        const updateObj = {};
        const newProperty = isNewProperty();

        if (newProperty) {
            const newPropertiesArray = properties.map(property => {
                if (newProperty === property) return { name, type, source, values, bulkUploadInstructions, networkManagerInstructions };
                return property;
            })

            setProperties(newPropertiesArray);
            return;
        }

        if (name !== modalData.property?.name) {
            updateObj.name = name;
            modalData.property.name = name;
        };

        if (!arraysEqual(values, modalData.property?.values)) {
            updateObj.values = values;
            modalData.property.values = values;
        };

        if (type !== modalData.property?.type) {
            updateObj.type = type;
            modalData.property.type = type;

            if (type === 'FREE_FORM') {
                updateObj.values = [];
                modalData.property.values = [];
            }
        };

        if (source !== modalData.property?.source) {
            updateObj.source = source;
            modalData.property.source = source;
        };

        if (bulkUploadInstructions !== modalData.property?.bulkUploadInstructions) {
            updateObj.bulkUploadInstructions = bulkUploadInstructions;
            modalData.property.bulkUploadInstructions = bulkUploadInstructions;
        };

        if (networkManagerInstructions !== modalData.property?.networkManagerInstructions) {
            updateObj.networkManagerInstructions = networkManagerInstructions;
            modalData.property.networkManagerInstructions = networkManagerInstructions;
        };

        handlePropertiesToEdit(modalData.property.websafe, updateObj);
    }

    const defaultSelectedValues = () => {
        return values.map(value => {
            return {
                label: value,
                value: value
            };
        });
    }

    return (
        <Modal
            onClose={onClose}
            header='Edit Property'
            style={{width: '700px'}}
        >
            <InnerModalContainer style={{margin: '10px auto'}}>
                <SubHeader> Property { name } </SubHeader>
                <ModalFieldContainer>
                    <ModalTextSecondary> Property Name <span className='asterisk'>*</span> </ModalTextSecondary>
                    <Input placeholder='Enter property name' value={name} onChange={e => setName(e.target.value)} />
                </ModalFieldContainer>

                <ModalFieldContainer>
                    <ModalTextSecondary> Type <span className='asterisk'>*</span> </ModalTextSecondary>
                    <Select
                        isClearable
                        options={propertyTypeOptions}
                        styles={selectTheme}
                        value={{label: type, value: type}}
                        defaultValue={{label: type, value: type}}
                        onChange={e => setType(e.value)}
                    />
                </ModalFieldContainer>

                <ModalFieldContainer>
                    <ModalTextSecondary> Source <span className='asterisk'>*</span> </ModalTextSecondary>
                    <Select
                        isClearable
                        options={propertySourceOptions}
                        styles={selectTheme}
                        value={{label: source, value: source}}
                        onChange={e => setSource(e.value)}
                    />
                </ModalFieldContainer>

                {type !== 'FREE_FORM' &&
                <ModalFieldContainer>
                    <ModalTextSecondary> Property Values <span className='asterisk'>*</span> </ModalTextSecondary>
                    <CreatableSelect
                        isClearable
                        isMulti
                        styles={selectTheme}
                        options={defaultSelectedValues()}
                        value={defaultSelectedValues()}
                        onChange={e => setValues(e.map(opt => opt.value))}
                    />
                </ModalFieldContainer>
                }

                <ModalFieldContainer>
                    <ModalTextSecondary> How to set property on Bulk Upload <span className='asterisk'>*</span> </ModalTextSecondary>
                    <Textarea rows={5} value={bulkUploadInstructions} onChange={e => setBulkUploadInstructions(e.target.value)} />
                </ModalFieldContainer>

                <ModalFieldContainer>
                    <ModalTextSecondary> How to set property on Network Manager <span className='asterisk'>*</span> </ModalTextSecondary>
                    <Textarea rows={5} value={networkManagerInstructions} onChange={e => setNetworkManagerInstructions(e.target.value)} />
                </ModalFieldContainer>
            </InnerModalContainer>

            {CenterButtons(
                <>
                    <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                    <BlackBtn
                        onClick={() => {
                            handleUpdateProperty();
                            onClose();
                        }}
                    > Edit Property </BlackBtn>
                </>
            )}
        </Modal>
    )
}

export default EditPropertyModal;