import styled from 'styled-components';

export const MainHeader = styled.div`
    height: 60px;
    box-shadow: 0px 2px 8px rgba(244, 182, 63, 0.15);
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: ${props => props.theme.background.white};
    padding: 16px;

    h2 {
        font-size: 25px;
    }

    svg, path {
        font-size: 38px;
    }

    path {
        fill: ${props => props.theme.color.yellow};
    }

    @media only screen and (min-width: 768px) {
        gap: 20px;

        svg {
            font-size: 48px;
        }
    }

    @media only screen and (min-width: 992px)  {
        gap: 30px;

        svg {
            font-size: 48px;
        }
    }
`;

export const PageHeader = styled.h1`
    font-family: 'GothamMedium';
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
     //? not sure if wee need this
    /* padding-bottom: 20px; */

    @media only screen and (min-width: 768px) {
        //? not sure if wee need this
        /* padding-bottom: 30px; */
    }
`;

export const PageHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid ${props => props.theme.hover.gray};
    padding-bottom: 20px;

    h1 {
        font-family: 'GothamMedium';
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 10px;
    }

    h2 {
        font-family: 'GothamBook';
        font-size: 14px;
        font-weight: 400;
    }

    span {
        color: ${props => props.theme.card.gray};
    }
`;

export const SubHeader = styled.h3`
    font-family: 'GothamMedium';
    font-weight: 500;
    color: ${props => props.theme.color.black};
    padding: 10px 0;
    display: flex;
    font-size: 16px;
    gap: 10px;
    white-space: nowrap;
    margin: 20px 0 20px 0;
    align-items: center;
    justify-content: space-between;


    button {
        font-size: 10px;
    }

    span {
        display: flex;
        align-items: center;

        svg {
            margin-right: 20px;
        }

        img {
            width: 30px;
            margin-right: 10px;
        }
    }

    .gray {
        color: ${props => props.theme.card.gray};
    }

    @media only screen and (min-width: 1000px) {
        flex-direction: row;
        font-size: 18px;

        button {
            font-size: 12px;
            padding: 0 6px;
        }
    }
`;

export const ColumnHeader = styled(SubHeader)`
    color: ${props => props.theme.card.gray};
    margin: 30px 0 0 0;
`;

export const NoTargets = styled(SubHeader)`
    color: ${props => props.theme.card.gray};
`;