import React from 'react'
import { StyledTableItem } from '../../styles'
import { BiPencil } from 'react-icons/bi'

const Platform= ({ isEdit, onEdit, platform, colored }) => {
    return (
        <StyledTableItem colored={colored}>
            <div className='each_item_info'>
                <div className='inner_wrapper'>
                    <p className='inner_text'>
                        <span className='inner_header'>Platform: </span>
                        {platform?.key}
                    </p>
                </div>
                <div className='inner_wrapper'>
                    <p className='inner_text'>
                        <span className='inner_header'>Abbrevation: </span>
                        {platform?.value}
                    </p>
                </div>
            </div>
            {
                isEdit && (
                    <div className="each_item_buttons">
                        <div className='each_item_icon'>
                            <BiPencil size="19" onClick={onEdit} />
                        </div>
                    </div>
                )
            }
        </StyledTableItem>
    )
}

export default Platform