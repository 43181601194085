import React, { useState } from 'react';
import ArchivePropertyModal from '../modals/ArchivePropertyModal';
import EditPropertyModal from '../modals/EditPropertyModal';

// Utils
import { normalizeUnderscoreString } from '../../../../../utils/strings';
import HowToSetPropertyModal from '../modals/HowToSetPropertyModal';

// Styles
import { PropertiesContainer, NoPropterties, PropertyHeader, PropertyValue, PropertyValueContainer } from '../styles';
import { BiPencil, BiArchiveIn } from 'react-icons/bi';

const Properties = ({isDummy, properties, setProperties, currProperties, isEdit, handlePropertiesToEdit}) => {
    const [isHowToSetPropertyModalOpen, setIsHowToSetPropertyModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    if (!isDummy) return <NoPropterties> <p> No properties can be set for API supported Networks </p> </NoPropterties>;

    const combinedAndFilteredProperties = [...currProperties, ...properties].filter(property => !property.archived);

    const handleModal = (data, setModalFunction) => {
        setModalFunction(true);
        setModalData(data);
    }

    const hasProperties = properties?.length;
    const hasCurrProperties = currProperties?.length;
    const isAllArchived = () => {
        if (!hasCurrProperties) return false;
        return !currProperties.find(property => property.archived === false);
    }

    const renderProperties = () => {
        if (!hasProperties && !hasCurrProperties || isAllArchived()) return <NoPropterties> <p> No properties currently </p> </NoPropterties>

        return combinedAndFilteredProperties.map((property, idx) => {
            if (property?.archived) return;

            return (
                <PropertiesContainer key={idx} index={idx}>
                    <PropertyValue> {property?.name} </PropertyValue>
                    <PropertyValue> {normalizeUnderscoreString(property?.type) || 'N/A'} </PropertyValue>
                    <PropertyValue> {normalizeUnderscoreString(property?.source) || 'N/A'} </PropertyValue>
                    <PropertyValue> {property?.values?.join(', ') || 'N/A'} </PropertyValue>
                    <PropertyValueContainer>
                        <div>
                            <p> View how to set on </p>
                            {property?.bulkUploadInstructions && <li onClick={() => handleModal({
                                name: property?.name,
                                type: 'Bulk Upload Sheet',
                                text: property?.bulkUploadInstructions
                            }, setIsHowToSetPropertyModalOpen
                            )}> Bulk Upload Sheet </li>}
                            {property?.networkManagerInstructions && <li onClick={() => handleModal({
                                name: property?.name,
                                type: 'Network Manager',
                                text: property?.networkManagerInstructions
                            }, setIsHowToSetPropertyModalOpen
                            )}> Network Manager </li>}
                        </div>
                        {isEdit &&
                            <div className='edit_archive'>
                                <BiPencil onClick={() => handleModal({
                                    property,
                                }, setIsEditModalOpen
                                )}/>
                                <BiArchiveIn onClick={() => handleModal(
                                    property,
                                    setIsArchiveModalOpen
                                )}/>
                            </div>
                        }
                    </PropertyValueContainer>
                </PropertiesContainer>
            )
        })
    }

    return (
        <>
        <PropertiesContainer className='headers'>
            <PropertyHeader> PROPERTY NAME </PropertyHeader>
            <PropertyHeader> TYPE </PropertyHeader>
            <PropertyHeader> SOURCE </PropertyHeader>
            <PropertyHeader> VALUES </PropertyHeader>
            <PropertyHeader> HOW TO SET PROPERTY </PropertyHeader>
        </PropertiesContainer>
        {renderProperties()}
        {
            isHowToSetPropertyModalOpen &&
            <HowToSetPropertyModal
                onClose={() => setIsHowToSetPropertyModalOpen(false)}
                modalData={modalData}
            />
        }
        {
            isArchiveModalOpen &&
            <ArchivePropertyModal
                onClose={() => setIsArchiveModalOpen(false)}
                modalData={modalData}
                properties={properties}
                setProperties={setProperties}
                handlePropertiesToEdit={handlePropertiesToEdit}
            />
        }
        {
            isEditModalOpen &&
            <EditPropertyModal
                onClose={() => setIsEditModalOpen(false)}
                modalData={modalData}
                properties={properties}
                setProperties={setProperties}
                handlePropertiesToEdit={handlePropertiesToEdit}
            />
        }
        </>
    )
}

export default Properties;