import React from "react";
import firebase from "firebase";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../../actions/userActions";

import { APP_PATH } from "../../constants/path";

// Styling
import { SidebarContainer, LinkText, LogoContainer, ImgLogo, StyledLink, SidebarSettings, SignoutContainer, NameText, EmailText, LabelContainer, StyledLinkContainer } from "./styles";

// Images
import { serveImage } from "../../pages/systemConfig/components/labelManagement/label.utils";


// React icons
//HiHome
import { HiClipboardList, HiTemplate, HiCog } from "react-icons/hi";
import { IoIosPaper } from "react-icons/io";
import { MdOutlineLogout } from "react-icons/md";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const user = useSelector(state => state.user);

  const orgLogo = useSelector(state => state.org.organization?.logo);
  const dispatch = useDispatch();

  const handleSignOut = () => {
    firebase.auth().signOut();
    dispatch(clearUser());
  };

  return (
    <SidebarContainer>
      <LogoContainer>
      {orgLogo ?  <ImgLogo src={serveImage(orgLogo)} alt="logo" /> : <div style={{width: '136px',height:'58px'}} />}
      </LogoContainer>
      <StyledLinkContainer>
        {/* <StyledLink to={`${APP_PATH}/dashboard`}>
          <HiHome size={25} />
          <LinkText> Dashboard </LinkText>
        </StyledLink> */}
        <StyledLink to={`${APP_PATH}/plans`}>
          <HiClipboardList size={25} />
          <LinkText> Media Plans </LinkText>
        </StyledLink>
        <StyledLink to={`${APP_PATH}/templates`}>
          <HiTemplate size={25} />
          <LinkText> Templates </LinkText>
        </StyledLink>
        <StyledLink to={`${APP_PATH}/lob`}>
          <IoIosPaper size={25} />
          <LinkText> Line of Business </LinkText>
        </StyledLink>
      </StyledLinkContainer>
      <SidebarSettings>
        {
          (user.role === "SUPER_ADMIN" || user.role === "ADMIN") && (
            <LabelContainer>
            <Link className="style-link" to={`${APP_PATH}/systemConfig`}>
              <HiCog size={25} />
              <LinkText> System Config </LinkText>
            </Link>
        </LabelContainer>
          )
        }
        <SignoutContainer>
          <div className="userInfo__container">
            <NameText> {user.name} </NameText>
            <EmailText> {user.email} </EmailText>
          </div>
          <MdOutlineLogout size={25} onClick={handleSignOut} />
        </SignoutContainer>
      </SidebarSettings>
    </SidebarContainer>
  );
};

export default Sidebar;
