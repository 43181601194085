import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../../../../components/Modal/modals/Modal';
import Loader from '../../../../../ui/Loader';

// Utils
import useCsv from '../../../../../hooks/useCsv';
import { serveImage } from '../../labelManagement/label.utils';
import { uploadBlob, getBlobFile } from '../../../../../utils';

// Redux
import { useDispatch } from 'react-redux';
import { createAdvertiser, getAdvertiserList, updateAdvertiser } from '../../../../../actions/systemConfigActions';

// Styles
import { ModalFieldContainer, ModalTextSecondary, ListBarItem } from '../../../../../components/Modal/styles';
import { Input, Textarea } from '../../../../../ui/input/input';
import { CsvSelectContainer } from '../../../../../ui/table/styles';
import { BlackBtn, OutlineBtn, BtnWithLoader } from '../../../../../ui/button';
import { TbCircleMinus } from 'react-icons/tb';
import { BsUpload } from 'react-icons/bs';
import { toast } from 'react-toastify';

const AdvertiserModal = ({onClose, isEditAdvertiserModalOpen, editData, setEditData}) => {
    const [advertiserObj, setAdvertiserObj] = useState({});

    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isDocumentLoading, setIsDocumentLoading] = useState(false);
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    const [dataToUpdate, setDataToUpdate] = useState({});

    const CsvUploader = useCsv();
    const imgInputRef = useRef(null);
    const csvInputRef = useRef(null);

    const [logoData, setLogoData] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        if (editData) {
            setAdvertiserObj(editData);
            setDataToUpdate({websafe: editData.websafe})

            if (editData.websafe && editData.logo) getBlobFile(editData.logo, (res) => setLogoData(res));
        } else {
            setAdvertiserObj({...advertiserObj, name: '', description: '', documents: [], logo: ''});
        }
    }, [editData])

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (editData.websafe) {
                setDataToUpdate({
                    ...dataToUpdate,
                    [name]: value
            })
        }

        setAdvertiserObj({...advertiserObj, [name]: value});
    }

    const renderSelectedFiles = (selectedFiles) => {
        return selectedFiles?.map((file, idx) => {
            return (
                <ListBarItem key={idx}> {file?.filename}
                    <TbCircleMinus size={18} onClick={(e) => handleRemoveDocument(e, file)}/>
                </ListBarItem>
            )
        })
    }

    const handleSave = () => {
        if (editData) {
            dispatch(updateAdvertiser({
                websafe: advertiserObj.websafe,
                advertiserObj: dataToUpdate,
                callback: (res) => {
                    toast.success(`Successfully updated ${res.name} advertiser`)
                    dispatch(getAdvertiserList({errorCallback: _ => {
                        toast.error('Something went wrong fetching Advertisers');
                    }}))
                },
                errorCallback: (err) => {
                    toast.error('Something went wrong');
                    console.error(err)
                }
            }))
        }
        handleClose();
    }

    const CreateNow = () => {
        setIsBtnLoading(true);
        const newAdvertiserObj = {...advertiserObj};
        if (newAdvertiserObj?.logo?.length) newAdvertiserObj.logo = newAdvertiserObj.logo[0].blobkey;

        dispatch(createAdvertiser({
            advertiserObj: newAdvertiserObj,
            callback: (_) => {
                setIsBtnLoading(false);
                onClose();
                dispatch(getAdvertiserList({errorCallback: _ => {
                    toast.error('Something went wrong fetching Advertisers');
                }}))
            },
            errorCallback: () => {
                setIsBtnLoading(false);
                toast.error('Something went wrong creating an Advertiser.');
                onClose();
            }
        }))
    }

    const handleRemoveDocument = (e, file) => {
        const updatedDocumentsList = advertiserObj?.documents.filter(document => document.websafe !== file.websafe);
        const websafeList = updatedDocumentsList.map(item => item.websafe);
        setAdvertiserObj({...advertiserObj, documents: updatedDocumentsList});
        setDataToUpdate({
            ...dataToUpdate,
            documents: websafeList
        });
        e.stopPropagation();
    }

    const handleRemoveLogo = e => {
        setAdvertiserObj({...advertiserObj, logo: []});
        setLogoData({});
        e.stopPropagation();
    }

    const handleClose = () => {
        onClose();
        setEditData({});
    }

    const ConfirmButton = isEditAdvertiserModalOpen ?
        <BlackBtn onClick={handleSave} key={2}> Update Advertiser </BlackBtn> :
        <BtnWithLoader onClick={CreateNow} loader={isBtnLoading} type='blackBtn' btnText='Create Advertiser' color='white' key={2} />

    const buttonsArray = [
        <OutlineBtn onClick={handleClose} key={1}> Cancel </OutlineBtn>,
        ConfirmButton
    ]

    const renderLogo = () => {
        if (isLogoLoading) return <Loader />;
        if (!logoData.blobkey && !advertiserObj?.logo) return <BsUpload size={50}/>;
        if (isEditAdvertiserModalOpen && advertiserObj.logo.length) {
            let logoData = advertiserObj?.logo[0]?.blobkey ? advertiserObj?.logo[0]?.blobkey : advertiserObj?.logo;
            return <img src={serveImage(logoData)} alt='logo' />;
        }
        if (advertiserObj?.logo?.length === 1) return <img src={serveImage(advertiserObj?.logo[0]?.blobkey)} alt='logo' />;

        return <BsUpload size={50}/>
    }

    return (
        <Modal
            onClose={handleClose}
            header={`${isEditAdvertiserModalOpen ? 'Edit' : 'Add'} Advertiser`}
            width='650px'
            height='98vh'
            buttons={buttonsArray}
        >
            <ModalFieldContainer>
                <ModalTextSecondary> Advertiser Name <span className='asterisk'>*</span> </ModalTextSecondary>
                <Input onChange={handleInputChange} type='text' name='name' defaultValue={advertiserObj.name} placeholder='Please enter advertiser name' />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> Advertiser Logo </ModalTextSecondary>
                <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(imgInputRef)}>
                {renderLogo()}
                <br />
                {logoData?.filename && <ListBarItem> {logoData?.filename}
                    <TbCircleMinus size={18} onClick={(e) => handleRemoveLogo(e)}/>
                </ListBarItem>}
                </CsvSelectContainer>
                <input
                    type='file'
                    hidden
                    accept='image/png, image/jpeg, image/svg'
                    onChange={(e) => {
                        setIsLogoLoading(true);
                        uploadBlob(
                            {
                                file: e.target.files[0],
                                callback: (file) => {
                                    setIsLogoLoading(false);
                                    setAdvertiserObj({...advertiserObj, logo: [file]});
                                    setDataToUpdate({...dataToUpdate, logo: file.blobkey})
                                    setLogoData(file)
                                }
                            }
                        )
                    }}
                    ref={imgInputRef}
                />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> Description </ModalTextSecondary>
                <Textarea onChange={handleInputChange} type='text' name='description' row={5} value={advertiserObj.description} placeholder='Please enter description' />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> Upload Advertiser Documents </ModalTextSecondary>
                <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(csvInputRef)}>
                {isDocumentLoading ? <Loader /> : <BsUpload size={50}/>}
                <br />
                {renderSelectedFiles(advertiserObj?.documents)}
                </CsvSelectContainer>
                <input
                    type='file'
                    hidden
                    accept='.csv'
                    onChange={(e) => {
                        setIsDocumentLoading(true);
                        uploadBlob(
                            {
                                file: e.target.files[0],
                                callback: (file) => {
                                    setIsDocumentLoading(false);
                                    const documentList = advertiserObj?.documents?.length > 1 ? [...advertiserObj.documents, file] : [file];
                                    setAdvertiserObj({...advertiserObj, documents: documentList});
                                    if (editData.websafe) {
                                        const oldDocumentsArr = editData.documents.map(document => document.websafe);
                                        setDataToUpdate({...dataToUpdate, documents: [...oldDocumentsArr, file.websafe]})
                                    }
                                }
                            }
                        )
                    }}
                    ref={csvInputRef}
                />
            </ModalFieldContainer>
        </Modal>
    )
}

export default AdvertiserModal;