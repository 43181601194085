import React, { useState, useEffect } from "react";
import { CSVDownload } from "react-csv";
import useOauth from "../../../../hooks/useOauth";
import { toast } from "react-toastify";
import { dummyNetworkImage } from "../../../systemConfig/components/labelManagement/label.utils";
import { serveImage } from "../../../systemConfig/components/labelManagement/label.utils";
import { STATUS, generateStatusText } from "../../../../ui/statusTag/status.utils";

// * Redux
import { exportBudgetGrid, exportTacticalPlan, exportAudienceLibrary, getMediaPlan, exportGoogleSheets } from "../../../../actions/mediaActions";
import { getUser } from "../../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";

// * Styles and Components
import Modal from "../../../../components/Modal/Modal";
import GoogleIcon from "../../../../assets/images/socialMediaIcons/GoogleIcon.png";
import FbIcon from "../../../../assets/images/socialMediaIcons/FbIcon.png";
import LinkedInIcon from "../../../../assets/images/socialMediaIcons/LinkedInIcon.png";
import CampaignItem from "../CampaignItem";
import Loader from "../../../../ui/Loader";
import { OutlineIconBtn, BlackBtn, GoogleButton } from "../../../../ui/button";
import { SocialLaunchContainer, SocialLaunchContainerHeader, ExportBtnContainer, StyleShowGoogleSheetsLink, MediaPlanStatus } from "../../styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CenterElement from "../../../../ui/CenterElement";
import DummyLaunchLinkModal from "./dummyNetworkLaunchModals/DummyLaunchLinkModal";
import MediaPlanReviewModal from "./MediaPlanReviewModal";
import StatusTag from "../../../../ui/statusTag/StatusTag";

const LaunchMediaPlan = ({ onClose }) => {
  const [mediaPlan, setMediaPlan] = useState({})
  const [loader, setLoader] = useState(false);
  const [isGoogleSelected, setIsGoogleSelected] = useState(false);
  const [isFbSelected, setIsFbSelected] = useState(false);
  const [isLiSelected, setIsLiSelected] = useState(false);
  const [isDummySelected, setIsDummySelected] = useState({});
  const [budgetGrid, setbudgetGrid] = useState([]);
  const [tacticalPlan, setTacticalPlan] = useState([]);
  const [audienceLibrary, setAudienceLibrary] = useState([]);
  const [isExport, setIsExport] = useState(false);
  const [dummyNetworks, setDummyNetworks] = useState({});

  const [selectedCampaignURLS, setSelectedCampaignURLS] = useState({})
  const [network, setNetwork] = useState("")

  const [dummyNetworkLaunchModal, setDummyNetworkLaunchModal] = useState(false)
  const [sendForReviewModal, setSendForReviewModal] = useState(false)

  const [bulkUploadSheets, setbulkUploadSheets] = useState({})
  const [bulkUploadUrls, setBulkUploadUrls] = useState({})


  const hasSheetsConnection = useSelector((state) => state.user.hasSheetsConnection);
  const dummyNetworksList = useSelector(state => state.org.organization.dummyNetworks);
  const mediaPlanWebsafe = useSelector((state) => state.media.currentMediaPlan.websafe);
  const sheetsId = useSelector(state => state.media.currentMediaPlan?.sheetsId);
  const sheetsUrl = useSelector(state => state.media.currentMediaPlan?.sheetsUrl);

  //* We are implementing this approach because the campaign object currently lacks the campaignUrl property, which helps avoid unnecessary API calls. To collect this data, we use the setSelectedCampaignURLs function within the CampaignItem component. Although this method is not the most optimal, I have discussed the issue with Vincent, who confirmed that the backend will soon include all necessary campaign properties within the campaign object. This update will eliminate the need for additional data fetching to retrieve specific fields.
  const injectUrls = (urls, campaigns) => {
	return campaigns.map(camp => {
	  if(urls[camp.websafe]) camp.campaignUrl = urls[camp.websafe]
	  return camp
	})
  }

  const _D = useDispatch();
  const {onFocusBack,  handleOAuth } = useOauth("sheets");
	  useEffect(() => {
		  if(onFocusBack) _D(getUser({notFoundCallback: () => {}}))
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [onFocusBack])

	  useEffect(() => {
		if (mediaPlanWebsafe) {
		  getMediaPlan({
			mediaPlanWebsafe,
			callback: (obj) => {
			  setMediaPlan(obj);
			},
		  });
		}
	  }, [mediaPlanWebsafe]);

  useEffect(() => {
	if(!mediaPlan) return
	exportBudgetGrid({
	  websafe: mediaPlanWebsafe,
	  callback: (res) => {
		setbudgetGrid(res);
	  },
	});

	exportTacticalPlan({
	  websafe: mediaPlanWebsafe,
	  callback: (res) => {
		setTacticalPlan(res);
	  },
	});

	exportAudienceLibrary({
	  websafe: mediaPlanWebsafe,
	  callback: (res) => {
		setAudienceLibrary(res);
	  },
	});

	const dummyObj = {};
	const dummySelectObj = {};
	const campaigns = mediaPlan?.campaigns;

	for (const campaign in campaigns) {
	  const currCampaign = campaigns[campaign];
	  const currNetwork = currCampaign.network;
	  if (currNetwork !== 'google' && currNetwork !== 'facebook' && currNetwork !== 'linkedin') {
		if (currNetwork in dummyObj) {
		  dummyObj[currNetwork] = [...dummyObj[currNetwork], currCampaign];
		} else {
		  dummyObj[currNetwork] = [currCampaign];
		  dummySelectObj[currNetwork] = false;
		}
	  }
	}
	setDummyNetworks(dummyObj);
	setIsDummySelected(dummySelectObj);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaPlan]);

  const googleCampaigns = () => {
	const filteredCampaigns = mediaPlan?.campaigns?.filter((campaign) => campaign.network === "google");
	return (
	  <SocialLaunchContainer key='google'>
		<SocialLaunchContainerHeader onClick={() => setIsGoogleSelected(!isGoogleSelected)}>
		  <h2>
			<img src={GoogleIcon} alt="icon" /> Google Campaigns
		  </h2>
		  {isGoogleSelected ? <IoIosArrowDown /> : <IoIosArrowUp />}
		</SocialLaunchContainerHeader>
		{isGoogleSelected &&
		  filteredCampaigns?.length > 0 &&
		  filteredCampaigns?.map((campaign) => {
			return <CampaignItem websafe={campaign?.websafe} key={campaign?.websafe} setIsSelected={setIsGoogleSelected} adSetGroup="Ad Group"/>;
		  })}
	  </SocialLaunchContainer>
	);
  };

  const facebookCampaigns = () => {
	const filteredCampaigns = mediaPlan?.campaigns?.filter((campaign) => campaign.network === "facebook");

	return (
	  <SocialLaunchContainer key='facebook'>
		<SocialLaunchContainerHeader onClick={() => setIsFbSelected(!isFbSelected)}>
		  <h2>
			<img src={FbIcon} alt="icon" /> Facebook Campaigns
		  </h2>
		  {isFbSelected ? <IoIosArrowDown /> : <IoIosArrowUp />}
		</SocialLaunchContainerHeader>
		{isFbSelected &&
		  filteredCampaigns?.length > 0 &&
		  filteredCampaigns.map((campaign) => {
			return <CampaignItem websafe={campaign?.websafe} key={campaign?.websafe} setIsSelected={setIsFbSelected}/>;
		  })
		}
	  </SocialLaunchContainer>
	);
  };

  const linkedInCampaigns = () => {
	const filteredCampaigns = mediaPlan?.campaigns?.filter((campaign) => campaign.network === "linkedin");
	return (
	  <SocialLaunchContainer key='linkedin'>
		<SocialLaunchContainerHeader onClick={() => setIsLiSelected(!isLiSelected)}>
		  <h2>
			<img src={LinkedInIcon} alt="icon" /> LinkedIn Campaigns
		  </h2>
		  {isLiSelected ? <IoIosArrowDown /> : <IoIosArrowUp />}
		</SocialLaunchContainerHeader>
		{isLiSelected &&
		  filteredCampaigns?.length > 0 &&
		  filteredCampaigns?.map((campaign) => {
			return <CampaignItem websafe={campaign?.websafe} key={campaign?.websafe} setIsSelected={setIsLiSelected} campaignHeader = 'Campaign Group' adSetGroup = 'Campaign'/>;
		  })}
	  </SocialLaunchContainer>
	);
  };

  const dummyCampaigns = () => {
	const dummyNetworkContainers = [];
	for (const network in dummyNetworks) {
	  const campaigns = dummyNetworks[network];

	  dummyNetworkContainers.push(
		<SocialLaunchContainer onClick={() => handleDummySelect(network)} key={network}>
		  <SocialLaunchContainerHeader>
			<h2>
			  <img src={dummyNetworkImage(network, dummyNetworksList)} alt="icon" /> {network}
			</h2>
			{isDummySelected[network] ? <IoIosArrowDown /> : <IoIosArrowUp />}
		  </SocialLaunchContainerHeader>
		  {isDummySelected[network] && (
			<div className="dummyNetworkHeader" >
			  <div className="dummyNetworkHeader-texts">
				 {mediaPlan?.bulkUploadUrls?.[network] && <div className="dummyNetworkHeader-text"><p>Bulk Upload Sheet url:</p> {mediaPlan.bulkUploadUrls[network]}</div>}
				 {mediaPlan?.bulkUploadSheets?.[network]?.filename && <div className="dummyNetworkHeader-text"><p>Bulk Upload CSV:</p><a id='download-url' href={serveImage(mediaPlan.bulkUploadSheets[network].blobkey)} download>{mediaPlan?.bulkUploadSheets?.[network]?.filename}</a></div>}
			  </div>
				<div onClick={setDummyNetworkLaunchModal.bind(null, true)}>
					<BlackBtn style={{width: "max-content"}}>Link Campaign Sheet</BlackBtn>
				</div>
			</div>
			)
		  }
		  {isDummySelected[network] &&
			campaigns?.length > 0 &&
			campaigns.map((campaign) => {
			  return <CampaignItem setSelectedCampaignURLS={setSelectedCampaignURLS} websafe={campaign?.websafe} key={campaign?.websafe} onClose={onClose}/>;
			})}
		</SocialLaunchContainer>
	  )
	}

	return (
	  <>
		{dummyNetworkContainers}
	  </>
	)
  }

	const handleDummySelect = (network) => {
		const currIsDummySelected = {...isDummySelected};
		currIsDummySelected[network] = !currIsDummySelected[network];
		setIsDummySelected(currIsDummySelected)
		setNetwork(network)
	}

  	const handleExportGoogleSheets = () => {
		setLoader(true);

		_D(exportGoogleSheets({
			websafe: mediaPlanWebsafe,
			callback: () => {
				toast.success('Successfully exported Google Sheets');
				setLoader(false);
			},
			errorCallback: () => {
				toast.error('Something went wrong unable to export Google Sheets');
				setLoader(false);
			}
		}))
	}

  if(!Object.keys(mediaPlan)?.length) return  (
	   <Modal header="Launch Media Plan" onClose={onClose} style={{ width: "1000px", height: '280px' }}>
		<CenterElement height='200px' width='100%'>
			<Loader size={30}/>
		</CenterElement>
		</Modal>
  )

  return (
	<>
	  <Modal header="Launch Media Plan" onClose={onClose} style={{width: '1000px'}}>
		{
		  mediaPlan?.approval &&
		  <MediaPlanStatus>
			<StatusTag data={mediaPlan} />
			{ mediaPlan?.approval?.status !== STATUS.DRAFT &&
			  <p>{generateStatusText(mediaPlan)}</p>
			}
		  </MediaPlanStatus>
		}
		<ExportBtnContainer>
		  <OutlineIconBtn style={{width: 'fit-content'}} onClick={() => setSendForReviewModal(true)}>Send Media Plan for Review</OutlineIconBtn>
		  <OutlineIconBtn onClick={() => setIsExport(true)}>Export Media Plan CSV</OutlineIconBtn>
		  {isExport && (
			<>
			  {budgetGrid?.length > 0 && <CSVDownload data={budgetGrid} target="_blank" />}
			  {audienceLibrary?.length > 0 && <CSVDownload data={audienceLibrary} target="_blank" />}
			  {tacticalPlan?.length > 0 && <CSVDownload data={tacticalPlan} target="_blank" />}
			</>
		  )}
		  {!hasSheetsConnection ?
			<GoogleButton text='Sign in with Google Sheets' onClick={handleOAuth} icon={GoogleIcon}/> :
			<GoogleButton text='Export to Google Sheets' onClick={handleExportGoogleSheets} icon={GoogleIcon} loader={loader} />
		}
		</ExportBtnContainer>
		{sheetsId && <StyleShowGoogleSheetsLink><a href={sheetsUrl} target="_blank" rel="noreferrer">Link to Google Sheet</a></StyleShowGoogleSheetsLink>}
		{facebookCampaigns()}
		{googleCampaigns()}
		{linkedInCampaigns()}
		{dummyCampaigns()}
	  </Modal>
	  {dummyNetworkLaunchModal &&(
		<DummyLaunchLinkModal
		  onClose={setDummyNetworkLaunchModal.bind(null, false)}
		  dummyNetworksCampaigns={injectUrls(selectedCampaignURLS, dummyNetworks[network])}
		  setbulkUploadSheets={setbulkUploadSheets}
		  setBulkUploadUrls={setBulkUploadUrls}
		  bulkUploadSheets={bulkUploadSheets}
		  bulkUploadUrls={bulkUploadUrls}
		  mediaPlan={mediaPlan}
		  network={network}
		  setMediaPlan={setMediaPlan}
		  />
		)}
		{
		  sendForReviewModal &&
		  <MediaPlanReviewModal
			mediaPlan={mediaPlan}
			onClose={() => setSendForReviewModal(false)}
		  />
		}
	  </>
  );
};

export default LaunchMediaPlan;
