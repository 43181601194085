export const themes = {
    color: {
        yellow: '#F8B133',
        darkYellow: '#F0C481',
        mediumYellow: '#F2D7B0',
        black: '#1D1D1B',
        gray: '#B3B0AC',
        mediumGray: '#93918F',
        red: '#CF1100',
        green: '#1ABF80',
        white: '#FFFFFF'
    },
    networks: {
        facebook: '#3578E9',
        google: "#FFFFFF",
    },
    background: {
        white: '#FFFFFF',
        black: '#000000',
        gray: '#F7F7F7',
        lightGray: '#FAFAFA',
        lightOrange: '#F8F3EB',
        hover: '#E5E5E5',
        boxShadow: 'rgba(244, 182, 63, 0.15)',
    },
    hover: {
        yellow: '#F1C381',
        black: 'rgba(0, 0, 0, 0.7)',
        gray: '#D9D9D8',
        red: '#FF6759',
        disabledGray: 'rgba(0, 0, 0, 0.06)'
    },
    card: {
        gray: '#717171',
        yellow: '#F4B63F',
    },
    input: {
        gray: '#C2C2C2',
    },
    modal: {
        gray: '#424242',
        boxShadow: 'rgba(100, 100, 111, 0.1)',
        overlay: 'linear-gradient(180deg, rgba(196, 196, 196, 0.66) 0%, rgba(244, 182, 63, 0.1725) 99.94%)',
    },
    table: {
        white: '#FDFDFD',
        gray: '#F7F7F7'
    },
    toast: {
        primary:"radial-gradient(121.88% 15046.3% at -21.88% -20.14%, #87A2FF 0%, #D8D8D8 100%)",
        success: "radial-gradient(121.88% 15046.3% at -21.88% -20.14%, #BDF9E2 0%, #5CEEB7 64.01%, #1ABF80 100%)",
        warning: "radial-gradient(121.88% 15046.3% at -21.88% -20.14%, #F5C15C 38.02%, #EB7066 100%);",
        error:"radial-gradient(121.88% 15046.3% at -21.88% -20.14%, #FFB7B1 0%, #EB7066 64.01%, #EA5E53 100%)",
    },
}

export const selectTheme = {
    control: (styles, state) => ({
        ...styles,
        width: '100%',
        overflow: 'scroll',
        boxShadow: state.isFocused ? `0 0 0 1px ${themes.color.yellow}` : styles.boxShadow,
        borderColor: state.isFocused ? themes.color.yellow : styles.borderColor,

        '&:hover': {
            borderColor: themes.color.yellow
        }
    }),
    menuList: (styles) => ({
        ...styles,
        padding: '0px'
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 9999,
        position: 'fixed',
    }),
    option: (styles, state) => {
        return (
            {
                ...styles,
                fontFamily: 'GothamBook',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                backgroundColor: state.isSelected && themes.color.yellow,
                color: 'black',


                "&:hover": {
                    cursor: 'pointer',
                    background: !state.isSelected && themes.color.mediumYellow
                },

                "&:active": {
                    backgroundColor: themes.color.darkYellow
                }
            }
        )
    },
    singleValue: (styles, { data }) => {
        return (
            {
                ...styles,
                fontFamily: 'GothamBook',
                fontWeight: 400,
                fontSize: '14px'
            }
        )
    },
    multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: themes.color.yellow,
          borderRadius: '6px',
          padding: '5px 10px',
        };
    },
    valueContainer: (styles) => ({
        ...styles,
    }),
}

// * Modal Variant of react-select styles
export const selectThemeModal = {
    control: (styles, state) => ({
        ...styles,
        width: '100%',
        overflow: 'scroll',
        boxShadow: state.isFocused ? `0 0 0 1px ${themes.color.yellow}` : styles.boxShadow,
        borderColor: state.isFocused ? themes.color.yellow : styles.borderColor,

        '&:hover': {
            borderColor: themes.color.yellow
        }
    }),
    placeholder: (styles) => ({
        ...styles,
        fontSize: '14px'
    }),
    menu: (styles) => ({
        ...styles,
        position: 'static',
    }),
    menuList: (styles) => ({
        ...styles,
        padding: '0px'
    }),
    option: (styles, state) => {
        return (
            {
                ...styles,
                fontFamily: 'GothamBook',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                backgroundColor: state.isSelected && themes.color.yellow,
                color: 'black',


                "&:hover": {
                    cursor: 'pointer',
                    background: !state.isSelected && themes.color.mediumYellow
                },

                "&:active": {
                    backgroundColor: themes.color.darkYellow
                }
            }
        )
    },
    singleValue: (styles, { data }) => {
        return (
            {
                ...styles,
                fontFamily: 'GothamBook',
                fontWeight: 400,
                fontSize: '14px'
            }
        )
    },
    multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: themes.color.yellow,
          borderRadius: '6px',
          padding: '5px 10px',
        };
    },
}

// Unused themes from Figma
// darkYellow: '#F0C481',
// mediumYellow: '#F2D7B0',
// lightYellow: '#F6E6C9',
// lightestYellow: '#F6E6C9'
// mediumGray: '#93918F',
// lightGreen: '#40E5A6',


