import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import CheckBox from '../../../../ui/input/checkbox/CheckBox'

const AdRotation = ({ googleIcon, isEdit, useAdRotation, setUseAdRotation }) => {
    return (
        <FieldContainer>
            <h3> {googleIcon} Ad Rotation </h3>
            <CheckBox
                checked={useAdRotation}
                callback={() => setUseAdRotation(!useAdRotation)}
                isDisabled={!isEdit}
                label={'Optimize: Prefer Best performing ads'}
            />
        </FieldContainer>
    )
}

export default AdRotation