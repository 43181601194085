import styled, {css} from 'styled-components';

// * TOGGLE BODY
const TOGGLE_BODY_MEDIUM = css`
    width: 56px;
    height: 28px;
`
const TOGGLE_BODY_SMALL = css`
    width: 45px;
    height: 24px;
`
export const StyledToggle = styled.div`
    background-color: ${ ({ isOn }) => !isOn ? '#CF1100' : ' #1fdd95'};;
    border-radius: 60px;
    cursor: pointer;
    overflow: hidden; /* Prevents lock and unlock from being seen */
    position: relative;
    transition: all 0.25s linear;
    ${({ size }) => {
        switch(size) {
            case 'md': {
                return TOGGLE_BODY_MEDIUM
            }
            case 'sm': {
                return TOGGLE_BODY_SMALL
            }
            default: {
                return TOGGLE_BODY_MEDIUM
            }
        }
    }}
`

// * TOGGLE CIRCLE BUTTON
const beforeAfterToggleStyles = css`
    font-family: 'Material Icons';
    font-weight: 400;
    color: #000000;
    opacity: 0.6;
    -webkit-font-smoothing: antialiased;
    position: absolute;
`
const TOGGLE_CIRCLE_MEDIUM = css`
    position: absolute;
    left: 18%;
    top: 50%;
    transform: translate(-30%,-50%);
    height: 20px;
    width: 20px;
    &:after {
        ${beforeAfterToggleStyles}
        font-size: 21px;
        left: -31px;
        top: -5px;
    }
    &:before {
        ${beforeAfterToggleStyles}
        font-size: 21px;
        right: -30px;
        top: -5px;
    }
`
const TOGGLE_CIRCLE_SMALL = css`
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    &:after {
        ${beforeAfterToggleStyles}
        content: 'pause_circle_outline';
        font-size: 16px;
        left: -20px;
        top: -5px;
    }
    &:before {
        ${beforeAfterToggleStyles}
        content: 'play_circle_outline';
        font-size: 16px;
        right: -19px;
        top: -5px;
    }
`

const OFFSET_SMALL = css`
    left: 26px;
`
const OFFSET_MEDIUM = css`
    left: 38px;
`
export const ToggleCircle = styled.div`
    background: #efefef;
    border-radius: 50%;
    transition: all 0.3s cubic-bezier(0.43, 1.3, 0.86, 1);
    ${({ size }) => {
        switch(size) {
            case 'md': {
                return TOGGLE_CIRCLE_MEDIUM
            }
            case 'sm': {
                return TOGGLE_CIRCLE_SMALL
            }
            default: {
                return TOGGLE_CIRCLE_MEDIUM
            }
        }
    }}
    ${({ isOn }) => isOn && `
       left: 38px;
    `}

    ${({ isOn, size }) => {
        if (!isOn) return ''
        switch(size) {
            case 'md': {
                return OFFSET_MEDIUM
            }
            case 'sm': {
                return OFFSET_SMALL
            }
            default: {
                return OFFSET_MEDIUM
            }
        }
    }}
`