import React, { useContext } from 'react';
import CurrencyInput from '../../../../../ui/input/fields/CurrencyInput';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
// TODO has to be atleast 10
const LifeTimeBudget = ({}) => {
    const { isEdit, campaignObj, handleUpdate, currency } = useContext(LinkedInCampaignContext);
    const { lifetimeBudget, budgetDuration } = campaignObj;

    if (budgetDuration === 'DAILY') return;

    return (
        <FieldContainer>
            <h3> Lifetime Budget <span className='asterisk'>*</span> </h3>
            { isEdit ?
                <CurrencyInput
                    onChange={(e) => handleUpdate({key: 'lifetimeBudget', value: e.target.value})}
                    value={lifetimeBudget}
                    currency={currency}
                /> :
                <p> {lifetimeBudget} {currency} </p>
            }
        </FieldContainer>
    )
}

export default LifeTimeBudget;