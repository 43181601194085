import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import CheckBox from '../../../../ui/input/checkbox/CheckBox'

const EnhancedCpc = ({googleIcon, isEdit, useEnhancedCpc, setUseEnhancedCpc}) => {
    return (
        <FieldContainer>
            <h3> {googleIcon} Enchanced CPC</h3>
            <CheckBox
                isDisabled={!isEdit}
                checked={useEnhancedCpc}
                callback={() => setUseEnhancedCpc(!useEnhancedCpc)}
                label={'Use Enchanced CPC'}
            />
        </FieldContainer>
    )
}

export default EnhancedCpc