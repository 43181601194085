import React, { useEffect, useState } from 'react'
import { CustomSelectOption, StyledAudienceTarget, StyledInputContainer, StyledItemContainer, StyledResultItem, StyledResultsContainer, StyledTargetItem } from './styles'
import { Input } from '../../../../../../ui/input/input'
import Select from 'react-select';
import { selectThemeModal } from '../../../../../../ui/themes';
import { toast } from 'react-toastify';
import Loader from '../../../../../../ui/Loader';
import { AiOutlineClose } from 'react-icons/ai'

const AudienceTargetV2 = ({ type, text, socialLoginId, selectedTargets, idx, handleAdd, handleRemove, isExclude, targetTypeOptions, allTargets }) => {
    const [query, setQuery] = useState('')
    const [queryInterests, setQueryInterests] = useState([])
    const [selectedType, setSelectedType] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // * Don't call endpoint if empty AND if the user removes search query
        if ((!query.trim() && selectedType?.qRequired === true) || !selectedType) {
            setQuery('')
            setQueryInterests([])
            return
        }

        // * In cases where we want to search within a non-query list prevent the general api call
        if (selectedType?.qRequired === false) return

        const delayedSearch = setTimeout(() => {
            setIsLoading(true)
            window.eulerity.makeApiCall({
                url: `/api/facebook/queryTargets?q=${query}&targetType=${selectedType?.value}&socialLoginId=${socialLoginId}`,
                method: 'GET',
                callback: (response) => {
                    setQueryInterests(response);
                    setIsLoading(false)
                },
                errorCallback: (error) => {
                    console.error(error)
                    setIsLoading(false)
                    toast.error('Unable to search for interest targets!')
                }
            })
        }, 500)

        return () => clearTimeout(delayedSearch)
    }, [query])

    const handleSelectTargetType = (targetType) => {
        setSelectedType(targetType)
        setQueryInterests([])
        setQuery('')

        // * Not all target types require a query, in those cases just make the call
        if (targetType?.qRequired === false) {
            setIsLoading(true)

            window.eulerity.makeApiCall({
                url: `/api/facebook/queryTargets?&targetType=${targetType?.value}&socialLoginId=${socialLoginId}`,
                method: 'GET',
                callback: (response) => {
                    setQueryInterests(response);
                    setIsLoading(false)
                },
                errorCallback: (error) => {
                    console.error(error)
                    setIsLoading(false)
                    toast.error('Unable to search for interest targets!')
                }
            })
        }
    }

    const handleSubFilter = (list) => {
        if (selectedType?.qRequired === true) return list
        const lowerCaseQuery = query?.toLocaleLowerCase()

        return list.filter(option => option?.name?.toLocaleLowerCase().includes(lowerCaseQuery))
    }

    const renderAudienceTargets = () => {
        const filteredList = handleSubFilter(queryInterests)
        if (isLoading) return <Loader />
        if (!query?.length && selectedType?.qRequired === true) return <p style={{ textAlign: 'center' }}>Enter Search Query</p>
        if (!filteredList?.length) return <p style={{ textAlign: 'center' }}>No Queries Found</p>

        return filteredList.map((interest) => {
            return (
                <StyledResultItem
                    key={interest?.id}
                    onClick={() => handleAdd(interest, idx)}
                    isSelected={allTargets?.find(target => target?.id === interest?.id)}
                >
                    <p>{interest?.name}</p>
                    <p>{interest?.topic}</p>
                </StyledResultItem>
            )
        })
    }

    const renderSelectedTargets = () => {
        // * For exclude we map a collection
        if (isExclude) {
            return selectedTargets?.map((target) => {
                return (
                    <StyledTargetItem
                        key={target.id}
                    >
                        <div className='item-text'>
                            <p>{target?.name}</p>
                            <p>{target?.topic}</p>
                        </div>
                        <AiOutlineClose size={20} onClick={() => handleRemove(target)}/>
                    </StyledTargetItem>
                )
            })
        }

        // * For include we map a collection of collections
        return selectedTargets[idx]?.map((target) => {
            return (
                <StyledTargetItem
                    key={target.id}
                    onClick={() => handleRemove(target, idx)}
                >
                    <div className='item-text'>
                        <p>{target?.name}</p>
                        <p>{target?.topic}</p>
                    </div>
                    <AiOutlineClose size={20} />
                </StyledTargetItem>
            )
        })
    }

    // * Custom Dropdown items
    const CustomDropdownOption = (props) => {
        const { data, innerRef, innerProps, isSelected } = props

        return <CustomSelectOption ref={innerRef} isSelected={isSelected} {...innerProps}>
            <p style={{ fontSize: '12px' }}>{data?.label}</p>
            {data?.label !== 'Behavior' && <p style={{ color: 'B3B0AC', fontSize: '10px' }}>Demographics</p>}
        </CustomSelectOption>
    }

    return (
        <StyledAudienceTarget type={type}>
            <p>{text}</p>
            <StyledItemContainer>
                {
                    renderSelectedTargets()
                }
            </StyledItemContainer>
            <StyledInputContainer>
                <div style={{ flex: '1' }}>
                    <Select
                        components={{ Option: CustomDropdownOption }}
                        options={targetTypeOptions?.sort((a, b) => a?.label.localeCompare(b?.label))}
                        onChange={(e) => handleSelectTargetType(e)}
                        styles={selectThemeModal}
                        placeholder='Select Target Type'
                        isClearable
                        menuPosition='fixed'
                    />
                </div>
                <div style={{ width: '250px' }}>
                    <Input
                        placeholder='Search for interest target'
                        onChange={(e) => setQuery(e.target.value)}
                        value={query}
                        disabled={!selectedType}
                    />
                </div>
            </StyledInputContainer>
            {
                (query?.trim()?.length > 0 || (queryInterests?.length > 0 || isLoading)) &&
                <StyledResultsContainer>
                    <p style={{ fontSize: '10px' }}>Search Results:</p>
                    <div className='styled-results'>
                        {
                            renderAudienceTargets()
                        }
                    </div>
                </StyledResultsContainer>
            }
        </StyledAudienceTarget>
    )
}

export default AudienceTargetV2