import styled from 'styled-components';

export const AdvertiserListItem = styled.div`
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    flex: 1;
    color: black;
    font-size: 14px;

    .advertiser-data {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
            color: ${props => props.theme.color.mediumGray};
        }
    }

    img {
        height: 26px;
        width: fit-content;
    }
`;