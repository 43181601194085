import React from 'react'
import PercentInput from '../../../../ui/input/fields/PercentInput'
import Information from '../../../../ui/tooltip/Information'

const TabletBidding = ({ FieldContainer, isEdit, tabletBid, setTabletBid}) => {
    return (
        <FieldContainer>
            <h3>
                Tablet Bid Adjustment
                <Information text='This campaign setting field is used as a reference and should be set on the network manager.' />
            </h3>
            {
                isEdit ? <PercentInput value={tabletBid} onChange={e => setTabletBid(e.target.value)} min='-100' max='900'/> : <p>{tabletBid} %</p>
            }
        </FieldContainer>
    )
}

export default TabletBidding