import { useLayoutEffect, useState } from "react";
import { gsap } from "gsap";
gsap.config({ nullTargetWarn: false });

const useDropDownAnimation = (mainClass, childrenClass) => {
  const [mainAnimation, setMainAnimation] = useState(null);
  const [childrenAnimation, setChildrenAnimation] = useState();
  const [status, setStatus] = useState()

  /*
    DOC
    This Hook creates animation for any dropdown
    Usage pass two classes the main class and the childClass

    This hook exposes one method and one propery the function acts like a button and the status is the status tells if the dropdown is open

    initial css values for the mainClass
      visibility: hidden;
      height: 0rem;

    Ask @taulant-eulerity for any implementation details
*/


useLayoutEffect(() => {

    let childrenAnim, dropAnimation;
    childrenAnim = gsap
      .timeline({ onReverseComplete: () => {
        dropAnimation.reverse()
      }})
      .fromTo(`.${childrenClass}`, {visibility: "hidden", opacity: 0, x: -20 }, {visibility: "visible", duration: 0.1, opacity: 1, x: 0 })
      .reverse();
    dropAnimation = gsap
      .timeline({ onComplete: () => childrenAnim.play() })
      .fromTo(`.${mainClass}`, { height: 0, visibility: "hidden" },  {height: 'auto', visibility: "visible" , duration: 0.1})
      .reverse();

    setMainAnimation(dropAnimation);
    setChildrenAnimation(childrenAnim);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handleDropDownAnimation = () => {
    setStatus(prew => !prew)
    if(mainAnimation.reversed()) {
      mainAnimation.play()
    } else  childrenAnimation.reverse()
  }


  const reverseAnimation = () =>  {
    if(!mainAnimation.reversed()) {
      setStatus(false)
      childrenAnimation.reverse()
    }
  }


  return { handleDropDownAnimation, reverseAnimation, status };
};

export default useDropDownAnimation;
