import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import AudienceModal from './modal/AudienceModal';
import LocationModal from './modal/LocationModal';
import TrackingModal from './modal/TrackingModal';
import Loader from '../../../ui/Loader';
import CheckBox from '../../../ui/input/checkbox/CheckBox';
import Information from '../../../ui/tooltip/Information';
import LobItemTable from './tables/LobItemTable';
import LobObjTable from './tables/LobObjTable';
import EditContext from './Context/EditContext';

// Utils
import { isObjArraysEqual } from '../../../utils/arrays';
import { genderOptions, incomeTargetObj } from '../lob.utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateLob } from '../../../actions/lobActions';
import { toast } from 'react-toastify';

// Styles
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn } from '../../../ui/button';
import { FieldColContainer, InputRangeContainer } from '../styles';
import { FieldContainer, StyledFieldsWrapper } from '../../../ui/containers';
import { NumberInput } from '../../../ui/input/input';
import { selectTheme } from '../../../ui/themes';

const Demographics = ({}) => {
    const [minAge, setMinAge] = useState();
    const [maxAge, setMaxAge] = useState();
    const [genderTargets, setGenderTargets] = useState([]);
    const [maxIncomePercentage, setMaxIncomePercentage] = useState();
    const [minIncomePercentage, setMinIncomePercentage] = useState();
    const [hasTrackingCodes, setHasTrackingCodes] = useState();
    const [languageTargets, setLanguageTargets] = useState();
    const [languageOptions, setLanguageOptions] = useState();

    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
    const [isAudienceCreateModalOpen, setIsAudienceCreateModalOpen] = useState(false);
    const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
    const [incomeTargetValues, setIncomeTargetValues] = useState([]);

    const _D = useDispatch()
    const lob = useSelector(state => state.lob.currentLineOfBusiness);

    const { isEdit, setIsEdit, handleUpdateLob } = useContext(EditContext);

    useEffect(() => {
        //Filling the dropdown with values!
        if (!lob?.minIncomePercentage || !lob?.maxIncomePercentage) return
            const max = incomeLabel().split(" - ")[1]
            const min = incomeLabel().split(" - ")[0]
            if(!max && min) return setIncomeTargetValues(incomeTargetObj[0])
            let indexForMax = incomeTargetObj.findIndex((it, idx) => it.label.split("-")[idx === incomeTargetObj.length-1 ? 0 : 1] === max)
            let indexForMin = incomeTargetObj.findIndex(it => it.label.split("-")[0] === min)
            setIncomeTargetValues(incomeTargetObj.slice(indexForMin, indexForMax+1))
    },[lob?.minIncomePercentage,lob?.maxIncomePercentage])

    useEffect(() => {
        setMinAge(lob?.minAge);
        setMaxAge(lob?.maxAge);
        setGenderTargets(lob?.genderTargets);
        setLanguageTargets(lob?.languageTargets);
        setMinIncomePercentage(lob?.minIncomePercentage);
        setMaxIncomePercentage(lob?.maxIncomePercentage);
        setHasTrackingCodes(lob?.hasTrackingCodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const list = [];
        window.eulerity.makeBatchedApiCall({
            url: `/api/lob/languageTargets`,
            dataCallback: function (res) {
                res.forEach(c => list.push(c))
            },
            doneCallback: () => {
                const optionsList = list.map(l => {
                    return {
                        value: l,
                        label: l.name
                    }
                })

                setLanguageOptions(optionsList)
            }
        })
    }, []);

    // * Gender Selector
    const genderDefaultValues = () => {
        return genderOptions.filter(gender => {
            return lob?.genderTargets.indexOf(gender.value) + 1;
        });
    }

    // * Language Selector
    const languageDefaultValue = () => {
        return languageTargets?.map(language => {
            return (
                {
                    label: language.name,
                    value: language
                }
            )
        })
    }

    const selectedGenderList = () => genderDefaultValues()?.map(gender => gender.label).join(', ');

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};
            const errorMessages = [];
            const minIncome = handleIncomePercentage('min');
            const maxIncome = handleIncomePercentage('max');

            if (minAge && lob.minAge !== minAge) updateObj.minAge = minAge;
            if (maxAge && lob.maxAge !== maxAge) updateObj.maxAge = maxAge;
            if (!isObjArraysEqual(genderTargets, lob?.genderTargets)) updateObj.genderTargets = genderTargets;
            if (minIncome && lob.minIncomePercentage !== minIncome) updateObj.minIncomePercentage = minIncome;
            if (maxIncome && lob.maxIncomePercentage !== maxIncome) updateObj.maxIncomePercentage = maxIncome;
            if (hasTrackingCodes !== lob.hasTrackingCodes) updateObj.hasTrackingCodes = hasTrackingCodes;
            if (!isObjArraysEqual(languageTargets, lob?.languageTargets)) updateObj.languageTargets = languageTargets;

            if (minAge > maxAge) errorMessages.push('minimum age has to be less than maximum age');

            if(!incomeTargetValues.length) {
                updateObj.minIncomePercentage = 0
                updateObj.maxIncomePercentage = 0
            }

            if (errorMessages.length > 0) {
                errorMessages.forEach(err => toast.error(err));
                return;
            }

            if (Object.keys(updateObj).length > 0) handleUpdateLob(updateObj);
        }
        setIsEdit(!isEdit);
    }


    const handleOnCreateItem = ( updateObj, handleSuccess ) => {
        _D(updateLob({
            websafe: lob?.websafe,
            updateObj,
            callback: () => {
                handleSuccess()
            }
        }))
    }

    const handleGenderTargetChange = (selectedOptions) => {
        const targets = selectedOptions?.map(opt => opt.value) || [];
        setGenderTargets(targets);
    }

    const handleLanguageTargetChange = (selectedOptions) => {
        const targets = selectedOptions?.map(opt => opt.value) || [];
        setLanguageTargets(targets);
    }

    const handleIncomeTargetChange = (event, options) => {
        switch (options.action) {
          case "select-option": {
            const addedOption = options.option;
            if (incomeTargetValues?.length) {
              return addedOption.id < incomeTargetValues[incomeTargetValues.length-1].id ?
                        setIncomeTargetValues(incomeTargetObj.slice(addedOption.id - 1,incomeTargetValues[incomeTargetValues.length-1].id))
                        :
                        setIncomeTargetValues(incomeTargetObj.slice(incomeTargetValues[0].id - 1, addedOption.id))
            }
            return setIncomeTargetValues([addedOption]);
          }
          case "remove-value": {
            const removedValue = options.removedValue;
            if (removedValue.id === incomeTargetValues[incomeTargetValues.length - 1]?.id) return setIncomeTargetValues(incomeTargetValues.slice(0, -1));
            if(!Array.isArray(incomeTargetValues)) return setIncomeTargetValues([])
            const cutIndex = event.findIndex((it, idx) => it.label !== incomeTargetValues[idx].label) + 1
            const halfArray = Math.ceil(incomeTargetValues.length / 2)
            return cutIndex > halfArray ? setIncomeTargetValues(incomeTargetValues.slice(0, cutIndex-1)) : setIncomeTargetValues(incomeTargetValues.slice(cutIndex))
          }
          case "clear": {
            return setIncomeTargetValues([])
          }
          default:
            return;
        }
      }


    const handleIncomePercentage = (type) => {
        const sortedIncomeArr = incomeTargetValues.sort((a, b) => {
            return a.id - b.id;
        });

        let minIncome = sortedIncomeArr[0];
        let maxIncome = sortedIncomeArr[sortedIncomeArr.length -1];
        if (type === 'min') return minIncome?.value?.min;
        if (type === 'max') return maxIncome?.value?.max;
    }

    const incomeLabel = () => {
        if (!lob?.minIncomePercentage || !lob?.maxIncomePercentage) return  "N/A"
        let label = '';
        const minObj = incomeTargetObj.find(income => income.value.min === lob?.minIncomePercentage);
        const maxObj = incomeTargetObj.find(income => income.value.max === lob?.maxIncomePercentage);
        if (!minObj || !maxObj) return "Please select House Hold Income Range"
        const minString = minObj.label.split('-')[0];
        const maxString = lob?.maxIncomePercentage >= 90 ? maxObj.label : maxObj.label.split('-')[1];

        label = `${minString} - ${maxString}`;
        return maxString ? label : minString
    }

    if (!lob) return <Loader />;

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                Core Demographics
                {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>

            <FieldColContainer>
                <FieldContainer>
                    <h3> Age </h3>
                    {
                        isEdit ?
                        <InputRangeContainer>
                            <NumberInput type='number' value={minAge} onChange={e => setMinAge(parseInt(e.target.value))} />
                            <span> to </span>
                            <NumberInput type='number' value={maxAge} onChange={e => setMaxAge(parseInt(e.target.value))} />
                        </InputRangeContainer>
                         :
                        <p> {lob?.minAge} - {lob?.maxAge} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> Gender </h3>
                    {
                        isEdit ?
                        <Select
                            isClearable
                            isMulti
                            options={genderOptions}
                            defaultValue={genderDefaultValues()}
                            styles={selectTheme}
                            onChange={e => handleGenderTargetChange(e)}
                        /> :
                        <p> {selectedGenderList() || 'N/A'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> House Hold Income Range </h3>
                    {
                        isEdit ?
                        <Select
                            isMulti
                            options={incomeTargetObj}
                            value={incomeTargetValues}
                            styles={selectTheme}
                            onChange={(event, action) => handleIncomeTargetChange(event, action)}
                        /> :
                        <p>{incomeLabel()}</p>
                    }
                </FieldContainer>
            </FieldColContainer>

            <FieldColContainer>
                <FieldContainer>
                    <h3>
                        Standard Tracking Convention
                        <Information text='By checking this box, these tracking codes will be automatically appended to the end of any provided urls for this LOB' />
                    </h3>
                    <CheckBox
                        checked={hasTrackingCodes}
                        callback={() => setHasTrackingCodes(!hasTrackingCodes)}
                        isDisabled={!isEdit}
                        label={'Use Standard Tracking Convention'}
                    />
                </FieldContainer>
                <FieldContainer>
                    <h3>
                        Language Targets
                    </h3>
                    {
                        isEdit ?
                        <Select
                            isClearable
                            isMulti
                            options={languageOptions?.filter(option => {
                                return !languageTargets.find(target => target.name === option.value.name)
                            })}
                            defaultValue={languageDefaultValue()}
                            styles={selectTheme}
                            onChange={e => handleLanguageTargetChange(e)}
                            noOptionsMessage={() => 'No Options'}
                        /> : <p> {lob?.languageTargets?.length ? lob?.languageTargets.map(lang => lang.name).join(', '): 'N/A'} </p>
                    }
                </FieldContainer>
            </FieldColContainer>

            <FieldColContainer>
                <LobItemTable
                    title='Location Targeting'
                    fieldName='locationTargets'
                    successText={'Location Target Deleted'}
                    isEdit={isEdit}
                    tableData={lob?.locationTargets}
                    handleCreateItem={() => setIsLocationModalOpen(true)}
                />
                <LobItemTable
                    title='Audience Description'
                    isEdit={isEdit}
                    handleCreateItem={() => setIsAudienceCreateModalOpen(true)}
                    tableData={lob?.audienceDescriptions}
                    fieldName='audienceDescriptions'
                    successText='Audience Description Deleted'
                />
                {
                    hasTrackingCodes &&
                    <LobObjTable
                        title='Tracking Codes'
                        tableData={lob?.trackingCodes}
                        isEdit={isEdit}
                        handleOnCreate={() => setIsTrackingModalOpen(true)}
                        successText='Tracking Code Deleted'
                        compareKey='value'
                        fieldName='trackingCodes'
                    />
                }

                {
                    isAudienceCreateModalOpen &&
                    <AudienceModal
                        onClose={() => setIsAudienceCreateModalOpen(false)}
                        handleTargetUpdate={handleOnCreateItem}
                        audienceDescriptions={lob?.audienceDescriptions}
                    />
                }
                {
                    isLocationModalOpen &&
                    <LocationModal
                        onClose={() => setIsLocationModalOpen(false)}
                        handleTargetUpdate={handleOnCreateItem}
                        locationTargets={lob?.locationTargets}
                    />
                }
                {
                    isTrackingModalOpen &&
                    <TrackingModal
                        onClose={() => setIsTrackingModalOpen(false)}
                        trackingCodes={lob?.trackingCodes}
                        handleTargetUpdate={handleOnCreateItem}
                    />
                }
            </FieldColContainer>
        </StyledFieldsWrapper>
    )
}

export default Demographics;