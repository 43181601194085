import React from 'react';
import AdvertiserItem from './AdvertiserItem';

// Styles
import { AdvertisersTableContainer } from '../styles';
import { FlexEndBtnContainer } from '../../../../../ui/buttonContainers';
import { OutlineIconBtn } from '../../../../../ui/button';
import { FaPlus } from 'react-icons/fa';

const AdvertisersTable = ({
        isEdit, advertisersList, newAdvertisersList,
        setIsAdvertiserModalOpen, setIsEditAdvertiserModalOpen,
        setEditData, setIsArchiveModalOpen,
    }) => {

    const FilteredAdvertiserList = advertisersList.filter(advertiser => !advertiser.archived);
    const AdvertiserListItem = FilteredAdvertiserList.map((advertiser, idx) => {
        return (
            <AdvertiserItem
                advertiser={advertiser}
                key={idx} idx={idx}
                isEdit={isEdit}
                setEditData={setEditData}
                setIsEditAdvertiserModalOpen={setIsEditAdvertiserModalOpen}
                setIsArchiveModalOpen={setIsArchiveModalOpen}
            />
        )
    });
    const addedIdxVal = (idx) => FilteredAdvertiserList.length + idx;
    const NewAdvertiserListItem = newAdvertisersList.map((advertiser, idx) => {
        return (
            <AdvertiserItem
                advertiser={advertiser}
                key={addedIdxVal(idx)} idx={addedIdxVal(idx)}
                isEdit={isEdit}
                setEditData={setEditData}
                setIsEditAdvertiserModalOpen={setIsEditAdvertiserModalOpen}/>
        )
    })

    return (
        <div>
            <FlexEndBtnContainer>
                <OutlineIconBtn onClick={setIsAdvertiserModalOpen}><FaPlus /> Add Advertiser </OutlineIconBtn>
            </FlexEndBtnContainer>

            <AdvertisersTableContainer isEdit={isEdit} >
                <h1> ADVERTISER NAME </h1>
                <h1> LOGO </h1>
                <h1> DESCRIPTION </h1>
                <h1> DOCUMENTS </h1>
            </AdvertisersTableContainer>

            {AdvertiserListItem}
            {NewAdvertiserListItem}
        </div>
    )
}

export default AdvertisersTable;