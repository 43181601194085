import React, { useContext } from 'react';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { Input } from '../../../../../ui/input/input';
import { FieldContainer } from '../../../../../ui/containers';

const AdAccount = ({}) => {
    const { isEdit } = useContext(LinkedInCampaignContext);

    const displayName = useSelector(state => state.media.currentCampaign?.adAccount?.displayName);
    return (
        <FieldContainer>
            <h3> Ad Account </h3>
            {
                isEdit ?
                <Input value={displayName} placeholder='LinkedIn Ad Account' disabled={true}/> :
                <p> {displayName} </p>
            }
        </FieldContainer>
    )
}

export default AdAccount;