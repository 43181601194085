import React, { useState, useContext, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

// Utils
import { makeGETPromiseApiCall } from '../../../../systemConfig/utils';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { selectTheme } from '../../../../../ui/themes';

const Locations = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { locationTargets } = campaignObj;
    const [inputValue, setInputValue] = useState('');

    const fetchData = async (inputValue) => {
        try {
            if (inputValue.length === 0) return;
            const response = await makeGETPromiseApiCall(`/api/linkedin/queryLocations?query=${inputValue}`);
            const uniqueValues = locationTargets?.map(location => location.name);

            const options = response.map(option => {
                return {label: option.name, value: option}
            })

            const filteredOptions = options.filter(option => !uniqueValues.includes(option.label));

            return filteredOptions;
        } catch (err) {
            console.error('Error fetching data', err);
            throw err;
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (inputValue?.trim() !== '') {
                fetchData(inputValue).then(options => {
                    console.log('Done fetching...', options)
                })
            }
        }, 5000);

        return () => clearTimeout(delayDebounceFn);
    }, [inputValue])

    const handleInputChange = (newValue) => {
        setInputValue(newValue);
    };

    const handleSelect = selectedOptions => {
        const result = selectedOptions?.map(option => option.value);
        handleUpdate({key: 'locationTargets', value: result});
    }

    const locationText = locationTargets?.length > 0 ? locationTargets.map(location => location.name).join(', ') : 'N/A'
    const defaultValue = locationTargets?.map(location => {
        return { label: location.name, value: location }
    })

    return (
        <FieldContainer>
            <h3> Locations </h3>
            {isEdit ?
            <AsyncSelect
                isClearable
                isMulti
                cacheOptions
                styles={selectTheme}
                onInputChange={handleInputChange}
                defaultValue={defaultValue}
                loadOptions={fetchData}
                onChange={handleSelect}
            /> :
            <p> {locationText} </p>
            }
        </FieldContainer>
    )
}

export default Locations;



