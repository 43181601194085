import React from "react";
import { Input } from "../input/input";
import Header from "./Header";
import { StyleSelectorWrapper } from "./styles";

const InputField = (props) => {
  return (
    <StyleSelectorWrapper>
      <Header headerText={props.name} handleOnChange={props.handleHowItWorksModal} howToData={props.howToObject} logo={props.logo} />
      {props.isEdit ? <Input placeholder="Please Enter Text" {...props} /> : <p>{(props?.state) || "N/A"}</p>}
    </StyleSelectorWrapper>
  );
};

export default InputField;
