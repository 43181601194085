import React, { useState } from 'react'
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from '../../../tables/styles'
import { FiPlusCircle } from 'react-icons/fi'
import DeleteTargetingModal from '../../../modal/DeleteTargetingModal'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { removeAdAccount } from '../../../../../../actions/lobActions'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import LinkedInBiddingModal from '../modals/linkedinNetwork/LinkedInBiddingModal'

const LinkedInNetworkTable = ({ isEdit, tableData, handleOnCreate, updateTableData, liConfigId }) => {
    const _D = useDispatch()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState();
    const [loader, setLoader] = useState(false)

    const deleteLinkedInAccount = () => {
        const updatedAdAccounts = tableData.filter(data => data?.accountId !== selectedItem?.accountId)
        setLoader(true)
        _D(removeAdAccount({
            socialConfig: `liconfig=${liConfigId}`,
            obj: [{ accountId: selectedItem?.accountId, network: 'linkedin' }],
            callback: () => {
                toast.success('Removing LinkedIn Ad Account');
                updateTableData(updatedAdAccounts)
                setIsDeleteModalOpen(false)
                setLoader(false)
            }
        }))
    }

    const handleOnEdit = (data) => {
        setIsEditModalOpen(true)
        setSelectedItem(data)
    }

    const handleItemSelection = (data) => {
        setSelectedItem(data)
        setIsDeleteModalOpen(true)
    }

    return (
        <TableContainer>
            <TableHeader>
                <h3>LinkedIn Accounts</h3>
                {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
            </TableHeader>
            {
                Object.keys(tableData || {})?.length > 0 ?
                    tableData.map((data, idx) => {
                        return (
                            <li key={idx}>
                                <ListObj style={{gap: '6px'}}>
                                    <span><strong>Ad Account</strong>: {data?.displayName}</span>
                                    <span><strong>Currency</strong>: {data?.currency}</span>
                                    <span><strong>Cost Per Result Goal</strong>: {data?.targetCpa}</span>
                                    <span><strong>Manual Bidding</strong>: {data?.cpcBidLimit}</span>
                                    { data?.liPage?.localizedName && <span><strong>Page</strong>: {data?.liPage?.localizedName}</span> }
                                </ListObj>
                                <div className='opt__button__container'>
                                    {isEdit && <BiPencil onClick={() => handleOnEdit(data)} />}
                                    {isEdit && <BiTrash onClick={() => handleItemSelection(data)} />}
                                </div>
                            </li>
                        )
                    })
                    :
                    <EmptyDataContainer key='n/a'>
                        <p> No Accounts Available </p>
                    </EmptyDataContainer>
            }
            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={deleteLinkedInAccount}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title='LinkedIn Account'
                    item={selectedItem}
                    useLoader
                    loading={loader}
                />
            }
            {
                isEditModalOpen &&
                <LinkedInBiddingModal
                    onClose={() => setIsEditModalOpen(false)}
                    selectedItem={selectedItem}
                    setAdAccounts={updateTableData}
                    adAccounts={tableData}
                    liConfigId={liConfigId}
                />
            }
        </TableContainer>
    )
}

export default LinkedInNetworkTable