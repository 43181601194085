import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import PropertyFields from '../networkManagement/components/PropertyFields';

// Styles
import { OutlineBtn, OutlineIconBtn, BlackBtn, CenterButtons } from '../../../../ui/button';
import { FlexEndBtnContainer } from '../../../../ui/buttonContainers';
import { FaPlus } from 'react-icons/fa';

const AddPropertyModal = ({onClose, setProperties}) => {
    const [propertyCount, setPropertyCount] = useState(1);
    const [propertyList, setPropertyList] = useState([{
        name: '',
        type: '',
        source: '',
        values: [],
        bulkUploadInstructions: '',
        networkManagerInstructions: '',
    }]);

    const handleAddProperties = () => {
        setProperties(propertyList);
        onClose();
    }

    const handleAddPropertiesCount = () => {
        const addByOne = propertyCount + 1;
        setPropertyCount(addByOne);
        setPropertyList([...propertyList, {
            name: '',
            type: '',
            source: '',
            values: [],
            bulkUploadInstructions: '',
            networkManagerInstructions: '',
        }])
    }

    const renderPropertyFields = () => {
        return propertyList?.map((property, idx) => <PropertyFields count={idx} key={idx} propertyList={propertyList} setPropertyList={setPropertyList} />);
    }

    return (
        <Modal
            onClose={onClose}
            header='Add Property'
            style={{width: '700px'}}
        >
            {renderPropertyFields()}
            <FlexEndBtnContainer>
                <OutlineIconBtn onClick={handleAddPropertiesCount}><FaPlus /> Add Property </OutlineIconBtn>
            </FlexEndBtnContainer>

            {
                CenterButtons(
                    <>
                        <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                        <BlackBtn onClick={handleAddProperties} isDisabled={false}> Add Property </BlackBtn>
                    </>
                )
            }
        </Modal>
    )
}

export default AddPropertyModal;