// * Function will create a deep copy of a nested object (NOTE - doesn't work with dates and functions)
export const createDeepCopy = (input) => {
    if(typeof input !== 'object') return input; // * if the input value is not an object just return it

    let copy = Array.isArray(input) ? [] : {};

    // * for every key in the object recursively call the function to clone nested properties (arrays/objs)
    for (let key in input) {
        const value = input[key];
        copy[key] = createDeepCopy(value)
    }

    return copy
}

export const objectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}