import React, { useContext } from 'react';
import FacebookbIcon from '../../../../../assets/images/socialMediaIcons/FbIcon.png';
import GoogleIcon from '../../../../../assets/images/socialMediaIcons/GoogleIcon.png';
import LinkedInIcon from '../../../../../assets/images/socialMediaIcons/LinkedInIcon.png';

// Styles
import { TableModalContainer } from '../../../../../ui/table/styles';
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { ModalListItemWithBtn, ModalListContainer, ModalListHeader, ModalListWrapper } from '../../../../../ui/list';
import { CenteredWarning } from '../../../../../ui/icons';
import { ErrorText } from '../../../../../ui/text';
import { EmptyListItem } from '../../../../../ui/list';
import { ChannelSetUpContext } from './ChannelSetupModal';
import { capitalize } from '../../../../../utils/strings';
import { NETWORKS } from '../../../../../constants/networks';

const SelectSocialAdAccount = ({ socialType }) => {
    const { adAccounts, setSelectedAccount, isAccountSelected, lob, channel } = useContext(ChannelSetUpContext)

    const socialIcon = () => {
        if (channel === NETWORKS.FACEBOOK) return <img src={FacebookbIcon} alt='img' />;
        if (channel === NETWORKS.GOOGLE) return <img src={GoogleIcon} alt='img' />;
        if (channel === NETWORKS.LINKEDIN) return <img src={LinkedInIcon} alt='img'/>;
    }

    return (
        <TableModalContainer>
            <ModalTextSecondary> Select {socialType} Ads Account </ModalTextSecondary>
            <ModalListWrapper>
                <ModalListHeader> {socialIcon()}{socialType} Ad Accounts </ModalListHeader>
                <ModalListContainer>
                    {
                        !adAccounts?.length ? <EmptyListItem><CenteredWarning /><ErrorText>No {socialType} ad accounts available for use. Please add {capitalize(channel)} ad accounts to the {lob?.name} LOB.</ErrorText></EmptyListItem> :
                            adAccounts?.map(account => {
                                const { displayName, currency } = account;
                                return (
                                    <ModalListItemWithBtn
                                        key={account?.accountId}
                                        isSelected={isAccountSelected(account?.accountId)}
                                        onClick={() => setSelectedAccount(account)}
                                    >
                                        {displayName}
                                        <span>Currency: {currency} </span>
                                    </ModalListItemWithBtn>
                                )
                            })
                    }
                </ModalListContainer>
            </ModalListWrapper>
        </TableModalContainer>
    )
}

export default SelectSocialAdAccount;