import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Select from 'react-select';

// Helpers
import { isObjArraysEqual } from '../../../../utils/arrays';

// Styles
import { ModalTextSecondary } from '../../../../components/Modal/styles';
import { OutlineBtn, CenterButtons, BtnWithLoader } from '../../../../ui/button';
import { TableModalContainer } from '../../../../ui/table/styles';
import { selectThemeModal } from '../../../../ui/themes';
import { toast } from 'react-toastify';

const LanguageModal = ({onClose, handleTargetUpdate, languageTargets, loader}) => {
    const [options, setOptions] = useState([]);
    const [currLanguages, setCurrLanguages] = useState([]);

    useEffect(() => {
        const list = [];
        setCurrLanguages(languageTargets)

        window.eulerity.makeBatchedApiCall({
            url: `/api/lob/languageTargets`,
            dataCallback: function (res) {
                res.forEach(c => list.push(c))
            },
            doneCallback: () => {
                const optionsList = list.map(l => {
                    return {
                        value: l,
                        label: l.name
                    }
                })

                setOptions(optionsList)
            }
        })
    }, []);


    const handleSave = () => {
        if (isObjArraysEqual(currLanguages, languageTargets)) return toast.error('No changes detected')
        handleTargetUpdate({languageTargets: [...currLanguages]}, onClose)
    }

    const languageDefaultValue = () => {
        return languageTargets?.map(language => {
            return (
                {
                    label: language.name,
                    value: language
                }
            )
        })
    }

    return (
        <Modal
            header='Add Language Targeting'
            onClose={onClose}
        >
            <TableModalContainer>
                <ModalTextSecondary> Language(s) </ModalTextSecondary>
                <Select
                    isClearable
                    isMulti
                    options={options}
                    defaultValue={languageDefaultValue}
                    styles={selectThemeModal}
                    onChange={e => setCurrLanguages(e.map(opt => opt.value) || [])}
                    noOptionsMessage={() => 'Loading languages'}
                />

                {CenterButtons(<>
                    <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                    <BtnWithLoader btnText='Add Language(s)' loader={loader} onClick={handleSave} type="blackBtn" />
                </>)}
            </TableModalContainer>
        </Modal>
    )
}

export default LanguageModal;