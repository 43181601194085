import styled, {css} from 'styled-components';

export const MediaPlansContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.background.gray};
`;

// Create
export const CreateMediaPlanContainer = styled.div`
    padding: 30px 20px;
    display: grid;
    grid-template-columns: 170px auto;
`;

export const TemplateCard = styled.div`
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    background-color: ${props => props.theme.background.lightGray};
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
        font-family: 'GothamBook';
        font-size: 14px;
        font-weight: 400;
    }

    span {
        color: ${props => props.theme.card.gray}
    }

    div {
        display: flex;
        flex-direction: row;
    }
`;

// Ad Group Setup
export const AdGroupSetupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-height: 50rem;
`;

export const AdGroupSetupViewMoreContainer = styled(AdGroupSetupContainer)`
    margin-top: 20px;
    max-width: 50vw;
`;

export const AdGroupListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    margin: 20px auto;
    font-family: 'GothamBook';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    gap: 20px;

    span {
        border: 1px solid black;
        border-radius: 4px;
        padding: 4px 8px;
        cursor: pointer;
    }
`;

export const AdGroupListItem = styled.li`
    border: 1px solid black;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    white-space: nowrap;

    ${({isSelected}) => isSelected && css`
            border: 1px solid ${props => props.theme.hover.yellow};
            background-color: ${props => props.theme.hover.yellow};
            font-family: 'GothamMedium';
            font-weight: 500;
        `
    }
`;

export const AdGroupAudienceTargetHeader = styled.h1`
    font-family: 'GothamMedium';
    font-size: 16px;
    font-weight: 500;

    & .gray {
        color: ${props => props.theme.input.gray};
        cursor: pointer;
    }

    & .green {
        color: ${props => props.theme.color.green};
    }

    & .red {
        color: ${props => props.theme.color.red};
    }

    svg {
        cursor: pointer;
    }
`;

export const AdGroupAudienceTargetWrapper = styled.div`
    display: flex;
    gap: 10px;
    max-width: 65vw;
    overflow: scroll;

    ${({isModal}) => isModal && css`
        flex-direction: column;
    `}
`;

export const AdGroupAudienceTargetContainer = styled.div`
    background-color: ${props => props.theme.background.lightGray};
    border-radius: 22px;
    width: fit-content;
    display: flex;
    gap: 10px;
    padding: 15px;

    ${({isModal}) => isModal && css`
        width: 100%;
        height: 100%;
        max-width: 100%;
        display: block;
        /* overflow-wrap: break-word; */

        li {
            margin-bottom: 5px;
        }
    `}
`;

export const AdGroupAudienceTargetItem = styled.li`
    width: fit-content;
    background-color: ${props => props.theme.color.green};
    border-radius: 10px;
    padding: 4px 10px;
    color: white;
    font-family: 'GothamBook';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    white-space: nowrap;

    ${({type}) => type === 'negative' && css`
        background-color: ${props => props.theme.color.red};
    `}
`;

export const AudienceTargetOr = styled.span`
    font-family: 'GothamLight';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AudienceTargetAnd = styled(AudienceTargetOr)`
    font-family: 'GothamMedium';
    font-weight: 500;
    font-size: 13px;
`;

// Launch
export const SocialLaunchContainer = styled.div`
    margin: 20px auto;
    #download-url {
            color: ${props => props.theme.color.yellow};
            text-decoration: underline;
        }
    .dummyNetworkHeader {
        display: flex;
        margin-top: 0.5rem;
        justify-content: space-between;
    }
    .dummyNetworkHeader-text {
        & > :first-child {
            margin-bottom: 0.5rem;
        }
        p,a {
            font-size: 14px;
            font-weight: bold;
        }
    }
    .dummyNetworkHeader-text {
        display: flex;
        gap: 0.5rem;
    }
`;

export const SocialLaunchContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    cursor: pointer;
    border-bottom: 1px solid rgba(205, 214, 218, 0.25);
    border-width: medium;
    padding: 15px 0;
   
    h2 {
        font-family: 'GothamMedium';
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 6px;
    }

    img {
        width: 18px;
    }
`;

export const CampaignLaunchItem = styled(SocialLaunchContainer)`
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: rgba(217, 217, 216, 0.1);
    padding: 10px;

    svg {
        font-size: 24px !important;
        color: ${props => props.theme.color.green};
        cursor: pointer;
    }

    button {
        width: fit-content;
    }

    p {
        font-family: 'GothamBook';
        font-style: normal;
        font-size: 14px;
        font-weight: 100 !important;
        display: flex;
        gap: 2px;
        margin: 5px 0;

        span {
            font-family: 'GothamMedium';
            font-weight: 500;
            margin-right: 3px;
        }
    }

    span {
        display: flex;
        justify-content: space-between;
    }

    .message {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        p {
            color: ${props => props.theme.color.green};
            font-size: 12px;
            opacity: 1.0 !important;
            text-align: bottom;
            display: flex;
            align-items: center;
        }
    }

    ${({isDisabled}) => isDisabled && css`
        pointer-events: none;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.06);

        button {
            opacity: 0.5;
        }

        svg {
            color: ${props => props.theme.color.gray};
        }

        .message > p {
            color: ${props => props.theme.color.red};
        }
    `}

    ${({isLaunched}) => isLaunched && css`
        .message > p {
            color: ${props => props.theme.color.green};
        }
    `};
`;

export const ExportBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    gap: 0.6rem;

`;

// * Warning Modal
export const WarningModalContainer = styled.div`
    width: 450px;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        font-family: 'GothamBook';
    }

    span {
        font-family: 'GothamMedium';
        font-weight: 500;
    }
`

export const StyleGoogleSheets = styled.div`
    width: 511px;

    .google-sheets_warmningText {
        font-family: "GothamBook";
        font-weight: 400;
        margin-top: 1rem;
        line-height: 1.5;
    }
`

export const StyleNumberPicker = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   gap: 1rem;

`

export const StyleShowGoogleSheetsLink  = styled.div`
    display: flex;
    height: 1rem;
    width: 100%;
    font-family: 'GothamMedium';
    justify-content: flex-end;
    font-size: 14px;
    a {
        color: ${props => props.theme.color.yellow};
        text-decoration: underline;
    }
`

export const StyledReviewContainer = styled.div`
    margin-top: 20px;

    .modal-info {
        margin-bottom: 30px;
    }

    .input-fields {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .input-field {
        width: 1024px;

        textarea {
            width: 100%;
            box-sizing: border-box;
        }

        p {
            margin-bottom: 4px;
        }

        .asterisk {
            color: #F4B63F;
        }
    }

    .attachments {
        display: flex;
        flex-direction: column;
    }

    .attachment-header {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .csv-attachment {
        color: ${props => props.theme.color.gray};
        text-decoration: underline;
        margin-top: 8px;
    }
`

export const MediaPlanStatus = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;

    div, p {
        margin-left: auto;
    }
`

export const OriginallyDerivedContainer = styled.div`
    background-color: ${props => props.theme.background.gray};
    padding: 10px;
`;