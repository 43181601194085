import React, { useState, useContext, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { generateDropdownOptions } from '../../../../../utils/arrays';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';


const Language = ({}) => {
    const [languageOptions, setLanguageOptions] = useState([]);
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { languageCodes } = campaignObj;

    useEffect(() => {
        window.eulerity.makeApiCall('/api/org/getDataFile?filename=languages', 'GET', null, response => {
            const languageOptions = generateDropdownOptions(response, 'code');
            setLanguageOptions(languageOptions)
        })
    }, []);

    const getDefaultValues = generateDropdownOptions(languageCodes);

    const handleLanguageChange = (selectedOptions) => {
        const selectedLanguages = selectedOptions?.map(option => option.value) || [];
        handleUpdate({key: 'languageCodes', value: selectedLanguages});
    }

    return (
        <FieldContainer>
            <h3> Language </h3>
            {
                isEdit ? (
                    <CreatableSelect
                        isClearable
                        isMulti
                        options={languageOptions}
                        styles={selectTheme}
                        defaultValue={getDefaultValues}
                        onChange={(e => handleLanguageChange(e))}
                        placeholder='Select Language'
                    />
                ) :
                <p> {languageCodes?.length ? languageCodes.join(', ') : 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default Language;