import React, { useState } from 'react'
import LinkedInIcon from '../../../../../../../assets/images/socialMediaIcons/LinkedInIcon.png';
import SelectNetworkAccountStep from '../../../../modal/SocialAccounts/SelectNetworkAccountStep';
import { BlackBtn, BlackBtnLg, BtnWithLoader, OutlineBtn } from '../../../../../../../ui/button';
import BuildModal from '../../../../../../../components/Modal/modals/BuildModal';
import ConnectSocialModal from '../../../../modal/ConnectSocialModal';
import AddSocialNetworkAccountStep from '../../../../modal/SocialAccounts/AddSocialNetworkAccountStep';
import LinkedInBiddingStep from './LinkedInBiddingStep';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { attachAdAccount } from '../../../../../../../actions/lobActions';
import useLinkedInAccount from '../../hooks/useLinkedInAccount';

const LinkedInNetworkModal = ({ onClose, data, setData, configId }) => {
    const _D = useDispatch()
    const {
        selectedAccount,
        setSelectedAccount,
        selectedPages,
        setSelectedPages,
        costPerGoal,
        setCostPerGoal,
        manualBidding,
        setManualBidding,
        insightTag,
        setInsightTag,
        insightOptions,
        fetchInsightOptions,
        liPage
    } = useLinkedInAccount()

    // * General State
    const [step, setStep] = useState(1)
    const [btnLoader, setBtnLoader] = useState(false)
    const [isAddSocialLoginModalOpen, setIsAddSocialLoginModalOpen] = useState(false);

    const nextStep = () => setStep(step + 1)
    const prevStep = () => setStep(step - 1)
    const handleNextAndFetch = () => {
        setStep(step + 1)
        fetchInsightOptions(selectedAccount?.id, selectedPages[0]?.id)
    }

    const lnNetworkObj = {
        name: 'Linkedin',
        header: 'LinkedIn account(s)',
        icon: LinkedInIcon,
    }

    // * Submit Function
    const handleAttachAccount = (list) => {
        // * Prevent user from entering a negative
        if (costPerGoal < 0 || manualBidding < 0) return toast.error('Currency cannot be negative')

        setBtnLoader(true)
        setData([...data, list[0]])

        _D(attachAdAccount({
            socialLoginId: selectedAccount?.id,
            socialConfig: `liconfig=${configId}`,
            pages: [
                {
                    accountId: list[0]?.id,
                    targetCpa: costPerGoal,
                    cpcBidLimit: manualBidding,
                    insightTag: insightTag?.value
                }],
            callback: () => {
                toast.success('Adding LinkedIn Account');
                setBtnLoader(false)
                onClose();
            },
            errorCallback: () => {
                toast.error('Something went wrong');
                setBtnLoader(false)
                onClose()
            }
        }))
    }

    // * Steps
    const steps = () => {
        switch (step) {
            case 1: return <SelectNetworkAccountStep
                isAddSocialLoginModalOpen={isAddSocialLoginModalOpen}
                handleAddSocialLogin={() => setIsAddSocialLoginModalOpen(true)}
                networkType={lnNetworkObj}
                setSelectedAccount={setSelectedAccount}
                selectedAccount={selectedAccount}
            />
            case 2: return <AddSocialNetworkAccountStep
                socialLoginId={selectedAccount?.id}
                selectedPages={selectedPages}
                setSelectedPages={setSelectedPages}
                socialType='linkedin'
                tableHeader='LinkedIn Ad Account(s)'
            />
            case 3: return <LinkedInBiddingStep
                costPerGoal={costPerGoal}
                setCostPerGoal={setCostPerGoal}
                manualBidding={manualBidding}
                setManualBidding={setManualBidding}
                insightTag={insightTag}
                setInsightTag={setInsightTag}
                selectedPage={selectedPages?.[0]}
                insightOptions={insightOptions}
                liPage={liPage}
            />
            default:
                console.log(`No step selected`);
        }
    }

    const decideButtons = () => {
        switch (step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BlackBtn onClick={nextStep} isDisabled={!selectedAccount}> Next </BlackBtn>]
            case 2: return [<OutlineBtn onClick={prevStep}> Back </OutlineBtn>, <BlackBtnLg onClick={handleNextAndFetch} isDisabled={!selectedPages?.length}> Next </BlackBtnLg>]
            case 3: return [<OutlineBtn onClick={prevStep}> Back </OutlineBtn>, <BtnWithLoader btnText='Add LinkedIn Ad Account' type='blackBtn' color='white' loader={btnLoader} onClick={() => handleAttachAccount(selectedPages)} />]
            default: return []
        }
    }

    return (
        <>
            <BuildModal
                header='Add LinkedIn Account'
                minWidth={'736px'}
                onClose={onClose}
                buttons={decideButtons()}
            >
                {steps()}
            </BuildModal>
            {
                isAddSocialLoginModalOpen &&
                <ConnectSocialModal
                    onClose={() => setIsAddSocialLoginModalOpen(false)}
                    network={lnNetworkObj}
                />
            }
        </>
    )
}

export default LinkedInNetworkModal