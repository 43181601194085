import React from 'react';
import Modal from '../../../../components/Modal/Modal';

// Styles
import { OutlineBtn, CenterBtnContainer, CenterBtnOutterContainer } from '../../../../ui/button';
import { AdGroupSetupViewMoreContainer } from '../../styles';

const ViewMoreAdGroupModal = ({onClose, viewObj}) => {
    const { header, text, data } = viewObj || {};
    return (
        <Modal
            header={header}
            onClose={onClose}
        >
            <AdGroupSetupViewMoreContainer>
                {text}
                {data}
            </AdGroupSetupViewMoreContainer>
            <CenterBtnOutterContainer>
            <CenterBtnContainer>
                <OutlineBtn onClick={onClose}> Close </OutlineBtn>
            </CenterBtnContainer>
            </CenterBtnOutterContainer>
        </Modal>
    )
}

export default ViewMoreAdGroupModal;