import CheckBox from "../../../../ui/input/checkbox/CheckBox";

export const AudienceTargetingSettingsField = ({isAudienceTargeting, setIsAudienceTargeting, isEdit, FieldContainer}) => {
    return (
        <FieldContainer>
            <h3> Audience Targeting Settings </h3>
            <CheckBox
                checked={isAudienceTargeting}
                callback={() => setIsAudienceTargeting(!isAudienceTargeting)}
                isDisabled={!isEdit}
                label={'Targeting'}
            />
        </FieldContainer>
    )
}

export default AudienceTargetingSettingsField;