import React from "react";
import styled from "styled-components";
import { HiOutlineChevronLeft } from "react-icons/hi";

export const StyleBackBtn = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 14px;
  padding: 0.5rem 0 0.5rem 0;
  cursor: pointer;
  p {
    margin-top: 0.1rem;
  }
`;

const BackButton = ({ handle, text = "Back To Overview" }) => {
  return (
    <StyleBackBtn onClick={handle}>
      <HiOutlineChevronLeft size={22} />
      <p>{text}</p>
    </StyleBackBtn>
  );
};

export default BackButton;
