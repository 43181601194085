import CheckBox from "../../../../ui/input/checkbox/CheckBox";

export const DisplayNetworkField = ({displayNetwork, setDisplayNetwork, isEdit, FieldContainer }) => {
    return (
        <FieldContainer>
            <h3>Display Network </h3>
            <CheckBox
                checked={displayNetwork}
                callback={() => setDisplayNetwork(!displayNetwork)}
                isDisabled={!isEdit}
                label='Include Google Display Network'
            />
        </FieldContainer>
    )
}

export default DisplayNetworkField;