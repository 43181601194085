import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import LocationModal from '../../lineofbusiness/components/modal/LocationModal';
import TrackingModal from '../../lineofbusiness/components/modal/TrackingModal';
import CustomDatePicker from '../../../ui/input/fields/CustomDatePicker';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

// * Campaign Fields
import CampaignObjective from './googleCampaignFields/CampaignObjective';
import CampaignType from './googleCampaignFields/CampaignType';
import SearchNetwork from './googleCampaignFields/SearchNetwork';
import DesktopBidding from './googleCampaignFields/DesktopBidding';
import MobileBidding from './googleCampaignFields/MobileBidding';
import TabletBidding from './googleCampaignFields/TabletBidding';
import DisplayNetwork from './googleCampaignFields/DisplayNetwork';
import AdRotation from './googleCampaignFields/AdRotation';
import SearchPartners from './googleCampaignFields/SearchPartners';
import AudienceTargetting from './googleCampaignFields/AudienceTargetting';
import BiddingStrategy from './googleCampaignFields/BiddingStrategy';
import EnhancedCpc from './googleCampaignFields/EnhancedCpc';
import TargetCpa from './googleCampaignFields/TargetCpa';
import TargetRoas from './googleCampaignFields/TargetRoas';
import CpcBidLimit from './googleCampaignFields/CpcBidLimit';
import AdLocation from './googleCampaignFields/AdLocation';
import PercentImpression from './googleCampaignFields/PercentImpression';
import Budget from './googleCampaignFields/Budget';
import LanguageTargets from './googleCampaignFields/LanguageTargets';
import LocationTargets from './googleCampaignFields/LocationTargets';

// Redux
import { googleObjectives } from '../../../actions/tempActions';
import { GENDER_OPTIONS } from '../../../constants/lob';
import { generateName, updateCampaign } from '../../../actions/mediaActions';
import { useDispatch } from 'react-redux';

// Styles
import GoogleIcon from '../../../assets/images/socialMediaIcons/GoogleIcon.png';
import { OneThirdGridColContainer, FieldContainer, StyledFieldsWrapper } from '../../../ui/containers';
import { Input, NumberInput, DatePickerWrapper } from '../../../ui/input/input';
import { InputRangeContainer } from '../../lineofbusiness/styles';
import { ColumnHeader } from '../../../ui/headers';
import { selectTheme } from '../../../ui/themes';
import { BiRefresh } from 'react-icons/bi';
import "react-datepicker/dist/react-datepicker.css";

const GoogleCampaignSetup = ({
        isEdit, lobName, templateName, searchSite, setSearchSite,
        name, setName, campaign, start, setStart, end, setEnd, budget, setBudget,
        objective, setObjective, campaignType, bidStrategy, useAdRotation, setUseAdRotation, audienceTargeting, setAudienceTargeting,
        minAge, setMinAge, maxAge, setMaxAge, genderTargets, setGenderTargets, maxIncomePercentage, setMaxIncomePercentage, minIncomePercentage, setMinIncomePercentage,
        locationTargets, setLocationTargets, languageTargets, setLanguageTargets, trackingCodes, setTrackingCodes, setCountries, countries, setLanguages, countryOptions, languages, languageOptions,
        searchNetwork, setSearchNetwork, desktopBid, setDesktopBid, tabletBid, setTabletBid, mobileBid, setMobileBid, displayNetwork, setDisplayNetwork, searchPartners, setSearchPartners,
        targetCpa, setTargetCpa, targetRoas, setTargetRoas, useEnhancedCpc , setUseEnhancedCpc, cpcBidLimit, setCpcBidLimit, adLocation, percentImpression, setPercentImpression
    }) => {
    const _D = useDispatch()

    const [objectiveOptions, setObjectiveOptions] = useState([]);
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
    const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
    const [languageTargetOptions, setLanguageTargetOptions] = useState([])

    const googleIcon = <img src={GoogleIcon} alt='img' />;

    useEffect(() => {
        googleObjectives(objectives => {
            const result = objectives.map(objective => {
                return (
                    {
                        label: objective,
                        value: objective
                    }
                )
            })
            setObjectiveOptions(result)
        })
    }, [campaign]);

    const genderDefaultValues = () => {
        return GENDER_OPTIONS?.filter(gender => {
            return genderTargets?.indexOf(gender.value) + 1;
        });
    }
    const selectedGenderList = () => genderDefaultValues()?.map(gender => gender.label).join(', ');
    const handleGenderTargetChange = (selectedOptions) => {
        const targets = selectedOptions?.map(opt => opt.value) || [];
        setGenderTargets(targets);
    }

    const getDefaultValues = (selectedOptions) => {
        return selectedOptions?.map(val => {
            return (
                {
                    label: val,
                    value: val
                }
            )
        })
    }

    const handleCountryChange = (selectedOptions) => {
        const selectedCountries = selectedOptions?.map(opt => opt.value) || [];
        setCountries(selectedCountries)
    }

    const handleLanguageChange = (selectedOptions) => {
        const selectedLanguages = selectedOptions?.map(opt => opt.value) || [];
        setLanguages(selectedLanguages)
    }

    // * Handle Saving Location (MEDIA PLAN LEVEL - LOB)
    const handleAddLocation = (updateObj, handleSuccess) => {
        _D(
            updateCampaign({
                mediaPlanCampaign: campaign?.websafe,
                obj: updateObj,
                callback: () => {
                    handleSuccess()
                    setLocationTargets(updateObj?.locationTargets)
                },
            })
        );
    }

    return (
        <StyledFieldsWrapper>
            <ColumnHeader> Flight Details </ColumnHeader>
            <OneThirdGridColContainer>
                <FieldContainer>
                <h3>
                    {googleIcon} Campaign Name&nbsp; {isEdit &&
                    <BiRefresh
                        color='#F8B133'
                        style={{cursor: 'pointer'}}
                        onClick={() => generateName({mediaPlanCampaign: campaign.websafe, callback: res => setName(res.name)})}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Generate campaign name or manually edit the campaign name"
                    />}
                </h3>
                {isEdit && <Tooltip id='my-tooltip' className='tooltip' classNameArrow='arrow' border={true} effect='solid' />}
                    {
                        isEdit ?
                        <Input value={name} onChange={e => setName(e.target.value)} placeholder={name || 'Enter name'} />
                        :
                        <p> {name || 'N/A'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> {googleIcon} Start Date {<span className='asterisk'>*</span>} </h3>
                    {
                        isEdit ?
                        <>
                        <DatePickerWrapper>
                        <DatePicker
                            customInput={<CustomDatePicker />}
                            minDate={new Date()}
                            wrapperClassName='datePicker'
                            onChange={(date) => setStart(date.getTime())}
                            selected={start && new Date(start)}
                        />
                        </DatePickerWrapper>
                        {!start && <p className="error">Please select Start Date</p>}
                        </>

                        :
                        <>
                            <p> {start ? new Date(start).toDateString() : 'N/A'} </p>
                            {!start && <p className="error">Please select Start Date</p>}
                        </>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> {googleIcon} End Date {<span className='asterisk'>*</span>} </h3>
                    {
                        isEdit ?
                        <>
                        <DatePickerWrapper>
                        <DatePicker
                            customInput={<CustomDatePicker />}
                            wrapperClassName='datePicker'
                            onChange={(date) => setEnd(date.getTime())}
                            selected={end && new Date(end)}
                            minDate={start + 60 * 60 * 24 * 1000}
                        />
                        </DatePickerWrapper>
                        {!end && <p className="error">Please select End Date</p>}
                        </>
                        :
                        <>
                             <p> {end ? new Date(end).toDateString() : 'N/A'} </p>
                             {!end && <p className="error">Please select End Date</p>}
                        </>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <FieldContainer>
                    <h3> {googleIcon} Budget {<span className='asterisk'>*</span>} </h3>
                    <Budget
                        campaign={campaign}
                        budget={budget}
                        setBudget={setBudget}
                        isEdit={isEdit}
                    />
                </FieldContainer>

                { campaign?.adAccount &&
                    <FieldContainer>
                        <h3> {googleIcon} Ad Account </h3>
                        <p> {campaign?.adAccount?.displayName} </p>
                        <p> Target CPA: ${campaign?.adAccount?.targetCpaMicros} </p>
                    </FieldContainer>
                }
            </OneThirdGridColContainer>

            <ColumnHeader>Campaign Name Details</ColumnHeader>
      <OneThirdGridColContainer>
        <FieldContainer>
          <h3>{googleIcon} Country or Region Code(s)</h3>
          {
            isEdit ? (
              <CreatableSelect
                isClearable
                isMulti
                options={countryOptions}
                styles={selectTheme}
                defaultValue={getDefaultValues(campaign?.countryCodes)}
                onChange={(e) => handleCountryChange(e)}
                placeholder='Select Country or Enter Region Code'
              />
            ) :
            <p> {countries?.length ? countries.join(', ') : 'N/A'} </p>
          }
        </FieldContainer>
        <FieldContainer>
          <h3>{googleIcon} Language(s)</h3>
          {
            isEdit ? (
              <Select
                isClearable
                isMulti
                options={languageOptions}
                styles={selectTheme}
                defaultValue={getDefaultValues(campaign?.languageCodes)}
                onChange={(e) => handleLanguageChange(e)}
                placeholder='Select Language(s)'
              />
            ) : <p>{languages.length ? languages.join(', ') : 'N/A'}</p>
          }
        </FieldContainer>
      </OneThirdGridColContainer>

        {lobName && <>
        <ColumnHeader> Originally Derived from the LOB: {lobName} </ColumnHeader>

        <OneThirdGridColContainer>
            <FieldContainer>
                <h3> Age </h3>
                {
                    isEdit ?
                    <InputRangeContainer>
                        <NumberInput type='number' value={minAge} onChange={e => setMinAge(parseInt(e.target.value))} />
                        <span> to </span>
                        <NumberInput type='number' value={maxAge} onChange={e => setMaxAge(parseInt(e.target.value))} />
                    </InputRangeContainer>
                     :
                    <p> {minAge} - {maxAge} </p>
                }
            </FieldContainer>

            <FieldContainer>
                <h3> Gender </h3>
                {
                    isEdit ?
                    <Select
                        isClearable
                        isMulti
                        options={GENDER_OPTIONS}
                        defaultValue={genderDefaultValues()}
                        styles={selectTheme}
                        onChange={e => handleGenderTargetChange(e)}
                    /> :
                    <p> {selectedGenderList() || 'N/A'} </p>
                }
            </FieldContainer>

            <FieldContainer>
                <h3> House Hold Income Range </h3>
                {
                    isEdit ?
                    <InputRangeContainer>
                        <NumberInput type='number' value={minIncomePercentage} onChange={e => setMinIncomePercentage(parseInt(e.target.value))} />
                        <span> to </span>
                        <NumberInput type='number' value={maxIncomePercentage} onChange={e => setMaxIncomePercentage(parseInt(e.target.value))} />
                    </InputRangeContainer> :
                    <p>{minIncomePercentage || 0} - {maxIncomePercentage || 0} </p>
                }
            </FieldContainer>
        </OneThirdGridColContainer>

        <OneThirdGridColContainer>
            <LanguageTargets
                isEdit={isEdit}
                languageTargets={languageTargets}
                languageTargetOptions={languageTargetOptions}
                setLanguageTargets={setLanguageTargets}
            />

            <LocationTargets
                locationTargets={locationTargets}
                setLocationTargets={setLocationTargets}
                isEdit={isEdit}
            />
        </OneThirdGridColContainer>

        <OneThirdGridColContainer>
            <FieldContainer>
            <h3> Business Website </h3>
            {
                isEdit ?
                <Input defaultValue={campaign?.searchSite} onChange={e => setSearchSite(e.target.value)} placeholder={campaign?.searchSite || 'Enter Business Website'} />
                :
                <p> {searchSite || 'N/A'} </p>
            }
            </FieldContainer>
        </OneThirdGridColContainer>
        </>}

        <ColumnHeader> Originally Derived from the {templateName} Template </ColumnHeader>
            <OneThirdGridColContainer>
                <CampaignType
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    campaignType={campaignType}
                />
                <CampaignObjective
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    objectiveOptions={objectiveOptions}
                    defaultValue={campaign?.objective}
                    objective={objective}
                    setObjective={setObjective}
                />
                <SearchNetwork
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    searchNetwork={searchNetwork}
                    setSearchNetwork={setSearchNetwork}
                />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <DisplayNetwork
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    displayNetwork={displayNetwork}
                    setDisplayNetwork={setDisplayNetwork}
                />
                <SearchPartners
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    searchPartners={searchPartners}
                    setSearchPartners={setSearchPartners}
                />
                <AdRotation
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    useAdRotation={useAdRotation}
                    setUseAdRotation={setUseAdRotation}
                />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <AudienceTargetting
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    setAudienceTargeting={setAudienceTargeting}
                    audienceTargeting={audienceTargeting}
                />
                <DesktopBidding
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    setDesktopBid={setDesktopBid}
                    desktopBid={desktopBid}
                />
                <MobileBidding
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    setMobileBid={setMobileBid}
                    mobileBid={mobileBid}
                />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <TabletBidding
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    setTabletBid={setTabletBid}
                    tabletBid={tabletBid}
                />
                <BiddingStrategy
                    googleIcon={googleIcon}
                    isEdit={isEdit}
                    bidStrategy={bidStrategy}
                />
                {
                    bidStrategy === 'Manual CPC' &&
                    <EnhancedCpc
                        googleIcon={googleIcon}
                        isEdit={isEdit}
                        setUseEnhancedCpc={setUseEnhancedCpc}
                        useEnhancedCpc={useEnhancedCpc}
                    />
                }
                {
                    bidStrategy === 'Conversions' &&
                    <TargetCpa
                        currency={campaign?.adAccount?.currency}
                        googleIcon={googleIcon}
                        isEdit={isEdit}
                        setTargetCpa={setTargetCpa}
                        targetCpa={targetCpa}
                    />
                }
                {
                    bidStrategy === 'Conversion Value' &&
                    <TargetRoas
                        googleIcon={googleIcon}
                        isEdit={isEdit}
                        targetRoas={targetRoas}
                        setTargetRoas={setTargetRoas}
                    />
                }
                {
                    (bidStrategy === 'Clicks' || bidStrategy === 'Impression Share') &&
                    <CpcBidLimit
                        googleIcon={googleIcon}
                        isEdit={isEdit}
                        currency={campaign?.adAccount?.currency}
                        cpcBidLimit={cpcBidLimit}
                        setCpcBidLimit={setCpcBidLimit}
                        showRequired={bidStrategy === 'Impression Share'}
                    />
                }
            </OneThirdGridColContainer>

            {
                bidStrategy === 'Impression Share' &&
                <OneThirdGridColContainer>
                    <AdLocation
                        googleIcon={googleIcon}
                        isEdit={isEdit}
                        adLocation={adLocation}
                    />
                    <PercentImpression
                        googleIcon={googleIcon}
                        isEdit={isEdit}
                        percentImpression={percentImpression}
                        setPercentImpression={setPercentImpression}
                    />
                </OneThirdGridColContainer>
            }

            {
                isLocationModalOpen &&
                <LocationModal
                    onClose={() => setIsLocationModalOpen(false)}
                    handleTargetUpdate={handleAddLocation}
                    locationTargets={locationTargets}
                />
            }
            {
                isTrackingModalOpen &&
                <TrackingModal
                    onClose={() => setIsTrackingModalOpen(false)}
                    handleTargetUpdate={setTrackingCodes}
                    trackingCodes={trackingCodes}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default GoogleCampaignSetup;