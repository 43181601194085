export const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    for (const item in a) {
        if (b.indexOf(a[item]) < 0) return false;
    }

    return true;
}

export const isObjArraysEqual = (a, b) => {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a?.length !== b?.length) return false;

    const aVals = Object.values(a).sort();
    const bVals = Object.values(b).sort();

    return JSON.stringify(aVals) === JSON.stringify(bVals);
}

// * Will take an array of options and return a list of dropdown options --- assuming label/value are the same
export const generateDropdownOptions = (options=[], key) => {
    const dropdownOptions = [];

    options.forEach(option => {
        if (key) dropdownOptions.push({ label: option[key], value: option[key]})
        else dropdownOptions.push({ label: option, value: option })
    })

    return dropdownOptions
}



export const joinObjectLabels = (values) => {
    if (!values) return '';
    if(!Array.isArray(values)) return values?.label
    return values.map(val => val?.label).join(', ');
}