export function sortObjectsByName(arr) {
    if(!arr) return []
    return arr.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        } else {
            return 0;
        }
    });
}