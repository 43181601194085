import React, { useState, useEffect } from 'react';
import BuildModal from '../../../../components/Modal/modals/BuildModal';
import AddKeywordStep from '../../../lineofbusiness/components/modal/GoogleSearchAdGroups/AddKeywordStep';
import AddAudienceSegmentStep from '../../../lineofbusiness/components/modal/GoogleSearchAdGroups/AddAudienceSegmentStep';
import ReviewAdGroupStep from '../../../lineofbusiness/components/modal/GoogleSearchAdGroups/ReviewAdGroupStep';

// Redux
import { updateAdGroup } from '../../../../actions/mediaActions';


// Styles
import { toast } from 'react-toastify';
import { BlackBtn, OutlineBtn } from '../../../../ui/button';

const EditAdGroupModal = ({onClose, posKeywords, negKeywords, includedSegments, excludedSegments, currAdGroup, campaign, reset}) => {
    const [step, setStep] = useState(1);
    const [keywords, setKeywords] = useState({});
    const [negativeKeywords, setNegativeKeywords] = useState({});
    const [matchAudienceSegment, setMatchAudienceSegment] = useState();
    const [excludeAudienceSegment, setExcludeAudienceSegment] = useState();
    const [includeAudiences, setIncludeAudiences] = useState([]);
    const [excludeAudiences, setExcludeAudiences] = useState([]);

    useEffect(() => {
        setKeywords(posKeywords);
        setNegativeKeywords(negKeywords);
        setMatchAudienceSegment(includedSegments);
        setExcludeAudienceSegment(excludedSegments);
    }, [posKeywords, negKeywords, includedSegments, excludedSegments]);

    const getDefaultMatchType = (isNegative) => {
        const keywordTypes = ['EXACT', 'BROAD', 'PHRASE'];

        if (!posKeywords || !negKeywords ) return;
        const currType = Object.keys(isNegative ? negKeywords : posKeywords).find(type => keywordTypes.includes(type))
        return { label: currType, value: currType }
    }

    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }

    const handleSaveInterests = (include, exclude) => {
        setMatchAudienceSegment(include);
        setExcludeAudienceSegment(exclude);
        handleNext();
    }

    const handleSaveKeywords = (keywordsObj, negativeKeywordsObj) => {
        setKeywords(keywordsObj);
        setNegativeKeywords(negativeKeywordsObj);
        handleNext();
    }

    const handleSkipAdGroup = () => {
        setMatchAudienceSegment([]);
        setExcludeAudienceSegment([]);
        handleNext();
    }

    const handleSave = () => {
        const updateObj = {
            includedSegments: matchAudienceSegment,
            excludedSegments: excludeAudienceSegment,
            keywords: keywords,
            negativeKeywords: negativeKeywords
        };

        updateAdGroup({
            mediaPlanAdGroup: currAdGroup?.websafe,
            obj: {audienceTarget: updateObj},
            callback: (res) => {
                toast.success('Updated Ad Group');
                reset();
                onClose();
            }
        })

    }

    const steps = () => {
        switch (step) {
            case 1: return <AddKeywordStep
                description='Edit Ad Group Keywords'
                setPositiveKeywords={setKeywords}
                positiveKeywords={keywords}
                negativeKeywords={negativeKeywords}
                setNegativeKeywords={setNegativeKeywords}
                isEditingAdGroup={true}
                getDefaultMatchType={getDefaultMatchType}
            />
            case 2: return <AddAudienceSegmentStep
                matchAudienceSegment={matchAudienceSegment} excludeAudienceSegment={excludeAudienceSegment} isEditingAdGroup={true}
                adAccount={campaign?.adAccount} includeAudiences={includeAudiences} setIncludeAudiences={setIncludeAudiences} excludeAudiences={excludeAudiences} setExcludeAudiences={setExcludeAudiences}
            />
            case 3: return <ReviewAdGroupStep
                keywords={keywords}
                negativeKeywords={negativeKeywords}
                matchAudienceSegment={matchAudienceSegment}
                excludeAudienceSegment={excludeAudienceSegment}
            />
            default:
              console.log(`No step selected`);
        }
    }

    const decideButtons = () => {
        switch(step) {
            case 1: return [<BlackBtn onClick={() => handleSaveKeywords(keywords, negativeKeywords)}> Next </BlackBtn>]
            case 2: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <OutlineBtn onClick={handleSkipAdGroup}> Skip </OutlineBtn>, <BlackBtn onClick={() => handleSaveInterests(includeAudiences, excludeAudiences)}> Next </BlackBtn>]
            case 3: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BlackBtn onClick={handleSave}> Update Google Ad Group </BlackBtn>]
            default: return []
        }
    }

    return (
        <BuildModal
            header='Edit Ad Group'
            onClose={onClose}
            buttons={decideButtons()}
            height={"750px"}
            minWidth={"800px"}
        >
            {steps()}
        </BuildModal>
    )
}

export default EditAdGroupModal;