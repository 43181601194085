import React, { useState, useEffect } from "react";
import TemplateNavigation from "./TemplateNavigation";
import TempDetails from "./TempDetails";

import GoogleCampaignDetails from "./GoogleCampaignDetails";
import FacebookCampaignDetails from "./FacebookCampaignDetails";
import LinkedinCampaignDetails from "./LinkedinCampaignDetails"

import DummyCampaignDetails from './DummyCampaignDetails';

import DeleteModal from '../../../components/Modal/DeleteModal';

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateTemplate,
  deleteCampaign,
  updateTemplateCampaign,
  deleteNetworkTemplate,
  setSelectedCampaign
} from "../../../actions/tempActions";

// Styles
import { CreateTemplateContainer, StyleTempHeader } from "../styles";
import { PageContainer } from "../../../ui/containers";
import { toast } from "react-toastify";

// Consts
import { TEMP_NAV } from "../../../constants/temp";
import Loader from "../../../ui/Loader";
import { deleteTemplateText, renderLogo } from "../templates.util";
import { PageHeader } from "../../../ui/headers";
import { RedBtn } from "../../../ui/button";
import { NETWORKS } from "../../../constants/networks";

const EditTemplates = ({ setIsEdit }) => {
  const [templateLoader, setTemplateLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false); // * Loader for Template Deletion
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // * For Deleting Template
  const [currSelector, setCurrSelector] = useState(TEMP_NAV.CONFIG_DETAILS);
  const [isEditingDetails, setIsEditingDetails] = useState(false);

  // Selectors
  const template = useSelector(state => state.template.currentNetworkTemplate);
  const dummyNetworks = useSelector(state => state?.org?.organization?.dummyNetworks);
  const isEditing = isEditingDetails


  const _D = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },[])

  useEffect(() => {
    setIsEditingDetails(false)
  }, [currSelector])

  const handleUpdate = (updateObj) => {
    if (Object.keys(updateObj).length < 1) return;

    _D(
      updateTemplate({
        websafe: template.websafe,
        updateObj,
        callback: res => {
            toast.success(`${res.name} template updated`);
        },
        errorCallback: () => toast.error('Something went wrong!')
      })
    );
  };



  const handleUpdateCampaign = updateObj => {
    setTemplateLoader(true);
      _D(updateTemplateCampaign({
        websafe: template.websafe,
        networkTemplateCampaignWebsafe: updateObj.websafe,
        updateObj,
        callback: (res) => {
          setCurrSelector(TEMP_NAV.CAMPAIGN_DETAILS);
          _D(setSelectedCampaign(res))
          toast.success(`${res.name} campaign updated`);
          setTemplateLoader(false);
        },
        errorCallback: () => {
          toast.error('Something went wrong');
          setTemplateLoader(false);
        }
      }))
  };

  const handleDeleteCampaign = deleteObj => {
    _D(deleteCampaign({
      websafe: template.websafe,
      networkTemplateCampaignWebsafe: deleteObj.websafe,
      callback: () => {
        setCurrSelector(TEMP_NAV.CONFIG_DETAILS);
        _D(setSelectedCampaign({}))
        toast.success(`${deleteObj.name} campaign deleted`);
      },
      errorCallback: () => toast.error('Something went wrong')
    }))
  };
  // * Deleting Network Template
  const handleDeleteTemplate = () => {
    setDeleteLoader(true);
    _D(deleteNetworkTemplate({
      websafe: template.websafe,
      callback: () => {
        setIsDeleteModalOpen(false);
        setDeleteLoader(false);
        setIsEdit(false);
        toast.success(`Deleted ${template?.name}`);
      },
      errorCallback: () => {
        toast.error('Something went wrong');
      }
    }))
  }
  const renderSelectedComponent = () => {
    switch (currSelector) {
      case TEMP_NAV.CONFIG_DETAILS:
        return <TempDetails
        handleUpdate={handleUpdate}
        setIsEditingDetails={setIsEditingDetails}
        isEditingDetails={isEditingDetails}
      />;
      case TEMP_NAV.CAMPAIGN_DETAILS:
        if (template.network === NETWORKS.FACEBOOK) {
          return (
            <FacebookCampaignDetails // make facebookObjectves call in here
              handleUpdate={handleUpdateCampaign}
              handleDeleteCampaign={handleDeleteCampaign}
            />
          )
        }

        if (template.network === NETWORKS.GOOGLE) {
          return (
            <GoogleCampaignDetails
              handleUpdate={handleUpdateCampaign}
              handleDeleteCampaign={handleDeleteCampaign}
            />
          )
        }

        if (template.network === NETWORKS.LINKEDIN) {
          return (
            <LinkedinCampaignDetails
              handleUpdate={handleUpdateCampaign}
              handleDeleteCampaign={handleDeleteCampaign}
            />
          )
        }

        return (
          <DummyCampaignDetails
            handleUpdate={handleUpdateCampaign}
            handleDeleteCampaign={handleDeleteCampaign}
          />
        )
      default:
        console.error("No selection made");
        break;
    }
  };

  if (templateLoader) return  <PageContainer style={{height: "90vh"}}><Loader size={"44"} /></PageContainer>;

  return (
    <CreateTemplateContainer>
      <TemplateNavigation
        setIsEdit={setIsEdit}
        currSelector={currSelector}
        setCurrSelector={setCurrSelector}
      />
      <PageContainer>
        <StyleTempHeader>
          <div className="template-header-tx">
            {renderLogo({ dummyNetworks: dummyNetworks, data: template})}
            <PageHeader>{isEditing ? `Edit ${template?.name}` : template?.name} </PageHeader>
          </div>
          <RedBtn onClick={() => setIsDeleteModalOpen(true)}>Delete Template</RedBtn>
        </StyleTempHeader>
        {renderSelectedComponent()}
        </PageContainer>
      {
        isDeleteModalOpen &&
        <DeleteModal
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          handleDelete={handleDeleteTemplate}
          header={deleteTemplateText?.header}
          deleteBtnText={deleteTemplateText?.deleteBtnText}
          modalText={deleteTemplateText?.modalText + template.name + '?'}
          network={template.network}
          loading={deleteLoader}
        />
      }
    </CreateTemplateContainer>
  );
};

export default EditTemplates;
