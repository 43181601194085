import { useEffect, useState } from 'react'
import { objectToReactSelectItem } from './templates.util';
import { toast } from 'react-toastify';

// Redux
import { getNetworkObjectives } from '../../actions/tempActions';
import { linkedInObjectiveMap } from '../../actions/mediaActions';


const useLinkedinCampaign = ({campaign}) => {
    const [objectives, setObjectives] = useState({})

    const [selectedObjective, setSelectedObjective] = useState(null);
    const [mapedOptimization, setMapedOptimization] = useState([]);
    const [mapedBidStrategies, setMapedBidStrategies] = useState([]);
    const [objectiveMap, setObjectiveMap] = useState({});
    const [showPlacements, setShowPlacements] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const INITIAL_DESABLE_PROPS = {
            objectives: false,
            optimization: true,
            bidStrategy: true,
        }

    const [disabledProps, setDisabledProps] = useState(INITIAL_DESABLE_PROPS );

    const selectObjective = (objectiveName) => {
        const objective = objectives?.[objectiveName?.label || objectiveName]
        if (objectiveName.label === 'Lead Generation') {
          setShowPlacements(false);
        }
        if (!objective) return;

          setMapedOptimization(objectToReactSelectItem(objective?.optimizations))
          setSelectedObjective(objective);
          setDisabledProps((prew) => ({ ...prew, optimization: false }));
      };


      const selectOptimization = (optimizationName) => {
        const optimization = selectedObjective?.optimizations?.[optimizationName?.label || optimizationName]
        const placement = optimization?.placements || {};
        if (Object.keys(placement)?.length > 0) setShowPlacements(true);
        if (!optimization) return;
        const bidStrategies = objectToReactSelectItem(optimization?.bidStrategies)
        setMapedBidStrategies(bidStrategies);
        setDisabledProps((prew) => ({ ...prew, bidStrategy: false }));

        return;
      };

    useEffect(() => {
        getNetworkObjectives('linkedin')
          .then((res) => setObjectives(res))
          .catch(_ => toast.error("Something went wrong"));
      },[])

    useEffect(() => {
      const { objective, optimization } = campaign;
      setIsLoading(true);
      linkedInObjectiveMap({
        callback: (res) => {
          const placement = res?.[objective]?.optimizations?.[optimization]?.placements || {};
          setShowPlacements(Object.keys(placement)?.length > 0);
          setObjectiveMap(res);
          setIsLoading(false);
        }
      })
    }, [campaign.websafe])

    useEffect(() => {
      const placement = objectiveMap?.[campaign?.objective]?.optimizations?.[campaign?.optimization]?.placements || {};
      setShowPlacements(Object.keys(placement)?.length > 0);
    }, [campaign.objective, campaign.optimization])

    return ( {
        selectObjective,
        selectOptimization,
        setDisabledProps,
        showPlacements,
        isLoading,
        disabledProps,
        INITIAL_DESABLE_PROPS,
        _M:{
            mapedObjectives: objectToReactSelectItem(objectives),
            mapedOptimization,
            mapedBidStrategies,
        }
    }
    )
}

export default useLinkedinCampaign