import styled from "styled-components";

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "GothamMedium";
  text-align: left;
  color: ${(props) => props.theme.modal.gray};
  width: 100%;
  gap: 10px;
  margin: 20px 0;
  justify-content: center;

  .datePicker {
    all: unset;
    border: 1px solid ${(props) => props.theme.input.gray};
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
      margin-right: 5px;
    }

    img {
      width: 17px;
      margin-right: 5px;
    }
  }

  p {
    font-family: "GothamBook";
    font-size: 14px;
    font-weight: 400;
  }

  span > .asterisk {
    vertical-align: sub;
  }

  .asterisk {
    color: ${(props) => props.theme.color.yellow};
  }
`;

export const StyleFrequencyFields = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  display: flex;
  width: 110%;
  .number-input_wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .last-item {
    margin-left: 0.5rem;
  }
  p {
    font-size: 14px;
  }
`;
