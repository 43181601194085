import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import FbIcon from '../../../../assets/images/socialMediaIcons/FbIcon.png';
import { BiTrash } from 'react-icons/bi';
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles';
import { toast } from 'react-toastify';
import DeleteTargetingModal from '../modal/DeleteTargetingModal';
import { FiPlusCircle } from 'react-icons/fi';
import { removeSocialAccount } from '../../../../actions/lobActions';


const FacebookPagesTable = ({isEdit, tableData, handleOnCreate, fbConfigId}) => {
    const _D = useDispatch()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState();
    const [loader, setLoader] = useState(false)
    const facebookIcon = <img src={FbIcon} alt='img' />;

    // * Handle Item Selection - depending on the action type this will either edit/delete the item
    const handleItemSelection = (data) => {
        setSelectedItem(data)
        setIsDeleteModalOpen(true)
    }

    const renderData = () => {
        return tableData.map(data => {
            return (
                <li key={data?.accountId}>
                    <ListObj>
                        <span>{data?.displayName}</span>
                    </ListObj>
                    <div className='opt__button__container'>
                        {isEdit && <BiTrash onClick={() => handleItemSelection(data)} />}
                    </div>
                </li>
            )
        })
    }

    // * Handle Delete Facebook Page
    const handleDeleteAdAccount = () => {
        setLoader(true)
        _D(removeSocialAccount({
            socialConfig: `fbconfig=${fbConfigId}`,
            obj: [{accountId: selectedItem?.accountId, network: 'facebook'}],
            callback: () => {
                toast.success('Removing Facebook Page');
                setIsDeleteModalOpen(false)
                setLoader(false)
            }
        }))
    }


    return (
        <TableContainer>
            <TableHeader>
                <h3> {facebookIcon} Facebook Page </h3>
                {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
            </TableHeader>

            {
                Object.keys(tableData || {})?.length > 0 ?
                    renderData() :
                    <EmptyDataContainer>
                        <p> No Facebook Pages Available </p>
                    </EmptyDataContainer>
            }

            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={handleDeleteAdAccount}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title='Facebook Ad Accounts'
                    item={selectedItem}
                    useLoader
                    loading={loader}
                />
            }
        </TableContainer>)
}

export default FacebookPagesTable