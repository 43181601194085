import React from 'react';


// Styles
import { SubNavigationContainer, SubNavItem } from './styles';

const SubNavigation = ({navigationObj, currNav, setCurrNav}) => {
    const navigation = navigationObj?.map(nav => {
        const { name } = nav;
        return <SubNavItem key={name} isSelected={currNav === name} onClick={() => setCurrNav(name)}> {name} </SubNavItem>
    });

    const selectedComponent = navigationObj?.find(nav => nav.name === currNav);
    return (
        <>
        <SubNavigationContainer>
            {navigation}
        </SubNavigationContainer>
            {selectedComponent?.component}
        </>
    )
}

export default SubNavigation;