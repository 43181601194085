import { Input } from "../../../../ui/input/input";

const CampaignNameField = ({name, setName, isEdit, FieldContainer }) => {
    return (
        <FieldContainer>
            <h3> Campaign Name </h3>
            { isEdit ? <Input value={name} onChange={e => setName(e.target.value)}/> : <p> {name || 'N/A'} </p> }
        </FieldContainer>
    )
}

export default CampaignNameField;