import React from 'react';
import Select from 'react-select';

// Styles
import { HeaderInputContainer, ModalText, ModalTextSecondary } from '../../../../components/Modal/styles';
import { selectTheme } from '../../../../ui/themes';
import { useSelector } from 'react-redux';

const AdvertiserStep = ({network, register, setValue, setTempAdvertiser}) => {
    const advertiserList = useSelector(state => state.org.advertisers) || []
    let advertiserOptions  = [{label: 'All Advertisers (System Network Template)', value: null}, ...advertiserList.map(it => ({label: it.name, value: it.websafe}))]

    const handleSelectAdvertiser = (e) => {
        register('advertiser', { value: e.value })
        setValue('advertiser', e.value);
        setTempAdvertiser(e.value);
        if (e.label.includes('All')) setTempAdvertiser('All');
    }

    return (
        <div>
            <ModalText> Select <span className='bold'>ALL</span> advertiser or select <span className='bold'>one</span> advertiser for this {network} network template </ModalText>

            <HeaderInputContainer>
                <ModalTextSecondary> Advertiser <span className='asterisk'>*</span> </ModalTextSecondary>
                <Select
                    isClearable
                    options={advertiserOptions}
                    menuPortalTarget={document.body}
                    styles={selectTheme}
                    onChange={e => handleSelectAdvertiser(e)}
                />
            </HeaderInputContainer>
        </div>
    )
}

export default AdvertiserStep;