import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { StyleHeader } from "./styles";

const Header = ({ headerText, logo, handleOnChange, howToData}) => {
  const handleOnHeaderClick = () => {
    handleOnChange(howToData)
  }
  return (
    <StyleHeader>
      <div>
        {logo}
        <h3>{headerText}</h3>
      </div>
      {howToData && <IoIosInformationCircleOutline  onClick={handleOnHeaderClick} />}
    </StyleHeader>
  );
};

export default Header;
