import React from 'react';

// Styles
import { AudienceTargetListContainer, ReviewInterestTargetContainer } from '../styles';
import { TableModalContainer } from '../../../../../ui/table/styles';
import { ModalTextSecondary } from '../../../../../components/Modal/styles';

const ReviewAdGroupStep = ({keywords, negativeKeywords, matchAudienceSegment, excludeAudienceSegment}) => {
    const excludedAudienceList = excludeAudienceSegment?.map(interest => interest.name).join(', ');
    const squishMatchAudienceSegment = matchAudienceSegment?.filter(group => group.length);

    const keywordsList = obj => {
        if (Object.keys(obj || {}).length === 0) return 'No keywords';

        const result = [];
        for (const key in obj) {
            for (const item in obj[key]) {
                result.push(`${obj[key][item].filename} (${key})`)
            }
        }

        return result.join(', ');
    }

    const matchAudienceSegmentList = squishMatchAudienceSegment?.length > 0 ? squishMatchAudienceSegment?.map((group, idx) => {
        let subMatchAudienceSegmentList = group?.map(interest => interest.name);

        return (
            <ReviewInterestTargetContainer key={idx}>
                <span className='green'>{idx === 0 ? 'People Who Match' : 'And Must Also Match'}</span> : &nbsp;
                <h3>{subMatchAudienceSegmentList?.join(', ')}</h3>
            </ReviewInterestTargetContainer>
        )
    }) :
    <ReviewInterestTargetContainer>
        <span className='green'>People Who Match</span> : &nbsp;
        <h3>No Match</h3>
    </ReviewInterestTargetContainer>

    return (
        <TableModalContainer>
            <ModalTextSecondary> Google Search Ad Group </ModalTextSecondary>
            <AudienceTargetListContainer>
                <h3><span className='green'> Keywords</span>: {keywordsList(keywords)}</h3>
                <h3><span className='red'> Negative Keywords</span>: {keywordsList(negativeKeywords)}</h3>
                <h3>{matchAudienceSegmentList}</h3>
                <h3><span className='red'> Exclude People Who Match</span>: {excludedAudienceList.length ? excludedAudienceList : 'No Match'}</h3>
            </AudienceTargetListContainer>
        </TableModalContainer>
    )
}

export default ReviewAdGroupStep;