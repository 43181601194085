// * selectedCatory - derived from category dropdown, used to help generate subcategory options

import { useState } from "react";
import { toast } from "react-toastify";
import { getFacetCategory, getFacetSubCategory, getSubcategoryDesc } from "../modals/linkedinAudience/linkedInTargets.utils";

const useLinkedInTargets = ({ selectedCategory }) => {
    const [linkedInTargetFacets, setLinkedInTargetFacets] = useState({});

    // * Fetches master list of targeting facets for linkedin (used to help generate suboptions)
    const getLinkedInFacets = (cb) => {
        // * Prevents api call from being made if linkedInTargetFacets already exist
        if (Object.keys(linkedInTargetFacets)?.length) {
            cb?.()
            return
        }

        window.eulerity.makeApiCall({
            url: '/api/linkedin/getAllFacets',
            method: 'GET',
            callback: (resp) => {
                let facetArrObj = {}
                resp.forEach((facet) => {
                    const { facetName, urn, entityTypes, availableEntityFinders } = facet
                    return facetArrObj[facetName.toUpperCase()] = {
                        facetName,
                        urn,
                        entityTypes,
                        availableEntityFinders,
                        category: getFacetCategory(urn),
                        subCategory: getFacetSubCategory(urn),
                        desc: getSubcategoryDesc(urn)
                    }
                })
                setLinkedInTargetFacets(facetArrObj)
                cb?.()
            },
            retry: 3,
            errorCallbacks: {
                1000: () => {
                    toast.error('Something went wrong, please try again later!')
                }
            }
        });
    }

    // * Generates and configures subcategory options - list is dynamically created as options depend on category
    const getSubCategoryOptions = (targetFacets) => {
        if (!selectedCategory) return
        const filteredTargets = Object.values(targetFacets)?.filter((target) => target.category.includes(selectedCategory?.value))

        return filteredTargets.map((tar) => {
            return {
                label: tar.subCategory,
                value: tar.subCategory,
                urn: tar.urn,
                desc: tar.desc,
                availableEntityFinders: tar.availableEntityFinders
            }})
    }

    return (
        {
            getLinkedInFacets,
            getSubCategoryOptions,
            linkedInTargetFacets,
        }
    )
}

export default useLinkedInTargets