import styled, {css} from 'styled-components';

export const TableContainer = styled.div`
    font-family: 'GothamBook';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: 20px 0;

    li {
        background-color: ${props => props.theme.table.white};
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            font-size: 18px;
        }

        .opt__button__container {
            max-width: 40px;
        }
    }

    li:nth-child(odd) {
        background-color: ${props => props.theme.table.gray};
    }

    svg {
        margin-left: 2px;
        cursor: pointer;
    }
`;

export const TableHeader = styled.div`
    border-radius: 9px 9px 0px 0px;
    border: 1px solid ${props => props.theme.input.gray};
    padding: 12px 18px;
    display: flex;
    white-space: nowrap;
    font-size: 18px;
    align-items: center;
    justify-content: space-between;

    h3 {
        font-family: 'GothamMedium';
        font-weight: 500;
        font-size: 14px;
        margin-right: 20px;
        display: flex;
        align-items: center;

        svg {
            font-size: 20px;
            margin-right: 5px;
        }

        img {
            width: 24px;
            margin-right: 5px;
        }
    }
`;

export const TableModalContainer = styled.div`
    p {
        margin: 20px 0 20px 0;
    }
    button {
        width: fit-content;
        height: fit-content;
        padding: 10px 20px;
    }
    .btn-container {
        display: flex;
        justify-content: center;
        background-color: rebeccapurple;
    }
    .button_spacing {
        padding: 20px;
        width: 100%;
    }

    .network-header {
        font-size: 18px;
        font-weight: 500;
        margin-top: 15px;
    }

    .header-seperator {
        height: 1px;
        border: 3.5px solid #F8F3EB;
        margin: 10px 0px;
    }

    .search-filter-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .network-cards-container {
        height: 350px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

export const CsvTableContainer = styled.div`
    width: fit-content;
    li {
        background-color: ${props => props.theme.table.white};
        padding: 10px 20px;
        display: grid;
        grid-template-columns: 1fr 3fr;
        justify-content: space-between;
        align-items: center;
        font-family: 'GothamBook';
        font-weight: 400;
        font-size: 11px;

        span {
            display: block;
            word-wrap: nowrap;
            overflow: ellipsis;
            width: 100%;
        }
    }

    li:nth-child(odd) {
        background-color: ${props => props.theme.table.gray};
    }

    li > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        svg {
            font-size: 16px !important;
            cursor: pointer;
        }
    }

    .csvNames {

    }
`;

export const CsvTableHeader = styled.div`
    display: flex;
    /* justify-content: space-between; */
    border: 1px solid ${props => props.theme.input.gray};
    border-radius: 5px 5px 0 0;
    width: fit-content;
    align-items: center;

    h3 {
        display: flex;
        color: ${props => props.theme.modal.gray};
        min-width: 200px;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        justify-content: space-between;
        padding: 12px 10px;
    }

    div {
        display: flex;
    }

    svg {
        cursor: pointer;
    }
`;

export const CsvSelectContainer = styled.div`
    border: 1px solid ${props => props.theme.color.mediumGray};
    color: ${props => props.theme.color.gray};
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    margin: 20px auto;
    cursor: pointer;
    width: 100%;
    background: rgba(247, 247, 247, 0.5);

    ${
        props => props.minHeight && css`
            min-height: 300px;
        `
     }
    img {
        width: 100px;
    }

    svg {
        color: ${props => props.theme.color.yellow};
    }
`;

export const EmptyDataContainer = styled.div`
    height: 144px;
    background-color: ${props => props.theme.table.gray};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-family: 'GothamBook';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
`;

export const DeleteList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border: 1px solid white;
    padding: 0 10px;

    ${({ isSelected }) => isSelected && css`
            border: 1px solid ${props => props.theme.color.yellow};
            background-color: rgba(248, 243, 235, 0.5);
        `
    }

    p {
        vertical-align: middle;
        margin: 0;
    }
`;

export const ListObj = styled.div`
    font-family: 'GothamMedium';
    display: flex;
    flex-direction: column;
    flex: 1;

    span:nth-child(2), span:nth-child(3), .google-ad-acc-item {
        font-family: 'GothamLight';
        font-size: 12px;
        font-weight: 300;
    }
`;

export const TableListItem = styled.li`
    div {
        display: flex;
        flex-direction: column;
        font-family: 'GothamMedium';
        font-weight: 500;

        span {
            font-family: 'GothamLight';
        }
    }
`;