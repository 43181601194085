import React, { useState, useEffect, useContext } from 'react';
import CreatableSelect from 'react-select/creatable';
import Loader from '../../../ui/Loader';
import Select from 'react-select';
import EditContext from './Context/EditContext';

// Utils
import { shouldUpdateString } from '../../../utils/payload';
import { toast } from 'react-toastify';
import { isDuplicateName } from '../../../utils';
import { isObjArraysEqual } from '../../../utils/arrays';

// Redux
import { useSelector } from 'react-redux';
import { getAdvertiser } from '../../../actions/systemConfigActions';

// Styles
import { Input, Textarea } from '../../../ui/input/input';
import { FieldContainer, StyledFieldsWrapper } from '../../../ui/containers';
import { FieldColContainer } from '../styles';
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn } from '../../../ui/button';
import { selectTheme } from '../../../ui/themes';


const LobDescription = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedLabels, setSelectedLabels] = useState('');
    const [lobProduct, setLobProduct] = useState('');
    const [advertiser, setAdvertiser] = useState({});

    const lineOfBusinessList = useSelector(state => state.lob.lineOfBusinessList);
    const lob = useSelector(state => state.lob.currentLineOfBusiness);

    const { isEdit, setIsEdit, handleUpdateLob } = useContext(EditContext);

    useEffect(() => {
        if (!lob) return;
        if (lob?.name) setName(lob.name);
        if (lob?.description) setDescription(lob.description);
        if (lob?.selectedLabels) setSelectedLabels(lob.selectedLabels);
        if (lob?.lobProduct) setLobProduct(lob.lobProduct);
        if (lob?.advertiser?.websafe) {
            getAdvertiser({
                websafe: lob.advertiser.websafe,
                callback: (res) => {
                    setAdvertiser(res);
                }
            })
        }
    }, [lob])

    const handleEdit = () => {
        if (!isEdit) {
            setIsEdit(!isEdit);
        } else {
            const updateObj = {}

            if (isDuplicateName(name, lineOfBusinessList, lob.websafe)) {
                console.log(lob.websafe, lineOfBusinessList)
                toast.error(`A line of business with the name ${name} already exists`);
                return;
            }

            if (shouldUpdateString(name, lob.name)) updateObj.name = name;
            if (shouldUpdateString(description, lob.description)) updateObj.description = description;
            if (shouldUpdateString(lobProduct, lob?.lobProduct)) updateObj.lobProduct = lobProduct;
            if (selectedLabels && !isObjArraysEqual(selectedLabels, lob.labels)) updateObj.labels = selectedLabels;

            if (Object.keys(updateObj).length > 0) handleUpdateLob(updateObj);

            setIsEdit(!isEdit);
        }
    }

    const renderLabels = () => {
        return lob?.labels?.map(label => {
            return (
                {
                    label: label,
                    value: label
                }
            )
        })
    }

    const handleLabelChange = selectedOptions => {
        const labels = selectedOptions?.map(opt => opt.value) || [];
        setSelectedLabels(labels);
    }

    if (!lob) return <Loader />

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                Line of Business / Product Description
                {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>

            <FieldColContainer>
                <FieldContainer>
                    <h3> Name of Line of Business {<span className='asterisk'>*</span>} </h3>
                    {
                        isEdit ?
                        <Input value={name || lob?.name} onChange={e => setName(e.target.value)}/> :
                        <p> {lob?.name} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3>LOB/Product</h3>
                    {
                        isEdit ?
                        <Input value={lobProduct || lob?.lobProduct} onChange={e => setLobProduct(e.target.value)} placeholder='Enter LOB/Product'/> :
                        <p>{lob?.lobProduct || 'N/A'}</p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> Advertiser </h3>
                    {
                        isEdit ?
                        <Input value={advertiser?.name} disabled /> :
                        <p> {advertiser?.name} </p>
                    }
                </FieldContainer>
            </FieldColContainer>

            <FieldColContainer>
                <FieldContainer>
                    <h3> Labels </h3>
                    {
                        isEdit ?
                        <CreatableSelect
                            isClearable
                            isMulti
                            options={renderLabels()}
                            styles={selectTheme}
                            defaultValue={renderLabels()}
                            onChange={e => handleLabelChange(e)}
                        />
                        :
                        <p> {lob?.labels?.length ? lob?.labels.join(', ') : 'N/A'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                <h3> Line of Business Description </h3>
                {
                    isEdit ?
                    <Textarea rows={5} value={description || lob?.description} onChange={e => setDescription(e.target.value)}/> :
                    <p> {lob?.description || 'No description'} </p>
                }
                </FieldContainer>
            </FieldColContainer>
        </StyledFieldsWrapper>
    )
}

export default LobDescription;