import React from 'react';
import Modal from '../../../../../components/Modal/Modal';

// Styles
import { OutlineBtn, CenterButtons, BlackBtn } from '../../../../../ui/button';
import { ModalText } from '../../../../../components/Modal/styles';

const ArchivePropertyModal = ({onClose, modalData, handlePropertiesToEdit, properties, setProperties}) => {
    const { name, websafe, type, source, values, bulkUploadInstructions, networkManagerInstructions } = modalData || {};

    const isNewProperty = () => {
        if (!properties.length) return false;

        const result =  properties.find(property => {
            return (
                    (name === property.name) &&
                    (type === property.type) &&
                    (source === property.source) &&
                    (bulkUploadInstructions === property.bulkUploadInstructions) &&
                    (networkManagerInstructions === property.networkManagerInstructions)
                )
        })
        return result;
    }

    const handleUpdateProperty = () => {
        const newProperty = isNewProperty();

        if (newProperty) {
            const newPropertiesArray = properties.map(property => {
                if (newProperty === property) return { name, type, source, values, bulkUploadInstructions, networkManagerInstructions, archived: true };
                return property;
            })

            setProperties(newPropertiesArray);
            return;
        }

        handlePropertiesToEdit(websafe, {archived: true});
    }

    return (
        <Modal
            onClose={onClose}
            header='Archive Property'
            style={{width: '500px'}}
        >
            <ModalText>
                Are you sure you want to archive {name} property?
                <br/>
                This property will no longer be available for newly created network templates or LOBs.
            </ModalText>

            {CenterButtons(
                <>
                    <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                    <BlackBtn onClick={() => {
                        handleUpdateProperty()
                        onClose();
                    }}> Archive Property </BlackBtn>
                </>
            )}
        </Modal>
    )
}

export default ArchivePropertyModal;