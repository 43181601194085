import React, { useRef, useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable';
import BuildModal from '../../../../components/Modal/modals/BuildModal'
import useCsv from '../../../../hooks/useCsv';
import Loader from '../../../../ui/Loader';

// Redux
import { useSelector } from 'react-redux';

// Utils
import { toast } from 'react-toastify'
import { uploadBlob } from '../../../../utils';
import { getTodayDate } from '../../../../utils/dates';

// Styles
import { ListItem } from '../../../../components/Modal/styles';
import { selectThemeModal } from '../../../../ui/themes'
import { BtnWithLoader, OutlineBtn } from '../../../../ui/button'
import { Input, Textarea } from '../../../../ui/input/input'
import { CsvSelectContainer } from '../../../../ui/table/styles';
import { StyledReviewContainer } from '../../styles'
import { BsUpload } from 'react-icons/bs';
import { FiPaperclip } from 'react-icons/fi'
import { TbCircleMinus } from 'react-icons/tb';

const MediaPlanReviewModal = ({ mediaPlan, onClose }) => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadLoader, setUploadLoader] = useState(false)
  const [loading, setLoading] = useState(false)

  const orgName = useSelector(state => state?.org?.organization?.name);

  const CsvUploader = useCsv();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const body = `Hi,\n\nPlease review the attached media plan.\n\nThank you,\n${orgName}`;
    setEmailBody(body);
  }, [])

  const APPROVAL_SUBJECT = `${mediaPlan.name} Media Plan Approval_${getTodayDate()}_Approval`
  const APPROVAL_TEXT = `Hi,\n\nPlease review the attached media plan.\n\nThank you,\n${orgName}`;

  useEffect(() => {
    setSubject(APPROVAL_SUBJECT || '');
    setEmailBody(APPROVAL_TEXT || '');
  }, [orgName])

  const handleRemoveFile = (blobkey) => {
    const filteredFiles = selectedFiles.filter(file => file.blobkey !== blobkey)
    setSelectedFiles(filteredFiles)
  }

  const renderSelectedFiles = () => {
    if (!selectedFiles?.length) return;
    return selectedFiles?.map((file, idx) => <ListItem key={idx}> {file.filename} <TbCircleMinus size={18} onClick={() => handleRemoveFile(file.blobkey)} /> </ListItem>);
  }

  const handleFileUpload = (e) => {
    setUploadLoader(true)
    uploadBlob(
      {
        file: e.target.files[0],
        callback: (file) => {
          setSelectedFiles(selectedFiles?.length ? [...selectedFiles, file] : [file])
          setUploadLoader(false)
        }
      }
    )
  }

  const submitForReview = () => {
    if (!email.length) return toast.error('Please enter an email!')
    if (!email.includes('@')) return toast.error('Please enter a valid email!')
    if (emailList.some(email => !email.value.includes('@'))) return toast.error('Please ensure all alert emails are valid emails!')
    if (!subject.length) return toast.error('Please enter a subject!')
    if (!emailBody.length) return toast.error('Please enter some body text!')

    // * List of emails that should receive an alert
    const alertList = emailList.map(email => email.label)
    const blobsList = selectedFiles.map(file => file.blobkey)

    const emailObject = {
      approverEmail: email,
      subject: subject,
      body: emailBody,
      alertEmails: alertList,
      blobs: blobsList
    }

    setLoading(true)
    window.eulerity.makeApiCall(
      `/api/mediaplan/sendApprovalEmail?mediaPlan=${mediaPlan?.websafe}`,
      'POST',
      emailObject,
      res => {
        onClose()
        toast.success('Media Plan review has been sent for approval!')
        setLoading(false)
      },
      {
        1000: (err) => {
          console.error(err)
          onClose()
          toast.error('An error has occured!')
          setLoading(false)
        }
      }
    )

  }

  return (
    <BuildModal
      header='Send Media Plan for Client Review'
      onClose={onClose}
      buttons={[<OutlineBtn onClick={onClose}>Go Back</OutlineBtn>, <BtnWithLoader btnText='Send Media Plan for Review' loader={loading} type='blackBtn' onClick={() => submitForReview()} />]}
      height={'704px'}
      minWidth={'1120px'}
    >
      <StyledReviewContainer>
        <p className='modal-info'>Use the form below to send an email to the client to approve this media plan</p>
        <div className='input-fields'>
          <div className='input-field'>
            <p>Send To <span className='asterisk'>*</span></p>
            <Input type='email' onChange={(e) => setEmail(e.target.value)} placeholder='Enter Email' />
          </div>
          <div className='input-field'>
            <p>Send Alert Email to</p>
            <CreatableSelect
              isClearable
              isMulti
              styles={selectThemeModal}
              placeholder='Enter Email(s)'
              onChange={(e) => setEmailList(e)}
              components={{
                Menu: () => null,
                MenuList: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
            />
          </div>
          <div className='input-field'>
            <p>Subject <span className='asterisk'>*</span></p>
            <Input value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Enter Subject Line' />
          </div>
          <div className='input-field'>
            <p>Email Body <span className='asterisk'>*</span></p>
            <Textarea style={{ lineHeight: '20px' }} rows={8} value={emailBody} onChange={(e) => setEmailBody(e.target.value)} placeholder='Enter Email Body' />
          </div>
          <div>
            <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(fileInputRef)}>
              <BsUpload size={30} />
              <p style={{ marginTop: '20px' }}>Choose file(s) to upload</p>
            </CsvSelectContainer>
            <input
              type='file'
              hidden
              onChange={(e) => handleFileUpload(e)}
              ref={fileInputRef}
            />
            <div className='attachments'>
              <span className='attachment-header'>
                <FiPaperclip size={18} />
                Attachments
              </span>
              <div>
                {
                  uploadLoader ?
                    <Loader size={24} />
                    : renderSelectedFiles()
                }
              </div>
            </div>
          </div>
        </div>
      </StyledReviewContainer>
    </BuildModal>
  )
}

export default MediaPlanReviewModal