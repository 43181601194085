import React, { useState } from 'react';
import CampaignName from './CampaignName';
import AdAccount from './AdAccount';
import InsightTag from './InsightTag';
import Page from './Page';
import CampaignBudgetInterval from './CampaignBudgetInterval';
import DailyBudget from './DailyBudget';
import BudgetSchedule from './BudgetSchedule';
import StartDate from './StartDate';
import EndDate from './EndDate';
import AdFormat from './AdFormat';
import Conversion from './Conversion';
import Language from './Language';
import CountryRegionCode from './CountryRegionCode';
import Age from './Age';
import Gender from './Gender';
import LobLanguage from './LobLanguage';
import Locations from './Locations';
import Objective from './Objective';
import Optimization from './Optimization';
import BiddingStrategy from './BiddingStrategy';
import CostPerResultGoal from './CostPerResultGoal';
import CpcBidLimit from './CpcBidLimit';
import Placements from './Placements';
import CampaignAdGroups from './CampaignAdGroups';
import TargetAudiences from './TargetAudiences';
import LifeTimeBudget from './LifeTimeBudget';
import DeleteModal from '../../../../../components/Modal/DeleteModal';

// Hooks
import useLinkedInCampaign from '../hooks/useLinkedInCampaign';

// Styles
import { BiTrash } from 'react-icons/bi';
import { EditSaveBtn, RedOutlineIconBtn, CenterBtnContainer } from '../../../../../ui/button';
import { ColumnHeader, SubHeader } from '../../../../../ui/headers';
import { OneThirdGridColContainer } from '../../../../../ui/containers';

export const LinkedInCampaignContext = React.createContext();

const LinkedInCampaignSetup = ({currNav}) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const linkedInCampaign = useLinkedInCampaign({});

    const campaignContextValues = {
        ...linkedInCampaign,
    }

    const {isEdit, handleEdit, campaignObj, isDeleteLoader, handleDelete} = linkedInCampaign;

    return (
        <LinkedInCampaignContext.Provider value={campaignContextValues}>
            <CampaignAdGroups currNav={currNav} />

            <SubHeader>
                <div />
                <CenterBtnContainer>
                <RedOutlineIconBtn onClick={() => setIsDeleteModalOpen(true)}>
                    <BiTrash /> Delete
                </RedOutlineIconBtn>
                    {EditSaveBtn(isEdit, handleEdit)}
                </CenterBtnContainer>
            </SubHeader>
            <ColumnHeader> Campaign Details </ColumnHeader>

            <OneThirdGridColContainer>
                <CampaignName />
                <AdAccount />
                <InsightTag />
                <Page />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <CampaignBudgetInterval />
                <DailyBudget />
                <LifeTimeBudget />
                <BudgetSchedule />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <StartDate />
                <EndDate />
                <Conversion />
                <AdFormat />
            </OneThirdGridColContainer>

            <ColumnHeader> Flight Details </ColumnHeader>

            <OneThirdGridColContainer>
                <CountryRegionCode />
                <Language />
            </OneThirdGridColContainer>

            <ColumnHeader> Originally Derived from LOB </ColumnHeader>

            <OneThirdGridColContainer>
                <Age />
                <Gender />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <LobLanguage />
                <Locations />
            </OneThirdGridColContainer>

            <TargetAudiences />

            <ColumnHeader> Originally Derived from Network Template </ColumnHeader>

            <OneThirdGridColContainer>
                <Objective />
                <Optimization />
                <BiddingStrategy />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <CostPerResultGoal />
                <CpcBidLimit />
                <Placements />
            </OneThirdGridColContainer>
            {
                isDeleteModalOpen &&
                <DeleteModal
                    header='Deleting Campaign'
                    deleteBtnText='Delete'
                    modalText={`Are you sure you want to delete ${campaignObj.name} campaign?`}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    loading={isDeleteLoader}
                    handleDelete={handleDelete}
                />
            }
        </LinkedInCampaignContext.Provider>
    )
}

export default LinkedInCampaignSetup;
