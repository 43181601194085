import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Redux
import { getLanguageTargets } from '../../../../actions/mediaActions';

// Styles
import { FieldContainer } from '../../../../ui/containers';
import { selectTheme } from '../../../../ui/themes';

const LanguageTargets = ({ isEdit, languageTargets, setLanguageTargets}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getLanguageTargets({
            callback: (res) => {
                setOptions(res);
                setIsLoading(false);
            }
        });
    }, []);

    const handleChange = e => {
        setLanguageTargets(e.map(language => language.value) || [])
    }

    return (
        <FieldContainer>
            <h3> Language Targets </h3>
            {
                isEdit ?
                    <Select
                        isClearable
                        isMulti
                        options={options.map(language => {
                            return ({label: language.name, value: language})
                        })}
                        defaultValue={languageTargets?.map(language => {
                            return ({label: language.name, value: language})
                        })}
                        styles={selectTheme}
                        onChange={handleChange}
                        isLoading={isLoading}
                        noOptionsMessage={() => 'No Languages'}
                    /> : <p> {languageTargets?.length ? languageTargets.map(language => language.name).join(', ') : 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default LanguageTargets;