import React, { useState } from 'react';
import BuildModal from '../../../../components/Modal/modals/BuildModal';
import NameStep from '../CreateLobSteps/NameStep';
import AdvertiserStep from '../CreateLobSteps/AdvertiserStep';

// Utils
import { toast } from 'react-toastify';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { OutlineBtn, BlackBtn } from '../../../../ui/button';

const CreateLobModal = ({onClose, register, handleSubmit, setValue, getValues, setIsEdit}) => {
    const [step, setStep] = useState('name');
    const [isDisabled, setIsDisabled] = useState(true);
    const lobList = useSelector(state => state.lob.lineOfBusinessList);

    const isDuplicateName = (name) => lobList.find(lob => lob.name.toLowerCase() === name.toLowerCase());

    const handleExit = () => {
        setValue('name', '');
        onClose();
    }

    const handeSaveAndExit = () => {
        const { name } = getValues();

        if (isDuplicateName(name)) {
            toast.error(`${name} LOB already exists`);
            return;
        } else {
            handleSubmit();
            onClose();
        }
    }

    const handleSaveAndContinue = () => {
        const { name } = getValues();

        if (isDuplicateName(name)) {
            toast.error(`${name} LOB already exists`);
            return;
        } else {
            handleSubmit();
            setIsEdit(true);
            onClose();
        }
    }

    const nameButtonsStepArray = [
        <OutlineBtn onClick={handleExit} key='cancel'> Cancel </OutlineBtn>,
        <BlackBtn onClick={() => setStep('advertiser')} key='continue'> Continue </BlackBtn>,
    ]

    const advertiserButtonsStepArray = [
        <OutlineBtn onClick={handleExit} key='cancel'> Cancel </OutlineBtn>,
        <OutlineBtn onClick={handeSaveAndExit} key='saveAndExit' isDisabled={isDisabled}> Save & Exit </OutlineBtn>,
        <BlackBtn onClick={handleSaveAndContinue} key='saveAndContinue' isDisabled={isDisabled}> Save & Continue </BlackBtn>
    ]

    const buttonStep = () => {
        switch (step) {
            case 'name':
                return nameButtonsStepArray;
            case 'advertiser':
                return advertiserButtonsStepArray;
            default: break;
        }
    }

    const componentSteps = () => {
        switch (step) {
            case 'name':
                return (
                    <NameStep
                        register={register}
                    />
                )
            case 'advertiser':
                return (
                    <AdvertiserStep
                        register={register}
                        setIsDisabled={() => setIsDisabled(false)}
                    />
                )
            default: break;
        }
    };

    return (
        <BuildModal
            onClose={onClose}
            header='Create New Line of Business'
            minWidth='600px'
            maxWidth='1000px'
            buttons={buttonStep()}
        >
           {componentSteps()}
        </BuildModal>
    )
}

export default CreateLobModal;