import styled, { css } from 'styled-components';
import { FaSearch, FaSlidersH } from 'react-icons/fa';
import AdvancedFilter from '../features/AdvancedFilter/components/AdvancedFilter';
import { useState } from 'react';

const SearchContainer = styled.div`
    width: 570px;
    height: 44px;
    border-radius: 32px;
    border: 0.62069px solid #93918F;
    font-family: 'GothamMedium';
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.background.white};

    .searchInput {
        all: unset;
        width: 100%;
        font-family: 'GothamLight';
    }

    #searchIcon {
        margin-right: 20px;
    }

    svg {
        fill: ${props => props.theme.color.yellow};
        font-size: 28px;
    }

    ${({ isModal }) => isModal && css`
        svg {
            font-size: 16px;
        }

        font-size: 12px;
        padding: 16px;
        border-radius: 9px;
        border: 2px solid ${props => props.theme.color.black};
        height: 22px;
        width: 100%;
    `}
`;

const Search = ({ text, handleFilter, isModal, value, useFilter, filterConfig }) => {

    const [isFilterOpen, setIsFilterOpen] = useState(false)

    return (
        <SearchContainer isModal={isModal}>
            <FaSearch id='searchIcon' />
            <input className='searchInput' placeholder={text} onChange={handleFilter} value={value} />
            {useFilter &&
                <div style={{position: 'relative'}}>
                    <div onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <FaSlidersH size={18} />
                    </div>
                    {
                        isFilterOpen &&
                        <AdvancedFilter
                            filterConfig={filterConfig}
                        />
                    }
                </div>
            }
        </SearchContainer>
    )
}

export default Search;
