import React from "react";
import styled from "styled-components";

const StyleStatusCircle = styled.div`


  @keyframes identifier {
    0% {
        background-color: ${props => props.status ? '#1abf80' : '#CF1100'};
    }
    50% {
        background-color: ${props => props.status ? '#38E54D' : '#FF1E00'};
    }
    100% {
        background-color: ${props => props.status ? '#1abf80' : '#CF1100'};
    }
  }





  width: 20px;
  height: 20px;
  border-radius: 100%;
  animation: identifier 2s infinite;

`;

const StatusCircle = ({status}) => {
  return <StyleStatusCircle status={true} />;
};

export default StatusCircle;
