import styled, {css} from 'styled-components';

export const LineOfBusinessContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.background.gray};
`;

//Overview
export const OverviewSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;

    button {
        width: 200px;

        svg {
            font-size: 14px;
        }
    }

    @media only screen and (min-width: 768px) {
        button {
            width: 260px;

            svg {
                font-size: 16px;
            }
        }
    }

    @media only screen and (min-width: 992px)  {
        button {
            width: 300px;

            svg {
                font-size: 18px;
            }
        }
    }
`;

// Grid
export const LineOfBusinessGrid = styled.div`
    width: 100%;
    margin: 80px auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, 250px);
    gap: 20px;
`;

// Modal
export const LobModalContainer = styled.div`
    button {
        width: fit-content;
        height: fit-content;
        padding: 10px 20px;
    }
`;

// Create
export const CreateLobContainer = styled.div`
    padding: 30px 20px;
    display: grid;
    grid-template-columns: 170px auto;
`;

// Container
export const FieldColContainer = styled.div`
    gap: 40px;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1075px)  {
        flex-direction: row;
    }
`;

export const InputRangeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const AudienceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 400px;
    min-height: 200px;
    height: 50%;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    border: 2px solid ${props => props.theme.color.green};

    ${({type}) => type === 'exclude' && css`
        border: 2px solid ${props => props.theme.color.red};
    `}

    ${({isHidden}) => isHidden && css`
        display: none;
    `}

    ${({andStyle}) => andStyle && css`
        ${andStyle}
    `}

    h3 {
        font-family: 'GothamMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }

    .result {
        min-height: 150px;
    }

    .search > span {
        font-family: 'GothamBook';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
    }

    ${({isAnd}) => css`
    @media only screen and (min-width: 992px)  {
        width: 48%;
    }
    `}
`;

export const AudienceTargetContainer = styled.div`
    gap: 20px;

    @media only screen and (min-width: 992px) {
        display: flex;
    }
`;