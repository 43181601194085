import React, { useState, useEffect, useMemo } from 'react';
import TopOverView from '../../../components/Overview/TopOverview';
import InitialCreateMediaPlan from './creationSteps/InitialCreateMediaPlan';
import Card from '../../../ui/card/Card';
import LaunchMediaPlan from './modal/LaunchMediaPlan';
import GoogleSheetsModal from './modal/GoogleSheetsModal';

// Redux
import { useSelector } from 'react-redux';
import { selectMediaPlan, deleteMediaPlan, getMediaPlan } from '../../../actions/mediaActions';

// Helpers
import { sortByRecentlyCreated } from '../../../utils/dates';
import useInitilizeOptions from '../../../features/AdvancedFilter/hooks/useInitializeOptions';

// Styles
import { OverviewContainer } from '../../../ui/containers';
import { CardGridContainer } from '../../../ui/card/styles';
import { toast } from 'react-toastify';
import useGoogleSheets from '../useGoogleSheets';
import { CATEGORY_TAB_NAME, CATEGORY_TYPES } from '../../../features/AdvancedFilter/constants';
import { doesMediaPlanMatch } from '../../../features/AdvancedFilter/utils/filters.utils';


const Overview = ({setIsEdit, setLoader}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [googleSheetModal, setGoogleSheetModal] = useState(false);
    const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false);
    const [mediaPlan, setMediaPlan] = useState(null);

    const mediaList = useSelector(state => state.media.mediaList); // * All media plans returned by backend (global state)

    const {updateMediaPlans} = useGoogleSheets();
    const { initOwnerNameList, initLobNameList, approvalStatus } = useInitilizeOptions();

    // * Default Filters
    const DEFAULT_FILTERS = {
        [CATEGORY_TYPES.OWNER]: [],
        [CATEGORY_TYPES.LOB]: [],
        [CATEGORY_TYPES.STATUS]: []
    }

    // * Filter Categories
    const categories = [
        {
            categoryName: CATEGORY_TAB_NAME.OWNER,
            options: initOwnerNameList(mediaList)
        },
        {
            categoryName: CATEGORY_TAB_NAME.LOB,
            options: initLobNameList()
        },
        {
            categoryName: CATEGORY_TAB_NAME.STATUS,
            options: approvalStatus
        }
    ]

    // * Active Filter State
    const [searchTerm, setSearchTerm] = useState('')
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

    // * Filter Config (obj b/c search component will crash if we try to destructure)
    const filterConfig = {
        selectedFilters: filters,
        setSelectedFilters: setFilters,
        categories
    }

    // * Template Options (will update as filters change)
    const filteredMediaPlanOptions = useMemo(() => {
        let doesMatch = true;
        return mediaList.filter(template => {
            doesMatch = doesMediaPlanMatch(template, filters)
            return doesMatch
        })
    }, [filters, mediaList])

    const handleExportGoogleSheets = async () => {
        if (mediaPlan?.sheetsUrl) {
            setIsLaunchModalOpen(false)
            setGoogleSheetModal(true)
            return
        }
        try {
            await updateMediaPlans(mediaPlan, setMediaPlan)
        } catch (error) {
            toast.error('Something went wrong')
        }
      };

    // * Will return all media plans that apply to search input
    const handleSearchFilter = (filteredOptions) => {
        return filteredOptions.filter(template => {
            const name = template.name.toLowerCase();
            const hasLabel = template.labels.find(label => label.toLowerCase().includes(searchTerm));
            return name.includes(searchTerm) || hasLabel;
        })
    }

    const deleteTextObj = {
        header: 'Delete Media Plan',
        deleteBtnText: 'Delete Media Plan',
        modalText: 'Are you sure you want to delete '
    }

    const handleLaunch = (media) => {
        setMediaPlan(null)
        setIsLaunchModalOpen(true);
        getMediaPlan({
            mediaPlanWebsafe: media,
            callback: (res) => {
                setMediaPlan(res)
            }
        })
    }
    const Cards = handleSearchFilter(sortByRecentlyCreated(filteredMediaPlanOptions))?.map(media =>
        <Card
            key={media.id}
            data={media}
            setIsEdit={setIsEdit}
            type='media'
            deleteItem={deleteMediaPlan}
            selectItem={selectMediaPlan}
            deleteTextObj={deleteTextObj}
            launch={true}
            handleLaunch={handleLaunch}
            googleSheetsLinkURL={media.sheetsUrl}
            setOverviewLoader={res => setLoader(res)}
        />)

    return (
        <OverviewContainer>
        <TopOverView
            handleClick={() => setIsModalOpen(true)}
            handleFilter={(e) => setSearchTerm(e.target.value)}
            searchText='Search for a Media Plan'
            buttonText='Add New Media Plan'
            filterConfig={filterConfig}
            useFilter
        />

        {!filteredMediaPlanOptions?.length && <p className='no-results'>No Media Plans are found based on the filters applied</p>}

        <CardGridContainer>
            {Cards}
        </CardGridContainer>

        {isModalOpen &&
            <InitialCreateMediaPlan onClose={() => setIsModalOpen(false)} setIsEdit={setIsEdit} />
        }

        {
            googleSheetModal &&
                <GoogleSheetsModal
                    onClose={setGoogleSheetModal.bind(null, false)}
                    mediaPlan={mediaPlan}
                    setMediaPlan={setMediaPlan}
                />
        }

        {
            isLaunchModalOpen &&
            <LaunchMediaPlan
                onClose={() => setIsLaunchModalOpen(false)}
                mediaPlan={mediaPlan}
                setMediaPlan={setMediaPlan}
                handleExportGoogleSheets={handleExportGoogleSheets}
            />
        }

        </OverviewContainer>
    )
}

export default Overview;