import React from 'react';
import { ReviewInterestTargetContainer, AudienceTargetListContainer } from '../styles';

const ReviewAudienceTargets = ({ includeAudiences, excludeAudiences, includedInterestTargets, excludedInterestTargets, productSet, adAccount }) => {
    const audiencesList = includeAudiences?.length ? includeAudiences?.map(audience => audience.name).join(', ') : 'No Inclusion';
    const excludeAudiencesList = excludeAudiences?.length ? excludeAudiences?.map(audience => audience.name).join(', ') : 'No Exclusion';
    const excludedInterestTargetsList = excludedInterestTargets?.length ? excludedInterestTargets?.map(interest => interest.name).join(', ') : 'No Exclusion';
    const squishIncludedInterestTargets = includedInterestTargets?.filter(group => group.length)

    const interestTargetList = squishIncludedInterestTargets?.length > 0 ? includedInterestTargets[0]?.map((group, idx) => {
        let subInterestTargetList = group?.map(interest => interest.name);

        return (
            <ReviewInterestTargetContainer key={idx}>
                <span className='green'>{idx === 0 ? 'People Who Match' : 'And Must Also Match'}</span> : &nbsp;
                <h3>{subInterestTargetList?.join(', ')}</h3>
            </ReviewInterestTargetContainer>
        )
    }) :
        <ReviewInterestTargetContainer>
            <span className='green'>People Who Match</span> : &nbsp;
            <h3>No Exclusion</h3>
        </ReviewInterestTargetContainer>

    return (
        <>
            <p>Facebook Audience Lists</p>
            <AudienceTargetListContainer>
                <h3><span className='green'>Include These Audiences</span>: {audiencesList}</h3>
                <h3><span className='red'>Exclude These Audiences</span>: {excludeAudiencesList}</h3>
            </AudienceTargetListContainer>
            <p> Facebook Demographics, Interests, or Behaviors </p>
            <AudienceTargetListContainer>
                <h3>{interestTargetList}</h3>
                <h3><span className='red'> Exclude People Who Match</span>: {excludedInterestTargetsList}</h3>
            </AudienceTargetListContainer>

            <p> Product Set and Catalog </p>

            <AudienceTargetListContainer>
                <h3> Product Set: {productSet?.name ? productSet?.name : 'N/A'} </h3>
                <h3> Catalog: {adAccount?.catalog?.name ? adAccount?.catalog?.name : 'N/A'} </h3>
            </AudienceTargetListContainer>
        </>
    )
}

export default ReviewAudienceTargets