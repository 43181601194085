
const useCsv = () => {
    const selectCsvFile = (fileInputRef) => {
        fileInputRef?.current?.click();
    }

    const handleFileSelected = ({file, updateState}) => {
        let fileReader = new FileReader();
        fileReader.onloadend = () => {
            const content = fileReader.result;
            if (updateState) updateState([content]);
        };
        fileReader.readAsText(file);
    };

    return {
        selectCsvFile,
        handleFileSelected
    }
}

export default useCsv;