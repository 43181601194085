import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
    background-color: ${props => props.theme.background.white};
    height: 252px;
    width: 256px;
    border-radius: 4px;
    border-style: solid;
    border: 1.5px solid;
    border-width: 1.5px;
    border-style: solid;
    border-image: linear-gradient(to right, #C4C4C4,#F4B63F) 1;
`;

export const IconContainer = styled.div`
    box-sizing: border-box;
    /* background-color: green; */
    display: flex;
    font-size: 24px;
    justify-content: flex-end;
    gap: 20px;
    padding: 0.4rem;
    svg {
        cursor: pointer;
    }
`;

export const NameLabel = styled.h3`
    padding: 10px 20px;
    margin: 0 auto;
`;

export const Label = styled.li`
    background-color: ${props => props.theme.color.yellow};
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    padding: 10px 20px;
`;

export const InverseLabel = styled(Label)`
    color: black;
    border: 1px solid ${props => props.theme.color.yellow};
    background-color: ${props => props.theme.background.white};
`;

export const LabelsContainer = styled.div`
    width: 100%;
    display: flex;
    overflow: scroll;
    padding: 10px 20px;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const DetailsContainer = styled.div`
    padding: 10px 20px;

    .google-sheet_link {
        font-family: "GothamMedium";
        font-size: 12px;
        color: ${props => props.theme.color.yellow};
        a {
            text-decoration: underline;
        }
    }
`;

export const Detail = styled.li`
    color: ${props => props.theme.card.gray};
    font-weight: 400;
    font-size: 12px;
    font-family: 'GothamBook';
    margin-bottom: 5px;

    span {
        font-weight: 500;
        color: ${props => props.theme.card.gray};
        font-family: 'GothamMedium';
    }

    .yellow {
        color: ${props => props.theme.color.yellow};
    }
`;

export const CardGridContainer = styled.div`
    width: 100%;
    margin: 80px auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, 250px);
    gap: 20px;
`;

export const WideCardContainer = styled.div`
    background-color: ${props => props.theme.background.white};
    width: 100%;
    border-radius: 6px;
    border: 2px solid ${props => props.theme.hover.gray};
    padding: 10px 20px;
    cursor: pointer;

    ${({ isSelected }) => isSelected && css`
        background-color: rgba(248, 243, 235, 0.4);
        border: 2px solid ${props => props.theme.color.yellow};
    `}

    h3, div, p {
        padding: 3px;
    }

    .advertiser {
        font-weight: 100;
        margin-left: 10px;
        align-items: center;
        justify-content: center;
    }

    .advertiser > img {
        width: 18px;
        margin: 0 10px;
    }
`;

export const Description = styled.p`
    padding: 10px 20px;
    margin: 0 auto;
    font-family: 'GothamBook';
    font-size: 12px;
    font-weight: 400;
`;

export const StyleTemplateCardName = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    padding: 0.3rem;
    padding-left: 1rem;
    word-break: break-word;
    font-size: 14px;

`
export const WideCardDescription = styled(Description)`
    margin: 0 !important;
`;

// Dummy
export const DummyLogo = styled.img`
    width: 22px;
    height: 22px;
`;

export const StatusContainer = styled.div`
    display: flex;
    padding: 0.3rem;
    padding-left: 1rem;
`