import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { cloneNetworkTemplate, selectTemplate } from '../../actions/tempActions'
import { cloneLob, selectLob } from '../../actions/lobActions'


// * Components
import Modal from './Modal'

// * Styles
import { BlackBtn, CenterBtnContainer, CenterBtnOutterContainer, OutlineBtn } from '../../ui/button'
import { Input } from '../../ui/input/input'
import { ModalText, ModalTextSecondary } from './styles'

// TODO - Once we want to duplicate LOBs tweak code to condtionally format text/handling for it
const CloneModal = ({cloneTemplateObj, setIsCopyModalOpen, data, setIsEdit }) => {
    const _D = useDispatch()
    const { cloneType, dataList, headerType, secondaryText } = cloneTemplateObj
    const { network } = data;
    const networkType = network === 'google' ? 'Google Search' : 'Facebook';

    // * State
    const [copyName, setCopyName] = useState("");

    // * Use Effect
    useEffect(() => {
        setCopyName(data?.name)
    }, [data?.name])

    // * Functions
    const isDuplicateName = (name) => dataList?.find(listItem => listItem?.name.toLowerCase() === name.toLowerCase());
    const returnErrorToast = (copyName) => toast.error(`${copyName} ${cloneType} already exists`)
    const returnSuccessToast = (copyName) => toast.success(`Created Clone ${copyName} ${cloneType}`)


    // * Closes copy modal
    const handleExit = () => {
        setCopyName('')
        setIsCopyModalOpen(false)
    }

    // * Handles Clone & Exit
    const handleSaveAndExit = () => {
        // * Check if cloned named already exists'
        if (isDuplicateName(copyName)) {
            returnErrorToast(copyName)
            return;
        }
        if(cloneType === 'template') _D(cloneNetworkTemplate({copyName, id: data?.id}))
        if(cloneType === 'line of business') _D(cloneLob({copyName, lobId: data?.websafe}))

        returnSuccessToast(copyName)
        setIsCopyModalOpen(false)
    }

    // * Handles Save & Continue (opens cloned item)
    const handleSaveAndContinue = () => {
        if (isDuplicateName(copyName)) {
            returnErrorToast(copyName)
            return;
        }

        if (cloneType === 'template') _D(cloneNetworkTemplate({
            copyName,
            id: data.id,
            callback: (response) => {
                _D(selectTemplate({ websafe: response?.websafe }))
                setIsEdit(true)
                returnSuccessToast(copyName)
                setIsCopyModalOpen(false)
            }
        }))

        if (cloneType === 'line of business') _D(cloneLob({
            copyName,
            lobId: data?.websafe,
            callback: (response) => {
                _D(selectLob({ websafe: response?.websafe }))
                setIsEdit(true)
                returnSuccessToast(copyName)
                setIsCopyModalOpen(false)
            }
        }))
    }

    return (
        <Modal header={`Duplicate ${networkType} ${headerType}`} onClose={handleExit}>
            <ModalText>{`Duplicate this ${networkType} ${cloneType}.`}</ModalText>
            <ModalTextSecondary>{secondaryText} <span className='asterisk'>*</span></ModalTextSecondary>

            <Input type='text' placeholder='Enter A Value' required onChange={(e) => setCopyName(e.target.value)} defaultValue={copyName}/>

            <CenterBtnOutterContainer>
                <CenterBtnContainer>
                    <OutlineBtn onClick={handleExit}>Close</OutlineBtn>
                    <OutlineBtn onClick={handleSaveAndExit}>Save & Exit</OutlineBtn>
                    <BlackBtn onClick={handleSaveAndContinue}>Save & Continue</BlackBtn>
                </CenterBtnContainer>
            </CenterBtnOutterContainer>
        </Modal>
    )
}

export default CloneModal