import Select from 'react-select';
import { selectTheme } from '../../../../ui/themes';
export const AudienceTypeField = ({audienceType, setAudienceType, defaultValue, audienceTypeOptions, isEdit, FieldContainer }) => {
    return (
        <FieldContainer>
            <h3> Audience Type </h3>
            { isEdit ?
            <Select
                isClearable
                options={audienceTypeOptions}
                defaultValue={{
                    label: defaultValue,
                    value: defaultValue
                }}
                styles={selectTheme}
                onChange={e => setAudienceType(e.label)}
            /> :
            <p> {audienceType || 'N/A'} </p> }
        </FieldContainer>
    )
}

export default AudienceTypeField;