import React, { useState } from "react";
// * Utils and Libraries
import { selectTheme } from "../../../../ui/themes";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setOrganization } from "../../../../actions/orgAction";
import { objectToObjArr } from "./label.utils";
import { changesMadeForLabels, changesMadeForObject, deleteDummyNetwork, makeGETPromiseApiCall, makePOSTPromiseApiCall, renderLabels } from "../../utils";

// * Components
import CreatableSelect from "react-select/creatable";
import AdvancedTable from "../../../../ui/advancedTable";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import Channels from "./components/DummyNetworks/Channels";
import ChannelModal from "./components/DummyNetworks/ChannelModal";
import Targets from "./components/TargetType/Targets";
import TargetingModal from "./components/TargetType/TargetingModal";
import Placement from "./components/Placements/Placement";
import PlacementModal from "./components/Placements/PlacementModal";

// * Styled Components
import { FieldContainer, StyleHeaderContainer } from "../../../../ui/containers";
import { StyleLabelManagement } from "./styles";
import { PageHeader, SubHeader } from "../../../../ui/headers";
import { EditSaveBtn } from "../../../../ui/button";
import { AdvancedTableGrid, StyleLabelManagementGrid } from "../../styles";
import Platform from "./components/Platforms/Platform";
import PlatformModal from "./components/Platforms/PlatformModal";


const LabelManagement = ({ setLoading }) => {
  const orgData = useSelector((state) => state.org.organization);
  const _D = useDispatch();

  // * General State
  const [isEdit, setIsEdit] = useState(false);
  // * API Network State
  const [isPlatformModalOpen, setIsPlatformModalOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  // * Dummmy Network State
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [deleteLoader, setDeleteLoader] = useState(false)
  // * Target Type State
  const [isTargetingModalOpen, setIsTargetingModalOpen] = useState(false);
  const [isDeleteTargetModalOpen, setIsDeleteTargetModalOpen] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(null);
  // * Placement State
  const [isPlacementModalOpen, setIsPlacementModalOpen] = useState(false);

  // * Label Object and State
  let _L = {
    priorities: orgData?.priorities || [],
    lobLabels: orgData?.lobLabels || [],
    goals: orgData?.goals || [],
    clients: orgData?.clients || [],
    networkTemplateLabels: orgData?.networkTemplateLabels || [],
    mediaPlanLabels: orgData?.mediaPlanLabels || [],
    audienceTypes: orgData?.audienceTypes || [],
  };

  const [labels, setLabels] = useState({
    priorities: _L.priorities,
    lobLabels: _L.lobLabels,
    goals: _L.goals,
    clients: _L.clients,
    networkTemplateLabels: _L.networkTemplateLabels,
    mediaPlanLabels: _L.mediaPlanLabels,
    audienceTypes: _L.audienceTypes,
  });

  let _O = {
    placementMap: orgData?.placementMap || {},
    audienceTypeMap: orgData?.audienceTypeMap || {},
    platformMap: orgData?.platformMap || {}
  }

  const [objectData, setObjectData] = useState({
    placementMap: _O.placementMap,
    audienceTypeMap: _O.audienceTypeMap,
    platformMap: _O.platformMap
  })

  const setLabelState = (field, labels) => {
    return setLabels((prew) => ({ ...prew, [field]: labels.map((it) => it.value) }));
  };

  // * Update Handler
  const handleOnSubmit = async () => {
    setIsEdit((prew) => !prew);
    let labelsToBeUpdated = changesMadeForLabels(_L, labels);
    let objectsToBeUpdated = changesMadeForObject(_O, objectData)
    let updatedLabelObject = { ...labelsToBeUpdated, ...objectsToBeUpdated}

    //* dont make an API call if nothing has changed.
    if (!Object.keys(updatedLabelObject).length) return;

    setLoading(true);
    try {
      await makePOSTPromiseApiCall("/api/org/updateSettings", updatedLabelObject);
      const refreshedOrgData = await makeGETPromiseApiCall("/api/org/get");
      _D(setOrganization(refreshedOrgData));
      toast.success("Labels have been updated");
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  // * Dummy Network Functions
  const handleEditNetworkModal = (selectedDummyNetwork) => {
    setSelectedNetwork(selectedDummyNetwork)
    setIsEditModalOpen(true)
  }

  const handleOnDeleteNetworkModal = (selectedDummyNetwork) => {
    setSelectedNetwork(selectedDummyNetwork)
    setIsDeleteModalOpen(true)
  }

  const handleOnCloseNetworkModal = () => {
    setSelectedNetwork(null)
    setIsEditModalOpen(false)
    setIsDeleteModalOpen(false)
  }

  const handleOnDeleteNetwork = async () => {
    try {
      setDeleteLoader(true)
      await deleteDummyNetwork(selectedNetwork?.websafe)
       setIsDeleteModalOpen(false)
       setSelectedNetwork(null)
    } catch (error) {
        toast.error("Something went wrong")
    } finally {
      setDeleteLoader(false)
    }
  }

  // * Targeting Type Functions
  const handleEditTargetingTypeModal = (selectedTarget) => {
    setSelectedTarget(selectedTarget);
    setIsTargetingModalOpen(true)
  }

  const handleDeleteTargetTypeModal = (selectedTarget) => {
    setSelectedTarget(selectedTarget);
    setIsDeleteTargetModalOpen(true);
  }

  const handleCloseDeleteTargetingTypeModal = () => {
    setSelectedTarget(null)
    setIsDeleteTargetModalOpen(false);
    setIsTargetingModalOpen(false);
  }

  const handleCloseTargetingModal = () => {
    setSelectedTarget(null)
    setIsTargetingModalOpen(false)
  }

  const handleDeleteTarget = () => {
    const currData = { ...objectData.audienceTypeMap }
    delete currData[selectedTarget?.key]

    setObjectData((prev) => (
      {
        ...prev,
        audienceTypeMap: {
          ...currData
        }
      }
    ))

    setIsDeleteTargetModalOpen(false)
  }

  // * Platform (API Network) Functions
  const handleOpenPlatformModal = (selectedPlatform) => {
    setSelectedPlatform(selectedPlatform);
    setIsPlatformModalOpen(true)
  }

  const handleClosePlatformModal = () => {
    setSelectedPlatform(null);
    setIsPlatformModalOpen(false);
  }

  // * Table Functions
  const handleUpdateTable = (updatedValues, key) => {
    setObjectData((prev) => (
      {
        ...prev,
        [key]: {
          ...updatedValues
        }
      }
    ))
  }

  return (
    <StyleLabelManagement>
      <StyleHeaderContainer>
        <PageHeader>
          <p className="action-header">{isEdit && <span className="action-header-span">Editing </span>}List Management</p>
        </PageHeader>
        <SubHeader>
          <div className="action-name">List Management</div>
          <div>{EditSaveBtn(isEdit, handleOnSubmit)}</div>
        </SubHeader>
      </StyleHeaderContainer>
      <StyleLabelManagementGrid>
        <FieldContainer>
          <h3> Labels for Priorities </h3>
          {isEdit ? (
            <CreatableSelect
              isClearable
              isMulti
              options={renderLabels(_L.priorities)}
              defaultValue={renderLabels(_L.priorities)}
              styles={selectTheme}
              onChange={(e) => setLabelState("priorities", e)}
            />
          ) : (
            <p> {_L.priorities?.length ? _L.priorities?.join(", ") : "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3> Labels for Goals </h3>
          {isEdit ? (
            <CreatableSelect
              isClearable
              isMulti
              options={renderLabels(_L.goals)}
              defaultValue={renderLabels(_L.goals)}
              styles={selectTheme}
              onChange={(e) => setLabelState("goals", e)}
            />
          ) : (
            <p> {_L.goals?.length ? _L.goals?.join(", ") : "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3> Labels for Client </h3>
          {isEdit ? (
            <CreatableSelect
              isClearable
              isMulti
              options={renderLabels(_L.clients)}
              defaultValue={renderLabels(_L.clients)}
              styles={selectTheme}
              onChange={(e) => setLabelState("clients", e)}
            />
          ) : (
            <p> {_L.clients?.length ? _L.clients?.join(", ") : "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3> Labels for LOB </h3>
          {isEdit ? (
            <CreatableSelect
              isClearable
              isMulti
              options={renderLabels(_L.lobLabels)}
              defaultValue={renderLabels(_L.lobLabels)}
              styles={selectTheme}
              onChange={(e) => setLabelState("lobLabels", e)}
            />
          ) : (
            <p> {_L.lobLabels?.length ? _L.lobLabels?.join(", ") : "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3> Labels for Network Templates </h3>
          {isEdit ? (
            <CreatableSelect
              isClearable
              isMulti
              options={renderLabels(_L.networkTemplateLabels)}
              defaultValue={renderLabels(_L.networkTemplateLabels)}
              styles={selectTheme}
              onChange={(e) => setLabelState("networkTemplateLabels", e)}
            />
          ) : (
            <p> {_L.networkTemplateLabels?.length ? _L.networkTemplateLabels?.join(", ") : "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3> Labels for Media Plans </h3>
          {isEdit ? (
            <CreatableSelect
              isClearable
              isMulti
              options={renderLabels(_L.mediaPlanLabels)}
              defaultValue={renderLabels(_L.mediaPlanLabels)}
              styles={selectTheme}
              onChange={(e) => setLabelState("mediaPlanLabels", e)}
            />
          ) : (
            <p> {_L.mediaPlanLabels?.length ? _L.mediaPlanLabels?.join(", ") : "N/A"} </p>
          )}
        </FieldContainer>
      </StyleLabelManagementGrid>

      <AdvancedTableGrid>
        <AdvancedTable isEdit={isEdit} headerText='API Networks' >
          {objectToObjArr(objectData.platformMap).map((platform, index) => {
            return (
              <Platform
                key={platform?.key}
                colored={index % 2 !== 0}
                isEdit={isEdit}
                platform={platform}
                selectedPlatform={selectedPlatform}
                onEdit={handleOpenPlatformModal.bind(null, platform)}
              />
            )
          })}
        </AdvancedTable>
        <AdvancedTable isEdit={isEdit} headerText="Non-API Networks" handler={setIsEditModalOpen.bind(null, true)} usePlus>
          {orgData.dummyNetworks.map((network, index) => {
            return (
              <Channels
                key={network?.name}
                network={network}
                colored={index % 2 !== 0}
                isEdit={isEdit}
                onEdit={handleEditNetworkModal.bind(null, network)}
                onDelete={handleOnDeleteNetworkModal.bind(null, network)}
              />
            );
          })}
        </AdvancedTable>

        <AdvancedTable isEdit={isEdit} headerText="Labels for Targeting Type" handler={setIsTargetingModalOpen.bind(null, true)} usePlus>
          {
            objectToObjArr(objectData.audienceTypeMap).map((target, index) => {
              return (
                <Targets
                  key={target.key}
                  target={target}
                  colored={index % 2 !== 0}
                  isEdit={isEdit}
                  onEdit={handleEditTargetingTypeModal.bind(null, target)}
                  onDelete={handleDeleteTargetTypeModal.bind(null, target)}
                />
              )
            })
          }
        </AdvancedTable>

        <AdvancedTable isEdit={isEdit} headerText='Labels for Placements' onEdit={setIsPlacementModalOpen.bind(null, true)} useEdit>
          {
            objectToObjArr(objectData.placementMap).map((placement, index) => {
              return (
                <Placement
                  placement={placement}
                  key={placement.key}
                  colored={index % 2 !== 0}
                  isEdit={isEdit}
                />
              )
            })
          }
        </AdvancedTable>
      </AdvancedTableGrid>
      { /* API NETWORK */}
      {
        isPlatformModalOpen &&
        <PlatformModal
          platform={selectedPlatform}
          platforms={objectData?.platformMap}
          onClose={handleClosePlatformModal}
          updatePlatform={handleUpdateTable}
        />
      }

      {/* CHANNEL */}
      {isEditModalOpen &&
        <ChannelModal
          onClose={handleOnCloseNetworkModal}
          selectedNetwork={selectedNetwork}
        />
      }
      {isDeleteModalOpen && (
        <DeleteModal
          header="Delete Channel"
          modalText={`Deleting Dummy Campaign`}
          deleteBtnText="Delete Channel"
          setIsDeleteModalOpen={handleOnCloseNetworkModal}
          handleDelete={handleOnDeleteNetwork}
          loading={deleteLoader}
        />
      )}
      {/* TARGETS */}
      {isTargetingModalOpen &&
        <TargetingModal
          onClose={handleCloseTargetingModal}
          target={selectedTarget}
          targets={objectData.audienceTypeMap}
          updateTargets={handleUpdateTable}
        />
      }
      {
        isDeleteTargetModalOpen &&
        <DeleteModal
          header='Delete Target'
          modalText={`Deleting Target ${selectedTarget?.key}.`}
          deleteBtnText='Delete Target'
          setIsDeleteModalOpen={handleCloseDeleteTargetingTypeModal}
          handleDelete={handleDeleteTarget}
        />
      }
      {/* PLACEMENT */}
      {
        isPlacementModalOpen &&
        <PlacementModal
          onClose={() => setIsPlacementModalOpen(false)}
          placements={objectData.placementMap}
          setObjectData={setObjectData}
          updatePlacement={handleUpdateTable}
        />
      }
    </StyleLabelManagement>
  );
};

export default LabelManagement;
