import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import Loader from '../../../../ui/Loader';
import PromoteAppModal from '../modal/PromoteAppModal';
import AudienceTargetModal from '../modal/AudienceTargetModal';
import CreatableSelect from "react-select";
import CheckBox from '../../../../ui/input/checkbox/CheckBox';
import EditContext from '../Context/EditContext';

// Util
import { createDeepCopy } from '../../../../utils/objects';

// Redux
import { conversionEvents, conversionLocationEvents, getInventoryFilters } from '../../../../actions/socialActions';
import { getFacebookSpecialAdCategory, getFbConfig, updateNetworkConfig } from '../../../../actions/lobActions';
import { shouldUpdateString } from '../../../../utils/payload';

// Styles
import { SubHeader } from '../../../../ui/headers';
import { EditSaveBtn } from '../../../../ui/button';
import { FieldContainer, StyledFieldsWrapper } from '../../../../ui/containers';
import { Input } from '../../../../ui/input/input';
import { selectTheme } from '../../../../ui/themes';
import { OneThirdGridColContainer } from '../../../../ui/containers';

import { toast } from 'react-toastify';
import FacebookNetworkTable from '../tables/FacebookNetworkTable';
import FacebookAdAccountModal from '../modal/SocialAccounts/FacebookAdAccountModal';
import { useDispatch, useSelector } from 'react-redux';
import FacebookPageModal from '../modal/SocialAccounts/FacebookPageModal';
import FacebookPagesTable from '../tables/FacebookPagesTable';
import PromoteTable from '../tables/PromoteTable';
import FacebookAdSetTable from '../tables/FacebookAdSetTable';
import Beneficiary from './facebook/Beneficiary';
import NamingConfiguration from '../NamingConfiguration';
import FbIcon from '../../../../assets/images/socialMediaIcons/FbIcon.png';

const FacebookNetworkConfig = () => {
    const currNetworkConfig = useSelector(s => s.lob.currentNetworkConfig)
    const lob = useSelector(s => s.lob.currentLineOfBusiness)

    const _D = useDispatch()

    const [fbConfigId, setFbConfigId] = useState();

    // * Modals
    const [isFbPageModalOpen, setIsFbPageModalOpen] = useState(false);
    const [isFbAdAccountModalOpen, setIsFbAdAccountModalOpen] = useState(false);
    const [isPromoteAppModalOpen, setIsPromoteAppModalOpen] = useState(false);
    const [isAudienceTargetModalOpen, setIsAudienceTargetModalOpen] = useState(false);

    const [conversionEventList, setConversionEventList] = useState([]);
    const [conversionEventLocationList, setConversionEventLocationList] = useState([]);
    const [conversionEvent, setConversionEvent] = useState('');
    const [conversionEventLocation, setConversionEventLocation] = useState('');
    const [pixelId, setPixelId] = useState();
    const [adSetNamingConvention, setAdSetNamingConvention] = useState();
    const [campaignNamingConvention, setCampaignNamingConvention] = useState();
    const [appsToPromote, setAppsToPromote] = useState({});
    const [euPayer, setEuPayer] = useState();
    const [euBeneficiary, setEuBeneficiary] = useState();

    const [fbSocialAccounts, setFbSocialAccounts] = useState([]);
    const [adAccounts, setAdAccounts] = useState([]);
    const [specialAdCategoryOptions, setSpecialCategoryOptions] = useState([]);
    const [selectedSpecialCat, setSelectedSpecialCat] = useState([]);
    const [isDynamicCreative, setIsDynamicCreative] = useState(false);
    const [facebookInventoryFilter, setFacebookInventoryFilter] = useState('');
    const [audienceNetworkInventoryFilter, setAudienceNetworkInventoryFilter] = useState('');
    const [inventoryFilterOptions, setInventoryFilterOptions] = useState([]);

    const [loader, setLoader] = useState(true);

    // * For Editing an Adset (Facebook)
    const [editedAdsetData, setEditedAdsetData] = useState(null); // * EXISTING data that will be edited (modified)
    const [isEditingAdset, setIsEditingAdset] = useState(false); // * controls if the user is in a edit state
    const [editedIndex, setEditedIndex] = useState(null) // * will find the index of the adset to "replace"

    const { isEdit, setIsEdit } = useContext(EditContext);
    // TODO to refactor into context
    // const FacebookNetworkConfigContext = React.createContext();

    useEffect(() => {
        const fbKey = lob?.networkConfigs.find(network => network.keyParam === 'fbconfig');
        setFbConfigId(fbKey?.websafe);

        conversionEvents(res => setConversionEventList(res.map(e => {
            return ({
                    label: e.charAt(0) + e.substring(1).toLowerCase().replaceAll('_', ' '),
                    value: e
                })
            })
        ));

        conversionLocationEvents(res => setConversionEventLocationList(res.map(e => {
            return ({
                    label: e,
                    value: e
                })
            })
        ));

        getFacebookSpecialAdCategory().then(res => {
            setSpecialCategoryOptions(res.map(it => ({label: it.replaceAll('_', ' '), value: it})))
        })

        getInventoryFilters(res => setInventoryFilterOptions(res.map(e => {
            return ({
                label: e,
                value: e
            })
        })))
    }, [])

    useEffect(() => {
        if (!fbConfigId) {
            setLoader(true);
            return;
        }

        _D(getFbConfig(
            {
                fbConfigId,
                callback: (res) => {
                    setSelectedSpecialCat(res.specialAdCategories.map(it => ({label: it.replaceAll('_', ' '), value: it})))
                    setLoader(false);
                }
            }
        ));
    }, [fbConfigId]);

    useEffect(() => {
        setAppsToPromote(currNetworkConfig?.appsToPromote);
        setFbSocialAccounts(currNetworkConfig?.fbSocialAccounts);
        setAdAccounts(currNetworkConfig?.adAccounts);
        setIsDynamicCreative(currNetworkConfig?.isDynamicCreative);
        setFacebookInventoryFilter(currNetworkConfig?.facebookInventoryFilter);
        setAudienceNetworkInventoryFilter(currNetworkConfig?.audienceNetworkInventoryFilter);
        setCampaignNamingConvention(currNetworkConfig?.campaignNamingConvention);
        setAdSetNamingConvention(currNetworkConfig?.adSetNamingConvention)
        setConversionEventLocation(currNetworkConfig?.conversionEventLocation);
        setConversionEvent(currNetworkConfig?.conversionEvent)
        setPixelId(currNetworkConfig?.pixelId);
        setEuPayer(currNetworkConfig?.dsaPayor);
        setEuBeneficiary(currNetworkConfig?.dsaBeneficiary)
    }, [currNetworkConfig])

    const handleOnCloseAdsetModal = () => {
        // * reset edit state, and clean up edited data upon modal close.
        if(isEditingAdset) {
            setIsEditingAdset(false);
            setEditedAdsetData(null)
        }
        setIsAudienceTargetModalOpen(false)
    }

    const handleEditAdset = (data) => {
        //  * find index of adset object that matches within audienceTarget array
        let index = currNetworkConfig?.audienceTargets?.findIndex(obj => obj === data)
        setEditedIndex(index)

        // * Deeply clone data to be edited
        const cloneData = createDeepCopy(data)
        setEditedAdsetData(cloneData)

        // * Begin edit flow and open modal
        setIsEditingAdset(true);
        setIsAudienceTargetModalOpen(true);
    }

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};

            if (shouldUpdateString(pixelId, currNetworkConfig?.pixelId)) updateObj.pixelId = pixelId;
            if (shouldUpdateString(conversionEvent, currNetworkConfig?.conversionEvent)) updateObj.conversionEvent = conversionEvent;
            if (shouldUpdateString(conversionEventLocation, currNetworkConfig?.conversionEventLocation)) updateObj.conversionEventLocation = conversionEventLocation;
            if (shouldUpdateString(adSetNamingConvention, currNetworkConfig?.adSetNamingConvention)) updateObj.adSetNamingConvention = adSetNamingConvention;
            if (shouldUpdateString(campaignNamingConvention, currNetworkConfig?.campaignNamingConvention)) updateObj.campaignNamingConvention = campaignNamingConvention;
            if (shouldUpdateString(euPayer, currNetworkConfig?.dsaPayor)) updateObj.dsaPayor = euPayer;
            if (shouldUpdateString(euBeneficiary, currNetworkConfig?.dsaBeneficiary)) updateObj.dsaBeneficiary = euBeneficiary;
            if (appsToPromote !== currNetworkConfig?.appsToPromote) updateObj.appsToPromote = appsToPromote;
            if (isDynamicCreative !== currNetworkConfig?.isDynamicCreative) updateObj.isDynamicCreative = isDynamicCreative;
            if (facebookInventoryFilter !== currNetworkConfig?.facebookInventoryFilter) updateObj.facebookInventoryFilter = facebookInventoryFilter;
            if (audienceNetworkInventoryFilter !== currNetworkConfig?.audienceNetworkInventoryFilter) updateObj.audienceNetworkInventoryFilter = audienceNetworkInventoryFilter;

            let shoudUpdate = false
            if (selectedSpecialCat?.length !== currNetworkConfig?.specialAdCategories?.length) shoudUpdate = true

            selectedSpecialCat.forEach(cat => {
                if (!currNetworkConfig?.specialAdCategories.includes(cat?.value)) {
                    shoudUpdate = true
                }
            })

            if (shoudUpdate) {
                updateObj.specialAdCategories = selectedSpecialCat.map(it => it.value);
            }

            if (Object.keys(updateObj).length > 0) {
                _D(updateNetworkConfig({
                    obj: updateObj,
                    socialConfig: `fbconfig=${fbConfigId}`,
                    callback: () => {
                        toast.success('Updated Facebook Network Config');
                    }
                }))
            }
        }

        setIsEdit(!isEdit);
    }

    if (loader) return <Loader size={55}/>

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                <span>
                    <img src={FbIcon} alt='icon' />
                    Configurations for Facebook Campaigns
                </span>
                {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>

            <OneThirdGridColContainer>
                <FieldContainer>
                    <h3> EU Payer </h3>
                    {
                        isEdit ?
                        <Input value={euPayer} onChange={e => setEuPayer(e.target.value)} /> :
                        <p>{euPayer || 'N/A'}</p>
                    }
                </FieldContainer>
                <Beneficiary
                    isEdit={isEdit}
                    euBeneficiary={euBeneficiary}
                    setEuBeneficiary={setEuBeneficiary}
                />

                <FieldContainer>
                    <h3> Special Ad Category </h3>
                    {
                        isEdit ?
                            <CreatableSelect
                                isDisabled={false}
                                styles={selectTheme}
                                isClearable
                                isMulti
                                options={specialAdCategoryOptions}
                                onChange={e => setSelectedSpecialCat(e)}
                                value={selectedSpecialCat}
                            />
                            :
                            <p> {selectedSpecialCat?.length ? selectedSpecialCat.map(it => it.label.replaceAll('_', ' '))?.join(', ') : 'N/A'} </p>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <FieldContainer>
                    <h3> Dynamic Creative </h3>
                    <CheckBox
                        checked={isDynamicCreative}
                        callback={() => setIsDynamicCreative(!isDynamicCreative)}
                        isDisabled={!isEdit}
                        label={'Use Dynamic Creative'}
                    />
                </FieldContainer>

                <FieldContainer>
                    <h3> Inventory Filter for Audience Network </h3>
                    {
                        isEdit ?
                            <Select
                                isClearable
                                options={inventoryFilterOptions}
                                defaultValue={{ label: audienceNetworkInventoryFilter, value: audienceNetworkInventoryFilter }}
                                styles={selectTheme}
                                onChange={e => setAudienceNetworkInventoryFilter(e.value)}
                            /> :
                            <p> {audienceNetworkInventoryFilter || 'N/A'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> Inventory Filter For Instream </h3>
                    {
                        isEdit ?
                            <Select
                                isClearable
                                options={inventoryFilterOptions}
                                defaultValue={{ label: facebookInventoryFilter, value: facebookInventoryFilter }}
                                styles={selectTheme}
                                onChange={e => setFacebookInventoryFilter(e.value)}
                            /> :
                            <p> {facebookInventoryFilter || 'N/A'} </p>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <FacebookPagesTable
                    tableData={fbSocialAccounts}
                    isEdit={isEdit}
                    handleOnCreate={() => setIsFbPageModalOpen(true)}
                    fbConfigId={fbConfigId}
                />
                <FacebookNetworkTable
                    tableData={adAccounts}
                    isEdit={isEdit}
                    handleOnCreate={() => setIsFbAdAccountModalOpen(true)}
                    fbConfigId={fbConfigId}
                    updateTableData={setAdAccounts}
                />
                <FacebookAdSetTable
                    tableData={currNetworkConfig?.audienceTargets}
                    isEdit={isEdit}
                    handleOnCreate={() => setIsAudienceTargetModalOpen(true)}
                    handleOnEdit={handleEditAdset}
                    fbConfigId={fbConfigId}
                />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <PromoteTable
                    isEdit={isEdit}
                    tableData={appsToPromote}
                    fbConfigId={fbConfigId}
                    successText='App to Promote and Store URL Deleted'
                    handleOnCreate={() => setIsPromoteAppModalOpen(true)}
                />
            </OneThirdGridColContainer>

            <NamingConfiguration
                network='Facebook'
                isEdit={isEdit}
                namingConvention={campaignNamingConvention}
                setNamingConvention={setCampaignNamingConvention}
                groupNamingConvention={adSetNamingConvention}
                setGroupNamingConvention={setAdSetNamingConvention}
            />
            {
                isFbAdAccountModalOpen &&
                <FacebookAdAccountModal
                    configId={fbConfigId}
                    onClose={() => setIsFbAdAccountModalOpen(false)}
                    data={adAccounts}
                    setData={setAdAccounts}
                />
            }
            {
                isFbPageModalOpen &&
                <FacebookPageModal
                    configId={fbConfigId}
                    onClose={() => setIsFbPageModalOpen(false)}
                />
            }
            {
                isPromoteAppModalOpen &&
                <PromoteAppModal
                    onClose={() => setIsPromoteAppModalOpen(false)}
                    fbConfigId={fbConfigId}
                    appsToPromote={currNetworkConfig?.appsToPromote}
                />
            }
            {
                isAudienceTargetModalOpen &&
                <AudienceTargetModal
                    onClose={() => handleOnCloseAdsetModal(false)}
                    fbConfig={currNetworkConfig}
                    audienceDescriptionList={lob?.audienceDescriptions}
                    adAccountsList={currNetworkConfig?.adAccounts}
                    audienceTargets={currNetworkConfig?.audienceTargets}
                    editedAdsetData={editedAdsetData}
                    isEditingAdset={isEditingAdset}
                    editedIndex={editedIndex}
                    fbConfigId={fbConfigId}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default FacebookNetworkConfig;