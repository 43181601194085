import styled from 'styled-components';



export const StyleDummyNetworkLinkUpload = styled.div`
    width: 600px;
    height: 500px;


`
export const StyleDummyNetworkUnit = styled.div`
    padding: 1rem;
    background-color: #FAFAFA;
    height: 153px;
    margin-bottom: 20px;
    .input-seperator {
        margin-top: 20px;
    }
    .input-seperator-title {
        color: #424242;
        margin-bottom: 5px;
    }
    p {
        font-size: 16px;
        margin-top: 5px;
    }
`