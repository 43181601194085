import styled from "styled-components";

export const FilterContainer = styled.div`
    font-family: 'GothamLight';
    z-index: 100;
    position: absolute;
    height: 456px;
    width: 360px;
    background-color: ${props => props.theme.color.white};
    box-shadow: 0px 0px 6.65px rgba(244, 182, 63, 0.2), 2.95556px 0px 2.95556px rgba(0, 0, 0, 0.04), 1.47778px 1.47778px 1.47778px rgba(0, 0, 0, 0.04), 1.47778px 1.47778px 13.3px rgba(241, 195, 129, 0.2);
    padding: 24px;
    margin-top: 5px;
    border-radius: 9px;
    right: 0;

    // * Body of filter
    .filter-content {
        display: flex;
        flex-direction: column;
    }

    .category-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .chevron-container {
        display: flex;
        align-items: center;

        svg {
            cursor: pointer;
        }
    }
`
// * Category Options Wrapper
export const CategoryOptionsWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    gap: 16px;
    overflow: scroll;

    // * Hide scrollbar
    ::-webkit-scrollbar {
        display: none;
    }
`

// * Category Item
export const CategoryItem = styled.div`
    cursor: pointer;
    font-weight: ${props => props.isSelected ? 'bold' : 300};

    .category-text {
        font-size: 14px;
        border-bottom: ${props => props.isSelected && `2px solid ${props.theme.color.yellow}`};
    }
`

// * Input Container
export const SearchContainer = styled.div`
    margin-top: 16px;

    div {
        width: 100%;
    }
`

// * Counter Container
export const CounterContainer = styled.div`
    margin-top: 6px;
    border-radius: 6px;
    background-color: rgba(247, 229, 201, 0.8);
    padding: 4px 12px;
    font-size: 10px;
    font-weight: 300;
    height: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    font-size: 12px;
`

// * Quick Actions
export const QuickActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    gap: 8px;
    text-decoration: underline;

    small {
        cursor: pointer;
    }
`

// * Filter Option Container
export const FilterOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 250px;
    overflow-y: scroll;
    margin-top: 16px;
`

// * Filter Option
export const FilterOption = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    gap: 16px;

    input[type=checkbox] {
        height: 16px;
        width: 16px;
        accent-color: ${props => props.theme.color.yellow};
        cursor: pointer;
    }
`