import React, {useState, useEffect} from 'react';
import Select from 'react-select';

// Styles
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { TableModalContainer } from '../../../../../ui/table/styles';
import { Input } from '../../../../../ui/input/input';
import { selectThemeModal } from '../../../../../ui/themes';
import { Asterisk } from '../../../../../ui/text';
import { useSelector } from 'react-redux';

const InfoStep = ({
        name,
        setName, setAdAccount, setDescription,
        adAccounts, audienceDescriptions, setSelectedAudienceType,
        getDefaultAudienceDescription, getDefaultAudienceType, getDefaultAccount
    }) => {
    const [adAccountList, setAdAccountList] = useState([]);
    const [descriptionsList, setDescriptionsList] = useState([]);
    const audienceTypes = useSelector(s => s.org.organization.audienceTypes)

    useEffect(() => {
        const list = adAccounts?.map(account => {
            return ({
                label: account.displayName,
                value: account
            })
        })

        setAdAccountList(list);

        const descriptions = audienceDescriptions.map(description => {
            return ({
                label: description,
                value: description
            })
        })

        setDescriptionsList(descriptions);
    }, [adAccounts])



    const audienceTypesOption = audienceTypes?.map(type => {
        return ({
            label: type,
            value: type
        })
    })

    return (
        <TableModalContainer>
            <ModalTextSecondary> Ad Group Name<Asterisk>*</Asterisk> </ModalTextSecondary>
            <Input type='text' placeholder={'Enter Ad Group Name'} onChange={e => setName(e.target.value)} value={name}/>

            <ModalTextSecondary> Select Ad Account<Asterisk>*</Asterisk> </ModalTextSecondary>
            <Select
                isClearable
                options={adAccountList}
                styles={selectThemeModal}
                onChange={e => setAdAccount(e.value)}
                value={getDefaultAccount()}
                placeholder={'Select an Account'}
            />

            <ModalTextSecondary> Select Targeting Type<Asterisk>*</Asterisk> </ModalTextSecondary>
            <Select
                isClearable
                options={audienceTypesOption}
                styles={selectThemeModal}
                onChange={e => setSelectedAudienceType(e.label)}
                value={getDefaultAudienceType()}
                placeholder={'Select a Type'}
            />

            <ModalTextSecondary> Select Audience Description<Asterisk>*</Asterisk> </ModalTextSecondary>
            <Select
                isClearable
                options={descriptionsList}
                styles={selectThemeModal}
                onChange={e => setDescription(e.label)}
                value={getDefaultAudienceDescription()}
                placeholder={'Select a Description'}
            />
        </TableModalContainer>
    )
}

export default InfoStep;