import React, {useState} from 'react';
import Modal from '../../../../../components/Modal/Modal';

// Redux
import { useDispatch } from 'react-redux';
import { getAdvertiserList, updateAdvertiser } from '../../../../../actions/systemConfigActions';

// Styles
import { ModalText } from '../../../../../components/Modal/styles';
import { BtnWithLoader, CenterButtons, OutlineBtn } from '../../../../../ui/button';
import { toast } from 'react-toastify';

const ArchiveModal = ({onClose, editData}) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();

    const handleArchive = () => {
        setLoader(true);

        dispatch(updateAdvertiser({
            websafe: editData.websafe,
            advertiserObj: {archived: true},
            callback: () => {
                onClose();
                dispatch(getAdvertiserList({errorCallback: _ => {
                    toast.error('Something went wrong fetching Advertisers');
                }}))
                toast.success('Successfully archived');
                setLoader(false);
            },
            errorCallback: (err) => {
                toast.error('Something went wrong');
                console.error(err);
                setLoader(false);
            }
        }))
    }

    return (
        <Modal
            onClose={onClose}
            header='Archive Advertiser'
            style={{width: '600px'}}
        >
            <ModalText>
                Are you sure you want to archive {editData.name}? This advertiser will no longer be available for newly created network templates or LOBs.
            </ModalText>
            {
                CenterButtons(
                    <>
                        <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                        <BtnWithLoader type='blackBtn' loader={loader} onClick={handleArchive} btnText='Archive Advertiser' />
                    </>
                )
            }
        </Modal>
    )
}

export default ArchiveModal;