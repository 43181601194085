import React, { useState } from 'react'
import BuildModal from '../../../../../components/Modal/modals/BuildModal'
import ConnectSocialModal from '../ConnectSocialModal'
import FbIcon from '../../../../../assets/images/socialMediaIcons/FbIcon.png';
import { useDispatch } from 'react-redux'
import { BlackBtn, BtnWithLoader, OutlineBtn } from '../../../../../ui/button'
import { toast } from 'react-toastify';
import SelectNetworkAccountStep from './SelectNetworkAccountStep';
import AddSocialNetworkAccountStep from './AddSocialNetworkAccountStep';
import { attachAdAccount } from '../../../../../actions/lobActions';
import AddFacebookBiddingFocus from './AddFacebookBiddingFocus';

const FacebookAdAccountModal = ({ configId, onClose, data, setData }) => {
    const _D = useDispatch()
    const [step, setStep] = useState(1);
    const [btnLoader, setBtnLoader] = useState(false);
    const [isAddSocialLoginModalOpen, setIsAddSocialLoginModalOpen] = useState(false)

    // * Step 1 States
    const [selectedAccount, setSelectedAccount] = useState();
    // * Step 2 States
    const [selectedFbAccount, setSelectedFbAccount] = useState([])
    // * Step 3 States
    const [costPerResultGoal, setCostPerResultGoal] = useState(0)
    const [bidControl, setBidControl] = useState(0)
    const [adPixel, setAdPixel] = useState({});
    const [catalog, setCatalog] = useState({});

    const fbNetworkObj = {
        name: 'Facebook',
        header: 'Facebook ad account(s)',
        icon: FbIcon,
    }

    const nextStep = () => setStep(step + 1)
    const prevStep = () => setStep(step - 1)

    // * Submit Function
    const handleAttachAddAccount = (list) => {
        setBtnLoader(true)
        setData([...data, list[0]])

        _D(attachAdAccount({
            socialLoginId: selectedAccount?.id,
            socialConfig: `fbconfig=${configId}`,
            pages: [
                { accountId: list[0]?.id,
                  targetCpa: costPerResultGoal,
                  cpcBidLimit: bidControl,
                  adPixel,
                  catalog
                }], // * Why does BE expect this to be the payload? It seems rather complicated
            callback: () => {
                toast.success('Adding Ad Account');
                setBtnLoader(false)
                onClose();
            },
            errorCallback: () => {
                toast.error('Something went wrong');
                setBtnLoader(false)
            }
        }))
    }

    // * Steps
    const steps = () => {
        switch (step) {
            case 1: return <SelectNetworkAccountStep
                isAddSocialLoginModalOpen={isAddSocialLoginModalOpen}
                handleAddSocialLogin={() => setIsAddSocialLoginModalOpen(true)}
                networkType={fbNetworkObj}
                setSelectedAccount={setSelectedAccount}
                selectedAccount={selectedAccount}
            />
            case 2: return <AddSocialNetworkAccountStep
                socialLoginId={selectedAccount?.id}
                selectedPages={selectedFbAccount}
                setSelectedPages={setSelectedFbAccount}
                socialType='facebook'
                tableHeader='Facebook Ad Account(s)'
            />
            case 3: return <AddFacebookBiddingFocus
                selectedFbAccount={selectedFbAccount}
                setCostPerResultGoal={setCostPerResultGoal}
                setBidControl={setBidControl}
                costPerResultGoal={costPerResultGoal}
                bidControl={bidControl}
                socialLoginId={selectedAccount?.id}
                setAdPixel={setAdPixel}
                setCatalog={setCatalog}
            />
            default:
                console.log(`No step selected`);
        }
    }


    // * Buttons
    const decideButtons = () => {
        switch (step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BlackBtn isDisabled={!selectedAccount} onClick={nextStep}> Next </BlackBtn>]
            case 2: return [<OutlineBtn onClick={prevStep}> Back </OutlineBtn>, <BlackBtn isDisabled={!selectedFbAccount.length} onClick={nextStep}> Next </BlackBtn>]
            case 3: return [<OutlineBtn onClick={prevStep}> Back </OutlineBtn>, <BtnWithLoader btnText="Add Facebook Ad Accounts" type='blackBtn' color='white' isDisabled={!selectedFbAccount.length} onClick={() => handleAttachAddAccount(selectedFbAccount)} loader={btnLoader} />]
            default: return []
        }
    }

    return (
        <>
            <BuildModal
                header="Attach Facebook Ad Account"
                onClose={onClose}
                buttons={decideButtons()}
                height={"750px"}
                minWidth={"700px"}
            >
                {steps()}
            </BuildModal>
            {
                isAddSocialLoginModalOpen &&
                <ConnectSocialModal
                    onClose={() => setIsAddSocialLoginModalOpen(false)}
                    network={fbNetworkObj}
                />
            }
        </>
    )
}

export default FacebookAdAccountModal