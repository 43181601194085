import styled from "styled-components";

// **** LINKEDIN AUDIENCE MODAL ***** /
export const StyledAttributeWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`

export const AttributeSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const SearchResults = styled.div`
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow-y: scroll;
    border: 1px solid ${props => props.theme.color.gray};
    border-radius: 8px;
`

export const ResultItem = styled.div`
    input[type='checkbox'] {
        height: 24px;
        width: 24px;
        accent-color: ${props => props.theme.color.yellow};
        border-radius: 2px;
        border-color: ${props => props.theme.color.mediumGray};
        cursor: pointer;
    }

    padding: 8px 8px 8px 16px;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 12px;
    // * Selection styles
    background: ${props => props?.isSelected && props.theme.background.lightOrange};
`

// **** LINKEDIN AUDIENCE COMPONENTS ***** /

export const StyledAttributeGroup = styled.div`
    border: 1px solid ${props => props.theme.color.gray};
    border-radius: 8px;
    padding: 16px;
    display: flex;
    gap: 32px;

    .attribute-left {
        display: flex;
        flex-direction: column;
        gap: 6px;
        flex: 1;
    }

    .attribute-right {
        svg {
            color: ${props => props.theme.color.yellow};
            cursor: pointer;
        }
    }
`

export const StyledAttributeItem = styled.div`
    width: 932px;
    border: 1px solid ${props => props.theme.color.gray};
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tag-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        font-size: 12px;
        align-items: center;

        .add-btn {
            color: ${props => props.theme.color.yellow};
            cursor: pointer;
        }
    }
`

export const StyledTag = styled.div`
    background: ${props => props.theme.card.yellow};
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    p {
        display: inline-block;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 700;
    }

    svg {
        cursor: pointer;
    }
`