import React from 'react';
import Select from 'react-select';

// Styles
import { FieldContainer } from '../../../../ui/containers';
import { selectTheme } from '../../../../ui/themes';

const ConversionEventLocation = ({isEdit}) => {
    return (
        <FieldContainer>
            <h3> Conversion Event Location </h3>
            {
                isEdit ?
                <Select
                    isClearable
                    defaultValue={{
                        label: 'Website',
                        value: 'Website'
                    }}
                    styles={selectTheme}
                    isDisabled={true}
                /> :
                <p> Website </p>
            }
        </FieldContainer>
    )
}

export default ConversionEventLocation;