/* eslint-disable no-loop-func */
import { useEffect, useState } from "react";
import { getNetworkObjectives } from "../../actions/tempActions";
import { toast } from "react-toastify";
import { objectToReactSelectItem } from "./templates.util";

const useFBCampagin = () => {
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [selectedOptimization, setSelectedOptimization] = useState(null);
  const [platformPlacements, setPlatformPlacements] = useState(null);

  const [mapedOptimization, setMapedOptimization] = useState([]);
  const [mapedBillingEvents, setMapedBillingEvents] = useState([]);
  const [mapedPlatforms, setMapedPlatforms] = useState([]);
  const [mapedBidStrategies, setMapedBidStrategies] = useState([]);
  const [mapedPlacements, setMapedPlacements] = useState([]);
  const [mapedBudgetDeliveryPacings, setMapedBudgetDeliveryPacings] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [objectives, setObjectives] = useState({})

  useEffect(() => {
    getNetworkObjectives('facebook')
      .then((res) => setObjectives(res))
      .catch(_ => toast.error("Something went wrong"));
  },[])



  const defaultDisabledProps = {
    objectives: false,
    optimization: true,
    bidStrategy: true,
    budgetDeliveryPacings: true,
    billingEvents: true,
    platforms: true,
    placements: true,
    bidControll: true,
  };

  const [disabledProps, setDisabledProps] = useState(defaultDisabledProps);

  //select Objective
  const selectObjective = (objectiveName) => {
    const objective = objectives?.[objectiveName?.label || objectiveName]
    if (!objective) return;

      setMapedOptimization(objectToReactSelectItem(objective?.optimizations))
      setSelectedObjective(objective);
      setDisabledProps((prew) => ({ ...prew, optimization: false }));
  };

  //Select Optimization
  const selectOptimization = (optimizationName) => {
    const optimization = selectedObjective?.optimizations?.[optimizationName?.label || optimizationName]
    if (!optimization) return;

    const bidStrategies = objectToReactSelectItem(optimization?.bidStrategies)
    const billingEvents = optimization?.billingEvents.map((it) => ({ label: it, value: it }));
    const platforms = objectToReactSelectItem(optimization?.placements)

    setMapedBidStrategies(bidStrategies);
    setMapedBillingEvents(billingEvents);
    setMapedPlatforms(platforms);
    setDisabledProps((prew) => ({ ...prew, billingEvents: false, platforms: false, bidStrategy: false }));
    setPlatformPlacements(optimization?.placements);
    setSelectedOptimization(optimization);
    return;
  };


  //Select Bid Strategy
  const selectBidStrategy = (bidStrategyName) => {
    const foundedBid = selectedOptimization?.bidStrategies?.[bidStrategyName?.label || bidStrategyName];
    if (!foundedBid) return;
    setDisabledProps((prew) => ({ ...prew, budgetDeliveryPacings: false }));
    const budgetDeliveryPacings = foundedBid.budgetDeliveryPacings.map((it) => ({ label: it, value: it }));
    setMapedBudgetDeliveryPacings(budgetDeliveryPacings);
  };

  const selectPlatforms = (platforms) => {
    if (platforms.length) setDisabledProps((prew) => ({ ...prew, placements: false }));
    else setDisabledProps((prew) => ({ ...prew, placements: true }));
    let placements = []
    for(let channel in platformPlacements) {
      platforms.forEach(network => {
        let chunk = []
        for(let eachChannel in platformPlacements?.[channel]) {
          if(eachChannel?.includes(network?.label)) {
            chunk.push({label: eachChannel, value: eachChannel})
          }
        }
        placements = placements.concat(chunk)
      })
    }
    setMapedPlacements(placements);
    return placements
  };

  const defaultCampaignBudgetAdScheduling = { label: "Run ads all the time", value: "Run ads all the time" };

  return {
    selectObjective,
    selectOptimization,
    selectPlatforms,
    selectBidStrategy,
    setDisabledProps,
    disabledProps,
    defaultDisabledProps,
    _M: {
      mapedBidStrategies,
      mapedPlatforms,
      mapedBillingEvents,
      mapedOptimization,
      mapedPlacements,
      mapedObjectives: objectToReactSelectItem(objectives),
      mapedBudgetDeliveryPacings,
      mapedCampaignBudgetAdScheduling: defaultCampaignBudgetAdScheduling,
    },
  };
};

export default useFBCampagin;
