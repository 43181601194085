import React from 'react'
import Select from 'react-select'
import { selectTheme } from '../../../../ui/themes'
const AdLocation = ({ FieldContainer, isEdit, setAdLocation, adLocation, adLocationOptions, defaultValue }) => {
    return (
        <FieldContainer>
            <h3> Where Do You Want Your Ads To Appear</h3>
            {
                isEdit ?
                    <Select
                        styles={selectTheme}
                        isClearable
                        options={adLocationOptions}
                        onChange={e => setAdLocation(e.label)}
                        defaultValue={{
                            label: defaultValue,
                            value: defaultValue
                        }}
                    /> : <p>{adLocation || 'N/A'}</p>
            }
        </FieldContainer>
    )
}

export default AdLocation