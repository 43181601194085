import styled from 'styled-components';

export const InformationContainer = styled.div`
    cursor: pointer;
    margin: 0 5px;

    .tooltip {
        border: 1px solid ${props => props.theme.color.gray} !important;
        max-width: 300px;
        background-color: white;
        color: black;
    }
`;