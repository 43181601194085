import React from 'react';
// * Steps
import GoalPriority from './GoalPriority';
import SelectAudience from './SelectAudience';
import SelectNetwork from './SelectNetwork';
import SelectNetworkTemplates from './SelectNetworkTemplates';
import SelectSocialAdAccount from './SelectSocialAdAccount';
import AdFormatStep from './AdFormatStep';

import NoRunnableCampaignModal from '../NoRunnableCampaignModal';
import BuildModal from '../../../../../components/Modal/modals/BuildModal';

// Styles
import { BlackBtn, BtnWithLoader, OutlineBtn } from '../../../../../ui/button';
import useChannelSetUp from './useChannelSetUp';
import { NETWORKS } from '../../../../../constants/networks';

export const ChannelSetUpContext = React.createContext()

const ChannelSetupModal = ({ onClose }) => {
    const channelSetUpValues = useChannelSetUp({ onClose })
    const { handleChannelStep,
        handleGoalPriorityStep,
        handleBack,
        handleNext,
        handleSave,
        handleLinkedInTemplateStep,
        setIsNoRunnableCampaignModalOpen,
        isNoRunnableCampaignModalOpen,
        loader,
        channel,
        step,
        selectedAudienceDescription,
        goal,
        priority,
        selectedTemplate,
        selectedAccount,
        selectedAdFormats
    } = channelSetUpValues

    const isDisabled = () => {
        return (step === 1 && !channel) ||
            (step === 2 && !selectedAccount) ||
            (step === 3 && !selectedAudienceDescription.length) ||
            (step === 4 && (!goal || !priority)) ||
            (step === 5 && !Object.keys(selectedTemplate).length) ||
            (step === 6 && !selectedAdFormats.length)
    }

    const steps = () => {
        switch (step) {
            case 1: return <SelectNetwork />
            case 2: return <SelectSocialAdAccount />
            case 3: return <SelectAudience />
            case 4: return <GoalPriority />
            case 5: return <SelectNetworkTemplates />
            case 6: return <AdFormatStep />
            default:
                console.log(`No step selected`);
        }
    }

    // * Handle Button Logic
    const decideButtons = () => {
        const isBtnDisabled = isDisabled()

        switch (step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BtnWithLoader type='blackBtn' btnText='Confirm Network' isDisabled={isBtnDisabled} onClick={handleChannelStep} loader={loader} />]
            case 2: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BlackBtn onClick={handleNext} isDisabled={isBtnDisabled}> Confirm Ad Account </BlackBtn>]
            case 3: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BlackBtn onClick={handleNext} isDisabled={isBtnDisabled}> Confirm Audience Descriptions </BlackBtn>]
            case 4: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BtnWithLoader type='blackBtn' btnText='Confirm Goal and Priority' isDisabled={isBtnDisabled} onClick={handleGoalPriorityStep} loader={loader} />]
            case 5: {
                if (channel === NETWORKS.LINKEDIN) return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BtnWithLoader type='blackBtn' btnText='Next' isDisabled={isBtnDisabled} onClick={handleLinkedInTemplateStep} loader={loader} />]
                else return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BtnWithLoader type='blackBtn' btnText='Add Channel Setup' isDisabled={isBtnDisabled} onClick={handleSave} loader={loader} />]
            }
            case 6: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BtnWithLoader type='blackBtn' btnText='Apply Network Template' isDisabled={isBtnDisabled} onClick={handleSave} loader={loader} />]
            default: return []
        }
    }

    return (
        <BuildModal
            header='Channel Setup'
            onClose={onClose}
            buttons={decideButtons()}
            height={'750px'}
            minWidth={'875px'}
        >
            <ChannelSetUpContext.Provider value={channelSetUpValues}>
                {steps()}
                {isNoRunnableCampaignModalOpen &&
                    <NoRunnableCampaignModal
                        onClose={() => setIsNoRunnableCampaignModalOpen(false)}
                        channel={channel}
                        hardClose={() => {
                            onClose();
                            setIsNoRunnableCampaignModalOpen(false);
                        }}
                        handleSave={handleSave}
                    />
                }
            </ChannelSetUpContext.Provider>
        </BuildModal>
    )
}

export default ChannelSetupModal;