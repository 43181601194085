import styled from "styled-components";

export const StyleLabelManagement = styled.div``;

export const StyledTableItem = styled.div`
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  background: ${(props) => (props.colored ? "transparent" : props.theme.background.gray)};

  .each_item_info {
    padding: 0.2rem;
    width: 90%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .each_item_buttons {
    margin-top: 0.5rem;
    width: 50px;
    cursor: pointer;
    background: transparent;
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    transition: all 0.4s;
  }

  .each_item_icon {
    transition: all 0.4s;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: grid;
    place-items: center;
  }

  .each_chanel_icon:hover {
    background: ${(props) => (!props.colored ? "white" : props.theme.background.gray)};
    cursor: pointer;
  }

  .inner_wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .inner_header {
    font-weight: bold;
  }

  .inner_text {
    line-height: 20px;
  }


  .inner_image {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
  }
`;

export const StyleChannelModal = styled.div`
  display: grid;
  gap: 1rem;
  padding: 0.5rem;
  transition: all 0.4;
  line-height: 1.1;
  span {
    color: ${(props) => props.theme.color.yellow};
    font-weight: bold;
  }
  p {
    margin-bottom: 0.4rem;
  }

  .chanel_network-name {
    margin-top: 1rem;
  }
  .chanel_network-logo {
    display: grid;
    place-items: center;
    width: 580px;
    height: 252px;
    border: 1px dashed ${(props) => (props.intersect ? props.theme.color.yellow : props.theme.color.mediumYellow)};
    border-spacing: 30px;
    background-color: ${(props) => props.theme.background.gray};
    img {
        height: 230px;
    }
  }
  .chanel-logo_holder {
    text-align: center;
    color: ${(props) => props.theme.color.gray};

    svg {
      stroke: ${(props) => (props.intersect ? props.theme.color.yellow : props.theme.color.gray)};
    }
    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
  .chanel_network-file {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    svg {
      fill: ${(props) => props.theme.card.gray};
    }
  }
  .chanel_network-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }
  .chanel_network-text {
  }
  .chanel--title {
    font-family: "GothamMedium";
    text-decoration: underline;
  }
  .chanel--filename {
    font-size: 14px;
    color: ${(props) => props.theme.card.gray};
  }
`;


export const NameAbbreviationContainer = styled.div`
  .input__container {
    margin-top: 24px;
  }

  .input__header__text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;

    .asterisk {
      color: ${props => props.theme.color.red};
    }
  }

`

export const PlacementContainer = styled.div`
  width: 650px;
`

export const PlacementFieldWrapper = styled.div`
  height: 420px;
  overflow-y: scroll;

  // * Hide scroll bar
  ::-webkit-scrollbar {
    display: none;
  }
`

export const PlacementField = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  .placement-headers {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .placement-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .placement-value {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .placement-inputs {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .placement-input-title {
    font-size: 16px;;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .asterisk {
    color: ${props => props.theme.color.yellow};
  }
`