import React, { useRef, useState } from "react";
import { BsUpload } from "react-icons/bs";
import { ModalFieldContainer, ModalTextSecondary } from "../../../../../components/Modal/styles";
import { CsvSelectContainer } from "../../../../../ui/table/styles";
import { uploadBlob } from "../../../../../utils";
import { Input } from "../../../../../ui/input/input";
import { StyleDummyNetworkLinkUpload } from "./styles";
import Loader from "../../../../../ui/Loader";
const DummyCampaignBulkUpload = (
  {
    setbulkUploadSheets, setBulkUploadUrls,
    network, mediaPlan, fileName, setFileName,
    setCurrentBulkUrl, currenBulkUrl
}) => {

  const csvInputRef = useRef();
  const [loader, setLoader] = useState(false)

  const handleSetBulkUploadLinks = (network, value) => {
    setBulkUploadUrls(prew => {
      prew[network] = value;
      return prew
    })
  }
  const handleSetBulkUpload = (network, value) => {
    setbulkUploadSheets(prew => {
      prew[network] = value
      return prew
    })
  }

  const stateFileName = fileName || mediaPlan.bulkUploadSheets?.[network]?.filename
  const stateBulkUrl = currenBulkUrl || mediaPlan.bulkUploadUrls?.[network]

  return (
    <StyleDummyNetworkLinkUpload>
      <ModalFieldContainer>
        <ModalTextSecondary>
          Sample Bulk Upload Sheet <span className="asterisk">*</span>
        </ModalTextSecondary>
        <CsvSelectContainer onClick={() => csvInputRef.current.click()} minHeight>
          <div style={{textAlign: 'center'}}>
            {loader ? <Loader size={40}/> : <BsUpload size={50} />}
            <p style={{marginTop: '10px'}}>Choose a <strong>CSV file</strong> to Upload</p>
            {stateFileName && <p style={{marginTop: '10px', fontSize: '12px'}}>Current <strong>CSV file</strong> {stateFileName}</p>}
          </div>
          <br />
        </CsvSelectContainer>
        <input
          type="file"
          hidden
          accept=".csv"
          onChange={(e) => {
            setLoader(true)
            uploadBlob({
              file: e.target.files[0],
              callback: (file) => {
                handleSetBulkUpload(network, file.websafe)
                setFileName(file?.filename)
                setLoader(false)
              },
            })
          }
          }
          ref={csvInputRef}
        />
      </ModalFieldContainer>

      <ModalFieldContainer>
        <ModalTextSecondary> Enter Google Sheets URL </ModalTextSecondary>
        <Input defaultValue={stateBulkUrl} onChange={(e) => {
                handleSetBulkUploadLinks(network, e.target.value)
                setCurrentBulkUrl(e.target.value)
        }} placeholder="Please enter Sheets URL" />
      </ModalFieldContainer>
    </StyleDummyNetworkLinkUpload>
  );
};
export default DummyCampaignBulkUpload;
//ref={urlRef}
