import CheckBox from "../../../../ui/input/checkbox/CheckBox";

export const SearchPartnersField = ({searchPartners, setSearchPartners, isEdit, FieldContainer}) => {
    return (
        <FieldContainer>
            <h3>Search Partners </h3>
            <CheckBox
                checked={searchPartners}
                callback={() => setSearchPartners(!searchPartners)}
                isDisabled={!isEdit}
                label='Include Google Search Partners'
            />
        </FieldContainer>
    )
}

export default SearchPartnersField;