import { combineReducers } from 'redux';
import lobReducer from './lobReducer';
import userReducer from './userReducer';
import templateReducer from './templateReducer';
import mediaReducer from './mediaReducer';
import systemConfigReducer from './systemConfig';
import orgReducer from './orgReducer';
export default combineReducers({
    user: userReducer,
    lob: lobReducer,
    template: templateReducer,
    media: mediaReducer,
    system: systemConfigReducer,
    org: orgReducer
});