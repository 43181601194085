import CheckBox from "../../../../ui/input/checkbox/CheckBox";

export const AdRotationField = ({useAdRotation, setUseAdRotation, isEdit, FieldContainer }) => {
    return (
        <FieldContainer>
            <h3>Ad Rotation </h3>
            <CheckBox
                checked={useAdRotation}
                callback={() => setUseAdRotation(!useAdRotation)}
                isDisabled={!isEdit}
                label={'Optimize: Prefer Best performing ads'}
            />
        </FieldContainer>
    )
}

export default AdRotationField;