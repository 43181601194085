import React from 'react';
import Select from 'react-select';

// Styles
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { selectThemeModal } from '../../../../../ui/themes';
import { Input } from '../../../../../ui/input/input';
import { useSelector } from 'react-redux';

const DescriptionStep = ({
        setAudienceTargetName, setAdAccount, setSelectedAudienceType, setAudienceDescription,
        audienceTargetName, getDefaultAudienceType, getDefaultAudienceDescription, getDefaultAccount,
        adAccountOptions, audienceDescriptionOptions,
    }) => {

    const audienceTypes = useSelector(s => s.org.organization.audienceTypes)

    const audienceTypesOption = audienceTypes?.map(type => {
        return ({
            label: type,
            value: type
        })
    })

    return <>
        <ModalTextSecondary> Adset Name <span className='asterisk'>*</span></ModalTextSecondary>
        <Input type='text' placeholder={'Enter Audience Target Name'} onChange={e => setAudienceTargetName(e.target.value)} value={audienceTargetName} />

        <ModalTextSecondary> Select Ad Account <span className='asterisk'>*</span></ModalTextSecondary>
        <Select
            isClearable
            options={adAccountOptions}
            styles={selectThemeModal}
            onChange={e => setAdAccount(e.value)}
            value={getDefaultAccount()}
            placeholder={'Select an Account'}
        />

        <ModalTextSecondary> Select Targeting Type<span className='asterisk'>*</span></ModalTextSecondary>
        <Select
            isClearable
            options={audienceTypesOption}
            styles={selectThemeModal}
            onChange={e => setSelectedAudienceType(e.label)}
            value={getDefaultAudienceType()}
            placeholder={'Select a Type'}
        />

        <ModalTextSecondary> Select Audience Description <span className='asterisk'>*</span></ModalTextSecondary>
        <Select
            isClearable
            options={audienceDescriptionOptions}
            styles={selectThemeModal}
            onChange={e => setAudienceDescription(e.label)}
            value={getDefaultAudienceDescription()}
            placeholder={'Select a Description'}
        />
    </>
}

export default DescriptionStep;