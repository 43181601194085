import React, { useState, useEffect } from 'react';
import ViewMoreAdGroupModal from './modal/ViewMoreAdGroupModal';
import EditAdGroupModal from './modal/EditAdGroupModal';
import DeleteModal from '../../../components/Modal/DeleteModal';
import Loader from '../../../ui/Loader';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getAdGroup, deleteAdGroup, deleteCampaign, generateName, updateAdGroup } from '../../../actions/mediaActions';

// Styles
import GoogleIcon from '../../../assets/images/socialMediaIcons/GoogleIcon.png';
import { AdGroupSetupContainer, AdGroupListContainer, AdGroupListItem, AdGroupAudienceTargetHeader, AdGroupAudienceTargetContainer, AdGroupAudienceTargetItem, AdGroupAudienceTargetWrapper, AudienceTargetOr, AudienceTargetAnd } from '../styles';
import { PageHeaderContainer, ColumnHeader } from '../../../ui/headers';
import { RedOutlineIconBtn, EditSaveBtn, CenterBtnContainer, StyleCenterElement } from '../../../ui/button';
import { OneThirdGridColContainer, FieldContainer } from '../../../ui/containers';
import { Input } from '../../../ui/input/input';
import { FiPlusCircle } from 'react-icons/fi';
import { BiTrash, BiRefresh } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';


const GoogleAdGroupSetup = ({campaign, mediaPlan, resetPage, adSetLoader, setAdSetLoader}) => {
    const [name, setName] = useState('');
    const [currAdGroupId, setCurrAdGroupId] = useState();
    const [adGroups, setAdGroups] = useState([]);
    const [viewObj, setViewObj] = useState({});
    const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteCampaignConfirmationModalOpen, setIsDeleteCampaignConfirmationModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isViewMoreKeywordModalOpen, setIsViewMoreKeywordModalOpen] = useState(false);

    const currAdGroup = useSelector(state => state.media.currentAdGroup);

    const dispatch = useDispatch();

    const googleIcon = <img src={GoogleIcon} alt='img' />;

    useEffect(() => {
        if (!campaign?.adGroups?.length) {
            setAdGroups([]);
            return;
        }
        setCurrAdGroupId(campaign?.adGroups[0].websafe);
        setAdGroups(campaign?.adGroups);
    }, [campaign]);

    useEffect(() => {
        if (!currAdGroupId) return;
        setAdSetLoader(true)

        dispatch(getAdGroup({
            mediaPlanAdGroup: currAdGroupId,
            callback: (res) => {
                setAdSetLoader(false);
                setName(res?.name)
            }
        }))
    }, [currAdGroupId])

    const { includedSegments, excludedSegments, keywords, negativeKeywords } = currAdGroup?.audienceTarget || {};

    const adGroupsList = () => {
        return adGroups?.map((adGroup, idx) =>
            <AdGroupListItem
                key={adGroup.websafe}
                isSelected={adGroup?.websafe === currAdGroupId}
                onClick={() => setCurrAdGroupId(adGroup.websafe)}
            >
                Ad Group {idx + 1}
            </AdGroupListItem>) || [];
    }

    const keywordsList = (type, obj, isModal) => {
        if (!obj) return;
        if (Object.keys(obj)?.length < 1) return;
        const items = [];

        for (let file in obj) {
            const arr = obj[file].map(item => <AdGroupAudienceTargetItem key={item.websafe} type={type}>{item.filename} ({file})</AdGroupAudienceTargetItem>);
            items.push(arr);
        }

        return <AdGroupAudienceTargetContainer isModal={isModal}> {items} </AdGroupAudienceTargetContainer>
    }

    const positiveNegativeKeywords = (isModal) => {
        return isKeywordsEmpty ?
        <AdGroupAudienceTargetWrapper> <ColumnHeader> No Keywords / Negative Keywords </ColumnHeader> </AdGroupAudienceTargetWrapper> :
        <AdGroupAudienceTargetWrapper isModal={isModal}> {keywordsList('positive', keywords, isModal)} {keywordsList('negative', negativeKeywords, isModal)} </AdGroupAudienceTargetWrapper>
    }

    const peopleWhoMatchAndAlsoMatch = () => {
        if (!includedSegments?.length) return <AdGroupAudienceTargetWrapper> <ColumnHeader> No matches </ColumnHeader> </AdGroupAudienceTargetWrapper>
        const result = includedSegments?.map((segment, idx) => {
            const items = segment?.map((item, i) =>
                <React.Fragment key={item?.id}>
                    <AdGroupAudienceTargetItem> {item?.name} </AdGroupAudienceTargetItem>
                    {i !== segment?.length - 1 && <AudienceTargetOr> OR </AudienceTargetOr>}
                </React.Fragment>)
            return (
                <React.Fragment key={idx}>
                    <AdGroupAudienceTargetContainer >
                        {items}
                    </AdGroupAudienceTargetContainer>
                    {idx !== includedSegments?.length - 1 && <AudienceTargetAnd> AND </AudienceTargetAnd>}
                </React.Fragment>
            )
        })

        return <AdGroupAudienceTargetWrapper isModal={isViewMoreModalOpen}> {result} </AdGroupAudienceTargetWrapper>
    }

    const excludePeopleWhoMatch = () => {
        if (!excludedSegments?.length) return <AdGroupAudienceTargetWrapper> <ColumnHeader> No exclusions </ColumnHeader> </AdGroupAudienceTargetWrapper>

        const result = excludedSegments?.map(item => {
            return (
                <AdGroupAudienceTargetItem key={item?.id} type='negative'>
                    {item?.name}
                </AdGroupAudienceTargetItem>
            )
        })
        return <AdGroupAudienceTargetContainer> { result } </AdGroupAudienceTargetContainer>
    }

    const handleEdit = () => {
        if (isEdit) {
            if (name !== campaign.name) {
                updateAdGroup({
                    mediaPlanAdGroup: currAdGroup?.websafe,
                    obj: {name: name},
                    callback: (res) => {
                        toast.success('Updated Ad Group');
                        resetPage();
                    }
                })
            }
        }

        setIsEdit(!isEdit);
    }

    const handleDelete = () => {
        if (adGroups.length === 1) {
            setIsDeleteCampaignConfirmationModalOpen(true);
            return;
        }
        setAdSetLoader(true)

        dispatch(deleteAdGroup({
            mediaPlanAdGroup: currAdGroup?.websafe,
            callback: (res) => {
                setIsDeleteModalOpen(false);
                const filteredAdGroup = adGroups.filter(adGroup => adGroup.websafe !== currAdGroup?.websafe);
                setAdGroups(filteredAdGroup);
                setCurrAdGroupId(filteredAdGroup[0].websafe);
                setAdSetLoader(false)
                toast.success('Deleted Ad Group');
            }
        }))
    }

    const handleDeleteCampaign = () => {
        deleteCampaign({
            mediaPlanCampaign: campaign?.websafe,
            callback: () => {
                setIsDeleteCampaignConfirmationModalOpen(false);
                setIsDeleteModalOpen(false);
                toast.success('Deleted Campaign');
                resetPage('campaign');
            }
        })
    }

    const isKeywordsEmpty = Object.keys(keywords || {})?.length === 0 && Object.keys(negativeKeywords || {})?.length === 0;

    if(adSetLoader) return <AdGroupSetupContainer> <StyleCenterElement><Loader size={50} /></StyleCenterElement> </AdGroupSetupContainer>


    if (!adGroups?.length) {
        return (
            <AdGroupSetupContainer>
                <AdGroupListContainer> {adGroupsList()} </AdGroupListContainer>
                <ColumnHeader> No Ad Groups </ColumnHeader>
            </AdGroupSetupContainer>
        )
    }

    return (
        <AdGroupSetupContainer>
            <AdGroupListContainer> {adGroupsList()} </AdGroupListContainer>

            <PageHeaderContainer>
                <ColumnHeader> Ad Group Details </ColumnHeader>
                <CenterBtnContainer>
                    <RedOutlineIconBtn onClick={() => setIsDeleteModalOpen(true)}> <BiTrash /> Delete Ad Group </RedOutlineIconBtn>
                    {EditSaveBtn(isEdit, handleEdit)}
                </CenterBtnContainer>
            </PageHeaderContainer>

            <ColumnHeader> Flight Details </ColumnHeader>

            <OneThirdGridColContainer>
                <FieldContainer>
                <h3>
                    {googleIcon} Ad Group Name&nbsp; {isEdit &&
                    <BiRefresh
                        color='#F8B133'
                        style={{cursor: 'pointer'}}
                        onClick={() => generateName({mediaPlanCampaign: campaign.websafe, callback: res => setName(res.name)})}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Generate campaign name or manually edit the campaign name"
                    />}
                </h3>
                {isEdit && <Tooltip id='my-tooltip' className='tooltip' classNameArrow='arrow' border={true} effect='solid' />}

                    {
                        isEdit ?
                        <Input value={name} onChange={e => setName(e.target.value)} placeholder={name || 'Enter name'} />
                        :
                        <p> {name || 'N/A'} </p>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>

            <ColumnHeader> Originally Derived from the {mediaPlan?.lob?.name} Line of Business </ColumnHeader>

            <AdGroupAudienceTargetHeader> <span className='green'>Keyword</span> Lists and <span className='red'>Negative Keyword</span> Lists
            <span className='gray'
                onClick={() => {
                    setViewObj({
                        header: 'Keywork Lists and Negative Keyword',
                        text: <AdGroupAudienceTargetHeader> <span className='green'>Keyword</span> Lists and <span className='red'>Negative Keyword</span> Lists </AdGroupAudienceTargetHeader>,
                        data: positiveNegativeKeywords(true)
                    });
                    setIsViewMoreModalOpen(true);
                }}
            > View More </span>
            {isEdit && <FiPlusCircle onClick={() => setIsViewMoreKeywordModalOpen(true)} />}
            </AdGroupAudienceTargetHeader>
            {positiveNegativeKeywords()}

            <AdGroupAudienceTargetHeader> People Who <span className='green'>Match</span> and People Who <span className='green'>Must Also Match</span>
                <span className='gray' onClick={() => {
                    setViewObj({
                        header: 'People Who Match and People Who Must Also Match',
                        text: <AdGroupAudienceTargetHeader> People Who <span className='green'>Match</span> and People Who <span className='green'>Must Also Match</span> </AdGroupAudienceTargetHeader>,
                        data: peopleWhoMatchAndAlsoMatch()
                    });
                    setIsViewMoreModalOpen(true);
                }}> View More </span>
                {isEdit && <FiPlusCircle onClick={() => setIsViewMoreKeywordModalOpen(true)} /> }
            </AdGroupAudienceTargetHeader>
            {peopleWhoMatchAndAlsoMatch()}

            <AdGroupAudienceTargetHeader>
                <span className='red'>Exclude</span> People Who Match
                <span className='gray' onClick={() => {
                    setViewObj({
                        header: 'People Who Match',
                        text: <AdGroupAudienceTargetHeader> <span className='red'>Exclude</span> People Who Match </AdGroupAudienceTargetHeader>,
                        data: excludePeopleWhoMatch()
                    });
                    setIsViewMoreModalOpen(true);
                }}> View More </span>
            </AdGroupAudienceTargetHeader>
            {excludePeopleWhoMatch()}
            {
                isViewMoreModalOpen &&
                <ViewMoreAdGroupModal
                    onClose={() => setIsViewMoreModalOpen(false)}
                    viewObj={viewObj}
                />
            }
            {
                isDeleteModalOpen &&
                <DeleteModal
                    header='Delete Ad Group'
                    modalText='Delete Ad Group?'
                    deleteBtnText='Delete'
                    setIsDeleteModalOpen={() => setIsDeleteModalOpen(false)}
                    handleDelete={handleDelete}
                />
            }
            {
                isDeleteCampaignConfirmationModalOpen &&
                <DeleteModal
                    header='Delete Ad Set'
                    modalText='This is the only ad set avaible for this campaign, deleting this ad set will also delete the Campaign. Are you sure you want to delete this Ad Set?'
                    deleteBtnText='Delete'
                    style={{maxWidth: '600px'}}
                    setIsDeleteModalOpen={() => setIsDeleteCampaignConfirmationModalOpen(false)}
                    handleDelete={handleDeleteCampaign}
                />
            }
            {
                isViewMoreKeywordModalOpen &&
                <EditAdGroupModal
                    header='Delete Ad Group'
                    modalText='Delete Ad Group?'
                    onClose={() => setIsViewMoreKeywordModalOpen(false)}
                    posKeywords={keywords}
                    negKeywords={negativeKeywords}
                    includedSegments={includedSegments}
                    excludedSegments={excludedSegments}
                    currAdGroup={currAdGroup}
                    campaign={campaign}
                />
            }
        </AdGroupSetupContainer>
    )
}

export default GoogleAdGroupSetup;