import React from 'react'
import PercentInput from '../../../../ui/input/fields/PercentInput'
import Information from '../../../../ui/tooltip/Information'

const MobileBidding = ({ FieldContainer, isEdit, mobileBid, setMobileBid }) => {
  return (
    <FieldContainer>
      <h3>
        Mobile Bid Adjustment
        <Information text='This campaign setting field is used as a reference and should be set on the network manager.' />
      </h3>
      {
        isEdit ? <PercentInput value={mobileBid} onChange={e => setMobileBid(e.target.value)} min='-100' max='900'/> : <p>{mobileBid} %</p>
      }
    </FieldContainer>
  )
}

export default MobileBidding