import React, {useEffect, useState} from 'react';
import UploadKeywords from './UploadKeywords';

// Styles
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { RightCaption } from '../styles';
import { AudienceTargetContainer } from '../../../styles';
import { ListItem } from '../../../../../components/Modal/styles';
import { TbCircleMinus } from 'react-icons/tb';

const AddKeywordStep = ({
        description, positiveKeywords, setPositiveKeywords, negativeKeywords, setNegativeKeywords ,isEditingAdGroup, getDefaultMatchType
    }) => {
    const [matchType, setMatchType] = useState('PHRASE');
    const [negativeMatchType, setNegativeMatchType] = useState('PHRASE');

    const typeList = [
        {
            label: "PHRASE",
            value: "PHRASE"
        },
        {
            label: "BROAD",
            value: "BROAD"
        },
        {
            label: "EXACT",
            value: "EXACT"
        }
    ]

    // TODO - CONFIRM IF THIS IS REALLY NEEDED
    // useEffect(() => {
    //     if (isEditingAdGroup) {
    //         const types = ['EXACT', 'BROAD', 'PHRASE'];
    //         let pKey = Object.keys(positiveKeywords || {})?.find(type => types.includes(type))
    //         let nKey = Object.keys(negativeKeywords || {})?.find(type => types.includes(type))
    //         setPositiveKeywords(positiveKeywords);
    //         setNegativeKeywords(negativeKeywords);
    //         setMatchType(pKey);
    //         setNegativeMatchType(nKey);
    //     }
    // }, [positiveKeywords, negativeKeywords])

    const renderSelectedFiles = (selectedFiles, isPositive) => {
        if (!Object.keys(selectedFiles || {})?.length) return;
        const result = [];

        for (const type in selectedFiles) {
            for (const file in selectedFiles[type]) {
                const item = selectedFiles[type][file];
                const { websafe, filename} = item || {};
                result.push(<ListItem key={websafe}> {filename} ({type}) <TbCircleMinus size={18} onClick={() => handleDelete(item, type, isPositive)} /> </ListItem>)
            }
        }

        return result;
    }

    const handleDelete = (file, type, isPositive) => {
        const updatedList = isPositive ? positiveKeywords : negativeKeywords;
        updatedList[type] = updatedList[type].filter(item => item.websafe !== file.websafe);

        // * Remove empty types from list object (ex - if user removes the only phrase csv they upload - we should also remove the key since it's now empty)
        if(updatedList[type].length === 0) delete updatedList[type]
        isPositive ? setPositiveKeywords({...updatedList}) : setNegativeKeywords({...updatedList});
    }

    return (
        <>
            <RightCaption>Audience Description: {description}</RightCaption>
            <ModalTextSecondary> Add Keywords List </ModalTextSecondary>
            <AudienceTargetContainer>
                <div>
                    <UploadKeywords
                        selectedFiles={positiveKeywords}
                        setSelectedFiles={setPositiveKeywords}
                        matchTypeList={typeList}
                        matchType={matchType}
                        setMatchType={setMatchType}
                        isNegative={false}
                        getDefaultMatchType={getDefaultMatchType}
                    />
                    {renderSelectedFiles(positiveKeywords, true)}
                </div>
                <div>
                    <UploadKeywords
                        selectedFiles={negativeKeywords}
                        setSelectedFiles={setNegativeKeywords}
                        matchTypeList={typeList}
                        matchType={negativeMatchType}
                        setMatchType={setNegativeMatchType}
                        isNegative={true}
                        getDefaultMatchType={getDefaultMatchType}
                    />
                    {renderSelectedFiles(negativeKeywords, false)}
                </div>
            </AudienceTargetContainer>
        </>
    )
}

export default AddKeywordStep;