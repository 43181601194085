import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import BrandSafetyModal from './modal/BrandSafetyModal';
import Loader from '../../../ui/Loader';
import CsvTable from './tables/CsvTable';
import EditContext from './Context/EditContext';

import { VIEWABILITY_MEASURES } from '../../../constants/lob';
import { shouldUpdateString } from '../../../utils/payload';

// Styles
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn } from '../../../ui/button';
import { FieldColContainer } from '../styles';
import { FieldContainer, StyledFieldsWrapper } from '../../../ui/containers';
import { Input } from '../../../ui/input/input';
import { selectTheme } from '../../../ui/themes';
import { useSelector } from 'react-redux';

const Activation = () => {
    const [homePageUrl, setHomePageUrl] = useState('');
    const [conversionUrl, setConversionUrl] = useState('');
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const [viewabilityMeasures, setViewabilityMeasures] = useState('');
    const [isBrandSafetyModalOpen, setIsBrandSafetyModalOpen] = useState();

    const lob = useSelector(state => state.lob.currentLineOfBusiness);

    const { isEdit, setIsEdit, handleUpdateLob } = useContext(EditContext);

    useEffect(() => {
        setHomePageUrl(lob?.homePageUrl);
        setConversionUrl(lob?.conversionUrl);
        setCheckoutUrl(lob?.checkoutUrl);
        setViewabilityMeasures(lob?.viewabilityMeasures);
    }, []);

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};

            if (shouldUpdateString(homePageUrl, lob.homePageUrl)) updateObj.homePageUrl = homePageUrl;
            if (shouldUpdateString(conversionUrl, lob.conversionUrl)) updateObj.conversionUrl = conversionUrl;
            if (shouldUpdateString(checkoutUrl, lob.checkoutUrl)) updateObj.checkoutUrl = checkoutUrl;
            if (shouldUpdateString(viewabilityMeasures, lob.viewabilityMeasures)) updateObj.viewabilityMeasures = viewabilityMeasures;

            if (Object.keys(updateObj).length > 0) handleUpdateLob(updateObj);
        }

        setIsEdit(!isEdit);
    }

    const renderViewabilityMeasuresList = () => {
        return VIEWABILITY_MEASURES.map(item => {
            return (
                {
                    label: item,
                    value: item
                }
            )
        })
    }

    if (!lob) return <Loader />;

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                Activation
                {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>

            <FieldColContainer>
                <FieldContainer>
                    <h3> Home Page Url </h3>
                    {
                        isEdit ?
                        <Input
                            placeholder='Enter home page url'
                            value={homePageUrl}
                            onChange={e => setHomePageUrl(e.target.value)}
                        /> :
                        <p>{homePageUrl || 'N/A'} </p>
                    }
                </FieldContainer>
                <FieldContainer>
                    <h3> Conversion Url </h3>
                    {
                        isEdit ?
                        <Input
                            placeholder='Enter conversion url'
                            value={conversionUrl}
                            onChange={e => setConversionUrl(e.target.value)}
                        /> :
                        <p>{conversionUrl || 'N/A'} </p>
                    }
                </FieldContainer>
                <FieldContainer>
                    <h3> Checkout Url </h3>
                    {
                        isEdit ?
                        <Input
                            placeholder='Enter checkout url'
                            value={checkoutUrl}
                            onChange={e => setCheckoutUrl(e.target.value)}
                        /> :
                        <p>{checkoutUrl || 'N/A'} </p>
                    }
                </FieldContainer>
            </FieldColContainer>

            <FieldColContainer>
                <FieldContainer>
                    <h3> Viewability Measures </h3>
                    {
                        isEdit ?
                        <Select
                            isClearable
                            options={renderViewabilityMeasuresList()}
                            defaultValue={{label: lob?.viewabilityMeasures, value: lob?.viewabilityMeasures}}
                            styles={selectTheme}
                            onChange={e => setViewabilityMeasures(e.label)}
                        /> :
                        <p> {lob?.viewabilityMeasures || 'N/A'} </p>
                    }
                </FieldContainer>
                <FieldContainer>
                <CsvTable
                    header='Brand Safety Measures'
                    isEdit={isEdit}
                    data={lob?.brandSafetyMeasures}
                    setIsModalOpen={() => setIsBrandSafetyModalOpen(true)}
                />
                </FieldContainer>
            </FieldColContainer>
            {
                isBrandSafetyModalOpen &&
                <BrandSafetyModal
                    onClose={() => setIsBrandSafetyModalOpen(false)}
                    brandSafetyMeasures={lob?.brandSafetyMeasures}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default Activation;