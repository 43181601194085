import React, { useContext } from 'react'
import { TableModalContainer } from '../../../../../ui/table/styles'
import { ModalTextSecondary } from '../../../../../components/Modal/styles'
import Select from 'react-select'
import { selectThemeModal } from '../../../../../ui/themes'
import { ChannelSetUpContext } from './ChannelSetupModal'

const AdFormatStep = () => {
    const { handleAddAdFormat, selectedAdFormats, adFormatOptions } = useContext(ChannelSetUpContext)

    return (
        <TableModalContainer>
            <ModalTextSecondary>Select an ad format that will perform best with your campaign objective</ModalTextSecondary>
            <ModalTextSecondary> Select Ad Format <span className='asterisk'>*</span></ModalTextSecondary>
            <Select
                isMulti
                isClearable
                styles={selectThemeModal}
                placeholder={'Select Ad Format(s)'}
                options={adFormatOptions}
                onChange={(e) => handleAddAdFormat(e)}
                defaultValue={selectedAdFormats}

            />
        </TableModalContainer>
    )
}

export default AdFormatStep