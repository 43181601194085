import React from 'react'
import Select from 'react-select';
import { selectTheme } from '../../../../ui/themes';
const BiddingType = ({ FieldContainer, isEdit, focusTypeOptions, focusType, setFocusType, defaultValue, resetBiddingData }) => {

    const handleTypeChange = (val) => {
        setFocusType(val)
        resetBiddingData(val)
    }

    return (
        <FieldContainer>
            <h3> Bidding Focus Type</h3>
            {
                isEdit ?
                    <Select
                        styles={selectTheme}
                        options={focusTypeOptions}
                        onChange={e => handleTypeChange(e.label)}
                        defaultValue={{
                            label: defaultValue,
                            value: defaultValue
                        }}
                        isClearable
                    /> : <p>{focusType || 'N/A'}</p>
            }
        </FieldContainer>
    )
}

export default BiddingType