import React from 'react';
import Modal from '../../../../components/Modal/Modal';

// Styles
import { ModalTextSecondary } from '../../../../components/Modal/styles';
import { RedBtn, OutlineBtn, CenterBtnOutterContainer, CenterBtnContainer, BtnWithLoader } from '../../../../ui/button';
import { TableModalContainer } from '../../../../ui/table/styles';

const DeleteTargetingModal = ({onClose, handleDelete, title, item, useLoader, loading}) => {
    return (
        <Modal
            header={`Delete ${title}`}
            onClose={onClose}
        >
            <TableModalContainer>
                <ModalTextSecondary> Are you sure you want to delete {typeof item === 'string' ? item : item?.name || item?.displayName} </ModalTextSecondary>

                <CenterBtnOutterContainer>
                    <CenterBtnContainer>
                        {
                            useLoader ? <BtnWithLoader btnText={`Delete ${title}`} loader={loading} onClick={handleDelete} type="redBtn" color='white'/> : <RedBtn onClick={handleDelete} >
                                Delete {title}
                            </RedBtn>
                        }
                    <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                    </CenterBtnContainer>
                </CenterBtnOutterContainer>
            </TableModalContainer>
        </Modal>
    )
}

export default DeleteTargetingModal;