export const LINKEDIN_CATEGORIES = {
    JOB_EXPERIENCE: 'Job Experience',
    INTERESTS_TRAITS: 'Interests and Traits',
    EDUCATION: 'Education',
    DEMOGRAPHICS: 'Demographics',
    COMPANY: 'Company',
    LANGUAGE: 'Language'
}

export const linkedinCategoryOptions = Object.values(LINKEDIN_CATEGORIES).map(val => {
    return { value: val, label: val }
})

export const LINKEDIN_SUBCATEGORIES = {
    JOB_FUNCTION: 'Job Functions',
    JOB_SENIORITIES: 'Job Seniorities',
    MEMBER_SKILLS: 'Member Skills',
    JOB_TITLES: 'Job Titles',
    YEARS_EXP: 'Years of Experience',
    MEMBER_GROUPS: 'Member Groups',
    MEMBER_INTERESTS: 'Member Interests',
    MEMBER_TRAITS: 'Member Traits',
    DEGREES: 'Degrees',
    FIELD_STUDY: 'Fields of Study',
    MEMBER_SCHOOLS: 'Member Schools',
    MEMBER_AGE: 'Member Age',
    MEMBER_GENDER: 'Member Gender',
    COMPANY_CATEGORY: 'Company Category',
    COMPANY_NAMES: 'Company Names',
    COMPANY_CONNECTIONS: 'Company Connections',
    COMPANY_FOLLOWER: 'Company Follower Of',
    COMPANY_GROWTH: 'Company Growth Rate',
    COMPANY_INDUSTRIES: 'Company Industries',
    COMPANY_REVENUE: 'Company Revenue',
    COMPANY_SIZE: 'Company Size',
    LANGUAGE: 'Language'
}

export const LINKEDIN_DESC = {
    JOB_FUNCTION: 'Reach LinkedIn members based on common tasks or activities undertaken within their job position.',
    JOB_SENIORITIES: 'Reach LinkedIn members with a rank or level of influence they hold based on a LinkedIn member’s current role(s) in their organization.',
    MEMBER_SKILLS: 'Reach LinkedIn members with expertise in a particular area based on the Skills & Endorsements section on their LinkedIn profile, skills mentioned in their LinkedIn profile text, or inferred skills based on their LinkedIn profile.',
    JOB_TITLES: 'Reach LinkedIn members based on their position within their organization.',
    YEARS_EXP: 'Reach LinkedIn members based on the years of professional experience they’ve accumulated over their career. When calculating years of experience gaps in experience are not counted and we do not double-count overlapping positions.',
    MEMBER_GROUPS: 'Reach LinkedIn members who share interests or professional associations by belonging to the same LinkedIn Group.',
    MEMBER_INTERESTS: 'Reach LinkedIn members based on interests that align with your business.',
    MEMBER_TRAITS: 'Reach LinkedIn members based on traits like if they are a frequent traveler, desktop user or open to education.',
    DEGREES: 'Reach LinkedIn members with a degree type recognized by a college, university, or other learning institution.',
    FIELD_STUDY: 'Reach LinkedIn members within a major area of study.',
    MEMBER_SCHOOLS: 'Reach LinkedIn members who completed a course at a specific school, college, university or other learning institution.',
    MEMBER_AGE: 'An estimation of how old a LinkedIn member is based on their LinkedIn profile information.',
    MEMBER_GENDER: 'Determination of whether a LinkedIn member is female or male is inferred based on LinkedIn member profile information.',
    COMPANY_CATEGORY: 'Reach LinkedIn members based on the category their company falls into. Company categories are defined based on publications and industry lists that group companies into specific market segments.',
    COMPANY_NAMES: 'Reach LinkedIn members based on the organization(s) a LinkedIn member has listed as their employer(s).',
    COMPANY_CONNECTIONS: 'Reach the 1st-degree LinkedIn connections of employees at the companies you select. Companies will be available if they have over 500 employees.',
    COMPANY_FOLLOWER: 'Reach followers of your LinkedIn Company Page.',
    COMPANY_GROWTH: 'Reach LinkedIn members based on the year over year employee growth rate associated with their company. Growth rate for a company is determined by comparing recent total employee count vs. one year prior.',
    COMPANY_INDUSTRIES: 'Reach LinkedIn members within certain industries by using the primary industry of the company the member is employed.',
    COMPANY_REVENUE: 'Reach LinkedIn members based on their company’s earned revenue (in USD) in the previous year.',
    COMPANY_SIZE: 'Reach LinkedIn members based on the size of the organization they work for. Company size is determined by the number of employees listed on the organization’s LinkedIn Company Page.',
    LANGUAGE: 'Language'
}

export const LINKEDIN_FACET_NAMES = {
    JOB_FUNCTION: 'jobFunctions',
    JOB_SENIORITIES: 'seniorities',
    MEMBER_SKILLS: 'skills',
    JOB_TITLES: 'titles',
    YEARS_EXP: 'yearsOfExperienceRanges',
    MEMBER_GROUPS: 'groups',
    MEMBER_INTERESTS: 'interests',
    MEMBER_TRAITS: 'memberBehaviors',
    DEGREES: 'degrees',
    FIELD_STUDY: 'fieldsOfStudy',
    MEMBER_SCHOOLS: 'schools',
    MEMBER_AGE: 'ageRanges',
    MEMBER_GENDER: 'genders',
    COMPANY_CATEGORY: 'companyCategory',
    COMPANY_NAMES: 'employers',
    COMPANY_CONNECTIONS: 'firstDegreeConnectionCompanies',
    COMPANY_FOLLOWER: 'followedCompanies',
    COMPANY_GROWTH: 'growthRate',
    COMPANY_INDUSTRIES: 'industries',
    COMPANY_REVENUE: 'revenue',
    COMPANY_SIZE: 'staffCountRanges',
    LANGUAGE: 'interfaceLocales'
}

export const LINKEDIN_FACET_URNS = {
    JOB_FUNCTION: 'urn:li:adTargetingFacet:jobFunctions',
    JOB_SENIORITIES: 'urn:li:adTargetingFacet:seniorities',
    MEMBER_SKILLS: 'urn:li:adTargetingFacet:skills',
    JOB_TITLES: 'urn:li:adTargetingFacet:titles',
    YEARS_EXP: 'urn:li:adTargetingFacet:yearsOfExperienceRanges',
    MEMBER_GROUPS: 'urn:li:adTargetingFacet:groups',
    MEMBER_INTERESTS: 'urn:li:adTargetingFacet:interests',
    MEMBER_TRAITS: 'urn:li:adTargetingFacet:memberBehaviors',
    DEGREES: 'urn:li:adTargetingFacet:degrees',
    FIELD_STUDY: 'urn:li:adTargetingFacet:fieldsOfStudy',
    MEMBER_SCHOOLS: 'urn:li:adTargetingFacet:schools',
    MEMBER_AGE: 'urn:li:adTargetingFacet:ageRanges',
    MEMBER_GENDER: 'urn:li:adTargetingFacet:genders',
    COMPANY_CATEGORY: 'urn:li:adTargetingFacet:companyCategory',
    COMPANY_NAMES: 'urn:li:adTargetingFacet:employers',
    COMPANY_CONNECTIONS: 'urn:li:adTargetingFacet:firstDegreeConnectionCompanies',
    COMPANY_FOLLOWER: 'urn:li:adTargetingFacet:followedCompanies',
    COMPANY_GROWTH: 'urn:li:adTargetingFacet:growthRate',
    COMPANY_INDUSTRIES: 'urn:li:adTargetingFacet:industries',
    COMPANY_REVENUE: 'urn:li:adTargetingFacet:revenue',
    COMPANY_SIZE: 'urn:li:adTargetingFacet:staffCountRanges',
    LANGUAGE: 'urn:li:adTargetingFacet:interfaceLocales'
}

export const ENTITY_FINDERS = {
    AD_TARGETING_FACET: 'AD_TARGETING_FACET',
    TYPEAHEAD: 'TYPEAHEAD',
    SIMILAR_ENTITIES :'SIMILAR_ENTITIES'
}

export const disclaimerArray = [LINKEDIN_SUBCATEGORIES.MEMBER_AGE, LINKEDIN_SUBCATEGORIES.MEMBER_GENDER]

export const getFacetCategory = (urn) => {
    switch (urn) {
        case LINKEDIN_FACET_URNS.JOB_FUNCTION:
        case LINKEDIN_FACET_URNS.JOB_SENIORITIES:
        case LINKEDIN_FACET_URNS.MEMBER_SKILLS:
        case LINKEDIN_FACET_URNS.JOB_TITLES:
        case LINKEDIN_FACET_URNS.YEARS_EXP:
            return LINKEDIN_CATEGORIES.JOB_EXPERIENCE
        case LINKEDIN_FACET_URNS.MEMBER_GROUPS:
        case LINKEDIN_FACET_URNS.MEMBER_INTERESTS:
        case LINKEDIN_FACET_URNS.MEMBER_TRAITS:
            return LINKEDIN_CATEGORIES.INTERESTS_TRAITS
        case LINKEDIN_FACET_URNS.DEGREES:
        case LINKEDIN_FACET_URNS.FIELD_STUDY:
        case LINKEDIN_FACET_URNS.MEMBER_SCHOOLS:
            return LINKEDIN_CATEGORIES.EDUCATION;
        case LINKEDIN_FACET_URNS.MEMBER_AGE:
        case LINKEDIN_FACET_URNS.MEMBER_GENDER:
            return LINKEDIN_CATEGORIES.DEMOGRAPHICS;
        case LINKEDIN_FACET_URNS.COMPANY_CATEGORY:
        case LINKEDIN_FACET_URNS.COMPANY_NAMES:
        case LINKEDIN_FACET_URNS.COMPANY_CONNECTIONS:
        case LINKEDIN_FACET_URNS.COMPANY_FOLLOWER:
        case LINKEDIN_FACET_URNS.COMPANY_GROWTH:
        case LINKEDIN_FACET_URNS.COMPANY_INDUSTRIES:
        case LINKEDIN_FACET_URNS.COMPANY_REVENUE:
        case LINKEDIN_FACET_URNS.COMPANY_SIZE:
            return LINKEDIN_CATEGORIES.COMPANY;
        case LINKEDIN_FACET_URNS.LANGUAGE:
            return LINKEDIN_CATEGORIES.LANGUAGE
        default:
            return 'Misc'
    }
}

export const getFacetSubCategory = (urn) => {
    switch (urn) {
        case LINKEDIN_FACET_URNS.JOB_FUNCTION:
            return LINKEDIN_SUBCATEGORIES.JOB_FUNCTION
        case LINKEDIN_FACET_URNS.JOB_SENIORITIES:
            return LINKEDIN_SUBCATEGORIES.JOB_SENIORITIES
        case LINKEDIN_FACET_URNS.MEMBER_SKILLS:
            return LINKEDIN_SUBCATEGORIES.MEMBER_SKILLS
        case LINKEDIN_FACET_URNS.JOB_TITLES:
            return LINKEDIN_SUBCATEGORIES.JOB_TITLES
        case LINKEDIN_FACET_URNS.YEARS_EXP:
            return LINKEDIN_SUBCATEGORIES.YEARS_EXP
        case LINKEDIN_FACET_URNS.MEMBER_GROUPS:
            return LINKEDIN_SUBCATEGORIES.MEMBER_GROUPS
        case LINKEDIN_FACET_URNS.MEMBER_INTERESTS:
            return LINKEDIN_SUBCATEGORIES.MEMBER_INTERESTS
        case LINKEDIN_FACET_URNS.MEMBER_TRAITS:
            return LINKEDIN_SUBCATEGORIES.MEMBER_TRAITS
        case LINKEDIN_FACET_URNS.DEGREES:
            return LINKEDIN_SUBCATEGORIES.DEGREES
        case LINKEDIN_FACET_URNS.FIELD_STUDY:
            return LINKEDIN_SUBCATEGORIES.FIELD_STUDY
        case LINKEDIN_FACET_URNS.MEMBER_SCHOOLS:
            return LINKEDIN_SUBCATEGORIES.MEMBER_SCHOOLS;
        case LINKEDIN_FACET_URNS.MEMBER_AGE:
            return LINKEDIN_SUBCATEGORIES.MEMBER_AGE;
        case LINKEDIN_FACET_URNS.MEMBER_GENDER:
            return LINKEDIN_SUBCATEGORIES.MEMBER_GENDER;
        case LINKEDIN_FACET_URNS.COMPANY_CATEGORY:
            return LINKEDIN_SUBCATEGORIES.COMPANY_CATEGORY;
        case LINKEDIN_FACET_URNS.COMPANY_NAMES:
            return LINKEDIN_SUBCATEGORIES.COMPANY_NAMES;
        case LINKEDIN_FACET_URNS.COMPANY_CONNECTIONS:
            return LINKEDIN_SUBCATEGORIES.COMPANY_CONNECTIONS;
        case LINKEDIN_FACET_URNS.COMPANY_FOLLOWER:
            return LINKEDIN_SUBCATEGORIES.COMPANY_FOLLOWER;
        case LINKEDIN_FACET_URNS.COMPANY_GROWTH:
            return LINKEDIN_SUBCATEGORIES.COMPANY_GROWTH;
        case LINKEDIN_FACET_URNS.COMPANY_INDUSTRIES:
            return LINKEDIN_SUBCATEGORIES.COMPANY_INDUSTRIES;
        case LINKEDIN_FACET_URNS.COMPANY_REVENUE:
            return LINKEDIN_SUBCATEGORIES.COMPANY_REVENUE;
        case LINKEDIN_FACET_URNS.COMPANY_SIZE:
            return LINKEDIN_SUBCATEGORIES.COMPANY_SIZE;
        case LINKEDIN_FACET_URNS.LANGUAGE:
            return LINKEDIN_SUBCATEGORIES.LANGUAGE
        default:
            return 'Misc'
    }
}

export const getSubcategoryDesc = (urn) => {
    switch (urn) {
        case LINKEDIN_FACET_URNS.JOB_FUNCTION:
            return LINKEDIN_DESC.JOB_FUNCTION
        case LINKEDIN_FACET_URNS.JOB_SENIORITIES:
            return LINKEDIN_DESC.JOB_SENIORITIES
        case LINKEDIN_FACET_URNS.MEMBER_SKILLS:
            return LINKEDIN_DESC.MEMBER_SCHOOLS
        case LINKEDIN_FACET_URNS.JOB_TITLES:
            return LINKEDIN_DESC.JOB_TITLES
        case LINKEDIN_FACET_URNS.YEARS_EXP:
            return LINKEDIN_DESC.YEARS_EXP
        case LINKEDIN_FACET_URNS.MEMBER_GROUPS:
            return LINKEDIN_DESC.MEMBER_GROUPS
        case LINKEDIN_FACET_URNS.MEMBER_INTERESTS:
            return LINKEDIN_DESC.MEMBER_INTERESTS
        case LINKEDIN_FACET_URNS.MEMBER_TRAITS:
            return LINKEDIN_DESC.MEMBER_TRAITS
        case LINKEDIN_FACET_URNS.DEGREES:
            return LINKEDIN_DESC.DEGREES
        case LINKEDIN_FACET_URNS.FIELD_STUDY:
            return LINKEDIN_DESC.FIELD_STUDY
        case LINKEDIN_FACET_URNS.MEMBER_SCHOOLS:
            return LINKEDIN_DESC.MEMBER_SCHOOLS;
        case LINKEDIN_FACET_URNS.MEMBER_AGE:
            return LINKEDIN_DESC.MEMBER_AGE;
        case LINKEDIN_FACET_URNS.MEMBER_GENDER:
            return LINKEDIN_DESC.MEMBER_GENDER;
        case LINKEDIN_FACET_URNS.COMPANY_CATEGORY:
            return LINKEDIN_DESC.COMPANY_CATEGORY;
        case LINKEDIN_FACET_URNS.COMPANY_NAMES:
            return LINKEDIN_DESC.COMPANY_NAMES;
        case LINKEDIN_FACET_URNS.COMPANY_CONNECTIONS:
            return LINKEDIN_DESC.COMPANY_CONNECTIONS;
        case LINKEDIN_FACET_URNS.COMPANY_FOLLOWER:
            return LINKEDIN_DESC.COMPANY_FOLLOWER;
        case LINKEDIN_FACET_URNS.COMPANY_GROWTH:
            return LINKEDIN_DESC.COMPANY_GROWTH;
        case LINKEDIN_FACET_URNS.COMPANY_INDUSTRIES:
            return LINKEDIN_DESC.COMPANY_INDUSTRIES;
        case LINKEDIN_FACET_URNS.COMPANY_REVENUE:
            return LINKEDIN_DESC.COMPANY_REVENUE;
        case LINKEDIN_FACET_URNS.COMPANY_SIZE:
            return LINKEDIN_DESC.COMPANY_SIZE;
        case LINKEDIN_FACET_URNS.LANGUAGE:
            return LINKEDIN_DESC.LANGUAGE
        default:
            return 'Misc'
    }
}

export const groupFacetsByCategory = (linkedInTargets) => {
    const groupedFacets = {}

    linkedInTargets.forEach((target) => {
        const propValue = target['facetUrn'];

        if (!groupedFacets[propValue]) groupedFacets[propValue] = []
        groupedFacets[propValue].push(target)
    })

    const facetArray = [];
    for (const facetUrn in groupedFacets) {
        if (groupedFacets?.hasOwnProperty(facetUrn)) {
            const groupedObject = {
                name: getFacetSubCategory(facetUrn),
                targets: groupedFacets[facetUrn]
            }

            facetArray.push(groupedObject)
        }
    }

    return facetArray.sort((a, b) => (a.name > b.name) ? 1 : -1 )
}