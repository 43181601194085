import React from 'react';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { Input } from '../../../../../ui/input/input';

const Beneficiary = ({ isEdit, euBeneficiary, setEuBeneficiary }) => {
    return (
        <FieldContainer>
            <h3> EU Beneficiary </h3>
            {
                isEdit ?
                <Input value={euBeneficiary} placeholder='Enter EU Beneficiary' onChange={e => setEuBeneficiary(e.target.value)} /> :
                <p>{euBeneficiary || 'N/A'}</p>
            }
        </FieldContainer>
    )
}

export default Beneficiary;