import React from 'react';

// Styles
import { FieldContainer } from '../../../../ui/containers';
import { Input } from '../../../../ui/input/input';

const PixelId = ({isEdit, currAdGroup}) => {
    return (
        <FieldContainer>
            <h3> Pixel ID </h3>
            {
                isEdit ?
                <Input defaultValue={currAdGroup?.adPixel?.id} disabled={true} /> :
                <p> {currAdGroup?.adPixel?.id || 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default PixelId;