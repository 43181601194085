import Select from 'react-select';
import { selectTheme } from '../../../../ui/themes';
export const ObjectiveField = ({objective, setObjective, defaultValue, objectiveOptions, isEdit, FieldContainer, RequiredStar }) => {
    return (
        <FieldContainer>
            <h3> Objective <RequiredStar /></h3>
            { isEdit ?
            <>
                <Select
                    isClearable
                    options={objectiveOptions}
                    defaultValue={{
                        label: defaultValue,
                        value: defaultValue
                    }}
                    styles={selectTheme}
                    onChange={e => setObjective(e.label)}
                />
                { !objective && <p className='error'>Please select an Objective</p>}
            </> :
            <p> {objective || 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default ObjectiveField;

