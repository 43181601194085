import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import AsyncMultiSelect from '../../../../../ui/AsyncMultiSelector';

import { generateDropdownOptions } from '../../../../../utils/arrays';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { selectTheme } from '../../../../../ui/themes';

const LobLanguage = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { languageTarget } = campaignObj;

    const [languageOptions, setLanguageOptions] = useState();

    useEffect(() => {
        window.eulerity.makeApiCall('/api/linkedin/queryLanguages', 'GET', null, response => {
            const languageOptions = response.map(option => {
                return { label: option.name, value: option }
            });

            setLanguageOptions(languageOptions);
        })
    }, []);

    return (
        <FieldContainer>
            <h3> Language <span className='asterisk'> * </span></h3>
            {isEdit ?
            <Select
                isClearable
                options={languageOptions}
                styles={selectTheme}
                value={{label: languageTarget?.name, value: languageTarget}}
                onChange={e => handleUpdate({key: 'languageTarget', value: e.value})}
                placeholder='Select a Language'
            /> :
            <p> {languageTarget?.name || 'N/A'} </p>
        }
        </FieldContainer>
    )
}

export default LobLanguage;