import React from "react";
//* not sure if we are going to need the delete btn
//import { DeleteIconBtn } from "../../../../../ui/button";
import StatusCircle from "../../../../../ui/status";
import { StyleSystemLoginAccounts } from "../styles";

const CurrentSystemAccount = ({currentSysAccount}) => {
  const {displayName, valid} = currentSysAccount

  return (
    <StyleSystemLoginAccounts>
      <div className="sys_acc_options sys_opt_1">
        <p>Name</p>
        <p>Status</p>

      </div>
      <div className="sys_acc_options sys_opt_2">
        <p>{displayName}</p>
         <div className="sys_acc_options-actions">
            <StatusCircle status={valid}/>
            {/* <DeleteIconBtn onClick={handleDelete} /> */}
         </div>
      </div>
    </StyleSystemLoginAccounts>
  );
};

export default CurrentSystemAccount;
