import { GET_LOB_LIST, CREATE_LOB, DELETE_LOB, SELECT_LOB, UPDATE_LOB, CLONE_LOB, RESET_LOB, SELECT_NETWORK_CONFIG, UPDATE_NETWORK_CONFIG } from '../actions/types';

const initialState = {
    lineOfBusinessList: [],
    currentLineOfBusiness: {},
    currentNetworkConfig: {},
}

const lobReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOB_LIST: {
            return { ...state, lineOfBusinessList: action.payload }
        }
        case CREATE_LOB: {
            return {
                ...state,
                lineOfBusinessList: [...state.lineOfBusinessList, action.payload]
            }
        }
        case DELETE_LOB: {
            const filteredList = state.lineOfBusinessList.filter(lob => lob.websafe !== action.payload);

            return {
                ...state,
                lineOfBusinessList: [...filteredList]
            }
        }
        case SELECT_LOB: {
            return {
                ...state,
                currentLineOfBusiness: action.payload
            }
        }
        case RESET_LOB: {
            return {
                ...state,
                currentLineOfBusiness: action.payload
            }
        }
        case UPDATE_LOB: {
            const updatedLineOfBusinessList = state.lineOfBusinessList.map(lob => {
                if (lob.websafe === action.payload.websafe) {
                    return action.payload;
                }
                return lob;
            })

            return {
                ...state,
                lineOfBusinessList: updatedLineOfBusinessList,
                currentLineOfBusiness: action.payload
            }
        }
        case CLONE_LOB: {
            return {
                ...state,
                lineOfBusinessList: [...state.lineOfBusinessList, action.payload]
            }
        }
        case SELECT_NETWORK_CONFIG:
        case UPDATE_NETWORK_CONFIG: {
            return {
                ...state,
                currentNetworkConfig: action.payload
            }
        }
        default: return state;
    }
}

export default lobReducer;