import styled, { css } from "styled-components";
import { FiSave } from "react-icons/fi";
import { BiTrash, BiPencil } from "react-icons/bi";
import { FaFacebook } from 'react-icons/fa';
import Loader from "./Loader";

export const Button = styled.button`
  all: unset;
  text-align: center;
  width: 104px;
  height: 33px;
  font-family: "GothamMedium";
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  svg {
    flex-shrink: 0;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3 !important;
      pointer-events: none !important;
    `}

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}

    @media only screen and (min-width: 768px) {
    width: 122px;
    height: 37px;
    font-size: 14px;
  }
`;

export const OutlineBtn = styled(Button)`
  border: 1px solid ${(props) => props.theme.color.black};
  :hover {
    background-color: ${(props) => props.theme.hover.gray};
  }
`;

export const YellowBtn = styled(Button)`
  background-color: ${(props) => props.theme.color.yellow};

  :hover {
    background-color: ${(props) => props.theme.hover.yellow};
  }
`;

export const BlackBtn = styled(Button)`
  color: ${(props) => props.theme.background.white};
  background-color: ${(props) => props.theme.color.black};
  min-width: fit-content;
  padding: 0 10px;

  :hover {
    background-color: ${(props) => props.theme.hover.black};
  }
`;


const BtnLg = css`
    width: fit-content !important;


  a {
    color: white !important;
  }
`;

export const BlackBtnLg = styled(BlackBtn)`
    ${BtnLg}
`;

export const OutlineBtnLg = styled(OutlineBtn)`
    ${BtnLg}
`;

export const RedBtn = styled(Button)`
  color: ${(props) => props.theme.background.white};
  background-color: ${(props) => props.theme.color.red};
  width: fit-content;
  padding: 0 10px;

  :hover {
    background-color: ${(props) => props.theme.hover.red};
  }
`;

// Buttons with icon
const IconResolution = css`
  width: 132px;
  height: 40px;
  justify-content: space-around;
  box-sizing: border-box;
  gap: 15px;

  svg {
    font-size: 16px;
    stroke-width: 0.6;
  }

  @media only screen and (min-width: 768px) {
    width: 150px;
    height: 42px;
    padding: 14px;

    svg {
      font-size: 18px;
      stroke-width: 0.9;
    }
  }

  @media only screen and (min-width: 992px) {
    width: 178px;
    height: 46px;
  }
`;

export const IconBtn = styled(Button)`
  ${IconResolution}
`;

export const OutlineIconBtn = styled(OutlineBtn)`
  ${IconResolution}
`;

export const YellowIconBtn = styled(YellowBtn)`
  ${IconResolution}
`;

export const BlackIconBtn = styled(BlackBtn)`
  ${IconResolution}
  width: fit-content !important;
`;

export const RedIconBtn = styled(RedBtn)`
  ${IconResolution}
`;

export const RedOutlineIconBtn = styled(OutlineIconBtn)`
  font-size: 14px !important;
  padding: 0 10px !important;
  height: 38px;
  width: fit-content;
  gap: 8px;
  color: ${(props) => props.theme.color.red};
  border: 1px solid ${(props) => props.theme.color.red};

  :hover {
    background-color: ${(props) => props.theme.hover.red};
    border: 1px solid white;
    color: white;
  }

  svg {
    font-size: 18px;
    stroke-width: 0.5;
  }
`;

// Icon only button
export const ImgBtnContainer = styled.div`
  width: 50px;
  height: 35px;
  border: 1px solid ${(props) => props.theme.color.black};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${(props) => props.theme.color.black};
  cursor: pointer;
`;

export const RedImgBtnContainer = styled(ImgBtnContainer)`
  color: ${(props) => props.theme.color.red};
  border: 1px solid ${(props) => props.theme.color.red};
`;

export const RedTextBtnContainer = styled(RedImgBtnContainer)`
  width: fit-content;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  height: 38px;

  svg {
    margin-right: 4px;
    font-size: 18px;
  }

  &:hover {
    background-color: ${props => props.theme.hover.disabledGray};
  }
`

export const SaveEditBtnContainer = styled(ImgBtnContainer)`
  width: 100px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  color: ${props => props.isEdit ? props.theme.color.green : props.theme.color.black};
  border-color: ${props => props.isEdit ? props.theme.color.green : props.theme.color.black};
  padding: 0 10px;
  height: 38px;
  gap: 8px;

  &:hover {
    background-color: ${props => props.theme.hover.disabledGray};
  }

  svg {
    font-size: 18px;
  }
`;


export const EditSaveBtn = (isEdit, onClick) => {
  return <SaveEditBtnContainer onClick={onClick} isEdit={isEdit}>{isEdit ? <FiSave /> : <BiPencil /> } { isEdit ? 'Save' : 'Edit' }</SaveEditBtnContainer>
};

export const DeleteIconBtn = ({ onClick }) => {
  return (
    <RedImgBtnContainer onClick={onClick}>
      <BiTrash />
    </RedImgBtnContainer>
  );
};

export const DeleteTextBtn = ({ onClick, btnText }) => {
  return (
    <RedTextBtnContainer onClick={onClick}>
      <BiTrash />
      {btnText}
    </RedTextBtnContainer>
  )
}

export const IconDropdownButton = styled(Button)`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "GothamMedium";
  font-size: 12px;
  font-weight: 500;
  height: 25px;
  line-height: 0;

  img {
    width: 18px;
    height: 18px;
  }
`;

// Containers
export const HeaderIconBtnContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CenterBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 10px;

  button {
    margin: auto;
  }
`;

export const StyleCenterElement = styled.div`
  width: 100%;
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CenterBtnOutterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const ConnectNetwrokBtn = styled.div`
  width: 221px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;

  ${(props) => {
    switch (props.network) {
      case "Facebook":
        return css`
          background-color: ${props.theme.networks.facebook};
          color: ${props.theme.color.white};
        `;
      case "Google":
        return css`
          background-color: ${props.theme.networks.google};
          color: ${props.theme.color.black};
          border: 1px solid ${props.theme.color.gray};
        `;
      default:
        return "";
    }
  }}
`;

export const CenterButtons = (components) => {
  return (
    <CenterBtnOutterContainer>
      <CenterBtnContainer>{components}</CenterBtnContainer>
    </CenterBtnOutterContainer>
  );
};

export const CenterButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: ${props => props.top};

  button {
    margin: 0;
  }
`;

const StyleRedBtnWithLoader = styled.div`
    position: relative;
    .red-btn-loader {
        position: absolute;
        top: 0;
        height: 0;
        display: ${props => props.loader ? 'block' : 'none'};
        width: 100%;
        height: 100%;
    }
    .btnText {
      opacity: ${props => !props.loader ? 1 : 0};
    }

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 0.3 !important;
        pointer-events: none !important;
    `}
`;

//* there is a better aproach for th
export const BtnWithLoader = ({onClick, loader, btnText, type, color, isDisabled}) => {
  let BtnComponent;
  switch(type) {
    case 'redBtn':  {
      BtnComponent =  RedBtn
      break;
    }
    case 'outlineBtn': {
      BtnComponent = OutlineBtn
      break
    }
    case 'blackBtn': {
      BtnComponent = BlackBtn
      break
    }
    default: {
      BtnComponent = OutlineBtn
      break
    }
  }

  return (
    <BtnComponent onClick={onClick} isDisabled={isDisabled}>
      <StyleRedBtnWithLoader loader={loader}>
        <div className='btnText'>
          {btnText}
        </div>
        <div className="red-btn-loader">
            <Loader color={color}/>
        </div>
      </StyleRedBtnWithLoader>
    </BtnComponent>
  );
};

export const FacebookBtnStyle = styled(IconBtn)`
  background-color: #1877F2;
  color: white;
`;

// Social Buttons
export const FacebookButton = ({text, onClick}) => {
  return (
    <FacebookBtnStyle onClick={onClick}>
      <FaFacebook />
      {text}
    </FacebookBtnStyle>
  )
}

export const LinkedBtnStyle = styled(IconBtn)`
  background-color: #0073B1;
  color: white;
`

export const LinkedInButton = ({text, onClick, icon}) => {
  return (
    <LinkedBtnStyle onClick={onClick}>
      <img src={icon} alt='Linkedin Logo'/>
      {text}
    </LinkedBtnStyle>
  )
}

export const GoogleBtnStyle = styled(IconBtn)`
  background-color: #fff;
  color: #1f1f1f;
  border-radius: 20px !important;
  border: 1px solid #747775;
  padding: 10px 12px !important;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;

  img {
    height: 30px;
    width: 30px;
  }
`

export const GoogleButton = ({text, onClick, icon, loader}) => {
	return (
		<GoogleBtnStyle onClick={onClick}>
			<img src={icon} alt='Google Logo'/>
			{loader ? <Loader /> : <span> {text} </span>}
		</GoogleBtnStyle>
	)
}