import React from 'react';
import AsyncMultiSelect from '../../../../ui/AsyncMultiSelector';

// Styles
import { FieldContainer } from '../../../../ui/containers';

const LanguageTargeting = ({isEdit, languageTargets, setLanguageTargets, socialLoginId}) => {
    const handleChange = (e) => {
        const list = e?.map(language => language.value);
        setLanguageTargets(list);
    }

    return (
        <FieldContainer>
            <h3> Language(s) </h3>
            {isEdit ?
            <AsyncMultiSelect
                query={`/api/facebook/queryLanguages?socialLoginId=${socialLoginId}`}
                localState={handleChange}
                defaultOptions={languageTargets.map(language => {
                    return ({label: language?.name, value: language})
                })}
                isMultiQuery={true}
                filterOptions={op => {
                    return !languageTargets.find(target => target.name === op.value.name)
                }}
            /> :
            <p> {languageTargets?.length ? languageTargets.map(language => language.name)?.join(', ') : 'N/A'} </p>
        }
        </FieldContainer>
    )
}

export default LanguageTargeting;