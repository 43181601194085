import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLobList } from '../../../../actions/lobActions';

import Search from '../../../../ui/search';
import WideCard from '../../../../ui/card/WideCard';

import { InitialCreateModalContainer } from '../../../../components/Modal/styles';
import { ModalText } from '../../../../components/Modal/styles';
import { ModalListContainer, ModalListHeader, ModalListWrapper } from '../../../../ui/list';

const SelectLobStep = ({ unregister, getValues, selectedCard, setSelectedCard, }) => {
    const [filteredList, setFilteredList] = useState([]);
    const lobList = useSelector(state => state.lob.lineOfBusinessList);
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLobList());
    }, []);

    useEffect(() => {
        const advertisersList = user.advertisers;
        const role = user.role;
        if (role === 'SUPER_ADMIN' || role === 'ADMIN') {
            setFilteredList(lobList);
            return;
        }

        const filterByAdvertiser = lobList.filter((lob) => {
            return advertisersList?.some(advertiser => advertiser.websafe === lob?.advertiser?.websafe);
        })

        setFilteredList(filterByAdvertiser);
    }, [lobList]);

    useEffect(() => {
        if (selectedCard?.websafe) return;
        const { lob } = getValues();
        if (!lob?.websafe) return;
        setSelectedCard(lob);
    }, []);

    const handleFilter = e => {
        const searchVal = e.target.value.toLowerCase()
        const filterSearch = lobList.filter(lob => lob.name.toLowerCase().includes(searchVal) || lob.labels.find(label => label.toLowerCase().includes(searchVal)));
        setFilteredList(filterSearch);
    }

    const handleSelectCard = (data) => {
        unregister('lob');
        setSelectedCard(data)
    }

    const renderLobCards = () => {
        if (!lobList.length) return;

        return filteredList.map(lob => {
            return (
                <WideCard
                    data={lob}
                    key={lob?.websafe}
                    setSelectedCard={handleSelectCard}
                    isSelected={selectedCard?.websafe === lob?.websafe}
                />
            )
        })
    }

    return (
        <InitialCreateModalContainer>
            <ModalText> Select a Line of Business <span className='asterisk'>*</span> </ModalText>
            <Search text='Search for a LOB name or label' handleFilter={handleFilter} />
            <ModalListWrapper>
                <ModalListHeader> Line of Businesses </ModalListHeader>
                <ModalListContainer>
                    {renderLobCards()}
                </ModalListContainer>
            </ModalListWrapper>
        </InitialCreateModalContainer>
    )
}

export default SelectLobStep;