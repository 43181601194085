import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import LanguageModal from "../../lineofbusiness/components/modal/LanguageModal";
import LocationModal from "../../lineofbusiness/components/modal/LocationModal";
import TrackingModal from "../../lineofbusiness/components/modal/TrackingModal";
import Checkbox from "../../../ui/input/checkbox/CheckBox";
import CreatableSelect from "react-select/creatable";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

// Redux
import { googleObjectives } from "../../../actions/tempActions";
import { getBuyingTypes } from "../../../actions/socialActions";
import { generateName } from "../../../actions/mediaActions";

// Styles
import { OneThirdGridColContainer, FieldContainer, InputFieldWrapper } from "../../../ui/containers";
import { Input, DatePickerWrapper, CheckboxContainer } from "../../../ui/input/input";
import { ColumnHeader } from "../../../ui/headers";
import { selectTheme } from "../../../ui/themes";
import "react-datepicker/dist/react-datepicker.css";
import { capitalize } from "../../../utils/strings";
import { BiRefresh } from 'react-icons/bi';
import { getFacebookSpecialAdCategory } from "../../../actions/lobActions";
import CustomDatePicker from "../../../ui/input/fields/CustomDatePicker";
import CurrencyInput from "../../../ui/input/fields/CurrencyInput";

const FacebookCampaignSetup = ({
  isEdit,
  lobName,
  templateName,
  name,
  setName,
  campaign,
  start,
  setStart,
  end,
  setEnd,
  budget,
  setBudget,
  objective,
  setObjective,
  locationTargets,
  setLocationTargets,
  languageTargets,
  setLanguageTargets,
  trackingCodes,
  setTrackingCodes,
  specialAdCategory,
  setSpecialAdCategory,
  budgetDuration,
  setBudgetDuration,
  selectedAdvantageCampaignBudget,
  setSelectedAdvantageCampaignBudget,
  spendLimit,
  setSpendLimit,
  setCampaignSpendingLimit,
  campaignSpendingLimit,
  setCountries,
  setLanguages,
  countryOptions,
  languageOptions,
}) => {
  const [objectiveOptions, setObjectiveOptions] = useState([]);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
  const [specialAdCategoriesOptions, setSpecialAdCategoryOptions] = useState(null);
  const [buyingTypesOptions, setBuyingTypesOptions] = useState(null);
  const [buyingType, setBuyingType] = useState("AUCTION");



  const budgetDurationOptions = [
    { label: "Lifetime", value: "LIFETIME" },
    { label: "Daily", value: "DAILY" },
  ];

  const isBudgetIntervalLifetime = (budgetDuration?.value || campaign?.budgetDuration) === "LIFETIME";
  const isValueNullController = (a, b) => (a === undefined ? b : a);


  const shouldShowCatalog = campaign?.objective === 'Sales'

  useEffect(() => {
    getFacebookSpecialAdCategory().then((response) => {
      setSpecialAdCategoryOptions(response.map((it) => ({ label: capitalize(it), value: it })));
    });

    getBuyingTypes((res) => {
      setBuyingTypesOptions(
        res.map((type) => {
          return {
            label: type,
            value: type,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    googleObjectives((objectives) => {
      const result = objectives.map((objective) => {
        return {
          label: objective,
          value: objective,
        };
      });
      setObjectiveOptions(result);
    });
  }, [campaign]);

  const getDefaultValues = (selectedOptions) => {
    return selectedOptions?.map(val => {
      return (
          {
              label: val,
              value: val
          }
      )
    })
  }

  const handleCountryChange = (selectedOptions) => {
    const selectedCountries = selectedOptions?.map(opt => opt.value) || [];
    setCountries(selectedCountries)
  }

  const handleLanguageChange = (selectedOptions) => {
    const selectedLanguages = selectedOptions?.map(opt => opt.value) || [];
    setLanguages(selectedLanguages)
  }

  return (
    <>
      <ColumnHeader> Flight Details </ColumnHeader>
      <OneThirdGridColContainer>
        <FieldContainer>
          <h3>
            Campaign Name&nbsp; {isEdit &&
            <BiRefresh
              color='#F8B133'
              style={{cursor: 'pointer'}}
              onClick={() => generateName({mediaPlanCampaign: campaign.websafe, callback: res => setName(res.name)})}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Generate campaign name or manually edit the campaign name"
            />}
          </h3>
          {isEdit && <Tooltip id='my-tooltip' className='tooltip' classNameArrow='arrow' border={true} effect='solid' />}
          {isEdit ? (
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          ) : (
            <p> {name || "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3> Start Date {<span className='asterisk'>*</span>} </h3>
          {isEdit ? (
            <>
              <DatePickerWrapper>
                <DatePicker
                  customInput={<CustomDatePicker />}
                  minDate={new Date()}
                  wrapperClassName="datePicker"
                  onChange={(date) => setStart(date.getTime())}
                  selected={start && new Date(start)}
                />
              </DatePickerWrapper>
              {!start && <p className="error">Please select Start Date</p>}
            </>
          ) : (
            <>
              <p> {start ? new Date(start).toDateString() : "N/A"} </p>
              {!start && <p className="error">Please select Start Date</p>}
            </>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3> End Date {<span className='asterisk'>*</span>} </h3>
          {isEdit ? (
            <>
              <DatePickerWrapper>
                <DatePicker
                  customInput={<CustomDatePicker />}
                  wrapperClassName="datePicker"
                  onChange={(date) => setEnd(date.getTime())}
                  selected={end && new Date(end)}
                  minDate={start + 60 * 60 * 24 * 1000}
                />
              </DatePickerWrapper>
              {!end && <p className="error">Please select End Date</p>}
            </>
          ) : (
            <>
               <p> {end ? new Date(end).toDateString() : "N/A"} </p>
               {!end && <p className="error">Please select End Date</p>}
            </>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3> Ad Account </h3>
          {isEdit ? (
            <Select isDisabled={true} value={{ label: campaign?.adAccount?.accountId, value: campaign?.adAccount?.accountId }} styles={selectTheme} />
          ) : (
            <p> {campaign?.adAccount?.accountId || "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer hidden={isBudgetIntervalLifetime}>
          <h3> Campaign Spending limits </h3>
          <CheckboxContainer>
            <Checkbox
              checked={campaignSpendingLimit}
              callback={() => setCampaignSpendingLimit((prew) => !prew)}
              isDisabled={!isEdit}
              label="Campaign Spending limits"
            />
          </CheckboxContainer>
        </FieldContainer>
        <FieldContainer hidden={!campaignSpendingLimit || isBudgetIntervalLifetime}>
          <h3> Spending Limit Budget </h3>
          {isEdit ? (
            <CurrencyInput
              onChange={(e) => setSpendLimit(e.target.value)}
              value={spendLimit}
              currency={campaign?.adAccount?.currency}
            />
          ) : (
            <p> {campaign?.adAccount?.currency} {spendLimit || campaign?.spendLimit || "N/A"} </p>
          )}
        </FieldContainer>
      </OneThirdGridColContainer>
      <OneThirdGridColContainer>
        <>
          <FieldContainer>
            <h3> Advantage Campaign Budget </h3>
            <CheckboxContainer>
              <Checkbox
                checked={isValueNullController(selectedAdvantageCampaignBudget)}
                callback={() => setSelectedAdvantageCampaignBudget((prew) => !prew)}
                isDisabled={!isEdit}
                label="Advantage Campaign Budget"
              />
            </CheckboxContainer>
          </FieldContainer>
          <FieldContainer hidden={!selectedAdvantageCampaignBudget}>
            <h3> Campaign Budget Interval </h3>
            {isEdit ? (
              <Select
                isClearable
                options={budgetDurationOptions}
                defaultValue={budgetDuration}
                styles={selectTheme}
                onChange={(e) => setBudgetDuration(e)}
              />
            ) : (
              <p> {budgetDuration?.label || capitalize(campaign?.budgetDuration)} </p>
            )}
          </FieldContainer>
          <FieldContainer hidden={!selectedAdvantageCampaignBudget}>
            <h3> Budget {<span className='asterisk'>*</span>} </h3>
            {isEdit ?  (
              <InputFieldWrapper>
                <CurrencyInput
                  onChange={(e) => setBudget(e.target.value)}
                  currency={campaign?.adAccount?.currency}
                  value={budget}
                />
              </InputFieldWrapper>
            ): <p> {campaign?.adAccount?.currency} {budget || "N/A"} </p> }
          {!budget && <p className="error">Enter Budget to Launch Campaign</p>}
          </FieldContainer>
        </>
      </OneThirdGridColContainer>

      <ColumnHeader>Campaign Name Details</ColumnHeader>
      <OneThirdGridColContainer>
        <FieldContainer>
          <h3>Country or Region Code(s)</h3>
          {
            isEdit ? (
              <CreatableSelect
                isClearable
                isMulti
                options={countryOptions}
                styles={selectTheme}
                defaultValue={getDefaultValues(campaign?.countryCodes)}
                onChange={(e) => handleCountryChange(e)}
                placeholder='Select Country or Enter Region Code'
              />
            ) :
            <p> {campaign?.countryCodes?.length ? campaign.countryCodes.join(', ') : 'N/A'} </p>
          }
        </FieldContainer>
        <FieldContainer>
          <h3>Language(s)</h3>
          {
            isEdit ? (
              <Select
                isClearable
                isMulti
                options={languageOptions}
                styles={selectTheme}
                defaultValue={getDefaultValues(campaign?.languageCodes)}
                onChange={(e) => handleLanguageChange(e)}
                placeholder='Select Language(s)'
              />
            ) : <p>{campaign?.languageCodes?.length ? campaign.languageCodes.join(', ') : 'N/A'}</p>
          }
        </FieldContainer>
      </OneThirdGridColContainer>

      {lobName && <>
      <ColumnHeader> Originally Derived from the LOB: {lobName} </ColumnHeader>
      <FieldContainer>
        <h3> Special Ad Category </h3>
        {isEdit ? (
          <CreatableSelect
            isDisabled={false}
            isClearable
            isMulti
            options={specialAdCategoriesOptions}
            styles={selectTheme}
            onChange={(e) => setSpecialAdCategory(e)}
            value={specialAdCategory}
          />
        ) : (
          <p> {specialAdCategory?.length ? specialAdCategory.map((it) => it.label.replaceAll("_", " "))?.join(", ") : "N/A"} </p>
        )}
      </FieldContainer>
      </>}

      <ColumnHeader> Originally Derived from the {templateName} Network Template </ColumnHeader>
      <OneThirdGridColContainer>
        <FieldContainer>
          <h3> Objective </h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              isClearable
              options={objectiveOptions}
              defaultValue={{
                label: campaign?.objective,
                value: campaign?.objective,
              }}
              styles={selectTheme}
              onChange={(e) => setObjective(e.label)}
            />
          ) : (
            <p> {campaign?.objective ? campaign?.objective : "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3> A/B Test </h3>
          <CheckboxContainer>
            <Checkbox isEdit={false} isDisabled={true} checked={campaign?.abTest} label="Use A/B Test" />
          </CheckboxContainer>
        </FieldContainer>

        {campaign?.objective === 'Sales' &&
        <FieldContainer>
          {
            shouldShowCatalog && (
              <>
              <h3> Catalog </h3>
              <CheckboxContainer>
                <Checkbox
                  checked={campaign?.networkTemplateCampaign?.useCatalog}
                  isDisabled={true}
                  label='Use Catalog'
                />
              </CheckboxContainer>
              </>
            )
          }
        </FieldContainer>
        }

        <FieldContainer>
          <h3> Bid Strategy </h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              isClearable
              options={objectiveOptions}
              defaultValue={{
                label: campaign?.bidStrategy,
                value: campaign?.bidStrategy,
              }}
              styles={selectTheme}
              onChange={(e) => setObjective(e.label)}
            />
          ) : (
            <p> {campaign?.bidStrategy} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3> Campaign Budget Delivery Type </h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              isClearable
              value={{
                label: campaign?.budgetDeliveryPacing,
                value: campaign?.budgetDeliveryPacing,
              }}
              options={objectiveOptions}
              styles={selectTheme}
            />
          ) : (
            <p> {campaign?.budgetDeliveryPacing || "N/A"} </p>
          )}
        </FieldContainer>
      </OneThirdGridColContainer>

      <ColumnHeader> Originally Derived from System Config </ColumnHeader>
      <OneThirdGridColContainer>
        <FieldContainer>
          <h3> Buying Type </h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              value={{
                label: buyingType,
                value: buyingType,
              }}
              options={objectiveOptions}
              styles={selectTheme}
            />
          ) : (
            <p> {buyingType || "N/A"} </p>
          )}
        </FieldContainer>
      </OneThirdGridColContainer>

      {isLanguageModalOpen && (
        <LanguageModal onClose={() => setIsLanguageModalOpen(false)} handleTargetUpdate={setLanguageTargets} languageTargets={languageTargets} />
      )}
      {isLocationModalOpen && (
        <LocationModal onClose={() => setIsLocationModalOpen(false)} handleTargetUpdate={setLocationTargets} locationTargets={locationTargets} />
      )}
      {isTrackingModalOpen && (
        <TrackingModal onClose={() => setIsTrackingModalOpen(false)} handleTargetUpdate={setTrackingCodes} trackingCodes={trackingCodes} />
      )}
    </>
  );
};
export default FacebookCampaignSetup;
