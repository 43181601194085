import { NavLink } from 'react-router-dom';
import styled, {css}  from 'styled-components';
import { OutlineBtn, Button } from '../../ui/button';

const themeYellow = props => props.theme.color.yellow;

// * Container for Nav-Links
export const StyledLinkContainer = styled.div`
    padding: 16px;
`

export const StyledLink = styled(NavLink)`
    display: grid;
    text-decoration: none;
    align-items: center;
    grid-template-columns: 1fr 3fr;
    place-items: center;

    path {
        fill: ${props => props.theme.background.white};
    }

    &.active {
        background-color: ${props => props.theme.color.black};
        border-radius: 8px;
        margin: 0 auto;

        li {
            color: ${themeYellow};
        }

        path {
            fill: ${themeYellow};
        }
    }
`;

export const SidebarContainer = styled.div`
    min-width: 200px;
    height: 100vh;
    background-color: #000000;
    position: sticky;
    top: 0;
`;

export const LinkText = styled.li`
    width: 100%;
    list-style-type: none;
    color: #FFFFFF;
    height: 58px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    font-family: 'GothamMedium';
    text-align: left;
    vertical-align: middle;
`;

export const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
`;

export const ImgLogo = styled.img`
    width: 136px;
    height: 58px;
`;

export const NavIcon = styled.img`
    width: 16px;
    height: 16px;
`;

export const SidebarSettings = styled.div`
    background-color: #1D1D1B;
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
    padding: 20px;
`;

export const SignoutContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .userInfo__container {
        width: 136px;
        font-size: 14px;
        line-height: 12px;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        // * Hides overflow if username/email are to long
        p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    svg {
        display: flex;
        cursor: pointer;
        fill: ${themeYellow};
    }
`;

export const NameText = styled.p`
    color: ${themeYellow};
    font-size: 14px;
    font-family: 'GothamBook';
`;

export const EmailText = styled.p`
    color: #D9D9D8;
    text-decoration: underline;
    font-size: 12px;
    font-family: 'GothamMedium';
`;

export const LabelContainer = styled.div`
    font-weight: 700;
    display: flex;
    align-items: center;
    color: ${props => props.theme.background.white};
    white-space: nowrap;

    li {
        height: 30px;
    }

    .style-link {
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
    }
    path {
        fill: ${props => props.theme.background.white};
    }

    svg {
        margin-right: 10px;
    }
`;

// Page's navivation
export const PageNavigationContainer = styled.div`
    width: 100%;

    button {
        margin-bottom: 20px;
        padding: 0 15px;
    }

    div:first-child {
        button {
            width: 144px;
            padding: 0;
        }
    }
`;


export const NavigationBtn = styled(OutlineBtn)`
    transition: ease-in-out 0.1s;
    font-family: 'GothamBook';
    font-weight: 400;
    height: 30px;
    ${({ fontSize }) => fontSize && css`
        font-size: 11px;
    `}


    ${({ isSelected }) => isSelected && css`
        background: ${props => props.theme.color.yellow};
        font-family: 'GothamMedium';
        font-weight: 500;
        border-color: ${props => props.theme.color.yellow};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    `}

    ${({ isLast }) => isLast && css`
        margin-bottom: 0 !important;
        cursor: none;
        pointer-events: none;
    `}


    :hover {
        background: ${props => props.theme.color.yellow};
        border-color: ${props => props.theme.color.yellow};
    }
`;

export const SubNavigationBtn = styled(Button)`
    transition: ease-in-out 0.1s;
    cursor: pointer !important;
    pointer-events: auto !important;
    font-family: 'GothamBook';
    font-weight: 400;
    margin-left: 1px;
    height: 25px;
    border-radius: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ isSelected }) => isSelected && css`
        background: ${props => props.theme.hover.yellow};
        font-family: 'GothamMedium';
        font-weight: 500;
    `}

    :hover {
        ${({isSelected}) => !isSelected && css`background: ${props => props.theme.color.yellow} !important;`}
    }

    div {
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export const PlusNavigationBtn = styled(SubNavigationBtn)`
    background-color: #F1F1F1;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        svg {
            color: ${props => props.theme.color.black};
        }
    }

    svg {
        color: ${props => props.theme.color.yellow};
    }
`;

export const PlusNavigationBtnContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;

    button {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 100%;
            width: 0;
            border-color: transparent transparent transparent #CAD5E0;
            border-style: solid;
            border-width: 8px;

            ${({ showMore }) => !showMore && css`
                display: none;
            `}
        }
    }

    div {
        background-color:  ${props => props.theme.table.gray};
        margin-left: 10px;
        margin-top: -12px;
        border-radius: 4px;
        border: 1px solid ${props => props.theme.hover.gray};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;

        li:first-child {
            border-bottom: 1px solid ${props => props.theme.hover.gray};
            width: 100%;
        }

        li {
            width: 100%;
            padding: 8px;
            text-align: center;
            font-family: 'GothamBook';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.hover.gray};
            }
        }

        ${({ showMore }) => !showMore && css`
            display: none;
        `}
    }
`;

export const MultiBtnContainer = styled.div`
    button {
        margin-bottom: 0 !important;
    }

    > button {
        pointer-events: none;
        cursor: initial;
    }
    .media-channel {
        margin: 0.5rem 0 0.5rem 0;
    }
`;


export const StyleMediaPlanNavigator = styled.div`
    .render-template-wrapper {
        display: flex;
        line-height: 1.8;
    }
    .render-template-name {
        font-size: 12px;
    }
`