
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getMediaPlanList } from '../../actions/mediaActions';
import { exportGoogleSheets } from './mediaplans.util';



const useGoogleSheets = () => {
    const _D = useDispatch()


    const updateMediaPlans = async (mediaPlan, localState) => {
        try {
            const updatedMediaPlans = await exportGoogleSheets(`/api/mediaplan/export?mediaPlan=${mediaPlan?.websafe}`);
            toast.success('Succesfully exported the media plan to Google Sheets.')
            localState(updatedMediaPlans);
            _D(getMediaPlanList())
          } catch (error) {
            console.error('error', error)
            toast.error("Something went wrong");
          }
    }


        return  {updateMediaPlans}
}

export default useGoogleSheets