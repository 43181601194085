import { toast } from "react-toastify";

// * Add functions that deal with validating any kinda of data as well as toast pop ups

export const validateBidAdjustment = (value) => {
    if (value < -100 || (value < -90 && value > -100)) return toast.error("Bidding Adjustments must be between -90 and 900 OR set to -100!");
    if (value > 900) return toast.error("Bidding Adjustments must not exceed 900!");
    if (value % 1 !== 0) return toast.error("Bidding Adjustments must be whole numbers!");
    return
}

export const validateCpaData = (data) => {
    const { cpcBidLimit, targetRoas, targetPercentImpressionShare, targetCpaMicros, targetCpa } = data;

    if (!cpcBidLimit || !targetRoas || !targetPercentImpressionShare || !targetCpaMicros) return toast.error("All fields are required!")

    if (targetCpa <= 0) return toast.error('Target CPA must be greater than 0!')
    if (targetCpaMicros <= 0) return toast.error('Target CPA must be greater than 0!')
    if (cpcBidLimit <= 0) return toast.error('CPC Bid Limit must be greater than 0!')
    if (targetRoas < 0 || targetRoas > 100) return toast.error('Target Roas must be between 0 and 100!')
    if (targetPercentImpressionShare < 0 || targetPercentImpressionShare > 100) return toast.error('Target Percent Impressions must be between 0 and 100!')
    return
}

export const validateImpressionData = (bidStrategy, cpcBidLimit) => {
    if (bidStrategy === 'Impression Share' && cpcBidLimit <= 0) return toast.error('Maximum CPC Bid Limit is required!')
    return
}
