
import { SET_SELECTED_NETWORK, SET_SOCIAL_LOGINS, SET_ADVERTISERS, CREATE_ADVERTISER, UPDATE_ADVERTISERS } from './types';

export const setSelectedNetwork = (network) => dispatch =>  (
    dispatch(
        {
            type: SET_SELECTED_NETWORK,
            payload: network
        }
    )
)

export const setSocialLogins = (socialLogins) => dispatch =>  {
    dispatch(
        {
            type: SET_SOCIAL_LOGINS,
            payload: socialLogins
        }
    )
}

// Advertisers
export const getAdvertiserList = ({errorCallback}) => dispatch => {
        const list = [];
        window.eulerity.makeBatchedApiCall({
            url: `/api/advertiser/list?excludeArchived=${true}`,
            dataCallback: (res) => {
                res.forEach(c => list.push(c))
            },
            errorCallbacks: {
                1000: (err) => {
                    errorCallback?.(err)
                }
              },
            doneCallback: () => {
                dispatch({
                    type: SET_ADVERTISERS,
                    payload: list
                })
            }
        })
}

export const getAdvertiser = ({websafe, callback}) => {
    try {
        window.eulerity.makeApiCall(`/api/advertiser/get?advertiser=${websafe}`, 'GET', null, res => {
            callback(res);
        })
    } catch (err) {
        console.error(err);
    }
}

export const createAdvertiser = ({advertiserObj, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/advertiser/create`, 'POST', advertiserObj, res => {
            callback(res);
            dispatch({
                type: CREATE_ADVERTISER,
                payload: res
            })
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const updateAdvertiser = ({websafe, advertiserObj, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/advertiser/update?advertiser=${websafe}`, 'POST', advertiserObj, res => {
            callback(res);
            dispatch(
                {
                    type: UPDATE_ADVERTISERS,
                    payload: res
                }
            )
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const addUserToAdvertiser = ({websafe, uid, callback, errorCallback}) => {
    try {
        window.eulerity.makeApiCall(`/api/advertiser/addUser?advertiser=${websafe}&uid=${uid}`, 'POST', null, res => {
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const removeUserFromAdvertiser = ({websafe, uid, callback, errorCallback}) => {
    try {
        window.eulerity.makeApiCall(`/api/advertiser/removeUser?advertiser=${websafe}&uid=${uid}`, 'POST', null, res => {
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}



