import React from "react";
import { toast } from "react-toastify";
import Toggle from "../../../../ui/toggle";
import { updateUserRole } from "../../utils";
import { StyleToggle, StyleToggleEditMode } from "./styles";

const UserToogle = ({ loader, setLoader, setToggle, setRole, user, toggle, isEdit }) => {
  const handleToogle = async () => {
    if (loader) return;
    setLoader(true);
    let role = toggle ? "None" : "Read";
    try {
      await updateUserRole({ userId: user.id, role });
      setToggle((prew) => !prew);
      setRole(role);
      toast.success("User role has been updated!");
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  const ToggleEditMode = () => <StyleToggleEditMode access={toggle} />;

  return <StyleToggle>{!isEdit ? <ToggleEditMode /> : <Toggle isOn={toggle} handleToggle={handleToogle} />}</StyleToggle>;
};

export default UserToogle;
