import styled from "styled-components";

export const StyleUserManagment = styled.div`
    font-family: 'GothamBook';

  .for-header {
        font-weight: 600;
        font-size: 18px;
        border-bottom: 3.66871px solid #F8F3EB;
    }
    .for-user {
        color: ${(props) => props.theme.card.gray};
        font-weight: 400;
    }
`;
export const StyleUserManagmentContent = styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns: 3fr 4fr 2fr 4fr 1fr 0.5fr;
    column-gap: 0.2rem;
    justify-content: center;
    align-items: center;
    grid-auto-rows: min-content;
    background-color: ${({applyClass}) => applyClass ? "rgba(248, 243, 235, 0.5)" : "none"};
    .toggle-wrapper {
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
    }
    div {
            display: flex;
            align-items: center;
        }
        .mag-item {
            display: flex;
            align-items: center;
        }
`;

export const StyleUserRole = styled.div`
    position: relative;

    .user-role_icon {
        transition: 0.3s;
        transform: ${({selected}) => selected ?`rotate(180deg)` : `rotate(0deg)` };
    }
    .user-role_wrapper {
        padding: 0.3rem;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        cursor: ${props => props.isEdit ? 'cursor' : 'pointer'};
        transition: 0.3s;
        background-color: ${({selected, theme}) => selected ?  theme.color.yellow : "inherit"};
        font-weight: ${({selected}) => selected ? "bold" : "400"};
        :hover {
            background-color: ${({theme, selected, isEdit, loader}) => {
                if(selected || isEdit || loader) return
                return theme.hover.yellow;
            }}
        }
    }

`
export const StyleUserRoleDropdown = styled.div`
    position: absolute;
    /* top: 62px; */
    margin-top: 150px;
    left: 0;
    width: 100%;
    z-index: 100;
    visibility: hidden;
    height: 0rem;
    border-radius: 0;
    background-color: white;
    box-shadow: 0px 2px 8px ${props => props.theme.background.boxShadow};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;


    .role-options {
        display: flex;
        flex-direction: column;
        width: 100%;

        div {
            height: 32px;
            border-bottom: 0.5px solid #C4C4C4;
            width: 100%;
            transition: 0.3s;
            :hover {
                background-color: ${props => props.theme.color.yellow};
                font-weight: bold;
            }
            p {
                margin-left: 1rem;
            }
            :last-child {
                border-bottom: none;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }

    }
`


export const StyleToggle = styled.div`
        width: 100%;
        height: 100%;
        display: grid;
        padding-left: 0.7rem;
`

export const StyleToggleEditMode = styled.div`
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: ${props => props.access ? "#1ABF80" : "#CF1100"};
`

// Advertiser
export const AdvertiserContainer = styled.div`
    .my-select__control {
        height: 100%;
    }
`;