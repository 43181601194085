import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import { Input } from '../../../../ui/input/input'

const AdLocation = ({ googleIcon, adLocation, isEdit }) => {
    return (
        <FieldContainer>
            <h3>{googleIcon} Where do you want your ads to appear</h3>
            {
                isEdit ?
                    <Input value={adLocation} disabled />
                    : <p>{adLocation}</p>
            }
        </FieldContainer>
    )
}

export default AdLocation