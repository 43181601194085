import React, {useContext} from 'react';
import CurrencyInput from '../../../../../ui/input/fields/CurrencyInput';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignGroupSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';

const LifeTimeBudget = ({}) => {
    const { isEdit, campaignObj, handleUpdate, currency } = useContext(LinkedInCampaignContext);
    const { budgetSchedule, budget } = campaignObj;

    if (!budgetSchedule?.includes('BUDGET')) return;

    return (
        <FieldContainer>
            <h3> Life Time Budget </h3>
            {isEdit ?
                <CurrencyInput
                    onChange={(e) => handleUpdate({key: 'budget', value: e.target.value})}
                    currency={currency}
                    value={budget}
                /> :
                <p> {budget || 'N/A'} {currency} </p>
            }
        </FieldContainer>
    )
}

export default LifeTimeBudget;