import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Loader from '../../../../ui/Loader';
import { toast } from 'react-toastify';

// Helpers
import useOauth from '../../../../hooks/useOauth';
import { getSocialLogins } from '../../../../actions/socialActions';
import { oAuthGetSocialLoginMethod } from '../../../systemConfig/utils';

// Styles
import { ModalTextSecondary, BtnContainer } from '../../../../components/Modal/styles';
import { TableModalContainer } from '../../../../ui/table/styles';
import { ConnectSocialBtn, FacebookConnectionContainer } from './styles';
import { FacebookButton, GoogleButton, LinkedInButton } from '../../../../ui/button';

const ConnectSocialModal = ({ onClose, network }) => {
    const { name, icon } = network;
    const [isSuccess, setIsSuccess] = useState(false);
    const [oAuthLoader, setOauthLoader] = useState(false)
    const { onFocusBack, handleOAuth } = useOauth(name)

    useEffect(() => {
        getSocialLogins((res) => {
            const result = res.find(social => social.network === name.toLowerCase());
            if (result) setIsSuccess(true);
        });
    }, [isSuccess]);

    // * This useEffect fires off only ONCE - when the user re-enters the app page it will make an api call to check if the socialLogins finds a newly added account.
    useEffect(() => {
        if (onFocusBack) {
            setOauthLoader(true)
            oAuthGetSocialLoginMethod('/api/user/getSocialLogins').then(response => {
                const userConnectedSocial = response.find(social => social.network === name.toLowerCase())
                setIsSuccess(userConnectedSocial)
            }).catch(_ => {
                toast.error("Something went wrong")
            }).finally(_ => {
                setOauthLoader(false);
                onClose();
            })
        }
    }, [onFocusBack]);

    return (
        <Modal
            header={`Connect ${name}`}
            onClose={onClose}
            withIcon={true}
            network={name}
        >
            <TableModalContainer>
                <FacebookConnectionContainer isConnected={isSuccess}>
                    {oAuthLoader ? <Loader /> : <h2> {name} Connection: <span> {isSuccess ? 'CONNECTED' : 'NOT CONNECTED'} </span> </h2>}
                    <ModalTextSecondary>
                        Sign in to {name} to Add a Network Account
                    </ModalTextSecondary>
                    <BtnContainer>
                        {
                            name === 'Facebook' ?
                                <FacebookButton text='Login with Facebook' onClick={handleOAuth} /> :
                            name === 'Linkedin' ?
                                <LinkedInButton text='Login with LinkedIn' onClick={handleOAuth} icon={icon} /> :
                            name === 'Google' ?
                                <GoogleButton text='Sign in with Google' onClick={handleOAuth} icon={icon} /> :
                                <ConnectSocialBtn
                                    onClick={handleOAuth}>
                                    <img src={icon} alt='img' />
                                    Connect to {name}
                                </ConnectSocialBtn>
                        }
                    </BtnContainer>
                </FacebookConnectionContainer>
            </TableModalContainer>
        </Modal>
    )
}

export default ConnectSocialModal;