import React from "react";
import NumberInput from "./NumberInput";
import { FieldContainer, StyleFrequencyFields } from "./style";

const FrequencyField = ({
  text, icon, isEdit,
  freqDaysLocalState,
  freqDaysLocalValue,
  freqImpressionsLocalState,
  freqImpressionsValue,
  }) => {

  const isMultiImpressions = freqImpressionsValue > 1 ? 's' : '';
  const isMultiDays = freqDaysLocalValue > 1 ? 's' : '';

  return (
    <FieldContainer>
      <h3>{icon} {text?.header}{text.RequiredStar?.()}</h3>
      {isEdit ? (
        <StyleFrequencyFields>
          <div className="number-input_wrapper">
            <NumberInput localState={freqImpressionsLocalState} value={freqImpressionsValue} />
            <p>Impression{isMultiImpressions} every</p>
          </div>

          <div className="number-input_wrapper last-item">
            <NumberInput localState={freqDaysLocalState} value={freqDaysLocalValue} />
            <p>day{isMultiDays}</p>
          </div>
        </StyleFrequencyFields>
      ) : (
        <p> {`${freqImpressionsValue} impression${isMultiImpressions} every ${freqDaysLocalValue} day${isMultiDays}`} </p>
      )}
    </FieldContainer>
  );
};

export default FrequencyField;
