import React from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedNetwork } from '../../../../../actions/systemConfigActions'
import { StyleSelecor } from '../styles'



const Selector = ({name, Icon, selected}) => {
    const _D = useDispatch()
    return (
        <StyleSelecor selected={name === selected} onClick={() => _D(setSelectedNetwork(name))}>
            <div className='content-wrapper' >
             <Icon />
             <p>{name}</p>
            </div>
        </StyleSelecor>
    )
}

export default Selector