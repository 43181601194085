import styled, {css} from 'styled-components';

export const ModalContainer = styled.div`
    position: absolute;
    background: ${props => props.theme.background.white};
    box-shadow: 0px 4px 5px ${props => props.theme.modal.boxShadow};
    border-radius: 4.2px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    max-height: 80vh;
    overflow: scroll;
    transform: scale(0.9);
    padding: 42px 48px;
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    z-index: 999;

    :before {
      content: ' ';
      display: flex;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: ${props => props.theme.modal.overlay};
    }
`;

export const Header = styled.h1`
    all: unset;
    color: ${props => props.theme.background.black};
    white-space: nowrap;
    font-family: 'GothamMedium';
    font-weight: 500;
    font-size: 20px;
    border-bottom: ${props => props.withIcon ? 'none' : `1px solid ${props.theme.hover.gray}`};
    padding-bottom: ${props => props.withIcon ? '0' : '10px'};
`;

export const ModalHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: ${props => `1px solid ${props.theme.hover.gray}`};

    div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .icon {
        width: 30px;
        object-fit: cover;
    }
`

export const SubHeader = styled(Header)`
    font-size: 16px;
    border: none;
`;

export const ModalContentContainer = styled.div`
    /* margin: 10px 0; */
    /* max-height: 50vh; */
    overflow: scroll;
    position: relative;
`;

export const InnerModalContainer = styled.div`
    background-color: ${props => props.theme.background.lightGray};
    padding: 20px;

    .trash {
        display: flex;
        justify-content: flex-end;
        font-size: 20px;

        svg {
            cursor: pointer;
        }
    }
`;

export const ModalFieldContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 10px auto;
    flex-direction: column;
    justify-content: space-around;

    p {
        width: 100%;
    }
`;

export const HeaderInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    gap: 10px;

    p {
        width: 100%;
    }

    .asterisk {
        color: ${(props) => props.theme.color.yellow};
    }
`;

// Buttons
export const CloseBtn = styled.div`
    text-align: right;
    font-size: 1.5rem;

    svg {
        cursor: pointer;
    }
`;

export const BtnContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin: 40px auto 0 auto;
    gap: 10px;
`;

export const SmBtnContainer = styled(BtnContainer)`
    width: 50%;
`;

// Text
export const ModalText = styled.p`
    font-family: 'GothamBook';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 20px auto;

    .bold {
        font-weight: bold !important;
    }

    span > .asterisk {
        vertical-align: sub;
    }

    .asterisk {
        color: ${props => props.theme.color.yellow};
    }
`;

export const ModalTextSecondary = styled(ModalText)`
    color: ${props => props.theme.modal.gray};
    margin: 0 auto;

    .importantText {
        font-weight: 600;
    }
`;

export const BasicModalText = styled(ModalText)`
    margin: 0 auto;
    line-height: 20px;
`;

export const ListItem = styled.li`
    color: ${props => props.theme.card.gray};
    font-family: 'GothamBook';
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

export const ListBarItem = styled(ListItem)`
    background-color: ${props => props.theme.background.lightOrange};
    width: 100%;
    padding: 10px;
    border-radius: 9px;
    z-index: 1;
    cursor: default;

    svg {
        cursor: pointer;
    }
`;

// Delete
export const DeleteModalContainer = styled.div`
    button {
        width: fit-content;
        height: fit-content;
        padding: 10px 20px;
    }
`;

// Search
export const SearchBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        margin: 30px 0;
    }
`;

export const LocationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 900px;

    .search__result__wrapper {
        flex: 1;
        margin-right: 5px;
    }

    .selected_locations__wrapper {
        flex: 1;
        margin-left: 5px;
    }
`

export const SelectListContainer = styled.div`
    width: 100%;
    height: 250px;
    overflow: scroll;
    border: 1px solid ${props => props.theme.input.gray};
    border-radius: 4px;
    padding: 8px;

    p {
        text-align: center;
        font-family: 'GothamBook';
        font-weight: 400;
    }
`;

export const SelectListItem = styled.div`
    width: 100%;
    max-width: 600px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${props => props.theme.color.yellow};
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    font-family: 'GothamMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 20px;
    background: ${props => props.theme.color.yellow};

    ${({ isSelected }) => isSelected && css`
        opacity: 0.3;
        pointer-events: none;
    `}
`;

// Initial Create Container
export const InitialCreateModalContainer = styled.div`
    button {
        width: fit-content;
        height: fit-content;
        padding: 10px 20px;
    }
`;