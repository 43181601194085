import React, { useState } from 'react'
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles'
import DeleteTargetingModal from '../modal/DeleteTargetingModal'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { FcGoogle } from 'react-icons/fc';
import { FiPlusCircle } from 'react-icons/fi'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { removeAdAccount } from '../../../../actions/lobActions';

const GoogleNetworkTable = ({ isEdit, tableData, updateTableData, handleOnCreate, handleOnEdit, goConfigId }) => {
    const _D = useDispatch()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [loader, setLoader] = useState(false)

    // * Handle Item Selection - depending on the action type this will either edit/delete the item
    const handleItemSelection = (data) => {
        setSelectedItem(data)
        setIsDeleteModalOpen(true)
    }

    // * Handle Delete Ad Account
    const handleDeleteAdAccount = () => {
        const updatedAdAccounts = tableData.filter(data => data?.accountId !== selectedItem?.accountId)
        setLoader(true)
        _D(removeAdAccount({
            socialConfig: `goconfig=${goConfigId}`,
            obj: [{ accountId: selectedItem?.accountId, network: 'google' }],
            callback: () => {
                toast.success('Removing Ad Account');
                updateTableData(updatedAdAccounts)
                setIsDeleteModalOpen(false)
                setLoader(false)
            }
        }))
    }

    const renderData = () => {
        return tableData.map(data => {
            return (
                <li key={data?.accountId}>
                    <ListObj>
                        <span>{data?.displayName}</span>
                        <span>Currency: {data?.currency}</span>
                        <span className='google-ad-acc-item'> Target CPA: {data?.targetCpaMicros} </span>
                        <span className='google-ad-acc-item'> Target ROAS: {data?.targetRoas}%</span>
                        <span className='google-ad-acc-item'> Max CPC Bid Limit: {data?.cpcBidLimit}</span>
                        <span className='google-ad-acc-item'> Percent Impression Share to Target: {data?.targetPercentImpressionShare}%</span>
                    </ListObj>
                    <div className='opt__button__container'>
                        {isEdit && <BiPencil onClick={() => handleOnEdit(data)} /> }
                        {isEdit && <BiTrash onClick={() => handleItemSelection(data)} />}
                    </div>
                </li>
            )
        })
    }

    return (
        <TableContainer>
            <TableHeader>
                <h3> <FcGoogle /> Google Search Ad Accounts</h3>
                {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
            </TableHeader>

            {
                Object.keys(tableData || {})?.length > 0 ?
                    renderData() :
                    <EmptyDataContainer>
                        <p> No Google Search Ad Accounts Available </p>
                    </EmptyDataContainer>
            }

            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={handleDeleteAdAccount}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title='Google Search Ad Accounts'
                    item={selectedItem}
                    useLoader
                    loading={loader}
                />
            }
        </TableContainer>
    )
}
export default GoogleNetworkTable