import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles'
import { BiTrash } from 'react-icons/bi'
import DeleteTargetingModal from '../modal/DeleteTargetingModal'
import { FiPlusCircle } from 'react-icons/fi'
import { updateNetworkConfig } from '../../../../actions/lobActions'
import FbIcon from '../../../../assets/images/socialMediaIcons/FbIcon.png';

const PromoteTable = ({ isEdit, handleOnCreate, tableData, successText, fbConfigId }) => {
    const _D = useDispatch()

    const [loader, setLoader] = useState(false)
    const [selectedItem, setSelectedItem] = useState(false)
    const [itemKey, setItemKey] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const facebookIcon = <img src={FbIcon} alt='img' />;

    // * Function will select item and will handle a given action (delete/edit)
    const handleSelectItem = (val, item) => {
        setSelectedItem(val)
        setItemKey(item)
        setIsDeleteModalOpen(true)
    }

    const handleDeleteItem = () => {
        delete tableData[itemKey]

        setLoader(true)
        _D(updateNetworkConfig({
            socialConfig: `fbconfig=${fbConfigId}`,
            obj: { appsToPromote : tableData},
            callback: () => {
                setLoader(false)
                setIsDeleteModalOpen(false)
                toast.success(successText)
            },
        }))
    }

    const renderData = () => {
        const list = [];
        for (const item in tableData) {
            list.push(
                <li key={item}>
                    <ListObj>
                        <span>{item}</span>
                        <span>{tableData[item]}</span>
                    </ListObj>
                    <div className='opt__button__container'>
                        {isEdit && <BiTrash onClick={() => handleSelectItem(tableData[item], item)} />}
                    </div>
                </li>
            )
        }
        return list;
    }

    return (
        <TableContainer>
        <TableHeader>
            <h3> {facebookIcon} App to Promote and Store URL </h3>
            {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
        </TableHeader>
        {
            Object.keys(tableData || {})?.length > 0 ?
                renderData() :
                <EmptyDataContainer>
                    <p> No App to Promote and Store URL Available </p>
                </EmptyDataContainer>
        }
        {
            isDeleteModalOpen &&
            <DeleteTargetingModal
                handleDelete={handleDeleteItem}
                onClose={() => setIsDeleteModalOpen(false)}
                title='App to Promote and Store URL'
                item={selectedItem}
                useLoader
                loading={loader}
            />
        }
    </TableContainer>
    )
}

export default PromoteTable