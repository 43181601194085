import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomDatePicker from "../../../ui/input/fields/CustomDatePicker";

// Utils
import { serveImage } from "../../systemConfig/components/labelManagement/label.utils";
import { capitalize } from "../../../utils/strings";

// Styles
import { OneThirdGridColContainer, FieldContainer, StyledFieldsWrapper } from "../../../ui/containers";
import { Input, DatePickerWrapper } from "../../../ui/input/input";
import { ColumnHeader } from "../../../ui/headers";
import { selectTheme } from "../../../ui/themes";
import "react-datepicker/dist/react-datepicker.css";
import useDummyNetworkProps from "../../../hooks/useDummyNetworkProps";
import HowToSetPropertyModal from "../../systemConfig/components/networkManagement/modals/HowToSetPropertyModal";
import { sortObjectsByName } from "../../../utils/sort";
import CurrencyInput from "../../../ui/input/fields/CurrencyInput";



const DummyCampaignSetup = ({
  isEdit,
  dummyNetwork,
  name,
  setName,
  campaign,
  start,
  setStart,
  end,
  setEnd,
  budget,
  setBudget,
  objective,
  setObjective,
  budgetDuration,
  setBudgetDuration,
  setDummyNetworkProperties,
  dummyNetworkProperties,
}) => {
  const [objectiveOptions, setObjectiveOptions] = useState([]);

  // const [dummyNetworkProperties, setDummyNetworkProperties] = useState({});
  const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
  const [howToData, setHowToData] = useState(null);

  const DynamicSelectorRenderer = useDummyNetworkProps(setDummyNetworkProperties);

  useEffect(() => {
    const result = dummyNetwork?.objectives?.map((objective) => {
      return {
        label: objective,
        value: objective,
      };
    });
    setObjectiveOptions(result);
  }, [campaign, dummyNetwork]);

  const budgetDurationOptions = [
    {
      label: "DAILY",
      value: "DAILY",
    },
    {
      label: "LIFETIME",
      value: "LIFETIME",
    },
  ];

  const handleHowItWorksModal = (modalData) => {
    setHowToData(modalData);
    setIsHowToModalOpen(true);
  };
  return (
    <StyledFieldsWrapper>
      <ColumnHeader> Flight Details </ColumnHeader>
      <OneThirdGridColContainer>
        <FieldContainer>
          <h3>
            <img src={serveImage(dummyNetwork?.logo)} alt="" /> Campaign Name
          </h3>
          {isEdit ? (
            <Input defaultValue={name} onChange={(e) => setName(e.target.value)} placeholder={name || "Enter name"} />
          ) : (
            <p> {name || "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3>
            <img src={serveImage(dummyNetwork?.logo)} alt="" /> Start Date {<span className="asterisk">*</span>}
          </h3>
          {isEdit ? (
            <>
              <DatePickerWrapper>
                <DatePicker
                  customInput={<CustomDatePicker />}
                  minDate={new Date()}
                  wrapperClassName="datePicker"
                  onChange={(date) => setStart(date.getTime())}
                  selected={start && new Date(start)}
                />
              </DatePickerWrapper>
              {!start && <p className="error">Please select Start Date</p>}
            </>
          ) : (
            <>
              <p> {start ? new Date(start).toDateString() : "N/A"} </p>
              {!start && <p className="error">Please select Start Date</p>}
            </>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3>
            <img src={serveImage(dummyNetwork?.logo)} alt="" /> End Date {<span className="asterisk">*</span>}
          </h3>
          {isEdit ? (
            <>
              <DatePickerWrapper>
                <DatePicker
                  customInput={<CustomDatePicker />}
                  wrapperClassName="datePicker"
                  onChange={(date) => setEnd(date.getTime())}
                  selected={end && new Date(end)}
                  minDate={start + 60 * 60 * 24 * 1000}
                />
              </DatePickerWrapper>
              {!end && <p className="error">Please select End Date</p>}
            </>
          ) : (
            <>
              <p> {end ? new Date(end).toDateString() : "N/A"} </p>
              {!end && <p className="error">Please select End Date</p>}
            </>
          )}
        </FieldContainer>
      </OneThirdGridColContainer>

      <OneThirdGridColContainer>
        {campaign?.adAccount && (
          <FieldContainer>
            <h3>
              <img src={serveImage(dummyNetwork?.logo)} alt="" /> Ad Account
            </h3>
            <p> {campaign?.adAccount?.displayName} </p>
          </FieldContainer>
        )}

        <FieldContainer>
          <h3><img src={serveImage(dummyNetwork?.logo)} alt="" /> Campaign Budget Interval</h3>
          {isEdit ? (
            <Select
              isClearable
              options={budgetDurationOptions}
              defaultValue={budgetDuration}
              styles={selectTheme}
              onChange={(e) => setBudgetDuration(e.value)}
            />
          ) : (
            <p> {budgetDuration?.label || capitalize(campaign?.budgetDuration)} </p>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3><img src={serveImage(dummyNetwork?.logo)} alt='logo'/> Budget {<span className="asterisk">*</span>}</h3>
          {isEdit ? (
            <>
              <div className="edit_text_label">
                <CurrencyInput
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  currency={campaign?.adAccount?.currency}
                />
              </div>
              {!budget && <p className="error">Enter Budget to Launch Campaign</p>}
            </>
          ) : (
            <>
              <p>
                {campaign?.adAccount?.currency} {campaign?.budget || budget || "N/A"}
              </p>
              {!budget && <p className="error">Enter Budget to Launch Campaign</p>}
            </>
          )}
        </FieldContainer>
      </OneThirdGridColContainer>

      <OneThirdGridColContainer>
        {/* <FieldContainer>
          <h3>
            <img src={serveImage(dummyNetwork?.logo)} alt="" /> Objective
          </h3>
          {isEdit ? (
            <Select
              isClearable
              options={objectiveOptions}
              defaultValue={{
                label: objective,
                value: objective,
              }}
              styles={selectTheme}
              onChange={(e) => setObjective(e.label)}
            />
          ) : (
            <p> {objective ? objective : "N/A"} </p>
          )}
        </FieldContainer> */}

        {sortObjectsByName(campaign?.properties).map((prop) => {
          return (
            <FieldContainer key={prop.id}>
              <DynamicSelectorRenderer
                isEdit={isEdit}
                handleHowItWorksModal={handleHowItWorksModal}
                state={dummyNetworkProperties?.[prop.id]?.values}
                logo={<img style={{ width: "20px" }} src={serveImage(dummyNetwork?.logo)} alt="logo" />}
                {...prop}
              />
            </FieldContainer>
          );
        })}
      </OneThirdGridColContainer>
      {isHowToModalOpen && <HowToSetPropertyModal modalData={howToData} onClose={setIsHowToModalOpen.bind(null, false)} />}
    </StyledFieldsWrapper>
  );
};
export default DummyCampaignSetup;
