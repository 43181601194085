import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Loader from '../../../../ui/Loader';

// Redux
import { addUserToAdvertiser, removeUserFromAdvertiser } from '../../../../actions/systemConfigActions';

// Styles
import { selectTheme } from '../../../../ui/themes';
import { AdvertiserContainer } from './styles';

const Advertisers = ({ user, isEdit, advertiserOptions, advertiserLoader, setAdvertiserLoader, role }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionsList, setOptionsList] = useState([]);

    useEffect(() => {
        const options = user?.advertisers?.map(advertiser => {
            return ({label: advertiser.name, value: advertiser})
        })

        const filteredOptions = advertiserOptions.filter((advertiser) => !options.some((option) => option.value.websafe === advertiser.value.websafe));

        setSelectedOptions(options);
        setOptionsList(filteredOptions);
    }, [advertiserOptions, role])

    const listOfAdvertisers = () => {
        if (role === 'Admin') return 'All Advertisers';
        if (role === 'None') return 'NONE';

        if (!isEdit) {
            if  (!selectedOptions?.length) return 'NONE';

            const advertisers = selectedOptions.map(advertiser => advertiser.label).join(', ');
            return advertisers;
        } else {
            return (<>
                    {
                        advertiserLoader ?
                        <Loader /> :
                        <Select
                            isMulti
                            hideSelectedOptions={true}
                            isClearable={false}
                            options={optionsList}
                            defaultValue={selectedOptions}
                            styles={selectTheme}
                            menuPortalTarget={document.body}
                            onChange={(event, options) => handleChange(event, options)}
                        />
                    }
            </>)
        }
    }

    const handleChange = (event, options) => {
        setAdvertiserLoader(true);

        switch (options.action) {
            case 'select-option': {
                const addedOption = options.option;
                addUserToAdvertiser({
                    websafe: addedOption.value.websafe,
                    uid: user.id,
                    callback: (res) => {
                        setSelectedOptions([...selectedOptions, addedOption]);
                        setAdvertiserLoader(false);
                        toast.success(`Added ${addedOption.value.name}`)
                    }
                })
                return;
            }

            case 'remove-value': {
                const removedValue = options.removedValue;
                removeUserFromAdvertiser({
                    websafe: removedValue?.value?.websafe,
                    uid: user.id,
                    callback: (res) => {
                        const filteredOptions = selectedOptions.filter(option => option.label !== removedValue.label);
                        setSelectedOptions(filteredOptions);
                        setOptionsList([...optionsList, removedValue])
                        setAdvertiserLoader(false);
                        toast.success(`Removed ${removedValue.value.name}`)
                    }
                })
                return;
            }
        default:
            return;
        }
    }

    return (
        <AdvertiserContainer>
            {listOfAdvertisers()}
        </AdvertiserContainer>
    )
}

export default Advertisers;