import {
    GET_NETWORK_LIST, CREATE_NETWORK, DELETE_NETWORK, SELECT_TEMPLATE, UPDATE_TEMPLATE,
    CREATE_CAMPAIGN, DELETE_CAMPAIGN, UPDATE_CAMPAIGN,
    CLONE_NETWORK, SELECTED_CAMPAIGN} from '../actions/types';

const initialState = {
    templateList: [],
    currentNetworkTemplate: {},
    selectedCampaign: {}
}

const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NETWORK_LIST: {
            return {
                ...state,
                templateList: action.payload
            }
        }
        case CREATE_NETWORK: {
            return {
                ...state,
                templateList: [...state.templateList, action.payload]
            }
        }
        case SELECT_TEMPLATE: {
            return {
                ...state,
                currentNetworkTemplate: action.payload
            }
        }
        case DELETE_NETWORK: {
            const filteredList = state.templateList.filter(template => template.websafe !== action.payload);

            return {
                ...state,
                templateList: [...filteredList],
                currentNetworkTemplate: {}
            }
        }
        case UPDATE_CAMPAIGN: {
            const updatedCampaignList = state.currentNetworkTemplate.campaigns.map(campaign => {
                if (campaign.websafe === action.payload.websafe) return action.payload;
                return campaign;
            })

            return {
                ...state,
                selectedCampaign: action.payload,
                currentNetworkTemplate: {...state.currentNetworkTemplate, campaigns: updatedCampaignList}
            }
        }

        case CREATE_CAMPAIGN: {
            return {
                ...state,
                currentNetworkTemplate: {...state.currentNetworkTemplate, campaigns: [...state.currentNetworkTemplate.campaigns, action.payload]}
            }
        }
        case DELETE_CAMPAIGN: {
            const filteredList = state.currentNetworkTemplate.campaigns.filter(campaign => campaign.websafe !== action.payload);

            return {
                ...state,
                currentNetworkTemplate: {...state.currentNetworkTemplate, campaigns: filteredList}
            }
        }
        case UPDATE_TEMPLATE: {
            return {
                ...state,
                currentNetworkTemplate: action.payload
            }
        }
        case CLONE_NETWORK: {
            return {
                ...state,
                templateList: [...state.templateList, action.payload]
            }
        }

        case SELECTED_CAMPAIGN: {
            return {
                ...state,
                selectedCampaign: action.payload

            }
        }

        default: return state;
    }
}

export default templateReducer;