import React, { useContext } from 'react';
import CurrencyInput from '../../../../../ui/input/fields/CurrencyInput';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { FieldContainer } from '../../../../../ui/containers';

const CostPerResultGoal = ({}) => {
    const { isEdit, campaignObj, handleUpdate, currency } = useContext(LinkedInCampaignContext);
    const { targetCpa } = campaignObj;

    const bidStrategy = useSelector(state => state.media.currentCampaign?.networkTemplateCampaign?.bidStrategy);

    if (bidStrategy === 'Manual Bidding' || bidStrategy == 'Manual Bidding with Bid Adjustment') {
        return (
            <FieldContainer>
                <h3> Target CPA </h3>
                { isEdit ?
                    <CurrencyInput
                        onChange={(e) => handleUpdate({key: 'targetCpa', value: e.target.value})}
                        value={targetCpa}
                        currency={currency}
                    /> :
                    <p> {targetCpa} {currency} </p>
                }
            </FieldContainer>
        )
    }
}

export default CostPerResultGoal;