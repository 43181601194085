import React, {useContext} from 'react';
import Select from 'react-select';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignGroupSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { selectTheme } from '../../../../../ui/themes';

const BudgetSchedule = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { budgetSchedule } = campaignObj;
    const options = [
        {label: 'Run continuously from a start date', value: 'RUN_CONTINUOUSLY'},
        {label: 'Set a start and end date', value: 'START_AND_END_DATE'},
        {label: 'Set a start and end date with a budget', value: 'START_AND_END_DATE_WITH_BUDGET'},
    ]

    const defaultValue = options.find(option => option.value === budgetSchedule);

    return (
        <FieldContainer>
            <h3> Budget Schedule <span className='asterisk'>*</span> </h3>
            {
                isEdit ?
                <Select
                    options={options}
                    styles={selectTheme}
                    onChange={e => handleUpdate({key: 'budgetSchedule', value: e.value})}
                    defaultValue={defaultValue}
                    value={options.find(option => option.label === budgetSchedule)}
                /> :
                <p> {defaultValue?.label || 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default BudgetSchedule;