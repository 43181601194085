import styled from "styled-components";

export const StyleSysAccounts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyleSysAccountsWrapper = styled.div`
  width: 100%;
  min-height: 500px;
  /* background-color: grey; */
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
`;

export const StyleSysAccountsSelectors = styled.div`
  display: flex;
  gap: 4rem;
`;

export const StyleSysAccountsDisplay = styled.div`
  margin-top: 3rem;
  font-size: 18px;
  color: ${(props) => props.theme.card.gray};
  font-family: "GothamMedium";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .change-system-account {
    margin-top: 1.3rem;
    width: 70%;
    display: flex;
    justify-content: flex-end;
  }
`;

//Components

export const StyleSelecor = styled.div`
  width: 180px;
  height: 30px;
  transition: all 0.4s;
  border-bottom: ${({ selected, theme }) => (selected ? `2.5px solid ${theme.color.yellow}` : `2.5px solid white`)};
  display: flex;
  justify-content: center;
  cursor: pointer;
  .content-wrapper {
    display: flex;
    gap: 0.5rem;
    font-family: ${({ selected }) => (selected ? "GothamMedium" : "GothamBook")};

    font-weight: 400;
    font-size: 18px;
  }
`;

export const StyleConnectionPage = styled.div`
  background-color: ${(props) => props.theme.background.lightGray};
  height: 188px;
  width: 100%;
  margin-top: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .CP-wrapper {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    gap: 1rem;
    line-height: 1.5;
    text-align: center;
  }
  .CP-span {
    font-family: "GothamLight";
    color: ${({ isConnected }) => (isConnected ? "green" : "red")};
  }
  .CP-header {
    color: ${(props) => props.theme.card.gray};
    font-size: 20px;
  }
  .CP-body {
    color: ${(props) => props.theme.color.black};
    font-family: "GothamBook";
    font-size: 16px;
  }
`;

export const StyleSystemLoginAccounts = styled.div`
margin-top: 3rem;
width: 80%;
  .sys_acc_options {
    padding-left: 1rem;
    padding-right: 1rem;
    display: grid;
    grid-template-columns: 10fr 3fr;
    height: 50px;
    align-items: center;
  }
  .sys_opt_1 {
    color: ${props => props.theme.color.black};
    border-bottom: 3px solid #F8F3EB;;
  }
  .sys_opt_2 {
    font-family: 'GothamBook';
  }
  .sys_acc_options-actions {
    display: flex;
    gap: 1rem;
    width: 120px;
 
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
  }

`;


export const StyleSysAccountsModal = styled.div`
  color: ${(props) => props.theme.color.black};
  border-radius: 4.2px;

  .modal-add-new-account {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .modal-displayNames {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 18px;
    border-bottom: 3.66871px solid #f8f3eb;
    margin-bottom: 2rem;
  }
  .modal-descriptor {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: ${(props) => props.theme.color.black};
    font-family: "GothamBook";
  }
`;

export const StyleSysAccountsLogins = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  overflow: scroll;
`;

export const StyleSystemAccountsSelector = styled.div`
  color: ${(props) => props.theme.color.black};
  padding: 0.5rem;
  width: 490px;
  height: 65px;
  display: grid;
  grid-template-columns: 6fr 3fr 1fr;
  align-items: center;
  background: ${props => props.isSelected ? "rgba(248, 243, 235, 0.4)": props.theme.color.white} ;
  border:  2px solid ${(props) => props.isSelected ? props.theme.card.yellow : props.theme.hover.gray};
  border-radius: 6px;
  margin-bottom: 1.5rem;
  cursor: pointer;
  transition: all 0.4s;
  .checkbox-wrapper {
    transition: all 0.2s;
    opacity: ${props => props.isSelected ? 1 : 0};
    transform: ${props => props.isSelected ? 'scale(1)' : 'scale(0)'};
    /* transform: scale(0.3); */
  }
  .sysAccount-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .sysAccount-name {
    font-family: "GothamMedium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .sysAccount-account {
    font-family: "GothamBook";
    font-size: 12px;
  }
  .sysAccount-icon-wrapper {
    display: flex;
    gap: 0.5rem;
  }
  .icon-holder {
    cursor: pointer;
    width: 38px;
    height: 30px;
    border-radius: 6px;
    display: grid;
    place-items: center;
    background-color: ${props => props.theme.color.white};
  }
  .trash {
      border: 1px solid red;
    }
    .refresh {
      border: 1px solid black
    }
`;
