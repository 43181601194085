import React, { useState, useEffect, useContext } from 'react';
import Loader from '../../../../ui/Loader';
import AddDummyAdAccountModal from '../modal/AddDummyAdAccountModal';
import EditContext from '../Context/EditContext';
import DummyNetworkTable from '../tables/DummyNetworkTable';

// Redux
import { getDataFile } from '../../../../actions/orgAction';

//Helpers

import { serveImage } from '../../../systemConfig/components/labelManagement/label.utils';

// Styles
import { SubHeader } from '../../../../ui/headers';
import { EditSaveBtn } from '../../../../ui/button';
import { FieldColContainer } from '../../styles';
import { FieldContainer, OneThirdGridColContainer, StyledFieldsWrapper } from '../../../../ui/containers';
import { toast } from 'react-toastify';

import useDummyNetworkProps from '../../../../hooks/useDummyNetworkProps';
import HowToSetPropertyModal from '../../../systemConfig/components/networkManagement/modals/HowToSetPropertyModal';
import { collectProperties, updateDummyNetworkProps } from '../../../templates/templates.util';
import { sortObjectsByName } from '../../../../utils/sort';
import { useDispatch, useSelector } from 'react-redux';
import { selectDummyNetwork, updateLOBDummyProperties } from '../../../../actions/lobActions';

const DummyNetworkConfig = ({dummy}) => {
    const _D = useDispatch()
    const currNetworkConfig = useSelector(s => s.lob.currentNetworkConfig)

    const [dummyNetworkProperties, setDummyNetworkProperties] = useState({});
    const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
    const [howToData, setHowToData] = useState(null);

    const DynamicSelectorRenderer = useDummyNetworkProps(setDummyNetworkProperties)

    // Modal
    const [isAdAccountsModalOpen, setIsAdAccountsModalOpen] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [loader, setLoader] = useState(true); // * This loader is for when the view is fetching data from BE

    const { isEdit, setIsEdit } = useContext(EditContext);

    useEffect(() => {
        getDataFile({
            query: 'currencies',
            callback: (res) => {
                const list = res.map(obj => {
                    return {
                        label: obj.currency,
                        value: obj
                    }
                })

                setCurrencyList(list);
            }
        })
    }, [])

    useEffect(() => {
        if (!dummy.websafe) {
            setLoader(true);
            return;
        }

        _D(selectDummyNetwork(
            {
                duConfigId: dummy?.websafe,
                callback: (res) => {
                    setLoader(false);
                }
            }
        ))

        setDummyNetworkProperties(collectProperties(dummy));
    }, [dummy.websafe])

    const handleEdit = () => {
        const { didDummyPropsChanged } = updateDummyNetworkProps(dummyNetworkProperties, dummy);
        if(didDummyPropsChanged) {
            setLoader(true)
            _D(updateLOBDummyProperties(
                {
                    dummy,
                    callback: () => {
                        setLoader(false);
                    },
                    errorCallback: err => {
                        toast.error('Something went wrong fetching data.')
                        setLoader(false);
                    }
                })
            )
        }
        setIsEdit(!isEdit);
    }

    const handleHowItWorksModal = (modalData) => {
        setHowToData(modalData)
        setIsHowToModalOpen(true);
      };

      if (loader) return <Loader size={55}/>;

      return (
        <StyledFieldsWrapper>
            <SubHeader>
                <span>
                    <img src={serveImage(dummy?.logo)} alt='' />
                    Configurations for {dummy?.network} Campaigns
                </span>
                {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>
            <OneThirdGridColContainer>
            <FieldColContainer>
                <DummyNetworkTable
                    title={`${dummy?.network} Ad Accounts`}
                    icon={<img src={serveImage(dummy?.logo)} alt='' />}
                    tableData={currNetworkConfig?.adAccounts}
                    isEdit={isEdit}
                    dummy={dummy}
                    handleOnCreate={() => setIsAdAccountsModalOpen(true)}
                />
            </FieldColContainer>
                  {sortObjectsByName(dummy?.properties).map((prop) => {
                    return (
                        <FieldContainer key={prop.id}>
                            <DynamicSelectorRenderer
                                 isEdit={isEdit}
                                 handleHowItWorksModal={handleHowItWorksModal}
                                 state={dummyNetworkProperties?.[prop.id]?.values}
                                 logo={<img style={{width:'20px'}} src={serveImage(dummy?.logo)} alt='logo'/>}
                                 {...prop}/>
                        </FieldContainer>
                    )
                  })}
            </OneThirdGridColContainer>
            {
                isHowToModalOpen && (
                <HowToSetPropertyModal
                    modalData={howToData}
                    onClose={setIsHowToModalOpen.bind(null, false)}
                />)
            }
            {
                isAdAccountsModalOpen &&
                <AddDummyAdAccountModal
                    onClose={() => setIsAdAccountsModalOpen(false)}
                    currencyList={currencyList}
                    dummy={dummy}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default DummyNetworkConfig;