import React from "react";

import AsyncSelect from "react-select/async";
import { createLocationString } from "../pages/mediaplans/components/utils";
import { makeGETPromiseApiCall } from "../pages/systemConfig/utils";
import { selectTheme } from "./themes";


const promiseOptions = (inputValue, query, forLocations, isBatchCall, label, isMultiQuery, filterOptions, queryText) => {
  if(!inputValue) return
  return new Promise((resolve) => {
    let queryType = '?'
    const queryParam = queryText ? queryText : 'q';
    if (isMultiQuery) queryType = '&'

    makeGETPromiseApiCall(`${query}${queryType}${queryParam}=${inputValue}&limit=${10}`)
      .then((res) => {
        let data;
        if (isBatchCall) {
          data = res?.paginatedData.map(item => ({label: item?.[label], value: item}))
        } else if (forLocations) {
          data = res.map( r => (createLocationString(r)))
        } else {
          data = res.map( r => ({label: r?.name, value: r}))
        }

        resolve(data.filter(filterOptions))
      })
      .catch((err) => {
        console.error(err);
      });
  });
}

const AsyncMultiSelect = ({ query, queryText, localState, defaultOptions, forLocations, isBatchCall, label, isMultiQuery, filterOptions=(() => true)}) => {
   return (
        <AsyncSelect styles={selectTheme} isMulti defaultValue={defaultOptions} defaultOptions loadOptions={(e) => promiseOptions(e, query, forLocations, isBatchCall, label, isMultiQuery, filterOptions, queryText)} onChange={localState} />
   )
}
export default AsyncMultiSelect;
