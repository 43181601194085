import React, { useContext, useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { generateDropdownOptions } from '../../../../../utils/arrays';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const CountryRegionCode = ({}) => {
    const [countryOptions, setCountryOptions] = useState([]);
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { countryCodes } = campaignObj;

    useEffect(() => {
        window.eulerity.makeApiCall('/api/org/getDataFile?filename=countries', 'GET', null, response => {
            const countryOptions = generateDropdownOptions(response, 'code');
            setCountryOptions(countryOptions);
        })
    }, []);

    const getDefaultValues = generateDropdownOptions(countryCodes);

    const handleCountryChange = (selectedOptions) => {
        const selectedCountries = selectedOptions?.map(option => option.value) || [];
        handleUpdate({key: 'countryCodes', value: selectedCountries});
    }

    return (
        <FieldContainer>
            <h3> Country or Region Code(s) </h3>
            {
                isEdit ? (
                    <CreatableSelect
                        isClearable
                        isMulti
                        options={countryOptions}
                        styles={selectTheme}
                        defaultValue={getDefaultValues}
                        onChange={(e => handleCountryChange(e))}
                        placeholder='Select Country or Enter Region Code'
                    />
                ) :
                <p> {countryCodes?.length ? countryCodes.join(', ') : 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default CountryRegionCode;