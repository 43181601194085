export const capitalize = string => {
    if (!string) return;
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()?.replaceAll('_', ' ')
}

export const shortString = (string, length=30) => !string?.length ? "No Template Name" : string?.length > length ? string?.slice(0, length)+"..." : string

export const normalizeUnderscoreString = string => {
    const words = string.split('_');
    const capitalizedWords = words.map(word => capitalize(word));
    return  capitalizedWords.join(' ');
}

export const truncateString = (string, charLimit) => {
    if (!charLimit) return string

    const truncatedString = string.slice(0, charLimit) + '...'
    return truncatedString;
}