import  { useState, useEffect} from 'react';
import { useTheme } from 'styled-components';


const useInputColors = (isDisabled, value) => {

    const theme = useTheme()
    const [shadow, setShadow] = useState("none")
    const [border, setBorder] = useState("1px solid #cccccc")

    useEffect(() => {
        if(isDisabled || value) {
          setShadow("none")
          setBorder("1px solid #cccccc")
          return
        }
        setShadow(`3px 0px 0px ${theme.color.yellow};`)
        setBorder(`1px solid ${theme.color.yellow}`)
        const timeout = setTimeout(() => {
          setShadow("none")
          setBorder("1px solid #cccccc")
        },800)
        return () => {
          clearTimeout(timeout)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isDisabled])


      const alertStyles = {
            boxShadow: shadow,
            border: border,
            transition: "all 0.5s",
      }


      return {alertStyles}
}


export default useInputColors;
