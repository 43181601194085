import React, { useEffect, useMemo, useState } from 'react'
import { StyledInterestContainer, StyledInterestTargetWrapper } from './styles'
import AudienceTargetV2 from './InterestTarget/AudienceTargetV2'
import { BlackBtn } from '../../../../../ui/button'
import { toast } from 'react-toastify'

const InterestTargetStepV2 = ({ audienceDescription, socialLoginId, setIncludedInterestTargets, includedInterestTargets, setExcludedInterestTargets, excludedInterestTargets, count, setCount }) => {
    const [targetType, setTargetType] = useState([])

    const combinedSelection = useMemo(() => {
        const includedTargets = [].concat(...includedInterestTargets)
        return [...includedTargets, ...excludedInterestTargets]
    }, [includedInterestTargets, excludedInterestTargets])

    useEffect(() => {
        window.eulerity.makeApiCall({
            url: `/api/facebook/targetTypes`,
            method: 'GET',
            callback: (response) => {
                setTargetType(response)
            },
            errorCallback: (error) => {
                console.error(error)
                toast.error('Something went wrong fetching Target Types')
            }
        })
    }, [])

    const renderAdditionalAudienceTargets = () => {
        const result = []

        for (let i = 1; i < count; i++) {
            result.push(
                <AudienceTargetV2
                    type='include'
                    text='Include ANY of these Interests'
                    socialLoginId={socialLoginId}
                    selectedTargets={includedInterestTargets}
                    allTargets={combinedSelection}
                    handleAdd={handleAddIncludedTarget}
                    handleRemove={handleRemoveIncludedTarget}
                    targetTypeOptions={targetType}
                    key={i}
                    idx={i}
                />
            )
        }
        return result
    }

    // * INCLUDE FUNCTIONS
    const handleAddIncludedTarget = (target, idx) => {
        const currentTargets = includedInterestTargets

        // * Prevent User from adding same target
        if (combinedSelection.find(tar => tar.id === target.id)) return

        // * If idx already exists add new target to that index
        if (currentTargets[idx]) currentTargets[idx].push(target)
        // * otherwise create a new idx and push the target there
        else currentTargets[idx] = [target]
        setIncludedInterestTargets([...currentTargets])
    }

    const handleRemoveIncludedTarget = (target, idx) => {
        const currentTargets = includedInterestTargets

        const filteredIndex = currentTargets[idx]?.filter(tar => tar.id !== target.id);
        currentTargets[idx] = filteredIndex

        // TODO - FIX THIS -- DELETING ALL TARGETS WON'T REMOVE THE NARROW COMPONENT
        if (!currentTargets[idx]?.length && idx !== 0) currentTargets?.toSpliced(idx, 1)
        setIncludedInterestTargets([...currentTargets])
    }

    // * EXCLUDE FUNCTIONS
    const handleAddExcludedTarget = (target) => {
        const currentTargets = excludedInterestTargets
        // * Prevent Dupes
        if (combinedSelection?.find(tar => tar.id === target.id)) return

        currentTargets.push(target)
        setExcludedInterestTargets([...currentTargets])
    }

    const handleRemoveExcludedTarget = (target) => {
        const filteredTargets = excludedInterestTargets.filter(tar => tar.id !== target?.id)
        setExcludedInterestTargets([...filteredTargets])
    }

    return (
        <StyledInterestTargetWrapper>
            <div className='header-wrapper'>
                <p style={{ alignSelf: 'flex-end' }}>Audience Description: {audienceDescription}</p>
                <p style={{ fontSize: '18px', alignSelf: 'flex-start' }}>Add Facebook Interest Groups to Facebook Adset. Search for interest target or add interest ID Manually</p>
            </div>
            <StyledInterestContainer>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <AudienceTargetV2
                        type='include'
                        text='Include ANY of these Interests'
                        socialLoginId={socialLoginId}
                        selectedTargets={includedInterestTargets}
                        handleAdd={handleAddIncludedTarget}
                        handleRemove={handleRemoveIncludedTarget}
                        allTargets={combinedSelection}
                        idx={0} // * The first selector will always be there
                        targetTypeOptions={targetType}
                    />
                    {
                        renderAdditionalAudienceTargets()
                    }
                </div>
                <AudienceTargetV2
                    type='exclude'
                    text='Exclude ANY of these Interests'
                    socialLoginId={socialLoginId}
                    selectedTargets={excludedInterestTargets}
                    handleAdd={handleAddExcludedTarget}
                    handleRemove={handleRemoveExcludedTarget}
                    allTargets={combinedSelection}
                    targetTypeOptions={targetType}
                    isExclude
                />
            </StyledInterestContainer>
            {
                includedInterestTargets?.length > 0 &&
                <div style={{ margin: '10px 0' }}>
                    <BlackBtn onClick={() => setCount(count + 1)}> Narrow your Audience (AND) </BlackBtn>
                </div>
            }
        </StyledInterestTargetWrapper>
    )
}

export default InterestTargetStepV2