import styled from "styled-components";

export const StyledInputWrapper = styled.div`
    height: 45px;
    width: ${props => props.inputWidth};
    padding: 12px;
    gap: 12px;
    display: flex;
    align-items: center;
    border: 1px solid ${props => props.theme.color.gray};
    border-radius: 6px;
    background: ${props => props?.isDisabled && 'hsl(0, 0%, 90%)'};

    svg {
        color: ${props => props.theme.color.yellow};
    }
`

export const StyledSearchInput = styled.input`
    height: 24px;
    border: none;
    outline: none;
    font-family: 'GothamBook';
    flex: 1;
    background: ${props => props.isDisabled && 'hsl(0, 0%, 90%)'};
`