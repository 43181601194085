import React from 'react'
import { StyledAttributeContainer, StyledAttributeStep } from './styles'
import { BtnWithLoader, OutlineBtnLg } from '../../../../../../../ui/button'
import { ButtonWrapper } from '../../../../../../../components/Modal/modals/styles'
import { useState } from 'react'
import LinkedInAttributeModal from './LinkedInAttributeModal'
import { useContext } from 'react'
import { LinkedInAudienceContext } from './LinkedAudienceModal'
import AttributeGroup from './components/AttributeGroup'
import useLinkedInTargets from '../../hooks/useLinkedInTargets'
import { getFacetCategory } from './linkedInTargets.utils'

export const LinkedInTargetContext = React.createContext()

const LinkedInAttributesStep = () => {
  const { audienceDesc, selectedAttributes, setSelectedAttributes } = useContext(LinkedInAudienceContext)

  const [isAttributeModalOpen, setIsAttributeModalOpen] = useState(false)
  const [isAddMore, setIsAddMore] = useState(false) // * This flag will control if we will run addToExistingGroup over addNewGroup
  const [loader, setLoader] = useState(false)
  const [groupIndex, setGroupIndex] = useState(null)
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')

  // * Hooks
  const { getLinkedInFacets, linkedInTargetFacets } = useLinkedInTargets({ selectedCategory: category })

  const handleOnClose = () => {
    setIsAttributeModalOpen(false)
    // * Reset onAdd
    setIsAddMore(false)
    setGroupIndex(null)
    setSubCategory('')
    setCategory('')
  }

  const addNewGroup = (newGroup) => {
    setSelectedAttributes((prev) => [...prev, newGroup])
    handleOnClose()
  }

  const addToExistingGroup = (updatedGroup, index) => {
    setSelectedAttributes((prev) => {
      const currentValues = [...prev]
      const indexValues = currentValues[index]
      currentValues[index] = [...indexValues, ...updatedGroup]
      return currentValues
    })
    handleOnClose()
  }

  // * Context Object
  const targetContextValues = {
    linkedInTargetFacets,
    category,
    subCategory,
    isAddMore,
    groupIndex,
    handleOnClose,
    handleOnSubmit: isAddMore ? addToExistingGroup : addNewGroup
  }

  return (
    <StyledAttributeStep>
      <div className='header'>
        <p style={{ marginLeft: 'auto' }}>Audience Description: {audienceDesc?.value}</p>
        <p>Your LinkedIn target audience will have ANY of the following attributes:</p>
        <p><strong>Note</strong> that there are some targeting categories that can’t be narrowed or AND’d with other targeting categories. If you would like to know more information please click <a target='_blank' rel="noreferrer" href='https://learn.microsoft.com/en-us/linkedin/shared/references/v2/ads/targeting-criteria-facet-urns'>here</a>.</p>
      </div>
      {!selectedAttributes?.length &&
        <div className='attribute-wrap'>
          <p style={{ fontSize: '20px' }}>You currently have no LinkedIn Audience attributes set for this Target Audience.</p>
          <ButtonWrapper>
            <BtnWithLoader
              btnText='Add Audience Attributes'
              onClick={() => {
                setLoader(true)
                getLinkedInFacets(() => {
                  setIsAttributeModalOpen(true)
                  setLoader(false)
                })
              }}
              loader={loader}
            />
          </ButtonWrapper>
        </div>
      }
      {
        selectedAttributes.length > 0 &&
        selectedAttributes.map((attributeGroup, idx) => {
          return <StyledAttributeContainer key={idx}>
            {idx > 0 && <p style={{ marginBottom: '16px' }}><strong>AND</strong> also have <strong>ANY</strong> of the following attributes:</p>}
            <AttributeGroup
              attributeGroup={attributeGroup}
              onAdd={() => {
                getLinkedInFacets(() => {
                  setIsAttributeModalOpen(true)
                  setIsAddMore(true)
                  setGroupIndex(idx)
                })
              }}
              onAddSpecific={(targetGroup) => {
                const categoryFromUrn = getFacetCategory(targetGroup?.targets[0]?.facetUrn)
                getLinkedInFacets(() => {
                  setIsAttributeModalOpen(true)
                  setIsAddMore(true)
                  setGroupIndex(idx)
                  setCategory({label: categoryFromUrn, value: categoryFromUrn} || '')
                  setSubCategory(targetGroup?.name || '')
                })
              }}
              targetIndex={idx}
            />
          </StyledAttributeContainer>
        })
      }
      {
        selectedAttributes?.length > 0 && <ButtonWrapper style={{ marginTop: '10px' }}>
          <OutlineBtnLg style={{ marginLeft: 'auto' }} onClick={() => {
            getLinkedInFacets(() => {
              setIsAttributeModalOpen(true)
            })
          }}>Narrow Your Target Audience</OutlineBtnLg>
        </ButtonWrapper>
      }
      {
        isAttributeModalOpen &&
        <LinkedInTargetContext.Provider value={targetContextValues}>
          <LinkedInAttributeModal />
        </LinkedInTargetContext.Provider>
      }
    </StyledAttributeStep>
  )
}

export default LinkedInAttributesStep