import styled from "styled-components";

export const StyledAudienceTarget = styled.div`
    display: flex;
    width: 500px;
    min-height: 278px;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border: 1px solid ${props => props.type === 'include' ? props.theme.color.green : props.theme.color.red};
    border-radius: 6px;
`

export const StyledItemContainer = styled.div`
    height: 160px;
    width: 460px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: scroll;
`

export const StyledTargetItem = styled.div`
    height: 44px;
    display: flex;
    align-items: center;
    background: ${props => props.theme.background.lightGray};
    padding: 4px;

    .item-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    svg {
        margin-left: auto;
        cursor: pointer;
    }
`

export const StyledInputContainer = styled.div`
    display: flex;
    gap: 4px;
`

export const StyledResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .styled-results {
        padding: 8px;
        width: 454px;
        max-height: 234px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        border: 1px solid ${props => props.theme.color.gray};
        border-radius: 6px;
        gap: 8px;
    }
`

export const StyledResultItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 18px;
    font-size: 12px;
    width: 434px;
    border: 1px solid ${props => props.theme.color.yellow};
    border-radius: 4px;

    &:hover {
        background: ${props => !props.isSelected && props.theme.background.lightOrange};
    }

    // * isSelected
    cursor: ${props => props.isSelected ? 'not-allowed' : 'pointer'};
    background: ${props => props.isSelected && props.theme.background.gray};
    opacity: ${props => props?.isSelected && '0.5'};
`

export const CustomSelectOption = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 4px;
    cursor: pointer;
    background: ${props => props?.isSelected && props.theme.color.darkYellow};

    &:hover {
        background: ${props => props.theme.color.mediumYellow};
    }
`