import React, { useState, useEffect } from 'react'
import CurrencyInput from '../../../../../../../ui/input/fields/CurrencyInput'
import Select from 'react-select'
import Information from '../../../../../../../ui/tooltip/Information';

// Redux
import { queryOrg } from '../../../../../../../actions/lobActions';

// Styles
import { StyledAccountInfo, StyledBiddingStepWrapper, StyledFieldWrapper, StyledInfoWrapper, StyledLineBreak } from './styles'
import { selectTheme } from '../../../../../../../ui/themes'

const LinkedInBiddingStep = ({ setCostPerGoal, setManualBidding, setInsightTag, selectedPage, insightOptions }) => {
    const [localizedName, setLocalizedName] = useState('N/A');

    useEffect(() => {
        const reference = selectedPage.reference;

        if (!reference) return;

        queryOrg({
            reference: reference,
            callback: (res) => {
                setLocalizedName(res?.localizedName)
            }
        })
    }, [])

    return (
        <StyledBiddingStepWrapper>
            <p>Enter and/or select values specific to this ad account.</p>
            <StyledAccountInfo>
                <p>Ad Account: {selectedPage?.name}</p>
                <p>ID: {selectedPage?.id}</p>
                <p style={{ display: 'flex' }}> Page<Information text='A page is required to launch a Leads Generation Campaign' />: { localizedName } </p>
            </StyledAccountInfo>
            <StyledInfoWrapper>
                <div className='field-group'>
                    <StyledFieldWrapper>
                        <p>Cost Per Result Goal</p>
                        <CurrencyInput
                            currency={'USD'}
                            onChange={(e) => setCostPerGoal(e.target.value)}
                        />
                    </StyledFieldWrapper>
                    <StyledFieldWrapper>
                        <p>Manual Bidding</p>
                        <CurrencyInput
                            currency={'USD'}
                            onChange={(e) => setManualBidding(e.target.value)}
                        />
                    </StyledFieldWrapper>
                </div>
                <StyledLineBreak />
                <div>
                    <p style={{ marginBottom: '18px' }}>Select a insight tag for this ad account</p>
                    <StyledFieldWrapper>
                        <p>Insight Tag</p>
                        <Select
                            isClearable
                            isSearchable={false}
                            styles={selectTheme}
                            placeholder={'Select a Insight Type'}
                            onChange={(e) => setInsightTag(e)}
                            options={insightOptions}
                        />
                    </StyledFieldWrapper>
                </div>
            </StyledInfoWrapper>
        </StyledBiddingStepWrapper>
    )
}

export default LinkedInBiddingStep