import styled from 'styled-components';

// Meta Data
export const MetaDataContainer = styled.div`
    padding: 20px;
    border-radius: 9px;
    background-color: ${props => props.theme.background.lightGray};

    .edit {
        text-decoration: underline;
        color: ${props => props.theme.card.gray};
        cursor: pointer;
    }

    .tooltip {
        font-family: 'GothamBook';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        background: white;
        color: black;
        padding: 15px;
        border: 1px solid black !important;
        max-width: 300px;
    }

    .arrow {
        border-bottom: 1px solid black !important;
        border-right: 1px solid black !important;
    }
`;

export const MetaDataContent = styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 4fr;

    img {
        width: 80px;
    }

    a {
        text-decoration: underline;
        cursor: pointer;
        color: ${props => props.theme.color.yellow} !important;
    }

    .network_data_container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        h2 {
            font-size: 18px;
        }

        div {
            display: flex;
            align-items: center;

            svg {
                font-size: 24px;
                margin-bottom: 5px;
            }
        }
    }

    .bulk_upload {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 5px;

        p {
            width: 100%;
        }
    }
`;

// Properties
export const PropertiesContainer = styled.div`
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(5, minmax(200px, 1fr)); */
    grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 2fr;
    overflow: scroll;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: ${({ index }) => (index % 2 === 0 ? '#FAFAFA' : '#FFFFFF')};
`;

export const PropertyHeader = styled.h2`
    font-family: 'GothamMedium';
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
`;

export const NoPropterties = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PropertyValue = styled.li`
    text-decoration: none;
    font-size: 16px;
    font-family: 'GothamBook';
    font-weight: 400;
`;

export const PropertyValueContainer = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;

    li {
        list-style-type: disc;
        text-decoration: underline;
        padding: 3px;
        cursor: pointer;
    }

    .edit_archive {
        display: flex;
        align-items: center;
        font-size: 20px;
        gap: 5px;

        svg {
            cursor: pointer;
        }
    }
`;

// How to set property Modal
export const HowToSetPropertyModalContainer = styled.div`
    background-color: ${props => props.theme.background.lightGray};
    padding: 20px;
    margin: 10px auto;

    h1 {
        font-family: 'GothamMedium';
        font-weight: 500;
        margin: 10px 0;
    }

    h2 {
        color: ${props => props.theme.card.gray};
        margin-bottom: 20px;
    }

    h1, h2, p {
        font-size: 16px !important;
    }

    h2, p {
        font-family: 'GothamBook' !important;
        font-weight: 400 !important;
    }
`;