import React, { useEffect, useState } from "react";
import { StyleSystemAccountsSelector } from "../styles";
import { HiOutlineRefresh, HiOutlineTrash } from "react-icons/hi";
import CheckBox from "../../../../../ui/input/checkbox/CheckBox";
import { getSystemAccount, makeGETPromiseApiCall, makePOSTPromiseApiCall, oAuthGetSocialLoginMethod } from "../../../utils";
import { toast } from "react-toastify";
import Loader from "../../../../../ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setSocialLogins } from "../../../../../actions/systemConfigActions";
import { setOrganization } from "../../../../../actions/orgAction";
import useOauth from "../../../../../hooks/useOauth";

const SystemAccountsSelector = ({ handleOnSelect, accountData, selectedAccount, onClose, setProcessing }) => {

  let network = accountData?.network;
  const socialLogins = useSelector(state => state.system.socialLogins)
  const { onFocusBack, handleOAuth } = useOauth(network);

  const isAdAccount = accountData.isAdAccount;
  const isSelected = isAdAccount ? selectedAccount?.accountId === accountData?.accountId : selectedAccount?.id === accountData?.id;
  const orgData = useSelector(state => state.org.organization);
  const [loader, setLoader] = useState(false);

  let name = accountData?.displayName || "N/A";

  const _D = useDispatch();

  useEffect(() => {
    if (onFocusBack) {
      oAuthGetSocialLoginMethod('/api/user/getSocialLogins').then(response => {
          if(socialLogins.length !== response.length)   _D(setSocialLogins(response));
      }).catch(_ => {
          toast.error("Something went wrong")
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFocusBack]);


  const handleDelete = async (event) => {
    event.stopPropagation();
    const currentSysAccount = getSystemAccount(orgData, selectedAccount.network);
    try {
        if(currentSysAccount?.id === accountData?.id) {
            toast.error(`${currentSysAccount.displayName} of System Account cannot be deleted here as it is already being used on the organization.`)
            return
        }
        setLoader(true);
        setProcessing(true);
      await makePOSTPromiseApiCall(`/api/user/deleteSocialLogin?socialLoginId=${accountData?.id}`);
      const refreshedSocialLogins = await makeGETPromiseApiCall("/api/user/getSocialLogins");
        _D(setSocialLogins(refreshedSocialLogins));
      const noConnectedAccounts = refreshedSocialLogins.filter((it) => it.network === network)?.length;
      if (!noConnectedAccounts) onClose();

      const newOrgData = await makeGETPromiseApiCall("/api/org/get");
        _D(setOrganization(newOrgData));

    } catch (error) {
        toast.error("Something whent wrong!");
    } finally {
        setProcessing(false);
        setLoader(false);
    }
  };

  if (loader) {
    return (
      <StyleSystemAccountsSelector isSelected={isSelected} style={{ gridTemplateColumns: "1fr" }}>
        <Loader />
      </StyleSystemAccountsSelector>
    );
  }

  return (
    <StyleSystemAccountsSelector isSelected={isSelected} onClick={handleOnSelect.bind(null, accountData)}>
      <div className="sysAccount-wrapper">
        <p className="sysAccount-name">{name}</p>
        { isAdAccount ? (<p className="sysAccount-account">{`Ad Account ID:${accountData?.accountId}`}</p>) : (<p className="sysAccount-account">{`Account ID:${accountData?.id}`}</p>)}
      </div>

      <div className="sysAccount-icon-wrapper">
        {!isAdAccount && (
          <div className="icon-holder trash" onClick={handleDelete}>
            <HiOutlineTrash color="red" />
          </div>
        )}
        {!isAdAccount && (
          <div onClick={handleOAuth} className="icon-holder refresh">
            <HiOutlineRefresh />
          </div>
        )}
      </div>
      {<div className="checkbox-wrapper"><CheckBox checked={true} notClickable /></div> }
    </StyleSystemAccountsSelector>
  );
};

export default SystemAccountsSelector;
