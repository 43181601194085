import React from 'react';
import CampaignGroupName from './CampaignGroupName';
import BudgetSchedule from './BudgetSchedule';

// Hooks
import useLinkedInCampaignGroup from '../hooks/useLinkedInCampaignGroup';
import StartDate from './StartDate';
import EndDate from './EndDate';
import LifeTimeBudget from './LifeTimeBudget';

// Styles
import { ColumnHeader, SubHeader } from '../../../../../ui/headers';
import { OneThirdGridColContainer } from '../../../../../ui/containers';
import { EditSaveBtn } from '../../../../../ui/button';

export const LinkedInCampaignContext = React.createContext();

const LinkedInCampaignGroupSetup = ({}) => {
    const linkedInCampaign = useLinkedInCampaignGroup({});

    const campaignContextValues = {
        ...linkedInCampaign,
    }

    const {isEdit, handleEdit} = linkedInCampaign;

    return (
        <LinkedInCampaignContext.Provider value={campaignContextValues}>
            <SubHeader>
                <span /> {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>
            <ColumnHeader> Campaign Group Details </ColumnHeader>
            <OneThirdGridColContainer>
                <CampaignGroupName />
                <BudgetSchedule />
                <StartDate />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <EndDate />
                <LifeTimeBudget />
            </OneThirdGridColContainer>
        </LinkedInCampaignContext.Provider>
    )
}

export default LinkedInCampaignGroupSetup;