import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import FbIcon from '../../../../assets/images/socialMediaIcons/FbIcon.png';
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { FiPlusCircle } from 'react-icons/fi';
import DeleteTargetingModal from '../modal/DeleteTargetingModal';
import { updateNetworkConfig } from '../../../../actions/lobActions';
import { toast } from 'react-toastify';

const FacebookAdSetTable = ({ tableData, isEdit, handleOnCreate, handleOnEdit, fbConfigId }) => {
    const _D = useDispatch();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState();
    const [loader, setLoader] = useState(false)

    const facebookIcon = <img src={FbIcon} alt='img' />;

    // * Handle Item Selection - depending on the action type this will either edit/delete the item
    const handleItemSelection = (data) => {
        setSelectedItem(data)
        setIsDeleteModalOpen(true)
    }

    const deleteAudienceTargets = () => {
        const updatedAdSets = tableData.filter(item => item !== tableData[selectedItem])
        setLoader(true)
        _D(updateNetworkConfig({
            obj: { audienceTargets: updatedAdSets },
            socialConfig: `fbconfig=${fbConfigId}`,
            callback: () => {
                setLoader(false)
                setIsDeleteModalOpen(false)
                toast.success('Adset Deleted');
            }
        }))
    }

    const renderData = () => {
        const list = []

        for (const item in tableData) {
            list.push(
                <li key={item}>
                    <ListObj>
                        <span style={{fontFamily: 'GothamBold'}}>{tableData[item]?.name}</span>
                        <span>{tableData[item]?.description}</span>
                    </ListObj>
                    <div className='opt__button__container'>
                        {isEdit && <BiPencil onClick={() => handleOnEdit(tableData[item])} />}
                        {isEdit && <BiTrash onClick={() => handleItemSelection(item)} />}
                    </div>
                </li>
            )
        }
        return list
    }

    return (
        <TableContainer>
            <TableHeader>
                <h3> {facebookIcon} Facebook Adsets</h3>
                {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
            </TableHeader>

            {
                Object.keys(tableData || {})?.length > 0 ?
                    renderData() :
                    <EmptyDataContainer>
                        <p> No Facebook Adsets Available </p>
                    </EmptyDataContainer>
            }

            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={deleteAudienceTargets}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title='Facebook Adsets'
                    item={tableData[selectedItem]}
                    useLoader
                    loading={loader}
                />
            }
        </TableContainer>
    )
}

export default FacebookAdSetTable