import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledToast = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
 })`

  /* Toast Container */
  width: 60%;
  border-radius: 9px;
  font-size: 16px;

  /* Toast Close Btn */
  button[aria-label="close"] {
    color:${props => props.theme.color.black};
    display: flex;
    align-self: center;
    svg{
      height: 25px;
      width: 25px;
    }
  }

  /* Toast Primary -> Default styles (info)*/
  .Toastify__toast {
    background: ${props => props.theme.toast.primary};
    color:${props => props.theme.color.black};
    .progress{
      background:${props => props.theme.color.icon};
    }
  }

  .Toastify__toast--success {
    background:${props => props.theme.toast.success};
    color:${props => props.theme.color.black};
    .progress{
      background:${props => props.theme.color.primaryGreen};
    }
  }

  .Toastify__toast--warning {
    background: ${props => props.theme.toast.warning};
    color:${props => props.theme.color.black};
    .progress{
      background:${props => props.theme.color.primaryOrange};
    }
  }

  .Toastify__toast--error {
    background: ${props => props.theme.toast.error};
    color:${props => props.theme.color.black};
    .progress{
      background:${props => props.theme.color.primaryRed};
    }
  }
`;

export { StyledToast }