import React from 'react';
import Information from '../../../../../ui/tooltip/Information';

// Utils
import { serveImage } from '../../labelManagement/label.utils';

// Styles
import { PageHeaderContainer } from '../../../../../ui/headers';
import { MetaDataContainer, MetaDataContent } from '../styles';

const MetaData = ({network, isDummy, isEdit, abbreviation, logo, setIsEditNetworkMetaDataModalOpen, bulkUploadSheet, bulkUploadUrl}) => {
    return (
        <MetaDataContainer>
            <PageHeaderContainer>
                <h2> Meta Data </h2>
                {isEdit && <h2 className='edit' onClick={() => setIsEditNetworkMetaDataModalOpen(true)}> Edit Meta Data </h2>}
            </PageHeaderContainer>

            <MetaDataContent>
                <img src={isDummy ? serveImage(logo) : network.logo} alt='logo' />

                <div className='network_data_container'>
                    <h2> Network Name: {network?.name} </h2>
                    <div>
                        <p> Abbreviation: {abbreviation || 'N/A'} </p>
                        <Information text='Abbreviation is used for creating naming convention' />
                    </div>

                    {isDummy && (bulkUploadSheet || bulkUploadUrl) &&
                    <div>
                        <div className='bulk_upload'>
                        {bulkUploadSheet?.filename && <>
                            <p> Sample Bulk Upload Sheet:  </p>
                            <a href={serveImage(bulkUploadSheet?.blobkey)} download> {bulkUploadSheet?.filename} </a>
                        </>}
                        {bulkUploadUrl && <p> Bulk Upload URL: {bulkUploadUrl} </p>}
                        </div>
                        <Information text='The sample bulk upload sheet is used as a reference to build campaigns for this network' />
                    </div>
                    }
                </div>
            </MetaDataContent>
        </MetaDataContainer>
    )
}

export default MetaData;