import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import CheckBox from '../../../../ui/input/checkbox/CheckBox'

const AudienceTargetting = ({googleIcon, isEdit, audienceTargeting, setAudienceTargeting}) => {
    return (
        <FieldContainer>
            <h3> {googleIcon} Audience Targeting Settings </h3>
            <CheckBox
                checked={audienceTargeting}
                callback={() => setAudienceTargeting(!audienceTargeting)}
                isDisabled={!isEdit}
                label={'Target'}
            />
        </FieldContainer>
    )
}

export default AudienceTargetting