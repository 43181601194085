import React, { useState } from 'react'
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from '../../../tables/styles'
import { FiPlusCircle } from 'react-icons/fi'
import { BiPencil, BiTrash } from 'react-icons/bi'
import DeleteTargetingModal from '../../../modal/DeleteTargetingModal'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { updateNetworkConfig } from '../../../../../../actions/lobActions'
import LinkedAudienceModal from '../modals/linkedinAudience/LinkedAudienceModal'

const LinkedInAudienceTable = ({ isEdit, liAudiences, setLiAudiences, liConfigId, adAccounts }) => {
    const _D = useDispatch()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isAudienceModalOpen, setIsAudienceModalOpen] = useState(false)
    const [isEditingAudience, setIsEditingAudience] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [loader, setLoader] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false) // * Controls copy text

    const handleOnClose = () => {
        setIsAudienceModalOpen(false)
        setIsEditingAudience(false) // * We always want to reset the edit logic when table closes
        setSelectedIndex(null) // * Reset select index
        setIsEditMode(false) // * Reset edit mode
    }

    // * Handle Delete
    const deleteAudienceTarget = () => {
        const updatedAudiences = liAudiences.filter(item => item !== liAudiences[selectedIndex])
        setLoader(true)
        _D(updateNetworkConfig({
            socialConfig: `liconfig=${liConfigId}`,
            obj: { audienceTargets: updatedAudiences },
            callback: () => {
                toast.success('Deleted LinkedIn Audience');
                setIsDeleteModalOpen(false)
                setLoader(false)
            }
        }))
    }

    return (
        <>
            <TableContainer>
                <TableHeader>
                    <h3>LinkedIn Audiences (Campaign)</h3>
                    {isEdit && <FiPlusCircle onClick={() => setIsAudienceModalOpen(true)} />}
                </TableHeader>
                {
                    Object.keys(liAudiences || {})?.length > 0 ?
                        <ul>
                            {
                                liAudiences?.map((audience, idx) => {
                                    return <li key={idx}>
                                        <ListObj style={{ gap: '6px' }}>
                                            <span><strong>Name</strong>: {audience?.name}</span>
                                            <span><strong>Target Type</strong>: {audience?.type}</span>
                                            <span><strong>Audience Description</strong>: {audience?.description}</span>
                                        </ListObj>
                                        <div className='opt__button__container'>
                                            {isEdit &&
                                                <BiPencil onClick={() => {
                                                    setSelectedIndex(idx)
                                                    setIsEditingAudience(true)
                                                    setIsAudienceModalOpen(true)
                                                    setIsEditMode(true)
                                                }} />
                                            }
                                            {isEdit &&
                                                <BiTrash onClick={() => {
                                                    setSelectedIndex(idx)
                                                    setIsDeleteModalOpen(true)
                                                }} />
                                            }
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                        :
                        <EmptyDataContainer>
                            <p> No Audiences Available </p>
                        </EmptyDataContainer>
                }
            </TableContainer>
            {
                isAudienceModalOpen &&
                <LinkedAudienceModal
                    onClose={handleOnClose}
                    adAccounts={adAccounts}
                    setData={setLiAudiences}
                    liAudiences={liAudiences}
                    configId={liConfigId}
                    isEditingAudience={isEditingAudience}
                    selectedAudience={liAudiences[selectedIndex]}
                    selectedIndex={selectedIndex}
                    isEditMode={isEditMode}
                />
            }
            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={deleteAudienceTarget}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title='LinkedIn Audience Target'
                    item={liAudiences[selectedIndex]}
                    useLoader
                    loading={loader}
                />
            }
        </>
    )
}

export default LinkedInAudienceTable