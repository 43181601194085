import React from 'react'
import Select from 'react-select'
import { FieldContainer } from '../../../../ui/containers'
import { selectTheme } from '../../../../ui/themes'

const CampaignObjective = ({ isEdit, googleIcon, objectiveOptions, defaultValue, objective, setObjective }) => {
    return (
        <FieldContainer>
            <h3>{googleIcon} Objective</h3>
            {
                isEdit ?
                    <Select
                        isClearable
                        options={objectiveOptions}
                        defaultValue={{
                            label: defaultValue,
                            value: defaultValue
                        }}
                        styles={selectTheme}
                        onChange={e => setObjective(e.label)}
                    />
                    :
                    <p> {objective ? objective : 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default CampaignObjective