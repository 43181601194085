import React, { useState } from "react";
import User from "./User";

// Redux
import { addUserToAdvertiser } from "../../../../actions/systemConfigActions";

// Styles
import { toast } from "react-toastify";
import { EditSaveBtn } from "../../../../ui/button";
import { StyleHeaderContainer } from "../../../../ui/containers";
import { PageHeader, SubHeader } from "../../../../ui/headers";
import { StyleUserManagment, StyleUserManagmentContent } from "./styles";
import { useSelector } from "react-redux";


export const UserManagment = ({ users }) => {
  const advertiserList = useSelector(state => state.org.advertisers) || []

  const [isEdit, setIsEdit] = useState(false);
  const [addAdvertiserArray, setAddAdvertiserArray] = useState([]);
  const [advertiserLoader, setAdvertiserLoader] = useState(false);
  const [advertisersToAdd, setAdvertisersToAdd] = useState([]);
  const [advertisersToRemove, setAdvertisersToRemove] = useState([]);


  const handleEdit = () => {
    if (isEdit) {
      const promises = [];

      if (addAdvertiserArray.length > 0) {
        for (const advertiser in addAdvertiserArray) {
          const curr = addAdvertiserArray[advertiser];
          promises.push(
            addUserToAdvertiser({
              uid: curr.user,
              websafe: curr.advertiser
            })
          )
        }
      }


      if (promises.length) {
        Promise.all(promises).then((data) => {
          toast.success('Successfully saved');
        })
      }
    }

    setIsEdit((prev) => !prev);
  };

  return (
    <StyleUserManagment>
      <StyleHeaderContainer>
        <PageHeader>
          <p className="action-header">{isEdit && <span className="action-header-span">Editing </span>}User Management</p>
        </PageHeader>
        <SubHeader>
          <div className="action-name">User Access</div>
          <div>{EditSaveBtn(isEdit, handleEdit)}</div>
        </SubHeader>
      </StyleHeaderContainer>
      <StyleUserManagmentContent className="for-header">
        <div>Name</div>
        <div>Email</div>
        <div>Role</div>
        <div>Advertiser</div>
        <div>Access</div>
      </StyleUserManagmentContent>

      {users.map((user, index) =>
         <User
            key={user?.id}
            applyClass={index % 2 !== 0}
            user={user} isEdit={isEdit}
            addAdvertiserArray={addAdvertiserArray}
            setAddAdvertiserArray={setAddAdvertiserArray}
            advertiserOptions={advertiserList.map(it => ({label: it.name, value: it}))}
            advertiserLoader={advertiserLoader}
            setAdvertiserLoader={setAdvertiserLoader}
            advertisersToAdd={advertisersToAdd}
            setAdvertisersToAdd={setAdvertisersToAdd}
            advertisersToRemove={advertisersToRemove}
            setAdvertisersToRemove={setAdvertisersToRemove}
          />
      )}
    </StyleUserManagment>
  );
};

export default UserManagment;
