import React from 'react'
import styled from 'styled-components';


const StyleCenterElement = styled.div`
    width: ${props => props?.width ? props?.width : '100vw'};
    height:${props => props?.height ? props?.height : '50vh'};
    display: grid;
    place-items: center;
`

const CenterElement = (props) => {
   return (
        <StyleCenterElement height={props?.height} width={props?.width} >
            {props.children}
        </StyleCenterElement>
   )

}
export default CenterElement