import React, { useState, useEffect } from 'react';
import Search from '../../../../ui/search';
import { isObjArraysEqual } from '../../../../utils/arrays';

// Styles
import { ModalTextSecondary, SearchBarContainer, SelectListContainer, SelectListItem, LocationWrapper } from '../../../../components/Modal/styles';
import { BtnWithLoader } from '../../../../ui/button';
import { OutlineBtn } from '../../../../ui/button';
import { TableModalContainer } from '../../../../ui/table/styles';
import { toast } from 'react-toastify';
import Loader from '../../../../ui/Loader';
import Modal from '../../../../components/Modal/modals/Modal';

const LocationModal = ({onClose, handleTargetUpdate, locationTargets}) => {
    const [loader, setLoader] = useState(false);
    const [isQueryLoading, setIsQueryLoading] = useState(false);
    const [locationsList, setLocationsList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        setSelectedList([...locationTargets]);
    }, []);

    useEffect(() => {
        const list = [];

        if (!searchFilter) return;
        setIsQueryLoading(true);

        window.eulerity.makeBatchedApiCall({
            url: `/api/lob/locationTargets?q=${searchFilter}`,
            dataCallback: (resp) => {
                resp.forEach(c => {list.push(c)})
            },
            doneCallback: () => {
                setIsQueryLoading(false);
                setLocationsList(list);
            }
        })
    }, [searchFilter]);

    const handleSave = () => {
        if (isObjArraysEqual(selectedList, locationTargets)) return toast.error('No changes detected')
        setLoader(true)
        handleTargetUpdate({locationTargets: [...selectedList]}, () => {
            onClose();
            setLoader(false)
            toast.success('Location Target(s) Updated')
        });
    }

    const handleFilter = (e) => {
        setTimeout(() => setSearchFilter(e.target.value), 1000);
    }

    const renderSelectedLocations = () => {
        if (!locationTargets && !selectedList.length) return <p className='result__text'> No Locations Selected </p>
        return selectedList?.map(location => {
            const { id, canonicalName, type } = location;
            const newSelectedArr = selectedList.filter(l => l.id !== id);
            return (
                <SelectListItem key={id} onClick={() => setSelectedList(newSelectedArr)}>
                    <li> NAME: {canonicalName} </li>
                    <li> TYPE: {type} </li>
                </SelectListItem>
            )
        })
    }

    const renderSearchResults = () => {
        if (isQueryLoading) return <Loader />;
        if (!locationsList.length) return <p className='result__text'> No Search Results </p>;

        return locationsList.map(location => {
            const { id, canonicalName, type } = location;
            const isSelected = selectedList.find(l => l.id === id);

            return (
                <SelectListItem key={id} onClick={() => setSelectedList([...selectedList, location])} isSelected={isSelected}>
                    <li> NAME: {canonicalName} </li>
                    <li> TYPE: {type} </li>
                </SelectListItem>
            )
        })
    }

    return (
        <Modal
            header='Add Location Targeting'
            buttons={[<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>,
                    <BtnWithLoader btnText='Add Locations(s)' loader={loader} onClick={handleSave} type="blackBtn" />
                ]}
            onClose={onClose}
        >
            <TableModalContainer>
                <SearchBarContainer>
                    <Search text='Search By Location' handleFilter={handleFilter} />
                </SearchBarContainer>

                <LocationWrapper>
                    <div className='search__result__wrapper'>
                        <ModalTextSecondary> Search Results: </ModalTextSecondary>
                        <SelectListContainer>
                            {renderSearchResults()}
                        </SelectListContainer>
                    </div>
                    <div className='selected_locations__wrapper'>
                    <ModalTextSecondary> Selected Locations: </ModalTextSecondary>
                    <SelectListContainer>
                        {renderSelectedLocations()}
                    </SelectListContainer>
                    </div>
                </LocationWrapper>
            </TableModalContainer>
        </Modal>
    )
}

export default LocationModal;