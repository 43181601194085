import React, { useContext, useMemo } from 'react';
import Select from 'react-select';
import { capitalize } from '../../../../../utils/strings';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { TableModalContainer } from '../../../../../ui/table/styles';
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { selectThemeModal } from '../../../../../ui/themes';
import { ChannelSetUpContext } from './ChannelSetupModal';

const SelectNetwork = () => {
    const { channel, handleSelectChannel, lob } = useContext(ChannelSetUpContext)

    const dummyNetworks = useSelector(state => state.org.organization?.dummyNetworks);
    const hasLiSupport = lob?.networkConfigs.some(config => config.keyParam === 'liconfig')

    const channelOptions = useMemo(() => {
        const options =
            [
                { label: 'Google', value: 'google' },
                { label: 'Facebook', value: 'facebook' },
                { label: 'LinkedIn', value: 'linkedin' }
            ]

        if (dummyNetworks?.length) {
            dummyNetworks?.forEach((dummyNetwork) => {
                const networkName = dummyNetwork?.name
                const networkId = dummyNetwork?.websafe

                options.push(
                    {
                        label: networkName,
                        value: networkName,
                        websafe: networkId
                    }
                )
            })
        }

        return options
    }, [dummyNetworks, hasLiSupport])

    return (
        <TableModalContainer>
            <ModalTextSecondary> Select Network <span className='asterisk'>*</span></ModalTextSecondary>

            <Select
                isClearable
                options={channelOptions}
                styles={selectThemeModal}
                placeholder={capitalize(channel) || 'Select a network'}
                onChange={e => handleSelectChannel(e)}
            />
        </TableModalContainer>
    )
}
export default SelectNetwork;