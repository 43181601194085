import React from 'react';
import { FiPercent } from 'react-icons/fi';
import styled from 'styled-components';
import { Input } from '../input';

const PercentInput = ({value, onChange, min, max}) => {

  return (
    <InputWrapper>
      <Input type='number' value={value} onChange={onChange} min={min} max={max} id='myNumberInput'/>
      <FiPercent className='icon'/>
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  #myNumberInput::-webkit-inner-spin-button,
  #myNumberInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  .icon {
    position: absolute;
    top: 12px;
    right: 10px;
  }
`

export default PercentInput