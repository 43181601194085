import GoogleIcon from "../assets/images/socialMediaIcons/GoogleIcon.png";
import FbIcon from "../assets/images/socialMediaIcons/FbIcon.png";
import linkedin from "../assets/images/socialMediaIcons/linkedin.webp";
import styled from "styled-components";
import { IoWarningOutline } from "react-icons/io5";

export const GoogleI = ({ size = "20px" }) => <img style={{ width: size, height: size }} src={GoogleIcon} alt="img" />;
export const FacebookI = ({ size = "20px" }) => <img style={{ width: size, height: size }} src={FbIcon} alt="img" />;
export const LinkedinI = ({size ="20px"}) => <img style={{ width: size, height: size }} src={linkedin} alt="img"/>

export const CenteredIcon = styled.div` 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const WarningIcon = () => (
  <IoWarningOutline
    style={{
      color: "#CF1100",
      fontSize: "40px",
    }}
  />
);

export const CenteredWarning = () => (
  <CenteredIcon>
    <WarningIcon />
  </CenteredIcon>
);



