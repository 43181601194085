import { SET_SELECTED_NETWORK, SET_SOCIAL_LOGINS } from '../actions/types';

const initialState = {
    selectedNetwork: 'Facebook',
    socialLogins: null,
}

const systemConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_NETWORK: return {...state, selectedNetwork: action.payload}
        case SET_SOCIAL_LOGINS: return {...state, socialLogins: action.payload}
        default: return state;
    }
}

export default systemConfigReducer;