import React, { useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Utils
import { propertyTypeOptions, propertySourceOptions } from '../../../utils';

// Styles
import { SubHeader, ModalTextSecondary, ModalFieldContainer, InnerModalContainer } from '../../../../../components/Modal/styles';
import { Input, Textarea } from '../../../../../ui/input/input';
import { selectTheme } from '../../../../../ui/themes';
import { BiTrash } from 'react-icons/bi';

const PropertyFields = ({count, propertyList, setPropertyList}) => {
    const [isFreeForm, setIsFreeForm] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const handleChange = (key, value) => {
        if (key === 'type') value === 'FREE_FORM' ? setIsFreeForm(true) : setIsFreeForm(false);
        propertyList[count][key] = value;
        setPropertyList([...propertyList]);
    }

    const handleDelete = () => {
        const filteredPropertyList = propertyList?.filter((property, idx) => idx !== count);
        setPropertyList(filteredPropertyList);
        setIsDeleted(true);
    }

    if (isDeleted) return;

    return (
        <InnerModalContainer style={{margin: '10px auto'}} key={count}>
            {count > 0 &&
                <div className='trash'><BiTrash onClick={() => handleDelete()}/></div>
            }
            <SubHeader> Property {count + 1} </SubHeader>
            <ModalFieldContainer>
                <ModalTextSecondary> Property Name <span className='asterisk'>*</span> </ModalTextSecondary>
                <Input placeholder='Enter property name' onChange={e => handleChange('name', e.target.value)} />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> Type <span className='asterisk'>*</span> </ModalTextSecondary>
                <Select
                    isClearable
                    options={propertyTypeOptions}
                    styles={selectTheme}
                    onChange={e => handleChange('type', e.value)}
                />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> Source <span className='asterisk'>*</span> </ModalTextSecondary>
                <Select
                    isClearable
                    options={propertySourceOptions}
                    styles={selectTheme}
                    onChange={e => handleChange('source', e.value)}
                />
            </ModalFieldContainer>

            {!isFreeForm &&
            <ModalFieldContainer>
                <ModalTextSecondary> Property Values <span className='asterisk'>*</span> </ModalTextSecondary>
                <CreatableSelect
                    isClearable
                    isMulti
                    styles={selectTheme}
                    onChange={e => handleChange('values', e.map(opt => opt.value))}
                />
            </ModalFieldContainer>
            }

            <ModalFieldContainer>
                <ModalTextSecondary> How to set property on Bulk Upload <span className='asterisk'>*</span> </ModalTextSecondary>
                <Textarea rows={5} onChange={e => handleChange('bulkUploadInstructions', e.target.value)} />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> How to set property on Network Manager <span className='asterisk'>*</span> </ModalTextSecondary>
                <Textarea rows={5} onChange={e => handleChange('networkManagerInstructions', e.target.value)} />
            </ModalFieldContainer>
        </InnerModalContainer>
    )
};

export default PropertyFields;