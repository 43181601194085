import CheckBox from "../../../../ui/input/checkbox/CheckBox";

export const SearchNetworkField = ({searchNetwork, setSearchNetwork, isEdit, FieldContainer }) => {
    return (
        <FieldContainer>
            <h3> Search Network </h3>
            <CheckBox
                checked={searchNetwork}
                callback={() => setSearchNetwork(!searchNetwork)}
                isDisabled={!isEdit}
                label='Include Search Network'
            />
        </FieldContainer>
    )
}

export default SearchNetworkField;