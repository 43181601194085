import { makeGETPromiseApiCall } from "../pages/systemConfig/utils";

export const handleSocialOAuth = ({network, callback}) => {
    window.eulerity.makeApiCall(`/api/user/doOauth?network=${network}`, "GET", null, (res) => {
        if (!res?.redirectUrl) return;
        window.open(res.redirectUrl, '_blank');
        callback?.(res);
    });
}

export const getSocialLogins = (callback) => {
    window.eulerity.makeApiCall(`/api/user/getSocialLogins`, "GET", null, (res) => {
        callback?.(res);
    });
}

export const deleteSocialLogin = ({socialLoginId, callback}) => {
    window.eulerity.makeApiCall(`/api/user/deleteSocialLogin?socialLoginId=${socialLoginId}`, "POST", null, (res) => {
        callback?.(res);
    })
}

export const getFbConfig = ({ fbConfigId }) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `/api/lob/getNetworkConfig?fbconfig=${fbConfigId}`,
            method: 'GET',
            callback: resolve,
            errorCallbacks: {
                1000: reject
            }
        })
    })
}

export const getGoConfig = ({goConfigId}) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `/api/lob/getNetworkConfig?goconfig=${goConfigId}`,
            method: 'GET',
            callback: resolve,
            errorCallbacks: {
                1000: reject
            }
        })
    })
}

export const getLiConfig = ({liConfigId}) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `/api/lob/getNetworkConfig?liconfig=${liConfigId}`,
            method: 'GET',
            callback: resolve,
            errorCallbacks: {
                1000: reject
            }
        })
    })
}

export const getDuConfig = ({duConfigId, callback}) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `/api/lob/getNetworkConfig?duconfig=${duConfigId}`,
            method: 'GET',
            callback: (res) => {
                callback?.(res)
                resolve(res)
            },
            errorCallbacks: {
                1000: reject
            }
        })
    })
}

export const getListConnectedAccounts = ({socialLoginId, socialType, callback, errorCallback}) => {
    const list = [];

    let appendUrl = '';
    if (socialType === 'fbSocialAccounts') appendUrl = '&social=true';
    if (socialType === 'instagram') appendUrl = '&social=true&instagram=true';

    window.eulerity.makeBatchedApiCall({
        url: `/api/user/listConnectedAccounts?socialLoginId=${socialLoginId}${appendUrl}`,

        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        errorCallbacks: {
            1000: (err) => {
                console.error(err);
                errorCallback?.(err);
            }
        },
        doneCallback: () => {
            callback?.(list);
        },
    })
}

export const conversionEvents = (callback) => {
    window.eulerity.makeApiCall(`/api/facebook/conversionEvents`, 'GET', null, (res) => {
        callback?.(res);
    })
}

export const objectiveMaps = ({callback}) => {
    try {
        window.eulerity.makeApiCall(`/api/facebook/objectiveMap`, 'GET', null, (res) => {
            callback?.(res);
        })
    } catch(err) {
        console.error(err);
    }
}

export const conversionLocationEvents = (callback) => {
    window.eulerity.makeApiCall(`/api/facebook/conversionEventLocations`, 'GET', null, (res) => {
        callback?.(res);
    })
}

export const audienceInterests = ({interestType, socialLoginId, accountId, callback}) => {
    const list = [];

    window.eulerity.makeBatchedApiCall({
        url: `/api/google/${interestType}?socialLoginId=${socialLoginId}&accountId=${accountId}`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        doneCallback: () => {
            callback?.(list);
        }
    })
}

export const getInventoryFilters = callback => {
    window.eulerity.makeApiCall(`/api/facebook/inventoryFilters`, 'GET', null, res => {
        callback(res);
    })
}

export const getHouseholdIncomes = callback => {
    window.eulerity.makeApiCall(`/api/facebook/householdIncomes`, 'GET', null, res => {
        callback(res);
    })
}

export const getBuyingTypes = callback => {
    window.eulerity.makeApiCall(`/api/facebook/buyingTypes`, 'GET', null, res => {
        callback(res);
    })
}