import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getMediaPlan, uploadDummyNetworkUrls } from "../../../../../actions/mediaActions";
import BuildModal from "../../../../../components/Modal/modals/BuildModal";
import { BlackBtn, BtnWithLoader, OutlineBtn } from "../../../../../ui/button";
import DummyCampaignBulkUpload from "./DummyCampaignBulkUpload";
import { updateMediaPlan } from "../../../../../actions/mediaActions";
import DummyCampaignLinks from "./DummyCampaignLinks";
import { useDispatch } from "react-redux";


const DummyLaunchLinkModal = ({
        onClose, dummyNetworksCampaigns, bulkUploadSheets,
        setbulkUploadSheets, setBulkUploadUrls,
        bulkUploadUrls, mediaPlan, network, setMediaPlan
    }) => {

    const [step, setStep] = useState(1);
    const [urls, setUrls] = useState({})
    const [loader, setLoader] = useState(false)
    const [fileName, setFileName] = useState(null)
    const [currenBulkUrl, setCurrentBulkUrl] = useState(null)

    const selectedBtn = useRef()
    const _D = useDispatch()



    useEffect(() => {
        setUrls(mapDummyLinksToObjectFormat(dummyNetworksCampaigns))
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[])

    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }

    const mapDummyLinksToObjectFormat = (campaignState) => {
        return  campaignState.reduce((object, network) => {
            if(network.campaignUrl) object[network.websafe] =  network.campaignUrl
            return object
        },{})
    }

    const handleOnInputChange = (key, value) => {
        setUrls(prew => {
            prew[key] = value
            return prew
        })
    }

    const didUrlsChange = () => {
        const oldState = mapDummyLinksToObjectFormat(dummyNetworksCampaigns)
        if(!Object.keys(oldState).length) return true
        for(let websafe in oldState) {
            if(oldState[websafe] !== urls[websafe] || !oldState[websafe]?.length) return true
        }
        return false
    }

    const handleUploadCampaignLinks = () => {
        selectedBtn.current = 'outlineBtn'
        if(!didUrlsChange()) {
            toast.info("No Campaign url was changed")
            return
        }
        const promises = Object.entries(urls).map(([websafe, data]) => uploadDummyNetworkUrls(websafe, {campaignUrl: data}))
        setLoader(true)
        Promise.all(promises)
        .then((_) => {
           toast.success("Urls Added successfully")
           setLoader(false)
           onClose()
        })
        .catch(e => toast.error("Something went wrong uploading urls", e))
   }

    const handleUploadBulkSheets = () => {
        selectedBtn.current = 'blackBtn'
        let bkSheets = {}
        if(Object.keys(mediaPlan.bulkUploadSheets).length) {
             for(let key in mediaPlan.bulkUploadSheets) {
                bkSheets[key] = mediaPlan.bulkUploadSheets[key].websafe
             }
        }
        const updateObj = {
            bulkUploadSheets:  {...bkSheets, ...bulkUploadSheets},
            bulkUploadUrls:    {...mediaPlan.bulkUploadUrls,   ...bulkUploadUrls}
        }
        setLoader(true)
        _D(updateMediaPlan({websafe: mediaPlan.websafe, updateObj, callback: (_) => {
            getMediaPlan({
                mediaPlanWebsafe: mediaPlan.websafe,
                callback: (obj) => {
                    setMediaPlan(obj)
                }
            })
            if(!didUrlsChange()) {
                toast.success("Update Successful!")
                setLoader(false); onClose()
                return
            }
            const promises = Object.entries(urls).map(([websafe, data]) => uploadDummyNetworkUrls(websafe, {campaignUrl: data}))
            Promise.all(promises).then(_ => {
                toast.success("Update Successful!")
            })
            .catch(_ => toast.error("Something went wrong uploading the Media plan"))
            .finally(() => {
                setLoader(false)
                onClose()
            })

        }}))
    }





    const decideButtons = (step) => {
        switch(step) {
            case 1: return [<OutlineBtn onClick={onClose}> Back </OutlineBtn>,
                            <BlackBtn onClick={handleNext}> Next </BlackBtn>]

            case 2: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>,
                            <BtnWithLoader type='outlineBtn' loader={loader && selectedBtn.current === 'outlineBtn'} onClick={handleUploadCampaignLinks} btnText="Skip Bulk Upload" />,
                            <BtnWithLoader type='blackBtn'   loader={loader && selectedBtn.current === 'blackBtn'} onClick={handleUploadBulkSheets}  btnText="Confirm Bulk Upload"/> ]
            default: return []
        }
    }

    const steps = () => {
        switch(step) {
            case 1: return <DummyCampaignLinks urls={urls}
                    dummyNetworksCampaigns={dummyNetworksCampaigns}
                    handleOnInputChange={handleOnInputChange}/>
            case 2: return <DummyCampaignBulkUpload
                    setbulkUploadSheets={setbulkUploadSheets}
                    setBulkUploadUrls={setBulkUploadUrls}
                    network={network}
                    mediaPlan={mediaPlan}
                    setFileName={setFileName}
                    fileName={fileName}
                    setCurrentBulkUrl={setCurrentBulkUrl}
                    currenBulkUrl={currenBulkUrl}
                    />
            default: return ''
        }
    }

    return (
        <BuildModal
            onClose={onClose}
            buttons={decideButtons(step)}
            header="Property Setup"
            >
             {steps()}
        </BuildModal>
    )
}

export default DummyLaunchLinkModal