import styled from 'styled-components';

const button = {
    width: '100%',
    marginBottom: '10px',
}

const input = {
    width: '100%',
    marginBottom: '10px',
}

export const LoginForm = styled.div`
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    margin: 200px auto;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    display: grid;
    place-items: center;
    padding: 20px;

    button {
        ${button}
    }

    input {
        ${input}
    }

    form {
        width: 100%;
    }
`

export const SignUpForm = styled(LoginForm)``;

export const OrDivider = styled.p`
    text-align: center;
    margin: 10px auto;
`