import React, { useContext } from 'react'
import { StyledAttributeItem, StyledTag } from './styles'
import { CgClose } from 'react-icons/cg'
import { BiPlus } from 'react-icons/bi'
import { LinkedInAudienceContext } from '../LinkedAudienceModal'

const AttributeItem = ({ group, onAddSpecific, targetIndex, idx }) => {
  const { selectedAttributes, setSelectedAttributes } = useContext(LinkedInAudienceContext)

  const onDeleteSingleItem = (target) => {
    const filteredIndex = selectedAttributes[targetIndex].filter(attribute => attribute.urn !== target.urn)
    const updatedTargets = [...selectedAttributes]


    // * If a group is empty just delete the whole group
    if (!filteredIndex?.length) {
      const removedGroup = updatedTargets.toSpliced(targetIndex, 1)
      setSelectedAttributes(removedGroup)
      return
    }

    updatedTargets[targetIndex] = filteredIndex
    setSelectedAttributes(updatedTargets)
  }

  return (
    <StyledAttributeItem>
      <p style={{ fontSize: '14px' }}>
        {idx > 0 && <strong>OR </strong>}
        {group?.name}
      </p>
      <div className='tag-wrapper'>
        {
          group?.targets?.map((target) => {
            return <StyledTag key={target?.urn}>
              <p>{target?.name}</p>
              <CgClose size={16} onClick={() => onDeleteSingleItem(target)}/>
            </StyledTag>
          })
        }
        <BiPlus className='add-btn' size={24} onClick={onAddSpecific}/>
      </div>
    </StyledAttributeItem>
  )
}

export default AttributeItem