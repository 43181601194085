import React from 'react'
import { Input } from '../../../../ui/input/input'

const MaxCpcBid = ({ FieldContainer, isEdit, maxBidLimit, setMaxBidLimit }) => {
    return (
        <FieldContainer>
            <h3>Maximum CPC Bid Limit</h3>
            {
                isEdit ?
                    <Input
                        type='number'
                        min='0'
                        value={maxBidLimit}
                        onChange={(e) => setMaxBidLimit(e.target.value)}
                    /> : <p>{maxBidLimit}</p>
            }
        </FieldContainer>
    )
}

export default MaxCpcBid