import React, { useState } from 'react';
import { serveImage } from '../../labelManagement/label.utils';

// Styles
import { AdvertisersTableContainer } from '../styles';
import { BiPencil, BiArchiveIn } from 'react-icons/bi';
import { truncateString } from '../../../../../utils/strings';

const AdvertiserItem = ({advertiser, idx, isEdit, setIsEditAdvertiserModalOpen, setEditData, setIsArchiveModalOpen}) => {
    const [extendText, setExtendText] = useState(false)
    const { name, logo, description, documents } = advertiser;
    const documentNames = documents?.map((document, idx) => {
        return (
            <li key={idx}>
                <a  id='download-url'
                    href={serveImage(document.blobkey)}
                    download
                > { document.filename } </a>
            </li>
        )
    });

    return (
        <AdvertisersTableContainer idx={idx} isEdit={isEdit}>
            <div> {name || 'N/A'} </div>
            {logo ? <img src={serveImage(logo[0].blobkey || logo)} alt='N/A' /> : 'N/A'}
            <div>
                {(description?.length > 120 ? truncateString(description, extendText ? 0 : 120) : description) || 'N/A'}
                { description?.length > 120 &&
                    <div onClick={() => setExtendText(!extendText)} className='read-more'>
                        {extendText ? 'Hide' : 'Read More' }
                    </div>
                }
            </div>
            <div> {documents?.length ? documentNames : 'N/A'} </div>
            <div className='edit'>
                <BiPencil onClick={() => {
                    setIsEditAdvertiserModalOpen();
                    if (!advertiser?.id) advertiser['id'] = idx;
                    setEditData(advertiser);
                }}/>
                {
                    advertiser.websafe &&
                    <BiArchiveIn onClick={() => {
                        setIsArchiveModalOpen();
                        setEditData(advertiser);
                    }}/>
                }
            </div>
        </AdvertisersTableContainer>
    )
}

export default AdvertiserItem;