import React, { useContext, useMemo } from 'react';

// Styles
import { TableModalContainer } from '../../../../../ui/table/styles';
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { ModalListContainer, ModalListHeader, ModalListItemWithBtn, ModalListWrapper } from '../../../../../ui/list';
import { ChannelSetUpContext } from './ChannelSetupModal';

const SelectAudience = () => {
    const { lob, selectedConfig, isAudSelected, isAudSelectable, handleSelectAudienceDescription } = useContext(ChannelSetUpContext)

    const usedAudienceDescriptions = useMemo(() => {
        const { audienceTargets } = selectedConfig
        const result = []
        for (let target in audienceTargets) {
            if (result.indexOf(audienceTargets[target].description) < 1) result.push(audienceTargets[target].description);
        }

        return result
    }, [selectedConfig])

    const audienceDescriptionsList = lob?.audienceDescriptions?.map(description =>
        <ModalListItemWithBtn
            key={description}
            isSelected={isAudSelected(description)}
            onClick={() => handleSelectAudienceDescription(description, usedAudienceDescriptions)}
            isDisabled={!isAudSelectable(description, usedAudienceDescriptions)}
        >
            {description}
        </ModalListItemWithBtn>);
    return (
        <TableModalContainer>
            <ModalTextSecondary> Select an Audience(s) <span className='asterisk'>*</span> </ModalTextSecondary>
            <ModalListWrapper>
                <ModalListHeader> Audience Descriptions </ModalListHeader>
                <ModalListContainer>
                    {audienceDescriptionsList}
                </ModalListContainer>
            </ModalListWrapper>
        </TableModalContainer>
    )
}

export default SelectAudience;