import React, { useContext } from 'react';
import CheckBox from '../../../../../ui/input/checkbox/CheckBox';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';

const Placements = ({}) => {
    const { campaignObj, objectiveMap } = useContext(LinkedInCampaignContext);
    const { audienceNetwork, objective, optimization } = campaignObj;
    const placementObj = objectiveMap[objective]?.optimizations[optimization]?.placements || {};
    const showPlacements = Object.keys(placementObj).length > 0;

    if (!showPlacements) return;

    return (
        <FieldContainer>
            <h3> Placements </h3>
            <CheckBox
                checked={audienceNetwork}
                label='LinkedIn Audience Network'
                isDisabled={true}
            />
        </FieldContainer>
    )
}

export default Placements;
