export const incomeTargetObj = [
    {
        id: 1,
        label: '< $66K',
        value: {
            min: 1,
            max: 49
        }
    },
    {
        id: 2,
        label: '$67K-$84K',
        value: {
            min: 50,
            max: 59
        }
    },
    {
        id: 3,
        label: '$85K-$107K',
        value: {
            min: 60,
            max: 69
        }
    },
    {
        id: 4,
        label: '$108K-$139K',
        value: {
            min: 70,
            max: 79
        }
    },
    {
        id: 5,
        label: '$140K-$199K',
        value: {
            min: 80,
            max: 89
        }
    },
    {
        id: 6,
        label: '$200K+',
        value: {
            min: 90,
            max: 100
        }
    },
]

export const genderOptions = [
    {
        label: 'Male',
        value: 'm'
    },
    {
        label: 'Female',
        value: 'f'
    },
    {
        label: 'Undetermined',
        value: 'u'
    }
]