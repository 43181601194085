import styled from 'styled-components';


export const StyleAdvancedTable = styled.div`
    padding: 0.4rem;
    width: 322px;

    .table-header-btn {
        cursor: pointer;
    }
`
export const StyleAdvancedTableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid ${props => props.theme.color.gray};
    padding: 0.8rem;
    font-weight: bold;

    .btn-container {
        display: flex;
        justify-content: space-evenly;
    }
`
export const StyleAdvancedTableBody= styled.div`
    max-height: 450px;
    overflow-y: scroll;
`