import styled from 'styled-components';

export const FlexEndBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px auto;
    height: 40px;

    button {
        height: 40px;
    }
`;