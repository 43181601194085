import React, { useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Styles
import { OneThirdGridColContainer, StyledFieldsWrapper } from "../../../ui/containers";
import {
  campaignUpdatedController,
  checkForMissingFields,
  constructPlacements,
  placementsColontroller,
  preloadPlatformAndPlacements,
} from "../templates.util";



import { SubHeader, ColumnHeader } from "../../../ui/headers";
import { EditSaveBtn, HeaderIconBtnContainer, DeleteTextBtn } from "../../../ui/button";
import { useTheme } from "styled-components";

// Components
import DeleteModal from "../../../components/Modal/DeleteModal";
import CheckBoxField from "../../../ui/input/fields/CheckboxField";
import DropDownField from "../../../ui/input/fields/DropdownField";
import MultiSelectField from "../../../ui/input/fields/MultiSelectField";
import useFBCampagin from "../useFBCampaign";
import { FieldContainer } from "../../../ui/containers";
import { Input } from "../../../ui/input/input";
import { StyleFacebookCampaignDetails } from "../styles";
import { toast } from "react-toastify";
import useFadeAnimation from "../../../hooks/useFadeAnimation";
import Catalog from './facebookCampaignFields/Catalog';
import FrequencyField from "../../../ui/input/fields/FrequencyField";

const FacebookCampaignDetails = ({ handleUpdate, handleDeleteCampaign }) => {
  //redux
  const template = useSelector(state => state.template.currentNetworkTemplate);
  const campaign = useSelector(state => state.template.selectedCampaign)
  const org = useSelector(state => state.org.organization);

  //Action state
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);


  const mappedAudienceType = org.audienceTypes.map((it) => ({ label: it, value: it }));

  //Fields State
  const [name, setName] = useState(null);
  const [audienceType, setAudienceType] = useState(null);
  const [budgetDeliveryPacing, setBudgetDeliveryPacing] = useState(null);

  const [objective, setObjective] = useState(null);
  const [bidStrategy, setBidStrategy] = useState(null);
  const [optimization, setOptimization] = useState(null);
  const [billingEvent, setBillingEvent] = useState(null);
  const [useCatalog, setUseCatalog] = useState(null);
  const [frequencyCapNum, setFrequencyImpressions] =   useState(0);
  const [frequencyCapIntervalDays, setFrequencyDays] = useState(0);
  const [advantagePlacements, setAdvantagePlacements] = useState(null);
  const [abTest, setAbTest] = useState(null);
  const [platforms, setPlatforms] = useState(null);
  const [placements, setPlacements] = useState(null);

  const isSalesObjective = (!objective?.label && campaign?.objective  === 'Sales') || objective?.label === 'Sales'

  const currentState = () => {
    return {
      name,
      abTest,
      advantagePlacements,
      useCatalog,
      frequencyCapIntervalDays,
      frequencyCapNum,
      audienceType: audienceType?.value || audienceType,
      budgetDeliveryPacing: budgetDeliveryPacing?.value || budgetDeliveryPacing,
      billingEvent: billingEvent?.value || billingEvent,
      objective: objective?.value || objective,
      optimization: optimization?.value || optimization ,
      placements: constructPlacements(platforms, placements),
      bidStrategy: bidStrategy?.value || bidStrategy,

    };
  };
  const theme = useTheme()
  const [loading, setLoading] = useState(false);

  const RequiredStar = () => <span style={{color: theme.color.yellow}}>*</span>

  const ref = useFadeAnimation([isEdit, campaign])

  const handleSelectedObjective = (value) => {
    if (!value) return;
    setObjective((prew) => {
      if (prew && prew?.label !== value?.label) {
        setOptimization(null);
        setBidStrategy(null);
        setBudgetDeliveryPacing(null);
        setBillingEvent(null);
        setPlacements(null);
        setPlatforms(null);
        setDisabledProps({ ...defaultDisabledProps, optimization: false });
      }
      selectObjective(value);
      return value;
    });
  };

  const handleSelectedOptimization = (value) => {
    if (!value) return;
    setOptimization((prew) => {
      if (prew && prew?.label !== value?.label) {
        setBidStrategy(null);
        setBillingEvent(null);
        setBudgetDeliveryPacing(null);
        setPlatforms(null);
        setPlacements(null);
        setDisabledProps({ ...disabledProps, budgetDeliveryPacings: true, placements: true });
      }
      if(shouldShowFrequencyCap(value)) {
        setFrequencyDays(campaign.frequencyCapIntervalDays ||  7);
        setFrequencyImpressions(campaign.frequencyCapNum || 1)
      }
      selectOptimization(value);
      return value;
    });
  };

  const handleBitStrategy = (value) => {
    if (!value) return
    setBidStrategy((prew) => {
      if (prew && prew?.label !== value?.label) setBudgetDeliveryPacing(null);

      const comperator = typeof value  === 'string' ? value : value.label
      let isNotBidCap = comperator !== "Bid Cap"  &&  comperator !== "Cost per Goal"

      setDisabledProps({ ...disabledProps, bidControll: isNotBidCap, budgetDeliveryPacings: true });
      selectBidStrategy(value);
      return value;
    });
  };

  const handlePlatforms = (values) => {
    const placementsOptions = selectPlatforms(values);
    setPlatforms(values);
    if (!placementsOptions || !placements) return;
    setPlacements((prew) => placementsColontroller(placementsOptions, prew));
  };


  useEffect(() => {
    clear()
    setDisabledProps(defaultDisabledProps);
    handleSelectedObjective(campaign.objective);
    handleSelectedOptimization(campaign.optimization);
    handleBitStrategy(campaign.bidStrategy);
    setName(campaign.name || "")
    setAudienceType(campaign.audienceType)
    setBudgetDeliveryPacing(campaign.budgetDeliveryPacing)
    setBillingEvent(campaign.billingEvent)
    setUseCatalog(campaign.useCatalog)
    setFrequencyImpressions(campaign.frequencyCapNum || 0)
    setFrequencyDays(campaign.frequencyCapIntervalDays || 0)
    setAdvantagePlacements(campaign.advantagePlacements || null)
    setAbTest(campaign.abTest || null)
    const {platforms, placements} = preloadPlatformAndPlacements(campaign.placements)
    setPlatforms(platforms)
    setPlacements(placements)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.id, isEdit]);


  const {
    selectObjective,
    selectOptimization,
    selectPlatforms,
    selectBidStrategy,
    setDisabledProps,
    defaultDisabledProps,
    disabledProps,
    _M,
  } = useFBCampagin();


  const shouldShowFrequencyCap = (current) => {
    const curOptimization = current || optimization

    const objectiveValue = typeof objective === 'string' ? objective : objective?.value;
    const optimizationValue = typeof curOptimization === 'string' ? curOptimization : curOptimization?.value;
    return objectiveValue === "Awareness" && optimizationValue === "Reach";
  }

  const handleEdit = () => {
    if (isEdit) {
      const updateObj = campaignUpdatedController(campaign, currentState());
      const error = checkForMissingFields(currentState())
      if(error) return toast.error(error)

      const shouldUpdate = Object.keys(updateObj).length;
      if (shouldUpdate) {
        if(!shouldShowFrequencyCap()) {
          updateObj.frequencyCapIntervalDays = 0
          updateObj.frequencyCapNum = 0
        }
        handleUpdate({ ...updateObj, websafe: campaign.websafe });
      } else {
          toast.info("No Changes Made")
      }
    }
    setIsEdit(prev => !prev);
  };

  const isBidStrategy = bidStrategy && typeof bidStrategy === 'string' ? bidStrategy : bidStrategy?.label

  const handleModalDeleteCampaign = () => {
    setLoading(true);
    handleDeleteCampaign(campaign, () => {
      setIsDeleteCampaignModalOpen(false);
      setLoading(false);
    });
  };
  const clear = () => {
    setName(''); setBidStrategy(null); setObjective(null);
    setOptimization(null); setPlatforms(null); setPlacements(null); setAudienceType(null);
    setBudgetDeliveryPacing(null); setBillingEvent(null); handleSelectedOptimization(null);
    handleBitStrategy(null); handleSelectedObjective(null); setFrequencyImpressions(0);
    setFrequencyDays(0); setAbTest(null); setAdvantagePlacements(null);
  };

  return (
    <StyledFieldsWrapper>
      <StyleFacebookCampaignDetails ref={ref} isAdvantagePlacements={!advantagePlacements}>
        <SubHeader>
          Campaign Details for {campaign?.name}
          <HeaderIconBtnContainer>
            <DeleteTextBtn onClick={() => setIsDeleteCampaignModalOpen(true)} btnText={'Delete Campaign'}/>
            {EditSaveBtn(isEdit, handleEdit)}
          </HeaderIconBtnContainer>
        </SubHeader>

        <OneThirdGridColContainer forFb>
          <FieldContainer>
              <h3> Campaign Name </h3>
              {
                  isEdit ?
                  <Input value={name} onChange={e => setName(e.target.value)}/> :
                  <p> {campaign?.name} </p>
              }
          </FieldContainer>
          <DropDownField
            isEdit={isEdit}
            text={{ header: "Targeting Type" }}
            options={mappedAudienceType}
            value={audienceType}
            localState={setAudienceType}
          />
          <DropDownField
            isEdit={isEdit}
            text={{ header: "Objective", RequiredStar }}
            options={_M.mapedObjectives}
            value={objective}
            localState={handleSelectedObjective}
          />
        </OneThirdGridColContainer>

        <OneThirdGridColContainer>
          { isSalesObjective && <Catalog
            isEdit={isEdit}
            useCatalog={useCatalog}
            setUseCatalog={setUseCatalog}
          />}
          <CheckBoxField isEdit={isEdit} text={{ header: "A/B Test", body: "Use A/B Test" }} localState={setAbTest} value={abTest} />

          <DropDownField
            isEdit={isEdit}
            isDisabled={disabledProps.bidStrategy}
            text={{ header: "Bid Strategy", RequiredStar }}
            options={_M.mapedBidStrategies}
            value={bidStrategy}
            localState={handleBitStrategy}
          />
        </OneThirdGridColContainer>

        <OneThirdGridColContainer>
          <DropDownField
            isEdit={isEdit}
            isDisabled={disabledProps.budgetDeliveryPacings}
            text={{ header: "Campaign Budget Delivery Type", RequiredStar: isBidStrategy === 'Bid Cap' ? RequiredStar : null }}
            options={_M.mapedBudgetDeliveryPacings}
            value={budgetDeliveryPacing}
            localState={setBudgetDeliveryPacing}
          />
      {
        shouldShowFrequencyCap() && (
          <FrequencyField
            text={{header: "Frequency Cap", RequiredStar}}
            freqImpressionsValue={frequencyCapNum}
            freqDaysLocalValue={frequencyCapIntervalDays}
            freqDaysLocalState={setFrequencyDays}
            freqImpressionsLocalState={setFrequencyImpressions}
            isEdit={isEdit}/>
        )
      }

        </OneThirdGridColContainer>
        <ColumnHeader>Ad Set Details for {campaign?.name}</ColumnHeader>
        <OneThirdGridColContainer forFb>
          <DropDownField
            isEdit={isEdit}
            isDisabled={disabledProps.optimization}
            text={{ header: "Optimization for Ad Delivery", RequiredStar }}
            options={_M.mapedOptimization}
            value={optimization}
            localState={handleSelectedOptimization}
          />
          <DropDownField
            isDisabled={disabledProps.billingEvents}
            isEdit={isEdit}
            text={{ header: "When you get charged", RequiredStar }}
            options={_M.mapedBillingEvents}
            value={billingEvent}
            localState={setBillingEvent}
          />
          <CheckBoxField
            isEdit={isEdit}
            text={{ header: "Advantage Placements", body: "Use Advantage Placements" }}
            localState={setAdvantagePlacements}
            value={advantagePlacements}
          />
          <div className="platforms">
            <MultiSelectField
              isEdit={isEdit}
              isDisabled={disabledProps.platforms}
              text={{ header: "Platforms" }}
              template={template}
              options={_M.mapedPlatforms}
              value={platforms}
              localState={handlePlatforms}
            />
          </div>
          <div className="platforms">
            <MultiSelectField
              isEdit={isEdit}
              isDisabled={disabledProps.placements}
              text={{ header: "Placements" }}
              template={template}
              options={_M.mapedPlacements}
              localState={setPlacements}
              value={placements}
              menuPlacement="top"
            />
          </div>
        </OneThirdGridColContainer>
      </StyleFacebookCampaignDetails>

      {isDeleteCampaignModalOpen && (
        <DeleteModal
          header="Delete Campaign"
          modalText={`Deleting ${campaign?.name} campaign`}
          deleteBtnText="Delete Campaign"
          setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
          handleDelete={handleModalDeleteCampaign}
          loading={loading}
        />
      )}
    </StyledFieldsWrapper>
  );
};

export default FacebookCampaignDetails;
