import React from 'react';
import { StyledTableItem } from '../../styles';
import { BiTrash, BiPencil } from "react-icons/bi";


const Targets = ({ target, colored, isEdit, onEdit, onDelete }) => {

  return (
    <StyledTableItem colored={colored}>
        <div className='each_item_info'>
            <div className='inner_wrapper'>
                <p className='inner_text'>
                  <span className='inner_header'>Target Type: </span>
                  {target.key}
                </p>
            </div>
            <div className='inner_wrapper'>
                <p className='inner_text'>
                  <span className='inner_header'>Abbrevation: </span>
                  {target.value}
                </p>
            </div>
        </div>
        {isEdit && (
          <div className="each_item_buttons">
            <div className='each_item_icon'>
              <BiPencil size="19" onClick={onEdit} />
            </div>
            <div className='each_item_icon'>
              <BiTrash size="19" onClick={onDelete} />
            </div>
          </div>
        )}
    </StyledTableItem>
  )
}

export default Targets