import { capitalize } from "../../../utils/strings";


export const dateCheck = (start, end) => {
    return end > start 
}


export const stringToDateconverter = (start, end) => {
    if(typeof start === 'string') start = new Date(start).getTime();
    if(typeof end === 'string') end = new Date(end).getTime();
    return {startDate: start, endDate: end}
}


export const plainArrayMulticheck = (val1, val2) => {
    let shouldUpdate = false
    if(val1?.length !== val2?.length) return true
    val1.forEach(cat => {
        if(!val2?.includes(cat)) {
            shouldUpdate = true
        }
    })
    return shouldUpdate
}


export const multiValueCheck =(val1, val2) => {
    let shouldUpdate = false

    if(val1?.length !== val2?.length) shouldUpdate = true
    val1.forEach(cat => {
        if(!val2?.includes(cat?.value)) {
            shouldUpdate = true
        }
    })
    if(shouldUpdate) {
        return val1?.map(it => it?.value);
    }
    return null

}

export const createLocationString = (l) => {
    return {label: `${l?.name}, ${l?.region ? l?.region : l?.country_code?.toUpperCase()}, ${capitalize(l?.country_name)}.`, value: l}
}
export const locationLabels = str => {
    if(!str) return
    return str.split(", ").map(i => {
        if(i.length === 2) return i.toUpperCase()
        return capitalize(i)
    }).join(", ")
}


export const buildMultiTemplateCampaigns = (campaigns) => {
       const getTemplates = [...new Set(campaigns.map(it => it?.networkTemplate?.name))];
       const collector = []
       getTemplates.forEach(template => {
        let temp = [template?.trim()]
        temp.push(campaigns.filter(campaign => campaign?.networkTemplate?.name === template))
        collector.push(temp)
       })
       return collector
}



export const dynamicPropName = (bidStrategy) => {
    if(bidStrategy === "Cost per Goal") return "cpaAmount"
    return "bidAmount"
}