import React from "react";
import { StyledTableItem } from "../../styles";
import { BiTrash, BiPencil } from "react-icons/bi";
import { serveImage } from "../../label.utils";

const Chanels = ({ network, colored, isEdit, onEdit, onDelete }) => {
  return (
    <StyledTableItem colored={colored}>
      <div className="each_item_info">
        <div className="inner_wrapper">
          <p className="inner_text">
            <span className="inner_header">Network Name: </span>
            {network?.name}
          </p>
        </div>
        <div className="inner_wrapper">
          <span className="inner_header">Network Logo: </span>
          <img className="inner_image" src={serveImage(network?.logo)} alt="logo" />
        </div>
        <div className="inner_wrapper">
          <p className="inner_text">
            <span className="inner_header">List of Objectives: </span>
            {network.objectives?.join(", ")}
          </p>
        </div>
      </div>

      {isEdit && (
        <div className="each_item_buttons">
          <div className='each_item_icon'>
            <BiPencil size="19" onClick={onEdit} />
          </div>
          <div className='each_item_icon'>
            <BiTrash size="19" onClick={onDelete} />
          </div>
        </div>
      )}
    </StyledTableItem>
  );
};

export default Chanels;
