import {
    CREATE_MEDIA_PLAN, GET_MEDIA_PLAN_LIST, DELETE_MEDIA_PLAN,
    SELECT_MEDIA_PLAN, UPDATE_MEDIA_PLAN, UPDATE_AD_GROUP, DELETE_AD_GROUP, SELECT_AD_GROUP,
    UPDATE_MEDIA_CAMPAIGN, DELETE_MEDIA_CAMPAIGN, UPDATE_MEDIA_CAMPAIGN_LOCATION_TARGETS, SELECT_CAMPAIGN
} from "./types";

export const getMediaPlanList = (callback) => dispatch => {
    const list = [];

    window.eulerity.makeBatchedApiCall({
        url: `/api/mediaplan/list`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        doneCallback: () => {
            dispatch({
                type: GET_MEDIA_PLAN_LIST,
                payload: list
            })
            callback?.()
        },
        errorCallbacks: {
            1000: (err) => callback(err)
        },
    })
}

// TODO - Refactor this to use try/catch
export const getMediaPlan = ({mediaPlanWebsafe, callback}) => {
    if (!mediaPlanWebsafe) return;

    window.eulerity.makeApiCall({
        url: `/api/mediaplan/get?mediaPlan=${mediaPlanWebsafe}`,
        method: 'GET',
        callback: (res) => {
            callback?.(res)
        }
    })
}

export const createMediaPlan = ({obj, callback}) => dispatch => {
    const {name, lob} = obj
    if (!name || !lob) return;

    window.eulerity.makeApiCall(`/api/mediaplan/create`, 'POST', obj, res => {
        dispatch({
            type: CREATE_MEDIA_PLAN,
            payload: res
        })
        callback?.(res);
    })
}

export const deleteMediaPlan = ({websafe, callback}) => dispatch => {
    if (!websafe) return;

    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall(`/api/mediaplan/delete?mediaPlan=${websafe}`, 'POST', null, res => {
            resolve(res);
            dispatch({
                type: DELETE_MEDIA_PLAN,
                payload: websafe
            })
            callback?.(res);
        }, {1000: reject})
    })
}

export const selectMediaPlan = ({websafe, callback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/get?mediaPlan=${websafe}`, 'GET', null, res => {
            dispatch({
                type: SELECT_MEDIA_PLAN,
                payload: res
            })
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
    }
}

export const setCurrentMediaPlan = (newCurrent = {}) => dispatch => {
    dispatch({
        type: SELECT_MEDIA_PLAN,
        payload: newCurrent
    })
}

export const updateMediaPlan = ({websafe, updateObj, callback, errorCallback}) => dispatch => {
    if (!websafe) return;

    try {
        window.eulerity.makeApiCall(`/api/mediaplan/update?mediaPlan=${websafe}`, 'POST', updateObj, res => {
            dispatch({
                type: UPDATE_MEDIA_PLAN,
                payload: res
            })
            callback?.(res);
        })
    } catch (err) {
        errorCallback?.();
    }
}

export const saveChannelSetup = ({ obj, id }) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall({
            url: `/api/mediaplan/addNetwork?mediaPlan=${id}`,
            method: 'POST',
            obj,
            callback: (res) => {
                resolve(res)
            },
            errorCallbacks: {
                1000: (error) => {
                    reject(error)
                }
            }
        })
    })
}

export const getCampaign = ({websafe, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/getCampaign?mediaPlanCampaign=${websafe}`, 'GET', null, res => {
            callback?.(res);
            dispatch({
                type: UPDATE_MEDIA_CAMPAIGN,
                payload: res
            })
        })
    } catch (err) {
        console.error(err);
        errorCallback?.(err);
    }
}

export const updateCampaign = ({mediaPlanCampaign, obj, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/updateCampaign?mediaPlanCampaign=${mediaPlanCampaign}`, 'POST', obj, res => {
            callback?.(res);
            dispatch({
                type: UPDATE_MEDIA_CAMPAIGN,
                payload: res
            })

        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const deleteCampaign = ({mediaPlanCampaign, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/deleteCampaign?mediaPlanCampaign=${mediaPlanCampaign}`, 'POST', null, res => {
            dispatch({
                type: DELETE_MEDIA_CAMPAIGN,
                payload: mediaPlanCampaign
            })
            callback?.(res);
        })
    } catch (err) {
        console.error('Something went wrong');
        errorCallback?.();
    }
}

export const selectCampaign = (campaign = {}) => dispatch => {
    dispatch({
        type: SELECT_CAMPAIGN,
        payload: campaign
    })
}


export const getAdGroup = ({mediaPlanAdGroup, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/mediaplan/getAdGroup?mediaPlanAdGroup=${mediaPlanAdGroup}`, 'GET', null, res => {
        callback?.(res);
        dispatch({
            type: UPDATE_AD_GROUP,
            payload: res
        })
    })
}

export const updateAdGroup = ({mediaPlanAdGroup, obj, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/updateAdGroup?mediaPlanAdGroup=${mediaPlanAdGroup}`, 'POST', obj, res => {
            callback?.(res);
            dispatch({
                type: UPDATE_AD_GROUP,
                payload: res
            })
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const deleteAdGroup = ({mediaPlanAdGroup, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/deleteAdGroup?mediaPlanAdGroup=${mediaPlanAdGroup}`, 'POST', null, res => {
            callback?.(res);
            dispatch({
                type: DELETE_AD_GROUP,
                payload: mediaPlanAdGroup
            })
        })
    } catch (err) {
        console.error(err);
        errorCallback?.(err);
    }
}

export const selectAdGroup = (adGroup = {}) => dispatch => {
    dispatch({
        type: SELECT_AD_GROUP,
        payload: adGroup
    })
}

export const LAUNCH = ({mediaPlanCampaign, callback, errorCallback}) => {
    window.eulerity.makeApiCall(`/api/mediaplan/apiPushCampaign?mediaPlanCampaign=${mediaPlanCampaign}`, 'POST', null, res => {
        callback?.(res);
    },
    {
        1000: (err) => {
            console.error(err);
            errorCallback?.();
        },
    })
}

export const exportBudgetGrid = ({websafe, callback}) => {
    window.eulerity.makeApiCall(`/api/mediaplan/exportBudgetGrid?mediaPlan=${websafe}`, 'GET', null, res => {
        callback?.(res);
    },
    {
        1000: (err) => {
            console.error(err);
        },
    })
}

export const exportTacticalPlan = ({websafe, callback}) => {
    window.eulerity.makeApiCall(`/api/mediaplan/exportTacticalPlan?mediaPlan=${websafe}`, 'GET', null, res => {
        callback?.(res);
    },
    {
        1000: (err) => {
            console.error(err);
        },
    })
}

export const exportAudienceLibrary = ({websafe, callback}) => {
    window.eulerity.makeApiCall(`/api/mediaplan/exportAudienceLibrary?mediaPlan=${websafe}`, 'GET', null, res => {
        callback?.(res);
    },
    {
        1000: (err) => {
            console.error(err);
        },
    })
}

export const generateName = ({mediaPlanCampaign, mediaPlanAdGroup, callback}) => {
    let query = '';
    if (mediaPlanCampaign) query = `mediaPlanCampaign=${mediaPlanCampaign}`;
    if (mediaPlanAdGroup) query = `mediaPlanAdGroup=${mediaPlanAdGroup}`;

    window.eulerity.makeApiCall(`/api/mediaplan/generateName?${query}`, 'GET', null, res => {
        callback?.(res);
    })
}

//* I see that we have the update Campaign Function as well but i'll go with this approach i need the call Promise.all
export const uploadDummyNetworkUrls = (websafe, data) => {
    return new Promise((resolve, reject) => window.eulerity.makeApiCall(`/api/mediaplan/updateCampaign?mediaPlanCampaign=${websafe}`, "POST", data, resolve, {1000: reject}))
}

// This function is to be used for location target inside a campaign only to avoid overlapping of campaign data
export const updateLocationTarget = ({mediaPlanCampaign, obj, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/updateCampaign?mediaPlanCampaign=${mediaPlanCampaign}`, 'POST', obj, res => {
            const locationTargets = res.locationTargets;
            callback?.(res);
            dispatch({
                type: UPDATE_MEDIA_CAMPAIGN_LOCATION_TARGETS,
                payload: locationTargets
            })

        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const getLanguageTargets = ({callback}) => {
    const list = [];

    window.eulerity.makeBatchedApiCall({
        url: `/api/lob/languageTargets`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        doneCallback: () => {
            callback?.(list);
        }
    })
}

export const exportGoogleSheets = ({websafe, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/mediaplan/export?mediaPlan=${websafe}`, 'POST', null, res => {
            callback?.(res);
            dispatch({
                type: UPDATE_MEDIA_PLAN,
                payload: res
            })
        })
    } catch (err) {
        console.error(err);
        errorCallback?.(err);
    }
}

export const linkedInObjectiveMap = ({callback, errorCallback}) => {
    try {
        window.eulerity.makeApiCall('/api/linkedin/objectiveMap', 'GET', null, res => {
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.(err);
    }
}