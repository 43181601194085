import React, { useState, useEffect } from "react";

// Components
import Overview from "./components/Overview";
import EditLob from "./components/EditLob";
import Loader from "../../ui/Loader";

// Redux
import { useDispatch } from "react-redux";
import { createLob, getLobList, resetLob, selectLob } from "../../actions/lobActions";

// Libraries
import { useForm } from "react-hook-form";

// Hooks
import useFadeAnimation from "../../hooks/useFadeAnimation";

// Styles
import { MainHeader } from "../../ui/headers";
import { IoIosPaper } from "react-icons/io";
import { LineOfBusinessContainer } from "./styles";
import { toast } from "react-toastify";

const LineOfBusiness = () => {
  const { register, getValues, setValue, handleSubmit } = useForm();
  const [isEditLob, setIsEditLob] = useState(false);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  const ref = useFadeAnimation([loader, isEditLob]);
  useEffect(() => {
    dispatch(
      getLobList((err) => {
        if (err) toast.error("Something went wrong fetching data");
        setLoader(false);
      })
    );
  }, []);

  const handleBackToOverview = () => {
    setIsEditLob(false);
    dispatch(resetLob())
  }


  const onSubmit = () => {
    const { name, advertiser } = getValues();

    dispatch(
      createLob({
        name,
        advertiser,
        callback: (obj) => {
          handleContinueCreate(obj);
          toast.success(`Created ${name} line of business`);
        },
      })
    );

    setValue('name', '');
    setValue('advertiser', '')
  };

  const handleContinueCreate = (obj) => {
    dispatch(selectLob({websafe: obj.websafe}));
  };


  return (
    <LineOfBusinessContainer ref={ref}>
      <MainHeader>
        <IoIosPaper />
        <h2> Line Of Business </h2>
      </MainHeader>

      {
        loader ?
        <Loader size='55' fullViewHeight/> :
        <>{
          isEditLob ?
          <EditLob setIsEditLob={handleBackToOverview}/> :
          <Overview
            register={register} handleSubmit={handleSubmit(onSubmit)}
            setValue={setValue} setIsEdit={setIsEditLob} getValues={getValues}
            setLoader={setLoader}
          />
        }</>
      }
    </LineOfBusinessContainer>
  );
};

export default LineOfBusiness;
