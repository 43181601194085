import styled from "styled-components";

export const CheckBoxContainer = styled.label`
    display: inline-flex;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
    cursor: pointer;

    .checkbox__label {
        margin-left: 10px;
    }

    .checkbox__input {
        display: none;

        &:checked + .checkbox__box {
            background-color: ${props => props.isDisabled ? '#cccccc' : '#f4b63f'};
            border-color: ${props => props.isDisabled ? '#cccccc' : '#f4b63f'};
        }
    }

    .checkbox__box {
        background-color: ${props => props.isDisabled ? '#cccccc' : '#ffffff'};
        height: 20px;
        width: 20px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
`