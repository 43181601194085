import React, { useContext } from 'react';
import Select from 'react-select';

// Utils
import { genderOptions } from '../../../../lineofbusiness/lob.utils';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { selectTheme } from '../../../../../ui/themes';

const Gender = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { genderTargets } = campaignObj;

    const handleChange = (e) => {
        const result = e.map(gender => gender.value);
        handleUpdate({key: 'genderTargets', value: result})
    }

    const filterGender = genderOptions.filter(option => genderTargets?.includes(option.value));
    const genderText = filterGender?.map(gender => gender?.label);

    return (
        <FieldContainer>
            <h3> Gender </h3>
            {
                isEdit ?
                <Select
                    isClearable
                    isMulti
                    options={genderOptions}
                    defaultValue={filterGender}
                    styles={selectTheme}
                    onChange={e => handleChange(e)}
                /> :
                <p> {genderText?.join(', ') || 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default Gender;