import styled, {css}  from 'styled-components';

export const SubNavigationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    gap: 20px;
`;

export const SubNavItem = styled.li`
    font-family: 'GothamBook';
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    padding: 10px;

    ${({isSelected}) => isSelected && css`
        font-family: 'GothamMedium';
        font-weight: 500;
        text-align: center;
        border-bottom: 2px solid ${props => props.theme.color.yellow};
    `}
`;