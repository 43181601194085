import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';

// Styles
import { ModalTextSecondary } from '../../../../components/Modal/styles';
import { OutlineBtn, CenterButtons, BtnWithLoader } from '../../../../ui/button';
import CheckBox from '../../../../ui/input/checkbox/CheckBox';
import { TableModalContainer, DeleteList } from '../../../../ui/table/styles';

const DeleteCsvModal = ({onClose, handleDelete, data, deleteObj, loader}) => {
    const [selectedCsv, setSelectedCsv] = useState([]);

    const csvList = () => data[deleteObj]?.map(csv =>
        <DeleteList
            key={csv.blobkey}
            isSelected={handleIsSelected(csv.blobkey)}
        >
            <p>{csv.filename}</p>
            <CheckBox callback={() => handleChange(csv.blobkey)} checked={handleIsSelected(csv.blobkey)} />
        </DeleteList>
    );

    const handleChange = (key) => {
       if (selectedCsv.indexOf(key) > -1) {
           setSelectedCsv(selectedCsv.filter(csv => csv !== key));
       } else {
           setSelectedCsv([...selectedCsv, key]);
       }
    }

    const handleIsSelected = (item) => {
        return selectedCsv.find(csv => csv === item);
    }

    return (
        <Modal
            header={`Delete Exclusion File(s)`}
            style={{width: '500px'}}
            onClose={onClose}
        >
            <TableModalContainer>
                <ModalTextSecondary> File Name </ModalTextSecondary>
                {csvList()}
                {CenterButtons(
                    <>
                        <BtnWithLoader btnText='Delete File(s)' loader={loader} onClick={() => handleDelete({ list: selectedCsv, type: deleteObj })} type="redBtn" isDisabled={!selectedCsv?.length}/>
                        <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                    </>
                )}
            </TableModalContainer>
        </Modal>
    )
}

export default DeleteCsvModal;