import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Modal from '../../../../../../../components/Modal/modals/Modal'
import CurrencyInput from '../../../../../../../ui/input/fields/CurrencyInput'
import Information from '../../../../../../../ui/tooltip/Information'

// Hooks
import useLinkedInAccount from '../../hooks/useLinkedInAccount'

// Redux
import { useDispatch } from 'react-redux'
import { updateAdAccount } from '../../../../../../../actions/lobActions'

// Styles
import { toast } from 'react-toastify'
import { Input } from '../../../../../../../ui/input/input'
import { selectTheme } from '../../../../../../../ui/themes'
import { BtnWithLoader, OutlineBtn } from '../../../../../../../ui/button'
import { StyledAccountInfo, StyledBiddingStepWrapper, StyledFieldWrapper, StyledInfoWrapper, StyledLineBreak } from './styles'

const LinkedInBiddingModal = ({ onClose, selectedItem, setAdAccounts, adAccounts, liConfigId }) => {
    const _D = useDispatch()
    const [btnLoader, setBtnLoader] = useState(false)

    const {
        costPerGoal,
        setCostPerGoal,
        manualBidding,
        setManualBidding,
        insightTag,
        setInsightTag,
        fetchInsightOptions,
        insightOptions,
        liPage
    } = useLinkedInAccount(selectedItem)

    const handleUpdateAccount = () => {
        if (costPerGoal < 0) return toast.error('Please Enter a Valid Cost Per Result Goal')
        if (manualBidding < 0) return toast.error('Please Enter a Valid Manaul Bidding')

        const updatedAccount = {
            ...selectedItem, // * spread current data
            targetCpa: costPerGoal,
            cpcBidLimit: manualBidding,
            insightTag: insightTag?.value || {}
        }

        const filteredAdAccounts = adAccounts.filter(acc => acc.accountId !== updatedAccount.accountId)
        setBtnLoader(true)

        _D(updateAdAccount({
            socialLoginId: selectedItem?.socialLoginId,
            socialConfig: `liconfig=${liConfigId}`,
            pages: [...filteredAdAccounts, updatedAccount],
            callback: () => {
                toast.success('Updated Ad Account');
                setAdAccounts([...filteredAdAccounts, updatedAccount])
                setBtnLoader(false)
                onClose()
            }
        }))
    }

    useEffect(() => {
        fetchInsightOptions(selectedItem?.socialLoginId, selectedItem?.accountId)
    }, [])

    return (
        <Modal
            header='Update Ad Account'
            onClose={onClose}
            width='736px'
            buttons={[<OutlineBtn onClick={onClose}>Cancel</OutlineBtn>, <BtnWithLoader btnText='Update LinkedIn Ad Account' type='blackBtn' color='white' loader={btnLoader} onClick={() => handleUpdateAccount()} />]}
        >
            <StyledBiddingStepWrapper>
                <p>Enter and/or select values specific to this ad account.</p>
                <StyledAccountInfo>
                    <p>Ad Account: {selectedItem?.displayName}</p>
                    <p>ID: {selectedItem?.accountId}</p>
                    <p style={{ display: 'flex' }}> Page<Information text='A page is required to launch a Leads Generation Campaign' />: { liPage || 'N/A' } </p>
                </StyledAccountInfo>
                <StyledInfoWrapper>
                    <div className='field-group'>
                        <StyledFieldWrapper>
                            <p>Cost Per Result Goal</p>
                            <CurrencyInput
                                currency={'USD'}
                                value={costPerGoal}
                                onChange={(e) => setCostPerGoal(e.target.value)}
                            />
                        </StyledFieldWrapper>
                        <StyledFieldWrapper>
                            <p>Manual Bidding</p>
                            <CurrencyInput
                                currency={'USD'}
                                value={manualBidding}
                                onChange={(e) => setManualBidding(e.target.value)}
                            />
                        </StyledFieldWrapper>
                    </div>
                    <StyledLineBreak />
                    <div>
                        <p style={{ marginBottom: '18px' }}>Select a insight tag for this ad account.</p>
                        <StyledFieldWrapper>
                            <p>Insight Tag</p>
                            <Select
                                isClearable
                                isSearchable={false}
                                styles={selectTheme}
                                placeholder={'Select a Insight Type'}
                                value={insightTag}
                                options={insightOptions}
                                onChange={(e) => setInsightTag(e)}
                            />
                        </StyledFieldWrapper>
                        {/* <StyledFieldWrapper>
                            <p style={{ margin: '18px 0' }}> Associate a LinkedIn Page with your account </p>
                        </StyledFieldWrapper> */}
                    </div>
                </StyledInfoWrapper>
            </StyledBiddingStepWrapper>
        </Modal>
    )
}

export default LinkedInBiddingModal