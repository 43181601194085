import React, { useState, useEffect } from 'react';
import Search from '../../../ui/search';

// Styles
import { SelectListContainer, SelectListItem } from '../../../components/Modal/styles';
import { AudienceContainer } from '../styles';
import { RadioFilterContainer } from './modal/GoogleSearchAdGroups/styles';
import Loader from '../../../ui/Loader';

const AudienceTarget = ({type, text, isLoading, setIsLoading,
        selectedAudiences, setAudience, setSelectedListItem, selectedListItem, searchResults,
        isAudience, isExclude, isGoogle, id, socialLoginId, setQueryInterests, setExcludeQueryInterests, userInterestsList, preSelectedItems
    }) => {

    const [filteredSearch, setFilteredSearch] = useState('');
    const [isInterestHidden, setIsInterestHidden] = useState(false);
    const [radioFilter, setRadioFilter] = useState('userInterests');

    const isInterestTarget = !isAudience && !isExclude;

    const interestTypeObj = {
        userInterests: 'userInterests',
        lifeEvents: 'lifeEvents',
        demographics: 'detailedDemographics'
    }

    // * If user is editing - set the selectedListItem to contain previously selected items.
    useEffect(() => {
        if(preSelectedItems) setSelectedListItem(preSelectedItems)
    }, [])

    useEffect(() => {
        setFilteredSearch('');
    }, [isAudience]);

    useEffect(() => {
        if (isGoogle || isAudience || !filteredSearch) return;
        setIsLoading(true);
        window.eulerity.makeApiCall(`/api/facebook/queryInterests?socialLoginId=${socialLoginId}&q=${filteredSearch}`, 'GET', null, response => {
            if (!isExclude) setQueryInterests(response);
            if (isExclude) setExcludeQueryInterests(response);
            if (response) setIsLoading(false);
        })
    }, [filteredSearch])

    const handleSelectItem = (item) => {
        if (isInterestTarget) {
            let newSelectedAudiences = selectedAudiences;

            if (newSelectedAudiences[id]) {
                selectedAudiences[id].push(item);
            } else {
                newSelectedAudiences[id] = [item];
            }

            setSelectedListItem([...selectedListItem, item]);
            setAudience(newSelectedAudiences);
            return;
        }
        setAudience([...selectedAudiences, item]);
        setSelectedListItem([...selectedListItem, item]);
    }

    const filteredAudienceTargetList = () => {
        const resultsList = isGoogle ? userInterestsList : searchResults;

        if (!filteredSearch.length) return resultsList;
        if (isGoogle) return resultsList?.[interestTypeObj[radioFilter]]?.filter(item => item.name.toLowerCase().includes(filteredSearch.toLowerCase()));
        return resultsList?.filter(item => item.name.toLowerCase().includes(filteredSearch.toLowerCase()));
    }

    const audienceTargetList = () => {
        if (isLoading) return <Loader />;
        if (!filteredAudienceTargetList()?.length) return <p> No List Found </p>;

        return filteredAudienceTargetList()?.map((item) => {
            const isSelected = selectedListItem?.find(l => l.id === item.id);

            return (
                    <SelectListItem
                        key={item.id}
                        onClick={() => handleSelectItem(item)}
                        isSelected={isSelected}
                    >
                        {item.name}
                    </SelectListItem>
                )
            })
    }

    const removeAudienceTargetList = (item) => {
        const filteredSelectAudienceArr = selectedAudiences.filter(audience => item.id !== audience.id);
        const filteredSelectedArr = selectedListItem.filter(selected => item.id !== selected.id);
        setAudience(filteredSelectAudienceArr);
        setSelectedListItem(filteredSelectedArr);
    }

    const removeInterestTargetList = (item) => {
        const filteredInterestAudienceArr = selectedAudiences[id].filter(interest => item.id !== interest.id);
        selectedAudiences[id] = filteredInterestAudienceArr;

        const filteredSelectedArr = selectedListItem.filter(selected => item.id !== selected.id);
        if (isInterestTarget && id > 0 && selectedAudiences[id].length < 1) setIsInterestHidden(true);
        setSelectedListItem(filteredSelectedArr);
        setAudience(selectedAudiences);
    }

    const selectedAudiencesList = () => selectedAudiences?.map(item =>
        <SelectListItem
            key={item?.id}
            onClick={() => removeAudienceTargetList(item)}
        >
            {item?.name}
        </SelectListItem>)

    const selectedInterestsList = () => selectedAudiences[id]?.map(item =>
        <SelectListItem
            key={item?.id}
            onClick={() => removeInterestTargetList(item)}
        >
            {item?.name}
        </SelectListItem>)

    const handleFilter = (e) => {
        if (!isAudience && !isGoogle) {
            setTimeout(() => {
                setFilteredSearch(e.target.value);
            }, 1000)
            return;
        }
        setFilteredSearch(e.target.value);
    }

    return (
        <AudienceContainer type={type} isHidden={isInterestHidden}>
            <div className='result'>
                <h3> {type === 'include' ? 'Include ANY' : 'Exclude ALL'} of these {isAudience ? 'Audiences' : 'Interests'} </h3>
                <SelectListContainer>
                    {isInterestTarget ? selectedInterestsList() : selectedAudiencesList()}
                </SelectListContainer>
            </div>

            <div className='search'>
                { isGoogle &&
                    <RadioFilterContainer>
                        <input type="radio" id='userInterests' name={type + id} value="userInterests" onChange={(e) => setRadioFilter(e.target.value)} defaultChecked/>
                        <label> User Interests </label>

                        <input type="radio" id='lifeEvents' name={type + id} value="lifeEvents" onChange={(e) => setRadioFilter(e.target.value)}/>
                        <label> Life Events </label>

                        <input type="radio" id='demographics' name={type + id} value="demographics" onChange={(e) => setRadioFilter(e.target.value)}/>
                        <label> Demographics </label>
                    </RadioFilterContainer>

                }

                <div style={!isGoogle ? {margin: '20px 0'} : {}}>
                    <Search text={text} handleFilter={handleFilter} isModal={true} />
                </div>

                {
                    filteredSearch?.length > 0 &&
                    <>
                    <span> Search Results: </span>
                    {isLoading ? <Loader /> :
                    <SelectListContainer>
                        {audienceTargetList()}
                    </SelectListContainer>}
                    </>
                }
            </div>
        </AudienceContainer>
    )
}

export default AudienceTarget;