import React, { useState } from "react";
import Advertisers from "./Advertisers";
import UserRole from "./UserRole";

// Utils
import { capitalize } from "../../../../utils/strings";
import UserToogle from "./UserToggle";

// Styles
import { StyleUserManagmentContent } from "./styles";

const User = ({
              applyClass, user, isEdit,
              addAdvertiserArray, setAddAdvertiserArray,
              advertiserOptions, advertiserLoader, setAdvertiserLoader,
              advertisersToAdd, setAdvertisersToAdd,
              advertisersToRemove, setAdvertisersToRemove
            }) => {
  const [toggle, setToggle] = useState(user?.role !== "NONE");
  const [whatRole, setRole] = useState(capitalize(user?.role));
  const [loader, setLoader] = useState(false);

  const userRole = user?.role.charAt(0).toUpperCase() + user?.role?.slice(1)?.toLowerCase()

  return (
    <StyleUserManagmentContent className="for-user" applyClass={applyClass}>
      <div>{user.name}</div>
      <div style={{textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: 'nowrap', display: 'block'}}>{user.email}</div>
      {(isEdit && userRole !== 'Approve') ?
        <UserRole
          applyClass={applyClass}
          setRole={setRole}
          setToggle={setToggle}
          setLoader={setLoader}
          userId={user.id}
          role={whatRole}
          isToggleOn={toggle}
          loader={loader}
          isEdit={isEdit} /> :
        <div>{userRole === 'Approve' ? 'Approver' : userRole}</div>
      }

      <Advertisers
        user={user} isEdit={isEdit}
        advertiserOptions={advertiserOptions}
        addAdvertiserArray={addAdvertiserArray}
        setAddAdvertiserArray={setAddAdvertiserArray}
        advertiserLoader={advertiserLoader}
        setAdvertiserLoader={setAdvertiserLoader}
        setAdvertisersToAdd={setAdvertisersToAdd}
        advertisersToRemove={advertisersToRemove}
        setAdvertisersToRemove={setAdvertisersToRemove}
        role={whatRole}
      />

      <UserToogle
                loader={loader}
                setLoader={setLoader}
                setToggle={setToggle}
                setRole={setRole}
                user={user}
                toggle={toggle}
                isEdit={isEdit}
      />
      <div></div>
    </StyleUserManagmentContent>
  );
};

export default User;
