import React, { useState, useEffect, useRef } from 'react';
import { firebase } from '../../services/firebase.js';
import { useDispatch, useSelector } from 'react-redux';
import { LoginForm, SignUpForm } from './style';
import { TextLink, ErrorText } from '../../ui/text';
import { getUser, clearUser } from '../../actions/userActions';

const AUTH_CODE = {
    USER_NOT_FOUND:'auth/user-not-found',
    WRONG_PASSWORD: 'auth/wrong-password',
    EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use'
}

const Login = () => {
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const dispatch = useDispatch()

    const isProduction = window.location.origin.indexOf("http://localhost") === -1;

    const [ showSignUp, setShowSignUp ] = useState(false);
    const [ error, setError ] = useState('');

    const emailField = useRef(null);
    const passwordField = useRef(null);

    const signupEmailField = useRef(null);
    const signupPasswordField = useRef(null);
    const signupDisplayName = useRef(null);

    const authRef = useRef(isAuthenticated);
    authRef.current = isAuthenticated;

    const handleLogin = () => {
        dispatch(
            getUser({
                notFoundCallback: () => {
                    setShowSignUp(true)
                }
            })
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword();
    }

    const signInWithEmailAndPassword = () => {
        const email = emailField.current.value;
        const password = passwordField.current.value;
        if (!email || !password) return;
        console.log(`attempting login...  email: ${email} password: XXXXXXX`);
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => handleLogin())
            .catch(error => {
                handleError(error)
            });
    }

    const signUpWithEmail = () => {
        const email = signupEmailField.current.value;
        const password = signupPasswordField.current.value;
        if (!email || !password) return;
        console.log(`creating firebase user login...  email: ${email} password: XXXXXXX`);
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(user => handleLogin(user))
            .catch(error => {
                handleError(error)
            });
    }

    const handleError = (error) => {
        const { code } = error;

        if (code === AUTH_CODE.WRONG_PASSWORD) {
            setError('Incorrect username or password');
        }

        if (code === AUTH_CODE.USER_NOT_FOUND || code === AUTH_CODE.EMAIL_ALREADY_IN_USE) {

            const email = signupEmailField?.current?.value;
            const name = signupDisplayName?.current?.value;
            if (!showSignUp) {
                setShowSignUp(true);
                return;
            }

            if (!email || !name) return;
            createEulerityUser(email, name);
        }
    }

    const createEulerityUser = (email, name) => {
        let confirmText = `Create a new Eulerity account for email address ${email} ?`;
        if (!isProduction) confirmText += 'WARNING: You are on a development environment. This will overwrite any user that exists with this email. Do you want to continue?'

        if (window.confirm(confirmText)) {
            window.eulerity.makeApiCall('/api/user/create', 'POST', { 'email': email, 'name': name }, () => {
                console.log('User created')
                window.location = '/';
            });
        } else {
            console.log('Canceled create user')
        }
    }

    // We only need to handle this auth state change once and load the user details
    // Fixes auth state trigger being called multiple times and causing multiple getUser() api calls
    useEffect(() => {
        firebase.addCallback(function () {
            let _doAuthStateHandling = true;
            firebase.auth().onAuthStateChanged((user) => {
                if (_doAuthStateHandling) {
                    _doAuthStateHandling = false;
                    if (user) {
                        user.getIdToken().then(function (idToken) {
                            window.eulerity.firebaseIdToken = idToken;
                            handleLogin(user);
                        });
                    } else {
                        dispatch(clearUser())
                    }
                }
            });
        });
    }, [])

    // On production bounce user back to Eulerity Sign In screen if they are not logged in
    useEffect(() => {
        let redirectTimeout = setTimeout(() => {
            const isLoggedIn = authRef.current;
            if (isProduction && !isLoggedIn) window.location = '/'
        }, 3000)

        return () => clearTimeout(redirectTimeout)
    }, [isProduction])

    if (isProduction) {
        // return <FetchingData text={'Checking login status...'}/>
    }

    if (showSignUp) {
        return (
            <SignUpForm>
                <h2>Sign Up</h2>
                <input
                    type="text"
                    ref={signupEmailField}
                    placeholder="Email"
                />
                <br />
                <input
                    type="text"
                    ref={signupDisplayName}
                    placeholder="First & Last Name"
                />
                <br />
                <input
                    type="password"
                    ref={signupPasswordField}
                    placeholder="Password"
                />
                <br />
                <button onClick={signUpWithEmail}>Sign Up</button>
                <p>Already have an account? <TextLink onClick={() => setShowSignUp(false)}>Sign in here</TextLink></p>
                <ErrorText>{error}</ErrorText>
            </SignUpForm>
        )
    }

    return (
        <>
        {!isProduction && <LoginForm>
            <h2>Log In</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    ref={emailField}
                    placeholder="Email"
                    autoComplete="username"
                />
                <br />
                <input
                    type="password"
                    ref={passwordField}
                    placeholder="Password"
                    autoComplete="current-password"
                />
                <br />
                <button type="submit">Sign in</button>
                <p>New User? <TextLink onClick={() => setShowSignUp(true)}>Sign Up</TextLink></p>
                <ErrorText>{error}</ErrorText>
            </form>
        </LoginForm>}
        </>
    )
}

export default Login;