export const updateUserRole = (queryData) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall(`/api/user/setUserRole?uid=${queryData?.userId}&role=${queryData?.role?.toUpperCase()}`, "POST", null, _ => {
            resolve()
        }, {1000: reject})
    })
}


export const oAuthGetSocialLoginMethod = (URL ) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await makeGETPromiseApiCall(URL);
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })
}



export const displayNonAdminUsers = (usr) => {
    return usr.filter(it => it.role !== "SUPER_ADMIN")
}


export const makeGETPromiseApiCall = (url) =>  new Promise((resolve,reject) => {
    window.eulerity.makeApiCall(url, "GET", null, (response) => {
        resolve(response)
    }, {1000: reject})
})


export const makePOSTPromiseApiCall = (url, data={}) =>  new Promise((resolve,reject) => {
    window.eulerity.makeApiCall(url, "POST", data , (response) => {
        resolve(response)
    }, {1000: reject})
})


export const getCampaignSettingsProps = (_) => {
    return {
        adSetNamingConvention: _?.adSetNamingConvention,
        adGroupNamingConvention: _?.adGroupNamingConvention,
        facebookCampaignNamingConvention: _.facebookCampaignNamingConvention,
        googleCampaignNamingConvention: _.googleCampaignNamingConvention,
        liCampaignGroupNamingConvention: _?.liCampaignGroupNamingConvention,
        liCampaignNamingConvention: _?.liCampaignNamingConvention
    }
}

export const changesMadeForCampaignSettings = (globalProps, localProps) => {
    let results = {}
    for(let key in globalProps) {
        const globalString = globalProps[key]
        const loacalString= localProps[key]
        if(globalString !== loacalString) {
            results[key] = loacalString
        }
    }
    return results
}


export const changesMadeForLabels = (globalProps, localProps) => {
      let results = {}
        for(let key in globalProps) {
            let orgLabelValues = globalProps[key]
            let localPropsValues = localProps[key]
            if(orgLabelValues.length === localPropsValues.length) {
                orgLabelValues.forEach(label => {
                    if(!localPropsValues.includes(label)) {
                        results[key] = localPropsValues
                    }
                })
            } else {
                results[key] = localPropsValues
            }
        }
        return results
}


// * globalProps -> what the backend has
// * localProps -> initially equal to global, until user makes any data changes
// * function will compare local changes to backend, and create an object to return new fields to backend
export const changesMadeForObject = (globalProps, localProps) => {
    // * results - collection of changes to send to backend (will send an object w/ keys corresponding to a field in the backend)
    let results = {}

    // * For every key stored in backend
    for (let key in globalProps) {
        // * Take key values find matching key value pair on backend/local state
        let orgObjValues = Object.values(globalProps[key])
        let localObjValues = Object.values(localProps[key])

        // * compare arrays, and if any changes are not found in backend, add it into results object
        if (orgObjValues.length === localObjValues.length) {
            orgObjValues.forEach(val => {
                if (!localObjValues.includes(val)) {
                    results[key] = localProps[key]
                }
            })
        }
        // * If a new field is created that is not on the backend, add it to results
        else {
            results[key] = localProps[key]
        }
    }
    return results
}


export const networkSocialLogins = (logins=[], network) => {
    network = network?.toLowerCase()
    return logins.filter((it) => it.network === network);
}



//* the selected account should be the selected system account 
export const InitalSelection = (currentSysAccount, networkSocialLogin) => {
    const user = networkSocialLogin.find(it => it.id === currentSysAccount?.id);
    if(user) return user
    if(networkSocialLogin.length) return networkSocialLogin[0]
    return null
}


export const renderLabels = (options) => {
    return options?.map(opt => {
        return ({label: opt, value: opt})
    })
}

export const getSystemAccount = (orgData, network) => {
    network = network?.toLowerCase();
    return orgData?.managingLogins?.[network];
}

function haveSameElements(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (!arr2.includes(arr1[i])) {
        return false;
      }
    }
    return true;
  }

export const didValuesChangeForDummyData = (oldData, newData) => {
    if(oldData.name !== newData.name) return false
    if(oldData.logo !== newData.logo) return false

    if(!haveSameElements(oldData?.objectives, newData?.objectives)) return false


    return true
}


//DUMMY NETWORK CRUD OPERATIONS


export const createDummyNetwork = (orgId, createNetworkData) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await makePOSTPromiseApiCall(`/api/org/createDummyNetwork?orgId=${orgId}`, createNetworkData)
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })
}

export const updateDummyNetwork = (dummyNetworkWebsafe, updatedNetworkData) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await makePOSTPromiseApiCall(`/api/org/updateDummyNetwork?dummyNetwork=${dummyNetworkWebsafe}`, updatedNetworkData)
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })
}

export const deleteDummyNetwork = (dummyNetworkWebsafe) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await makePOSTPromiseApiCall(`/api/org/deleteDummyNetwork?dummyNetwork=${dummyNetworkWebsafe}`, {})
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })
}

// Options
export const propertyTypeOptions = [
    {
        label: 'Single Select',
        value: 'SINGLE_SELECT'
    },
    {
        label: 'Multi Select',
        value: 'MULTI_SELECT'
    },
    {
        label: 'Free Form Text',
        value: 'FREE_FORM'
    },
]

export const propertySourceOptions = [
    {
        label: 'LOB',
        value: 'LOB'
    },
    {
        label: 'Network Template',
        value: 'NETWORK_TEMPLATE'
    },
    {
        label: 'Media Plan',
        value: 'MEDIA_PLAN'
    },
]