import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteCsvModal from '../modal/DeleteCsvModal';

// Styles
import { FiPlusCircle } from 'react-icons/fi';
import { BiTrash } from 'react-icons/bi';
import { CsvTableContainer, CsvTableHeader, EmptyDataContainer } from './styles';
import { updateLob } from '../../../../actions/lobActions';
import { toast } from 'react-toastify';


const CsvTable = ({header, data, isEdit, setIsModalOpen}) => {
    const _D = useDispatch()
    const lob = useSelector(s => s.lob.currentLineOfBusiness)
    const [loader, setLoader] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteObj, setDeleteObj] = useState();

    const handleSelect = (obj) => {
        setDeleteObj(obj);
        setIsDeleteModalOpen(true);
    }

    const handleDelete = ({list, type}) => {
        const filteredSafetyMeasureList = data[type].filter(csv => !list.includes(csv.blobkey));
        data[type] = filteredSafetyMeasureList;
        setLoader(true)
        _D(updateLob({
            websafe: lob.websafe,
            updateObj: { brandSafetyMeasures: {...data}},
            callback: () => {
                setLoader(false)
                setIsDeleteModalOpen(false)
                toast.success('Deleted Csv')
            },
        }))
    }

    const renderBrandSafetyList = () => {
        const list = [];

        for (const safetyMeasure in data) {
            const csvNames = data[safetyMeasure]?.map(csv => csv.filename);
            const listOfCsvName = csvNames.join(', ');
            if (listOfCsvName) {
                list.push(
                    <li key={safetyMeasure}>
                        {safetyMeasure}
                            <div>
                                <span className='csvNames'>{listOfCsvName}</span>
                                {isEdit && <BiTrash onClick={() => handleSelect(safetyMeasure)}/>}
                            </div>
                    </li>
                )
            }
        }

        return list;
    }

    return (
        <CsvTableContainer>
            <CsvTableHeader>
                <h3> {header} </h3>
                <h3>
                    File(s)
                    {isEdit && <FiPlusCircle onClick={setIsModalOpen} />}
                </h3>
            </CsvTableHeader>
            {
                Object.keys(data)?.length ?
                renderBrandSafetyList() :
                <EmptyDataContainer>
                    <p> No {header} Available </p>
                </EmptyDataContainer>
            }
            {
                isDeleteModalOpen &&
                <DeleteCsvModal
                    handleDelete={handleDelete}
                    onClose={() => setIsDeleteModalOpen(false)}
                    data={data}
                    deleteObj={deleteObj}
                    loader={loader}
                    useLoader
                />
            }

        </CsvTableContainer>
    )
}

export default CsvTable;