import React, { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrg } from './actions/orgAction';
import { getAdvertiserList } from './actions/systemConfigActions';

// Routes
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

import Dashboard from './pages/dashboard/Dashboard';
import Templates from './pages/templates/Templates';
import MediaPlans from './pages/mediaplans/MediaPlans';
import LineOfBusiness from './pages/lineofbusiness/LineOfBusiness';
import SystemConfig from './pages/systemConfig';

// Components
import Sidebar from './components/Sidebar/Sidebar';
import Login from './components/Login/Login';

// Utils
import { serveImage } from './pages/systemConfig/components/labelManagement/label.utils';

// Constants
import { APP_PATH } from './constants/path';

// Styles
import { ThemeProvider } from 'styled-components';
import { AppContainer, MainContainer } from './styles';
import { themes } from './ui/themes';
import { GlobalStyles } from './ui/globalStyles';
import { StyledToast } from './ui/toastStyles';
import { Slide, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const {isAuthenticated, role} = useSelector(state => state.user);
  const org = useSelector(state => state.org.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window?.eulerity) {
      dispatch(getOrg());
      dispatch(getAdvertiserList({errorCallback: () => {
        toast.error('Something went wrong fetching Advertisers')
      }}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.eulerity])

  useEffect(() => {
    document.title = org?.name || '';
    const link = document.querySelector("link[rel*='icon']")
    link.href = serveImage(org?.icon)
  }, [org])


  if (!isAuthenticated) return <Login />;

  return (
    <Router>
      <AppContainer>
        <ThemeProvider theme={themes}>
        <GlobalStyles />
        <StyledToast
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          transition={Slide}
          pauseOnHover
        />
        <Sidebar />
        <MainContainer>
          <Routes>
            <Route path={APP_PATH} element={<Dashboard useNavigate={useNavigate} />} />
            <Route path='/' element={<Dashboard useNavigate={useNavigate} />} />
            <Route exact path={`${APP_PATH}/templates`} element={<Templates/>} />
            <Route exact path={`${APP_PATH}/plans`}  element={<MediaPlans/>} />
            <Route exact path={`${APP_PATH}/lob`}  element={<LineOfBusiness/>} />
            {(role === "SUPER_ADMIN" || role === "ADMIN") && <Route exact path={`${APP_PATH}/systemConfig`}  element={<SystemConfig />} />}
          </Routes>
        </MainContainer>
        </ThemeProvider>
      </AppContainer>
    </Router>
  );
}

export default App;
