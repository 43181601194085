import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';

// Utils
import { generateDropdownOptions } from '../../../../../utils/arrays';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const AdFormat = ({}) => {
    const { isEdit, campaignObj, handleUpdate, objectiveMap } = useContext(LinkedInCampaignContext);
    const { adFormat } = campaignObj;
    const adFormatOptions = generateDropdownOptions(objectiveMap['Website Visits']?.adFormats);

    return (
        <FieldContainer>
            <h3> Ad Format <span className='asterisk'>*</span> </h3>
            {
                isEdit ?
                <Select
                    isClearable
                    options={adFormatOptions}
                    defaultValue={{label: adFormat, value: adFormat}}
                    styles={selectTheme}
                    onChange={e => handleUpdate({key: 'adFormat', value: e.value})}
                /> :
                <p> {adFormat} </p>
            }
        </FieldContainer>
    )
}

export default AdFormat;