import { serveImage } from "../systemConfig/components/labelManagement/label.utils";
import { DummyLogo } from "../../ui/card/styles";
import GoogleIcon from "../../assets/images/socialMediaIcons/GoogleIcon.png";
import FbIcon from "../../assets/images/socialMediaIcons/FbIcon.png";
import linkedin from "../../assets/images/socialMediaIcons/linkedin.webp";
import { arraysEqual } from "../../utils/arrays";
import { shouldUpdateString } from "../../utils/payload";
import { NETWORKS } from "../../constants/networks";
import { FacebookI, GoogleI, LinkedinI } from "../../ui/icons";


export const templateModalController = (networkType) => {
  return {
    header: `Create New ${networkType} Template`,
    primaryText: `Create a new ${networkType} Template by entering the name.`,
  };
};

export const deleteTemplateText = {
  header: "Delete Network Template",
  deleteBtnText: "Delete Template",
  modalText: "Are you sure you want to delete ",
};

export const preloadPlatformAndPlacements = (allPlacements) => {
  const platformsContainer = [];
  let placementsContainer = [];

  for (let network in allPlacements) {
    const placements = allPlacements[network];
    platformsContainer.push({ label: network, value: network });
    placementsContainer = placementsContainer.concat(placements.map((it) => ({ label: it, value: it })));
  }

  return {
    platforms: platformsContainer.length ? platformsContainer : null,
    placements: placementsContainer.length ? placementsContainer : null,
  };
};

export const placementsColontroller = (mapedPlacements, prew) => {
  let newPlacements = [];
  prew.forEach((it) => {
    mapedPlacements.forEach((pl) => {
      if (pl.label === it.label) {
        newPlacements.push(pl);
      }
    });
  });
  return newPlacements?.length ? newPlacements : null;
};

export const checkForSimilarValue = (value, netwotkString) => {
  if (value !== "story") return value;
  const network = netwotkString?.split(" ")?.[0];
  return network + " " + value;
};

export const constructPlacements = (platforms, placements) => {
  const combinedPlacements = {};
  platforms?.forEach((platform) => {
    combinedPlacements[platform?.label] = [];
    for (let key in placements) {
      const placement = placements[key]?.label;
      if (placement.includes(platform?.label)) {
        combinedPlacements[platform?.label].push(placement);
      }
    }
  });
  return combinedPlacements;
};

const didPlacementsChange = (updatedObj, campaignObj) => {
  for (let key in updatedObj) {
    const u = updatedObj[key];
    const c = campaignObj[key];
    if (u.length !== c.length) return true;
    for (let i = 0; i < u.length; i++) {
      const updated = u[i];
      if (!c.includes(updated)) return true;
    }
  }
  return false;
};

const hasPlacementsChanged = (newPlacements, oldPlacements) => {
  return Object.keys(newPlacements).length !== Object.keys(oldPlacements).length
    || didPlacementsChange(newPlacements, oldPlacements);
};

const shouldUpdateKey = (newValue, oldValue) => {
  return newValue !== oldValue && (newValue || typeof newValue === "boolean");
};

export const campaignUpdatedController = (C, U) => {


  
  let updateObject = {};

  for (let key in U) {
    const updatedValues = U[key];
    const campaignValues = C[key];

    if (key === "placements") {
      if (hasPlacementsChanged(updatedValues, campaignValues)) updateObject[key] = updatedValues
    } else if (shouldUpdateKey(updatedValues, campaignValues)) updateObject[key] = updatedValues;
  }

  if (updateObject.bidStrategy && updateObject.bidStrategy !== "Bid Cap") {
    updateObject.budgetDeliveryPacing = null;
  }

  if (updateObject.bidAmount === "" ) {
    delete updateObject.bidAmount;
  }

  if (updateObject.advantagePlacements) {
    delete updateObject.placements;
  }

  return updateObject;
};


export const linkedinUpdateController = (C, U) => {
  let updatedObject = {};
  for (let key in U) {
    const updatedValues = U[key];
    const campaignValues = C[key];
    if (shouldUpdateKey(updatedValues, campaignValues)) {
        updatedObject[key] = updatedValues
    }
  }
  return updatedObject
}


export const checkForMissingFields = (updatedObj) => {
  const objective =    updatedObj.objective === "Awareness"
  const optimization = updatedObj.optimization === "Reach"

  if(objective && optimization) {
    const intervalDays = parseInt(updatedObj.frequencyCapIntervalDays);
    const capNum = parseInt(updatedObj.frequencyCapNum);
    if (intervalDays < 7) return "The min value for days is 7 days"
    if (capNum < 1) return  "The min value for impressions is 1";
  }

  if (updatedObj.objective && !updatedObj.optimization) return "Please select an Optimization";
  if (updatedObj.objective && updatedObj.optimization && !updatedObj.bidStrategy) return "Please select a Bid Strategy.";
  if (updatedObj.bidStrategy && updatedObj.bidStrategy === "Bid Cap") {
    if (!updatedObj.budgetDeliveryPacing) return "Campaign Budget Delivery Type";
  }
  if (updatedObj.bidAmount && (updatedObj.bidAmount < 0 || isNaN(updatedObj.bidAmount))) return "Please select larger bid Amount";

  if(updatedObj.objective && !updatedObj.billingEvent) return "When you get charged is required field"

};

export const deleteUnnecessaryFields = (_) => {
  delete _?.id;
  delete _?.skipUpdateTime;
  delete _?.updated;
  delete _?.websafe;
  return _;
};

export const decideBidControl = (bidStrategy, adSet) => {
  if (bidStrategy === "Cost per Goal" || bidStrategy === "Highest Volume") return {bidControllHeader: "Cost Per Result Goal", value: adSet?.cpaAmount};
  return {bidControllHeader: "Bid Amount", value: adSet?.bidAmount};
};

export const renderLogo = ({ dummyNetworks, data }) => {
  if (data?.network?.toLowerCase() === NETWORKS.GOOGLE.toLowerCase()) return   <GoogleI size="37px" />;
  if (data?.network?.toLowerCase() === NETWORKS.FACEBOOK.toLowerCase()) return <FacebookI  size="37px"/>;
  if (data?.network?.toLowerCase() === NETWORKS.LINKEDIN.toLowerCase()) return <LinkedinI size="37px" /> ;
  const currDummyNetwork = dummyNetworks.find((dummy) => dummy.name === data?.network);
  return <DummyLogo src={serveImage(currDummyNetwork?.logo)} alt="logo" style={{height: "37px", width: '37px'}}/>;
};

//* this function updates the campaign if the values have changed
export const updateDummyNetworkProps = (state, campaign) => {
  let didDummyPropsChanged = false;

  campaign.properties.forEach((property) => {
    const currentData = state[property.id];
    if(!currentData) return

    const isString = typeof currentData.values === "string";
    const isNotAnArray = !Array.isArray(currentData.values);

    if (isString) {
      if (shouldUpdateString(property.selectedValues?.[0], currentData.values)) didDummyPropsChanged = true;
      property.selectedValues = [currentData.values];
    } else {
      if (isNotAnArray) currentData.values = !currentData?.values ? [] : [currentData.values];
      if (!arraysEqual( property.selectedValues,currentData?.values.map((it) => it?.value))) didDummyPropsChanged = true;
      property.selectedValues = currentData?.values.map((it) => it?.value);
    }
  });
  return {
    didDummyPropsChanged,
  };
};

export const collectProperties = (campaign) => {
  if (!campaign?.properties) return {};
  const data = {};
  campaign?.properties.forEach((property) => {
    if (property.type === "FREE_FORM") {
      data[property.id] = { values: property.selectedValues.join(" ") };
    } else {
      data[property.id] = { values: property.selectedValues.map((it) => ({ label: it, value: it })) };
    }
  });
  return data;
};

export const howToController = (bulkUploadInstructions, networkManagerInstructions, name) => {
  if (!bulkUploadInstructions && !networkManagerInstructions) return null;

  const bulkObj = bulkUploadInstructions ? { name, text: bulkUploadInstructions, type: 'Bulk Upload Sheet'} : null;
  const network = networkManagerInstructions ? { name, text: networkManagerInstructions, type: 'Network Manager' } : null;

  return [bulkObj, network].filter(item => item !== null);
};



export const objectToReactSelectItem = (object) => {
  const container = []
  for(let key in object) container.push({label: key, value: key})
  return container
}

// * Returns copytext for network template campaigns
export const getCampaignCopyText = (network) => {
  if (network === NETWORKS.LINKEDIN) return 'Campaign Group'
  else return 'Campaign'
}