import React, {useLayoutEffect, useState} from 'react'
import Loader from '../../../ui/Loader'
import CampaignSettings from './campaignSettings'
import LabelManagement from './labelManagement'
import UserManagment from './userManagment'
import NetworkManagement from './networkManagement';
import SystemAccounts from './systemAccounts'

// Modal
import AddNetworkModal from './networkManagement/modals/AddNetworkModal';

// Redux
import { useSelector } from 'react-redux';

// Styles
import gsap from 'gsap';
import { FaPlus } from 'react-icons/fa';
import { StyleSysyemConfigMain, StyleSystemConfigNavigator,StyleSysyemConfigDisplay, StyleSysyemConfigContent } from '../styles';
import { NavigationBtn, SubNavigationBtn, PlusNavigationBtnContainer, PlusNavigationBtn } from '../../../components/Sidebar/styles';
import Advertisers from './advertisers'

gsap.config({ nullTargetWarn: false });

const SystemConfigMain = ({loading, userManagmentData, setLoading, setIconRotation}) => {
    const [selected, setSelected] = useState("User Management");
    const [isAddNetworkModalOpen, setIsAddNetworkModalOpen] = useState(false);
    const dummyNetworks = useSelector(state => state?.org?.organization?.dummyNetworks);

    useLayoutEffect(() => {
        gsap.timeline().fromTo('.a-sys_config', {opacity: 0}, {opacity: 1})
        setIconRotation(prew => prew += 20)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selected, loading])

    const isDummy = dummyNetworks?.find(dummy => dummy.name === selected);
    const isNetworkManagement = selected === 'Google' || selected === 'Facebook' || isDummy;

    const NavigationBtnWrapper = ({name, isSelected, isLast}) => {
        return (
            <NavigationBtn
                onClick={setSelected.bind(null, name)}
                className='system-configBtn'
                isSelected={isSelected}
                isLast={isLast}
                >
                    {name}
            </NavigationBtn>
        )
    }

    const RenderSelectedComponent = () => {
        switch(selected) {
            case "User Management" :   return <UserManagment users={userManagmentData} />
            case 'Advertisers':  return <Advertisers />
            case "List Management":  return <LabelManagement  setLoading={setLoading}/>
            case "Campaign Settings": return <CampaignSettings setLoading={setLoading}/>
            case "System Accounts":   return <SystemAccounts/>
            default: return <NetworkManagement selected={selected} isDummy={isDummy} />
        }
    }

    const renderDummyNetworksList = () => {
        if (!dummyNetworks?.length) return;
        return dummyNetworks.map(dummy => {
            return <SubNavigationBtn key={dummy.websafe} isSelected={selected === dummy.name} onClick={() => setSelected(dummy.name)}> {dummy.name} </SubNavigationBtn>
        })
    }

    return (
        <StyleSysyemConfigMain>
            <StyleSystemConfigNavigator>
                <div className='btn-wrapper'>
                    <NavigationBtnWrapper name="User Management"    isSelected={selected === "User Management"}/>
                    <NavigationBtnWrapper name='Advertisers'   isSelected={selected === 'Advertisers'}/>
                    <NavigationBtnWrapper name="System Accounts"   isSelected={selected === "System Accounts"}/>
                    <NavigationBtnWrapper name="Campaign Settings" isSelected={selected === "Campaign Settings"}/>
                    <NavigationBtnWrapper name="List Management"  isSelected={selected === "List Management"} />
                    <NavigationBtnWrapper name="Network Management"  isSelected={isNetworkManagement} isLast={true} />
                </div>
                <SubNavigationBtn isSelected={selected === 'Facebook'} onClick={() => setSelected('Facebook')}> Facebook </SubNavigationBtn>
                <SubNavigationBtn isSelected={selected === 'Google'} onClick={() => setSelected('Google')}> Google </SubNavigationBtn>
                <SubNavigationBtn isSelected={selected === 'linkedin'} onClick={() => setSelected('linkedin')}> LinkedIn </SubNavigationBtn>
                {renderDummyNetworksList()}
                <PlusNavigationBtnContainer>
                    <PlusNavigationBtn onClick={() => setIsAddNetworkModalOpen(true)}>
                        <FaPlus /> Add Network
                    </PlusNavigationBtn>
                </PlusNavigationBtnContainer>
            </StyleSystemConfigNavigator>
            <StyleSysyemConfigDisplay>
                <StyleSysyemConfigContent>
                    {
                        loading ? <Loader size="70" /> : <div className='a-sys_config'><RenderSelectedComponent /></div>
                    }
                </StyleSysyemConfigContent>
            </StyleSysyemConfigDisplay>
            {isAddNetworkModalOpen &&
                <AddNetworkModal
                    onClose={() => setIsAddNetworkModalOpen(false)}
                    setSelected={setSelected}
                />
            }
        </StyleSysyemConfigMain>
    )
}


export default SystemConfigMain;