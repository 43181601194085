import { useEffect, useRef, useState } from 'react';
import useLastValue from './useLastValue';

/*
    This hook is used to do things like make api calls after the user hasn't typed
    after a certain timeout, configurable in the options param
    You also pass a cb to this hook, which is called once the timeout has expired

    It also returns convenient value and onChange properties. These are passed into
    the input you are waiting for a delay on.

    params:
    {
        cb: the function called after timeout expires
        options: {
            timeout: the amount of time in ms to wait before calling the cb (default 250)
        }
    }

    example usage:
    const Component = () => {
        const { value, onChange } = useDelayedInputCallback(() => {
            console.log("making api call...")
        })

        return <input value={value} onChange={onChange} />
    }
*/

const useDelayedInputCallback = (cb, options = {}) => {
    const TIMEOUT = options.timeout || 1000;

    const timeoutRef = useRef();
    const hasInput = useRef(false)

    const [value, setValue] = useState('');
    const lastValue = useLastValue(value)

    useEffect(() => {
        window.clearTimeout(timeoutRef.current);

        if(!hasInput.current || value === lastValue) return

        timeoutRef.current = window.setTimeout(() => cb(value), TIMEOUT);
    }, [value, lastValue, TIMEOUT, cb]);

    const onChange = (e) => {
        hasInput.current = true
        setValue(e.target.value);
    };

    return {
        value,
        setValue,
        onChange,
    };
};

export default useDelayedInputCallback;
