import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';

// Utils
import { generateDropdownOptions } from '../../../../../utils/arrays';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const normalize = (string) => {
    if (!string) return;
    const word = string?.toLowerCase()?.split('_')?.join(' ');
    return word?.charAt(0).toUpperCase() + word?.slice(1);
}

const CampaignBudgetInterval = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { budgetDuration } = campaignObj;

    const [budgetIntervalOptions, setBudgetIntervalOptions] = useState([]);

    useEffect(() => {
        window.eulerity.makeApiCall('/api/linkedin/budgetDurations', 'GET', null, response => {
            const result = response.map(option => {
                return {label: normalize(option), value: option}
            })
            setBudgetIntervalOptions(result);
        })
    }, [])

    return (
        <FieldContainer>
            <h3> Campaign Budget Interval <span className='asterisk'>*</span> </h3>
            {
                isEdit ?
                <Select
                    isClearable
                    options={budgetIntervalOptions}
                    defaultValue={{label: normalize(budgetDuration), value: budgetDuration}}
                    styles={selectTheme}
                    onChange={e => handleUpdate({key: 'budgetDuration', value: e.value})}
                /> :
                <p> {budgetDuration ? normalize(budgetDuration) : 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default CampaignBudgetInterval;