import styled, { css } from 'styled-components';

export const NoAdvertisersContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin: 50px auto;

    .svg_container {
        border-radius: 50%;
        background-color: ${props => props.theme.background.gray};
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
`;

export const AdvertisersTableContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 2fr 0.5fr;
    align-items: center;
    color: ${props => props.theme.card.gray};
    padding: 20px;
    min-height: 80px;

    h1 {
        font-family: 'GothamMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }

    img {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    li {
        list-style-type: disc;
    }

    li > a {
        text-decoration: underline;
        color: black;
    }

    .item {
        font-size: 14px;
    }

    .edit {

    }

    .edit {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        font-size: 20px;
        color: black;
        /* display: ${props => !props.isEdit && 'none'}; */
    }

    svg {
        cursor: pointer;
    }

    ${({idx}) => idx % 2 === 0 && css`
            background-color: ${props => props.theme.background.lightGray};
        `
    }

    .read-more {
        color: ${props => props.theme.color.yellow};
        cursor: pointer;
    }
`;