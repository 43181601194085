
// Convert date to readable format
export const dateNormalizer = date => {
    const newDate = new Date(date)
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return `${month}/${day}/${year}`;
}

// Sort by most recently created
export const sortByRecentlyCreated = (array) => array?.sort((a, b) => {
    return new Date(b.created) - new Date(a.created)
});

// * Returns todays date in MM/DD/YY format
export const getTodayDate = () => {
    const now = new Date();
    return now.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
    })
}

// * Returns users current time in HH:MM timeZone format (ex - 10:30 PM EST)
export const getCurrentTime = () => {
    const now = new Date()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        timeZone
    }
    const formattedTime = now.toLocaleDateString('en-US', options)
    return formattedTime
}

export const gmtDateString = datestr => datestr?.includes('GMT') ? datestr : datestr + ' GMT'

export const gmtToLocaleString = (datetime, format = 'datetime') => {
    // Use this to display the datetimes from the backend (GMT) in the user's local timezone

    // Check if this datetime has GMT in it
    const dateTimeGMT = gmtDateString(datetime)

    // Create new date object from this
    let date = new Date(dateTimeGMT)

    // Return the date to the user's locale timezone
    let options = { dateStyle: "medium", timeStyle: "long" };
    if (format === 'date') options = { dateStyle: "medium" };
    if (format === 'time') options = { timeStyle: "long" };
    if (format === 'none') options = {} // this is used when passing to Date constructor
    return `${date.toLocaleString("en-US", options)}`;
}