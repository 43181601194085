import React, { useState } from 'react'
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles'
import { FiPlusCircle } from 'react-icons/fi'
import DeleteTargetingModal from '../modal/DeleteTargetingModal'
import { BiTrash } from 'react-icons/bi'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { removeAdAccount } from '../../../../actions/lobActions'

const DummyNetworkTable = ({ title, icon, tableData, isEdit, handleOnCreate, dummy }) => {
    const _D = useDispatch()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const [selectedItem, setSelectedItem] = useState();

    // * Handle Item Selection - depending on the action type this will either edit/delete the item
    const handleItemSelection = (data) => {
        setSelectedItem(data)
        setIsDeleteModalOpen(true)
    }

    // * Handle deletion of dummy network account
    const handleDeleteItem = () => {
        setLoader(true)
        _D(removeAdAccount(
            {
                socialConfig: `duconfig=${dummy.websafe}`,
                obj: [selectedItem],
                callback: () => {
                    setIsDeleteModalOpen(false)
                    setLoader(false)
                    toast.success('Removing Ad Account');
                }
            }
        ))
    }

    const renderData = () => {
        return tableData.map(data => {

            return (
                <li key={data?.accountId}>
                    <ListObj>
                        <span>{data?.displayName}</span>
                        <span>Currency: {data?.currency}</span>
                    </ListObj>
                    <div className='opt__button__container'>
                        {isEdit && <BiTrash onClick={() => handleItemSelection(data)} />}
                    </div>
                </li>
            )
        })
    }

    return (
        <TableContainer>
            <TableHeader>
                <h3> {icon} {title}</h3>
                {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
            </TableHeader>

            {
                Object.keys(tableData || {})?.length > 0 ?
                    renderData() :
                    <EmptyDataContainer>
                        <p> No {title} Available </p>
                    </EmptyDataContainer>
            }

            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={() => handleDeleteItem(selectedItem)}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title={title}
                    item={selectedItem}
                    useLoader
                    loading={loader}
                />
            }
        </TableContainer>
    )
}

export default DummyNetworkTable