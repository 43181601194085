import React from 'react';
import Select from 'react-select';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const Objective = ({}) => {
    const objective = useSelector(state => state.media.currentCampaign?.networkTemplateCampaign?.objective);

    return (
        <FieldContainer>
            <h3> Objective <span className='asterisk'>*</span> </h3>
            <Select
                value={{label: objective, value: objective}}
                styles={selectTheme}
                isDisabled={true}
            />
        </FieldContainer>
    )
}

export default Objective;