import React from "react";
import styled from "styled-components";

const StyleHoverEffect = styled.div`
  background-color: inherit;
  display: grid;
  place-items: center;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 100%;
  transition: all 0.3s;
  :hover {
    background-color: ${(props) => props.theme.background.gray};
    svg {
      color: ${(props) => props?.color};
 
    }
  }
`;

const HoverEffect = (props) => {
  return <StyleHoverEffect color={props?.color}>{props.children}</StyleHoverEffect>;
};

export default HoverEffect;
