import React, { useState, useEffect } from 'react';
import MetaData from './components/MetaData';
import EditNetworkMetaDataModal from './modals/EditNetworkMetaDataModal';
import AddPropertyModal from '../modals/AddPropertyModal';
import Properties from './components/Properties';
import Loader from '../../../../ui/Loader';
import { toast } from 'react-toastify';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateDummyNetwork, updateSettings, createDummyProperties, updateDummyProperty } from '../../../../actions/orgAction';

// Styles
import { FaPlus } from 'react-icons/fa';
import { PageHeaderContainer, SubHeader } from '../../../../ui/headers';
import { EditSaveBtn, StyleCenterElement, OutlineIconBtn } from '../../../../ui/button';
import { FlexEndBtnContainer } from '../../../../ui/buttonContainers';
import FbIcon from '../../../../assets/images/socialMediaIcons/FbIcon.png';
import GoogleIcon from '../../../../assets/images/socialMediaIcons/GoogleIcon.png';
import LinkedInIcon from '../../../../assets/images/socialMediaIcons/linkedin.webp';

const NetworkManagement = ({ selected, isDummy }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [currNetwork, setCurrNetwork] = useState({});
    const [abbreviation, setAbbreviation] = useState('');
    const [logo, setLogo] = useState('');
    const [bulkUploadSheet, setBulkUploadSheet] = useState();
    const [bulkUploadUrl, setBulkUploadUrl] = useState();
    const [properties, setProperties] = useState([]);
    const [currProperties, setCurrProperties] = useState([]);
    const [propertiesToEdit, setPropertiesToEdit] = useState([]);
    const [dummyData, setDummyData] = useState({});

    // Modals
    const [isAddPropertiesModalOpen, setIsAddPropertiesModalOpen] = useState(false);
    const [isEditNetworkMetaDataModalOpen, setIsEditNetworkMetaDataModalOpen] = useState(false);

    // Selectors
    const dummyNetworks = useSelector(state => state?.org?.organization?.dummyNetworks);
    const platformMap = useSelector(state => state?.org?.organization?.platformMap);

    const dispatch = useDispatch();

    useEffect(() => {
        const dummyDataObj = isDummy && dummyNetworks?.find(dummy => dummy.name === selected);

        if (isDummy) {
            setCurrNetwork(dummyDataObj);
            setAbbreviation(dummyDataObj?.abbreviation);
            setBulkUploadSheet(dummyDataObj?.bulkUploadSheet);
            setBulkUploadUrl(dummyDataObj?.bulkUploadUrl);
            setLogo(dummyDataObj?.logo);
            setCurrProperties(dummyDataObj?.properties || []);
            setDummyData(dummyDataObj);
            return;
        }

        const dataObj = {};

        if (selected === 'Google') {
            dataObj.logo = GoogleIcon;
            dataObj.name = 'Google';
            dataObj.abbreviation = platformMap.google;
            setAbbreviation(platformMap.google);
        }

        if (selected === 'Facebook') {
            dataObj.logo = FbIcon;
            dataObj.name = 'Facebook';
            dataObj.abbreviation = platformMap.Facebook;
            setAbbreviation(platformMap.Facebook);
        }

        // TODO - MAKE THIS WORK WHEN BE SUPPORT IS THERE
        if (selected === 'linkedin') {
            dataObj.logo = LinkedInIcon;
            dataObj.name = 'linkedin';
            dataObj.abbreviation = platformMap.linkedin;
            setAbbreviation(platformMap.linkedin);
        }

        setCurrNetwork(dataObj);
    }, [selected, isDummy, dummyNetworks])

    const handlePropertiesToEdit = (websafe, data) => {
        const newArray = [...propertiesToEdit]
        const propertyObj = newArray.find(property => property.websafe === websafe);
        if (!propertyObj) {
            setPropertiesToEdit([...propertiesToEdit, { websafe, data }]);
        } else {
            const updatePropertyObj = { ...propertyObj, data }
            const updatedPropertyArray = newArray.map(property => property.websafe === propertyObj.websafe ? updatePropertyObj : property);
            setPropertiesToEdit(updatedPropertyArray);
        }
    }

    const handleEdit = () => {
        if (isEdit) {
            if (isDummy) {
                const isUpdatingMetaData = (logo !== dummyData.logo) || (abbreviation !== dummyData.abbreviation) ||
                    bulkUploadSheet !== dummyData?.bulkUploadSheet || bulkUploadUrl !== dummyData?.bulkUploadUrl;

                const isAddingNewProperties = properties.length;
                setLoader(true);
                const promises = [];

                if (isUpdatingMetaData) {
                    dispatch(updateDummyNetwork({
                        websafe: currNetwork?.websafe,
                        obj: { abbreviation, logo, bulkUploadUrl, bulkUploadSheet: bulkUploadSheet?.websafe },
                        callback: (_) => {
                            setLoader(false);
                            toast.success(`Updated ${currNetwork.name}'s property`)
                        }
                    }));
                }

                if (isAddingNewProperties) {
                    createDummyProperties({
                        websafe: currNetwork.websafe,
                        obj: properties,
                        callback: (res) => {
                            setLoader(false);
                            toast.success(`Updated ${currNetwork.name}'s properties`);
                        },
                        errorCallback: (err) => {
                            toast.error('Something went wrong');
                            setLoader(false);
                        }
                    });
                }

                if (propertiesToEdit.length) {
                    for (const property in propertiesToEdit) {
                        const { websafe, data } = propertiesToEdit[property];
                        promises.push(
                            new Promise((resolve, reject) => {
                                updateDummyProperty({
                                    websafe: websafe,
                                    obj: data,
                                    callback: (res) => {
                                        resolve(res);
                                    }
                                })
                            })
                        )
                    }
                }

                Promise.all(promises).then((data) => {
                    toast.success(`Successfully updated ${selected}`);
                    setLoader(false);
                }).catch((err) => {
                    console.log('err', err)
                })

                if (!isUpdatingMetaData && !isAddingNewProperties) setLoader(false);
            } else {
                setLoader(true);
                const updatedPlatformMap = { ...platformMap };

                if (selected === 'Google') updatedPlatformMap['google'] = abbreviation;
                if (selected === 'linkedin') updatedPlatformMap['linkedin'] = abbreviation;
                else updatedPlatformMap[selected] = abbreviation;

                dispatch(updateSettings({
                    obj: { platformMap: updatedPlatformMap },
                    callback: (res) => {
                        toast.success(`Updated ${selected}'s abbreviation to ${abbreviation}`)
                        setLoader(false);
                    }
                }))
            }
        }

        setIsEdit(!isEdit);
    }

    if (loader) return <StyleCenterElement><Loader size={50} /></StyleCenterElement>

    return (
        <div>
            <PageHeaderContainer>
                <h1> Network Management </h1>
            </PageHeaderContainer>
            <SubHeader>
                <span className='gray'> Manage {selected} </span>
                <div>
                    {EditSaveBtn(isEdit, handleEdit)}
                </div>
            </SubHeader>

            <MetaData
                network={currNetwork}
                abbreviation={abbreviation}
                logo={logo}
                bulkUploadSheet={bulkUploadSheet}
                bulkUploadUrl={bulkUploadUrl}
                isDummy={isDummy}
                isEdit={isEdit}
                setIsEditNetworkMetaDataModalOpen={setIsEditNetworkMetaDataModalOpen}
            />

            {isDummy && isEdit &&
                <FlexEndBtnContainer>
                    <OutlineIconBtn onClick={() => setIsAddPropertiesModalOpen(true)}><FaPlus /> Add Properties </OutlineIconBtn>
                </FlexEndBtnContainer>
            }

            <Properties
                isDummy={isDummy}
                properties={properties}
                setProperties={setProperties}
                currProperties={currProperties}
                isEdit={isEdit}
                propertiesToEdit={propertiesToEdit}
                setPropertiesToEdit={setPropertiesToEdit}
                handlePropertiesToEdit={handlePropertiesToEdit}
            />

            {
                isAddPropertiesModalOpen &&
                <AddPropertyModal
                    onClose={() => setIsAddPropertiesModalOpen(false)}
                    setProperties={setProperties}
                />
            }

            {
                isEditNetworkMetaDataModalOpen &&
                <EditNetworkMetaDataModal
                    onClose={() => setIsEditNetworkMetaDataModalOpen(false)}
                    abbreviation={abbreviation}
                    setAbbreviation={setAbbreviation}
                    name={currNetwork?.name}
                    logo={{ blobkey: logo }}
                    setLogo={setLogo}
                    bulkUploadSheet={bulkUploadSheet}
                    setBulkUploadSheet={setBulkUploadSheet}
                    bulkUploadUrl={bulkUploadUrl}
                    setBulkUploadUrl={setBulkUploadUrl}
                    isDummy={isDummy}
                />
            }
        </div>
    )
}

export default NetworkManagement;