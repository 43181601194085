import React, {useRef} from 'react';
import Select from 'react-select';
import useCsv from '../../../../../hooks/useCsv';
import { uploadBlob } from '../../../../../utils';

// Styles
import { selectThemeModal } from '../../../../../ui/themes';
import { UploadKeywordsContainer } from './styles';
import { CsvSelectContainer } from '../../../../../ui/table/styles';
import { FaFileUpload } from 'react-icons/fa';

const UploadKeywords = ({isNegative, selectedFiles, setSelectedFiles, matchType, matchTypeList, setMatchType, getDefaultMatchType}) => {
    const CsvUploader = useCsv();
    const fileInputRef = useRef(null);

    return (
        <UploadKeywordsContainer isNegative={isNegative}>
            <h1> Upload {isNegative && 'Negative'} Keyword Lists </h1>
            <h2> Select Match Type </h2>
            <Select
                isClearable
                options={matchTypeList}
                styles={selectThemeModal}
                onChange={e => setMatchType(e.value)}
                defaultValue={getDefaultMatchType(isNegative)}
                placeholder={'Select Keyword Match Type'}
            />

            <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(fileInputRef)}>
                <FaFileUpload size={50}/>
                <p> Select csv(s) to upload </p>
            </CsvSelectContainer>
            <input
                type='file'
                hidden
                accept='.csv'
                multiple={true}
                onChange={(e) => uploadBlob(
                    {
                        file: e.target.files[0],
                        callback: (file) => {
                            const updateObj = {...selectedFiles};

                            if (selectedFiles.hasOwnProperty(matchType)) {
                                updateObj[matchType] = [...updateObj[matchType], file]
                            } else {
                                updateObj[matchType] = [file]
                            }

                            setSelectedFiles(updateObj)
                        }
                    }
                )}
                ref={fileInputRef}
            />
        </UploadKeywordsContainer>
    )
}

export default UploadKeywords;