import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import CheckBox from '../../../../ui/input/checkbox/CheckBox'

const DisplayNetwork = ({ googleIcon, isEdit, displayNetwork, setDisplayNetwork }) => {
    return (
        <FieldContainer>
            <h3> {googleIcon} Display Network </h3>
            <CheckBox
                checked={displayNetwork}
                callback={() => setDisplayNetwork(!displayNetwork)}
                isDisabled={!isEdit}
                label='Include Google Display Network'
            />
        </FieldContainer>
    )
}

export default DisplayNetwork