import React from "react";
import DummyCampaignUnitLink from "./DummyCampaignUnitLink";
import { StyleDummyNetworkLinkUpload } from "./styles";

const DummyCampaignLinks = ({dummyNetworksCampaigns, handleOnInputChange, urls}) => {


  return (
    <StyleDummyNetworkLinkUpload>
      {
        (dummyNetworksCampaigns || []).map(campaign => {
          return  <DummyCampaignUnitLink campaign={campaign} handleOnInputChange={handleOnInputChange} url={urls[campaign.websafe]}/>
        })
      }
    </StyleDummyNetworkLinkUpload>
  );
};

export default DummyCampaignLinks;
