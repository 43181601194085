import React, { useState } from 'react';
import Search from '../../../../ui/search';

// Utils
import { serveImage } from '../../../systemConfig/components/labelManagement/label.utils';

// Styles
import { LobModalContainer } from '../../styles';
import { ModalText } from '../../../../components/Modal/styles';
import { ModalListContainer, ModalListHeader, ModalListItemWithBtn, ModalListWrapper } from '../../../../ui/list';
import { AdvertiserListItem } from './styles';
import { useSelector } from 'react-redux';

const AdvertiserStep = ({ register, setIsDisabled }) => {
    const advertiserList = useSelector(state => state.org.advertisers) || []

    const [filteredList, setFilteredList] = useState(advertiserList);
    const [selectedAdvertiser, setSelectedAdvertiser] = useState('');

    const renderDocuments = (documents) => {
        return documents.map(document => document.filename).join(', ');
    }

    const handleSelectAdvertiser = (websafe) => {
        setSelectedAdvertiser(websafe);
        setIsDisabled();
        register('advertiser', { value: websafe });
    }

    const listOfAdvertisers = () => {
        if (!advertiserList.length) return <ModalText> No Advertisers </ModalText>;

        return filteredList.map(advertiser => {
            const { websafe, name, logo, documents, description } = advertiser;
            return (
                <ModalListItemWithBtn
                    key={websafe}
                    isSelected={websafe === selectedAdvertiser}
                    onClick={() => handleSelectAdvertiser(websafe)}
                >
                    <AdvertiserListItem>
                        <div className='advertiser-data'>
                            <p><span>Advertiser: </span> {name} </p>
                            {documents?.length > 0 && <p><span>Documents: </span>{renderDocuments(documents)} </p>}
                            {description && <p>{description}</p>}
                        </div>
                        <div className='image-wrapper'>
                            {logo && <img src={serveImage(logo)} alt='logo' />}
                        </div>
                    </AdvertiserListItem>
                </ModalListItemWithBtn>
            )
        })
    }

    const handleFilter = e => {
        const input = e.target.value.toLowerCase();
        if (!input) {
            setFilteredList(advertiserList);
            return;
        }

        const filteredByInputList = advertiserList?.filter(advertiser => {
            const { name, documents, description } = advertiser;
            const hasName = name.toLowerCase().includes(input);
            const hasDocuments = documents?.length > 0 && documents.filter(document => document.filename.toLowerCase().includes(input)).length > 0;
            const hasDescription = description?.length > 0 && description.toLowerCase().includes(input);
            return hasName || hasDocuments || hasDescription;
        })

        setFilteredList(filteredByInputList);
    }

    return (
        <LobModalContainer>
            <ModalText> Create an advertiser for this LOB </ModalText>
            <Search
                text='Search for an advertiser'
                handleFilter={handleFilter}
            />
            <ModalListWrapper>
                <ModalListHeader> Advertisers</ModalListHeader>
                <ModalListContainer>
                    {listOfAdvertisers()}
                </ModalListContainer>
            </ModalListWrapper>
        </LobModalContainer>
    )
}

export default AdvertiserStep;