import React, {useState, useEffect} from 'react';
// Redux
import { LAUNCH, getCampaign } from '../../../actions/mediaActions';
import { useDispatch } from 'react-redux';

// Styles
import { CampaignLaunchItem } from '../styles';
import { WarningIcon } from '../../../ui/icons';
import { IoRocketOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';

const CampaignItem = ({websafe, setIsSelected, setSelectedCampaignURLS, campaignHeader = 'Campaign Name', adSetGroup = 'Ad Set'}) => {
    const [campaign, setCampaign] = useState({});
    const [message, setMessage] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!websafe) return;
        dispatch(getCampaign({
            websafe: websafe,
            callback: (res) => {
                if(res.campaignUrl) setSelectedCampaignURLS(prew => ({...prew, [websafe]: res.campaignUrl}))
                setCampaign(res)
            }
        }))
    }, [websafe])

    useEffect(() => {
        if (campaign.isDummy) {
            setMessage({
                text: `${campaign.name || "N/A"} Campaigns cannot be programmatically pushed`,
                type: 'dummy'
            });
            setIsDisabled(true);

            return;
        }

        if (!campaign?.adGroups?.length) {
            setMessage({text: 'No ad groups'});
            setIsDisabled(true);
        } else if (campaign.networkCampaignId) {
            setMessage({
                text: 'Campaign already published to network',
                type: 'launched'
            })
            setIsDisabled(true);
        } else {
            const missingName = campaign.adGroups.find(item => !item.name);
            if (missingName || !campaign?.name) {
                setMessage({
                    text: 'Campaign information not yet completed',
                })
                setIsDisabled(true);
            } else {
                setMessage({
                    text: '',
                    type: ''
                })
                setIsDisabled(false);
            }
        }
    }, [campaign])

    const handleLaunch = (websafe) => {
        LAUNCH({
            mediaPlanCampaign: websafe,
            callback: () => {
                toast.success("Campaigns submitted for launch");
                setIsSelected(false);
            },
            errorCallback: () => {
                toast.error('Unable to API push to network');
            }
        });
    };

    const isLaunched = message.type === 'launched';

    const renderAdGroups = () => {
        if (Object.keys(campaign).length === 0) return;

        return campaign?.adGroups.map(item => {
            return (
                <div>
                    <p><span>{adSetGroup}:</span> {item?.name || 'N/A'} </p>
                </div>
            )
        })
    }

    return (
        <CampaignLaunchItem isDisabled={isDisabled} isLaunched={isLaunched} key={websafe}>
            <div className='message'>
                <p> {isDisabled && !isLaunched && <WarningIcon />} {message.text} </p>
                <IoRocketOutline onClick={() => handleLaunch(campaign?.websafe)} />
            </div>

            <p> <span>{campaignHeader}:</span> {campaign?.name || 'N/A'} </p>
            {(campaign.isDummy && campaign.campaignUrl) && <p> <span>Campaign Url:</span> {campaign.campaignUrl} </p> }
            {renderAdGroups()}
        </CampaignLaunchItem>
    )
}

export default CampaignItem;