import React from 'react'
import styled from 'styled-components'
import { Input, NumberInput } from '../input'

const StyledCurrencyWrapper = styled.div`
    position: relative;
    width: 100%;

    .currencyTag {
        position: absolute;
        right: 10px;
        top: 14px;
    }
`
const CurrencyInput = ({onChange, value, currency}) => {
  return (
    <StyledCurrencyWrapper>
        <p className='currencyTag'>{currency}</p>
        <NumberInput fullWidth onChange={onChange} type='number' value={value} />
    </StyledCurrencyWrapper>
  )
}

export default CurrencyInput