import {
        CREATE_NETWORK, GET_NETWORK_LIST, DELETE_NETWORK,
        SELECT_TEMPLATE, UPDATE_TEMPLATE,
        CREATE_CAMPAIGN, UPDATE_CAMPAIGN, DELETE_CAMPAIGN,
        CLONE_NETWORK, SELECTED_CAMPAIGN,
    } from "./types";

export const getNetworkTemplateList = (callback) => dispatch => {
    const list = [];

    window.eulerity.makeBatchedApiCall({
        url: `/api/networktemplate/list`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        doneCallback: () => {
            dispatch({
                type: GET_NETWORK_LIST,
                payload: list
            })
            callback?.(list)
        }
    })
}

export const createNetworkTemplate = ({obj, advertiser, callback}) => dispatch => {
    if (!obj.name || !obj.network) return;
    const advertiserParams = advertiser ? `?advertiser=${advertiser}` : '';

    window.eulerity.makeApiCall(`/api/networktemplate/create${advertiserParams}`, 'POST', obj, response => {
        dispatch({
            type: CREATE_NETWORK,
            payload: response
        })
        callback?.(response);
    })
}


export const deleteNetworkTemplate = ({websafe, callback, errorCallback}) => dispatch => {
    if (!websafe) return;

    try {
        window.eulerity.makeApiCall(`/api/networktemplate/delete?networkTemplate=${websafe}`, 'POST', null, res => {
            dispatch({
                type: DELETE_NETWORK,
                payload: websafe
            })
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const cloneNetworkTemplate = ({copyName, id, callback}) => dispatch => {
    if (!id) return;
    window.eulerity.makeApiCall(`/api/networktemplate/clone?networkTemplateId=${id}`, 'POST', { name: copyName }, response => {
        dispatch({
            type: CLONE_NETWORK,
            payload: response
        })
        // * This is to open the new cloned network
        callback?.(response);
    })
}

export const selectTemplate = ({websafe, callback, errorCallback}) => (dispatch) => {
    try {
        window.eulerity.makeApiCall(`/api/networktemplate/get?networkTemplate=${websafe}`, 'GET', null, res => {
            dispatch({
                type: SELECT_TEMPLATE,
                payload: res
            })
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const resetTemplate = () => dispatch => {
    dispatch({
        type: SELECT_TEMPLATE,
        payload: {}
    })
}

export const getTemplate = (websafe) => {
    return new Promise ((resolve, reject) => {
        window.eulerity.makeApiCall(`/api/networktemplate/get?networkTemplate=${websafe}`, 'GET', null, response => {
            resolve(response)
        },{1000: reject})
    })
}

export const updateTemplate = ({websafe, updateObj, callback, errorCallback}) => dispatch => {
    if (!websafe) return;

    try {
        window.eulerity.makeApiCall(`/api/networktemplate/update?networkTemplate=${websafe}`, 'POST', updateObj, res => {
            dispatch({
                type: UPDATE_TEMPLATE,
                payload: res
            })
            callback(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

// Campaign
export const createCampaign = ({websafe, updateObj, callback, errorCallback}) => dispatch => {
    if (!websafe) return;

    try {
        window.eulerity.makeApiCall(`/api/networktemplate/createCampaign?networkTemplate=${websafe}`, 'POST', updateObj, res => {
            dispatch({
                type: CREATE_CAMPAIGN,
                payload: res
            })
            callback(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const updateTemplateCampaign = ({websafe, networkTemplateCampaignWebsafe, updateObj, callback, callbackError}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/networktemplate/updateCampaign?networkTemplate=${websafe}&networkTemplateCampaign=${networkTemplateCampaignWebsafe}`, 'POST', updateObj, res => {
            callback?.(res);
            dispatch({
                type: UPDATE_CAMPAIGN,
                payload: res
            })
        })
    } catch (err) {
        console.error(err);
        callbackError?.();
    }
}

export const deleteCampaign = ({websafe, networkTemplateCampaignWebsafe, callback, errorCallback}) => dispatch => {
    if (!websafe || !networkTemplateCampaignWebsafe) return;

    try {
        window.eulerity.makeApiCall(`/api/networktemplate/deleteCampaign?networkTemplate=${websafe}&networkTemplateCampaign=${networkTemplateCampaignWebsafe}`, 'POST', null, res => {
            callback(res);
            dispatch({
                type: DELETE_CAMPAIGN,
                payload: networkTemplateCampaignWebsafe
            })
        })
    } catch (err) {
        console.error(err);
        errorCallback?.();
    }
}

export const googleObjectives = (callback) => {
    window.eulerity.makeApiCall(`/api/google/objectives`, 'GET', null, res => {
        callback?.(res);
    })
}

export const getBiddingStrategies = (callback) => {
    window.eulerity.makeApiCall('/api/google/standardBidStrategies', 'GET', null, res => {
        callback?.(res);
    })
}

export const getImpressionShareLocation = (callback) => {
    window.eulerity.makeApiCall('/api/google/impressionShareLocations', 'GET', null, res => {
        callback?.(res);
    })
}

export const getNetworkObjectives = (network) => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall(`/api/${network}/objectiveMap`, 'GET', null, res => {
            resolve(res);
        }, {1000: reject})
    })
}

export const setSelectedCampaign = (campaign) => dispatch => {
    dispatch({
        type: SELECTED_CAMPAIGN,
        payload: campaign
    })
}
