import React from 'react';
import AudienceTarget from '../../AudienceTarget';

// Styles
import { RightCaption } from '../styles';
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { AudienceTargetContainer } from '../../../styles';

const ListAudienceStep = ({
    isAudience, listAudiences, socialLoginId, preSelectedAudiences,
    includeAudiences, selectedListItem, excludeAudiences,
    setIncludeAudiences, setSelectedListItem, setQueryInterests, setExcludeAudiences, setExcludeQueryInterests,
    audienceDescription, isLoading
}) => {
    return (
        <>
            <RightCaption> Audience Description: {audienceDescription} </RightCaption>
            <ModalTextSecondary> Add Audience List(s) to Facebook Adset </ModalTextSecondary>
                <AudienceTargetContainer>
                {
                    <AudienceTarget
                        type='include'
                        text='Search for audience or add audience ID manually'
                        isAudience={isAudience}
                        selectedAudiences={includeAudiences}
                        setAudience={setIncludeAudiences}
                        setSelectedListItem={setSelectedListItem}
                        selectedListItem={selectedListItem}
                        searchResults={listAudiences}
                        id={0}
                        setQueryInterests={setQueryInterests}
                        socialLoginId={socialLoginId}
                        preSelectedItems={preSelectedAudiences}
                        isLoading={isLoading}
                    />
                }
                    <AudienceTarget
                        type='exclude'
                        text='Search for audience or add audience ID manually'
                        isAudience={isAudience}
                        isExclude={true}
                        selectedAudiences={excludeAudiences}
                        setAudience={setExcludeAudiences}
                        setSelectedListItem={setSelectedListItem}
                        selectedListItem={selectedListItem}
                        searchResults={listAudiences}
                        setExcludeQueryInterests={setExcludeQueryInterests}
                        socialLoginId={socialLoginId}
                        preSelectedItems={preSelectedAudiences}
                        isLoading={isLoading}
                        />
                    </AudienceTargetContainer>
        </>
    )
}

export default ListAudienceStep;