import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { Input } from '../../../../ui/input/input';
import Select from 'react-select';

// Styles
import { ModalText } from '../../../../components/Modal/styles';
import { selectTheme } from '../../../../ui/themes';
import { BtnContainer } from '../../../../components/Modal/styles';
import { OutlineBtn, BtnWithLoader } from '../../../../ui/button';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { attachDummyAdAccount } from '../../../../actions/lobActions';

const AddDummyAdAccountModal = ({onClose, currencyList, dummy }) => {
    const _D = useDispatch()
    const currNetworkConfig = useSelector(s => s.lob.currentNetworkConfig)

    const [loader, setLoader] = useState(false)
    const [currency, setCurrency] = useState()
    const [adAccount, setAdAccount] = useState('')

    const isDisabled = !adAccount?.length || !currency?.length

    const handleAddAdAccount = () => {
        setLoader(true)
        const updatedAccounts = [...currNetworkConfig?.adAccounts, {
            currency,
            displayName: adAccount
        }]

        _D(attachDummyAdAccount({
            socialConfig: `duconfig=${dummy.websafe}`,
            pages: updatedAccounts,
            callback: () => {
                toast.success('Adding Ad Account');
                onClose()
                setLoader(false)
            }
        }))
    }

    return (
        <Modal
            header='Add Ad Account'
            width='600px'
            onClose={onClose}
        >
            <ModalText> Enter Ad Account <span className='asterisk'>*</span> </ModalText>
            <Input onChange={(e) => setAdAccount(e.target.value)} placeholder='Enter Ad Account' />

            <ModalText> Select Currency <span className='asterisk'>*</span> </ModalText>
            <Select
                isClearable
                options={currencyList}
                styles={selectTheme}
                onChange={e => setCurrency(e.value.currency)}
            />
            <BtnContainer>
                <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                <BtnWithLoader btnText='Add Ad Account' loader={loader} onClick={handleAddAdAccount} type="blackBtn" isDisabled={isDisabled}/>
            </BtnContainer>
        </Modal>
    )
}

export default AddDummyAdAccountModal;