import React, { useState, useRef, useEffect } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import Loader from '../../../../../ui/Loader';
import { Input } from '../../../../../ui/input/input';

// Utils
import useCsv from '../../../../../hooks/useCsv';
import { uploadBlob, getBlobFile } from '../../../../../utils';
import { serveImage } from '../../labelManagement/label.utils';

// Styles
import { ModalTextSecondary, ModalContentContainer, ModalFieldContainer, ListBarItem } from '../../../../../components/Modal/styles';
import { BlackBtn, CenterButtons, OutlineBtn } from '../../../../../ui/button';
import { CsvSelectContainer } from '../../../../../ui/table/styles';
import { TbCircleMinus } from 'react-icons/tb';
import { BsUpload } from 'react-icons/bs';

const EditNetworkMetaDataModal = ({onClose, isDummy, name,
        abbreviation, setAbbreviation, logo, setLogo,
        bulkUploadSheet, setBulkUploadSheet, bulkUploadUrl, setBulkUploadUrl
    }) => {
    const [currAbbreviation, setCurrAbbreviation] = useState(abbreviation);
    const [selectedLogo, setSelectedLogo] = useState([logo]);
    const [fileName, setFileName] = useState('');

    const [selectedCSV, setSelectedCSV] = useState(bulkUploadSheet);

    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isCsvLoading, setIsCsvLoading] = useState(false);

    const CsvUploader = useCsv();
    const imgInputRef = useRef(null);
    const csvInputRef = useRef(null);

    // TODO - THIS GET'S CALLED TWICE (ENDPOINT) - we may want to refactor this in a way where we make the endpoint call upon opening the modal rather than the useEffect after it renders
    useEffect(() => {
        if (!logo?.blobKey) return
        getBlobFile(selectedLogo[0].blobkey, (res) => setFileName(res.filename));
    }, []);

    const renderSelectedFiles = (selectedFiles, setSelectedFiles) => {
        if (selectedFiles && Object.keys(selectedFiles)?.length) {
            return (
                <ListBarItem> {selectedFiles.filename || selectedFiles[0].filename || fileName}
                    <TbCircleMinus size={18} onClick={(e) => handleRemoveFile(e, setSelectedFiles)}/>
                </ListBarItem>
            )
        }

        if (!selectedFiles?.length || !selectedLogo[0].filename) return;

        return selectedFiles?.map((file, idx) => {
            return (
                <ListBarItem key={idx}> {fileName}
                    <TbCircleMinus size={18} onClick={(e) => handleRemoveFile(e, setSelectedFiles)}/>
                </ListBarItem>
            )
        })
    }

    const handleRemoveFile = (e, setSelectedFiles) => {
        setSelectedFiles([]);
        e.stopPropagation();
    }

    const handleUpdate = () => {
        setAbbreviation(currAbbreviation);
        if (isDummy && selectedLogo[0].blobkey !== logo) setLogo(selectedLogo[0].blobkey);
        if (isDummy && selectedCSV && (selectedCSV[0]?.websafe || (selectedCSV[0]?.websafe !== bulkUploadSheet?.websafe))) setBulkUploadSheet(selectedCSV[0]);
        onClose();
    }

    return (
        <Modal
            onClose={onClose}
            header='Edit Network Meta Data'
            style={{width: '600px'}}
        >
            <ModalContentContainer>
                <ModalFieldContainer>
                    <ModalTextSecondary> Network Name <span className='asterisk'>*</span></ModalTextSecondary>
                    <Input type='text' value={name} disabled />
                </ModalFieldContainer>

                {isDummy &&
                <ModalFieldContainer>
                    <ModalTextSecondary> Network Logo <span className='asterisk'>*</span> </ModalTextSecondary>
                    <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(imgInputRef)}>
                    {isLogoLoading ? <Loader /> : selectedLogo?.length ? <img src={serveImage(selectedLogo[0].blobkey)} alt='logo' /> : <BsUpload size={50}/>}
                    <br />
                    {renderSelectedFiles(selectedLogo, setSelectedLogo)}
                    </CsvSelectContainer>
                    <input
                        type='file'
                        hidden
                        accept='image/png, image/jpeg, image/svg'
                        onChange={(e) => {
                            setIsLogoLoading(true);
                            uploadBlob(
                                {
                                    file: e.target.files[0],
                                    callback: (file) => {
                                        setIsLogoLoading(false);
                                        setSelectedLogo([file]);
                                    }
                                }
                            )
                        }}
                        ref={imgInputRef}
                    />
                </ModalFieldContainer>
                }

                <ModalFieldContainer>
                    <ModalTextSecondary> Abbreviation <span className='asterisk'>*</span></ModalTextSecondary>
                    <Input type='text' value={currAbbreviation} onChange={e => setCurrAbbreviation(e.target.value)} placeholder='Enter Abbreviation' />
                </ModalFieldContainer>

                {isDummy && <>
                <ModalFieldContainer>
                <ModalTextSecondary> Sample Bulk Upload Sheet </ModalTextSecondary>

                <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(csvInputRef)}>
                    {isCsvLoading ? <Loader /> : <BsUpload size={50}/>}
                    <br />
                    {renderSelectedFiles(selectedCSV, setSelectedCSV)}
                </CsvSelectContainer>
                    <input
                        type='file'
                        hidden
                        accept='.csv'
                        onChange={(e) => {
                            setIsCsvLoading(true);
                            uploadBlob(
                                {
                                    file: e.target.files[0],
                                    callback: (file) => {
                                        setIsCsvLoading(false);
                                        setSelectedCSV([file]);
                                    }
                                }
                            )
                        }}
                        ref={csvInputRef}
                    />
                </ModalFieldContainer>

                <ModalFieldContainer>
                    <ModalTextSecondary> Enter Google Sheets URL </ModalTextSecondary>
                    <Input value={bulkUploadUrl} onChange={e => setBulkUploadUrl(e.target.value)} placeholder='Please enter Google Sheets URL' />
                </ModalFieldContainer>
                </>}

                {
                    CenterButtons(
                        <>
                            <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                            <BlackBtn onClick={handleUpdate}> Update Meta Data </BlackBtn>
                        </>
                    )
                }
            </ModalContentContainer>
        </Modal>
    )
}

export default EditNetworkMetaDataModal;