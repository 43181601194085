
import { makePOSTPromiseApiCall } from "../systemConfig/utils"

export const exportGoogleSheets =  (queryUrl) => {
    return new Promise(async(resolve, reject) => {
        try {
            const mediaPlan = await makePOSTPromiseApiCall(queryUrl)
            resolve(mediaPlan)
        } catch (error) {
            reject(error)
        }
    })
}