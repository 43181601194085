import React from 'react'

import { StyledToggle, ToggleCircle } from './styles';

const Toggle = ({ size, isOn, handleToggle }) => {
    return (
        <StyledToggle size={size} isOn={isOn} onClick={handleToggle}>
            <ToggleCircle size={size} isOn={isOn} />
        </StyledToggle>
    )
}

export default Toggle
