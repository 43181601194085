import React from 'react'
import { StyledTableItem } from '../../styles'

const Placement = ({placement, colored}) => {
  return (
    <StyledTableItem colored={colored}>
        <div className='each_item_info'>
            <div className='inner_wrapper'>
                <p className='inner_text'>
                    <span className='inner_header'>Placement: </span>
                    {placement.key}
                </p>
            </div>
            <div className='inner_wrapper'>
                <p className='inner_text'>
                    <span className='inner_header'>Abbrevation: </span>
                    {placement.value}
                </p>
            </div>
        </div>
    </StyledTableItem>
  )
}

export default Placement