import React from 'react'
import Select from 'react-select'
import { FieldContainer } from '../../../../ui/containers'

const BiddingStrategy = ({googleIcon, isEdit, bidStrategy}) => {
  return (
    <FieldContainer>
    <h3>{googleIcon} Bidding Focus Type</h3>
    {
        isEdit ?
            <Select
                defaultValue={{
                    label: bidStrategy,
                    value: bidStrategy
                }}
                isDisabled
            />
            :
            <p> {bidStrategy ? bidStrategy : 'N/A'} </p>
    }
</FieldContainer>
  )
}

export default BiddingStrategy