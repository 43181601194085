import React, { useState, useEffect } from 'react';
import DummyCampaignSetup from './DummyCampaignSetup';
import SubNavigation from '../../../components/SubNavigation/SubNavigation';
import DeleteModal from '../../../components/Modal/DeleteModal';
import Loader from '../../../ui/Loader';

// Helpers
import { shouldUpdateString } from '../../../utils/payload';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getLob } from '../../../actions/lobActions';
import { getNetworkTemplateList, getTemplate } from '../../../actions/tempActions';
import { deleteCampaign, updateCampaign } from '../../../actions/mediaActions';
import { getAdvertiser } from '../../../actions/systemConfigActions';

// Styles
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn, HeaderIconBtnContainer, RedOutlineIconBtn, StyleCenterElement } from '../../../ui/button';
import { TemplateCard } from '../styles';
import { Label } from '../../../ui/card/styles';
import { Text } from '../../../ui/text';

import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { getCampaign } from '../../../actions/mediaActions';
import { WarningIcon } from '../../../ui/icons';
import { StyledFieldsWrapper } from '../../../ui/containers';
import { dateCheck, stringToDateconverter } from './utils';
import { collectProperties, updateDummyNetworkProps } from '../../templates/templates.util';

const DummyCampaignDetails = ({websafe, mediaPlan, resetPage, isEdit, setIsEdit}) => {
    const [dummyNetwork, setDummyNetwork] = useState();

    // Flight Details
    const [name, setName] = useState('');
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [budget, setBudget] = useState();
    const [adAccount, setAdAccount] = useState();
    const [budgetDuration, setBudgetDuration] = useState(null)

    // Originally Derived from Template
    const [objective, setObjective] = useState('');

    const [lob, setLob] = useState();
    const [template, setTemplate] = useState();
    const [currNav, setCurrNav] = useState('Campaign Setup');
    const [loader, setLoader] = useState(true)
    const [deleteLoader, setDeleteLoader] = useState(false)

    const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);
    const [dummyNetworkProperties, setDummyNetworkProperties] = useState({});

    // Advertiser
    const [advertiser, setAdvertiser] = useState({});

    const dispatch = useDispatch();

    const networkList = useSelector(state => state.template.templateList);
    const dummyNetworkList = useSelector(state => state.org.organization.dummyNetworks);
    const campaign = useSelector(state => state.media.currentCampaign);

    const isEditable = mediaPlan?.lob?.name;

    useEffect(() => {
        setLoader(true)
        dispatch(getCampaign({
            websafe,
            callback: (res) => {
                const dummyData = dummyNetworkList.find(dummy => dummy.name === res.network);
                setDummyNetwork(dummyData);
                setLoader(false)
            },
            errorCallback: (err) => {
                console.error(err);
                toast.error('Something went wrong');
            }
        }))
        dispatch(getNetworkTemplateList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [websafe]);

    useEffect(() => {
        if (!campaign?.websafe) return;
        const network = networkList.find(net => net.websafe === campaign?.networkTemplateCampaign?.networkTemplate?.websafe);
        if (!campaign?.websafe || !network?.websafe) return;

        getTemplate(network?.websafe).then(res => {
            setTemplate(res);
            if (res?.advertiser?.websafe) {
                getAdvertiser({
                    websafe: res.advertiser.websafe,
                    callback: advertiserData => setAdvertiser(advertiserData)
                })
            }
        }).catch(err => console.error(err))
        if (!campaign?.adGroups?.length) setCurrNav('Campaign Setup');
        setDummyNetworkProperties(collectProperties(campaign));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    useEffect(() => {
        if (!mediaPlan?.websafe || !mediaPlan?.lob) return;

        getLob({
            lobId: mediaPlan?.lob?.websafe,
            callback: (res) => setLob(res)
        })
    }, [mediaPlan])

    useEffect(() => {
        clearCampaignFields();
        if (campaign?.name) setName(campaign?.name);
        if (campaign?.start) setStart(campaign?.start);
        if (campaign?.end) setEnd(campaign?.end);
        if (campaign?.budget) setBudget(campaign?.budget);
        if (campaign?.objective) setObjective(campaign?.objective);
        if (campaign?.budgetDuration) setBudgetDuration(campaign?.budgetDuration);

    }, [template, campaign, lob])

    let isCampaignRunnable = () => true //TODO write condition to check if campaign is runnable (ask nirma)

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};
            const errorMessages = [];

            const { startDate, endDate } = stringToDateconverter(start, end);

            //* REQUIRED FIELDS
            if(!start)  errorMessages.push({message: "Please select Start Date"})
            if(!end)    errorMessages.push({message: "Please select End Date"})
            if(!dateCheck(startDate, endDate)) errorMessages.push({message: 'End Date should be greater than Start Date'})
            if(!budget) errorMessages.push({message: "Enter Budget to Launch Campaign"})
            const { startDate: campStartDate, endDate: campEndDate } = stringToDateconverter(campaign?.start, campaign?.end)


            if (shouldUpdateString(name, campaign?.name)) updateObj.name = name;
            if (startDate && startDate !== campStartDate) updateObj.start = startDate;
            if (endDate && endDate !== campEndDate) updateObj.end = endDate;
            if (budget && budget !== campaign?.budget) updateObj.budget = budget;

            if (shouldUpdateString(objective, campaign?.objective)) updateObj.objective = objective;
            if (shouldUpdateString(budgetDuration, campaign?.budgetDuration)) updateObj.budgetDuration = budgetDuration;

            if (errorMessages.length > 0) {
                toast.error(errorMessages[0].message)
                return;
            }
            const { didDummyPropsChanged } = updateDummyNetworkProps(dummyNetworkProperties, campaign);
            if(didDummyPropsChanged) updateObj.properties = campaign.properties
            if (Object.keys(updateObj).length) {
                setLoader(true)
                dispatch(updateCampaign({
                    mediaPlanCampaign: websafe,
                    obj: updateObj,
                    callback: (res) => {
                        setLoader(false)
                        toast.success(`Updated campaign`);
                    }
                }))
            }
        }
        setIsEdit(!isEdit);
    }

    const handleModalDeleteCampaign = () => {
        setDeleteLoader(true)
        dispatch(deleteCampaign({
            mediaPlanCampaign: websafe,
            callback: () => {
                setIsDeleteCampaignModalOpen(false);
                toast.success(`Deleted campaign`);
                resetPage('campaign');
                setDeleteLoader(false)
            }
        }))
    }

    const clearCampaignFields = () => {
        setName();
        setObjective();
        setStart();
        setEnd();
        setBudget();
    }

    const navigationObj = [
        {
            name: 'Campaign Setup',
            component: <DummyCampaignSetup
                isEdit={isEdit} handleEdit={handleEdit}
                lobName={lob?.name} templateName={template?.name}
                campaign={campaign}
                name={name} setName={setName}
                start={start} setStart={setStart}
                end={end} setEnd={setEnd}
                budget={budget} setBudget={setBudget}
                adAccount={adAccount} setAdAccount={setAdAccount}
                objective={objective} setObjective={setObjective}
                budgetDuration={budgetDuration} setBudgetDuration={setBudgetDuration}
                dummyNetwork={dummyNetwork}
                dummyNetworkProperties={dummyNetworkProperties}
                setDummyNetworkProperties={setDummyNetworkProperties}
            />
        }
    ]

    if(loader) return <StyleCenterElement><Loader size={50} /></StyleCenterElement>
    return (
        <StyledFieldsWrapper>
            <SubHeader>
                <HeaderIconBtnContainer>
                    {dummyNetwork?.name} Campaign
                    {!isCampaignRunnable() && <RedOutlineIconBtn style={{pointerEvents: 'none'}}><WarningIcon /> Unable to run Campaign </RedOutlineIconBtn>}</HeaderIconBtnContainer>
                {isEditable && <HeaderIconBtnContainer>
                    <RedOutlineIconBtn onClick={() => setIsDeleteCampaignModalOpen(true)}> <BiTrash /> Delete Campaign </RedOutlineIconBtn>
                    {currNav === 'Campaign Setup' && EditSaveBtn(isEdit, handleEdit)}
                </HeaderIconBtnContainer>}
            </SubHeader>

            {name && <Text> CAMPAIGN NAME: {name} </ Text>}

            <TemplateCard>
                <Text> <span>NETWORK TEMPLATE</span>: {template?.name} </Text>
                <Text> <span>NETWORK TEMPLATE TYPE</span>: Advertiser - {advertiser?.name || 'All Advertisers'} </Text>

                <div>
                    <Label> Goal : {template?.goal || 'N/A'} </Label>
                    <Label> Priority : {template?.priority || 'N/A'} </Label>
                </div>

                {template?.description && <Text> <span>DESCRIPTION</span>: {template?.description} </Text>}
            </TemplateCard>

            <SubNavigation navigationObj={navigationObj} currNav={currNav} setCurrNav={setCurrNav} />

            {
                isDeleteCampaignModalOpen &&
                <DeleteModal
                    header='Delete Campaign'
                    modalText={`Deleting ${campaign?.name} campaign`}
                    deleteBtnText='Delete Campaign'
                    setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
                    handleDelete={handleModalDeleteCampaign}
                    loading={deleteLoader}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default DummyCampaignDetails;