import React from 'react'
import Modal from "../../../../components/Modal/Modal";
import { BlackBtn, OutlineBtnLg } from '../../../../ui/button';
import { SmBtnContainer } from '../../../../components/Modal/styles';
import { WarningModalContainer } from '../../styles';

const WarningModal = ({onClose, goal, priority, handleSave}) => {
  return (
    <Modal header='Are you sure you want to proceed?' onClose={onClose}>
        <WarningModalContainer>
            <p>You have a chosen a Network Template that doesn't match the goal: <span>{goal}</span> and priority: <span>{priority}</span> you initially chose. Are you sure you want to proceed?</p>
            <SmBtnContainer>
              <BlackBtn onClick={handleSave}>Proceed Anyways</BlackBtn>
              <OutlineBtnLg onClick={onClose}>Select Different Template</OutlineBtnLg>
            </SmBtnContainer>
        </WarningModalContainer>
    </Modal>
  )
}

export default WarningModal