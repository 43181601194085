import React, { useState, useEffect } from 'react';
import Card from '../../../ui/card/Card';
import LobListTop from './LobListTop';
import CreateLobModal from './modal/CreateLobModal';

// Redux
import { useSelector } from 'react-redux';
import { deleteLob, selectLob } from '../../../actions/lobActions';

// Utils
import { sortByRecentlyCreated } from '../../../utils/dates';

// Style
import { OverviewContainer } from '../../../ui/containers';
import { CardGridContainer } from '../../../ui/card/styles';

const Overview = ({register, setValue, handleSubmit, setIsEdit, getValues, setLoader}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const lobList = useSelector(state => state.lob.lineOfBusinessList);

    useEffect(() => {
        const sortedList = sortByRecentlyCreated(lobList);

        setFilteredList(sortedList);
    }, [lobList, isModalOpen])

    const handleFilter = e => {
        const input = e.target.value.toLowerCase();
        setFilteredList(lobList.filter(lob => {
            const name = lob.name.toLowerCase();
            const hasLabel = lob.labels.find(label => label.toLowerCase().includes(input));
            return name.includes(input) || hasLabel;
        }))
    }

    const deleteTextObj = {
        header: 'Delete Line of Business',
        deleteBtnText: 'Delete Lob',
        modalText: 'Are you sure you want to delete '
    }

    const cloneTemplateObj = {
        // * Texts
        headerType: 'Line of Business',
        secondaryText: 'Name of Line of Business',
        cloneType: 'line of business',
        dataList: lobList,
    }

    const Cards = filteredList?.map(lob =>
        <Card
            key={lob.id} data={lob}
            setIsEdit={setIsEdit} type='lob'
            deleteItem={deleteLob} selectItem={selectLob}
            deleteTextObj={deleteTextObj}
            cloneTemplateObj={cloneTemplateObj}
            setOverviewLoader={(res) => setLoader(res)}
        />);

    return (
        <OverviewContainer>
            <LobListTop handleClick={() => setIsModalOpen(true)} handleFilter={handleFilter}/>

            {!filteredList?.length && <p className='no-results'>No LOBs are found based on the filters applied</p>}

            <CardGridContainer>
                {Cards}
            </CardGridContainer>

            {isModalOpen &&
                <CreateLobModal
                    getValues={getValues}
                    onClose={() => setIsModalOpen(false)}
                    handleSubmit={handleSubmit}
                    register={register}
                    setValue={setValue}
                    setIsEdit={setIsEdit}
                />
            }
        </OverviewContainer>
    )
}

export default Overview;