import styled from "styled-components";

export const TemplatesContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.background.gray};
`;

// Create
export const CreateTemplateContainer = styled.div`
  padding: 30px 20px;
  display: grid;
  grid-template-columns: 170px auto;
`;

export const StyleTempHeader = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${(props) => props.theme.hover.gray};
  padding-bottom: 1rem;

  .template-header-tx {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;

export const StyleFacebookCampaignDetails = styled.div`
  opacity: 0;
  height: 100%;
  .platforms {
    transition: all 0.3s;
    opacity: ${props => props.isAdvantagePlacements ? 1 : 0 };
    visibility: ${props => props.isAdvantagePlacements ? "visible" : "hidden"};
  }
`;
