import { GET_USER, CLEAR_USER } from './types';

export const getUser = params => dispatch => {
    const { notFoundCallback } = params;
    try {
        window.eulerity.makeApiCall('/api/user/get', 'GET', null, response => {
            dispatch({
                type: GET_USER,
                payload: response
            })
        },
        {
            404: () => {
                notFoundCallback?.();
            }
        });
    } catch (err) {
        console.error('Unable to load user');
    }
}

export const clearUser = () => dispatch => {
    dispatch({
        type: CLEAR_USER
    })
}