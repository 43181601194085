import styled, {css} from 'styled-components';
import { OutlineIconBtn } from '../../../../ui/button';

const GothamMedium = css`
    font-family: 'GothamMedium';
    font-style: normal;
    font-weight: 500;
`;

// Fb Network Config
export const LoginContainer = styled.div`
    display: flex;
    margin: 40px 0;
    width: 100%;
    justify-content: flex-end;

    span {
        ${GothamMedium}
        font-size: 14px;
        color: ${props => props.theme.color.yellow};
        cursor: pointer;
    }
`;

export const FacebookConnectionContainer = styled.div`
    background-color: ${props => props.theme.background.lightGray};
    padding: 20px;
    margin: 20px auto;
    text-align: center;

    h2 {
        ${GothamMedium}
        font-size: 20px;
    }

    h2 > span {
        ${({isConnected}) => isConnected ?
            `color: green` :
            `color: red`
        }
    }
`;

export const ConnectSocialBtn = styled(OutlineIconBtn)`
    img {
        width: 24px;
        margin-right: 10px;
    }
`

export const RightCaption = styled.h2`
    font-family: 'GothamMedium';
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    margin: 10px 0;

    span {
        font-family: 'GothamBook';
        font-weight: 300;
    }
`;

export const AudienceTargetListContainer = styled.div`
    background: rgba(247, 247, 247, 0.4);
    border: 1px solid #D9D9D8;
    border-radius: 3px;
    padding: 40px;

    h3 {
        font-family: 'GothamLight';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;

        .green {
            color: ${props => props.theme.color.green};
            font-family: 'GothamMedium';
        }

        .red {
            color: ${props => props.theme.color.red};
            font-family: 'GothamMedium';
        }
    }
`;

export const ReviewInterestTargetContainer = styled.div`
    display: flex;
`;

export const AudienceBtnContainer = styled.div`
    margin: 10px 0;

    button {
        width: 30%;
    }

    @media only screen and (max-width: 992px)  {
        button {
            width: 240px;
        }
    }
`;

export const TargetCpaContainer = styled.div`
    width: 602px;

    .ad-account-list-container {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
`

export const CpaAccountItem = styled.div`
    background-color: #FAFAFA;
    padding: 20px;

    img {
        width: 20px;
        height: 20px;
    }

    .field-container {
        display: flex;
        flex-direction: column;
    }

    .field-header {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .field-content {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 4px;
    }

    .field-item {
        margin-top: 20px;
    }
`

export const StyleFacebookBiddingFocus = styled.div`
    max-width: 700px;
    line-height: 2;
    margin: 2rem 0;
    .header-text {
        width: 600px;
    }
    .google-account-fields {
        margin-top: 1rem;
        display: grid;
        gap: 1rem;
    }
    .google-account-fields--icons {
        display: flex;
        align-items: center;
        gap: 0.3rem;
         img {
            width: 24px;
            object-fit: contain;
        }
    }
    .bidding-foucs {
        padding: 1rem;
        margin-top: 2rem;
        background-color: ${p => p.theme.background.gray};
    }

    .pixel_container {
        border-top: 1px solid ${props => props.theme.color.gray};
        margin-top: 20px;
        padding-top: 20px;
    }

    .text {
        line-height: 20px;
        margin: 10px auto;
    }

    .adAccount {
        border: 1px solid ${props => props.theme.color.gray};
        padding: 20px;
        border-radius: 9px;

        h2 {
            font-size: 18px;
        }
    }

`