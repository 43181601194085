import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../../../components/Modal/modals/Modal';
import Select from 'react-select';
import useCsv from '../../../../hooks/useCsv';
import Loader from '../../../../ui/Loader';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateLob } from '../../../../actions/lobActions';

// Helpers
import { uploadBlob } from '../../../../utils';
import { isObjArraysEqual } from '../../../../utils/arrays';

// Const
import { BRAND_SAFETY_MEASURES } from '../../../../constants/lob';

// Styles
import { ModalTextSecondary, HeaderInputContainer, SubHeader, ListItem } from '../../../../components/Modal/styles';
import { BtnWithLoader, OutlineBtn } from '../../../../ui/button';
import { CsvSelectContainer } from '../../../../ui/table/styles';
import { FaFileUpload } from 'react-icons/fa';
import { TbCircleMinus } from 'react-icons/tb';
import { selectThemeModal } from '../../../../ui/themes';
import { toast } from 'react-toastify';

const BrandSafetyModal = ({onClose, brandSafetyMeasures}) => {
    const _D = useDispatch()
    const lob = useSelector(s => s.lob.currentLineOfBusiness)
    const [loader, setLoader] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [currSafetyMeasure, setCurrSafetyMeasure] = useState({
        label: 'Inclusion List',
        value: 'Inclusion List'
    });
    const fileInputRef = useRef(null);

    const CsvUploader = useCsv();

    useEffect(() => {
        setSelectedFiles(brandSafetyMeasures);
    }, [])

    const handleSave = () => {
        if (isObjArraysEqual(selectedFiles, brandSafetyMeasures[currSafetyMeasure?.label] || [])) return toast.error('No Changes Found')
        setLoader(true)
        _D(updateLob({
            websafe: lob.websafe,
            updateObj: { brandSafetyMeasures: {...selectedFiles}},
            callback: () => {
                onClose()
                setLoader(false)
                toast.success('Updated Brand Safety Measures')
            },
        }))
    }

    const brandSafetyMeasuresList = BRAND_SAFETY_MEASURES.map(measure => {
        return {
            label: measure,
            value: measure
        }
    })

    const handleDelete = (currFile, type) => {
        const filteredFiles = {...selectedFiles};
        filteredFiles[type] = filteredFiles[type].filter(file => currFile.websafe !== file.websafe);
        setSelectedFiles({...filteredFiles});
    }

    const renderSelectedFiles = () => {
        const filesArr = [];

        for (let type in selectedFiles) {
            for (let file in selectedFiles[type]) {
                const currItem = selectedFiles[type][file];
                filesArr.push(
                    <ListItem key={type + file}> {currItem?.filename} - ({type})<TbCircleMinus size={18} onClick={() => handleDelete(currItem, type)}/> </ListItem>
                )
            }
        }

        return filesArr;
    }

    const isDisabled = () => {
        if (isFileUploading) return true;
        if (Object.keys(selectedFiles).length > 0) return false;
        return true;
    }

    const handleChange = (e) => {
        setIsFileUploading(true);
        uploadBlob({
            file: e.target.files[0],
            callback: (file) => {
                const updatedObj = {...selectedFiles}
                const currType = currSafetyMeasure.value
                if (selectedFiles[currType]?.length > 0) {
                    updatedObj[currType] = [...updatedObj[currType], file];
                } else {
                    updatedObj[currType] = [file];
                }

                setIsFileUploading(false);
                setSelectedFiles(updatedObj);
            }
        })
    }

    return (
        <Modal
            header='Upload Brand Safety Measures'
            buttons={[
                <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>,
                <BtnWithLoader btnText='Update Brand Safety Measure' loader={loader} onClick={handleSave} type="blackBtn" isDisabled={isDisabled()}/>
            ]}
            onClose={onClose}
        >
            <>
                <HeaderInputContainer>
                    <ModalTextSecondary> Brand Safety Measures </ModalTextSecondary>
                    <Select
                        isClearable
                        options={brandSafetyMeasuresList}
                        styles={selectThemeModal}
                        value={currSafetyMeasure}
                        onChange={e => setCurrSafetyMeasure(e)}
                        placeholder='Select Brand Safety Measure'
                    />
                </HeaderInputContainer>

                <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(fileInputRef)}>
                    {isFileUploading ? <Loader /> :
                    <>
                    <FaFileUpload size={50}/>
                    <p> Select csv(s) to upload </p>
                    </>}
                </CsvSelectContainer>

                <input
                    type='file'
                    hidden
                    accept='.csv'
                    multiple={true}
                    onChange={handleChange}
                    ref={fileInputRef}
                />

                <SubHeader> Selected Files </SubHeader>
                {renderSelectedFiles()}
            </>
        </Modal>
    )
}

export default BrandSafetyModal;
