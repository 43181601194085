import { createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'GothamBook';
    transition: all 0.50s linear;
  }

  a {
    text-decoration: none;
  }
`