import React, { useEffect } from 'react';
import { InitialCreateModalContainer } from '../../../../components/Modal/styles';
import { ModalText, ModalTextSecondary } from '../../../../components/Modal/styles';
import { Input } from '../../../../ui/input/input';

const InfoStep = ({register, getValues, name, setName}) => {

    useEffect(() => {
        const { name } = getValues();
        if (name) setName(name);
    }, []);

    return (
        <InitialCreateModalContainer>
            <ModalText> Create a new Media Plan by entering the name. </ModalText>
            <ModalTextSecondary> Name of Media Plan <span className='asterisk'>*</span> </ModalTextSecondary>

            <Input type='text' value={name} {...register('name', { required: true })} onChange={(e) => setName(e.target.value)} />
        </InitialCreateModalContainer>
    )
}

export default InfoStep;