import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setOrganization } from "../../../../actions/orgAction";

import { EditSaveBtn } from "../../../../ui/button";
import { FieldContainer, StyleHeaderContainer } from "../../../../ui/containers";
import { PageHeader, SubHeader } from "../../../../ui/headers";
import { Textarea } from "../../../../ui/input/input";
import { StyleLabelManagementGrid } from "../../styles";
import { changesMadeForCampaignSettings, getCampaignSettingsProps, makeGETPromiseApiCall, makePOSTPromiseApiCall } from "../../utils";
import { StyleCampaignManagement } from "./styles";

import FbIcon from '../../../../assets/images/socialMediaIcons/FbIcon.png';
import GoogleIcon from '../../../../assets/images/socialMediaIcons/GoogleIcon.png';
import LinkedInIcon from '../../../../assets/images/socialMediaIcons/LinkedInIcon.png'
import NamingConventionModal from "../modals/NamingConventionModal";


const CampaignSettings = ({ setLoading }) => {
  const _D = useDispatch()

  const org = useSelector(state => state.org.organization);
  const _O = getCampaignSettingsProps(org)

  const [isEdit, setIsEdit] = useState(false);
  const [googleCampNaming, setGoogleCampNaming] = useState(_O.googleCampaignNamingConvention)
  const [facebookCampNaming, setFacebookCampNaming] = useState(_O.facebookCampaignNamingConvention)
  const [adGroupNaming, setAdGroupNaming] = useState(_O.adGroupNamingConvention)
  const [adSetNaming, setAdSetNaming] = useState(_O.adSetNamingConvention)
  const [lnCampaignGroup, setLnCampaignGroup] = useState(_O.liCampaignGroupNamingConvention)
  const [lnNamingConvention, setLnNamingConvention] = useState(_O.liCampaignNamingConvention)
  const [showNamingConventionModal, setShowNamingConventionModal] = useState(false)

  const fbIcon = <img src={FbIcon} alt='fbIcon' />
  const googleIcon = <img src={GoogleIcon} alt='googleIcon' />
  const linkedinIcon = <img src={LinkedInIcon} alt='linkedinIcon' />

  const handleOnSubmit = async () => {
    setIsEdit((prew) => !prew);

    const createCampaingMaper = {
      adSetNamingConvention: adSetNaming,
      adGroupNamingConvention: adGroupNaming,
      facebookCampaignNamingConvention: facebookCampNaming,
      googleCampaignNamingConvention: googleCampNaming,
      liCampaignGroupNamingConvention: lnCampaignGroup,
      liCampaignNamingConvention: lnNamingConvention
    }

    const updatesToBeMade = changesMadeForCampaignSettings(_O, createCampaingMaper);

    //* dont make an API call if nothing has changed.
    if (!Object.keys(updatesToBeMade).length) return;
    setLoading(true);
    try {
      await makePOSTPromiseApiCall("/api/org/updateSettings", updatesToBeMade);
      const refreshedOrgData = await makeGETPromiseApiCall("/api/org/get");
      _D(setOrganization(refreshedOrgData))
      toast.success("Labels have been updated");
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyleCampaignManagement>
      <StyleHeaderContainer>
        <PageHeader>
          <p className="action-header">{isEdit && <span className="action-header-span">Editing </span>}Campaign Settings</p>
        </PageHeader>
        <SubHeader>
          <div className="action-name">Campaign Default Fields</div>
          <div>{EditSaveBtn(isEdit, handleOnSubmit)}</div>
        </SubHeader>
        <SubHeader>
          <p className="learn-more-text" onClick={() => setShowNamingConventionModal(true)}>Learn more about Naming Conventions</p>
        </SubHeader>
      </StyleHeaderContainer>
      <StyleLabelManagementGrid>
        <FieldContainer>
          <h3>{fbIcon} Facebook Campaign Naming Convention</h3>
          {
            isEdit ? <Textarea value={facebookCampNaming}
              rows={5}
              placeholder={"Enter Facebook Naming Convention"}
              onChange={(e) => setFacebookCampNaming(e.target.value)} /> : <p> {facebookCampNaming || "N/A"}</p>
          }
        </FieldContainer>
        <FieldContainer>
          <h3>{fbIcon} Facebook Ad Set Naming Convention </h3>
          {isEdit ? <Textarea value={adSetNaming}
            rows={5}
            placeholder={"Enter Ad Set Naming Convention"}
            onChange={(e) => setAdSetNaming(e.target.value)} /> : <p> {adSetNaming || "N/A"} </p>}
        </FieldContainer>
        <FieldContainer>
          <h3>{googleIcon} Google Campaign Naming Convention</h3>
          {
            isEdit ? <Textarea value={googleCampNaming}
              rows={5}
              placeholder={"Enter Google Naming Convention"}
              onChange={(e) => setGoogleCampNaming(e.target.value)} /> : <p> {googleCampNaming || "N/A"}</p>
          }
        </FieldContainer>
        <FieldContainer>
          <h3>{googleIcon} Google Ad Group Naming Convention</h3>
          {isEdit ? <Textarea value={adGroupNaming}
            rows={5} placeholder={"Enter Ad Group Naming Convention"}
            onChange={(e) => setAdGroupNaming(e.target.value)} /> : <p> {adGroupNaming || "N/A"} </p>}
        </FieldContainer>
        <FieldContainer>
          <h3>{linkedinIcon} LinkedIn Campaign Group Naming Convention</h3>
          {
            isEdit ? <Textarea value={lnCampaignGroup}
              rows={5}
              placeholder={"Enter Campaign Group Naming Conventions"}
              onChange={(e) => setLnCampaignGroup(e.target.value)} /> : <p> {lnCampaignGroup || "N/A"}</p>
          }
        </FieldContainer>
        <FieldContainer>
          <h3>{linkedinIcon} LinkedIn Campaign Naming Convention</h3>
          {isEdit ? <Textarea value={lnNamingConvention}
            rows={5} placeholder={"Enter Campaign Naming Conventions"}
            onChange={(e) => setLnNamingConvention(e.target.value)} /> : <p> {lnNamingConvention || "N/A"} </p>}
        </FieldContainer>
      </StyleLabelManagementGrid>
      {
        showNamingConventionModal && <NamingConventionModal onClose={() => setShowNamingConventionModal(false)} />
      }
    </StyleCampaignManagement>
  );
};

export default CampaignSettings;
