import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Redux
import { productSetsList } from '../../../../../actions/lobActions';

// Styles
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { CatalogContainer } from './styles';
import { selectTheme } from '../../../../../ui/themes';

const ConfigureCatalogStep = ({socialLoginId, setProductSet, adAccount}) => {
    const [list, setList] = useState([]);
    const catalogName = adAccount?.catalog?.name;
    useEffect(() => {
        productSetsList({
            socialLoginId,
            catalogId: adAccount?.catalog?.id,
            callback: (res) => {
                const products = res.map(product => {
                    return ({label: product.name, value: product})
                })
                setList(products);
            }
        })
    }, [])

    return (
        <div>
            <ModalTextSecondary> Configure for catalog by selecting a media title to optimize sales campaigns. </ModalTextSecondary>
            <CatalogContainer>
                <h2> Configure for Catalog </h2>
                <ModalTextSecondary> Media Titles (Product Set) </ModalTextSecondary>

                <Select
                    isClearable
                    options={list}
                    styles={selectTheme}
                    onChange={e => setProductSet(e.value)}
                />
                {catalogName && <p className='catalog_label'> Catalog: {catalogName} </p>}
            </CatalogContainer>
        </div>
    )
}

export default ConfigureCatalogStep;