import React, { useState } from 'react';
import Modal from '../../../../components/Modal/modals/Modal';

// Styles
import { Input } from '../../../../ui/input/input';
import { ModalTextSecondary, HeaderInputContainer, BtnContainer } from '../../../../components/Modal/styles';
import { BtnWithLoader, OutlineBtn } from '../../../../ui/button';
import { toast } from 'react-toastify';

const LanguageModal = ({onClose, trackingCodes, handleTargetUpdate}) => {
    const [loader, setLoader] = useState(false)
    const [description, setDescription] = useState('');
    const [code, setCode] = useState('');

    const handleSave = () => {
        let updatedTrackingCodes = [];
        const trackingCode = {
            name: description,
            value: code
        }

        if (trackingCodes.find(code => code.value === trackingCode.value)) return toast.error('Tracking Code Value Already Exists!')
        updatedTrackingCodes = [...trackingCodes, trackingCode]

        setLoader(true)
        handleTargetUpdate({trackingCodes: updatedTrackingCodes}, () => {
            onClose()
            toast.success('Tracking Code Added')
            setLoader(false)
        })
    }

    return (
        <Modal
            header='Add Tracking Code'
            width='500px'
            buttons={[
                <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>,
                <BtnWithLoader btnText='Save Tracking Code' loader={loader} onClick={handleSave} type="blackBtn" isDisabled={(!code?.length || !description?.length)}/>
            ]}
            onClose={onClose}
        >
            <>
                <HeaderInputContainer>
                    <ModalTextSecondary> Tracking Description Label </ModalTextSecondary>
                    <Input type='text' onChange={e => setDescription(e.target.value)} />
                </HeaderInputContainer>

                <HeaderInputContainer>
                    <ModalTextSecondary> Tracking Code </ModalTextSecondary>
                    <Input type='text' onChange={e => setCode(e.target.value)} />
                </HeaderInputContainer>
            </>
        </Modal>
    )
}

export default LanguageModal;