import React, { useContext } from 'react';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { Input } from '../../../../../ui/input/input';
import { FieldContainer } from '../../../../../ui/containers';


const InsightTag = ({}) => {
    const { isEdit } = useContext(LinkedInCampaignContext);
    const insightTag = useSelector(state => state.media.currentCampaign?.adAccount?.insightTag);

    return (
        <FieldContainer>
            <h3> Insight Tag </h3>
            {
                isEdit ?
                <Input value={insightTag?.id || 'N/A'} disabled={true}/> :
                <p> {insightTag?.id || 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default InsightTag;