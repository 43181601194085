import styled from "styled-components";

export const StyledDescStep = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
`

export const StyledInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;

    .asterisk {
        color: ${props => props.theme.color.yellow};
    }
`

export const StyledAttributeStep = styled.div`
    width: 1020px;
    margin: 32px 0px;

    .header {
        display: flex;
        flex-direction: column;
        gap: 32px;

        p {
            font-family: 'GothamBook';
            line-height: 20px;
        }

        a {
            color: #000;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .attribute-wrap {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
`

export const StyledAttributeContainer = styled.div`
    margin-top: 16px;
`