import React, { useState, useEffect } from 'react';
import NoAdvertisers from './components/NoAdvertisers';
import AdvertisersTable from './components/AdvertisersTable';
import AdvertiserModal from './modal/AdvertiserModal';
import ArchiveModal from './modal/ArchiveModal';
import Loader from '../../../../ui/Loader';

// Redux
import { useSelector } from 'react-redux';
import { createAdvertiser, updateAdvertiser } from '../../../../actions/systemConfigActions';

// Styles
import { toast } from 'react-toastify';
import { PageHeaderContainer, SubHeader } from '../../../../ui/headers';
import { EditSaveBtn } from '../../../../ui/button';

const Advertisers = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [newAdvertisersList, setNewAdvertisersList] = useState([]);
    const [editData, setEditData] = useState({});
    const [editList, setEditList] = useState([]);

    const [isAdvertiserModalOpen, setIsAdvertiserModalOpen] = useState(false);
    const [isEditAdvertiserModalOpen, setIsEditAdvertiserModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);


    const advertisersList = useSelector(state => state.org.advertisers);

    const handleClose = () => {
        if (isEditAdvertiserModalOpen) {
            setIsEditAdvertiserModalOpen(false);
            return;
        }

        setIsAdvertiserModalOpen(false);
    }

    const handleEdit = () => {
        setIsEdit(prev => !prev);
    }

    return (
        <div>
            <PageHeaderContainer>
                <h1> Advertisers </h1>
            </PageHeaderContainer>
            <SubHeader>
                <span className='gray'> Manage Advertisers </span>
            </SubHeader>

            <> {
                advertisersList?.length || newAdvertisersList.length ?
                <AdvertisersTable
                    isEdit={isEdit}
                    advertisersList={advertisersList}
                    newAdvertisersList={newAdvertisersList}
                    setNewAdvertisersList={setNewAdvertisersList}
                    setIsAdvertiserModalOpen={() => setIsAdvertiserModalOpen(true)}
                    setIsEditAdvertiserModalOpen={() => setIsEditAdvertiserModalOpen(true)}
                    setEditData={setEditData}
                    setIsArchiveModalOpen={() => setIsArchiveModalOpen(true)}
                /> :
                <NoAdvertisers setIsAdvertiserModalOpen={() => setIsAdvertiserModalOpen(true)} />
            }
            </>

            {
                (isAdvertiserModalOpen || isEditAdvertiserModalOpen) &&
                <AdvertiserModal
                    onClose={() => handleClose()}
                    isEditAdvertiserModalOpen={isEditAdvertiserModalOpen}
                    advertisersList={advertisersList}
                    newAdvertisersList={newAdvertisersList}
                    setNewAdvertisersList={setNewAdvertisersList}
                    editData={editData}
                    setEditData={setEditData}
                    setEditList={setEditList}
                    editList={editList}
                />
            }

            {
                isArchiveModalOpen &&
                <ArchiveModal
                    onClose={() => setIsArchiveModalOpen(false)}
                    editData={editData}
                />
            }
        </div>
    )
}

export default Advertisers;