import React, { useState, useEffect } from 'react';
import BackButton from '../../../ui/backBtn';
import AddNetworkModal from './modal/AddNetworkModal';

// Redux
import { getDuConfig } from '../../../actions/socialActions';
import { useSelector } from 'react-redux';

// Constants
import { LOB_NAV } from '../../../constants/lob';

// Styles
import { PageNavigationContainer, NavigationBtn, MultiBtnContainer, SubNavigationBtn, PlusNavigationBtnContainer, PlusNavigationBtn } from '../../../components/Sidebar/styles';
import { FaPlus } from 'react-icons/fa';

const Navigation = ({setIsEdit, currSelector, setCurrSelector, lob}) => {
    const [isAddNetworkModalOpen, setIsAddNetworkModalOpen] = useState(false);
    const [renderList, setRenderList] = useState([]);
    const dummyNetworks = useSelector(state => state.org.organization.dummyNetworks);

    const NETWORK_CONFIG_VAL = Object.values(LOB_NAV.NETWORK_CONFIG);

    useEffect(() => {
        const dummyFilter = lob?.networkConfigs?.filter(network => network.keyParam === 'duconfig');

        const promiseList = dummyFilter?.map(dummy => {
            return new Promise((resolve, reject) => {
                getDuConfig({
                    duConfigId: dummy.websafe,
                    callback: (res) => {
                        resolve(res);
                    }
                })
            })
        }) || [];

        const result = [];

        Promise.all(promiseList).then(res => {
            for (const dummy in res) {
                const curr = res[dummy];
                curr.logo = dummyNetworks.find(obj => obj.name === curr.network).logo;
                result.push(curr)
            }

            setRenderList(result)
        })
    }, [lob]);

    const updateRenderList = (dummyData) => {
        const addedNetworksList = dummyData.map(dummyNetwork => {
            dummyNetwork.logo = dummyNetworks.find(obj => obj.name === dummyNetwork.network).logo;
            return dummyNetwork;
        })

        setRenderList([...renderList, ...addedNetworksList]);
    }

    const dummyNetworkList = () => {
        return renderList.map(dummy => {
            return (
                <SubNavigationBtn onClick={() => setCurrSelector(dummy)} isSelected={currSelector.websafe === dummy.websafe} key={dummy.websafe}>
                    { dummy.network }
                </SubNavigationBtn>
            )
        })
    }

    return (
        <PageNavigationContainer>
            <BackButton handle={setIsEdit}/>
            <NavigationBtn onClick={() => setCurrSelector(LOB_NAV.DESCRIPTION)} isSelected={currSelector === LOB_NAV.DESCRIPTION} >
                LOB Description
            </NavigationBtn>
            <NavigationBtn onClick={() => setCurrSelector(LOB_NAV.DEMOGRAPHIC)} isSelected={currSelector === LOB_NAV.DEMOGRAPHIC} >
                Demographics
            </NavigationBtn>
            <NavigationBtn onClick={() => setCurrSelector(LOB_NAV.ACTIVATION)} isSelected={currSelector === LOB_NAV.ACTIVATION} >
                Activation
            </NavigationBtn>

            <MultiBtnContainer>
                <NavigationBtn isSelected={NETWORK_CONFIG_VAL.indexOf(currSelector) > -1} >
                    Network Config
                </NavigationBtn>
                <SubNavigationBtn onClick={() => setCurrSelector(LOB_NAV.NETWORK_CONFIG.FACEBOOK)} isSelected={currSelector === LOB_NAV.NETWORK_CONFIG.FACEBOOK}>
                    Facebook
                </SubNavigationBtn>
                <SubNavigationBtn onClick={() => setCurrSelector(LOB_NAV.NETWORK_CONFIG.GOOGLE)} isSelected={currSelector === LOB_NAV.NETWORK_CONFIG.GOOGLE}>
                    Google Search
                </SubNavigationBtn>
                { lob?.networkConfigs?.find(config => config.keyParam === 'liconfig') &&
                    <SubNavigationBtn onClick={() => setCurrSelector(LOB_NAV.NETWORK_CONFIG.LINKEDIN)} isSelected={currSelector === LOB_NAV.NETWORK_CONFIG.LINKEDIN}>
                        LinkedIn
                    </SubNavigationBtn>
                }
                {dummyNetworkList()}

            <PlusNavigationBtnContainer>
                <PlusNavigationBtn isSelected={currSelector === LOB_NAV.ADD_NETWORK} onClick={() => setIsAddNetworkModalOpen(true)}>
                    <FaPlus />
                </PlusNavigationBtn>
            </PlusNavigationBtnContainer>
            </MultiBtnContainer>
            {
                isAddNetworkModalOpen &&
                <AddNetworkModal
                    onClose={() => setIsAddNetworkModalOpen(false)}
                    dummyNetworks={dummyNetworks}
                    updateRenderList={updateRenderList}
                    renderList={renderList}
                />
            }
        </PageNavigationContainer>
    )
}

export default Navigation;