import styled from 'styled-components';

export const Text = styled.p`
    font-family: 'GothamBook';
    font-size: 14px;
    font-weight: 400;
`;

export const GrayText = styled(Text)`
    color: ${props => props.theme.card.gray};
`

export const TextLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`

export const GrayTextLink = styled(TextLink)`
    font-family: 'GothamMedium';
    font-weight: 500;
    color: ${props => props.theme.card.gray};
`;

export const ErrorText = styled.p`
    color: red;
    font-size: 0.8rem;
    text-align: center;
`

export const Asterisk = styled.span`
    color: ${props => props.theme.color.yellow};
`

export const LineHeader = styled.h1`
    width: 100%;
    text-align: center;
    border-bottom: 1px solid black;
    line-height: 0.1px;
    font-weight: 400;
    font-family: 'GothamBook';
    font-size: 14px;

    span {
        background: white;
        padding: 0 14px;
    }
`;