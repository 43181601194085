import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateAdAccount } from '../../../../actions/lobActions';
import { toast } from 'react-toastify';
import { validateCpaData } from '../../../../utils/validation';

import PercentInput from '../../../../ui/input/fields/PercentInput';
import BuildModal from '../../../../components/Modal/modals/BuildModal';
import gIcon from '../../../../assets/images/socialMediaIcons/GoogleIcon.png'
import { CpaAccountItem, TargetCpaContainer } from './styles';
import { ModalTextSecondary } from '../../../../components/Modal/styles';
import { BtnWithLoader, OutlineBtn } from '../../../../ui/button';
import CurrencyInput from '../../../../ui/input/fields/CurrencyInput';


const BiddingFocusModal = ({ selectedAccount, goConfigId, onClose, adAccounts, setAdAccounts }) => {
    const _D = useDispatch()
    const [loader, setLoader] = useState(false)
    const [targetCpaMicros, setTargetCpaMicros] = useState(0);
    const [targetRoas, setTargetRoas] = useState(0);
    const [cpcBidLimit, setCpcBidLimit] = useState(0);
    const [targetPercentImpressionShare, setTargetPercentImpressionShare] = useState(0);

    useEffect(() => {
        setTargetCpaMicros(selectedAccount?.targetCpaMicros)
        setTargetRoas(selectedAccount?.targetRoas)
        setCpcBidLimit(selectedAccount?.cpcBidLimit)
        setTargetPercentImpressionShare(selectedAccount?.targetPercentImpressionShare)
    }, [])

    const handleUpdateAdAccount = () => {
        const updatedAccount = {
            ...selectedAccount,
            targetRoas,
            targetPercentImpressionShare,
            cpcBidLimit,
            targetCpa: targetCpaMicros
        }
        if (validateCpaData(updatedAccount)) return

        const filteredAdAccounts = adAccounts.filter(acc => acc.accountId !== updatedAccount.accountId)
        setLoader(true)

        _D(updateAdAccount({
            socialLoginId: selectedAccount?.socialLoginId,
            socialConfig: `goconfig=${goConfigId}`,
            pages: [...filteredAdAccounts, updatedAccount],
            callback: () => {
                toast.success('Updated Ad Account');
                setAdAccounts([...filteredAdAccounts, updatedAccount])
                setLoader(false)
                onClose()
            }
        }))
    }

    const modalButtons = [
        <OutlineBtn onClick={() => onClose()}>Cancel</OutlineBtn>,
        <BtnWithLoader btnText='Update Ad Account' loader={loader} onClick={() => handleUpdateAdAccount()} type="blackBtn" />
    ]

    return (
        <BuildModal
            header='Edit Google Ad Accounts'
            onClose={onClose}
            buttons={modalButtons}
        >
            <TargetCpaContainer style={{ marginBottom: '20px' }}>
                <ModalTextSecondary style={{ marginTop: '20px', marginBottom: '20px' }}> These bidding focus specific values will be used as a reference when the relevant bidding focus is used on a search campaign. </ModalTextSecondary>
                <div className='ad-account-list-container'>
                    <CpaAccountItem key={selectedAccount.accountId}>
                        <div className='ad-account-item'>
                            <h3 style={{ marginBottom: '10px' }}>Ad Account: {selectedAccount.displayName}</h3>
                            <p style={{ marginBottom: '20px' }}>ID: {selectedAccount.accountId}</p>
                            <div className='field-container'>
                                <div style={{ marginTop: '0' }} className='field-item'>
                                    <div className='field-header'>
                                        <img src={gIcon} alt='google icon' />
                                        <p>Target CPA</p>
                                    </div>
                                    <div className='field-content'>
                                        <CurrencyInput
                                            onChange={(e) => setTargetCpaMicros(e.target.value)}
                                            value={targetCpaMicros}
                                            currency={selectedAccount?.currency}
                                        />
                                    </div>
                                </div>
                                <div className='field-item'>
                                    <div className='field-header'>
                                        <img src={gIcon} alt='google icon' />
                                        <p>Target ROAS</p>
                                    </div>
                                    <div className='field-content'>
                                        <PercentInput onChange={(e) => setTargetRoas(e.target.value)} min='0' max='100' value={targetRoas} />
                                    </div>
                                </div>
                                <div className='field-item'>
                                    <div className='field-header'>
                                        <img src={gIcon} alt='google icon' />
                                        <p>Maximum CPC Bid Limit</p>
                                    </div>
                                    <div className='field-content'>
                                        <CurrencyInput
                                            onChange={(e) => setCpcBidLimit(e.target.value)}
                                            value={cpcBidLimit}
                                            currency={selectedAccount?.currency}
                                        />
                                    </div>
                                </div>
                                <div className='field-item'>
                                    <div className='field-header'>
                                        <img src={gIcon} alt='google icon' />
                                        <p>Percent Impression Share To Target</p>
                                    </div>
                                    <div className='field-content'>
                                        <PercentInput onChange={(e) => setTargetPercentImpressionShare(e.target.value)} min='0' max='100' value={targetPercentImpressionShare} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CpaAccountItem>
                </div>
            </TargetCpaContainer>
        </ BuildModal>
    )
}

export default BiddingFocusModal