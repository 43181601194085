import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Select from 'react-select';

import { ModalTextSecondary, BtnContainer } from '../../../../components/Modal/styles';
import { BtnWithLoader, OutlineBtn } from '../../../../ui/button';
import { TableModalContainer } from '../../../../ui/table/styles';
import { Input } from '../../../../ui/input/input';
import { selectThemeModal } from '../../../../ui/themes';
import { useDispatch } from 'react-redux';
import { updateNetworkConfig } from '../../../../actions/lobActions';
import { toast } from 'react-toastify';

const PromoteAppModal = ({onClose, appsToPromote, fbConfigId}) => {
    const _D = useDispatch()
    const [appToPromoteList, setAppToPromoteList] = useState([]);
    const [loader, setLoader] = useState(false)
    const [appToPromote, setAppToPromote] = useState('');
    const [storeUrl, setStoreUrl] = useState('');

    useEffect(() => {
        window.eulerity.makeApiCall(`/api/facebook/appStores`, 'GET', null, (res) => {
            const list = res.map(app => {
                return ({
                    label: app.replaceAll('_', ' '),
                    value: app
                })
            })

            setAppToPromoteList(list);
        })
    }, []);

    const handleSave = () => {
        if (appsToPromote[appToPromote]) return toast.error('App to Promote already exists!')
        appsToPromote[appToPromote] = storeUrl;
        setLoader(true)
        _D(updateNetworkConfig({
            socialConfig: `fbconfig=${fbConfigId}`,
            obj: { appsToPromote },
            callback: () => {
                setLoader(false)
                onClose()
                toast.success('App to Promote and Store URL Added')
            },
        }))
    }

    return (
        <Modal
            header='Add App to Promote'
            onClose={onClose}
        >
            <TableModalContainer>
                <ModalTextSecondary> App to Promote </ModalTextSecondary>
                <Select
                    isClearable
                    options={appToPromoteList}
                    styles={selectThemeModal}
                    onChange={e => setAppToPromote(e.value)}
                />

                <ModalTextSecondary> Store Url </ModalTextSecondary>
                <Input type='text' onChange={e => setStoreUrl(e.target.value)} />

                <BtnContainer>
                    <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                    <BtnWithLoader btnText='Add App to Promote' loader={loader} onClick={handleSave} type="blackBtn" />
                </BtnContainer>
            </TableModalContainer>
        </Modal>
    )
}

export default PromoteAppModal;