import React, {useEffect} from 'react';
import { serveImage } from '../../pages/systemConfig/components/labelManagement/label.utils';
import { useSelector } from 'react-redux';
import ModalContent from './ModalContent';
import { ModalContainer, ModalOverlay, Header, CloseBtn, ModalHeaderContainer } from './styles';
import { CgClose } from 'react-icons/cg';
import gsap from 'gsap'
import { FacebookI, GoogleI } from '../../ui/icons';
import LinkedInIcon from '../../assets/images/socialMediaIcons/LinkedInIcon.png';

gsap.config({ nullTargetWarn: false });

const Modal = ({
    children,
    style,
    header,
    onClose,
    withIcon,
    network
}) => {
    const dummyNetworks = useSelector(state => state?.org?.organization?.dummyNetworks);

    useEffect(() => {
        gsap.timeline().to(`.modal-animation`, {scale: 1, ease: 'power4.out', duration: 0.2})
    },[])

    const icon = () => {
        if (!network) return;
        const isFacebook = network?.toLowerCase() === 'facebook';
        const isGoogle = network?.toLowerCase() === 'google';
        const isLinkedIn = network?.toLowerCase() === 'linkedin';

        if (isFacebook) return <FacebookI size="27px" />;
        if (isGoogle) return <GoogleI size = "27px"/>;
        if (isLinkedIn) return <img src={LinkedInIcon} alt='linkedin icon'/>
        if (!isFacebook && !isGoogle) {
            const currNetwork = dummyNetworks.find(dummy => dummy.name === network);
            return <img src={serveImage(currNetwork?.logo)} alt='' className='icon'/>
        }
    }

    return (
        <ModalOverlay>
            <ModalContainer className="modal-animation" onClick={e => e.stopPropagation()} style={style} id='modal-ref'>
                <ModalHeaderContainer>
                   <div>
                        { withIcon && icon() }
                        <Header withIcon> {header} </Header>
                   </div>
                   <CloseBtn> <CgClose onClick={onClose}/> </CloseBtn>
                </ModalHeaderContainer>
                {children && <ModalContent children={children} />}
            </ModalContainer>
        </ModalOverlay>
    )
}

export default Modal;