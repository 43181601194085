import React, {useState, useEffect} from 'react';
import AudienceTarget from '../../AudienceTarget';

import { audienceInterests } from '../../../../../actions/socialActions';

// Styles
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { BlackBtn } from '../../../../../ui/button';
import { RightCaption, AudienceBtnContainer } from '../styles';
import { AudienceTargetContainer } from '../../../styles';

const AddAudienceSegmentStep = ({
        description, adAccount, matchAudienceSegment, excludeAudienceSegment, isEditingAdGroup, includeAudiences, setIncludeAudiences, excludeAudiences, setExcludeAudiences
    }) => {
    const [selectedListItem, setSelectedListItem] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [userInterestsList, setUserInterestsList] = useState({});
    const [selectedAudiences, setSelectedAudiences] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(1);

    const { socialLoginId, accountId } = adAccount || {};

    useEffect(() => {
        // * Check if user is editing a ad group and pre-populate selected options
        if(isEditingAdGroup) {
            setIncludeAudiences(matchAudienceSegment || [])
            setExcludeAudiences(excludeAudienceSegment || [])
            setSelectedAudiences([...matchAudienceSegment.flat(), ...excludeAudienceSegment] || [])
            setCount(matchAudienceSegment?.length || 1)
        }
    }, [])

    useEffect(() => {
        if (!socialLoginId || !accountId ) return;

        audienceInterests({
            interestType: 'userInterests',
            socialLoginId,
            accountId,
            callback: (res) => {
                userInterestsList.userInterests = res;
                setUserInterestsList({...userInterestsList})
                setIsLoading(false);
            }
        })

        audienceInterests({
            interestType: 'lifeEvents',
            socialLoginId,
            accountId,
            callback: (res) => {
                userInterestsList.lifeEvents = res;
                setUserInterestsList({...userInterestsList})
                setIsLoading(false);
            }
        })

        audienceInterests({
            interestType: 'detailedDemographics',
            socialLoginId,
            accountId,
            callback: (res) => {
                userInterestsList.detailedDemographics = res;
                setUserInterestsList({...userInterestsList})
                setIsLoading(false);
            }
        })
    }, [socialLoginId, accountId])

    const renderNarrowAudience = () => {
        const result = [];

        for (let i = 1; i < count; i++) {
            result.push(
                <AudienceTarget
                    type='include'
                    text='Search for segment or add segment ID manually'
                    selectedAudiences={includeAudiences}
                    setAudience={setIncludeAudiences}
                    setSelectedListItem={setSelectedListItem}
                    selectedListItem={selectedListItem}
                    searchResults={searchResults}
                    userInterestsList={userInterestsList}
                    socialLoginId={adAccount.socialLoginId}
                    id={i}
                    key={i}
                    isGoogle={true}
                    isAnd={true}
                    preSelectedItems={selectedAudiences}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            )
        }

        return result;
    }

    return (
        <>
            <RightCaption>Audience Description: {description}</RightCaption>
                <ModalTextSecondary> Add Google Search Audience Segment(s) to Google Search Ad Group  </ModalTextSecondary>
                <AudienceTargetContainer>
                    {
                        <AudienceTarget
                            type='include'
                            text='Search for segment or add segment ID manually'
                            selectedAudiences={includeAudiences}
                            setAudience={setIncludeAudiences}
                            setSelectedListItem={setSelectedListItem}
                            selectedListItem={selectedListItem}
                            searchResults={searchResults}
                            userInterestsList={userInterestsList}
                            socialLoginId={adAccount?.socialLoginId}
                            isGoogle={true}
                            id={0}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    }
                    <AudienceTarget
                        type='exclude'
                        text='Search for segment or add segment ID manually'
                        isExclude={true}
                        selectedAudiences={excludeAudiences}
                        setAudience={setExcludeAudiences}
                        setSelectedListItem={setSelectedListItem}
                        selectedListItem={selectedListItem}
                        userInterestsList={userInterestsList}
                        socialLoginId={adAccount?.socialLoginId}
                        searchResults={searchResults}
                        isGoogle={true}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                </AudienceTargetContainer>
                {renderNarrowAudience()}
                {
                    includeAudiences.length > 0 &&
                    <AudienceBtnContainer>
                        <BlackBtn onClick={() => setCount(count + 1)}> Narrow your Segment (AND) </BlackBtn>
                    </AudienceBtnContainer>
                }
        </>
    )
}

export default AddAudienceSegmentStep;