// * Collection of all filter functions

import { CATEGORY_TYPES } from "../constants";

export const doesTemplateMatch = (template, filters) => {
    let foundMatch = true
    let matchedLabels = false

    const hasNoLabelsSelected = !filters[CATEGORY_TYPES.LABEL]?.length;

    // * Label Check
    if (hasNoLabelsSelected) {
        matchedLabels = true
    } else {
        matchedLabels = template?.labels?.some(label => filters[CATEGORY_TYPES.LABEL].includes(label))
    }

    foundMatch = foundMatch && matchedLabels;
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.OWNER]?.length || filters[CATEGORY_TYPES.OWNER].includes(template?.ownerName))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.GOAL]?.length || filters[CATEGORY_TYPES.GOAL].includes(template?.goal))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.PRIORITY]?.length || filters[CATEGORY_TYPES.PRIORITY].includes(template?.priority))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.NETWORK]?.length || filters[CATEGORY_TYPES.NETWORK].map(network => network.toLowerCase()).includes(template?.network?.toLowerCase()))

    return foundMatch
}

export const doesMediaPlanMatch = (mediaplan, filters) => {
    let foundMatch = true;

    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.OWNER]?.length || filters[CATEGORY_TYPES.OWNER].includes(mediaplan?.ownerName))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.LOB]?.length || filters[CATEGORY_TYPES.LOB].includes(mediaplan?.lob?.name))
    foundMatch = foundMatch && (!filters[CATEGORY_TYPES.STATUS]?.length || filters[CATEGORY_TYPES.STATUS].map(status => status.toLowerCase()).includes(mediaplan?.approval?.status?.toLowerCase()))

    return foundMatch
}