import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import PercentInput from '../../../../ui/input/fields/PercentInput'

const PercentImpression = ({ googleIcon, isEdit, percentImpression, setPercentImpression }) => {
    return (
        <FieldContainer>
            <h3> {googleIcon} Percent Impression Share to Target</h3>
            {
                isEdit ?
                    <PercentInput value={percentImpression} min='0' max='100' onChange={e => setPercentImpression(e.target.value)} />
                    : <p>{percentImpression} %</p>
            }
        </FieldContainer>
    )
}

export default PercentImpression