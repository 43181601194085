import { Input } from "../../../../ui/input/input";

export const CampaignTypeField = ({campaignType, isEdit, FieldContainer}) => {
    return (
        <FieldContainer>
            <h3> Campaign Type </h3>
            { isEdit ? <Input value='Search' disabled /> : <p> {campaignType} </p> }
        </FieldContainer>
    )
}

export default CampaignTypeField;