import React, { useMemo, useState, useLayoutEffect } from "react";
import gsap from 'gsap'
import { StyleHeaderContainer } from "../../../../ui/containers";
import { PageHeader } from "../../../../ui/headers";
import Selector from "./components/Selecor";
import { StyleSysAccounts, StyleSysAccountsWrapper, StyleSysAccountsSelectors, StyleSysAccountsDisplay } from "./styles";

import ConnectPage from "./components/ConnectPage";
import { FacebookI, GoogleI } from "../../../../ui/icons";
import { useSelector } from "react-redux";
import CurrentSystemAccount from "./components/CurrentSystemAccount";
import SystemAccountModal from "../modals/SystemAccountModal";
import { getSystemAccount, networkSocialLogins } from "../../utils";
gsap.config({ nullTargetWarn: false });

const SystemAccounts = () => {
  const selectedNetwork = useSelector(s => s.system.selectedNetwork);
  const socialLogins = useSelector(s => s.system.socialLogins);
  const orgData = useSelector(s => s.org.organization)

  let currentSysAccount =  getSystemAccount(orgData, selectedNetwork)
  const [openSysAccountModal, setOpenSysAccountModal] = useState(false);

  let networkSocialLogin = useMemo(() => {
    return networkSocialLogins(socialLogins, selectedNetwork)
  }, [selectedNetwork, socialLogins]);

  useLayoutEffect(() => {
    gsap.timeline().fromTo('.selector-animation', {opacity: 0, y: '5px'}, {opacity: 1, y: 0, duration: 0.4})
},[selectedNetwork])

  return (
    <>
      {openSysAccountModal && <SystemAccountModal currentSysAccount={currentSysAccount} networkSocialLogin={networkSocialLogin} selectedNetwork={selectedNetwork} onClose={setOpenSysAccountModal.bind(null, false)} />}
      <StyleHeaderContainer>
        <PageHeader>
          <p className="action-header">System Accounts</p>
        </PageHeader>
      </StyleHeaderContainer>
      <StyleSysAccounts>
        <StyleSysAccountsWrapper>
          <StyleSysAccountsSelectors>
            <Selector name="Facebook" selected={selectedNetwork} Icon={FacebookI} />
            <Selector name="Google" selected={selectedNetwork} Icon={GoogleI} />
            {/* More chanels latter */}
          </StyleSysAccountsSelectors>
          <StyleSysAccountsDisplay className="selector-animation">
            <p>{selectedNetwork + " System Accounts"}</p>

            {(!!networkSocialLogin?.length && currentSysAccount) && (
                 <>
                <ConnectPage network={selectedNetwork} networkSocialLogin={networkSocialLogin} setOpenSysAccountModal={setOpenSysAccountModal} />
                <CurrentSystemAccount currentSysAccount={currentSysAccount} />
               </>
            )}

             {
              !!networkSocialLogin?.length && !currentSysAccount && (
                <ConnectPage network={selectedNetwork} networkSocialLogin={networkSocialLogin} setOpenSysAccountModal={setOpenSysAccountModal} />
              )
             }

            {!networkSocialLogin?.length && (
              <>
                <ConnectPage network={selectedNetwork} networkSocialLogin={networkSocialLogin} setOpenSysAccountModal={setOpenSysAccountModal} />
                {currentSysAccount && <CurrentSystemAccount currentSysAccount={currentSysAccount} />}
              </>
            )}
          </StyleSysAccountsDisplay>
        </StyleSysAccountsWrapper>
      </StyleSysAccounts>
    </>
  );
};

export default SystemAccounts;
