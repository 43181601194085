import React from 'react';
import AsyncMultiSelect from '../../../../ui/AsyncMultiSelector';

// Styles
import { FieldContainer } from '../../../../ui/containers';

const LocationTargeting = ({isEdit, locationTargets, setLocationTargets, socialLoginId}) => {
    const handleChange = (e) => {
        const list = e?.map(location => location.value);
        setLocationTargets(list);
    }

    return (
        <FieldContainer>
            <h3> Location Targeting {<span className='asterisk'>*</span>} </h3>

            {isEdit ?
                <AsyncMultiSelect
                    query={`/api/facebook/queryLocations?socialLoginId=${socialLoginId}`}
                    localState={handleChange}
                    defaultOptions={locationTargets.map(location => {
                        return ({label: location?.name, value: location})
                    })}
                    isBatchCall={false}
                    label='name'
                    // isMultiQuery={true}
                />
            : <p> {locationTargets?.length ? locationTargets.map(location => location?.name).join(', ') : 'N/A'} </p>}
        </FieldContainer>
    )
}

export default LocationTargeting;
