import { SET_ORG, SET_ADVERTISERS, UPDATE_DUMMY_PROPERTY, CREATE_ADVERTISER, UPDATE_ADVERTISERS } from '../actions/types';

const initialState = {
    organization: null,
    advertisers: [],
}

const orgReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORG: return {...state, organization: action.payload}
        case SET_ADVERTISERS: return {...state, advertisers: action.payload}
        case CREATE_ADVERTISER: return {...state, advertisers: [...state.advertisers, action.payload]}
        case UPDATE_ADVERTISERS: {
            const updatedAdvertisers = state.advertisers.map(advertiser => {
                if (advertiser.websafe === action.payload.websafe) return action.payload;
                return advertiser;
            })

            return {...state, advertisers: updatedAdvertisers};
        }
        case UPDATE_DUMMY_PROPERTY: {
            const dummyNetworks = state.organization.dummyNetworks;
            const dummyNetwork = dummyNetworks.find(dummy => dummy.websafe === action.payload.websafe);
            dummyNetwork.properties = action.payload;

            return {...state};
        }

        default: return state;
    }
}
export default orgReducer;

