import React, { useContext } from 'react';
import CurrencyInput from '../../../../../ui/input/fields/CurrencyInput';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
// TODO has to be atleast 10
const DailyBudget = ({}) => {
    const { isEdit, campaignObj, handleUpdate, currency } = useContext(LinkedInCampaignContext);
    const { dailyBudget, budgetDuration } = campaignObj;

    if (budgetDuration === 'LIFETIME') return;

    return (
        <FieldContainer>
            <h3> Daily Budget <span className='asterisk'>*</span> </h3>
            { isEdit ?
                <CurrencyInput
                    onChange={(e) => handleUpdate({key: 'dailyBudget', value: e.target.value})}
                    value={dailyBudget}
                    currency={currency}
                /> :
                <p> {dailyBudget} {currency} </p>
            }
        </FieldContainer>
    )
}

export default DailyBudget;