import React, { useState } from 'react';
import BuildModal from '../../../../components/Modal/modals/BuildModal'

// Redux
import { createMediaPlan, getMediaPlanList, selectMediaPlan } from '../../../../actions/mediaActions';
import { useDispatch } from 'react-redux';

// Steps
import InfoStep from './InfoStep';
import SelectLobStep from './SelectLobStep';
import UploadFileStep from './UploadFileStep';

import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { BlackBtn, OutlineBtn } from '../../../../ui/button';

const InitialCreateMediaPlan = ({onClose, setIsEdit}) => {
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [selectedCard, setSelectedCard] = useState({});

    const { register, getValues, unregister } = useForm();
    const dispatch = useDispatch();

    const steps = () => {
        switch (step) {
            case 1:
                return <InfoStep register={register} getValues={getValues} name={name} setName={setName}/>
            case 2:
                return <SelectLobStep unregister={unregister} getValues={getValues} selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
            case 3:
                return <UploadFileStep register={register} unregister={unregister} getValues={getValues} />
            default:
              console.log(`No step selected`);
        }
    }

    const handleClose = () => {
        onClose();
    }

    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }

    const registerSelectedCard = () => {
        register('lob', { value: selectedCard})
        handleNext();
    }

    const onSubmit = (callback) => {
        const { name, lob, file } = getValues();
        const obj = {
            name,
            lob: lob.websafe,
            clientBriefs: file?.map(blob => blob.websafe),
        }

        // TODO - Refactor, nested dispatch - bad practice?
        dispatch(createMediaPlan({obj, callback: (res) => {
            dispatch(getMediaPlanList());
            toast.success(`Created Media Plan for ${name}`)
            dispatch(callback?.({websafe: res.websafe}))
        }}))
        handleClose();
    }

    const handleSaveAndContinue = () => {
        onSubmit(selectMediaPlan);
        handleBack()
        setIsEdit(true)
    }

    const handleSaveAndExit = () => {
        onSubmit()
        handleBack()
    }

    const decideButtons = () => {
        switch(step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BlackBtn onClick={handleNext} isDisabled={!name}> Next </BlackBtn>]
            case 2: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BlackBtn onClick={registerSelectedCard} isDisabled={Object.keys(selectedCard).length < 1}> Next </BlackBtn>]
            case 3: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <OutlineBtn onClick={handleSaveAndExit}> Save & Exit </OutlineBtn>, <BlackBtn onClick={handleSaveAndContinue}> Save & Continue </BlackBtn>]
            default: return []
        }
    }


    return (
        <BuildModal
            header='Create a New Media Plan'
            onClose={onClose}
            buttons={decideButtons()}
            height='650px'
            minWidth='650px'
            maxWidth='800px'
        >
            {steps()}
        </BuildModal>
    )
}

export default InitialCreateMediaPlan;