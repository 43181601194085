import React from "react";
import { selectTheme } from "../../themes";
import { FieldContainer } from "./style";
import Select from 'react-select';

import useInputColors from "../../../hooks/useInputColors";

const DropDownField = ({ icon, text, isEdit, options, localState, value, isDisabled }) => {
  if(value) isDisabled = false
  // if(!options?.length) isDisabled = true
  if(value === undefined) value = null
  const {alertStyles} = useInputColors(isDisabled, value)
  if(typeof value !== 'object') value = {label: value, value}
  return (
    <FieldContainer>
      <h3>
        {icon} {text.header} {text.RequiredStar && text.RequiredStar() }
      </h3>
      {isEdit ? (
        <Select
          isDisabled = {isDisabled}
          isClearable={false}
          options={options}
          placeholder={isDisabled ? "": options?.length ? "Select" : "No Options to Select"}
          value={value}
          styles={{...selectTheme,
            control: provided => ({
              ...provided,
              ...alertStyles
          })
          }}
          onChange={(e) => {
            localState({label: e.label, value: e.value})
          }}
        />
      ) : (
        <p> {value?.label ? value?.label : "N/A"} </p>
      )}
    </FieldContainer>
  );
};

export default DropDownField;
