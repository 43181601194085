import React, { useState, useEffect, useMemo } from 'react';
import TopOverView from '../../../components/Overview/TopOverview';
import CreateTemplateModal from './modal/CreateTemplateModal';
import Card from '../../../ui/card/Card';

// Utils
import useInitilizeOptions from '../../../features/AdvancedFilter/hooks/useInitializeOptions';
import { deleteTemplateText, templateModalController } from '../templates.util';
import { sortByRecentlyCreated } from '../../../utils/dates';

// Redux
import { useSelector } from 'react-redux';
import { deleteNetworkTemplate, selectTemplate } from '../../../actions/tempActions';

// Styles
import { OverviewContainer } from '../../../ui/containers';
import { CardGridContainer } from '../../../ui/card/styles';
import { CATEGORY_TAB_NAME, CATEGORY_TYPES } from '../../../features/AdvancedFilter/constants';
import { doesTemplateMatch } from '../../../features/AdvancedFilter/utils/filters.utils';


const Overview = ({register, setValue, handleSubmit, setIsEdit, getValues, setLoader}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNetworkText, setSelectedNetworkText] = useState({})
    const [network, setNetwork] = useState(null)
    const [goalOptions, setGoalOptions] = useState([]);
    const [priorityOptions, setPriorityOptions] = useState([]);

    // Selectors
    const tempList = useSelector(state => state.template.templateList); // * All templates returned from backend (global state)
    const dummyNetworks = useSelector(state => state.org?.organization?.dummyNetworks);
    const org = useSelector(state => state.org.organization);

    // * This will initilize what options appear within the filter
    const { initOwnerNameList, initGoalList, initPriorityList } = useInitilizeOptions();

    const handleSelectedTemplate = (networkName) => {
        setNetwork(networkName)
        setSelectedNetworkText(templateModalController(networkName))
        setIsModalOpen(true)
    }

    // * Generate network list
    const initNetworkList = () => {
        const networks = ['Facebook', 'Google', 'LinkedIn'];
        if(dummyNetworks?.length) {
            for (const network in dummyNetworks) {
                const networkName = dummyNetworks[network]?.name
                networks.push(networkName)
            }
        }
        return networks
    }

    // * Default Filters
    const DEFAULT_FILTERS = {
        [CATEGORY_TYPES.OWNER]: [],
        [CATEGORY_TYPES.NETWORK]: [],
        [CATEGORY_TYPES.GOAL]: [],
        [CATEGORY_TYPES.PRIORITY]: []
    }

    // * Filter Categories
    const categories = [
        {
            categoryName: CATEGORY_TAB_NAME.OWNER,
            options: initOwnerNameList(tempList)
        },
        {
            categoryName: CATEGORY_TAB_NAME.NETWORK,
            options: initNetworkList()
        },
        {
            categoryName: CATEGORY_TAB_NAME.GOAL,
            options: initGoalList(goalOptions)
        },
        {
            categoryName: CATEGORY_TAB_NAME.PRIORITY,
            options: initPriorityList(priorityOptions)
        }
    ]

    // * Active Filter State
    const [searchTerm, setSearchTerm] = useState('')
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

    // * Filter Config (obj b/c search component will crash if we try to destructure)
    const filterConfig = {
        selectedFilters: filters,
        setSelectedFilters: setFilters,
        categories
    }

    // * Template Options (will update as filters change)
    const filteredTemplateOptions = useMemo(() => {
        let doesMatch = true;
        return tempList.filter(template => {
            doesMatch = doesTemplateMatch(template, filters)
            return doesMatch
        })
    }, [filters, tempList])

    const handleSearchFilter = (filteredOptions) => {
        return filteredOptions.filter(template => {
            const name = template.name.toLowerCase();
            const hasLabel = template.labels.find(label => label.toLowerCase().includes(searchTerm));
            return name.includes(searchTerm) || hasLabel;
        })
    }

    const cloneTemplateObj = {
        // * Texts
        headerType: 'Template',
        secondaryText: 'Name of Network Template',
        cloneType: 'template',
        dataList: tempList,
    }

    const Cards = handleSearchFilter(sortByRecentlyCreated(filteredTemplateOptions))?.map(template =>
        <Card
            key={template.websafe}
            data={template}
            setIsEdit={setIsEdit}
            type='temp'
            deleteItem={deleteNetworkTemplate}
            selectItem={selectTemplate}
            deleteTextObj={deleteTemplateText}
            cloneTemplateObj={cloneTemplateObj}
            setOverviewLoader={res => setLoader(res)}
        />)

    // * Filter Data Obj and Config
    useEffect(() => {
        setGoalOptions(org?.goals?.map(goal => { return {label: goal, value: goal}}))
        setPriorityOptions(org?.priorities?.map(priority => { return {label: priority, value: priority}}))
    }, [org])

    return (
        <OverviewContainer>
        <TopOverView
            handleClick={handleSelectedTemplate}
            handleFilter={(e) => setSearchTerm(e.target.value)}
            searchText='Search for a network template'
            buttonText='Add New Network Template'
            withDropdown={true}
            dummyNetworks={dummyNetworks}
            filterConfig={filterConfig}
            useFilter
        />

        {!filteredTemplateOptions?.length && <p className='no-results'>No Templates are found based on the filters applied</p>}

        <CardGridContainer>
            {Cards}
        </CardGridContainer>

        {isModalOpen &&
            <CreateTemplateModal
                onClose={() => setIsModalOpen(false)}
                handleSubmit={handleSubmit}
                register={register}
                setValue={setValue}
                setIsEdit={setIsEdit}
                header={selectedNetworkText?.header}
                primaryText={selectedNetworkText?.primaryText}
                secondaryText='Name of Network Template'
                network={network}
                getValues={getValues}
            />
        }

        </OverviewContainer>
    )
}

export default Overview;