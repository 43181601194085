import React from 'react';

// Styles
import { HeaderInputContainer, ModalText, ModalTextSecondary } from '../../../../components/Modal/styles';
import { Input } from '../../../../ui/input/input';

const NameStep = ({register, setTempName}) => {
    return (
        <>
            <ModalText> Create a new Network Template by entering the name </ModalText>
            <HeaderInputContainer>
                <ModalTextSecondary> Name of Network Template <span className='asterisk'>*</span> </ModalTextSecondary>
                <Input type='text' {...register('name', { required: true })} onChange={(e) => setTempName(e)}/>
            </HeaderInputContainer>
        </>
    )
}

export default NameStep;