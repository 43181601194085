import React from 'react'
const HiddenInput = ({reference, onChange, accept='image/png,image/gif,image/jpeg', multi=false, type}) => {
    return (
        <input onChange={onChange}
                ref={reference}
                style={{visibility: 'hidden', position: 'absolute'}}
                accept = {accept}
                multiple = {multi}
                type={type ? type : 'file'}
          />
    )
}

export default HiddenInput