import React, { useContext } from 'react';
import Select from 'react-select';

// Styles
import { TableModalContainer } from '../../../../../ui/table/styles';
import { ModalTextSecondary } from '../../../../../components/Modal/styles';
import { selectThemeModal } from '../../../../../ui/themes';
import { ChannelSetUpContext } from './ChannelSetupModal';


const GoalPriority = () => {
    const { goal, priority, setGoal, setPriority, goalOptions, priorityOptions } = useContext(ChannelSetUpContext)

    return (
        <TableModalContainer>
            <ModalTextSecondary> What is the goal? <span className='asterisk'>*</span></ModalTextSecondary>
            <Select
                isClearable
                options={goalOptions}
                styles={selectThemeModal}
                onChange={e => setGoal(e.value)}
                placeholder={goal}
            />
            <ModalTextSecondary> What is the priority? <span className='asterisk'>*</span></ModalTextSecondary>
            <Select
                isClearable
                options={priorityOptions}
                styles={selectThemeModal}
                onChange={e => setPriority(e.value)}
                placeholder={priority}
            />
        </TableModalContainer>
    )
}
export default GoalPriority;