import React from 'react'
import CheckBox from '../checkbox/CheckBox'
import { CheckboxContainer } from '../input'
import { FieldContainer } from './style'



const CheckBoxField = ({icon, text, value, localState, isEdit}) => {

    return (
        <FieldContainer>
            <h3> {icon} {text?.header} </h3>
            <CheckboxContainer>
                <CheckBox
                type='checkbox'
                    checked={value}
                    disabled={!isEdit}
                    callback={() => localState(!value)}
                    isDisabled={!isEdit}
                    label={text?.body}
                />
            </CheckboxContainer>
    </FieldContainer>
    )

}


export default CheckBoxField