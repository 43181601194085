import React from 'react';
import Modal from '../../../../components/Modal/modals/Modal';
import { OutlineBtn, CenterButtonsContainer } from '../../../../ui/button';
import { StyledNamingConventionModal } from '../campaignSettings/styles';

const tableData = [
    {
        name: 'Client',
        shortcut: '%client%',
        description: `The %client% variable can be set per LOB on an LOB's  "LOB Description" tab.`,
    },
    {
        name: 'Product',
        shortcut: '%product%',
        description: `The %product% variable can be set per LOB on an LOB's "LOB Description" tab.`
    },
    {
        name: 'Initiative',
        shortcut: '%initiative%',
        description: `The %initiative% variable can be set per Media Plan on the “Media Plan Setup” tab.`
    },
    {
        name: 'Country',
        shortcut: '%country%',
        description: `The %country% variable can be set per Media Plan campaign and/or Media Plan ad set. (Note: A region abbreviation may be typed here instead.)`
    },
    {
        name: 'Language',
        shortcut: '%language%',
        description: `The %language% variable can be set per Media Plan campaign and/or Media Plan ad set.`
    },
    {
        name: 'Platform',
        shortcut: '%platform%',
        description: `The %platform% variable is derived from Network Template settings and the mapping can be managed from the System Config "List Management" tab.`
    },
    {
        name: 'Format',
        shortcut: '%format%',
        description: `The %format% variable is refers to ad format and is to be prefilled with "NA" when used.`
    },
    {
        name: 'Unique Identifier',
        shortcut: '%uniqueIdentifier%',
        description: `The %uniqueIdentifier% variable refers to a special id used by JellyFish to identify campaigns and/or ad sets. The platform will output "X" as a placeholder for JellyFish to manage outside of this platform.`
    },
    {
        name: 'Targeting Type',
        shortcut: '%targetingType%',
        description: `The %targetingType% variable is derived from an LOB's Ad Set/Ad Group setting and an LOB's Network Config Ad Set/Ad Group setting.`
    },
    {
        name: 'Audience Description',
        shortcut: '%audienceDescription%',
        description: `The %audienceDescription% variable is derived from an LOB's Demographics tab and an LOB's Network Config Ad Set/Ad Group setting.`
    },
    {
        name: 'Audience Target',
        shortcut: '%audienceTarget%',
        description: `The %audienceTarget% variable is derived from the name of the LOB's Network Config Ad Set/Ad Group.`
    },
    {
        name: 'Ad Format',
        shortcut: '%adFormat%',
        description: `The %adFormat% variable refers to ad format and is to be prefilled with "NA" when used.`
    },
    {
        name: 'Placement',
        shortcut: '%placement%',
        description: `The %placement% variable is derived from Network Template placement settings and the mapping can be managed from the System Config "List Management" tab.`
    },
    {
        name: 'Device',
        shortcut: '%device%',
        description: `The %device% variable refers to specific device placement used for a campaign. For now, the platform will hardcode this to "Mobile/Desktop"`
    },
]


const NamingConventionModal = ({ onClose }) => {
    return (
        <Modal header='Naming Variable Key' onClose={onClose}>
            <StyledNamingConventionModal>
                    <div className='table-wrapper'>
                        <table>
                            <thead>
                                <tr>
                                    {/* <th className='display-name-header'>VARIABLE DISPLAY NAME</th> */}
                                    <th className='shortcut-header'>VARIABLE SHORTCUT</th>
                                    <th className='description-header'>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData.map((data, index) => {
                                        return (
                                            <tr key={index} className='data-row'>
                                                {/* <td className='name-data'>{data.name}</td> */}
                                                <td className='shortcut-data'>{data.shortcut}</td>
                                                <td className='desc-data'>{data.description}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                <CenterButtonsContainer>
                    <OutlineBtn onClick={onClose}>Okay</OutlineBtn>
                </CenterButtonsContainer>
            </StyledNamingConventionModal>
        </Modal>
    )
}

export default NamingConventionModal