export const LOB_NAV = {
    DESCRIPTION: 'description',
    DEMOGRAPHIC: 'demographics',
    ACTIVATION: 'activation',
    NETWORK_CONFIG: {
        GOOGLE: 'google',
        FACEBOOK: 'facebook',
        LINKEDIN: 'linkedin',
        DUMMY: 'dummy'
    },
    ADD_NETWORK: 'addNetwork'
}

export const VIEWABILITY_MEASURES = ['All Impressions', '10%+', '20%+', '30%+', '40%+', '50%+', '60%+', '70%+', '80%+', '90%+'];

export const BRAND_SAFETY_MEASURES = ['Inclusion List', 'Exclusion List', 'Negative Keyword List'];

export const GENDER_OPTIONS = [
    {
        label: 'Male',
        value: 'm'
    },
    {
        label: 'Female',
        value: 'f'
    },
    {
        label: 'Undetermined',
        value: 'u'
    }
]
