import React, { useState } from 'react'
import BuildModal from '../../../../../components/Modal/modals/BuildModal';
import GoogleIcon from '../../../../../assets/images/socialMediaIcons/GoogleIcon.png';
import ConnectSocialModal from '../ConnectSocialModal';
import { BlackBtn, BlackBtnLg, BtnWithLoader, OutlineBtn } from '../../../../../ui/button';
import SelectNetworkAccountStep from './SelectNetworkAccountStep';
import AddSocialNetworkAccountStep from './AddSocialNetworkAccountStep';
import BiddingFocusStep from './BiddingFocusStep';
import { validateCpaData } from '../../../../../utils/validation';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { attachAdAccount } from '../../../../../actions/lobActions';


const GoogleAdAccountModal = ({ configId, onClose, data, setData }) => {
    const _D = useDispatch();
    const [step, setStep] = useState(1);
    const [btnLoader, setBtnLoader] = useState(false);
    const [isAddSocialLoginModalOpen, setIsAddSocialLoginModalOpen] = useState(false);

    // * Step 1 States
    const [selectedAccount, setSelectedAccount] = useState();
    // * Step 2 States
    const [selectedPages, setSelectedPages] = useState([]);

    const goNetworkObj = {
        name: 'Google',
        header: 'Google ad account(s)',
        icon: GoogleIcon,
    }

    const nextStep = () => setStep(step + 1)
    const onBack = () => setStep(step - 1)

    // * Submit Function
    const handleAttachAddAccount = (list) => {
        if (validateCpaData(list[0])) return
        setBtnLoader(true)
        setData([...data, list[0]])

        _D(attachAdAccount({
            socialLoginId: selectedAccount?.id,
            socialConfig: `goconfig=${configId}`,
            pages: list,
            callback: () => {
                toast.success('Adding Ad Account');
                setBtnLoader(false)
                onClose();
            }
        }))
    }

    // * Steps
    const steps = () => {
        switch (step) {
            case 1: return <SelectNetworkAccountStep
                isAddSocialLoginModalOpen={isAddSocialLoginModalOpen}
                handleAddSocialLogin={() => setIsAddSocialLoginModalOpen(true)}
                networkType={goNetworkObj}
                setSelectedAccount={setSelectedAccount}
                selectedAccount={selectedAccount}
            />
            case 2: return <AddSocialNetworkAccountStep
                socialLoginId={selectedAccount?.id}
                selectedPages={selectedPages}
                setSelectedPages={setSelectedPages}
                socialType='google'
                tableHeader='Google Ad Account(s)'
            />
            case 3: return <BiddingFocusStep
                selectedAccounts={selectedPages}
            />
            default:
                console.log(`No step selected`);
        }
    }

    // * Buttons
    const decideButtons = () => {
        switch (step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BlackBtn isDisabled={!selectedAccount} onClick={nextStep}> Next </BlackBtn>]
            case 2: return [<OutlineBtn onClick={onBack}> Back </OutlineBtn>, <BlackBtnLg isDisabled={!selectedPages.length} onClick={nextStep}> Add Bidding Focus Values </BlackBtnLg>]
            case 3: return [<OutlineBtn onClick={onBack}> Back </OutlineBtn>, <BtnWithLoader btnText="Add Ad Account(s)" type='blackBtn' color='white' onClick={() => handleAttachAddAccount(selectedPages)} loader={btnLoader} />]
            default: return []
        }
    }

    return (
        <>
            <BuildModal
                header="Attach Google Ad Account"
                onClose={onClose}
                buttons={decideButtons()}
                height={"750px"}
                minWidth={"700px"}
            >
                {steps()}
            </BuildModal>
            {
                isAddSocialLoginModalOpen &&
                <ConnectSocialModal
                    onClose={() => setIsAddSocialLoginModalOpen(false)}
                    network={goNetworkObj}
                />
            }
        </>

    )
}

export default GoogleAdAccountModal