import React, { useState, useEffect, useRef} from 'react';

import useCsv from '../../../../hooks/useCsv';
import { uploadBlob } from '../../../../utils';

// Styles
import { InitialCreateModalContainer } from '../../../../components/Modal/styles';
import { ModalText } from '../../../../components/Modal/styles';
import { CsvSelectContainer } from '../../../../ui/table/styles';
import { FaFileUpload } from 'react-icons/fa';
import { ListItem } from '../../../../components/Modal/styles';
import { TbCircleMinus } from 'react-icons/tb';
import { SubHeader } from '../../../../components/Modal/styles';

const UploadFileStep = ({register, unregister, getValues}) => {
    const [selectedFiles, setSelectedFiles] = useState();

    useEffect(() => {
        const { file } = getValues();
        if (file?.length < 1) return;
        setSelectedFiles(file);
    }, []);

    const CsvUploader = useCsv();
    const fileInputRef = useRef(null);

    const renderSelectedFiles = () => {
        if (selectedFiles?.length < 1) return;
        return selectedFiles?.map((file, idx) => <ListItem key={idx}> {file?.filename} <TbCircleMinus size={18}/> </ListItem>);
    }

    return (
        <InitialCreateModalContainer>
            <ModalText> Upload a Client Brief </ModalText>

            <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(fileInputRef)}>
                <FaFileUpload size={50}/>
                <br />
                <p> Select file(s) to upload </p>
            </CsvSelectContainer>
            <input
                type='file'
                hidden
                multiple={true}
                onChange={(e) => uploadBlob(
                    {
                        file: e.target.files[0],
                        callback: (file) => {
                            unregister('file');
                            register('file', { value: selectedFiles?.length ? [...selectedFiles, file] : [file] });
                            setSelectedFiles(selectedFiles?.length ? [...selectedFiles, file] : [file] )
                        }
                    }
                )}
                ref={fileInputRef}
            />

            <SubHeader> Selected Files </SubHeader>
            {renderSelectedFiles()}
        </InitialCreateModalContainer>
    )
}

export default UploadFileStep;