import React, { useState, useEffect } from 'react';
import DeleteModal from '../../../components/Modal/DeleteModal';
import useFadeAnimation from '../../../hooks/useFadeAnimation';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';

// Campaign Fields
import CampaignNameField from './googleCampaignFields/CampaignNameField';
import AudienceTypeField from './googleCampaignFields/AudienceTypeField';
import CampaignTypeField from './googleCampaignFields/CampaignTypeField';
import ObjectiveField from './googleCampaignFields/ObjectiveField';
import AdRotationField from './googleCampaignFields/AdRotationField';
import AudienceTargetingSettingsField from './googleCampaignFields/AudienceTargetingSettingsField';
import SearchNetworkField from './googleCampaignFields/SearchNetworkField';
import DisplayNetworkField from './googleCampaignFields/DisplayNetworkField';
import SearchPartnersField from './googleCampaignFields/SearchPartnersField';
import DesktopBidding from './googleCampaignFields/DesktopBidding';
import MobileBidding from './googleCampaignFields/MobileBidding';
import TabletBidding from './googleCampaignFields/TabletBidding';
import BiddingType from './googleCampaignFields/BiddingType';
import MaxCpcBid from './googleCampaignFields/MaxCpcBid';
import AdLocation from './googleCampaignFields/AdLocation';
import EnhancedCpc from './googleCampaignFields/EnhancedCpc';

// Utils
import { shouldUpdateString } from '../../../utils/payload';
import { getBiddingStrategies, getImpressionShareLocation, googleObjectives } from '../../../actions/tempActions';


// Redux
import { useSelector } from 'react-redux';

// Styles
import { FieldContainer, OneThirdGridColContainer, StyledFieldsWrapper } from '../../../ui/containers';
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn, HeaderIconBtnContainer, DeleteTextBtn } from '../../../ui/button';
import { validateBidAdjustment } from '../../../utils/validation';


const GoogleCampaignDetails = ({handleUpdate, handleDeleteCampaign }) => {
    const campaign = useSelector(state => state.template.selectedCampaign)

    const [isEdit, setIsEdit] = useState(false);
    const [name, setName] = useState('');
    const [objective, setObjective] = useState('');
    const [objectiveOptions, setObjectiveOptions] = useState('');
    const [audienceType, setAudienceType] = useState('');
    const [loading, setLoading] = useState(false);
    const [campaignType, setCampaignType] = useState('Search');
    const [desktopBid, setDesktopBid] = useState(0);
    const [mobileBid, setMobileBid] = useState(0);
    const [tabletBid, setTabletBid] = useState(0);
    const [focusType, setFocusType] = useState('');
    const [focusTypeOptions, setFocusTypeOptions] = useState([]);
    const [maxBidLimit, setMaxBidLimit] = useState(0);
    const [adLocation, setAdLocation] = useState('');
    const [adLocationOptions, setAdLocationOptions] = useState([]);
    const [useEnhancedCpc, setUseEnhancedCpc] = useState(false);
    const [useAdRotation, setUseAdRotation] = useState(false);
    const [searchNetwork, setSearchNetwork] = useState(false);
    const [displayNetwork, setDisplayNetwork] = useState(false);
    const [searchPartners, setSearchPartners] = useState(false);
    const [isAudienceTargeting, setIsAudienceTargeting] = useState(false);

    const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);
    const theme = useTheme()
    const RequiredStar = () => <span style={{color: theme.color.yellow}}>*</span>

    const ref = useFadeAnimation([isEdit, campaign])

    // Selectors
    const audienceTypes = useSelector(state => state.org.organization.audienceTypes);
    const audienceTypeOptions = audienceTypes?.map(type => ({ label: type, value: type }));


    useEffect(() => {
        googleObjectives(objectives => {
            const result = objectives.map(objective => {
                return (
                    {
                        label: objective,
                        value: objective
                    }
                )
            })
            setObjectiveOptions(result)
        })
        getBiddingStrategies(biddingObjectives => {
            const result = biddingObjectives.map(objective => {
                return (
                    {
                        label: objective,
                        value: objective
                    }
                )
            })
            setFocusTypeOptions(result)
        })
        getImpressionShareLocation(impressions => {
            const result = impressions.map(impression => {
                return (
                    {
                        label: impression,
                        value: impression
                    }
                )
            })
            setAdLocationOptions(result)
        })
    }, [campaign]);

    useEffect(() => {
        clearCampaignFields();
        setName(campaign?.name)
        setObjective(campaign?.objective);
        setCampaignType(campaign?.campaignType);
        setAudienceType(campaign?.audienceType);
        setUseAdRotation(campaign?.useAdRotation);
        setIsAudienceTargeting(campaign?.isAudienceTargeting);
        setSearchNetwork(campaign?.targetSearchNetwork);
        setDisplayNetwork(campaign?.targetDisplayNetwork);
        setSearchPartners(campaign?.targetSearchPartners);
        setDesktopBid(campaign?.desktopBidModifier);
        setMobileBid(campaign?.mobileBidModifier);
        setTabletBid(campaign?.tabletBidModifier);
        setFocusType(campaign?.bidStrategy)
        setUseEnhancedCpc(campaign?.enhancedCpc)
        setMaxBidLimit(campaign?.cpcBidLimit)
        setAdLocation(campaign?.impressionShareLocation)
    }, [campaign]);

    const handleEdit = () => {
        if (isEdit) {
                let shouldUpdate = false;

                if (shouldUpdateString(name, campaign.name)) {
                    campaign.name = name;
                    shouldUpdate = true;
                }

                if (shouldUpdateString(campaignType, campaign.campaignType)) {
                    campaign.campaignType = campaignType;
                    shouldUpdate = true;
                }

                if (shouldUpdateString(audienceType, campaign.audienceType)) {
                    campaign.audienceType = audienceType;
                    shouldUpdate = true;
                }
                if (shouldUpdateString(objective, campaign.objective )) {
                    campaign.objective = objective;
                    shouldUpdate = true;
                }
                if (campaign.useAdRotation !== useAdRotation) {
                    campaign.useAdRotation = useAdRotation;
                    shouldUpdate = true;
                }
                if (campaign.targetSearchNetwork !== searchNetwork) {
                    campaign.targetSearchNetwork = searchNetwork;
                    shouldUpdate = true;
                }
                if (campaign.targetDisplayNetwork !== displayNetwork) {
                    campaign.targetDisplayNetwork = displayNetwork;
                    shouldUpdate = true;
                }
                if (campaign.targetSearchPartners !== searchPartners) {
                    campaign.targetSearchPartners = searchPartners;
                    shouldUpdate = true;
                }
                if (campaign.isAudienceTargeting !== isAudienceTargeting) {
                    campaign.isAudienceTargeting = isAudienceTargeting;
                    shouldUpdate = true;
                }
                if (campaign.desktopBidModifier !== desktopBid) {
                    campaign.desktopBidModifier = desktopBid;
                    shouldUpdate = true;
                }
                if (campaign.mobileBidModifier !== mobileBid) {
                    campaign.mobileBidModifier = mobileBid;
                    shouldUpdate = true;
                }
                if (campaign.tabletBidModifier !== tabletBid) {
                    campaign.tabletBidModifier = tabletBid;
                    shouldUpdate = true;
                }
                if (campaign.bidStrategy !== focusType) {
                    campaign.bidStrategy = focusType;
                    shouldUpdate = true;
                }
                if (campaign.enhancedCpc !== useEnhancedCpc) {
                    campaign.enhancedCpc = useEnhancedCpc;
                    shouldUpdate = true;
                }
                if (campaign.cpcBidLimit !== maxBidLimit) {
                    campaign.cpcBidLimit = maxBidLimit;
                    shouldUpdate = true;
                }
                if (campaign.impressionShareLocation !== adLocation) {
                    campaign.impressionShareLocation = adLocation;
                    shouldUpdate = true;
                }
                if(!campaign.objective) return toast.error("Please Select an Objective")
                if(validateBidAdjustment(campaign?.desktopBidModifier) || validateBidAdjustment(campaign?.mobileBidModifier) || validateBidAdjustment(campaign?.tabletBidModifier)) return
                if (shouldUpdate) {
                    handleUpdate(campaign);
                }
        }
        setIsEdit(prev => !prev);
    }

    const handleModalDeleteCampaign = () => {
        setLoading(true);
        handleDeleteCampaign(campaign, () => {
            setIsDeleteCampaignModalOpen(false);
            clearCampaignFields();
            setLoading(false);
        });
    }

    const clearCampaignFields = () => {
        setObjective('');
        setObjectiveOptions('');
        setAudienceType('');
        setUseAdRotation(false);
        setIsAudienceTargeting(false);
        setDisplayNetwork(false);
        setSearchNetwork(false);
        setSearchPartners(false);
        setDesktopBid(0);
        setMobileBid(0);
        setTabletBid(0);
        setFocusType('')
        setUseEnhancedCpc(false)
        setMaxBidLimit(0)
        setAdLocation('')
    }

    const resetBiddingData = (biddingFocus) => {
        switch (biddingFocus) {
            case 'Manual CPC':
                setMaxBidLimit(0);
                setAdLocation('');
                return;
            case 'Clicks':
                setUseEnhancedCpc(false)
                setAdLocation('')
                return
            case 'Impression Share':
                setUseEnhancedCpc(false)
                return;
            case 'Conversions':
            case 'Conversion Value':
            default:
                setUseEnhancedCpc(false)
                setMaxBidLimit(0)
                setAdLocation('')
                return
        }
    }

    return (
        <StyledFieldsWrapper ref={ref}>
            <SubHeader>
                Campaign Details for {campaign?.name}
                <HeaderIconBtnContainer>
                    <DeleteTextBtn onClick={() => setIsDeleteCampaignModalOpen(true)} btnText={'Delete Campaign'} />
                    {EditSaveBtn(isEdit, handleEdit)}
                </HeaderIconBtnContainer>
            </SubHeader>

            <OneThirdGridColContainer>
                <CampaignNameField
                    FieldContainer={FieldContainer}
                    name={name} setName={setName}
                    isEdit={isEdit}
                />

                <AudienceTypeField
                    FieldContainer={FieldContainer}
                    audienceType={audienceType} setAudienceType={setAudienceType} defaultValue={campaign?.audienceType}
                    audienceTypeOptions={audienceTypeOptions} isEdit={isEdit}
                />

                <CampaignTypeField
                    FieldContainer={FieldContainer}
                    campaignType={campaignType} isEdit={isEdit}
                />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <ObjectiveField
                    FieldContainer={FieldContainer}
                    objective={objective} setObjective={setObjective} defaultValue={campaign?.objective}
                    objectiveOptions={objectiveOptions} isEdit={isEdit} RequiredStar={RequiredStar}
                />
                {/* New Field */}
                <SearchNetworkField
                    FieldContainer={FieldContainer}
                    searchNetwork={searchNetwork} setSearchNetwork={setSearchNetwork} isEdit={isEdit}
                />
                {/* New Field */}
                <DisplayNetworkField
                    FieldContainer={FieldContainer}
                    displayNetwork={displayNetwork} setDisplayNetwork={setDisplayNetwork} isEdit={isEdit}
                />

            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                {/* New Field */}
                <SearchPartnersField
                    FieldContainer={FieldContainer}
                    searchPartners={searchPartners} setSearchPartners={setSearchPartners} isEdit={isEdit}
                />

                <AdRotationField
                    FieldContainer={FieldContainer}
                    useAdRotation={useAdRotation} setUseAdRotation={setUseAdRotation} isEdit={isEdit}
                />

                <AudienceTargetingSettingsField
                    FieldContainer={FieldContainer}
                    isAudienceTargeting={isAudienceTargeting} setIsAudienceTargeting={setIsAudienceTargeting} isEdit={isEdit}
                />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <DesktopBidding
                    FieldContainer={FieldContainer}  isEdit={isEdit}
                    desktopBid={desktopBid} setDesktopBid={setDesktopBid}
                />
                <MobileBidding
                    FieldContainer={FieldContainer} isEdit={isEdit}
                    mobileBid={mobileBid} setMobileBid={setMobileBid}
                />
                <TabletBidding
                    FieldContainer={FieldContainer} isEdit={isEdit}
                    tabletBid={tabletBid} setTabletBid={setTabletBid}
                />
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <BiddingType
                    FieldContainer={FieldContainer}
                    isEdit={isEdit} focusTypeOptions={focusTypeOptions}
                    focusType={focusType} setFocusType={setFocusType} defaultValue={campaign?.bidStrategy}
                    resetBiddingData={resetBiddingData}
                />
                {
                    (focusType === 'Clicks' || focusType === 'Impression Share') &&
                    <MaxCpcBid
                        FieldContainer={FieldContainer}
                        isEdit={isEdit} maxBidLimit={maxBidLimit} setMaxBidLimit={setMaxBidLimit}
                    />
                }
                {
                    (focusType === 'Manual CPC' || focusType === 'Enchanced CPC') &&
                    <EnhancedCpc
                        FieldContainer={FieldContainer}
                        isEdit={isEdit} useEnhancedCpc={useEnhancedCpc} setUseEnhancedCpc={setUseEnhancedCpc}
                    />
                }
                {
                    focusType === 'Impression Share' &&
                    <AdLocation
                        FieldContainer={FieldContainer}
                         isEdit={isEdit} setAdLocation={setAdLocation} adLocation={adLocation}
                        adLocationOptions={adLocationOptions} defaultValue={campaign?.impressionShareLocation}
                    />
                }
            </OneThirdGridColContainer>

            {
                isDeleteCampaignModalOpen &&
                <DeleteModal
                    header='Delete Campaign'
                    modalText={`Deleting ${campaign?.name} campaign`}
                    deleteBtnText='Delete Campaign'
                    setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
                    handleDelete={handleModalDeleteCampaign}
                    loading={loading}
                />
            }
        </StyledFieldsWrapper>
    )
}

export default GoogleCampaignDetails;