import React, { useState, useRef } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import Loader from '../../../../../ui/Loader';

// Utils
import useCsv from '../../../../../hooks/useCsv';
import { uploadBlob } from '../../../../../utils';
import { serveImage } from '../../labelManagement/label.utils';

// Redux
import { useDispatch } from 'react-redux';
import { createDummyNetwork } from '../../../../../actions/orgAction';

// Styles
import { ModalTextSecondary, ModalFieldContainer, ListBarItem } from '../../../../../components/Modal/styles';
import { Input } from '../../../../../ui/input/input';
import { CsvSelectContainer } from '../../../../../ui/table/styles';
import { OutlineBtn, CenterButtons, BtnWithLoader } from '../../../../../ui/button';
import { BsUpload } from 'react-icons/bs';
import { TbCircleMinus } from 'react-icons/tb';
import { toast } from 'react-toastify';

const AddNetworkModal = ({onClose, setSelected}) => {
    const [logoFile, setLogoFile] = useState([]);
    const [csvFile, setCsvFile] = useState([]);
    const [selectedLogo, setSelectedLogo] = useState(logoFile);
    const [selectedCSV, setSelectedCSV] = useState(csvFile);
    const [name, setName] = useState('');
    const [abbreviation, setAbbreviation] = useState('');

    const [loader, setLoader] = useState(false);
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isCsvLoading, setIsCsvLoading] = useState(false);

    const CsvUploader = useCsv();
    const imgInputRef = useRef(null);
    const csvInputRef = useRef(null);
    const urlRef = useRef('');

    const dispatch = useDispatch();

    const renderSelectedFiles = (selectedFiles, setSelectedFiles) => {
        if (!selectedFiles?.length) return;
        return selectedFiles?.map((file, idx) => {
            return (
                <ListBarItem key={idx}> {file.filename}
                    <TbCircleMinus size={18} onClick={(e) => handleRemoveFile(e, setSelectedFiles)}/>
                </ListBarItem>
            )
        })
    }

    const handleRemoveFile = (e, setSelectedFiles) => {
        setSelectedFiles([]);
        e.stopPropagation();
    }

    const handleAddNetwork = () => {
        setLoader(true);

        const data = {
            name,
            abbreviation,
            logo: selectedLogo[0]?.blobkey,
            bulkUploadUrl: urlRef.current.value,
            bulkUploadSheet: selectedCSV[0]?.websafe
        }

        dispatch(createDummyNetwork({
            obj: data,
            callback: (res) => {
                setLoader(false);
                toast.success(`Successfully created ${name} network`);
                setSelected(data?.name);
                onClose();
            }
        }));
    }

    return (
        <Modal
            onClose={onClose}
            header='Add Network'
            style={{width: '600px'}}
        >
            <div style={{marginTop: '10px'}} />

            <ModalFieldContainer>
                <ModalTextSecondary> Network Name <span className='asterisk'>*</span> </ModalTextSecondary>
                <Input onChange={(e) => setName(e.target.value)} placeholder='Please enter network name' />
            </ModalFieldContainer>

            <ModalFieldContainer>
            <ModalTextSecondary> Network Logo <span className='asterisk'>*</span> </ModalTextSecondary>

            <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(imgInputRef)}>
                {isLogoLoading ? <Loader /> :
                selectedLogo?.length ? <img src={serveImage(selectedLogo[0].blobkey)} alt='logo' /> : <BsUpload size={50}/>}
                <br />
                {renderSelectedFiles(selectedLogo, setSelectedLogo)}
                </CsvSelectContainer>
                <input
                    type='file'
                    hidden
                    accept='image/png, image/jpeg, image/svg'
                    onChange={(e) => {
                        setIsLogoLoading(true);
                        uploadBlob(
                        {
                            file: e.target.files[0],
                            callback: (file) => {
                                setSelectedLogo([file]);
                                setIsLogoLoading(false);
                            }
                        }
                    )}}
                    ref={imgInputRef}
                />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> Abbreviation <span className='asterisk'>*</span> </ModalTextSecondary>
                <Input onChange={(e) => setAbbreviation(e.target.value)} placeholder='Please enter abbreviation' />
            </ModalFieldContainer>

            <ModalFieldContainer>
            <ModalTextSecondary> Sample Bulk Upload Sheet </ModalTextSecondary>

            <CsvSelectContainer onClick={() => CsvUploader.selectCsvFile(csvInputRef)}>
                {isCsvLoading ? <Loader /> : <BsUpload size={50}/>}
                <br />
                {renderSelectedFiles(selectedCSV, setSelectedCSV)}
            </CsvSelectContainer>
                <input
                    type='file'
                    hidden
                    accept='.csv'
                    onChange={(e) => {
                        setIsCsvLoading(true);
                        uploadBlob(
                            {
                                file: e.target.files[0],
                                callback: (file) => {
                                    setIsCsvLoading(false);
                                    setSelectedCSV([file]);
                                }
                            }
                        )
                    }}
                    ref={csvInputRef}
                />
            </ModalFieldContainer>

            <ModalFieldContainer>
                <ModalTextSecondary> Enter Google Sheets URL </ModalTextSecondary>
                <Input ref={urlRef} placeholder='Please enter Google Sheets URL' />
            </ModalFieldContainer>

            {
                CenterButtons(
                    <>
                        <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                        <BtnWithLoader
                            btnText='Add Network'
                            loader={loader}
                            onClick={handleAddNetwork}
                            type='blackBtn'
                            color='white'
                            isDisabled={!name || !abbreviation || !selectedLogo[0]?.blobkey}
                        />
                    </>
                )
            }
        </Modal>
    )
}

export default AddNetworkModal;