import React, { useState, useEffect } from 'react';
import FacebookAdSetSetup from './FacebookAdSetSetup';
import SubNavigation from '../../../components/SubNavigation/SubNavigation';
import FacebookCampaignSetup from './FacebookCampaignSetup'
// Helpers
import { shouldUpdateString } from '../../../utils/payload';
import { generateDropdownOptions, isObjArraysEqual } from '../../../utils/arrays';


// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getLob } from '../../../actions/lobActions';
import { getNetworkTemplateList, getTemplate } from '../../../actions/tempActions';
import { deleteCampaign, updateCampaign } from '../../../actions/mediaActions';
import { getAdvertiser } from '../../../actions/systemConfigActions';

// Styles
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn, HeaderIconBtnContainer, RedOutlineIconBtn, StyleCenterElement } from '../../../ui/button';
import { TemplateCard } from '../styles';
import { Label } from '../../../ui/card/styles';
import { Text } from '../../../ui/text';

import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/Modal/DeleteModal';
import { getCampaign } from '../../../actions/mediaActions';
import { WarningIcon } from '../../../ui/icons';
import { capitalize } from '../../../utils/strings';
import { dateCheck, multiValueCheck, stringToDateconverter } from './utils';
import Loader from '../../../ui/Loader';

const FacebookCampaignDetails = ({websafe, mediaPlan, resetPage, isEdit, setIsEdit}) => {
    // Dropdown values
    const [countryOptions, setCountryOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);

    // Flight Details
    const [name, setName] = useState('');
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [budget, setBudget] = useState();
    const [adAccount, setAdAccount] = useState();

    // Campaign Name Details
    const [campaignCountries, setCampaignCountries] = useState([]);
    const [campaignLanguages, setCampaignLanguages] = useState([]);

    // Originally Derived from Template
    const [objective, setObjective] = useState('');

    const [useAdRotation, setUseAdRotation] = useState(false);
    const [audienceTargeting, setAudienceTargeting] = useState(false);
    // Facebook


    // Originally Derived from LOB
    const [minAge, setMinAge] = useState();
    const [maxAge, setMaxAge] = useState();
    const [genderTargets, setGenderTargets] = useState([]);
    const [maxIncomePercentage, setMaxIncomePercentage] = useState();
    const [minIncomePercentage, setMinIncomePercentage] = useState();
    const [locationTargets, setLocationTargets] = useState([]);
    const [languageTargets, setLanguageTargets] = useState([]);
    const [trackingCodes, setTrackingCodes] = useState();
    const [searchSite, setSearchSite] = useState('');
    const [specialAdCategory, setSpecialAdCategory] = useState([])
    const [ budgetDuration, setBudgetDuration] = useState(null)
    const [lob, setLob] = useState();
    const [template, setTemplate] = useState();
    const [currNav, setCurrNav] = useState('Campaign Setup');
    const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);
    const [selectedAdvantageCampaignBudget, setSelectedAdvantageCampaignBudget] = useState(null)
    const [spendLimit, setSpendLimit] = useState(null)
    const [campaignSpendingLimit, setCampaignSpendingLimit] = useState(null);
    const [loader, setLoader] = useState(true)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [adGroupLoader, setAdGroupLoader] = useState(true)
    const dispatch = useDispatch();

    const networkList = useSelector(state => state.template.templateList);
    const campaign = useSelector(state => state.media.currentCampaign);

    // Advertiser
    const [advertiser, setAdvertiser] = useState({});

    const isEditable = mediaPlan?.lob?.name;

  const getCampaignFun = (websafe) => {
    if(currNav !== "Ad Set Setup")  setLoader(true)
    dispatch(getCampaign({
        websafe,
        callback: (res) => {
            dispatch(getNetworkTemplateList(() => {
                // setCampaign(res)
                setLoader(false)
            }))
        },
        errorCallback: (err) => {
            console.error(err);
            toast.error('Something went wrong');
        }
    }))
  }
    useEffect(() => {
        getCampaignFun(websafe)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [websafe]);

    useEffect(() => {
        if (!campaign?.websafe) return;
        const network = networkList.find(net => net.websafe === campaign?.networkTemplateCampaign?.networkTemplate?.websafe);
        if (!campaign?.websafe || !network?.websafe) return;

        getTemplate(network?.websafe).then(res => {
            setTemplate(res)

            if (res?.advertiser?.websafe) {
                getAdvertiser({
                    websafe: res.advertiser.websafe,
                    callback: advertiserData => setAdvertiser(advertiserData)
                })
            }
        }).catch(err => console.error(err))
        if (!campaign?.adGroups?.length) setCurrNav('Campaign Setup');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    useEffect(() => {
        if (!mediaPlan?.websafe || !mediaPlan.lob) return;
        getLob({
            lobId: mediaPlan?.lob?.websafe,
            callback: (res) => setLob(res)
        })
    }, [mediaPlan])

    // * Get Countrty and Language Options
    useEffect(() => {
        const codeKey = 'code';

        window.eulerity.makeApiCall("/api/org/getDataFile?filename=countries", 'GET', null, response => {
            let countryOptions = generateDropdownOptions(response, codeKey)
            setCountryOptions(countryOptions)
        })

        window.eulerity.makeApiCall("/api/org/getDataFile?filename=languages", 'GET', null, response => {
            let languageOptions = generateDropdownOptions(response, codeKey);
            setLanguageOptions(languageOptions)
        })
    }, [])

    useEffect(() => {
        clearCampaignFields();
        if (campaign?.name) setName(campaign?.name);
        if (campaign?.start) setStart(campaign?.start);
        if (campaign?.end) setEnd(campaign?.end || 0);
        if (campaign?.budget) setBudget(campaign?.budget);
        if (campaign?.budgetDuration) setBudgetDuration([{label: capitalize(campaign?.budgetDuration), value: campaign?.budgetDuration }])
        if (campaign?.advantageCampaignBudget) setSelectedAdvantageCampaignBudget(campaign?.advantageCampaignBudget)
        if (campaign?.audienceTargeting) setAudienceTargeting(campaign?.audienceTargeting);
        if (campaign?.specialAdCategories) setSpecialAdCategory(campaign?.specialAdCategories?.map(it => ({label: capitalize(it), value: it})))
        if(campaign?.spendLimit) setSpendLimit(campaign.spendLimit)
        if(campaign?.campaignSpendingLimit !== null) setCampaignSpendingLimit(campaign?.campaignSpendingLimit)
        if(campaign?.countryCodes) setCampaignCountries(campaign?.countryCodes);
        if(campaign?.languageCodes) setCampaignLanguages(campaign?.languageCodes);
        campaign?.minAge ? setMinAge(campaign?.minAge) : setMinAge(lob?.minAge);
        campaign?.maxAge ? setMaxAge(campaign?.maxAge) : setMaxAge(lob?.maxAge);
        campaign?.genderTargets ? setGenderTargets(campaign?.genderTargets) : setGenderTargets(lob?.genderTargets);
        campaign?.minIncomePercentage ? setMinIncomePercentage(campaign?.minIncomePercentage) : setMinIncomePercentage(lob?.minIncomePercentage);
        campaign?.maxIncomePercentage ? setMaxIncomePercentage(campaign?.maxIncomePercentage) : setMaxIncomePercentage(lob?.maxIncomePercentage);
        campaign?.maxAge ? setMaxAge(campaign?.maxAge) : setMaxAge(lob?.maxAge);
        campaign?.languageTargets ? setLanguageTargets(campaign?.languageTargets) : setLanguageTargets(lob?.languageTargets);
        campaign?.locationTargets ? setLocationTargets(campaign?.locationTargets) : setLocationTargets(lob?.locationTargets);

        if (campaign?.searchSite) setSearchSite(campaign.searchSite);

    }, [template, campaign, lob])

    let isCampaignRunnable = () => true //TODO write condition to check if campaign is runnable (ask nirma)

    const audienceType = () => {
        const networkTemplateCampaignId = campaign?.networkTemplateCampaign?.id;
        return template?.campaigns?.find(camp => camp.id === networkTemplateCampaignId)?.audienceType;
    }

    const networkCampaignName = () => {
        const networkTemplateCampaignId = campaign?.networkTemplateCampaign?.id;
        return template?.campaigns?.find(camp => camp.id === networkTemplateCampaignId)?.name;
    }

    const handleEdit = () => {
        if (isEdit) {
            let updateObj = {};
            const errorMessages = [];
            if (shouldUpdateString(budgetDuration.label, campaign?.budgetDuration)) updateObj.budgetDuration = budgetDuration.value
            if (shouldUpdateString(name, campaign?.name)) updateObj.name = name;

            const {startDate, endDate} = stringToDateconverter(start, end)

            if(!start)  errorMessages.push({message: "Please select Start Date"})
            if(!end)    errorMessages.push({message: "Please select End Date"})

            if(selectedAdvantageCampaignBudget) {
                if(!budget) errorMessages.push({message: "Enter Budget to Launch Campaign"})
                if(budget < 0) errorMessages.push({message: 'Budget should be greater than zero'})
            }
            if(!dateCheck(startDate, endDate))  errorMessages.push({message: 'End Date should be greater than Start Date'})
            const {startDate: campStartDate, endDate: campEndDate} = stringToDateconverter(campaign?.start, campaign?.end)

            if (startDate && startDate !== campStartDate) updateObj.start = startDate;
            if (endDate && endDate !== campEndDate) updateObj.end = endDate;

            if(parseInt(budget || 0) !== campaign?.budget) updateObj.budget = budget; //todo add only number input
            if (shouldUpdateString(spendLimit, campaign?.spendLimit)) updateObj.spendLimit = spendLimit

            if(campaignSpendingLimit !== null && campaignSpendingLimit !== campaign?.campaignSpendingLimit)  updateObj.campaignSpendingLimit = campaignSpendingLimit;
            if(!campaignSpendingLimit) delete updateObj.spendLimit;

            if (campaignCountries && !isObjArraysEqual(campaignCountries, campaign?.countryCodes)) updateObj.countryCodes = campaignCountries;
            if (campaignLanguages && !isObjArraysEqual(campaignLanguages, campaign?.languageCodes)) updateObj.languageCodes = campaignLanguages;
            if((selectedAdvantageCampaignBudget !== null) && selectedAdvantageCampaignBudget !== campaign?.advantageCampaignBudget) {
                updateObj.advantageCampaignBudget = selectedAdvantageCampaignBudget
                if(!selectedAdvantageCampaignBudget) {
                    delete updateObj.budget;
                    delete updateObj.budgetDuration;
                }
            }

            // Temporary until we are setting up buying type
            if (!campaign?.buyingType) updateObj.buyingType = 'AUCTION';

           const response = multiValueCheck(specialAdCategory, campaign?.specialAdCategories);
           if(response) updateObj.specialAdCategories = response

            if (errorMessages.length > 0) {
                toast.error(errorMessages[0].message)
                return;
            }

            if (Object.keys(updateObj).length) {
                if(currNav !== "Ad Set Setup")  setLoader(true)
                dispatch(updateCampaign({
                    mediaPlanCampaign: websafe,
                    obj: updateObj,
                    callback: (_) => {
                        setLoader(false)
                        getCampaignFun(websafe)
                        toast.success(`Updated campaign`);
                    }
                }))
            }
        }
        setIsEdit(!isEdit);
    }

    const handleModalDeleteCampaign = () => {
        setDeleteLoader(true)
        dispatch(deleteCampaign({
            mediaPlanCampaign: websafe,
            callback: () => {
                setIsDeleteCampaignModalOpen(false);
                toast.success(`Deleted campaign`);
                resetPage('campaign');
                setDeleteLoader(false)
            }
        }))
    }


    const clearCampaignFields = () => {
        setName();
        setObjective();
        setUseAdRotation(false);
        setAudienceTargeting(false);
        setStart();
        setEnd();
        setBudget();
        setMinAge();
        setMaxAge();
        setGenderTargets();
        setMinIncomePercentage();
        setMaxIncomePercentage();
        setLanguageTargets([]);
        setLocationTargets([]);
        setSearchSite();
        setSpendLimit(null)
        setCampaignSpendingLimit(null)
        setCampaignCountries([]);
        setCampaignLanguages([]);
    }

    const navigationObj = [
        {
            name: 'Campaign Setup',
            component: <FacebookCampaignSetup
                isEdit={isEdit} handleEdit={handleEdit}
                lobName={lob?.name} templateName={template?.name}
                campaign={campaign}
                name={name} setName={setName}
                start={start} setStart={setStart}
                end={end} setEnd={setEnd}
                budget={budget} setBudget={setBudget}
                specialAdCategory={specialAdCategory}setSpecialAdCategory={setSpecialAdCategory}
                budgetDuration={budgetDuration} setBudgetDuration={setBudgetDuration}
                adAccount={adAccount} setAdAccount={setAdAccount}
                objective={objective} setObjective={setObjective}
                locationTargets={locationTargets} setLocationTargets={setLocationTargets}
                languageTargets={languageTargets} setLanguageTargets={setLanguageTargets}
                trackingCodes={trackingCodes} setTrackingCodes={setTrackingCodes}
                selectedAdvantageCampaignBudget={selectedAdvantageCampaignBudget} setSelectedAdvantageCampaignBudget={setSelectedAdvantageCampaignBudget}
                spendLimit={spendLimit} setSpendLimit={setSpendLimit}
                campaignSpendingLimit ={campaignSpendingLimit} setCampaignSpendingLimit={setCampaignSpendingLimit}
                setCountries={setCampaignCountries} setLanguages={setCampaignLanguages}
                countryOptions={countryOptions} languageOptions={languageOptions}
            />
        }
    ]

    if (campaign?.adGroups?.length) navigationObj.push(
        {
            name: 'Ad Set Setup',
            component: <FacebookAdSetSetup
                campaign={campaign}
                mediaPlan={mediaPlan}
                lobName={lob?.name}
                template={template}
                resetPage={resetPage}
                adGroupLoader={adGroupLoader}
                setAdGroupLoader={setAdGroupLoader}
                countryOptions={countryOptions}
                languageOptions={languageOptions}
                selectedAdvantageCampaignBudget={selectedAdvantageCampaignBudget}
            />
        }
    )
    if(loader) return <StyleCenterElement><Loader size={50} /></StyleCenterElement>
    return (
        <>
            <SubHeader>
                <HeaderIconBtnContainer>
                    Facebook Campaign
                    {!isCampaignRunnable() && <RedOutlineIconBtn style={{pointerEvents: 'none'}}><WarningIcon /> Unable to run Campaign </RedOutlineIconBtn>}</HeaderIconBtnContainer>
                {isEditable && <HeaderIconBtnContainer>
                    <RedOutlineIconBtn onClick={() => setIsDeleteCampaignModalOpen(true)}> <BiTrash /> Delete Campaign </RedOutlineIconBtn>
                    {currNav === 'Campaign Setup' && EditSaveBtn(isEdit, handleEdit)}
                </HeaderIconBtnContainer>}
            </SubHeader>

            {name && <Text> CAMPAIGN NAME: {name} </ Text>}

            <TemplateCard>
                <Text> <span>NETWORK TEMPLATE</span>: {template?.name} </Text>
                { networkCampaignName() && <Text> <span>NETWORK CAMPAIGN NAME</span>: {networkCampaignName()} </Text> }
                <Text> <span>NETWORK TEMPLATE TYPE</span>: Advertiser - {advertiser?.name || 'All Advertisers'} </Text>

                <div>
                    <Label> Goal : {template?.goal} </Label>
                    <Label> Priority : {template?.priority} </Label>
                </div>
                {audienceType() && <div>
                    <Label> Targeting Type: {audienceType()} </Label>
                </div>}
                {template?.description && <Text> <span>DESCRIPTION</span>: {template?.description} </Text>}
            </TemplateCard>

            <SubNavigation navigationObj={navigationObj} currNav={currNav} setCurrNav={setCurrNav}/>

            {
                isDeleteCampaignModalOpen &&
                <DeleteModal
                    header='Delete Campaign'
                    modalText={`Deleting ${campaign?.name} campaign`}
                    deleteBtnText='Delete Campaign'
                    setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
                    handleDelete={handleModalDeleteCampaign}
                    loading={deleteLoader}
                />
            }
        </>
    )
}

export default FacebookCampaignDetails;