import React, { useContext } from 'react';
import Select from 'react-select';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const Optimization = ({}) => {
    const optimization = useSelector(state => state.media.currentCampaign?.networkTemplateCampaign?.optimization);

    return (
        <FieldContainer>
            <h3> Optimization </h3>
            <Select
                value={{label: optimization, value: optimization}}
                styles={selectTheme}
                isDisabled={true}
            />
        </FieldContainer>
    )
}

export default Optimization;