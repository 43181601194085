import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';

// Styles
import { InformationContainer } from './styles';

const Information = ({text, place}) => {
    return (
        <InformationContainer>
            <IoIosInformationCircleOutline
                data-tooltip-id='my-tooltip'
                data-tooltip-content={text}
            />
            <Tooltip id='my-tooltip' className='tooltip' classNameArrow='arrow' border={true} effect='solid' place={place} />
        </InformationContainer>
    )
}

export default Information;