import React, { useContext } from 'react';
import CurrencyInput from '../../../../../ui/input/fields/CurrencyInput';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { FieldContainer } from '../../../../../ui/containers';

const CpcBidLimit = ({}) => {
    const { isEdit, campaignObj, handleUpdate, currency } = useContext(LinkedInCampaignContext);
    const { cpcBidLimit } = campaignObj;

    const bidStrategy = useSelector(state => state.media.currentCampaign?.networkTemplateCampaign?.bidStrategy);

    if (bidStrategy !== 'Cost Cap') return;

    return (
        <FieldContainer>
            <h3> CPC Bid Limit </h3>
            { isEdit ?
                <CurrencyInput
                    onChange={(e) => handleUpdate({key: 'cpcBidLimit', value: e.target.value})}
                    value={cpcBidLimit}
                    currency={currency}
                /> :
                <p> {cpcBidLimit ? cpcBidLimit : 'N/A'} {currency} </p>
            }
        </FieldContainer>
    )
}

export default CpcBidLimit;