import React, { useContext } from 'react';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { Input } from '../../../../../ui/input/input';
import { FieldContainer } from '../../../../../ui/containers';


const Page = ({}) => {
    const { isEdit } = useContext(LinkedInCampaignContext);
    const page = useSelector(state => state.media.currentCampaign?.adAccount?.liPage?.localizedName);

    return (
        <FieldContainer>
            <h3> Page </h3>
            { isEdit ? <Input value={page || 'N/A'} disabled={true} /> : <p> {page || 'N/A'} </p> }
        </FieldContainer>
    )
}

export default Page;