import React from 'react'
import CheckBox from '../../../../ui/input/checkbox/CheckBox'

const EnhancedCpc = ({ FieldContainer, isEdit, useEnhancedCpc, setUseEnhancedCpc }) => {
    return (
        <FieldContainer>
            <h3> Enchanced CPC</h3>
            <CheckBox
                isDisabled={!isEdit}
                checked={useEnhancedCpc}
                callback={() => setUseEnhancedCpc(!useEnhancedCpc)}
                label={'Use Enchanced CPC'}
            />
        </FieldContainer>
    )
}

export default EnhancedCpc