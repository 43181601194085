import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import PercentInput from '../../../../ui/input/fields/PercentInput'

const TargetRoas = ({googleIcon, isEdit, targetRoas, setTargetRoas}) => {
  return (
    <FieldContainer>
        <h3>{googleIcon} Target ROAS</h3>
        {
            isEdit ?
            <PercentInput
                onChange={(e) => setTargetRoas(e.target.value)}
                value={targetRoas}
                min="0"
                max="100"
            />
            : <p>{targetRoas}</p>
        }
    </FieldContainer>
  )
}

export default TargetRoas