import styled from "styled-components";

export const StyleSelectorWrapper = styled.div`
  & > :first-child {
    margin-bottom: 0.5rem;
  }
`;

export const StyleHeader = styled.div`
  display: flex;
  align-items: center;
  & > :first-child {
    display: flex;
    gap: 5px;
    margin-right: 7px;
  }
  svg {
    cursor: pointer;
  }
`;
