import React from 'react'
import { StyleAdvancedTable, StyleAdvancedTableHeader, StyleAdvancedTableBody } from './style'
import { GrAddCircle } from "react-icons/gr";
import { BiPencil } from 'react-icons/bi';

const AdvancedTable = ({ headerText, handler, children, isEdit, onEdit, usePlus, useEdit }) => {
    return (
        <StyleAdvancedTable>
            <StyleAdvancedTableHeader>
                <p>{headerText}</p>
                {
                    isEdit && (
                        <div className='btn-container'>
                            {useEdit &&
                                <div className='table-header-btn'>
                                    <BiPencil size='19' onClick={onEdit} />
                                </div>
                            }
                            {
                                usePlus &&
                                <div className='table-header-btn' onClick={handler}>
                                    <GrAddCircle size='20' />
                                </div>

                            }
                        </div>
                    )
                }
            </StyleAdvancedTableHeader>
            <StyleAdvancedTableBody>
                {children}
            </StyleAdvancedTableBody>
        </StyleAdvancedTable>
    )
}


export default AdvancedTable