import React from 'react'
import { BiSearchAlt2 } from 'react-icons/bi'
import { StyledInputWrapper, StyledSearchInput } from './styles'

const SearchInput = ({handleOnChange, textValue, placeholder = 'Search...', inputWidth = '500px'}) => {
  return (
    <StyledInputWrapper inputWidth={inputWidth}>
        <BiSearchAlt2 size={24}/>
        <StyledSearchInput
            value={textValue}
            placeholder={placeholder}
            onChange={handleOnChange}
        />
    </StyledInputWrapper>
  )
}

export default SearchInput