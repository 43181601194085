import React, {useContext} from 'react';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../../ui/input/fields/CustomDatePicker';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { DatePickerWrapper } from '../../../../../ui/input/input';

const StartDate = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { start } = campaignObj;

    const handleChange = (date) => {
        handleUpdate({key: 'start', value: date})
    }

    return (
        <FieldContainer>
            <h3> Start Date <span className='asterisk'>*</span> </h3>
            {
                isEdit ?
                <DatePickerWrapper>
                    <DatePicker
                        customInput={<CustomDatePicker />}
                        minDate={new Date()}
                        wrapperClassName='datePicker'
                        onChange={(date) => handleChange(date.getTime())}
                        selected={start && new Date(start)}
                        readOnly={!isEdit}
                    />
                </DatePickerWrapper> :
                <p> {start ? new Date(start).toDateString() : "N/A"} </p>
            }
        </FieldContainer>
    )
}

export default StartDate;