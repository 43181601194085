import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../../../../../components/Modal/Modal'
import { BtnContainer } from '../../../../../../components/Modal/styles'
import { BlackBtnLg, OutlineBtn } from '../../../../../../ui/button'
import { Input } from '../../../../../../ui/input/input'
import { objectToObjArr } from '../../label.utils'
import { PlacementContainer, PlacementField, PlacementFieldWrapper } from '../../styles'

const PlacementModal = ({ onClose, placements, setObjectData, updatePlacement }) => {

  const [currValues, setCurrValues] = useState({});

  useEffect(() => {
    setCurrValues(placements)
  }, [])

  const updateLocalValues = (field, value) => {
    setCurrValues((prev) => (
      {
        ...prev,
        [field]: value
      }
    ))
  }

  const validateData = () => {
    const collectedValues = Object.values(currValues)

    if (collectedValues.some(val => val.trim() === '')) return toast.error("Please don't leave any fields blank!")

    updatePlacement(currValues, 'placementMap')
    onClose()
  }

  return (
    <Modal onClose={onClose} header='Edit Labels for Placements'>
      <PlacementContainer>
        <PlacementFieldWrapper>
          {
            objectToObjArr(currValues).map(placement => {
              return (
                <PlacementField key={placement.key}>
                  <div className='placement-headers'>
                    <p className='placement-title'>Placement Name</p>
                    <p className='placement-value'>{placement.key}</p>
                  </div>
                  <div className='placement-inputs'>
                    <p className='placement-input-title'>Abbreviation {<span className='asterisk'>*</span>}</p>
                    <Input
                      placeholder='Enter Abbreviation'
                      value={placement.value}
                      onChange={(e) => updateLocalValues(placement.key, e.target.value)}
                    />
                  </div>
                </PlacementField>
              )
            })
          }
        </PlacementFieldWrapper>
        <BtnContainer>
          <OutlineBtn onClick={onClose}>Cancel</OutlineBtn>
          <BlackBtnLg onClick={validateData}>Add Placement Labels</BlackBtnLg>
        </BtnContainer>
      </PlacementContainer>
    </Modal>
  )
}

export default PlacementModal