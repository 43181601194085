import React from "react";
import { StyleUserRole, StyleUserRoleDropdown } from "./styles";
import { GoChevronDown } from "react-icons/go";
import useDropDownAnimation from "../../../../hooks/useDropdownAnimation";
import { createNanoId } from "../../../../utils/helpers";
import { capitalize } from "../../../../utils/strings";
import { toast } from "react-toastify";
import { updateUserRole } from "../../utils";
import Loader from "../../../../ui/Loader";

const UserRole = ({ role, applyClass, isToggleOn, setRole, setToggle, userId, loader, setLoader, isEdit }) => {
  let child = createNanoId();
  let main = createNanoId();
  const { handleDropDownAnimation, reverseAnimation, status } = useDropDownAnimation(main, child);

  const dropDownController = () => {
    if (!isEdit || loader) return;
    handleDropDownAnimation();
  };

  const handleOnOptionClick = async (role) => {
    reverseAnimation();
    setLoader(true);
    try {
      await updateUserRole({ userId, role });
      toast.success("User role has been updated!");
      if (!isToggleOn && role !== "None") {
        setToggle(true);
      } else if (isToggleOn && role === "None") {
        setToggle(false);
      }
      setRole(role);
    } catch (_) {
      toast.error("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  return (
    <StyleUserRole className="mag-item" isEdit={isEdit} loader={loader} applyClass={applyClass} selected={status} onMouseLeave={(e) => status && e.id !== "drop-container" && reverseAnimation()}>
      <div className="user-role_wrapper" onClick={dropDownController}>
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="user-role">{capitalize(role)}</div>
            <div className="user-role_icon">{isEdit && <GoChevronDown />}</div>
          </>
        )}
      </div>

      <StyleUserRoleDropdown id="drop-container" className={main}  >
        <div className={`${child} role-options`}>
          <div onClick={handleOnOptionClick.bind(null, "None")}>
            <p>None</p>
          </div>
          <div onClick={handleOnOptionClick.bind(null, "Read")}>
            <p>Read</p>
          </div>
          <div onClick={handleOnOptionClick.bind(null, "Write")}>
            <p>Write</p>
          </div>
          <div onClick={handleOnOptionClick.bind(null, "Admin")}>
            <p>Admin</p>
          </div>
        </div>
      </StyleUserRoleDropdown>
    </StyleUserRole>
  );
};

export default UserRole;
