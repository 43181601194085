import React, { useEffect, useState } from 'react'
import Modal from '../../../../../../components/Modal/Modal'
import { BtnContainer } from '../../../../../../components/Modal/styles';
import { BlackBtnLg, OutlineBtn } from '../../../../../../ui/button';
import { NameAbbreviationContainer } from '../../styles';
import { Input } from '../../../../../../ui/input/input';
import { toast } from 'react-toastify';

const PlatformModal = ({ onClose, platform, platforms, updatePlatform }) => {
    const [platformAbbreviation, setPlatformAbbreviation] = useState('');

    useEffect(() => {
        if(platform) {
            setPlatformAbbreviation(platform?.value)
        }
    }, [])

    const handleUpdatePlatform = () => {
        if(!platformAbbreviation.length) return toast.error('Please enter an abbreviation')

        const updatedObj = {
            ...platforms,
            [platform?.key]: platformAbbreviation
        }

        updatePlatform(updatedObj, 'platformMap');
        onClose();
    }


    return (
        <Modal header='API Networks' onClose={onClose}>
            <NameAbbreviationContainer>
                <div className='input__container'>
                    <p className='input__header__text'>Platform<span className='asterisk'> *</span></p>
                    <Input
                        readOnly
                        disabled
                        value={platform?.key}
                    />
                </div>
                <div className='input__container'>
                    <p className='input__header__text'>Abbreviation<span className='asterisk'> *</span></p>

                    <Input
                        value={platformAbbreviation}
                        placeholder='Enter Abbreviation'
                        onChange={(e) => setPlatformAbbreviation(e.target.value)}
                    />
                </div>
            </NameAbbreviationContainer>
            <BtnContainer>
                <OutlineBtn onClick={onClose}>Cancel</OutlineBtn>
                <BlackBtnLg onClick={handleUpdatePlatform}>Add API Network</BlackBtnLg>
            </BtnContainer>
        </Modal>
    )
}

export default PlatformModal