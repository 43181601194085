/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from 'react';

function useDragDrop(onDrop, acceptedFiles) {
  const [onEnter, setOnEnter] = useState(false)
  const ref = useRef(null);

  useEffect(() => {
    const handleDragEnter = (e) => {
      e.preventDefault();
    //   setOnEnter(true)
      ref.current.classList.add('dragging');
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      setOnEnter(false)
      ref.current.classList.remove('dragging');
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      setOnEnter(true)
    };

    const handleDrop = (e) => {
      e.preventDefault();
      ref.current.classList.remove('dragging');
      const file = e.dataTransfer.files[0];
      let error = {message: ""}
      if(!acceptedFiles.includes(file.type)) {
        error.message = "This file type is not supported!"
        onDrop(file, error);
        return
      }
      onDrop(file);
    };
    if(!ref.current) return
    ref.current.addEventListener('dragenter', handleDragEnter);
    ref.current.addEventListener('dragleave', handleDragLeave);
    ref.current.addEventListener('dragover', handleDragOver);
    ref.current.addEventListener('drop', handleDrop);

    return () => {
     if(!ref.current) return
      ref.current.removeEventListener('dragenter', handleDragEnter);
      ref.current.removeEventListener('dragleave', handleDragLeave);
      ref.current.removeEventListener('dragover', handleDragOver);
      ref.current.removeEventListener('drop', handleDrop);
    };
  }, [onDrop]);

  return [ref, onEnter];
}

export default useDragDrop;
