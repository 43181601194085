import React from 'react';

// Styles
import { LobModalContainer } from '../../styles';
import { ModalText, ModalTextSecondary } from '../../../../components/Modal/styles';
import { Input } from '../../../../ui/input/input';

const NameStep = ({register}) => {
    return (
        <LobModalContainer>
            <ModalText> Create a new Line of business by entering the name. </ModalText>
            <ModalTextSecondary> Name of Line of Business<span className='asterisk'>*</span> </ModalTextSecondary>

            <Input type='text' {...register('name', { required: true })} />
        </LobModalContainer>
    )
}

export default NameStep;