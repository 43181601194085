import styled, {css} from 'styled-components';

export const PageContainer = styled.div`
    background-color: ${props => props.theme.background.white};
    padding: 40px 27px;
    height: 100%;
    box-shadow: 0px 2px 8px ${props => props.theme.background.boxShadow};
    min-height: 500px !important;
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'GothamMedium';
    text-align: left;
    color: ${props => props.theme.modal.gray};
    width: 100%;
    gap: 10px;
    margin: 20px 0;
    transition: all 0.2s;
    display: ${props => props.hidden ? "none" : "flex"};
    width: 305px;

    .error {
       color: ${props => props.theme.color.red};
    }

    .datePicker {
        all: unset;
        border: 1px solid ${props => props.theme.input.gray};
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        align-items: center;
        display: flex;

        svg {
            font-size: 20px;
            margin-right: 5px;
        }

        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    p {
        font-family: 'GothamBook';
        font-size: 14px;
        font-weight: 400;
        word-break: break-all;
    }

    span > .asterisk {
        vertical-align: sub;
    }

    .asterisk {
        color: ${props => props.theme.color.yellow};
    }

    .edit_text_label {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            white-space: nowrap;
            margin-right: 5px;
        }
    }

    .tooltip {
        font-family: 'GothamBook';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        border: 1px solid ${props => props.theme.color.gray} !important;
        background-color: white;
        max-width: 300px;
        color: black;
        padding: 15px;
    }

    .arrow {
        border-bottom: 1px solid ${props => props.theme.color.gray} !important;
        border-right: 1px solid ${props => props.theme.color.gray} !important;
    }
`;

export const FieldColContainer = styled.div`
    gap: 30px;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1075px)  {
        flex-direction: row;
    }
`;

export const OneThirdGridColContainer = styled(FieldColContainer)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;


    ${({forFb}) => {
       return forFb && css`
            align-items: center;
            row-gap: 0;
            margin: 1rem 0 2rem 0;
        `
    }}

    @media only screen and (max-width: 1075px)  {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
`;

export const OneThirdFieldColContainer = styled(FieldColContainer)`
    display: grid;
    grid-template-columns: 1fr 2fr;

    @media only screen and (max-width: 1075px)  {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
`;

// Used in every page's overview
export const OverviewContainer = styled.div`
    padding: 40px;

    @media only screen and (min-width: 768px) {
        padding: 40px 70px;
    }

    @media only screen and (min-width: 992px)  {
        padding: 50px 80px;
    }

    .no-results {
        margin-top: 28px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }
`;

export const StyleHeaderContainer = styled.div`
    font-family: 'GothamBook';
  .action-name {
    font-size: 20px;
    color: ${(props) => props.theme.card.gray};
  }
  .action-header {
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
  }

  .action-header-span {
    font-family: 'GothamBook';
    font-weight: 100;
    color: gray;
  }

  .learn-more-text {
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: underline;
    text-decoration-thickness: 0.8px;
    line-height: 0.8;
    color: #717171;
    cursor: pointer;
  }
`

export const StyledFieldsWrapper = styled.div`
    min-height: 850px;
`

export const ScrollableContainer = styled.div`
    overflow: scroll;
    height: ${props => props.height}px;
`;

export const InputFieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    gap: 5px;
`;