import React, { useEffect, useState } from 'react'
import { TableModalContainer } from '../../../../../ui/table/styles'
import { ModalTextSecondary } from '../../../../../components/Modal/styles'
import { LoginContainer } from '../styles'
import { ModalListContainer, ModalListHeader, ModalListItemWithBtn, ModalListWrapper } from '../../../../../ui/list'
import Loader from '../../../../../ui/Loader'
import { BiRefresh, BiTrash } from 'react-icons/bi'
import { deleteSocialLogin, getSocialLogins } from '../../../../../actions/socialActions'
import useOauth from '../../../../../hooks/useOauth'

const SelectNetworkAccountStep = ({ handleAddSocialLogin, setSelectedAccount, selectedAccount, networkType, isAddSocialLoginModalOpen }) => {
    const [loader, setLoader] = useState(false)
    const [systemAccounts, setSystemAccounts] = useState([])
    const { onFocusBack, handleOAuth } = useOauth(networkType?.name);

    // * Fetches list of system accounts
    useEffect(() => {
        setLoader(true);
        getSocialLogins(res => {
            if (res?.length) {
                if (networkType?.name === 'Google') {
                    const goAccounts = res.filter(acc => acc.network === 'google');
                    setSystemAccounts(goAccounts);
                    if (goAccounts?.length === 1) setSelectedAccount(goAccounts[0])

                }

                if (networkType?.name === 'Facebook') {
                    const fbAccounts = res.filter(acc => acc.network === 'facebook');
                    setSystemAccounts(fbAccounts);
                    if (fbAccounts?.length === 1) setSelectedAccount(fbAccounts[0])
                }

                if (networkType?.name === 'Linkedin') {
                    const lnAccounts = res.filter(acc => acc.network === 'linkedin');
                    setSystemAccounts(lnAccounts);
                }
            }
            setLoader(false);
        })
    }, [isAddSocialLoginModalOpen, onFocusBack])

    // * Deletes Social Item
    const handleDelete = account => {
        deleteSocialLogin({
            socialLoginId: account.id,
            callback: () => {
                const updatedSystemAccountsArr = systemAccounts.filter(curr => curr.id !== account.id);
                setSystemAccounts(updatedSystemAccountsArr);
            }
        })
    }

    // * Function returns list of selectable Social Items
    const systemAccountList = () => {
        if (loader) return <div className='center-account-loader'><Loader /></div>
        if (!systemAccounts.length) return <p style={{textAlign: 'center'}}>No Accounts Found</p>;

        return systemAccounts?.map(account =>
            <ModalListItemWithBtn
                key={account.id}
                isSelected={selectedAccount?.id === account.id}
                onClick={() => setSelectedAccount(account)}
            >
                {account.displayName}
                <div className='action-buttons'>
                    <BiRefresh size={24} onClick={() => handleOAuth(networkType?.name)} />
                    <BiTrash onClick={() => handleDelete(account)} />
                </div>
            </ModalListItemWithBtn>)
    }

    return (
        <TableModalContainer>
            <ModalTextSecondary> Select a network account to add {networkType?.header}. </ModalTextSecondary>
            <LoginContainer>
                <span onClick={handleAddSocialLogin}> Add {networkType.name} Login </span>
            </LoginContainer>
            <ModalListWrapper>
                <ModalListHeader> Display Name </ModalListHeader>
                <ModalListContainer>
                    {systemAccountList()}
                </ModalListContainer>
            </ModalListWrapper>
        </TableModalContainer>
    )
}

export default SelectNetworkAccountStep