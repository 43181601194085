import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import { Input } from '../../../../ui/input/input'


const CampaignType = ({isEdit, googleIcon, campaignType}) => {
    return (
        <FieldContainer>
            <h3>{googleIcon} Campaign Type</h3>
            {
                isEdit ?
                    <Input value='Search' disabled />
                    :
                    <p> {campaignType} </p>
            }
        </FieldContainer>
    )
}

export default CampaignType