
export const isDuplicateName = (name, list, websafe) => list.find(data => {
    if (!name) return false;
    const result = data.name.toLowerCase() === name.toLowerCase();
    if (websafe && data.websafe === websafe && result) return !result;
    return result;
});

export const isLocalhost = () => {
    return Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
}

const getPostUrl = uploadUrl => {
    // For Local Environment, use relative path
    let url = isLocalhost() ?  uploadUrl.replace("https://eulerity-brandtech-test.appspot.com/", "/") : uploadUrl;
    return url;
}

export const uploadBlob = ({file, callback}) => {
    window.eulerity.makeApiCall('/srv/getUploadUrl', 'POST', null, function (resp) {
        if (!resp['uploadUrl']) {
            window.eulerity.displayError('Could not generate upload url');
            return;
        }

        let formData = new FormData();
        formData.append('file', file);
        //Modify url for local development because we're running different ports
        let posturl = getPostUrl(resp['uploadUrl']);

        window.eulerity.makeApiCall(posturl, 'POST', formData, function(resp) {

            //! We should be cautious when reviewing this pull request to ensure that removing this line does not cause any errors. We should also test the uploadBlob function to check for any potential issues that may arise.
            // callback?.(resp.file[0]);

            window.eulerity.makeApiCall(`/api/blob/uploadBlobFile?blobkey=${resp.file[0]}&orgId=${1}`, 'POST', null, (resp) => {
                callback?.(resp)
            })
        });
    });
}

export const getBlobFile = async (blobKey, callback) => {
    if (!blobKey) return;

    try {
        window.eulerity.makeApiCall(`/api/blob/get?blobkey=${blobKey}`, 'GET', null, (res) => callback?.(res))
    } catch (error) {
        console.error(error);
    }
}

export const debounce = (func, delay) => {
    let timeoutId;

    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    }
};

