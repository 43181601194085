import styled from "styled-components";

export const StyledBiddingStepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-top: 28px;
    font-size: 16px;
`

export const StyledAccountInfo = styled.div`
    border: 1px solid ${props => props.theme.color.gray};
    border-radius: 9px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const StyledInfoWrapper = styled.div`
    background: ${props => props.theme.background.lightGray};
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;

    .field-group {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`

export const StyledFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const StyledLineBreak = styled.div`
    height: 1px;
    background: #d9d9d9;
    margin: 24px 0px;
`