import React, { useState } from 'react';
import Modal from '../../../../components/Modal/modals/Modal';

// Styles
import { ModalTextSecondary, HeaderInputContainer } from '../../../../components/Modal/styles';
import { BtnWithLoader, OutlineBtn } from '../../../../ui/button';
import { Input } from '../../../../ui/input/input';
import { toast } from 'react-toastify';

const AudienceModal = ({onClose, handleTargetUpdate, audienceDescriptions}) => {
    const [description, setDescription] = useState('');
    const [loader, setLoader] = useState(false);

    const handleSave = () => {
        if (audienceDescriptions.includes(description)) return toast.error('Description already exists!');
        setLoader(true)
        handleTargetUpdate({audienceDescriptions: [...audienceDescriptions, description]}, () => {
            onClose();
            setLoader(false);
            toast.success('Audience Description Added');
        })
    }

    return (
        <Modal
            header='Add Audience Description'
            width='550px'
            buttons={[
                <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>,
                <BtnWithLoader btnText='Add Audience Description' loader={loader} onClick={handleSave} type="blackBtn" isDisabled={!description?.length}/>
            ]}
            onClose={onClose}
        >
            <>
                <HeaderInputContainer>
                    <ModalTextSecondary> Audience Description </ModalTextSecondary>
                    <Input type='text' onChange={e => setDescription(e.target.value)} />
                </HeaderInputContainer>
            </>
        </Modal>
    )
}

export default AudienceModal;