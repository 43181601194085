import React, { useState } from 'react'
import useAdvancedFilter from '../hooks/useAdvancedFilter';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { CategoryItem, CategoryOptionsWrapper, CounterContainer, FilterContainer, FilterOption, FilterOptionContainer, QuickActionsContainer, SearchContainer } from './styles'
import Search from '../../../ui/search';

const AdvancedFilter = ({ filterConfig }) => {
  const { categories, selectedFilters, setSelectedFilters } = filterConfig

  const {
    selectedCategory,
    filteredOptions,
    allOptionsSelected,
    someOptionsSelected,
    handleSelectCategory,
    countSelectedOptions,
    handleSelectOption,
    isChecked,
    handleUpdateQuery,
    handleSelectAll,
    handleDeSelectAll
  } = useAdvancedFilter({ selectedFilters, setSelectedFilters, categories })


  // * Navigation
  const [categoryPage, setCategoryPage] = useState(1);
  const categoriesPerPage = 4;
  const startingIndex = (categoryPage - 1) * categoriesPerPage;
  const endingIndex = startingIndex + categoriesPerPage
  const renderCategories = categories.slice(startingIndex, endingIndex)
  const isFirstPage = categoryPage === 1
  const isLastPage = endingIndex >= categories?.length

  const handleNext = () => setCategoryPage(prev => prev + 1)
  const handlePrev = () => setCategoryPage(prev => prev - 1)

  return (
    <FilterContainer>
      <div className='filter-content'>
        <div className='category-wrapper'>
          <div className='chevron-container'>
            {
              !isFirstPage && <FaChevronLeft size={16} onClick={() => handlePrev()} />
            }
          </div>
          <CategoryOptionsWrapper>
            {
              renderCategories.map((item, index) => {
                return (
                  <CategoryItem isSelected={selectedCategory === item.categoryName} onClick={() => handleSelectCategory(item)} key={index}>
                    <div className='category-text'>{item?.categoryName.toUpperCase()}</div>
                  </CategoryItem>
                )
              })
            }
          </CategoryOptionsWrapper>
          <div className='chevron-container'>
            {
              !isLastPage && <FaChevronRight size={16} onClick={() => handleNext()} />
            }
          </div>
        </div>
      </div>
      <SearchContainer>
        <Search text='Search Filter Category' placeholder="Search Filter Category" handleFilter={(e) => handleUpdateQuery(e.target.value)} style={{ padding: '2px 8px', height: '36px' }} />
      </SearchContainer>
      <CounterContainer>
        <small>{countSelectedOptions()}</small>
      </CounterContainer>
      <QuickActionsContainer>
        {
          !allOptionsSelected && <small onClick={() => handleSelectAll()}>Select All</small>
        }
        {
          someOptionsSelected && <small onClick={() => handleDeSelectAll()}>Deselect All</small>
        }
      </QuickActionsContainer>
      <FilterOptionContainer>
        {
          filteredOptions.map((option, index) => {
            return (
              <FilterOption key={index}>
                <input type='checkbox' onChange={() => handleSelectOption(option)} checked={isChecked(option)} />
                <p style={{ maxWidth: '260px' }}>{option}</p>
              </FilterOption>
            )
          })
        }
      </FilterOptionContainer>
    </FilterContainer>
  )
}

export default AdvancedFilter