import React from 'react';

// Styles
import { TemplateCard } from '../styles';
import { Text } from '../../../ui/text';
import { Label } from '../../../ui/card/styles';

export const NetworkTemplateCard = ({template, campaign}) => {
    return (
        <TemplateCard>
            <Text> <span>NETWORK TEMPLATE</span>: {template?.name} </Text>
            { campaign?.name && <Text> <span>NETWORK CAMPAIGN NAME</span>: {campaign?.name} </Text> }
            {/* <Text> <span>NETWORK TEMPLATE TYPE</span>: Advertiser - {advertiser?.name || 'All Advertisers'} </Text> */}

            <div>
                <Label> Goal : {template?.goal} </Label>
                <Label> Priority : {template?.priority} </Label>
            </div>
            {campaign?.audienceType && <div>
                <Label> Targeting Type: {campaign?.audienceType} </Label>
            </div>}
            {template?.description && <Text> <span>DESCRIPTION</span>: {template?.description} </Text>}
        </TemplateCard>
    )
}

export default NetworkTemplateCard;