import React, { useState, useEffect } from 'react';
import BuildModal from '../../../../../components/Modal/modals/BuildModal';

// Steps
import InfoStep from './InfoStep';
import AddKeywordStep from './AddKeywordStep';
import AddAudienceSegmentStep from './AddAudienceSegmentStep';
import ReviewAdGroupStep from './ReviewAdGroupStep';
import { BlackBtn, BtnWithLoader, OutlineBtn } from '../../../../../ui/button';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateNetworkConfig } from '../../../../../actions/lobActions';

const AdGroupsModal = ({onClose, adAccounts, audienceTargets, audienceDescriptions, isEditingAdGroup, editedAdGroupData, editedIndex, goConfigId}) => {
    const _D = useDispatch()
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [adAccount, setAdAccount] = useState('');
    const [selectedAudienceType, setSelectedAudienceType] = useState('');
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState({});
    const [negativeKeywords, setNegativeKeywords] = useState({});
    const [matchAudienceSegment, setMatchAudienceSegment] = useState([]);
    const [excludeAudienceSegment, setExcludeAudienceSegment] = useState([]);
    const [includeAudiences, setIncludeAudiences] = useState([]);
    const [excludeAudiences, setExcludeAudiences] = useState([]);

    // * Loaders
    const [loading, setLoading] = useState(false)

    const isDisabled = !name || !adAccount || !description;
    const isKeywordsDisabled = Object.keys(keywords).length === 0 && Object.keys(negativeKeywords).length === 0;

    useEffect(() => {
        if(!isEditingAdGroup || !editedAdGroupData) return

        // * extract data from ad group (selected)
        const { name, description, type, keywords, negativeKeywords, includedSegments, excludedSegments, accountId} = editedAdGroupData
        const matchedAccount = adAccounts.find(acc => acc?.accountId === accountId)
        // * initialize ad group data
        setName(name || '')
        setDescription(description || '')
        setSelectedAudienceType(type || '');
        setKeywords(keywords || {})
        setNegativeKeywords(negativeKeywords || {})
        setMatchAudienceSegment(includedSegments || [])
        setExcludeAudienceSegment(excludedSegments || [])
        setAdAccount(matchedAccount || '')
    }, [])

    // * get default values for selection drop downs
    const getDefaultAudienceDescription = () => {
        return { label: description, value: description }
    }

    const getDefaultAudienceType = () => {
        return { label: selectedAudienceType, value: selectedAudienceType }
    }

    const getDefaultAccount = () => {
            // const matchedAccount = adAccounts.find(acc => acc?.accountId === editedAdGroupData?.accountId)
        return { label: adAccount?.displayName, value: adAccount?.accountId }
    }

    const getDefaultMatchType = (isNegative) => {
        const keywordTypes = ['EXACT', 'BROAD', 'PHRASE'];

        // * By default selected "PHRASE" if no csv files exists in given type
        if(isNegative && Object.keys(negativeKeywords).length === 0) return { label: 'PHRASE', value: 'PHRASE' }
        if(Object.keys(keywords).length === 0) return { label: 'PHRASE', value: 'PHRASE' }

        // * Pre-select/persist dropdown value (for first file upload)
        const currType = Object.keys(isNegative ? negativeKeywords : keywords).find(type => keywordTypes.includes(type))
        return currType ? { label: currType, value: currType } : { label: 'PHRASE', value: 'PHRASE' }
    }

    // * Step Functions
    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
    const handleSkip = () => setStep(step + 1);

    // * Keywork Step Functions
    const handleSaveKeywords = (keywordsObj, negativeKeywordsObj) => {
        setKeywords(keywordsObj);
        setNegativeKeywords(negativeKeywordsObj);
        handleNext();
    }

    const handleSkipKeywords = () => {
        setKeywords({});
        setNegativeKeywords({});
        handleSkip();
    }

    // * Audience Functions
    const handleSkipAdGroup = () => {
        setMatchAudienceSegment([]);
        setExcludeAudienceSegment([]);
        handleSkip();
    }

    const handleSaveInterests = (include, exclude) => {
        setMatchAudienceSegment(include);
        setExcludeAudienceSegment(exclude);
        handleNext();
    }

    // * Save Ad Group Functions
    const handleSaveAdGroup = (updatedAudienceTargets) => {
        setLoading(true)
        _D(updateNetworkConfig({
            obj: {audienceTargets: updatedAudienceTargets},
            socialConfig: `goconfig=${goConfigId}`,
            callback: () => {
                toast.success('Added Audience Target');
                setLoading(false)
                onClose()
            }
        }))
    }

    // * Will config/reconfigure audienceTarget (ad group) object
    const handleConfigAdGroup = () => {
        let updatedAudienceTargets = []
        const obj = {
            name,
            description,
            adAccount,
            type: selectedAudienceType,
            includedSegments: matchAudienceSegment,
            excludedSegments: excludeAudienceSegment,
            keywords,
            negativeKeywords,
            accountId: adAccount?.accountId
        }

        if (isEditingAdGroup) {
            updatedAudienceTargets = [...audienceTargets]
            updatedAudienceTargets[editedIndex] = obj;
            handleSaveAdGroup(updatedAudienceTargets)
            return
        }

        updatedAudienceTargets = [...audienceTargets, obj];
        handleSaveAdGroup(updatedAudienceTargets)
    }

    const handleSaveAndExit = () => {
        if(isEditingAdGroup) {
            handleConfigAdGroup()
            return
        }

        const updatedAudienceTargets = [...audienceTargets, {
            name,
            description,
            type: selectedAudienceType,
            adAccount,
            accountId: adAccount.accountId
        }]

        handleSaveAdGroup(updatedAudienceTargets)
    }

    const steps = () => {
        switch (step) {
            case 1:
                return <InfoStep
                            name={name}
                            setName={setName} setAdAccount={setAdAccount} setDescription={setDescription}
                            adAccounts={adAccounts} audienceDescriptions={audienceDescriptions}
                            selectedAudienceType={selectedAudienceType} setSelectedAudienceType={setSelectedAudienceType}
                            getDefaultAudienceDescription={getDefaultAudienceDescription} getDefaultAudienceType={getDefaultAudienceType} getDefaultAccount={getDefaultAccount}
                        />
            case 2:
                return <AddKeywordStep
                            description={description}
                            positiveKeywords={keywords} setPositiveKeywords={setKeywords}
                            negativeKeywords={negativeKeywords} setNegativeKeywords={setNegativeKeywords}
                            isEditingAdGroup={isEditingAdGroup} getDefaultMatchType={getDefaultMatchType}
                        />
            case 3:
                return <AddAudienceSegmentStep
                            adAccount={adAccount} matchAudienceSegment={matchAudienceSegment} excludeAudienceSegment={excludeAudienceSegment} isEditingAdGroup={isEditingAdGroup} includeAudiences={includeAudiences} setIncludeAudiences={setIncludeAudiences} excludeAudiences={excludeAudiences} setExcludeAudiences={setExcludeAudiences}
                        />
            case 4:
                return <ReviewAdGroupStep
                            keywords={keywords} negativeKeywords={negativeKeywords}
                            excludeAudienceSegment={excludeAudienceSegment} matchAudienceSegment={matchAudienceSegment}
                        />
            default:
              console.log(`No step selected`);
        }
    }

    const decideButtons = () => {
        switch(step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BtnWithLoader btnText='Add and Exit' onClick={handleSaveAndExit} loader={loading} isDisabled={isDisabled} />, <BlackBtn onClick={handleNext} isDisabled={isDisabled}> Next </BlackBtn>]
            case 2: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <OutlineBtn onClick={handleSkipKeywords}> Skip </OutlineBtn>, <BlackBtn onClick={() => handleSaveKeywords(keywords, negativeKeywords)} isDisabled={isKeywordsDisabled}> Next </BlackBtn>]
            case 3: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <OutlineBtn onClick={handleSkipAdGroup}> Skip </OutlineBtn>, <BlackBtn onClick={() => handleSaveInterests(includeAudiences, excludeAudiences)}> Next </BlackBtn>]
            case 4: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BtnWithLoader type='blackBtn' btnText='Add Google Ad Group' onClick={handleConfigAdGroup} loader={loading} />
        ]
            default: return []
        }
    }

    return (
        <BuildModal
            header={`${isEditingAdGroup ? 'Edit' : 'Create'} Google Search Ad Group`}
            onClose={onClose}
            buttons={decideButtons()}
            height={"750px"}
            minWidth={"800px"}
        >
            {steps()}
        </BuildModal>
    )
}

export default AdGroupsModal;