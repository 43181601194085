import { useEffect, useRef } from 'react';

const useLastValue = (v) => {
    const ref = useRef(v);

    useEffect(() => {
        ref.current = v;
    }, [v]);

    return ref.current;
};

export default useLastValue;