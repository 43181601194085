import React, { useState, useEffect } from 'react';

// Redux
import { getAdvertiser } from '../../actions/systemConfigActions';
import { serveImage } from '../../pages/systemConfig/components/labelManagement/label.utils';

// Styles
import { WideCardContainer, NameLabel, Label, InverseLabel, LabelsContainer, DetailsContainer, WideCardDescription } from './styles';

const WideCard = ({data, setSelectedCard, isSelected}) => {
    const [advertiser, setAdvertiser] = useState({});
    useEffect(() => {
        if (data?.advertiser?.websafe) {
            getAdvertiser({
                websafe: data?.advertiser?.websafe,
                callback: res => setAdvertiser(res)
            })
        }
    }, [data?.advertiser?.websafe]);

    const {name, labels, description, goal, priority} = data;

    const renderLabels = labels?.map((label, idx) => <InverseLabel key={idx}> {label} </InverseLabel>);

    return (
        <WideCardContainer isSelected={isSelected} onClick={() => setSelectedCard(data)}>
            <DetailsContainer>
            <NameLabel> { name } </NameLabel>
            {
                data?.advertiser?.websafe &&
                    <NameLabel> Advertiser:
                        <span className='advertiser'>
                            { advertiser.name }
                            {advertiser.logo && <img src={serveImage(advertiser.logo)} alt='logo' />}
                        </span>
                    </NameLabel>
            }
            {
                goal && priority &&
                <LabelsContainer>
                    <Label> Goal: {goal} </Label>
                    <Label> Priority: {priority} </Label>
                </LabelsContainer>
            }
            {
                labels?.length > 0 &&
                <LabelsContainer>
                    {renderLabels}
                </LabelsContainer>
            }
            {
                description &&
                <WideCardDescription> {description} </WideCardDescription>
            }
            </DetailsContainer>
        </WideCardContainer>
    )
}

export default WideCard;