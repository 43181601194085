import React, {useContext} from 'react';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../../../ui/input/fields/CustomDatePicker';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignGroupSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { DatePickerWrapper } from '../../../../../ui/input/input';

const EndDate = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { end, budgetSchedule } = campaignObj || {};

    if (!budgetSchedule?.includes('END')) return;

    return (
        <FieldContainer>
            <h3> End Date <span className='asterisk'>*</span> </h3>
            {
                isEdit ?
                <DatePickerWrapper>
                    <DatePicker
                        customInput={<CustomDatePicker />}
                        minDate={new Date()}
                        wrapperClassName='datePicker'
                        onChange={(date) => handleUpdate({key: 'end', value: date.getTime()})}
                        selected={end && new Date(end)}
                    />
                </DatePickerWrapper> :
                <p> {end ? new Date(end).toDateString() : "N/A"} </p>
            }
        </FieldContainer>
    )
}

export default EndDate;