import styled from "styled-components";
export const SystemConfigContainer = styled.div`
  background-color: ${(props) => props.theme.background.gray};
  height: 100%;
  width: 100%;

  .settingIcon {
    transition: all 0.4s;
    transform: rotate(${props => props.iconRotation}deg);
  }
`;

export const StyleSysyemConfigMain = styled.div`
  display: flex;
  height: calc(100% - 88px);
`;

export const StyleSystemConfigNavigator = styled.div`
  /* width: 200px; */
  margin: 3rem 0 0 15px;
  .btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 5px;
    }
  }


  .system-configBtn {
    width: 160px;
    border-radius: 3px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  button {
    width: 100%;
  }
`;

export const StyleSysyemConfigDisplay = styled.div`
  padding: 1rem;
  height: 100%;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyleSysyemConfigContent = styled.div`
  padding: 2rem;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.background.white};
`;

export const StyleLabelManagementGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const AdvancedTableGrid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
`
