import React, { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateLob, deleteLob } from '../../../actions/lobActions';

// Components
import Navigation from './Navigation';
import LobDescription from './LobDescription';
import Demographics from './Demographics';
import Activation from './Activation';
import GoogleNetworkConfig from './networkConfigs/GoogleNetworkConfig';
import FacebookNetworkConfig from './networkConfigs/FacebookNetworkConfig';
import LinkedInNetworkConfig from './networkConfigs/linkedIn/LinkedInNetworkConfig';
import DummyNetworkConfig from './networkConfigs/DummyNetworkConfig';
import DeleteModal from '../../../components/Modal/DeleteModal';
import EditContext from './Context/EditContext';

// Styles
import { PageContainer } from '../../../ui/containers';
import { CreateLobContainer } from '../styles';
import { toast } from 'react-toastify';

// Constants
import { LOB_NAV } from '../../../constants/lob';
import { RedBtn } from '../../../ui/button';
import { PageHeaderContainer, PageHeader } from '../../../ui/headers';
import useFadeAnimation from '../../../hooks/useFadeAnimation';

const EditLob = ({setIsEditLob}) => {
    const [currSelector, setCurrSelector] = useState(LOB_NAV.DESCRIPTION);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const lob = useSelector(state => state.lob.currentLineOfBusiness);
    const clientTypes = useSelector(state => state?.org?.organization?.clients);

    const _D = useDispatch();
    const ref = useFadeAnimation([currSelector]);

    // * Lob Updating Function
    const handleUpdateLob = (updateObj) => {
        _D(updateLob({
            websafe: lob.websafe,
            updateObj,
            callback: () => {
                toast.success(`${lob.name} line of business updated`);
            },
            errorCallback: () => {
                toast.error('Something went wrong with updating');
            }
        }))
    }

    const handleClose = () => {
        setIsDeleteLoading(false);
        setIsDeleteModalOpen(false);
    }

    const handleDelete = () => {
        setIsDeleteLoading(true);
        _D(deleteLob({
            websafe: lob.websafe,
            callback: () => {
                handleClose();
                toast.success(`${lob.name} line of business deleted`);
                setIsEditLob(false);
            },
            errorCallback: () => {
                handleClose();
                toast.error('Something went wrong with deleting LOB')
            }
        }))
    }

    const renderSelectedComponent = () => {
        switch(currSelector) {
            case LOB_NAV.DESCRIPTION:
                return <LobDescription clientTypes={clientTypes} />
            case LOB_NAV.DEMOGRAPHIC:
                return <Demographics  />
            case LOB_NAV.ACTIVATION:
                return <Activation />
            case LOB_NAV.NETWORK_CONFIG.GOOGLE:
                return <GoogleNetworkConfig />
            case LOB_NAV.NETWORK_CONFIG.FACEBOOK:
                return <FacebookNetworkConfig />
            case LOB_NAV.NETWORK_CONFIG.LINKEDIN:
                return <LinkedInNetworkConfig />
            default:
                return <DummyNetworkConfig dummy={currSelector} />
        }
    }

    return (
        <CreateLobContainer>

            <Navigation setIsEdit={setIsEditLob} currSelector={currSelector} setCurrSelector={setCurrSelector} lob={lob} />
            <PageContainer ref={ref}>
                <PageHeaderContainer>
                    <PageHeader> {isEdit && 'Editing '}{lob?.name} </PageHeader>
                    <RedBtn onClick={() => setIsDeleteModalOpen(true)}> Delete LOB </RedBtn>
                </PageHeaderContainer>
                <EditContext.Provider value={{isEdit, setIsEdit, handleUpdateLob}}>
                    {renderSelectedComponent()}
                </EditContext.Provider>

            </PageContainer>
            {
                isDeleteModalOpen &&
                <DeleteModal
                    setIsDeleteModalOpen={() => setIsDeleteModalOpen(false)}
                    header='Delete LOB'
                    modalText={`Are you sure you want to delete ${lob?.name}`}
                    deleteBtnText='Delete LOB'
                    handleDelete={handleDelete}
                    loading={isDeleteLoading}
                />
            }
        </CreateLobContainer>
    )
}

export default EditLob;