import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { resetTemplate, setSelectedCampaign } from '../../../actions/tempActions';
import { TEMP_NAV } from '../../../constants/temp';
import { FaPlus } from 'react-icons/fa';
import { PageNavigationContainer, NavigationBtn, SubNavigationBtn, MultiBtnContainer, PlusNavigationBtn, PlusNavigationBtnContainer } from '../../../components/Sidebar/styles';
import CreateExistingTemplateModal from './modal/CreateExistingTemplateModal';
import BackButton from '../../../ui/backBtn';
import gsap from 'gsap'
import { NETWORKS } from '../../../constants/networks';
import { getCampaignCopyText } from '../templates.util';

gsap.config({ nullTargetWarn: false });

const TemplateNavigation = ({setIsEdit, currSelector, setCurrSelector}) => {
    const template = useSelector(state => state.template.currentNetworkTemplate);
    const selectedCampaign = useSelector(state => state.template.selectedCampaign)
    const campaignCopyText = getCampaignCopyText(template?.network)

    const [clickToShow, setClickToShow] = useState(false);
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const [startFromScratch, setStartFromScratch] = useState(false)

    const campaigns = template?.campaigns
    const hasCampaigns = campaigns?.length > 0;

    const _D = useDispatch();

    const shouldShowMore = () => {
        if (!hasCampaigns || !clickToShow) return false;
        return true;
    }

    const handleSelectCampaign = (campaign) => {
        _D(setSelectedCampaign(campaign))
        setCurrSelector(TEMP_NAV.CAMPAIGN_DETAILS);
    }

    useEffect(() => {
        const closeTab = (e) => {
            if(e.target.tagName === 'path') e.target.id = 'path-option'
            if(e.target.id !== 'option-side' && e.target.id !== "option-icon" && e.target.id !== "path-option") setClickToShow(false)
        }
        document.addEventListener('click', closeTab)
        return () => document.removeEventListener('click', closeTab)
    })

    useEffect(() => {
        gsap.timeline().fromTo('.stagger', {opacity: 0}, {opacity: 1} )
    },[campaigns?.length])

    const renderCampaigns = () => {
        if (!hasCampaigns) return;
        return campaigns.sort().map((campaign) => {
            return (
                <SubNavigationBtn key={campaign.id} onClick={() => handleSelectCampaign(campaign)} isSelected={campaign.id === selectedCampaign?.id}>
                    <div>{campaign?.name}</div>
                </SubNavigationBtn>
            )
        })
    }

    const handlePlusClick = (e) => {
        e.stopPropagation()
        if (!hasCampaigns) {
            setStartFromScratch(true)
            setIsCopyModalOpen(true)
            _D(setSelectedCampaign({}))
            return;
        }
        setClickToShow(!clickToShow);
    }

    const handleStartFromScratch = (e) => {
        e.stopPropagation()
        setStartFromScratch(true)
        _D(setSelectedCampaign({}))
        setIsCopyModalOpen(true)
        setClickToShow(false)
    }

    const handleExistingCampaign = () => {
        setStartFromScratch(false)
        setIsCopyModalOpen(true)
        setClickToShow(false)
    }

    const handleCurrSelector = (nav) => {
        setCurrSelector(nav);
        _D(setSelectedCampaign({}))
    }

    const handleBackToOverview = () => {
        setIsEdit(false);
        setCurrSelector(null);
        _D(setSelectedCampaign({}))
        _D(resetTemplate())
    }

    const campaignDetailsController = (network) => {
        switch(network) {
            case NETWORKS.LINKEDIN: return <NavigationBtn fontSize='22px' isSelected={selectedCampaign?.id}>Campaign Group Details</NavigationBtn>
            default: return <NavigationBtn isSelected={selectedCampaign?.id}>Campaign Details</NavigationBtn>
        }
    }

    return (
        <>
        <PageNavigationContainer>
            <BackButton handle={handleBackToOverview} />
            <NavigationBtn onClick={() => handleCurrSelector(TEMP_NAV.CONFIG_DETAILS)} isSelected={currSelector === TEMP_NAV.CONFIG_DETAILS}>
                Details/Config
            </NavigationBtn>

            <MultiBtnContainer>
                {campaignDetailsController(template.network)}
                <div className='stagger'>
                    {renderCampaigns()}
                </div>
                <PlusNavigationBtnContainer className='stagger' showMore={shouldShowMore()} onClick={handlePlusClick}>
                    <PlusNavigationBtn isSelected={currSelector === TEMP_NAV.DEMOGRAPHIC} id='option-side'>
                            <FaPlus id="option-icon" />
                            <span>Add {campaignCopyText}</span>
                    </PlusNavigationBtn>
                    <div>
                        <li onClick={handleExistingCampaign}> Use Existing {campaignCopyText} </li>
                        <li onClick={handleStartFromScratch}> Start from Scratch </li>
                    </div>
                </PlusNavigationBtnContainer>
            </MultiBtnContainer>
        </PageNavigationContainer>
            {
                isCopyModalOpen &&
                <CreateExistingTemplateModal
                    setClickToShow={setClickToShow}
                    startFromScratch = {startFromScratch}
                    onClose={() => setIsCopyModalOpen(false)}
                    setCurrSelector={setCurrSelector}
                />
            }
        </>
    )
}

export default TemplateNavigation;