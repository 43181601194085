import styled, {css} from 'styled-components';

const backgrounds = {
    padding: '12px',
    backgroundColor: `${props => props.theme.background.white}`,
    borderRadius: '4px',
}

const fonts = {
    fontFamily: 'GothamBook',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px'
}

const inputProps = css`
     border: 1px solid ${props => props.theme.input.gray};
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`


export const Input = styled.input`
    ${backgrounds}
    ${fonts}
    ${inputProps}
    ${props => props.customStyles}

    &:focus {
        outline: none;
        border: 1px solid ${props => props.theme.color.yellow};
    }
`;

export const NumberInput = styled(Input)`
    max-width: ${props => props?.fullWidth ? '100%' : '200px'};

    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    [type=number] {
    -moz-appearance: textfield;
    }
`;

export const Textarea = styled.textarea`
    all: unset;
    ${backgrounds}
    ${fonts}
    border: 1px solid ${props => props.theme.input.gray};
    word-break: break-all; // * used to prevent long strings from going past width of area when typing
`;

export const LabelInput = styled.div`
    ${backgrounds}
    padding: 6px;
    border: 1px solid ${props => props.theme.input.gray};
    display: flex;
    height: 40px;
`;

export const LabelItem = styled.li`
    ${fonts}
    font-size: 12px;
    margin-right: 4px;
    background-color: ${props => props.theme.color.yellow};
    padding: 6px 12px;
    border-radius: 6px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-items: center;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

// Checkbox
export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;

    p {
        white-space: nowrap;
    }

    input {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
`;

// Date Picker
export const DatePickerWrapper = styled.div`
    input {
        all: unset;
        ${fonts}
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
    }
`;