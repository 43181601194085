import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import PercentInput from '../../../../ui/input/fields/PercentInput'
import Information from '../../../../ui/tooltip/Information'

const DesktopBidding = ({ googleIcon, isEdit, setDesktopBid, desktopBid }) => {
  return (
    <FieldContainer>
      <h3>
        {googleIcon} Desktop Bid Adjustment
        <Information text='This campaign setting field is used as a reference and should be set on the network manager.' />
      </h3>
      {
        isEdit ?
          <PercentInput value={desktopBid} min='-100' max='900' onChange={e => setDesktopBid(e.target.value)} />
          : <p>{desktopBid} %</p>
      }
    </FieldContainer>
  )
}

export default DesktopBidding