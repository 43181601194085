import React, { useEffect, useState } from 'react'
import Modal from '../../../../../../components/Modal/Modal'
import { BtnContainer } from '../../../../../../components/Modal/styles'
import { BlackBtnLg, OutlineBtn } from '../../../../../../ui/button'
import { Input } from '../../../../../../ui/input/input'
import { NameAbbreviationContainer } from '../../styles'
import { toast } from "react-toastify";

const TargetingModal = ({ onClose, target, targets, updateTargets }) => {
    const [targetName, setTargetName] = useState('');
    const [targetAbbreviation, setTargetAbbreviation] = useState('');

    useEffect(() => {
        if (target) {
            setTargetName(target.key)
            setTargetAbbreviation(target.value)
        }
    }, [])

    const handleAddTarget = () => {
        const doesTargetExist = Object.keys(targets).includes(targetName)

        if (!targetName.length) return toast.error('Please enter a target type!')
        if (doesTargetExist) return toast.error(`${targetName} already exists!`)

        const updatedTargets = {
            ...targets,
            [targetName]: targetAbbreviation
        }


        updateTargets(updatedTargets, 'audienceTypeMap')
        onClose()
    }

    const handleUpdateTarget = () => {
        if (!targetName.length) return toast.error('Please enter a target type!')

        const currData = { ...targets };
        delete currData[target?.key];

        const doesTargetExist = Object.keys(currData).includes(targetName);
        if (doesTargetExist) return toast.error(`${targetName} already exists!`)


        const updatedTargets = {
            ...currData,
            [targetName]: targetAbbreviation
        }

        updateTargets(updatedTargets, 'audienceTypeMap');
        onClose();
    }

    return (
        <Modal onClose={onClose} header={`${target ? 'Edit' : 'Add'} Label for Target Type`}>
            <NameAbbreviationContainer>
                <div className='input__container'>
                    <p className='input__header__text'>Targeting Type<span className='asterisk'> *</span></p>
                    <Input
                        placeholder='Enter Targeting Type'
                        onChange={(e) => setTargetName(e.target.value)}
                        value={targetName}
                    />
                </div>
                <div className='input__container'>
                    <p className='input__header__text'>Abbreviation </p>
                    <Input
                        placeholder='Enter Abbreviation'
                        onChange={(e) => setTargetAbbreviation(e.target.value)}
                        value={targetAbbreviation}
                    />
                </div>
            </NameAbbreviationContainer>
            <BtnContainer>
                <OutlineBtn onClick={onClose}>Cancel</OutlineBtn>
                <BlackBtnLg onClick={target ? handleUpdateTarget : handleAddTarget}>{target ? 'Update' : 'Add'} Target Type Label</BlackBtnLg>
            </BtnContainer>
        </Modal>
    )
}

export default TargetingModal