import React  from "react";
import styled from "styled-components";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const NumberInput = ({value, localState}) => {

  const handleOnChange = (e) => {

    var reg = new RegExp("(^[0-9]+$|^$)");
    let target = parseInt(e.target.value);

    if ((reg.test(target) && target !== 0) || isNaN(target)) {
      if (isNaN(target) || target < 91) {
        if (isNaN(target)) {
          localState(0);
        } else {
          localState(target);
        }
      }
    }
  };

  const incrementNumber = (e) => {
    if (value < 90) {
      localState((prew) => {
        if (isNaN(parseInt(prew))) return 1;
        return parseInt(prew) + 1;
      });
    }
  };

  const decrementNumber = (e) => {
    if (value > 0) {
      localState((prew) => {
        if (isNaN(parseInt(prew))) return 1;
        return parseInt(prew) - 1;
      });
    }
  };


  return (
    <StyleNumberInput>
      <input  pattern="[0-9]" onChange={handleOnChange} value={value} placeholder="0" />
      <div className="number-wrapper">
        <div className="number-icon increment" onClick={incrementNumber}>
          <BiChevronUp size='22' />
        </div>
        <div className="number-icon decrement" onClick={decrementNumber}>
         <BiChevronDown size='22'/>
        </div>
      </div>
    </StyleNumberInput>
  );
};

const StyleNumberInput = styled.div`
  padding: 0.3rem;
  width: 72px;
  display: flex;
  height: 40px;
  align-items: center;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  input {
    width: 100%;

    border: none;
    outline: none;
    font-size: 15px;
    text-align: center;
  }
  .number-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30%;
    transition: 0.5s;
    :hover {
        background-color: ${props => props.theme.color.yellow};
    }
  }
`;

export default NumberInput;
