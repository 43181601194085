import React, { useEffect, useState } from "react";
import { BlackIconBtn, ConnectNetwrokBtn, FacebookButton } from "../../../../../ui/button";
import { StyleConnectionPage } from "../styles";
import { LOB_NAV } from "../../../../../constants/lob";
import useOauth from "../../../../../hooks/useOauth";
import Loader from "../../../../../ui/Loader";

import { FacebookI, GoogleI } from "../../../../../ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setSocialLogins } from "../../../../../actions/systemConfigActions";
import { oAuthGetSocialLoginMethod } from "../../../utils";
import { toast } from "react-toastify";


const ConnectPage = ({ network,  networkSocialLogin, setOpenSysAccountModal }) => {
  const socialLogins = useSelector(state => state.system.socialLogins)
  const { onFocusBack, handleOAuth } = useOauth(network);
  const [oAuthLoader, setOauthLoader] = useState(false)
  const lNetwork = network?.toLowerCase();
  const fbNetwork = lNetwork === LOB_NAV.NETWORK_CONFIG.FACEBOOK;
  const isConnected = networkSocialLogin.length
  const _D = useDispatch()


  useEffect(() => {
    if (onFocusBack) {
      setOauthLoader(true)
      oAuthGetSocialLoginMethod('/api/user/getSocialLogins').then(response => {
          if(socialLogins.length !== response.length)   _D(setSocialLogins(response));
      }).catch(_ => {
          toast.error("Something went wrong")
      }).finally(_ => {
        setOauthLoader(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFocusBack]);


  const SelectSystemAccount = ({ buttonText, handleClick }) => {
    return (
      <BlackIconBtn onClick={handleClick}>
        {buttonText}
      </BlackIconBtn>
    );
  };

  if (oAuthLoader) return <StyleConnectionPage><Loader /></StyleConnectionPage>
  return (
    <StyleConnectionPage isConnected={isConnected}>
      <p className="CP-header">
        {network} Connection: <span className="CP-span"> {!isConnected ? "NOT" : " "} CONNECTED</span>
      </p>
      {!isConnected ? (
        <>
          <p className="CP-body">Login to {network} to Add a System Account</p>
          {/* {fbNetwork ?
            <FacebookButton text='Login with Facebook' /> : */}
          <ConnectNetwrokBtn network={network} onClick={handleOAuth}>
            {fbNetwork ? <FacebookI /> : <GoogleI />}
            <p>Connect to {network}</p>
          </ConnectNetwrokBtn>
        {/* } */}
        </>
      ) : (
        <div className="CP-wrapper">
          <p className="CP-body"> This system account is used to make queries on {network}. This connection is not used to launch campaigns on the network.</p>
          <SelectSystemAccount buttonText="Replace System Account" handleClick={setOpenSysAccountModal.bind(null, true)}/>
        </div>
      )}
    </StyleConnectionPage>
  );
};

export default ConnectPage;
