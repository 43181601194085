import { isLocalhost } from "../../../../utils";

export const serveImage = blobKey => {
  if (!blobKey) return;
  return isLocalhost() ? `https://eulerity-brandtech-test.appspot.com/srv/${blobKey}` : `${window.origin}/srv/${blobKey}`
}

export const dummyNetworkImage = (network, dummyNetworks) => {
  if (!dummyNetworks || !network) return;
  const result = dummyNetworks?.find(item => item.name === network)?.logo;

  return serveImage(result);
}

export const objectToObjArr = (objData) => {
  const objArr = Object.entries(objData).map(([key, value]) => ({key, value}))
  return objArr
}