import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import FbIcon from '../../../../../assets/images/socialMediaIcons/FbIcon.png';
import { attachSocialPage } from '../../../../../actions/lobActions';
import SelectNetworkAccountStep from './SelectNetworkAccountStep';
import AddSocialNetworkAccountStep from './AddSocialNetworkAccountStep';
import { BlackBtn, BtnWithLoader, OutlineBtn } from '../../../../../ui/button';
import ConnectSocialModal from '../ConnectSocialModal';
import BuildModal from '../../../../../components/Modal/modals/BuildModal';

const FacebookPageModal = ({ onClose, configId }) => {
    const _D = useDispatch();
    const [step, setStep] = useState(1);
    const [btnLoader, setBtnLoader] = useState(false);
    const [isAddSocialLoginModalOpen, setIsAddSocialLoginModalOpen] = useState(false)

    const fbNetworkObj = {
        name: 'Facebook',
        header: 'Facebook Page',
        icon: FbIcon,
    }

    // * Step 1 States
    const [selectedAccount, setSelectedAccount] = useState();
    // * Step 2 States
    const [selectedPages, setSelectedPages] = useState([]);

    const nextStep = () => setStep(step + 1)
    const onBack = () => setStep(step - 1)

    // * Submit Function
    const handleAttachSocialPage = (list) => {
        setBtnLoader(true)

        _D(attachSocialPage({
            socialLoginId: selectedAccount?.id,
            socialConfig: `fbconfig=${configId}`,
            pages: [{ accountId: list[0]?.id, displayName: selectedPages[0]?.name }],
            callback: () => {
                toast.success('Adding Pages');
                setBtnLoader(false)
                onClose();
            }
        }))
    }

    // * Steps
    const steps = () => {
        switch (step) {
            case 1: return <SelectNetworkAccountStep
                isAddSocialLoginModalOpen={isAddSocialLoginModalOpen}
                handleAddSocialLogin={() => setIsAddSocialLoginModalOpen(true)}
                networkType={fbNetworkObj}
                setSelectedAccount={setSelectedAccount}
                selectedAccount={selectedAccount}
            />
            case 2: return <AddSocialNetworkAccountStep
                socialLoginId={selectedAccount?.id}
                selectedPages={selectedPages}
                setSelectedPages={setSelectedPages}
                socialType='fbSocialAccounts'
                tableHeader='Facebook Page(s)'
            />
            default:
                console.log(`No step selected`);
        }
    }

    // * Buttons
    const decideButtons = () => {
        switch (step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BlackBtn isDisabled={!selectedAccount} onClick={nextStep}> Next </BlackBtn>]
            case 2: return [<OutlineBtn onClick={onBack}> Back </OutlineBtn>, <BtnWithLoader btnText="Add Page" type='blackBtn' color='white' isDisabled={!selectedPages.length} onClick={() => handleAttachSocialPage(selectedPages)} loader={btnLoader} />]
            default: return []
        }
    }


    return (
        <>
        <BuildModal
            header={'Attach Facebook Page'}
            onClose={onClose}
            buttons={decideButtons()}
            height={"750px"}
            minWidth={"700px"}
        >
            {steps()}
        </BuildModal>
        {
            isAddSocialLoginModalOpen &&
            <ConnectSocialModal
                onClose={() => setIsAddSocialLoginModalOpen(false)}
                network={fbNetworkObj}
            />
        }
    </>
    )
}

export default FacebookPageModal