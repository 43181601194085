import styled, { css } from 'styled-components';
import { OutlineBtn } from './button';

const listHeader = {
    fontFamily: 'GothamMedium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px'
}

const listItem = {
    fontFamily: 'GothamBook',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
}

export const ModalListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 12px 0px;
`

export const ModalListContainer = styled.div`
    max-height: 50vh;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: scroll;

    .center-account-loader {
        display: grid;
        place-items: center;
        height: 70px;
    }
`;

export const ModalListHeader = styled.h1`
    ${listHeader}
    border-bottom: 4px solid #F8F3EB;
    padding: 10px;
    display: flex;
    align-items: center;

    img {
        width: 20px;
        margin-right: 2px;
    }
`;

export const ModalListItemWithBtn = styled.div`
    display: flex;
    color: ${props => props.theme.card.gray};
    border: 1px solid ${props => props.theme.color.gray};
    border-radius: 9px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 2px;
    cursor: pointer;

    ${({ isSelected }) => isSelected && css`
        border: 1px solid ${props => props.theme.color.yellow} !important;
        background-color: rgba(248, 243, 235, 0.5);
        `
    }

    ${({ isDisabled }) => isDisabled && css`
        opacity: 0.3 !important;
        pointer-events: none !important;
        `
    }

    div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }
`;

export const DeleteOutlineIconBtn = styled(OutlineBtn)`
    color: red;
    border: 1px solid red;
    padding: none;
`;

const TwoThirdGrid = {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
}

export const TwoThirdGridContainer = styled.div`
    width: 100%;
    ${TwoThirdGrid}
`;

export const TwoThirdHeadGridContainer = styled.div`
    margin-top: 20px;
    border-bottom: 4px solid #F8F3EB;
    padding: 10px;

    ${TwoThirdGrid}

    h2 {
        ${listHeader}
    }
`

export const TwoThirdListItem = styled.div`
    width: 100%;
    ${TwoThirdGrid}
    ${listItem}
    align-items: center;
    gap: 20px;
    margin: 10px 0;

    span {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    div:nth-of-type(1) {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    div:nth-of-type(2) {
        p {
            margin: 0;
            display: flex;
            align-items: center;

            img {
                width: 22px;
                margin-right: 5px;
            }
        }

        input {
            height: 20px;
        }
    }
`

export const EmptyListItem = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.background.lightGray};

    p {
        margin: 0;
    }
`;