import { useSelector } from "react-redux"

const useInitilizeOptions = () => {
    const templateList = useSelector(state => state.template.templateList);
    const lobList = useSelector(state => state.lob.lineOfBusinessList);


    // * Init Owner List
    const initOwnerNameList = (listData) => {
        let ownerList = [];

        // * Map through each template and push the owner into ownList
        listData.map(item => {
            ownerList.push(item?.ownerName)
        })

        // * Set removes all duplicate entries
        ownerList = [...new Set(ownerList)]
        return ownerList.sort()
    }

    // * Init Labels
    const initTemplateLabelList = () => {
        let labelList = [];
        templateList.map(template => {
            labelList.push(...template?.labels)
        })

        labelList = [...new Set(labelList)]

        return labelList.sort() || []
    }

    // * Init Goals Options
    const initGoalList = (goalOptions) => { //TODO combine with initPriorityList
        const goalList = goalOptions?.map(goal => {
            return goal?.label
        })
        return goalList?.sort();
    }

    // * Init Priority Options
    const initPriorityList = (priorityOptions) => { //TODO combine with initGoalList
        const priorityList = priorityOptions?.map(priority => {
            return priority?.label;
        })
        return priorityList?.sort();
    }

    // * Init Lob Name list
    const initLobNameList = () => { //TODO refactor this function as well
        let lobNameList = [];

        lobList.map(lob => {
            lobNameList.push(lob?.name);
        })

        return lobNameList.sort() || []
    }

    const approvalStatus = ['Draft', 'Pending', 'Approved', 'Disapproved']

    return {
        initTemplateLabelList,
        initOwnerNameList,
        initGoalList,
        initPriorityList,
        initLobNameList,
        approvalStatus
    }
}

export default useInitilizeOptions;