import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles';
import { BiTrash } from 'react-icons/bi';
import { FiPlusCircle } from 'react-icons/fi';
import DeleteTargetingModal from '../modal/DeleteTargetingModal';
import { toast } from 'react-toastify';
import { removeAdAccount } from '../../../../actions/lobActions';
import FbIcon from '../../../../assets/images/socialMediaIcons/FbIcon.png';


const FacebookNetworkTable = ({ isEdit, tableData, updateTableData, handleOnCreate, fbConfigId }) => {
  const _D = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState();
  const [loader, setLoader] = useState(false)
  const facebookIcon = <img src={FbIcon} alt='img' />;

  // * Handle Item Selection - depending on the action type this will either edit/delete the item
  const handleItemSelection = (data) => {
    setSelectedItem(data)
    setIsDeleteModalOpen(true)
  }

  const renderData = () => {
    return tableData?.map(data => {
      return (
        <li key={data?.accountId}>
          <ListObj>
            <span className='header'>{data?.displayName}</span>
            <span>Currency: {data?.currency}</span>
            <span>Cost Per Result Goal: {data?.targetCpa}</span>
            <span>Bid Control: {data?.cpcBidLimit}</span>
            {data?.adPixel?.name && <span>Pixel: {data?.adPixel?.name}</span>}
            {data?.catalog?.name && <span>Catalog: {data?.catalog?.name}</span>}
          </ListObj>
          <div className='opt__button__container'>
            {isEdit && <BiTrash onClick={() => handleItemSelection(data)} />}
          </div>
        </li>
      )
    })
  }

  // * Handle Delete Ad Account
  const handleDeleteAdAccount = () => {
    const updatedAdAccounts = tableData.filter(data => data?.accountId !== selectedItem?.accountId)
    setLoader(true)
    _D(removeAdAccount({
      socialConfig: `fbconfig=${fbConfigId}`,
      obj: [{ accountId: selectedItem?.accountId, network: 'facebook' }],
      callback: () => {
        toast.success('Removing Ad Account');
        updateTableData(updatedAdAccounts)
        setIsDeleteModalOpen(false)
        setLoader(false)
      }
    }))
  }

  return (
    <TableContainer>
      <TableHeader>
        <h3> {facebookIcon} Facebook Ad Account</h3>
        {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
      </TableHeader>

      {
        tableData?.length > 0 ?
          renderData() :
          <EmptyDataContainer>
            <p> No Facebook Ad Accounts Available </p>
          </EmptyDataContainer>
      }

      {
        isDeleteModalOpen &&
        <DeleteTargetingModal
          handleDelete={handleDeleteAdAccount}
          onClose={() => setIsDeleteModalOpen(false)}
          title='Facebook Ad Accounts'
          item={selectedItem}
          useLoader
          loading={loader}
        />
      }
    </TableContainer>
  )
}

export default FacebookNetworkTable