import React, { useState, useEffect } from 'react';

import Modal from '../../../../components/Modal/Modal';
import Select from 'react-select';

// Styles
import { TableModalContainer } from '../../../../ui/table/styles';
import { ModalText } from '../../../../components/Modal/styles';
import { selectThemeModal } from '../../../../ui/themes';
import { BtnContainer } from '../../../../components/Modal/styles';
import { OutlineBtn, BtnWithLoader } from '../../../../ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { createCampaign, setSelectedCampaign } from '../../../../actions/tempActions';
import { Input } from '../../../../ui/input/input';
import { toast } from 'react-toastify';
import { TEMP_NAV } from '../../../../constants/temp';
import { deleteUnnecessaryFields, getCampaignCopyText } from '../../templates.util';


// TEMP_NAV.CAMPAIGN_DETAILS
const CreateExistingTemplateModal = ({onClose, startFromScratch, setCurrSelector}) => {
    const template = useSelector(state => state.template.currentNetworkTemplate);
    const campaigns = template?.campaigns
    const campaignCopyText = getCampaignCopyText(template?.network)

    const _D = useDispatch()
    const [currCampaign, setCurrCampaign] = useState(null);
    const [optionsList, setOptionsList] = useState([]);
    const [campaignName, setCampaignName] = useState("")
    const [loader, setLoader] = useState(false)

    const loaderColor = "white"
    useEffect(() => {
        const list = campaigns?.map(campaign => {
            return (
                {
                    label: campaign.name,
                    value: campaign
                }
            )
        })
        setOptionsList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleOnCancel = () => {
        setCurrSelector(TEMP_NAV.CONFIG_DETAILS)
        onClose()
    }

    const handleSubmit = () => {
        if(!campaignName)  return toast.error("Enter a campaign name.")
        if(!currCampaign && !startFromScratch) return toast.error("Select campaign to copy over to new campaign.")

        let updateObj;
        if(!startFromScratch) {
            updateObj = deleteUnnecessaryFields({...currCampaign, name: campaignName, network: template.network })
        }  else updateObj = {network: template.network, name: campaignName}

        setLoader(true);
        _D(createCampaign({websafe: template.websafe, updateObj, callback: res => {
            _D(setSelectedCampaign(res))
            setCurrCampaign(res)
            toast.success('New campaign created');
            setCurrSelector(TEMP_NAV.CAMPAIGN_DETAILS)
            setLoader(false)
            onClose();
        },
        errorCallback: () => toast.error('Something went wrong')
    }))
    }

    return (
        <Modal
            header={`Add ${campaignCopyText}`}
            onClose={handleOnCancel}
            network = {template.network}
            // withIcon
        >
            <TableModalContainer style={{minHeight: !startFromScratch ? '260px' : "100px"}}>
            <ModalText>{campaignCopyText} Name{<span className='asterisk'>*</span>}</ModalText>
                <Input
                    value={campaignName}
                    placeholder={`Enter ${campaignCopyText}`}
                    onChange={e => {setCampaignName(e.target.value)}}
                />
                {
                    !startFromScratch &&  <ModalText> Select campaign to copy over to new campaign <span className='asterisk'>*</span> </ModalText>
                }
                {
                    !startFromScratch && (
                        <Select
                        maxMenuHeight={100}
                        isClearable
                        options={optionsList}
                        styles={selectThemeModal}
                        onChange={e => setCurrCampaign(e.value)}
                    />
                    )
                }
                <BtnContainer>
                    <OutlineBtn onClick={handleOnCancel}> Cancel </OutlineBtn>
                    <BtnWithLoader btnText={`Create New ${campaignCopyText}`} loader={loader} onClick={handleSubmit} type="blackBtn" color={loaderColor}/>
                </BtnContainer>
            </TableModalContainer>
        </Modal>
    )
}

export default CreateExistingTemplateModal;