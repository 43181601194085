import React, { useState, useEffect, useContext, useMemo } from 'react';
import Select from 'react-select';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const Conversion = () => {
    const { isEdit, campaignObj, handleUpdate, accountId, socialLoginId } = useContext(LinkedInCampaignContext);
    const { conversions } = campaignObj;

    const [conversionsOptions, setConversionsOptions] = useState([]);

    useEffect(() => {
        if (!accountId || !socialLoginId) return
        window.eulerity.makeApiCall(`/api/linkedin/listConversions?accountId=${accountId}&socialLoginId=${socialLoginId}`, 'GET', null, response => {
            const conversionsOptions = response?.map((option) => {
                return {label: option?.name, value: option};
            })
            setConversionsOptions(conversionsOptions);
        })
    }, [accountId])

    const selectedConversions = useMemo(() => {
        const values = conversions?.map((conversion) => {
            return { label: conversion?.name, value: conversion }
        })

        return values
    }, [conversions])

    return (
        <FieldContainer>
            <h3> Conversion </h3>
            {
                isEdit ?
                <Select
                    isClearable
                    isMulti
                    options={conversionsOptions}
                    defaultValue={selectedConversions}
                    styles={selectTheme}
                    onChange={e => handleUpdate({key: 'conversions', value: e.map(o => o.value)})}
                /> :
                <p> {conversions?.map(conversion => conversion?.name)?.join(', ') || 'N/A'} </p>
            }
        </FieldContainer>
    )
}

export default Conversion;