import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import CheckBox from '../../../../ui/input/checkbox/CheckBox'

const SearchNetwork = ({ googleIcon, isEdit, searchNetwork, setSearchNetwork }) => {
    return (
        <FieldContainer>
            <h3> {googleIcon} Search Network </h3>
            <CheckBox
                checked={searchNetwork}
                callback={() => setSearchNetwork(!searchNetwork)}
                isDisabled={!isEdit}
                label='Include Search Network'
            />
        </FieldContainer>
    )
}

export default SearchNetwork