import React, { useState } from 'react'
import { updateNetworkConfig } from '../../../../actions/lobActions';
import { toast } from 'react-toastify';
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles';
import { BiPencil, BiTrash } from 'react-icons/bi';
import DeleteTargetingModal from '../modal/DeleteTargetingModal';
import { FiPlusCircle } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { FcGoogle } from 'react-icons/fc';

const GoogleAdGroupTable = ({ tableData, isEdit, handleOnCreate, handleOnEdit, goConfigId }) => {
  const _D = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState();
  const [loader, setLoader] = useState(false)

  // * Handle Item Selection - depending on the action type this will either edit/delete the item
  const handleItemSelection = (item) => {
    setSelectedItem(item)
    setIsDeleteModalOpen(true)
  }

  const deleteAudienceTargets = () => {
    const updatedAdGroups = tableData.filter(item => item !== tableData[selectedItem])

    setLoader(true)
    _D(updateNetworkConfig({
      obj: { audienceTargets: updatedAdGroups },
      socialConfig: `goconfig=${goConfigId}`,
      callback: () => {
        setLoader(false)
        setIsDeleteModalOpen(false)
        toast.success('Ad Group Deleted');
      }
    }))
  }

  const renderData = () => {
    const list = []

    for (const item in tableData) {
      list.push(
        <li key={item}>
          <ListObj>
            <span>{tableData[item]?.name}</span>
            <span>{tableData[item]?.description}</span>
          </ListObj>
          <div className='opt__button__container'>
            {isEdit && <BiPencil onClick={() => handleOnEdit(tableData[item])} />}
            {isEdit && <BiTrash onClick={() => handleItemSelection(item)} />}
          </div>
        </li>
      )
    }
    return list
  }

  return (
    <TableContainer>
      <TableHeader>
        <h3> <FcGoogle /> Google Search Ad Groups</h3>
        {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
      </TableHeader>

      {
        Object.keys(tableData || {})?.length > 0 ?
          renderData() :
          <EmptyDataContainer>
            <p> No Search Ad Groups Available </p>
          </EmptyDataContainer>
      }

      {
        isDeleteModalOpen &&
        <DeleteTargetingModal
          handleDelete={deleteAudienceTargets}
          onClose={() => setIsDeleteModalOpen(false)}
          title='Google Search Ad Groups'
          item={tableData[selectedItem]}
          useLoader
          loading={loader}
        />
      }
    </TableContainer>
  )
}

export default GoogleAdGroupTable