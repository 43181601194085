import React, { useState, useEffect } from "react";
import SystemConfigMain from "./components/SystemConfigMain";

// Redux
import { useDispatch } from "react-redux";
import { setSocialLogins } from "../../actions/systemConfigActions";

// Utils
import { displayNonAdminUsers, makeGETPromiseApiCall } from "./utils";

// Styles
import { BsFillGearFill } from "react-icons/bs";
import { MainHeader } from "../../ui/headers";
import { SystemConfigContainer } from "./styles";

const SystemConfig = () => {
  const [loading, setLoading] = useState(true);
  const [iconRotation, setIconRotation] = useState(0)
  const [userManagmentData, setUserManagmentData] = useState(null);

  const _D = useDispatch()

  useEffect(() => {
    const getAllUsers =     makeGETPromiseApiCall("/api/user/getAll");
    const getSocialLogins = makeGETPromiseApiCall("/api/user/getSocialLogins")

    Promise.all([getAllUsers, getSocialLogins]).then(([userData, socialLoginsData]) => {
      setUserManagmentData(displayNonAdminUsers(userData));
      _D(setSocialLogins(socialLoginsData))
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SystemConfigContainer iconRotation={iconRotation}>
      <MainHeader>
        <div className="settingIcon">
          <BsFillGearFill />
        </div>
        <h2> System Config</h2>
      </MainHeader>
      <SystemConfigMain  loading={loading}
                         userManagmentData={userManagmentData}
                         setLoading={setLoading}
                         setIconRotation={setIconRotation}
                         />
    </SystemConfigContainer>
  );
};

export default SystemConfig;
