import React, { useContext } from 'react';
import Select from 'react-select';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { selectTheme } from '../../../../../ui/themes';
import { FieldContainer } from '../../../../../ui/containers';

const BiddingStrategy = ({}) => {
    const bidStrategy = useSelector(state => state.media.currentCampaign?.networkTemplateCampaign?.bidStrategy);

    return (
        <FieldContainer>
            <h3> Bidding Strategy <span className='asterisk'>*</span> </h3>
            <Select
                value={{label: bidStrategy, value: bidStrategy}}
                styles={selectTheme}
                isDisabled={true}
            />
        </FieldContainer>
    )
}

export default BiddingStrategy;