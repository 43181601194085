import React, { useEffect, useState } from "react";
import Select from 'react-select';

// Redux
import { getPixelList, getCatalogsList } from "../../../../../actions/lobActions";

// Styles
import FbIcon from "../../../../../assets/images/socialMediaIcons/FbIcon.png";
import { StyleFacebookBiddingFocus } from "../styles";
import { Input } from "../../../../../ui/input/input";
import { selectTheme } from "../../../../../ui/themes";


const AddFacebookBiddingFocus = ({ selectedFbAccount, setCostPerResultGoal, setBidControl, costPerResultGoal, bidControl, socialLoginId, setAdPixel, setCatalog }) => {
  const [pixelList, setPixelList] = useState([]);
  const [catalogsList, setCatalogsList] = useState([]);
  const accountId = selectedFbAccount?.[0]?.id;
  const fbAccountName = selectedFbAccount?.[0]?.name
  const currency = selectedFbAccount?.[0]?.currency;

  useEffect(() => {
    getPixelList({
      socialLoginId,
      accountId,
      callback: (res) => {
        const options = res.map(pixel => {
          return ({
            label: pixel.name,
            value: pixel
          })
        })
        setPixelList(options);
      }
    })

    getCatalogsList({
      socialLoginId,
      accountId,
      callback: (res) => {
        const options = res.map(catalog => {
          return ({
            label: catalog.name,
            value: catalog
          })
        })
        setCatalogsList(options);
      }
    })
  }, [])

  return (
    <StyleFacebookBiddingFocus>
      <div className='adAccount'>
        <h2>Ad Account: {fbAccountName}</h2>
        <p>ID: {accountId}</p>
      </div>
      <div className="bidding-foucs">
        <div className="google-account-fields">
          <div>
            <p className='text'> These bidding focus specific values will be used as a reference when the relevant bidding focus is used on a Facebook campaign. </p>
            <div className="google-account-fields--icons">
              <img src={FbIcon} alt="icon" />
              Cost Per Result Goal ({currency})
            </div>
            <Input value={costPerResultGoal} onChange={(e)=>setCostPerResultGoal(e.target.value)} />
          </div>
          <div>
            <div className="google-account-fields--icons">
              <img src={FbIcon} alt="icon" />
              Bid Control ({currency})
            </div>
            <Input value={bidControl} onChange={(e) => setBidControl(e.target.value)} />
          </div>

          <div className='pixel_container'>
           <p> Select a pixel and catalog for this ad account </p>
           <div className="google-account-fields--icons">
              <img src={FbIcon} alt="icon" />
              Pixel
            </div>
            <Select
             isClearable
             options={pixelList}
             styles={selectTheme}
             onChange={e => setAdPixel(e.value)}
            />

            <div className="google-account-fields--icons">
              <img src={FbIcon} alt="icon" />
              Catalog
            </div>
            <Select
              isClearable
              options={catalogsList}
              styles={selectTheme}
              onChange={e => setCatalog(e.value)}
            />
          </div>
        </div>
      </div>
    </StyleFacebookBiddingFocus>
  );
};

export default AddFacebookBiddingFocus;
