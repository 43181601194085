import React, { useState } from 'react'
import DeleteTargetingModal from '../modal/DeleteTargetingModal'
import { EmptyDataContainer, ListObj, TableContainer, TableHeader } from './styles'
import { FiPlusCircle } from 'react-icons/fi'
import { BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { updateLob } from '../../../../actions/lobActions'

const LobObjTable = ({ title, isEdit, handleOnCreate, tableData, successText, compareKey, fieldName }) => {
    const _D = useDispatch()
    const currentLob = useSelector(s => s.lob.currentLineOfBusiness)

    const [loader, setLoader] = useState(false)
    const [selectedItem, setSelectedItem] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    // * Function will select item and will handle a given action (delete/edit)
    const handleSelectItem = (val) => {
        setSelectedItem(val)
        setIsDeleteModalOpen(true)
    }

    const handleDeleteItem = () => {
        const updatedData = tableData.filter(data => data[compareKey] !== selectedItem[compareKey])
        setLoader(true)
        _D(updateLob({
            websafe: currentLob.websafe,
            updateObj: { [fieldName] : updatedData},
            callback: () => {
                setLoader(false)
                setIsDeleteModalOpen(false)
                toast.success(successText)
            },
        }))
    }

    const renderData = () => {
        const list = [];
        for (const item in tableData) {
            list.push(
                <li key={item}>
                    <ListObj>
                        <span> {tableData[item]?.name || tableData[item]?.label} </span>
                        <span> {tableData[item]?.targetCpaMicros || tableData[item]?.description || tableData[item]?.value} </span>
                    </ListObj>
                    <div className='opt__button__container'>
                        {isEdit && <BiTrash onClick={() => handleSelectItem(tableData[item])} />}
                    </div>
                </li>
            )
        }
        return list;
    }


    return (
        <TableContainer>
            <TableHeader>
                <h3> {title} </h3>
                {isEdit && <FiPlusCircle onClick={handleOnCreate} />}
            </TableHeader>
            {
                Object.keys(tableData || {})?.length > 0 ?
                    renderData() :
                    <EmptyDataContainer>
                        <p> No {title} Available </p>
                    </EmptyDataContainer>
            }
            {
                isDeleteModalOpen &&
                <DeleteTargetingModal
                    handleDelete={handleDeleteItem}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title={title}
                    item={selectedItem}
                    useLoader
                    loading={loader}
                />
            }
        </TableContainer>
    )
}

export default LobObjTable