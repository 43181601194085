import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Select from 'react-select';

// Redux
import { useSelector } from 'react-redux';
import { createDummyConfig } from '../../../../actions/lobActions';

// Styles
import { ModalText, ModalTextSecondary } from '../../../../components/Modal/styles';
import { OutlineBtn, BtnWithLoader, CenterButtonsContainer } from '../../../../ui/button';
import { selectTheme } from '../../../../ui/themes';
import { toast } from 'react-toastify';

const AddNetworkModal = ({onClose, dummyNetworks, updateRenderList, renderList}) => {
    const [selectedNetwork, setSelectedNetwork] = useState([]);
    const [loader, setLoader] = useState(false);
    const lobWebsafe = useSelector(state => state?.lob?.currentLineOfBusiness?.websafe);

    const handleAddNetwork = () => {
        setLoader(true);
        createDummyConfig({
            websafe: lobWebsafe,
            network: selectedNetwork.map(network => network.label),
            callback: (res) => {
                toast.success(`Successfully Created Network(s)`);
                updateRenderList(res.configs);
                setLoader(false);
                onClose();
            }
        })
    }

    const dummyNetworksList = dummyNetworks.filter(dummy => {
        return !renderList.some(selected => {
            return selected.network === dummy.name;
        })
    }).map(dummy => {
        return {
            label: dummy.name,
            value: dummy.name
        }
    })

    return (
        <Modal
            header='Add Non-Api Network'
            onClose={onClose}
            style={{width: '600px'}}
       >
            <>
                <ModalText> Select non-API network(s) to configure </ModalText>
                <ModalTextSecondary> Non-API Network <span className='asterisk'>*</span> </ModalTextSecondary>

                <Select
                    isClearable
                    isMulti
                    options={dummyNetworksList}
                    styles={selectTheme}
                    menuPortalTarget={document.body}
                    onChange={e => setSelectedNetwork(e)}
                />
                <CenterButtonsContainer top='20px'>
                    <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>
                    <BtnWithLoader onClick={handleAddNetwork} btnText='Add Network' type='blackBtn' loader={loader} />
                </CenterButtonsContainer>
            </>
        </Modal>
    )
}

export default AddNetworkModal;
