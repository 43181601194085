import styled from "styled-components";


export const StyleCampaignManagement = styled.div`

`

export const StyledNamingConventionModal = styled.div`
    width: 1000px;
    height: 600px;

    .table-wrapper {
        margin: 10px auto;
        height: 550px;
        overflow: scroll;

        ::-webkit-scrollbar {
            display: none;
        }
    }

    table {
        border-collapse: collapse;
        font-size: 16px;
        width: 100%;

        thead th {
            text-align: left;
            padding: 13.5px 0px;
            border-bottom: 1px solid #D9D9D8;
            position: sticky;
            top: 0;
            background: white;
        }

        td {
            color: #717171;
        }

        .data-row:nth-child(odd) {
            background-color: #fafafa;
        }

        .display-name-header {
            width: 250px;
        }

        .shortcut-header {
            width: 200px;
        }

        .description-header {
            width: 413px;
        }

        .name-data {
            padding: 32.5px 0px 32.5px 18.35px;
        }

        .shortcut-data {
            padding: 32.5px 0px;
        }

        .desc-data {
            padding: 16.5px 8px 16.5px 0px;
        }
    }
`

