import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import PercentInput from '../../../../ui/input/fields/PercentInput'
import Information from '../../../../ui/tooltip/Information'

const MobileBidding = ({ googleIcon, isEdit, setMobileBid, mobileBid }) => {
    return (
        <FieldContainer>
            <h3>
                {googleIcon} Mobile Bid Adjustment
                <Information text='This campaign setting field is used as a reference and should be set on the network manager.' />
            </h3>
            {
                isEdit ?
                    <PercentInput value={mobileBid} min='-100' max='900' onChange={e => setMobileBid(e.target.value)} />
                    : <p>{mobileBid} %</p>
            }
        </FieldContainer>
    )
}

export default MobileBidding