import React, { useState, useEffect } from "react";
import DeleteModal from "../../../components/Modal/DeleteModal";
// import Select from "react-select";

// Utils
import { shouldUpdateString } from "../../../utils/payload";
import { Input } from "../../../ui/input/input";
import { collectProperties, updateDummyNetworkProps } from "../templates.util";

// Redux
import { useSelector } from "react-redux";

// Styles
import { FieldContainer, OneThirdGridColContainer, StyledFieldsWrapper } from "../../../ui/containers";
import { SubHeader } from "../../../ui/headers";
import { EditSaveBtn, HeaderIconBtnContainer, DeleteTextBtn } from "../../../ui/button";
import useFadeAnimation from "../../../hooks/useFadeAnimation";

import useDummyNetworkProps from "../../../hooks/useDummyNetworkProps";
import HowToSetPropertyModal from "../../systemConfig/components/networkManagement/modals/HowToSetPropertyModal";
import { sortObjectsByName } from "../../../utils/sort";

const DummyCampaignDetails = ({handleUpdate, handleDeleteCampaign }) => {

  const campaign = useSelector(state => state.template.selectedCampaign)

  const [name, setName] = useState("");
  const [objective, setObjective] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);


  const [dummyNetworkProperties, setDummyNetworkProperties] = useState({});
  const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
  const [howToData, setHowToData] = useState(null);


  const [isEdit, setIsEdit] = useState(false);


  const DynamicSelectorRenderer = useDummyNetworkProps(setDummyNetworkProperties);

  useEffect(() => {
    clearCampaignFields();
    setName(campaign?.name);
    setObjective(campaign?.objective);
    //Set initial Dummy network Properties
    setDummyNetworkProperties(collectProperties(campaign));
  }, [campaign]);

  const ref = useFadeAnimation([isEdit, campaign]);

  const handleEdit = () => {
    if (isEdit) {
      let shouldUpdate = false;

      if (shouldUpdateString(name, campaign.name)) {
        campaign.name = name;
        shouldUpdate = true;
      }

      if (shouldUpdateString(objective, campaign.objective)) {
        campaign.objective = objective;
        shouldUpdate = true;
      }
      const { didDummyPropsChanged } = updateDummyNetworkProps(dummyNetworkProperties, campaign);

      if (shouldUpdate || didDummyPropsChanged) {
        handleUpdate(campaign);
      }
    }
    setIsEdit(prev => !prev);
  };

  const handleModalDeleteCampaign = () => {
    setLoading(true);
    handleDeleteCampaign(campaign, () => {
      setIsDeleteCampaignModalOpen(false);
      clearCampaignFields();
      setLoading(false);
    });
  };

  const clearCampaignFields = () => {
    setObjective("");
  };

  const handleHowItWorksModal = (modalData) => {
    setHowToData(modalData)
    setIsHowToModalOpen(true);
  };

  return (
    <StyledFieldsWrapper ref={ref}>
      <SubHeader>
        Campaign Details for {campaign?.name}
        <HeaderIconBtnContainer>
          <DeleteTextBtn onClick={() => setIsDeleteCampaignModalOpen(true)} btnText={"Delete Campaign"} />
          {EditSaveBtn(isEdit, handleEdit)}
        </HeaderIconBtnContainer>
      </SubHeader>

      <OneThirdGridColContainer>
        <FieldContainer>
          <h3> Campaign Name </h3>
          {isEdit ? <Input value={name || campaign?.name} onChange={(e) => setName(e.target.value)} /> : <p> {name} </p>}
        </FieldContainer>

        {sortObjectsByName(campaign?.properties).map((prop) => {
          return (
            <FieldContainer key={prop.id}>
              <DynamicSelectorRenderer
                    isEdit={isEdit}
                    handleHowItWorksModal={handleHowItWorksModal}
                    state={dummyNetworkProperties?.[prop.id]?.values}
                    {...prop}
                    />
            </FieldContainer>
          );
        })}
      </OneThirdGridColContainer>
      {isHowToModalOpen && (
        <HowToSetPropertyModal
          modalData={howToData}
          onClose={setIsHowToModalOpen.bind(null, false)}
        />
      )}
      {isDeleteCampaignModalOpen && (
        <DeleteModal
          header="Delete Campaign"
          modalText={`Deleting ${campaign?.name} campaign`}
          deleteBtnText="Delete Campaign"
          setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
          handleDelete={handleModalDeleteCampaign}
          loading={loading}
        />
      )}
    </StyledFieldsWrapper>
  );
};

export default DummyCampaignDetails;
