import React, { useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setOrganization } from "../../../../actions/orgAction";
import { setSocialLogins } from "../../../../actions/systemConfigActions";

import { LOB_NAV } from "../../../../constants/lob";
import { BlackBtn, BtnWithLoader, OutlineBtn } from "../../../../ui/button";

import { capitalize } from "../../../../utils/strings";
import { makeGETPromiseApiCall, makePOSTPromiseApiCall, networkSocialLogins, InitalSelection, oAuthGetSocialLoginMethod } from "../../utils";
import { StyleSysAccountsLogins, StyleSysAccountsModal, StyleSystemAccountsSelector } from "../systemAccounts/styles";

import Loader from "../../../../ui/Loader";
import SystemAccountsSelector from "../systemAccounts/components/SystemAccountsSelector";
import useOauth from "../../../../hooks/useOauth";
import Modal from "../../../../components/Modal/modals/Modal";

const SystemAccountModal = ({ onClose, networkSocialLogin, selectedNetwork, currentSysAccount }) => {
  const googleSocialLoginId = useRef()
  const socialLogins = useSelector(state => state.system.socialLogins)

  const [selectedAccount, setSelectedAccount] = useState(InitalSelection(currentSysAccount, networkSocialLogin));
  const [isProcessing, setProcessing] = useState(false);
  const [accountMaper, setAccountMapper] = useState(networkSocialLogin);
  const [loader, setLoader] = useState(false);
  const [oAuthLoader, setOauthLoader] = useState(false)

  const _D = useDispatch();
  const currentNetwork = selectedNetwork?.toLowerCase();
  const isGoogle = currentNetwork === LOB_NAV.NETWORK_CONFIG.GOOGLE;
  const network = networkSocialLogin?.[0]?.network || currentNetwork;

  const { onFocusBack, handleOAuth } = useOauth(network);


  //oAuthGetSocialLoginMethod.bind(null, '/api/user/getSocialLogins', socialLogins)
  const handleOnSelect = (account) => setSelectedAccount(account);

  const handleBackButton = () => {
    setAccountMapper(networkSocialLogin);
    setSelectedAccount(InitalSelection(currentSysAccount, networkSocialLogin));
  };


  useEffect(() => {
     if(!selectedAccount?.isAdAccount && socialLogins) setAccountMapper(networkSocialLogins(socialLogins, network))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLogins])

  useEffect(() => {
    if (onFocusBack) {
      setOauthLoader(true)
      oAuthGetSocialLoginMethod('/api/user/getSocialLogins').then(response => {
          if(socialLogins.length !== response.length)   _D(setSocialLogins(response));
      }).catch(_ => {
          toast.error("Something went wrong")
      }).finally(_ => {
        setOauthLoader(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFocusBack]);


  const handleAddSystemAccountForGoogle = async () => {
    if (!selectedAccount) return;
    setLoader(true)
    const socialLoginId = googleSocialLoginId.current
    try {
      await makePOSTPromiseApiCall(`/api/org/setSystemCredentials?socialLoginId=${socialLoginId}&accountId=${selectedAccount?.accountId}`);
      const newOrgData = await makeGETPromiseApiCall("/api/org/get");
      _D(setOrganization(newOrgData));
      toast.success("System Account has been updated!");
      onClose()
    } catch (error) {
      toast.error("Something whent wrong!");
    } finally {
      setLoader(false)
    }
  }
  const handleAddSystemAccount = async () => {
    if (!selectedAccount) return;

    if (isGoogle) {
      let list = [];
      setLoader(true);
       googleSocialLoginId.current = selectedAccount?.id
        window.eulerity.makeBatchedApiCall(
          {
            url: `/api/user/listConnectedAccounts?socialLoginId=${selectedAccount?.id}`,
            errorCallbacks: {
              1000: (_) => {
                toast.error("Your Google Account does not have any Ad Accounts associated. Please sign in with a different Google Account.")
                setLoader(false);
              }
            },

            dataCallback: function (resp) {
              resp.forEach((c) => list.push({ ...c, isAdAccount: true }));
            },
            doneCallback: async function () {
              if(!list.length) {
                setLoader(false);
                toast.error("Your Google Account does not have any Ad Accounts associated. Please sign in with a different Google Account.")
                return
              }
              setAccountMapper(list);
              setSelectedAccount(list?.[0]);
              setLoader(false);
            },
          },
        );
      return;
    }
    setLoader(true);
    try {
      await makePOSTPromiseApiCall(`/api/org/setSystemCredentials?socialLoginId=${selectedAccount.id}`);
      const newOrgData = await makeGETPromiseApiCall("/api/org/get");
      _D(setOrganization(newOrgData));
      onClose()
      toast.success("System Account has been updated!");
    } catch (error) {
      toast.error("Something whent wrong!");
    } finally {
      setLoader(false);
    }
  };

  const modalButtons = useMemo(() => {
    const btnArray = []
    if (!isGoogle) btnArray.push(<OutlineBtn onClick={onClose}>Cancel</OutlineBtn>)
    if (isGoogle && !selectedAccount?.isAdAccount) btnArray.push(<OutlineBtn onClick={onClose}>Cancel</OutlineBtn>)
    if (isGoogle && selectedAccount?.isAdAccount) btnArray.push(<OutlineBtn onClick={handleBackButton}>Back</OutlineBtn>)
    if (!isGoogle) btnArray.push(<BtnWithLoader onClick={handleAddSystemAccount} loader={loader} btnText='Add System Account' type='blackBtn' />)
    if (isGoogle && !selectedAccount?.isAdAccount) btnArray.push(<BtnWithLoader onClick={handleAddSystemAccount} loader={loader} btnText='Next' type='blackBtn' />)
    if (isGoogle && selectedAccount?.isAdAccount) btnArray.push(<BtnWithLoader onClick={handleAddSystemAccountForGoogle} loader={loader} btnText='Add System Account' type='blackBtn' />)

    return btnArray || []
  }, [selectedAccount, isGoogle, loader])

  return (
    <Modal
      header={`Select a ${selectedAccount?.isAdAccount ? "Ad" : "System"} Account`}
      useIcon
      network={network}
      onClose={!loader ? onClose : function () {}}
      buttons={modalButtons}
    >
      <StyleSysAccountsModal>
        {selectedAccount?.isAdAccount ? <div className="modal-descriptor">Select a ad Account</div> : <div className="modal-descriptor">Select a {capitalize(network)} System Account</div>}

        {!selectedAccount?.isAdAccount && (
          <div className="modal-add-new-account">
            <BlackBtn style={{ width: "max-content" }} onClick={!isProcessing && handleOAuth}>
              Add New Account
            </BlackBtn>
          </div>
        )}
        <div className="modal-displayNames">{selectedAccount?.isAdAccount ? "Ad Accounts" : "Display Name"}</div>

        <StyleSysAccountsLogins>
          {accountMaper.map((eachAccount) => {
            return <SystemAccountsSelector key={eachAccount?.isAdAccount ? eachAccount?.accountId : eachAccount?.id} accountData={eachAccount} handleOnSelect={handleOnSelect} selectedAccount={selectedAccount} onClose={onClose} setProcessing={setProcessing}/>;
          })}
          {oAuthLoader && (
            <StyleSystemAccountsSelector isSelected={true} style={{ gridTemplateColumns: "1fr" }}>
              <Loader />
            </StyleSystemAccountsSelector>
          )}
        </StyleSysAccountsLogins>
      </StyleSysAccountsModal>
    </Modal>
  );
};

export default SystemAccountModal;
