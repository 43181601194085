import React, { useContext } from 'react';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Redux
import { generateName } from '../../../../../actions/mediaActions';

// Styles
import { BiRefresh } from 'react-icons/bi';
import { Input } from '../../../../../ui/input/input';
import { FieldContainer } from '../../../../../ui/containers';

const CampaignName = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { name } = campaignObj;

    return (
        <FieldContainer>
            <h3> Campaign Name <span className='asterisk'>*</span>
                {/* {isEdit &&
                <BiRefresh
                    color='#F8B133'
                    style={{cursor: 'pointer'}}
                    onClick={() => generateName({mediaPlanAdGroup: 'currAdGroupId', callback: res => setName(res.name)})}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Generate ad set name or manually edit the ad set name"
                />} */}
            </h3>

            {isEdit ?
            <Input value={name} onChange={(e) => handleUpdate({key: 'name', value: e.target.value})} placeholder={name || "Enter name"} /> :
            <p> {name || 'N/A'} </p>}
        </FieldContainer>
    )
}

export default CampaignName;