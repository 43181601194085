import React, { useState, useEffect } from 'react';
import SubNavigation from '../../../../components/SubNavigation/SubNavigation';
import LinkedInCampaignSetup from './campaignSetup/LinkedInCampaignSetup';
import LinkedInCampaignGroupSetup from './campaignGroupSetup/LinkedInCampaignGroupSetup';
import NetworkTemplateCard from '../NetworkTemplateCard';
import DeleteModal from '../../../../components/Modal/DeleteModal';
import Loader from '../../../../ui/Loader';
import { toast } from 'react-toastify';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getNetworkTemplateList } from '../../../../actions/tempActions';
import { deleteCampaign, selectAdGroup } from '../../../../actions/mediaActions';

// Styles
import { SubHeader } from '../../../../ui/headers';
import { BiTrash } from 'react-icons/bi';
import { HeaderIconBtnContainer, StyleCenterElement, RedOutlineIconBtn } from '../../../../ui/button';

const LinkedInCampaignDetails = ({currCampaign, resetPage}) => {
    const [loader, setLoader] = useState(false);
    const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [currNav, setCurrNav] = useState('Campaign Group Setup');
    const [template, setTemplate] = useState({});
    const [templateCampaign, setTemplateCampaign] = useState({});

    const campaign = useSelector(state => state.media.currentCampaign);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoader(true);

        dispatch(getNetworkTemplateList((res) => {
            const networkTemplateWebsafe = currCampaign.networkTemplate?.key?.websafe;
            const networkTemplateCampaignWebsafe = currCampaign.networkTemplateCampaign;

            const network = res.find(net => net.websafe === networkTemplateWebsafe);
            const networkCampaign = network?.campaigns?.find(net => net.websafe === networkTemplateCampaignWebsafe);

            setTemplate(network);
            setTemplateCampaign(networkCampaign);
            setLoader(false);
        }));
    }, [])

    useEffect(() => {
        setCurrNav('Campaign Group Setup');
        dispatch(selectAdGroup({}));
    }, [campaign.websafe, campaign?.adGroups?.length])

    const handleModalDeleteCampaign = () => {
        setDeleteLoader(true);
        dispatch(deleteCampaign({
            mediaPlanCampaign: campaign.websafe,
            callback: () => {
                setIsDeleteCampaignModalOpen(false);
                toast.success(`Deleted campaign`);
                setDeleteLoader(false);
                resetPage('media');
            }
        }))
    }

    const navigationObj = [
        {
            name: 'Campaign Group Setup',
            component: <LinkedInCampaignGroupSetup />
        }
    ]

    if (campaign?.adGroups?.length > 0) navigationObj.push({ name: 'Campaign Setup', component: <LinkedInCampaignSetup currNav={currNav} /> });

    if (loader) return <StyleCenterElement><Loader size={50} /></StyleCenterElement>

    return (
        <>
            <NetworkTemplateCard template={template} campaign={templateCampaign} />
            <SubHeader>
                <HeaderIconBtnContainer> LinkedIn Campaign </HeaderIconBtnContainer>
                <RedOutlineIconBtn onClick={() => setIsDeleteCampaignModalOpen(true)}> <BiTrash /> Delete Campaign </RedOutlineIconBtn>
            </SubHeader>

            <SubNavigation navigationObj={navigationObj} currNav={currNav} setCurrNav={setCurrNav}/>
            {
                isDeleteCampaignModalOpen &&
                <DeleteModal
                    header='Delete Campaign'
                    modalText={`Deleting ${campaign?.name} campaign`}
                    deleteBtnText='Delete Campaign'
                    setIsDeleteModalOpen={() => setIsDeleteCampaignModalOpen(false)}
                    handleDelete={handleModalDeleteCampaign}
                    loading={deleteLoader}
                />
            }
        </>
    )
}

export default LinkedInCampaignDetails;
