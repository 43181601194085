import React from 'react'
import { howToController } from '../pages/templates/templates.util';
import InputField from '../ui/customSelectors/InputFeild';
import Multiselect from '../ui/customSelectors/Multiselect';
import SingleSelect from '../ui/customSelectors/SingleSelect';
import { generateDropdownOptions } from '../utils/arrays';


const useDummyNetworkProps = (localState) => {

    function handleOnStateChange (data, id, freeForm)  {
        localState(prew => {
            if(!prew[id]) prew[id] = {};
            prew[id] =  {values: freeForm ? data.target.value : data}
            return prew
        })
    }
    const DynamicSelectorRenderer = (props) => {
        let howToObject = howToController(
                props?.bulkUploadInstructions,
                props?.networkManagerInstructions,
                props?.name,
            )
        switch(props.type) {
            case "MULTI_SELECT": {
                const options = generateDropdownOptions(props.values)
                return  <Multiselect
                    onChange={(e) => handleOnStateChange(e, props.id)}
                    defaultValue={props.state}
                    options={options}
                    howToObject={howToObject}
                    {...props}

                />
            }
            case "SINGLE_SELECT": {
                const options = generateDropdownOptions(props.values)
                return <SingleSelect
                    onChange={(e) => handleOnStateChange(e, props.id)}
                    defaultValue={props.state}
                    options={options}
                    howToObject={howToObject}
                    {...props}
                    />
            }
            case "FREE_FORM": {
                return <InputField
                    onChange={(e) => handleOnStateChange(e, props.id, true)}
                    defaultValue={props.state}
                    howToObject={howToObject}
                    {...props}
                    />
            }
            default: return ""
        }
    }

    return DynamicSelectorRenderer
}


export default useDummyNetworkProps;