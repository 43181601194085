import React, { useState, useEffect } from 'react';

// Components
import EditTemplates from './components/EditTemplates';
import Overview from './components/Overview';
import Loader from '../../ui/Loader';

import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { getNetworkTemplateList, createNetworkTemplate } from '../../actions/tempActions';
import { selectTemplate } from '../../actions/tempActions';

// Styles
import { MainHeader } from '../../ui/headers';
import { HiTemplate } from 'react-icons/hi';
import { TemplatesContainer } from './styles';

const Templates = () => {
    const { register, getValues, setValue, handleSubmit } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(true)
    const [submitLoader, setSubmitLoader] = useState(false)
    const templateList = useSelector(state => state.template.templateList);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoader(true)
        dispatch(getNetworkTemplateList(async () => {
            setLoader(false)
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isDuplicateName = (name) => {
      return  templateList.find(template => template.name.toLowerCase() === name.toLowerCase());
    }


    const onSubmit = () => {
        const { name, network, advertiser } = getValues();

        if (isDuplicateName(name)) {
            setSubmitLoader(false)
            return;
        }

        setSubmitLoader(true)
        dispatch(createNetworkTemplate({
            obj: {
                name,
                network
            },
            advertiser,
            callback: (obj) => {
                handleContinueCreate(obj)
                toast.success(`Created ${name} network template`)
                setSubmitLoader(false)
            }
        }));

        setValue('name', '');
        setValue('advertiser', '')
    }

    const handleContinueCreate = obj => {
        dispatch(selectTemplate({websafe: obj?.websafe}))
    }

    if(loader || submitLoader ) {
        return (
            <TemplatesContainer>
                <MainHeader>
                <HiTemplate />
                <h2> Templates</h2>
                 </MainHeader>
                 <Loader size="55" fullViewHeight/>
            </TemplatesContainer>
        )
    }

    return (
        <TemplatesContainer>
            <MainHeader>
                <HiTemplate />
                <h2> Templates</h2>
            </MainHeader>
            {
                isEdit ?
                    <EditTemplates setIsEdit={setIsEdit} /> :
                    <Overview
                        register={register} handleSubmit={handleSubmit(onSubmit)}
                        setValue={setValue} setIsEdit={setIsEdit} getValues={getValues}
                        setLoader={setLoader}
                    />
            }
        </TemplatesContainer>
    )
}

export default Templates;