import React from 'react'
import { ModalOverlay } from '../styles'
import { BasicModalBody, BasicModalContent, BasicModalFooter, BasicModalHeader, ModalHeaderText, BasicModalContainer, ModalSeparator } from './styles'
import { FacebookI, GoogleI } from '../../../ui/icons'
import { CgClose } from 'react-icons/cg'
import { ButtonWrapper } from './styles'

// * This component is meant to replace our other modal, it is more generic and allows for us to pass in buttons into a footer.
// * It is also intended as a SINGLE popup modal, not a multistep one

const Modal = ({ height, width, useIcon, header, network, onClose, buttons, children }) => {

    const NETWORKS = {
        FACEBOOK: 'facebook',
        GOOGLE: 'google'
    }

    const renderIcon = () => {
        if (network?.toLowerCase() === NETWORKS.FACEBOOK) return <FacebookI size='30px' />
        if (network?.toLowerCase() === NETWORKS.GOOGLE) return <GoogleI size='30px' />
    }

    return (
        <ModalOverlay>
            <BasicModalContainer height={height} width={width}>
                <BasicModalContent>
                    <BasicModalHeader>
                        <div className='header__left'>
                            {useIcon &&
                                <div className='header__image__wrapper'>
                                    {renderIcon()}
                                </div>
                            }
                            <div className='header__title__wrapper'>
                                <ModalHeaderText>{header}</ModalHeaderText>
                            </div>
                        </div>
                        <div className='header__right' onClick={onClose}>
                            <CgClose size='30px' />
                        </div>
                    </BasicModalHeader>
                    <ModalSeparator />
                    <BasicModalBody>
                        {children}
                    </BasicModalBody>
                    <BasicModalFooter>
                        <ButtonWrapper>
                            {
                                buttons?.length && buttons.map((button, index) => <div key={index}>{button}</div>)
                            }
                        </ButtonWrapper>
                    </BasicModalFooter>
                </BasicModalContent>
            </BasicModalContainer>
        </ModalOverlay>
    )
}

export default Modal