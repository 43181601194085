import React, { useContext, useMemo } from 'react'
import Select from 'react-select'
import { StyledDescStep, StyledInputWrapper } from './styles'
import { Input } from '../../../../../../../ui/input/input'
import CheckBox from '../../../../../../../ui/input/checkbox/CheckBox'
import { useSelector } from 'react-redux'
import { LinkedInAudienceContext } from './LinkedAudienceModal'
import { selectTheme } from '../../../../../../../ui/themes'

const LinkedInDescStep = () => {
    const { setAudienceName, audienceName,
        setAdAccount, adAccount, adAccounts,
        setTargetType, targetType,
        setAudienceDesc, audienceDesc,
        setEnableExpansion, enableExpansion
    } = useContext(LinkedInAudienceContext)

    const lob = useSelector(s => s.lob.currentLineOfBusiness)
    const targetTypes = useSelector(s => s.org.organization.audienceTypes)

    // * Dropdown Options
    const targetTypeOptions = useMemo(() => {
        const options = targetTypes?.map(type => {
            return { label: type, value: type }
        })
        return options
    }, [targetTypes])

    const audienceDescriptionOptions = useMemo(() => {
        const options = lob?.audienceDescriptions?.map(desc => {
            return { label: desc, value: desc }
        })
        return options
    }, [lob?.audienceDescriptions])

    const adAccountOptions = useMemo(() => {
        const options = adAccounts.map(account => {
            return { label: account.displayName, value: account?.accountId }
        })

        return options
    }, [adAccounts])

    return (
        <StyledDescStep>
            <StyledInputWrapper>
                <p>Target Audience Name <span className='asterisk'>*</span></p>
                <Input
                    placeholder='Enter Target Audience Name'
                    onChange={(e) => setAudienceName(e.target.value)}
                    value={audienceName}
                />
            </StyledInputWrapper>
            <StyledInputWrapper>
                <p>Ad Account <span className='asterisk'>*</span></p>
                <Select
                    isClearable
                    styles={selectTheme}
                    placeholder={'Select an Ad Account'}
                    onChange={(e) => setAdAccount(e)}
                    options={adAccountOptions}
                    value={adAccount}
                />
            </StyledInputWrapper>
            <StyledInputWrapper>
                <p>Target Type</p>
                <Select
                    isClearable
                    styles={selectTheme}
                    placeholder={'Select a Target Type'}
                    onChange={(e) => setTargetType(e)}
                    options={targetTypeOptions}
                    value={targetType}
                />
            </StyledInputWrapper>
            <StyledInputWrapper>
                <p>Audience Description <span className='asterisk'>*</span></p>
                <Select
                    isClearable
                    styles={selectTheme}
                    placeholder={'Select a Audience Description'}
                    onChange={(e) => setAudienceDesc(e)}
                    options={audienceDescriptionOptions}
                    value={audienceDesc}
                />
            </StyledInputWrapper>
            <StyledInputWrapper>
                <p>Audience Expansion</p>
                <CheckBox
                    checked={enableExpansion}
                    callback={() => setEnableExpansion(!enableExpansion)}
                    label='Enable Audience Expansion'
                />
            </StyledInputWrapper>
        </StyledDescStep>
    )
}

export default LinkedInDescStep