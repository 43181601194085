import React, { useState, useEffect, createContext } from "react";
import DeleteModal from "../../../components/Modal/DeleteModal";
import Checkbox from "../../../ui/input/checkbox/CheckBox";
import NumberInput from "../../../ui/input/fields/NumberInput";
import Loader from '../../../ui/Loader';
import LanguageTargeting from './facebookAdSetSetupFields/LanguageTargeting';
import LocationTargeting from './facebookAdSetSetupFields/LocationTargeting';
import SalesObjectiveRendering from "./SalesObjectiveRendering";

// Redux
import { objectiveMaps } from "../../../actions/socialActions";
import { useDispatch } from "react-redux";

// Library
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import DatePicker from "react-datepicker";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

// Util
import { isObjArraysEqual } from "../../../utils/arrays";
import { capitalize } from "../../../utils/strings";
import { shouldUpdateString } from "../../../utils/payload";

// Redux
import { getAdGroup, deleteAdGroup, generateName } from "../../../actions/mediaActions";

// Styles
import { AdGroupSetupContainer, AdGroupListContainer, AdGroupListItem, StyleNumberPicker } from "../styles";
import { PageHeaderContainer, ColumnHeader, NoTargets } from "../../../ui/headers";
import { Input, DatePickerWrapper, CheckboxContainer } from "../../../ui/input/input";
import { OneThirdGridColContainer, FieldContainer, InputFieldWrapper } from "../../../ui/containers";
import { RedOutlineIconBtn, EditSaveBtn, CenterBtnContainer, StyleCenterElement } from "../../../ui/button";
import { BiTrash } from "react-icons/bi";
import FacebookIcon from "../../../assets/images/socialMediaIcons/FbIcon.png";
import { toast } from "react-toastify";
import { dateCheck, dynamicPropName, multiValueCheck, stringToDateconverter } from "./utils";
import { selectTheme } from "../../../ui/themes";
import { makeGETPromiseApiCall, makePOSTPromiseApiCall } from "../../systemConfig/utils";
import { AdGroupAudienceTargetHeader, AdGroupAudienceTargetContainer, AdGroupAudienceTargetWrapper, AdGroupAudienceTargetItem, AudienceTargetOr, AudienceTargetAnd, OriginallyDerivedContainer } from "../styles";
import { BiRefresh } from "react-icons/bi";

import CustomDatePicker from "../../../ui/input/fields/CustomDatePicker";
import { decideBidControl } from "../../templates/templates.util";
import { useTheme } from "styled-components";
import CurrencyInput from '../../../ui/input/fields/CurrencyInput';
import FrequencyField from "../../../ui/input/fields/FrequencyField";

const FacebookAdSetSetup = ({ campaign, mediaPlan, adGroupLoader, setAdGroupLoader, lobName, template, countryOptions, languageOptions, selectedAdvantageCampaignBudget}) => {
  const [currAdGroupId, setCurrAdGroupId] = useState();
  const [currAdGroup, setCurrAdGroup] = useState();
  const [adGroups, setAdGroups] = useState([]);

  const [name, setName] = useState("");
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [budget, setBudget] = useState();
  const [budgetDuration, setBudgetDuration] = useState(null)
  const [isDynamicCreative, setIsDynamicCreative] = useState(null)
  const [limitSpend, setlimitSpend] = useState(null)
  const [languageCodes, setLanguages] = useState([]);
  const [countryCodes, setCountries] = useState([]);

  const [minAge, setMinAge] = useState(20);
  const [maxAge, setMaxAge] = useState(40);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [invFilAudience, setInvFilAudience] = useState([])
  const [invFilInstream, setInvFilInstream] = useState([])


  const [inventoryFiltersOptions, setInventoryFiltersOptions] = useState([])
  const [facebookPages, setFbPages]  = useState([])
  const [selectedFBPage, setFbSelectedPage] = useState(null)
  const [genderTargets, setGendersTargets] = useState([])
  const [dailyMinimum, setDailyMinimum] = useState(null)
  const [dailyMaximum, setDailyMaximum] = useState(null)
  const [locationTargets, setLocationTargets] = useState([])
  const [languageTargets, setLanguageTargets] = useState([])
  const [bidControllHeader, setBidControllHeader] = useState(null)
  const [bidValue, setBidValue] = useState(null)

  const [conversionEventList, setConversionEventList] = useState([]);
  // const [conversionEventLocationList, setConversionEventLocationList] = useState([]);
  const [conversionEvent, setConversionEvent] = useState('');
  // const [conversionEventLocation, setConversionEventLocation] = useState('');

  const dispatch = useDispatch();

  const isSalesObjective = campaign?.objective === 'Sales';

  const templateName = template?.name

  const theme = useTheme()

  const budgetDurationOptions = [
    { label: "Lifetime", value: "LIFETIME" },
    { label: "Daily", value: "DAILY" },
  ];

  const genderOptions = [
    {label: "Male", value: 'm'},
    {label: "Female", value: 'f'},
    {label: "Undetermined", value: 'u'}
  ]

  const mapTarget = (l) => {
    if(l === 'u') return {label: 'Undetermined', value: l}
    if(l === 'f') return {label: 'Female', value: l}
    if(l === 'm') return {label: 'Male', value: l}
  }

  const FbIcon = <img src={FacebookIcon} alt="img" />;

  const createReactSelectObjects = (data) => {
    if(!data) return []
    if(!Array.isArray(data)) return {label: capitalize(data), value: data}
    return data?.map(it => ({label: capitalize(it), value: it}))
}
    const createPlacements = (placement) => {
        if(!placement) return
        let platforms = [];
        let placements = [];
        for(let key in placement) {
            platforms.push(key)
            placements.push(placement[key].reduce((arr, el) => {
                arr.push(el)
                return arr
            },[]))
        }
        return {
            platforms,
            placements: placements.flat()
        }
    }

  useEffect(() => {
    if (!campaign?.adGroups?.length) {
      setAdGroups([]);
      return;
    }
    setCurrAdGroupId(campaign?.adGroups[0].websafe);
    setAdGroups(campaign?.adGroups);
  }, [campaign]);


  useEffect(() => {
    if (!currAdGroupId) return;
    getAdGroupData(currAdGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currAdGroupId]);

  const { includeAudiences, excludeAudiences, includedInterestTargets, excludedInterestTargets } = currAdGroup?.audienceTarget || {};

  useEffect(() => {
    const getConfig = mediaPlan?.lob?.networkConfigs.find(n => n.keyParam === 'fbconfig')
    if(!getConfig) return;
     makeGETPromiseApiCall(`/api/lob/getNetworkConfig?fbconfig=${getConfig.websafe}`).then(response => {
        let fbPagesArray = response.fbSocialAccounts
        setFbPages(fbPagesArray)
     })
      makeGETPromiseApiCall("/api/facebook/inventoryFilters").then(response => {
        setInventoryFiltersOptions(createReactSelectObjects(response))
     })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currAdGroupId])

  useEffect(() => {
    const networkTemplateCampaign = campaign?.networkTemplateCampaign;
    const objective = networkTemplateCampaign?.objective;
    const optimization = networkTemplateCampaign?.optimization;

    if (!objective || !optimization) return;

    objectiveMaps({
      callback: (res) => {
        const conversionEventListOptions = res[objective]?.optimizations[optimization]?.conversionEvents;
        setConversionEventList(conversionEventListOptions);
      }
    })
  }, [])

  const isHighestVolume = campaign?.bidStrategy === "Highest Volume"
  const isBidCap = campaign?.bidStrategy === 'Bid Cap'
  const isCostPerGoal = campaign?.bidStrategy === 'Cost per Goal'

  const getAdGroupData = (id) => {
    handleClear()
    setAdGroupLoader(true)
    dispatch(getAdGroup({
      mediaPlanAdGroup: id,
      callback: async (adSet) => {
        const {bidControllHeader, value} = decideBidControl(campaign?.bidStrategy, adSet)

        setBidControllHeader(bidControllHeader)
        setBidValue(value)
        if(adSet?.minAge) setMinAge(adSet?.minAge)
        if(adSet?.maxAge) setMaxAge(adSet?.maxAge);
        if (adSet?.languageTargets?.length > 0) setLanguageTargets(adSet?.languageTargets);
        if (adSet?.locationTargets?.length > 0) setLocationTargets(adSet?.locationTargets);
        if(adSet?.genderTargets) setGendersTargets(adSet?.genderTargets.map(it => mapTarget(it)))
        if(adSet?.facebookInventoryFilter) setInvFilInstream(createReactSelectObjects(adSet.facebookInventoryFilter))
        if(adSet?.audienceNetworkInventoryFilter) setInvFilAudience(createReactSelectObjects(adSet.audienceNetworkInventoryFilter))
        if(adSet?.name) setName(adSet?.name);
        if(adSet?.budget) setBudget(adSet?.budget)
        adSet?.start ? setStart(adSet?.start) : setStart(campaign?.start)
        adSet?.end ? setEnd(adSet?.end) : setEnd(campaign?.end)
        if(adSet?.budgetDuration) setBudgetDuration({label: capitalize(adSet?.budgetDuration), value: adSet?.budgetDuration})
        if(adSet?.minAge) setMinAge(adSet?.minAge)
        if(adSet?.maxAge) setMaxAge(adSet?.maxAge);
        if(adSet?.genderTargets) setGendersTargets(adSet?.genderTargets.map(it => mapTarget(it)))
        if(adSet?.isDynamicCreative !== undefined) setIsDynamicCreative(adSet?.isDynamicCreative)
        if(adSet?.facebookInventoryFilter) setInvFilInstream(createReactSelectObjects(adSet?.facebookInventoryFilter))
        if(adSet?.audienceNetworkInventoryFilter) setInvFilAudience(createReactSelectObjects(adSet?.audienceNetworkInventoryFilter))
        if(adSet?.limitSpend !== undefined) setlimitSpend(adSet?.limitSpend)
        if(adSet?.minSpend) setDailyMinimum(adSet?.minSpend)
        if(adSet?.maxSpend) setDailyMaximum(adSet?.maxSpend)
        if(adSet?.countryCodes) setCountries(adSet?.countryCodes)
        if(adSet?.languageCodes) setLanguages(adSet?.languageCodes)
        if (adSet?.conversionEvent) setConversionEvent(adSet?.conversionEvent);
        if (adSet?.fbSocialAccount) setFbSelectedPage(adSet?.fbSocialAccount);

        setCurrAdGroup(adSet)
        setAdGroupLoader(false)
      }
    }));
  };

  const adSetList = () => {
    return (
      adGroups?.map((adGroup, idx) => (
        <AdGroupListItem key={adGroup.websafe + idx} isSelected={adGroup?.websafe === currAdGroupId} onClick={() => setCurrAdGroupId(adGroup.websafe)}>
          Ad Set {idx + 1}
        </AdGroupListItem>
      )) || []
    );
  };

  const handleEdit = async () => {
    const errorMessages = []
    const updateObj = {};
    if (isEdit) {

      if (shouldUpdateString(budgetDuration.value, currAdGroup?.budgetDuration)) updateObj.budgetDuration = budgetDuration.value

     const {startDate, endDate} = stringToDateconverter(start, end)

      //* REQUIRED FIELDS
      if(!startDate)  errorMessages.push({message: "Please select Start Date"})
      if(!endDate)  errorMessages.push({message: "Please select End Date"})
      if(!selectedAdvantageCampaignBudget && !budget) errorMessages.push({message: "Enter Budget to Launch Campaign"})
      if(!selectedFBPage)  errorMessages.push({message: "Facebook Page Required"})
      if(budget < 0) errorMessages.push({message: "Budget cant be lower than Zero"})
      if(!dateCheck(startDate, endDate))  errorMessages.push({message: 'End Date should be greater than Start Date'})
      if(isNaN(parseFloat(bidValue)))  errorMessages.push({message: "Only Numeric Values Allowed"})
      if(bidValue <= 0 && (isBidCap || isCostPerGoal)) errorMessages.push({message: `${bidControllHeader} must be greater than 0`})

      if(errorMessages.length > 0) return toast.error(errorMessages[0]?.message)

      if (name && name !== currAdGroup?.name) updateObj.name = name;
      const {startDate: adGroupStartDate, endDate: adGroupEndDate} = stringToDateconverter(currAdGroup?.start, currAdGroup?.end)
      if (startDate && startDate !== adGroupStartDate) updateObj.start = startDate;
      if (endDate && endDate !== adGroupEndDate) updateObj.end = endDate;
      if (budget && parseInt(budget) !== currAdGroup?.budget) updateObj.budget = budget;
      if (minAge !== currAdGroup?.minAge) updateObj.minAge = minAge;
      if (maxAge !== currAdGroup?.maxAge) updateObj.maxAge = maxAge;
      if (isDynamicCreative !== currAdGroup?.isDynamicCreative) updateObj.isDynamicCreative = isDynamicCreative;
      const response = multiValueCheck(genderTargets, currAdGroup?.genderTargets);
      if(response) updateObj.genderTargets  = response;
      if(shouldUpdateString(invFilAudience.value, currAdGroup?.audienceNetworkInventoryFilter)) updateObj.audienceNetworkInventoryFilter = invFilAudience.value;
      if(shouldUpdateString(invFilInstream.value, currAdGroup?.facebookInventoryFilter)) updateObj.facebookInventoryFilter = invFilInstream.value;
      if(currAdGroup?.fbSocialAccount?.accountId !==  selectedFBPage?.value?.accountId)  updateObj.fbSocialAccount = selectedFBPage.value;
      if(currAdGroup?.limitSpend !== limitSpend) updateObj.limitSpend = limitSpend
      if(dailyMinimum && (currAdGroup?.minSpend !== dailyMinimum)) updateObj.minSpend = dailyMinimum
      if(dailyMaximum && (currAdGroup?.maxSpend !== dailyMaximum)) updateObj.maxSpend = dailyMaximum
      if(countryCodes && !isObjArraysEqual(countryCodes, currAdGroup?.countryCodes)) updateObj.countryCodes = countryCodes;
      if(languageCodes && !isObjArraysEqual(languageCodes, currAdGroup?.languageCodes)) updateObj.languageCodes = languageCodes;


      if(!isHighestVolume) {
        const propName = dynamicPropName(campaign?.bidStrategy)
        updateObj[propName] = bidValue
      }

    if (languageTargets !== currAdGroup?.languageTargets) updateObj.languageTargets = languageTargets;
    if (locationTargets !== currAdGroup?.locationTargets) updateObj.locationTargets = locationTargets;
    if (conversionEvent !== currAdGroup?.conversionEvent) updateObj.conversionEvent = conversionEvent;

    if(errorMessages.length > 0) {
        return toast.error(errorMessages[0]?.message)
    }

    if(Object.keys(updateObj).length) {
        try {
            setAdGroupLoader(true)
            await makePOSTPromiseApiCall(`/api/mediaplan/updateAdGroup?mediaPlanAdGroup=${currAdGroup.websafe}`, updateObj)
            getAdGroupData(currAdGroupId)
        } catch (error) {
            toast.error("Something went wrong!")
        } finally {
            setAdGroupLoader(false)
            toast.success('Successfully updated')
        }
      }
    }
    setIsEdit(!isEdit);
  };

  const handleDelete = () => {
    setAdGroupLoader(true)
    dispatch(
    deleteAdGroup({
      mediaPlanAdGroup: currAdGroup?.websafe,
      callback: (res) => {
        setAdGroupLoader(false)
        toast.success("Deleted Ad Group");
        setIsDeleteModalOpen(false);
        const filteredAdGroup = adGroups.filter((adGroup) => adGroup.websafe !== currAdGroup?.websafe);
        setAdGroups(filteredAdGroup);
        setCurrAdGroupId(filteredAdGroup[0]?.websafe);
      },
    }));
  };

  const handleClear = () => {
    setBudget(null)
    setBudgetDuration(null)
    setLocationTargets([])
    setLanguageTargets([])
  }

  // * Naming Convention
  const getDefaultValues = (selectedOptions) => {
    return selectedOptions?.map(val => {
      return (
          {
              label: val,
              value: val
          }
      )
    })
  }
  const handleCountryChange = (selectedOptions) => {
    const selectedCountries = selectedOptions?.map(opt => opt.value) || [];
    setCountries(selectedCountries)
  }

  const handleLanguageChange = (selectedOptions) => {
    const selectedLanguages = selectedOptions?.map(opt => opt.value) || [];
    setLanguages(selectedLanguages)
  }

  // Audiences
  const audienceList = (type, obj, isModal) => {
    if (!obj || Object.keys(obj)?.length < 1) return;

    const items = obj.map(item => {
      return <AdGroupAudienceTargetItem key={item.websafe + item.name} type={type}>{item.name}</AdGroupAudienceTargetItem>;
    })

    return <AdGroupAudienceTargetContainer isModal={isModal}> {items} </AdGroupAudienceTargetContainer>
  }

  const isAudiencesEmpty = Object.keys(includeAudiences || {})?.length === 0 && Object.keys(excludeAudiences || {})?.length === 0;

  const positiveNegativeAudiences = (isModal) => {
    return isAudiencesEmpty ?
    <AdGroupAudienceTargetWrapper> <NoTargets> No Keywords / Negative Keywords </NoTargets> </AdGroupAudienceTargetWrapper> :
    <AdGroupAudienceTargetWrapper isModal={isModal}> {audienceList('positive', includeAudiences, isModal)} {audienceList('negative', excludeAudiences, isModal)} </AdGroupAudienceTargetWrapper>
  }

  const peopleWhoMatchAndAlsoMatch = () => {
    if (!includedInterestTargets?.length) return <AdGroupAudienceTargetWrapper> <NoTargets> No matches </NoTargets> </AdGroupAudienceTargetWrapper>
    const result = includedInterestTargets?.map((segment, idx) => {
        const items = segment?.map((item, i) =>
            <React.Fragment key={item?.id}>
                <AdGroupAudienceTargetItem> {item?.name} </AdGroupAudienceTargetItem>
                {i !== segment?.length - 1 && <AudienceTargetOr> OR </AudienceTargetOr>}
            </React.Fragment>)
        return (
            <React.Fragment key={idx}>
                <AdGroupAudienceTargetContainer >
                    {items}
                </AdGroupAudienceTargetContainer>
                {idx !== includedInterestTargets?.length - 1 && <AudienceTargetAnd> AND </AudienceTargetAnd>}
            </React.Fragment>
        )
    })

    return <AdGroupAudienceTargetWrapper isModal={false}> {result} </AdGroupAudienceTargetWrapper>
}

const excludePeopleWhoMatch = () => {
    if (!excludedInterestTargets?.length) return <AdGroupAudienceTargetWrapper> <NoTargets> No exclusions </NoTargets> </AdGroupAudienceTargetWrapper>

    const result = excludedInterestTargets?.map(item => {
        return (
            <AdGroupAudienceTargetItem key={item?.id} type='negative'>
                {item?.name}
            </AdGroupAudienceTargetItem>
        )
    })
    return <AdGroupAudienceTargetContainer> { result } </AdGroupAudienceTargetContainer>
}

  if (!adGroups?.length) {
    return (
      <AdGroupSetupContainer>
        <AdGroupListContainer> {adSetList()} </AdGroupListContainer>
        <NoTargets> No Ad Groups </NoTargets>
      </AdGroupSetupContainer>
    );
  }

  if(adGroupLoader) return <AdGroupSetupContainer> <StyleCenterElement><Loader size={50} /></StyleCenterElement> </AdGroupSetupContainer>
  const shouldShowFrequencyCap = campaign?.objective === "Awareness" && currAdGroup?.optimization === "Reach"


  return (
    <AdGroupSetupContainer>
      <AdGroupListContainer> {adSetList()} </AdGroupListContainer>

      <PageHeaderContainer>
        <ColumnHeader> Ad Set Details </ColumnHeader>
        <CenterBtnContainer>
          <RedOutlineIconBtn onClick={() => setIsDeleteModalOpen(true)}>
            <BiTrash /> Delete Ad Set
          </RedOutlineIconBtn>
          {EditSaveBtn(isEdit, handleEdit)}
        </CenterBtnContainer>
      </PageHeaderContainer>

      <ColumnHeader> Flight Details </ColumnHeader>
      <OneThirdGridColContainer>
        <FieldContainer>
          <h3>
           Ad Set Name&nbsp; {isEdit &&
            <BiRefresh
              color='#F8B133'
              style={{cursor: 'pointer'}}
              onClick={() => generateName({mediaPlanAdGroup: currAdGroupId, callback: res => setName(res.name)})}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Generate ad set name or manually edit the ad set name"
            />
          }
          </h3>
          {isEdit && <Tooltip id='my-tooltip' className='tooltip' classNameArrow='arrow' border={true} effect='solid' />}
          {isEdit ?
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={name || "Enter name"} /> :
          <p> {name || "N/A"} </p>}
        </FieldContainer>

        <FieldContainer>
          <h3>  Start Date {<span className='asterisk'>*</span>} </h3>
          {isEdit ? (
            <>
            <DatePickerWrapper>
              <DatePicker
                customInput={<CustomDatePicker />}
                wrapperClassName="datePicker"
                onChange={(date) => setStart(date.getTime())}
                selected={start && new Date(start)}
                placeholderText="Please select start date"
                minDate={new Date()}

              />
            </DatePickerWrapper>
            {!start && <p className="error">Please select Start Date</p>}
            </>
          ) : (
            <>
                <p> {start ? new Date(start).toDateString() : "N/A"} </p>
               {!start && <p className="error">Please select Start Date</p>}
            </>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3>  End Date {<span className='asterisk'>*</span>} </h3>
          {isEdit ? (
            <>
            <DatePickerWrapper>
              <DatePicker
                customInput={<CustomDatePicker />}
                wrapperClassName="datePicker"
                onChange={(date) => setEnd(date.getTime())}
                selected={end && new Date(end)}
                placeholderText="Please select end date"
                minDate={start + 60 * 60 * 24 * 1000}
              />
            </DatePickerWrapper>
              {!end && <p className="error">Please select End Date</p>}
            </>
          ) : (
            <>
              <p> {end ? new Date(end).toDateString() : "N/A"} </p>
              {!end && <p className="error">Please select End Date</p>}
            </>
          )}
        </FieldContainer>

        <FieldContainer hidden={!campaign?.advantageCampaignBudget}>
          <h3>  Ad Set Spend Limit  </h3>
          <CheckboxContainer>
            <Checkbox
              checked={limitSpend}
              callback={() => setlimitSpend(prew => !prew)}
              isDisabled={!isEdit}
              label="Ad Set Spend Limit"
            />
          </CheckboxContainer>
        </FieldContainer>


  <FieldContainer hidden={!limitSpend || !campaign?.advantageCampaignBudget}>
          <h3>  Daily Minimum </h3>
          {isEdit ? (
            <Input onChange={(e) => setDailyMinimum(e.target.value)} placeholder={dailyMinimum || "Enter Daily Minimum"} />
          ) : (
            <p> {dailyMinimum || "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer hidden={!limitSpend || !campaign?.advantageCampaignBudget}>
          <h3>  Daily Maximum  </h3>
          {isEdit ? (
            <Input onChange={(e) => setDailyMaximum(e.target.value)} placeholder={dailyMaximum || "Enter Daily Maximum"} />
          ) : (
            <p> {dailyMaximum || "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer hidden={campaign?.advantageCampaignBudget}>
          <h3>  Ad Set Budget Interval </h3>
          {isEdit ? (
            <Select
              isClearable
              options={budgetDurationOptions}
              styles={selectTheme}
              defaultValue={budgetDuration}
              onChange={(e) => setBudgetDuration(e)}
            />
          ) : (
            <p> {budgetDuration?.label ? capitalize(budgetDuration.label) : "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer hidden={campaign?.advantageCampaignBudget}>
          <h3>  Budget {<span className='asterisk'>*</span>} </h3>
          {isEdit ?
            <InputFieldWrapper>
              <CurrencyInput
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                currency={campaign?.adAccount?.currency}
              />
            </InputFieldWrapper>
          : <p> {campaign?.adAccount?.currency} {budget || "N/A"} </p>}
          {!budget && <p className="error">Enter Budget to Launch Campaign</p>}
        </FieldContainer>
      </OneThirdGridColContainer>

      <OneThirdGridColContainer>
      <SalesObjectiveRendering
        isEdit={isEdit}
        isSalesObjective={isSalesObjective}
        networkTemplateCampaign={campaign?.networkTemplateCampaign}
        currAdGroup={currAdGroup}
        conversionEvent={conversionEvent}
        setConversionEvent={setConversionEvent}
        conversionEventList={conversionEventList}
      />
      </OneThirdGridColContainer>

      <ColumnHeader>Ad Set Name Details</ColumnHeader>
      <OneThirdGridColContainer>
      <FieldContainer>
          <h3> Country or Region Code(s)</h3>
          {
            isEdit ? (
              <CreatableSelect
                isClearable
                isMulti
                options={countryOptions}
                styles={selectTheme}
                defaultValue={getDefaultValues(countryCodes)}
                onChange={(e) => handleCountryChange(e)}
                placeholder='Select Country or Enter Region Code'
              />
            ) :
            <p> {countryCodes?.length ? countryCodes.join(', ') : 'N/A'} </p>
          }
        </FieldContainer>
        <FieldContainer>
          <h3> Language(s)</h3>
          {
            isEdit ? (
              <Select
                isClearable
                isMulti
                options={languageOptions}
                styles={selectTheme}
                defaultValue={getDefaultValues(languageCodes)}
                onChange={(e) => handleLanguageChange(e)}
                placeholder='Select Language(s)'
              />
            ) : <p>{languageCodes?.length ? languageCodes.join(', ') : 'N/A'}</p>
          }
        </FieldContainer>
      </OneThirdGridColContainer>

    <ColumnHeader> Originally Derived from LOB: {lobName} </ColumnHeader>
    {lobName && <OriginallyDerivedContainer>
      <h2 style={{color: '#717171', fontSize: '20px', marginBottom: '20px'}}> Facebook Ad Sets </h2>
      { isSalesObjective && campaign?.networkTemplateCampaign?.useCatalog && <>
      <FieldContainer>
        <h3> {FbIcon} Media Titles </h3>
      </FieldContainer>
      {currAdGroup?.productSet?.name && <p> {currAdGroup?.productSet?.name} </p>}
      {campaign?.catalog?.name && <p style={{margin: '10px 0 20px 0'}}> Catalog: <span style={{color: '#717171'}}>{campaign?.catalog?.name}</span> </p>}
    </>}
    <AdGroupAudienceTargetHeader> <span className='green'>Included</span> Audiences and <span className='red'>Excluded</span> Audiences </AdGroupAudienceTargetHeader>
    {positiveNegativeAudiences()}

    <AdGroupAudienceTargetHeader> People Who <span className='green'>Match</span> and  People Who <span className='red'>Must Also Match</span> </AdGroupAudienceTargetHeader>
    {peopleWhoMatchAndAlsoMatch()}

    <AdGroupAudienceTargetHeader> <span className='red'>Exclude</span> People Who Match </AdGroupAudienceTargetHeader>
    {excludePeopleWhoMatch()}
    </OriginallyDerivedContainer>}
      <OneThirdGridColContainer>
        <FieldContainer>
          <div style={{display: "flex"}}><h3>  Facebook Page </h3> <span style={{color: theme.color.yellow}}>*</span></div>
          {isEdit ? (
            <Select
              isClearable
              options={facebookPages.map(page => {
                return {
                  label: page?.displayName,
                  value: page
                }
              })}
              defaultValue={{label: selectedFBPage?.displayName, value: selectedFBPage }}
              styles={selectTheme}
              onChange={(e) => setFbSelectedPage(e)}
            />
          ) : (
            <p> {selectedFBPage?.displayName || "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3>  Facebook Ad Account </h3>
          {isEdit ? (
            <Select isDisabled={true} value={{ label: campaign?.adAccount?.accountId, value: campaign?.adAccount?.accountId }} styles={selectTheme} />
          ) : (
            <p> {campaign?.adAccount?.accountId || "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3>  Age </h3>
          <StyleNumberPicker>
            {isEdit ? (
              <>
                <NumberInput value={minAge} localState={setMinAge} />
                <p>to</p>
                <NumberInput value={maxAge} localState={setMaxAge} />
              </>
            ) : (
              <p>
                {minAge} to {maxAge}
              </p>
            )}
          </StyleNumberPicker>
        </FieldContainer>

        <FieldContainer>
        <h3>  Gender</h3>
        {isEdit ? (
          <CreatableSelect
            isDisabled={false}
            isClearable
            isMulti
            options={genderOptions}
            styles={selectTheme}
            onChange={(e) => setGendersTargets(e)}
            value={genderTargets}
          />
        ) : (
          <p> {genderTargets?.length ? genderTargets.map(it => capitalize(it.label))?.join(', ') : 'N/A'} </p>
        )}
      </FieldContainer>
        <LanguageTargeting
          isEdit={isEdit}
          languageTargets={languageTargets}
          setLanguageTargets={setLanguageTargets}
          socialLoginId={campaign?.adAccount?.socialLoginId}
        />

        <LocationTargeting
          isEdit={isEdit}
          locationTargets={locationTargets}
          setLocationTargets={setLocationTargets}
          socialLoginId={campaign?.adAccount?.socialLoginId}
        />

        <FieldContainer>
          <h3>  Dynamic Creative </h3>
          <CheckboxContainer>
            <Checkbox
              checked={isDynamicCreative}
              callback={() => setIsDynamicCreative(!isDynamicCreative)}
              isDisabled={!isEdit}
              label="Use Dynamic Creative"
            />
          </CheckboxContainer>
        </FieldContainer>

        <FieldContainer>
          <h3>  Inventory Filter for Audience Network</h3>
          {isEdit ? (
            <Select
              isClearable
              options={inventoryFiltersOptions}
              defaultValue={invFilAudience}
              styles={selectTheme}
              onChange={(e) => setInvFilAudience(e)}
            />
          ) : (
            <p> {invFilAudience?.label || "N/A"} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3>  Inventory Filter For Instream</h3>
          {isEdit ? (
            <Select
              isClearable
              options={inventoryFiltersOptions}
              defaultValue={invFilInstream}
              styles={selectTheme}
              onChange={(e) => setInvFilInstream(e)}
            />
          ) : (
            <p> {invFilInstream?.label || "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer hidden>
          <h3>  Content Type Exclusions </h3>
          <CheckboxContainer>
            <Checkbox
              checked={""}
              callback={() => ""}
              isDisabled={!isEdit}
              label="Content Type Exclusions"
            />
          </CheckboxContainer>
        </FieldContainer>
      </OneThirdGridColContainer>

      <ColumnHeader> Originally Derived from the Network Template: {templateName} </ColumnHeader>

      <OneThirdGridColContainer>
      <FieldContainer>
          <h3>  Optimization for Ad Delivery</h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              isClearable
              defaultValue={createReactSelectObjects(currAdGroup?.optimization)}
              styles={selectTheme}
              onChange={(e) => setInvFilInstream(e)}
            />
          ) : (
            <p> {currAdGroup?.optimization || "N/A"} </p>
          )}
        </FieldContainer>

        {shouldShowFrequencyCap &&
          <FrequencyField
            text={{header: "Frequency Cap"}}
            freqImpressionsValue={currAdGroup.frequencyCapNum}
            freqDaysLocalValue={currAdGroup.frequencyCapIntervalDays}
          />
        }

        <FieldContainer>
          <h3>  {bidControllHeader} <span className="asterisk">*</span> </h3>
          {isEdit ? (
            <Input
              disabled={isHighestVolume}
              onChange={e => setBidValue(e.target.value)}
              defaultValue={isHighestVolume ? "N/A" : bidValue}
            />
          ) : (
            <p> {isHighestVolume || !bidValue ? "N/A" : bidValue} </p>
          )}
        </FieldContainer>


        <FieldContainer>
          <h3> When you get charged</h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              isClearable
              defaultValue={createReactSelectObjects(currAdGroup?.billingEvent)}
              styles={selectTheme}
              onChange={(e) => setInvFilInstream(e)}
            />
          ) : (
            <p> {currAdGroup?.billingEvent || "N/A"} </p>
          )}
        </FieldContainer>

        <FieldContainer>
          <h3>  Advantage Placements </h3>
          <CheckboxContainer>
            <Checkbox
              checked={currAdGroup?.advantagePlacements}
              isDisabled={true}
              label=" Advantage Placements"
            />
          </CheckboxContainer>
        </FieldContainer>
        <FieldContainer>
          <h3>  Platforms</h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              isClearable
              defaultValue={createReactSelectObjects(createPlacements(currAdGroup?.placements)?.platforms)}
              styles={selectTheme}
            />
          ) : (
            <p> {createPlacements(currAdGroup?.placements)?.platforms.map(it => capitalize(it))?.join(', ') || 'N/A'} </p>
          )}
        </FieldContainer>
        <FieldContainer>
          <h3>  Placements</h3>
          {isEdit ? (
            <Select
              isDisabled={true}
              isClearable
              defaultValue={createReactSelectObjects(createPlacements(currAdGroup?.placements)?.placements)}
              styles={selectTheme}
            />
          ) : (
            <p> {createPlacements(currAdGroup?.placements)?.placements.map(it => capitalize(it))?.join(', ') || 'N/A'} </p>
          )}
        </FieldContainer>
      </OneThirdGridColContainer>

      {isDeleteModalOpen && (
        <DeleteModal
          header="Delete Ad Set"
          modalText="Delete Ad Set?"
          deleteBtnText="Delete"
          setIsDeleteModalOpen={() => setIsDeleteModalOpen(false)}
          handleDelete={handleDelete}
        />
      )}
    </AdGroupSetupContainer>
  );
};

export default FacebookAdSetSetup;
