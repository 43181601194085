import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNetworkObjectives, getNetworkTemplateList } from '../../../../../actions/tempActions';
import { getDuConfig, getFbConfig, getGoConfig, getLiConfig } from '../../../../../actions/socialActions';
import { toast } from 'react-toastify';
import { getMediaPlan, saveChannelSetup, setCurrentMediaPlan } from '../../../../../actions/mediaActions';
import { NETWORKS } from '../../../../../constants/networks';

const useChannelSetUp = ({ onClose }) => {
    const _D = useDispatch()
    const org = useSelector(state => state.org.organization)
    const templateList = useSelector(state => state.template.templateList)
    const mediaPlan = useSelector(state => state.media.currentMediaPlan)

    /**************************** STATE ****************************/
    const [step, setStep] = useState(1)
    const [loader, setLoader] = useState(false) // * For loader buttons
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isNoRunnableCampaignModalOpen, setIsNoRunnableCampaignModalOpen] = useState(false);

    // * Step 1
    const [channel, setChannel] = useState('')
    const [configId, setConfigId] = useState()
    const [dummyNetworkData, setDummyNetworkData] = useState({})

    // * Step 2
    const [selectedConfig, setSelectedConfig] = useState()
    const [adAccounts, setAdAccounts] = useState([])
    const [selectedAccount, setSelectedAccount] = useState()

    // * Step 3
    const [selectedAudienceDescription, setSelectedAudienceDescription] = useState([])

    // * Step 4
    const [goal, setGoal] = useState()
    const [priority, setPriority] = useState()

    // * Step 5
    const [selectedTemplate, setSelectedTemplate] = useState({})

    // * Step 6 (ONLY FOR LINKEDIN NETWORKS)
    const [selectedAdFormats, setSelectedAdFormats] = useState([])
    const [adFormatOptions, setAdFormatOptions] = useState([])

    /**************************** VARIABLES ****************************/
    const isDummy = channel !== NETWORKS.FACEBOOK && channel !== NETWORKS.GOOGLE;

    const goalOptions = useMemo(() => {
        const opts = []
        org?.goals?.forEach(goal => opts.push({ label: goal, value: goal }))
        return opts
    }, [org])

    const priorityOptions = useMemo(() => {
        const opts = []
        org?.priorities?.forEach(priority => opts.push({ label: priority, value: priority }))
        return opts
    }, [org])


    /**************************** FUNCTIONS ****************************/
    const handleNext = () => {
        setStep(step + 1)

        // * Handling Step Resets if user mames changes in previous steps
        if (step === 1) {
            setSelectedAccount()
            setSelectedAudienceDescription([])
        }

        if (step === 2) setSelectedAudienceDescription([])
        if (channel !== NETWORKS.LINKEDIN && selectedAdFormats.length) setSelectedAdFormats([])
    }
    const handleBack = () => setStep(step - 1)

    const handleSelectChannel = (selectedChannel) => {
        setAdAccounts([])
        setChannel(selectedChannel?.value)

        const networkConfigs = mediaPlan?.lob?.networkConfigs

        switch (selectedChannel?.value) {
            case NETWORKS.LINKEDIN: {
                const liConfigId = networkConfigs?.find(config => config.keyParam === 'liconfig')?.websafe
                setConfigId(liConfigId)
                break
            }
            case NETWORKS.FACEBOOK: {
                const fbConfigId = networkConfigs?.find(config => config.keyParam === 'fbconfig')?.websafe
                setConfigId(fbConfigId)
                break
            }
            case NETWORKS.GOOGLE: {
                const goConfigId = networkConfigs?.find(config => config.keyParam === 'goconfig')?.websafe
                setConfigId(goConfigId)
                break
            }
            default: {
                console.log(`Selected network: ${selectedChannel?.value}`)
            }
        }
    }

    const handleChannelStep = async () => {
        setLoader(true)
        try {
            switch (channel) {
                case NETWORKS.FACEBOOK: {
                    const fbConfig = await getFbConfig({ fbConfigId: configId })
                    setSelectedConfig(fbConfig)
                    setAdAccounts(fbConfig?.adAccounts)
                    break
                }
                case NETWORKS.GOOGLE: {
                    const goConfig = await getGoConfig({ goConfigId: configId })
                    setSelectedConfig(goConfig)
                    setAdAccounts(goConfig?.adAccounts)
                    break
                }
                case NETWORKS.LINKEDIN: {
                    const liConfig = await getLiConfig({ liConfigId: configId })
                    setSelectedConfig(liConfig)
                    setAdAccounts(liConfig?.adAccounts)
                    break
                }
                default: {
                    // * This default case is meant for DUMMY CHANNELS - it will make an api call to all dummy networkConfigs, and create an obj to store all ad accounts
                    const network = mediaPlan?.lob?.networkConfigs
                    const dummyObj = {}

                    if (Object.keys(dummyNetworkData).length) {
                        setSelectedConfig(dummyNetworkData[channel])
                        setAdAccounts(dummyNetworkData[channel]?.adAccounts || [])
                        return
                    }

                    for (const item in network) {
                        if (network[item].keyParam === 'duconfig') {
                            await getDuConfig(
                                {
                                    duConfigId: network[item].websafe,
                                    callback: (res) => {
                                        dummyObj[res.network] = res;
                                    }
                                }
                            )
                        }
                    }

                    setDummyNetworkData(dummyObj) // * This will store ALL ad accounts per dummy network into an object
                    setSelectedConfig(dummyObj[channel])
                    setAdAccounts(dummyObj[channel]?.adAccounts || [])
                }
            }
        } catch (error) {
            console.error(error)
            toast.error('Something went wrong!')
        } finally {
            setLoader(false)
            handleNext()
        }
    }

    const isAccountSelected = (accountId) => {
        return selectedAccount?.accountId === accountId
    }

    const isAudSelected = description => selectedAudienceDescription.indexOf(description) > -1;

    const isAudSelectable = (description, usedAudienceDescriptions) => {
        if (isDummy) return true;
        return usedAudienceDescriptions.indexOf(description) > -1;
    };

    const handleSelectAudienceDescription = (item, usedAudienceDescriptions) => {
        if (!isDummy && usedAudienceDescriptions.indexOf(item) < 0) return;
        if (isAudSelected(item)) {
            const filtered = selectedAudienceDescription.filter(description => description !== item)
            setSelectedAudienceDescription(filtered);
        } else {
            setSelectedAudienceDescription([...selectedAudienceDescription, item]);
        }
    }

    const handleGoalPriorityStep = () => {
        // * Avoid making api call if we already have the list of network templates
        if (templateList?.length > 0) {
            handleNext()
            return
        }

        setLoader(true)
        _D(getNetworkTemplateList(() => {
            handleNext()
            setLoader(false)
        }))
    }

    const handleLinkedInTemplateStep = async () => {
        if (adFormatOptions?.length) {
            handleNext()
            return
        }

        setLoader(true)
        try {
            const response = await getNetworkObjectives(NETWORKS.LINKEDIN)
            const { adFormats } = response['Website Visits']
            setAdFormatOptions(adFormats.map((adFormat) => {
                return {
                    value: adFormat,
                    label: adFormat
                }
            }))
            handleNext()
        } catch (error) {
            console.error(error)
        } finally {
            setLoader(false)
        }
    }

    const handleAddAdFormat = (options) => {
        const selectedOptions = options?.map(opt => opt)
        setSelectedAdFormats(selectedOptions)
    }

    const handleSave = async () => {
        setLoader(true)

        try {
            await saveChannelSetup({
                obj: {
                    networkTemplate: selectedTemplate?.websafe,
                    audienceDescriptions: selectedAudienceDescription,
                    accountId: selectedAccount.accountId,
                    adFormats: selectedAdFormats?.map(format => format.value) // * Can always make this an array of strings -> instead of mapping
                },
                id: mediaPlan.websafe
            })
            getMediaPlan({
                mediaPlanWebsafe: mediaPlan?.websafe,
                callback: (mediaplan) => _D(setCurrentMediaPlan(mediaplan))
            })
            onClose();
            setShowWarningModal(false);
            toast.success('Channel(s) created');
        } catch (error) {
            console.error(error)
            setIsNoRunnableCampaignModalOpen(true);
        } finally {
            setLoader(false)
        }
    }

    return {
        lob: mediaPlan?.lob,
        step,
        handleBack,
        handleNext,
        loader,
        channel,
        handleSelectChannel,
        handleChannelStep,
        adAccounts,
        isAccountSelected,
        setSelectedAccount,
        selectedAccount,
        selectedConfig,
        selectedAudienceDescription,
        isAudSelectable,
        isAudSelected,
        handleSelectAudienceDescription,
        goalOptions,
        priorityOptions,
        goal,
        setGoal,
        priority,
        setPriority,
        handleGoalPriorityStep,
        selectedTemplate,
        setSelectedTemplate,
        templateList,
        handleSave,
        showWarningModal,
        setShowWarningModal,
        isNoRunnableCampaignModalOpen,
        setIsNoRunnableCampaignModalOpen,
        selectedAdFormats,
        setSelectedAdFormats,
        handleAddAdFormat,
        handleLinkedInTemplateStep,
        adFormatOptions
    }
}

export default useChannelSetUp