import styled from 'styled-components';

export const CatalogContainer = styled.div`
    background: ${props => props.theme.background.gray};
    border: 1px solid ${props => props.theme.color.gray};
    border-radius: 9px;
    padding: 20px;
    font-family: 'GothamLight';
    font-style: normal;
    font-size: 14px;

    h2 {
        font-size: 18px;
        font-weight: 800;
    }

    .catalog_label {
        margin-top: 10px;
        color: ${props => props.theme.card.gray};
        font-family: 'GothamMedium';
    }
`;

// * Interest Target Facebook
export const StyledInterestTargetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 32px 0px;

    .header-wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    // * Reset margin
    p {
        margin: 0;
    }
`

export const StyledInterestContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
    flex-shrink: 0;
    align-items: flex-start;
`