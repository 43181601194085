import React from "react";
import Modal from "../../../../../components/Modal/Modal";

// Styles
import { OutlineBtn, CenterButtons } from "../../../../../ui/button";

// Styles
import { HowToSetPropertyModalContainer } from "../styles";

const HowToSetPropertyModal = ({ onClose, modalData }) => {
  return (
    <Modal onClose={onClose} header="How to set Property" style={{ width: "600px" }}>
      {Array.isArray(modalData) ? (
        modalData.map((it, idx) => {
          return (
            <HowToSetPropertyModalContainer key={it.text+it.name+idx}>
              <h2> Set on {it.type} </h2>
              <h1> How to set property: {it.name} </h1>
              <p> {it.text} </p>
            </HowToSetPropertyModalContainer>
          );
        })
      ) : (
        <HowToSetPropertyModalContainer>
          <h2> Set on {modalData.type} </h2>
          <h1> How to set property: {modalData.name} </h1>
          <p> {modalData.text} </p>
        </HowToSetPropertyModalContainer>
      )}

      {CenterButtons(<OutlineBtn onClick={onClose}> Okay </OutlineBtn>)}
    </Modal>
  );
};

export default HowToSetPropertyModal;
