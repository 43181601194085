// USER
export const GET_USER = 'GET_USER';
export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';
export const CLEAR_USER = 'CLEAR_USER';

// LOB
export const CREATE_LOB = 'CREATE_LOB';
export const GET_LOB_LIST = 'GET_LOB_LIST'
export const VIEW_LOB = 'VIEW_LOB';
export const DELETE_LOB = 'DELETE_LOB';
export const UPDATE_LOB = 'UPDATE_LOB';
export const CLONE_LOB = 'CLONE_LOB';
export const FAVORITE_LOB = 'FAVORITE_LOB';
export const SELECT_LOB = 'SELECT_LOB';
export const RESET_LOB = 'RESET_LOB';
export const SELECT_NETWORK_CONFIG = 'SELECT_NETWORK_CONFIG';
export const UPDATE_NETWORK_CONFIG = 'UPDATE_NETWORK_CONFIG';


// Network Templates
export const GET_NETWORK_LIST = 'GET_NETWORK_LIST';
export const CREATE_NETWORK = 'CREATE_NETWORK';
export const CLONE_NETWORK = 'CLONE_NETWORK';
export const DELETE_NETWORK = 'DELETE_NETWORK';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const EDIT_GOOGLE_TEMPLATE_FLAG = "EDIT_GOOGLE_TEMPLATE_FLAG"
export const EDIT_FACEBOOK_TEMPLATE_FLAG = "EDIT_FACEBOOK_TEMPLATE_FLAG"
export const EDIT_DUMMY_TEMPLATE_FLAG = "EDIT_DUMMY_TEMPLATE_FLAG"
export const SELECTED_CAMPAIGN = "SELECTED_CAMPAIGN"

// Media Plans
export const CREATE_MEDIA_PLAN = 'CREATE_MEDIA_PLAN';
export const GET_MEDIA_PLAN_LIST = 'GET_MEDIA_PLAN_LIST';
export const GET_MEDIA_PLAN = 'GET_MEDIA_PLAN';
export const DELETE_MEDIA_PLAN = 'DELETE_MEDIA_PLAN';
export const SELECT_MEDIA_PLAN = 'SELECT_MEDIA_PLAN';
export const UPDATE_MEDIA_PLAN = 'UPDATE_MEDIA_PLAN';
export const UPDATE_MEDIA_CAMPAIGN = 'UPDATE_MEDIA_CAMPAIGN';
export const DELETE_MEDIA_CAMPAIGN = 'DELETE_MEDIA_CAMPAIGN';
export const UPDATE_AD_GROUP = 'UPDATE_AD_GROUP';
export const DELETE_AD_GROUP = 'DELETE_AD_GROUP';
export const UPDATE_MEDIA_CAMPAIGN_LOCATION_TARGETS = 'UPDATE_MEDIA_CAMPAIGN_LOCATION_TARGETS';
export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN';
export const SELECT_AD_GROUP = 'SELECT_AD_GROUP';

// Organization
export const GET_ORG = 'GET_ORG';
export const SET_ORG = 'SET_ORG';
export const SET_ADVERTISERS = 'SET_ADVERTISERS';
export const UPDATE_ADVERTISERS = 'UPDATE_ADVERTISERS';
export const CREATE_ADVERTISER = 'CREATE_ADVERTISER';

export const UPDATE_DUMMY_PROPERTY = 'UPDATE_DUMMY_PROPERTY';


//System Config
export const SET_SELECTED_NETWORK = 'SET_SELECTED_NETWORK';
export const SET_SOCIAL_LOGINS = 'SET_SOCIAL_LOGINS';