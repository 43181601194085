import styled from 'styled-components';

export const AppContainer = styled.div`
    display: flex;
    position: relative;

`

export const MainContainer = styled.div`
    width: 100%;
`;


