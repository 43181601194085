import React, { useEffect } from 'react';
import { DashboardContainer } from './styles';
import { HiHome } from 'react-icons/hi';
import { MainHeader } from '../../ui/headers';

import { APP_PATH } from '../../constants/path';

const Dashboard = ({useNavigate}) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`${APP_PATH}/plans`);
    }, []);

    return (
        <DashboardContainer>
        <MainHeader>
            <HiHome />
            <h2> DashBoard </h2>
        </MainHeader>
        </DashboardContainer>
    )
}

export default Dashboard;