// * Sinan worked on this if you have questions

import React, { useMemo } from 'react'
import { StyledAudienceContainer, StyledTargetList, StyledTargetWrapper } from './styles'
import { useSelector } from 'react-redux';
import { groupFacetsByCategory } from '../../../../lineofbusiness/components/networkConfigs/linkedIn/modals/linkedinAudience/linkedInTargets.utils';

const TargetAudiences = () => {
    const adGroup = useSelector(s => s.media.currentAdGroup);
    const { audienceTarget } = adGroup

    const groupedAudienceTargets = useMemo(() => {
        const groupedValues = []

        for (let i = 0; i < audienceTarget?.includedTargets?.length; i++) {
            const groupedTargets = groupFacetsByCategory(audienceTarget?.includedTargets[i])
            groupedValues.push(groupedTargets)
        }

        return groupedValues
    }, [audienceTarget])

    return (
        <StyledTargetWrapper>
            <p style={{ fontSize: '18px', color: '#717171', fontWeight: 'bold' }}>LinkedIn Target Audiences</p>
            <p>Your LinkedIn target audience will have ANY of the following attributes:</p>
            <StyledAudienceContainer>
                <p>{audienceTarget?.name}</p>
                <div className='meta-data'>
                    <p><span>Targeting Type:</span> {audienceTarget?.type}</p>
                    <p><span>Audience Type:</span> {audienceTarget?.description}</p>
                    <p><span>Audience Expansion:</span> {audienceTarget?.audienceExpansion ? 'Enabled' : 'Disabled'}</p>
                </div>
                {
                    groupedAudienceTargets.map((targetGroup, idx) => {
                        return (
                            <div key={idx}>
                                {idx > 0 && <p><span style={{fontWeight: 'bold'}}>AND</span> also have ANY of the following attributes:</p>}
                                <StyledTargetList key={idx}>
                                    <div className='target-group'>
                                        {
                                            targetGroup?.map((group, gIdx) => {
                                                return (
                                                    <div key={gIdx} className='target-wrapper'>
                                                        <p>{gIdx > 0 && <span style={{ fontWeight: 'bold' }}>OR</span>} {group?.name}</p>
                                                        <div className='target-items'>
                                                            {
                                                                group?.targets?.map((target) => {
                                                                    return <div className='item' key={target?.urn}>
                                                                        <p>{target?.name}</p>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </StyledTargetList>
                            </div>
                        )
                    })
                }
            </StyledAudienceContainer>
        </StyledTargetWrapper>
    )
}

export default TargetAudiences