import {useState, useEffect} from 'react'


const useOauth = (network) => {
    network = network?.toLowerCase() || ''
    const [startedOAuthFlow, setStartedOAuthFlow] = useState(false);
    const [onFocusBack, setOnFocusBack] = useState(false)


    const handleOAuth = () => {
        window.eulerity.makeApiCall(`/api/user/doOauth?network=` + network, "GET", null, (response) => {
            if (!response?.redirectUrl) return;
            setStartedOAuthFlow(true);
            window.open(response.redirectUrl, '_blank');
        });
    }

    useEffect(() => {
        if (!startedOAuthFlow) return;
        const handleReturnFromOAuth = () => setOnFocusBack(true)
        window.addEventListener('focus', handleReturnFromOAuth)
        return () => {
            window.removeEventListener('focus', handleReturnFromOAuth)
            setOnFocusBack(false)
            setStartedOAuthFlow(false)
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startedOAuthFlow])


    return {onFocusBack, handleOAuth}
}

export default useOauth