import React from 'react';
import CheckBoxField from '../../../../ui/input/fields/CheckboxField';


const Catalog = ({ isEdit, useCatalog, setUseCatalog }) => {
    return (

            <CheckBoxField isEdit={isEdit} text={{ header: 'Catalog', body: 'Use Catalog' }} localState={setUseCatalog} value={useCatalog} />

    )
}

export default Catalog;