import React, { forwardRef } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import styled from 'styled-components';

const CalenderWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        color: ${props => props.theme.color.yellow};
        margin-right: 5px;
    }
`

const CustomDatePicker = forwardRef(({onClick, onChange, value}, ref) => {
    return (
        <CalenderWrapper onClick={onClick}>
            <input
                onChange={onChange}
                value={value}
                placeholder='Pick a date'
                ref={ref}
            />
            <AiOutlineCalendar size={28}/>
        </CalenderWrapper>
    )
})

export default CustomDatePicker