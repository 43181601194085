import styled from "styled-components";
import { STATUS } from "./status.utils";

const determineStatusColor = (status) => {
    switch (status) {
        case STATUS.APPROVED:
            return '#1ABF801A';
        case STATUS.PENDING:
            return '#F8F3EB99';
        case STATUS.REJECTED:
            return '#CF11001A';
        default:
            return '#D9D9D880';
    }
}

const determineTextColor = (status) => {
    switch (status) {
        case STATUS.APPROVED:
            return '#1ABF80';
        case STATUS.PENDING:
            return '#F4B63F';
        case STATUS.REJECTED:
            return '#CF1100';
        default:
            return '#717171';
    }
}

export const StyledStatusTag = styled.div`
    align-items: center;
    background-color: ${props => determineStatusColor(props.status)};
    border-radius: 4px;
    color: ${props => determineTextColor(props.status)};
    display: flex;
    font-size: 12px;
    font-weight: 600;
    gap: 5px;
    height: 25px;
    justify-content: center;
    padding: 0 20px;
    width: fit-content;

    svg {
        cursor: pointer;
    }

    .tool-tip {
        border: 1px solid #A7AFB2;
        max-width: 278px;
    }

    .arrow {
        border: 1px solid #A7AFB2;
        border-left-color: transparent;
        border-top-color: transparent;
    }
`

export const StyledTooltipContent = styled.div`
`