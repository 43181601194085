import React, { useState, useEffect } from 'react';
import Loader from '../../../../../ui/Loader';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getAdGroup } from '../../../../../actions/mediaActions';

// Styles
import { AdGroupListContainer, AdGroupSetupContainer, AdGroupListItem } from '../../../styles';
import { StyleCenterElement } from '../../../../../ui/button';

const CampaignAdGroups = ({currNav}) => {
    const adGroups = useSelector(state => state.media.currentCampaign.adGroups);
    const currentAdGroup = useSelector(state => state.media.currentAdGroup);

    const [adGroupLoader, setAdGroupLoader] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (currNav === 'Campaign Setup') getAdGroupData(adGroups[0]?.websafe);
    }, [currNav])

    const getAdGroupData = (websafe) => {
        setAdGroupLoader(true);

        dispatch(getAdGroup({
            mediaPlanAdGroup: websafe,
            callback: () => {
                setAdGroupLoader(false);
            }
        }))
    }

    const adGroupsList = () => {
        return adGroups?.map((adGroup, idx) =>
            <AdGroupListItem
                key={adGroup.websafe}
                isSelected={adGroup?.websafe === currentAdGroup?.websafe}
                onClick={() => getAdGroupData(adGroup?.websafe)}
            >
                Campaign {idx + 1}
            </AdGroupListItem>) || [];
    }

    if (adGroupLoader) return <AdGroupSetupContainer> <StyleCenterElement><Loader size={50} /></StyleCenterElement> </AdGroupSetupContainer>

    return <AdGroupListContainer> {adGroupsList()} </AdGroupListContainer>
}

export default CampaignAdGroups;