import React from 'react';
import PixelId from './facebookAdSetSetupFields/PixelId';
import ConversionEvent from './facebookAdSetSetupFields/ConversionEvent';
import ConversionEventLocation from './facebookAdSetSetupFields/ConversionEventLocation';

const SalesObjectiveRendering = ({isEdit, isSalesObjective, networkTemplateCampaign, currAdGroup, conversionEvent, setConversionEvent, conversionEventList}) => {
    const useCatalog = networkTemplateCampaign?.useCatalog;
    const optimization = networkTemplateCampaign?.optimization;


    if (!isSalesObjective) return;
    if (isSalesObjective && useCatalog && optimization === 'Conversions') {
        return(
            <ConversionEvent
                isEdit={isEdit}
                conversionEvent={conversionEvent}
                setConversionEvent={setConversionEvent}
                conversionEventList={conversionEventList}
                currAdGroup={currAdGroup}
            />
        )
    }
    if (isSalesObjective && useCatalog) return;

    return (<>
        <PixelId isEdit={isEdit} currAdGroup={currAdGroup} />
        <ConversionEventLocation isEdit={isEdit} />
        <ConversionEvent
            isEdit={isEdit}
            conversionEvent={conversionEvent}
            setConversionEvent={setConversionEvent}
            conversionEventList={conversionEventList}
            currAdGroup={currAdGroup}
        />
    </>)
}

export default SalesObjectiveRendering;