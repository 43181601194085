import React from 'react'
import { FieldContainer } from '../../../../ui/containers'
import CurrencyInput from '../../../../ui/input/fields/CurrencyInput'


const customStyles = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
}

const TargetCpa = ({ currency, googleIcon, isEdit, setTargetCpa, targetCpa }) => {
    return (
        <FieldContainer styles={{ background: 'red' }}>
            <h3>{googleIcon} Target CPA</h3>
            {
                isEdit ? <div style={{ ...customStyles }}>
                    <CurrencyInput
                        value={targetCpa}
                        onChange={(e) => setTargetCpa(e.target.value)}
                        currency={currency}
                    />
                </div> : <p>{currency} {targetCpa}</p>
            }
        </FieldContainer>
    )
}

export default TargetCpa