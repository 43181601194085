import React, { useContext } from 'react';

// Hooks
import { LinkedInCampaignContext } from './LinkedInCampaignSetup';

// Styles
import { FieldContainer } from '../../../../../ui/containers';
import { InputRangeContainer } from '../../../../lineofbusiness/styles';
import { NumberInput } from '../../../../../ui/input/input';

const Age = ({}) => {
    const { isEdit, campaignObj, handleUpdate } = useContext(LinkedInCampaignContext);
    const { minAge, maxAge } = campaignObj;
    return (
        <FieldContainer>
            <h3> Age </h3>
            {
                isEdit ?
                <InputRangeContainer>
                    <NumberInput type='number' value={minAge} onChange={e => handleUpdate({key: 'minAge', value: parseInt(e.target.value)})} />
                    <span> to </span>
                    <NumberInput type='number' value={maxAge} onChange={e => handleUpdate({key: 'maxAge', value: parseInt(e.target.value)})} />
                </InputRangeContainer>
                 :
                <p> {minAge} - {maxAge} </p>
            }
        </FieldContainer>
    )
}

export default Age;