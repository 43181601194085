import React, { useContext } from 'react'
import { BiTrash } from 'react-icons/bi'
import AttributeItem from './AttributeItem'
import { ButtonWrapper } from '../../../../../../../../components/Modal/modals/styles'
import { OutlineBtnLg } from '../../../../../../../../ui/button'
import { LinkedInAudienceContext } from '../LinkedAudienceModal'
import { StyledAttributeGroup } from './styles'
import { groupFacetsByCategory } from '../linkedInTargets.utils'

const AttributeGroup = ({ attributeGroup, onAddSpecific, onAdd, targetIndex }) => {
  const { selectedAttributes, setSelectedAttributes } = useContext(LinkedInAudienceContext)

  const deleteTargetGroup = () => {
    const updatedTargets = [...selectedAttributes]
    const removedGroup = updatedTargets.toSpliced(targetIndex, 1)
    setSelectedAttributes(removedGroup)
  }

  return (
    <StyledAttributeGroup>
      <div className='attribute-left'>
        {
          groupFacetsByCategory(attributeGroup).map((group, idx) => {
            return <AttributeItem key={idx} group={group} targetIndex={targetIndex} onAddSpecific={() => onAddSpecific(group)} idx={idx}/>
          })
        }
        <ButtonWrapper>
          <OutlineBtnLg onClick={onAdd}>Add More Attributes</OutlineBtnLg>
        </ButtonWrapper>
      </div>
      <div className='attribute-right'>
        <BiTrash onClick={() => deleteTargetGroup()} size={24} />
      </div>
    </StyledAttributeGroup>
  )
}

export default AttributeGroup