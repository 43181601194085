import { toast } from 'react-toastify';
import { GET_LOB_LIST, CREATE_LOB, DELETE_LOB, SELECT_LOB, UPDATE_LOB, CLONE_LOB, RESET_LOB, SELECT_NETWORK_CONFIG, UPDATE_NETWORK_CONFIG } from './types';

export const getLobList = (cb) => dispatch => {
    const list = [];
    window.eulerity.makeBatchedApiCall({
        url: `/api/lob/list`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        errorCallbacks: {
            1000: (err) => {
                cb?.(err)
            }
          },
        doneCallback: () => {
            dispatch({
                type: GET_LOB_LIST,
                payload: list
            })
            cb?.()
        }
    })
}

export const getFacebookSpecialAdCategory = () => {
    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall("/api/facebook/specialAdCategories", "GET", null, (res) => {
           resolve(res);
        },{1000: reject})
    })
}

export const createLob = ({name, advertiser, callback}) => dispatch => {
    if (!name) return;

    window.eulerity.makeApiCall(`/api/lob/create?advertiser=${advertiser}`, 'POST', {name}, response => {
        dispatch({
            type: CREATE_LOB,
            payload: response
        })
        callback({websafe: response.websafe})
    })
}

export const deleteLob = ({websafe, callback, errorCallback}) => dispatch => {
    if (!websafe) return;

    try {
        window.eulerity.makeApiCall(`/api/lob/delete?lob=${websafe}`, 'POST', null, res => {
            dispatch({
                type: DELETE_LOB,
                payload: websafe
            })
            callback?.(res);
        })
    } catch(err) {
        console.error(err);
        errorCallback?.();
    }
}

export const cloneLob = ({copyName, lobId, callback}) => dispatch => {
    if(!lobId) return;
    window.eulerity.makeApiCall(`/api/lob/clone?lob=${lobId}`, 'POST', { name: copyName }, response => {
        dispatch({
            type: CLONE_LOB,
            payload: response
        })

        // * This is to open the new cloned network
        callback?.(response);
    })
}

export const selectLob = ({websafe, callback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/lob/get?lob=${websafe}`, 'GET', null, res => {
            dispatch({
                type: SELECT_LOB,
                payload: res
            })
            callback?.(res);
            // TODO - Remove when support is given to legacy LOBs
            if (!res?.networkConfigs?.find(config => config?.keyParam === 'liconfig')) toast.info('This Line of Business currently does not support LinkedIn Network Config')
        })
    } catch(err) {
        console.error('Something went wrong');
    }
}

export const resetLob = () => dispatch => {
    dispatch({
        type: RESET_LOB,
        payload: {}
    })
}

export const updateLob = ({websafe, updateObj, callback}) => dispatch => {
    if (!websafe) return;

    try {
        window.eulerity.makeApiCall(`/api/lob/update?lob=${websafe}`, 'POST', updateObj, res => {
            dispatch({
                type: UPDATE_LOB,
                payload: res
            })
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
    }
}

export const getLob = ({lobId, callback}) => {
    if (!lobId) return;

    window.eulerity.makeApiCall(`/api/lob/get?lob=${lobId}`, 'GET', null, res => {
        callback(res);
    })
}

export const createDummyConfig = ({websafe, network, callback}) => {
    if (!websafe) return;

    return new Promise((resolve, reject) => {
        window.eulerity.makeApiCall(`/api/lob/createDummyConfig?lob=${websafe}&network=${network}`, 'POST', null, res => {
            resolve(res);
            callback?.(res);
        }, {1000: reject})
    })
}

// * Selects Google Network
export const selectGoConfig = ({goConfigId, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/getNetworkConfig?goconfig=${goConfigId}`, 'GET', null, (res) => {
        dispatch({
            type: SELECT_NETWORK_CONFIG,
            payload: res
        })
        callback?.();
    })
}

// * Selects Facebook Network
export const getFbConfig = ({fbConfigId, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/getNetworkConfig?fbconfig=${fbConfigId}`, 'GET', null, (res) => {
        dispatch({
            type: SELECT_NETWORK_CONFIG,
            payload: res
        })
        callback?.(res);
    })
}

export const selectLiConfig = ({liConfigId, callback}) => dispatch => {
    window.eulerity.makeApiCall({
        url: `/api/lob/getNetworkConfig?liconfig=${liConfigId}`,
        method: 'GET',
        callback: (res) => {
            dispatch({
                type: SELECT_NETWORK_CONFIG,
                payload: res
            })
            callback?.();
        },
        errorCallbacks: {
            500: (err) => {
                console.error(err)
                toast.error('Unable To Select Linkedin Config')
            },
            1000: (err) => {
                console.error(err)
                toast.error('Something went wrong!')
            }
        }
    })
}

// * Attach Facebook Social Page
export const attachSocialPage = ({socialLoginId, socialConfig, pages, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/attachSocialAccounts?socialLoginId=${socialLoginId}&${socialConfig}`, "POST", pages, (response) => {
        dispatch({
            type: UPDATE_NETWORK_CONFIG,
            payload: response
        })
        callback?.();
    })
}

// * Deletes Facebook Social Page
export const removeSocialAccount = ({obj, socialConfig, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/removeSocialAccounts?${socialConfig}`, "POST", obj, (res) => {
        dispatch({
            type: UPDATE_NETWORK_CONFIG,
            payload: res
        })
        callback?.();
    })
}

// * Attaches Ad Account
export const attachAdAccount = ({socialLoginId, socialConfig, pages, callback, errorCallback}) => dispatch => {
    try {
        window.eulerity.makeApiCall(`/api/lob/attachAdAccounts?socialLoginId=${socialLoginId}&${socialConfig}`, "POST", pages, (response) => {
            dispatch({
                type: UPDATE_NETWORK_CONFIG,
                payload: response
            })
            callback?.();
        })
    } catch (err) {
        errorCallback?.(err);
    }
}

// * Edits Ad Account
export const updateAdAccount = ({socialLoginId, socialConfig, pages, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/updateAdAccounts?socialLoginId=${socialLoginId}&${socialConfig}`, "POST", pages, (response) => {
        dispatch({
            type: UPDATE_NETWORK_CONFIG,
            payload: response
        })
        callback?.()
    })
}

// * Removes Ad Account
export const removeAdAccount = ({obj, socialConfig, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/removeAdAccounts?${socialConfig}`, "POST", obj, (res) => {
        dispatch({
            type: UPDATE_NETWORK_CONFIG,
            payload: res
        })
        callback?.();
    })
}


// * Updates network config (generic data)
export const updateNetworkConfig = ({obj, socialConfig, callback, handleErrorCallback}) => dispatch => {
    window.eulerity.makeApiCall({
        url: `/api/lob/updateNetworkConfig?${socialConfig}`,
        method: 'POST',
        obj,
        callback: (res) => {
            dispatch({
                type: UPDATE_NETWORK_CONFIG,
                payload: res
            })
            callback?.() // * This callback is what should happen AFTER the api call is made (such as closing a modal/toast)
        },
        errorCallbacks: {
            1000: (error) => {
                console.error(error)
                handleErrorCallback?.()
            }
        }
    })
}


// * DUMMY NETWORK CONFIG ACTIONS * //

// * Selects Dummy Network (navigation between dummy networks)
export const selectDummyNetwork = ({duConfigId, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/getNetworkConfig?duconfig=${duConfigId}`, 'GET', null, (res) => {
        dispatch({
            type: SELECT_NETWORK_CONFIG,
            payload: res
        })
        callback?.()
    })
}

// * Updates generic fields for a dummy network (save/edit btn clicked)
export const updateLOBDummyProperties = ({ dummy, callback, errorCallback }) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/updateNetworkConfig?duconfig=${dummy.websafe}`, "POST", dummy, (_) => {
        try {
            dispatch({
                type: UPDATE_NETWORK_CONFIG,
                payload: _
            })
            callback?.();
        } catch (err) {
            errorCallback?.(err);
        }
    })
}

// * Attaches account to Dummy Network
export const attachDummyAdAccount = ({socialConfig, pages, callback}) => dispatch => {
    window.eulerity.makeApiCall(`/api/lob/attachAdAccounts?${socialConfig}`, "POST", pages, (response) => {
        dispatch({
            type: UPDATE_NETWORK_CONFIG,
            payload: response
        })
        callback?.();
    })
}

export const getPixelList = ({socialLoginId, accountId, callback}) => {
    try {
        const list = [];

        window.eulerity.makeBatchedApiCall({
        url: `/api/facebook/listAdPixels?socialLoginId=${socialLoginId}&accountId=${accountId}`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        errorCallbacks: {
            1000: (err) => {
                console.error(err);
            }
        },
        doneCallback: () => {
            callback?.(list)
        }
    })
    } catch (err) {
        console.error(err);
    }
}

export const getCatalogsList = ({socialLoginId, accountId, callback}) => {
    try {
        const list = [];

        window.eulerity.makeBatchedApiCall({
        url: `/api/facebook/listCatalogs?socialLoginId=${socialLoginId}&accountId=${accountId}`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        errorCallbacks: {
            1000: (err) => {
                console.error(err);
            }
        },
        doneCallback: () => {
            callback?.(list)
        }
    })
    } catch (err) {
        console.error(err);
    }
}

export const productSetsList = ({socialLoginId, catalogId, callback}) => {
    try {
        const list = [];

        window.eulerity.makeBatchedApiCall({
        url: `/api/facebook/listProductSets?socialLoginId=${socialLoginId}&catalogId=${catalogId}`,
        dataCallback: (res) => {
            res.forEach(c => list.push(c))
        },
        errorCallbacks: {
            1000: (err) => {
                console.error(err);
            }
        },
        doneCallback: () => {
            callback?.(list)
        }
    })
    } catch (err) {
        console.error(err);
    }
}

export const queryOrg = ({reference, callback, errorCallback}) => {
    try {
        window.eulerity.makeApiCall(`/api/linkedin/queryOrg?orgUrn=${reference}`, 'GET', null, (res) => {
            callback?.(res);
        })
    } catch (err) {
        console.error(err);
        errorCallback?.(err);
    }
}
