import { useEffect, useState } from "react"
import { toast } from "react-toastify";

const useLinkedInAccount = (selectedItem) => {
    // * Step 1 States
    const [selectedAccount, setSelectedAccount] = useState();
    // * Step 2 States
    const [selectedPages, setSelectedPages] = useState([]);
    // * Step 3 States
    const [costPerGoal, setCostPerGoal] = useState(0);
    const [manualBidding, setManualBidding] = useState(0);
    const [insightTag, setInsightTag] = useState(null)
    const [insightOptions, setInsightOptions] = useState([])
    const [liPage, setLiPage] = useState();

    useEffect(() => {
        if (!selectedItem) return

        setCostPerGoal(selectedItem?.targetCpa)
        setManualBidding(selectedItem?.cpcBidLimit)
        setInsightTag({label: selectedItem?.insightTag?.id, value: selectedItem?.insightTag})
        setLiPage(selectedItem?.liPage?.localizedName);
    }, [selectedItem])

    const fetchInsightOptions = (socialId, accId) => {
        window.eulerity.makeApiCall({
            url: `/api/linkedin/listInsightTags?socialLoginId=${socialId}&accountId=${accId}`,
            method: 'GET',
            callback: (res) => {
                const mappedOptions = res?.map(option => {
                    return { label: `Insight ID: ${option?.id}`, value: option }
                })
                setInsightOptions(mappedOptions)
            },
            errorCallbacks: {
                400: (error) => {
                    console.error(error)
                },
                500: (error) => {
                    console.error(error)
                    toast.error('Unable to retrieve insight tags!')
                }
            }
        })
    }

    return {
        selectedAccount,
        setSelectedAccount,
        selectedPages,
        setSelectedPages,
        costPerGoal,
        setCostPerGoal,
        manualBidding,
        setManualBidding,
        insightTag,
        setInsightTag,
        fetchInsightOptions,
        insightOptions,
        liPage
    }
}

export default useLinkedInAccount