import React, { useState, useRef } from "react";

// Redux
import { useSelector } from "react-redux";

// Components
import Modal from "../../../../../../components/Modal/Modal";

// Utils
import { uploadBlob } from "../../../../../../utils";
import { serveImage } from "../../label.utils";
import { createDummyNetwork, didValuesChangeForDummyData, updateDummyNetwork } from "../../../../utils";

// Hooks
import useDragDrop from "../../../../../../hooks/useDragAndDrop";

// Libraries
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";

// Styles
import HiddenInput from "../../../../../../ui/HiddenInput";
import { BtnWithLoader, OutlineBtn } from "../../../../../../ui/button";
import { StyleChannelModal } from "../../styles";
import { IoImageOutline } from "react-icons/io5";
import { BiPlusCircle } from "react-icons/bi";
import { selectTheme } from "../../../../../../ui/themes";
import { Input } from "../../../../../../ui/input/input";

//* accepted Files
let acceptedFiles = ["image/svg+xml", "image/png", "image/jpeg", "image/jpg"];

const ChannelModal = ({ onClose, selectedNetwork }) => {
  const browseRef = useRef();
  const [file, setFile] = useState(null);
  const [networkName, setNetworkName] = useState(selectedNetwork?.name);
  const [objectives, setObjectives] = useState(selectedNetwork?.objectives.map((it) => ({ label: it, value: it })) || []);
  const [uploadLoader, setUploadLoader] = useState(false);

  const org = useSelector((s) => s.org.organization);

  //Hooks
  const [dropRef, onEnter] = useDragDrop(handleOnReceiveFile, acceptedFiles);

  function handleOnReceiveFile(file, error) {
    if (error) return toast.error(error.message);
    setFile(file);
  }

  function handleOnFileSelect(e) {
    const file = e.target.files[0];
    setFile(file);
  }

  function handleFilePickerClick() {
    browseRef?.current.click();
  }

  function handleDummyNetworkController(logo) {
    const createOrUpdateDummyNetwork = {
      name: networkName,
      objectives: objectives.map((it) => it.value),
      logo,
    };
    return createOrUpdateDummyNetwork
  }

  //* this function uploads the dummy network
  async function handleUploadController(dummyNetworkData) {
    try {

      if(selectedNetwork) {
          const didChangeHappend = didValuesChangeForDummyData(selectedNetwork,dummyNetworkData)
          if(didChangeHappend) {
            return toast.warning("No data was changed!")
          }
          await updateDummyNetwork(selectedNetwork?.websafe, dummyNetworkData)
      } else {
          await createDummyNetwork(org?.id, dummyNetworkData)
      }
      onClose();
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setUploadLoader(false);
    }
  }

  async function handleOnUpload() {
    if (!networkName || !objectives.length || (!file && !selectedNetwork)) return toast.error("All fields are required!");
    const doesNetworkExists = org.dummyNetworks.find((it) => it.name === networkName);
    if (doesNetworkExists && !selectedNetwork) return toast.error("Network exists");
    setUploadLoader(true);

    if (file) {
      uploadBlob({
        file,
        callback: async (res) => {
          const dummyNetworkData = handleDummyNetworkController(res.blobkey);
          await handleUploadController(dummyNetworkData);
        },
      });
    } else {
      const allDummyNetworks = handleDummyNetworkController(selectedNetwork?.logo);
      await handleUploadController(allDummyNetworks);
    }
  }
  function handleNetworkName(e) {
    setNetworkName(e.target.value);
  }

  return (
    <Modal onClose={onClose} header={"Non-API Networks"}>
      <HiddenInput reference={browseRef} onChange={handleOnFileSelect} accept={acceptedFiles.join(",")} />
      <StyleChannelModal intersect={onEnter}>
        <div className="chanel_network-name">
          <p>
            Network Name <span>*</span>
          </p>
          <Input onChange={handleNetworkName} value={networkName} />
        </div>

        {file || selectedNetwork?.logo ? (
          <div ref={dropRef} className="chanel_network-logo">
            <img src={file ? URL.createObjectURL(file) : serveImage(selectedNetwork?.logo)} alt="logo" />
          </div>
        ) : (
          <div ref={dropRef} className="chanel_network-logo">
            <div className="chanel-logo_holder">
              <IoImageOutline size="44" />
              <p>
                Drag and drop a logo, or <span onClick={handleFilePickerClick}>Browse</span>
              </p>
            </div>
          </div>
        )}

        <div className="chanel_network-file">
          <div className="chanel_network-text">
            <p className="chanel--title">Selected File</p>
            <p className="chanel--filename">{file?.name || "File Name"}</p>
          </div>
          <div onClick={handleFilePickerClick}>
            <BiPlusCircle size="20" />
          </div>
        </div>
        <div className="chanel_network-labels">
          <p>
            List of Objectives <span>*</span>
          </p>
          <CreatableSelect isClearable isMulti defaultValue={objectives} styles={selectTheme} onChange={setObjectives} />
        </div>
        <div className="chanel_network-buttons">
          <OutlineBtn onClick={onClose}>Cancel</OutlineBtn>
          <BtnWithLoader btnText="Save Network" type="blackBtn" onClick={handleOnUpload} loader={uploadLoader} color="white" />
        </div>
      </StyleChannelModal>
    </Modal>
  );
};

export default ChannelModal;
