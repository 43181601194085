import React, { useState, useEffect } from 'react';
import ConfigureCatalogStep from './AudienceTargetSteps.js/ConfigureCatalogStep';
import DescriptionStep from './AudienceTargetSteps.js/DescriptionStep';
import ListAudienceStep from './AudienceTargetSteps.js/ListAudienceStep';
import InterestTargetStep from './AudienceTargetSteps.js/InterestTargetStep';
import ReviewAudienceTargets from './AudienceTargetSteps.js/ReviewAudienceTargets';
import BuildModal from '../../../../components/Modal/modals/BuildModal';

// Redux
import { useSelector } from 'react-redux';

// Styles
import { TableModalContainer } from '../../../../ui/table/styles';
import { BlackBtn, OutlineBtn, BtnWithLoader } from '../../../../ui/button';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateNetworkConfig } from '../../../../actions/lobActions';
import InterestTargetStepV2 from './AudienceTargetSteps.js/InterestTargetStepV2';

const AudienceTargetModal = ({onClose, audienceDescriptionList, adAccountsList, audienceTargets, editedAdsetData, isEditingAdset, editedIndex, fbConfigId}) => {
    const _D = useDispatch()

    const [audienceTargetName, setAudienceTargetName] = useState('');
    const [audienceDescription, setAudienceDescription] = useState('');
    const [adAccount, setAdAccount] = useState('');
    const [accountId, setAccountId] = useState('');
    const [selectedAudienceType, setSelectedAudienceType] = useState('');

    // * Audience Target
    const [includeAudiences, setIncludeAudiences] = useState([]);
    const [excludeAudiences, setExcludeAudiences] = useState([]);
    const [includedInterestTargets, setIncludedInterestTargets] = useState([]);
    const [excludedInterestTargets, setExcludedInterestTargets] = useState([]);
    const [selectedListItem, setSelectedListItem] = useState([]);
    const [listAudiences, setListAudiences] = useState([]);
    const [queryInterests, setQueryInterests] = useState([]);
    const [excludeQueryInterests, setExcludeQueryInterests] = useState([]);
    const [productSet, setProductSet] = useState({});

    // * Editing States
    const [preSelectedAudiences, setPreSelectedAudiences] = useState([])
    const [preSelectedInterestTargets, setPreSelectedInterestTargets] = useState([])

    // Storing step state
    const [isAudience, setIsAudience] = useState(true);
    const [count, setCount] = useState(1);
    const [step, setStep] = useState(1);

    const socialLoginId = adAccount.socialLoginId;
    const isDisabled = !audienceTargetName || !audienceDescription || !adAccount;

    const [isAudienceLoading, setIsAudienceLoading] = useState(true);
    const [isInterestLoading, setIsInterestLoading] = useState(true);
    const [loading, setLoading] = useState(false)

    // * Fires off for edit flow
    useEffect(() => {
        if(!isEditingAdset || !editedAdsetData) return;
        const {name, description, excludeAudiences, type, excludedInterestTargets, includeAudiences, includedInterestTargets} = editedAdsetData;
        const matchedAccount = adAccountsList.find(acc => acc?.accountId === editedAdsetData?.accountId) // * the adAccount state should be the object assocaited with the account number (id).

        // * Initlize data
        setAudienceTargetName(name || '');
        setAudienceDescription(description || '');
        setSelectedAudienceType(type || '');
        setIncludeAudiences(includeAudiences || []);
        setIncludedInterestTargets(includedInterestTargets || []);
        setExcludeAudiences(excludeAudiences || []);
        setExcludedInterestTargets(excludedInterestTargets || []);
        setAdAccount(matchedAccount || '');

        // * Initilize combined pre-selected audiences and interest targets
        let includedAud = []
        let excludedAud = []
        let includedTargs = []
        let excludedTargs = []
        if(includeAudiences) includedAud = [...includeAudiences]
        if(excludeAudiences) excludedAud = [...excludeAudiences]
        if(includedInterestTargets) includedTargs = [...includedInterestTargets]
        if(excludedInterestTargets) excludedTargs = [...excludedInterestTargets]
        setPreSelectedAudiences([...includedAud, ...excludedAud] || [])
        setPreSelectedInterestTargets([...includedTargs, ...excludedTargs].flat() || [])

        // * count is set to have includedInterestTargets to show addtional fields (narrow)
        setCount(includedInterestTargets?.length || 1)
    }, [])

    useEffect(() => {
        if (!socialLoginId) return;
        if (!accountId && !adAccount?.accountId) return;

        const audiencesList = [];

        window.eulerity.makeBatchedApiCall({
            url: `/api/facebook/listAudiences?socialLoginId=${socialLoginId}&accountId=${accountId || adAccount.accountId}`,
            dataCallback: (res) => {
                res.forEach(c => audiencesList.push(c))
            },
            doneCallback: () => {
                setListAudiences(audiencesList);
                setIsAudienceLoading(false);
            },
            errorCallbacks: {
                1000: (err) => {
                    console.error(err);
                    toast.error('Something went wrong with this account')
                }
            },
        })
    }, [socialLoginId, accountId, adAccount])

    // * get default values for selection drop downs
    const getDefaultAudienceDescription = () => {
        return { label: audienceDescription, value: audienceDescription}
    }

    const getDefaultAudienceType = () => {
        return { label: selectedAudienceType, value: selectedAudienceType}
    }

    const getDefaultAccount = () => {
        return { label: adAccount?.displayName, value: adAccount?.accountId }
    }

    // * Dispatch function
    const handleSaveAdSet = (updatedAdsets) => {
        setLoading(true)
        _D(updateNetworkConfig({
            obj: {audienceTargets: updatedAdsets},
            socialConfig: `fbconfig=${fbConfigId}`,
            callback: () => {
                toast.success('Added Facebook Adset');
                setLoading(false)
                onClose()
            }
        }))
    }

    const handleSubmit = () => {
        const squishedIncludedInterestTargets = includedInterestTargets.filter(arr => arr.length);

        const obj = {
            name: audienceTargetName,
            description: audienceDescription,
            type: selectedAudienceType,
            accountId: adAccount?.accountId,
            includeAudiences: includeAudiences,
            excludeAudiences: excludeAudiences,
            includedInterestTargets: squishedIncludedInterestTargets,
            excludedInterestTargets: excludedInterestTargets,
            productSet
        }

        if(isEditingAdset) {
            let updatedArray = [...audienceTargets] // *  copy current audience targets array
            updatedArray[editedIndex] = obj // * replace index of selected adset with new data
            handleSaveAdSet([...updatedArray])
            return
        }

        handleSaveAdSet([...audienceTargets, obj]);
    }

    const handleSaveAndExit = () => {
        if (isEditingAdset) {
            handleSubmit();
            return;
        }

        const obj = {
            name: audienceTargetName,
            description: audienceDescription,
            accountId: adAccount?.accountId,
            type: selectedAudienceType,
        }

        handleSaveAdSet([...audienceTargets, obj]);
    }

    const audienceDescriptionOptions = audienceDescriptionList?.map(audience => {
        return ({
            label: audience,
            value: audience
        })
    })

    const adAccountOptions = adAccountsList?.map(accounts => {
        return ({
            label: accounts?.displayName,
            value: accounts
        })
    })

    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }

    const handleSkip = () => {
        if (step === 2) {
            setSelectedListItem([])
            setIncludeAudiences([]);
            setExcludeAudiences([]);
            setIsAudience(false);
        }

        if (step === 3) {
            setSelectedListItem([]);
            setIncludedInterestTargets([]);
            setExcludedInterestTargets([]);
        }
        setStep(step + 1);
    }

    const handleSaveAndContinue = () => {
        setStep(step + 1);
    }

    const steps = () => {
        switch (step) {
            case 1: return <DescriptionStep
                setAudienceTargetName={setAudienceTargetName} setAdAccount={setAdAccount} setSelectedAudienceType={setSelectedAudienceType} setAudienceDescription={setAudienceDescription}
                audienceTargetName={audienceTargetName} getDefaultAudienceType={getDefaultAudienceType} getDefaultAudienceDescription={getDefaultAudienceDescription} getDefaultAccount={getDefaultAccount}
                adAccountOptions={adAccountOptions} audienceDescriptionOptions={audienceDescriptionOptions}
            />
            case 2: return <ListAudienceStep
                isAudience={true} listAudiences={listAudiences} socialLoginId={socialLoginId} preSelectedAudiences={preSelectedAudiences}
                includeAudiences={includeAudiences} selectedListItem={selectedListItem} excludeAudiences={excludeAudiences}
                setIncludeAudiences={setIncludeAudiences} setSelectedListItem={setSelectedListItem} setQueryInterests={setQueryInterests} setExcludeAudiences={setExcludeAudiences} setExcludeQueryInterests={setExcludeQueryInterests}
                audienceDescription={audienceDescription} isLoading={isAudienceLoading}
            />
            case 3: return <InterestTargetStepV2
                audienceDescription={audienceDescription}
                socialLoginId={socialLoginId}
                setIncludedInterestTargets={setIncludedInterestTargets}
                includedInterestTargets={includedInterestTargets}
                setExcludedInterestTargets={setExcludedInterestTargets}
                excludedInterestTargets={excludedInterestTargets}
                count={count}
                setCount={setCount}
            />
            // ! - Serious question, why do we have all these props if this flow is disconnected from others? 
            // ! - Is audience even being used? Or are these meant to take care of condtional edge cases from the shared usage of the AudienceTargetCompoennt
            // ! - Please confirm before mergin that I'm not missing any crucial properties...
            // case 3: return <InterestTargetStep
            //     isAudience={false} socialLoginId={socialLoginId} preSelectedAudiences={preSelectedAudiences}
            //     includeAudiences={includeAudiences} selectedListItem={selectedListItem}
            //     setIncludeAudiences={setIncludeAudiences} setSelectedListItem={setSelectedListItem}
            //     queryInterests={queryInterests} setQueryInterests={setQueryInterests}
            //     excludeQueryInterests={excludeQueryInterests} setExcludeQueryInterests={setExcludeQueryInterests}
            //     excludeAudiences={excludeAudiences} setExcludeAudiences={setExcludeAudiences}
            //     includedInterestTargets={includedInterestTargets} setIncludedInterestTargets={setIncludedInterestTargets}
            //     excludedInterestTargets={excludedInterestTargets} setExcludedInterestTargets={setExcludedInterestTargets}
            //     count={count} setCount={setCount} isLoading={isInterestLoading} setIsLoading={setIsInterestLoading}
            // />
            case 4: return <ConfigureCatalogStep
                socialLoginId={socialLoginId}
                accountId={adAccount?.accountId}
                setProductSet={setProductSet}
                adAccount={adAccount}
            />
            case 5: return <ReviewAudienceTargets
                includeAudiences={includeAudiences}
                excludeAudiences={excludeAudiences}
                includedInterestTargets={[includedInterestTargets]}
                excludedInterestTargets={excludedInterestTargets}
                productSet={productSet}
                adAccount={adAccount}
            />
            default:
              console.log(`No step selected`);
        }
    }

    const decideButtons = () => {
        switch(step) {
            case 1: return [<OutlineBtn onClick={onClose}> Cancel </OutlineBtn>, <BtnWithLoader btnText='Add and Exit' onClick={handleSaveAndExit} loader={loading} isDisabled={isDisabled} />, <BlackBtn isDisabled={isDisabled} onClick={handleSaveAndContinue}> Next </BlackBtn>]
            case 2: case 3: case 4: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <OutlineBtn onClick={handleSkip}> Skip </OutlineBtn>, <BlackBtn onClick={handleNext}> Next </BlackBtn>]
            case 5: return [<OutlineBtn onClick={handleBack}> Back </OutlineBtn>, <BtnWithLoader type='blackBtn' btnText='Add Facebook Adset' onClick={handleSubmit} loader={loading} />]
            default: return []
        }
    }

    return (
        <BuildModal
            header={`${isEditingAdset ? 'Edit' : 'Add'} Facebook Adset`}
            onClose={onClose}
            buttons={decideButtons()}
            height={"750px"}
            minWidth={"700px"}
        >
            <TableModalContainer>
            {steps()}
            </TableModalContainer>
        </BuildModal>
    )
}

export default AudienceTargetModal;