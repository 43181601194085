import {
    CREATE_MEDIA_PLAN, GET_MEDIA_PLAN_LIST, DELETE_MEDIA_PLAN, SELECT_MEDIA_PLAN, UPDATE_MEDIA_PLAN,
    UPDATE_MEDIA_CAMPAIGN, DELETE_MEDIA_CAMPAIGN, UPDATE_MEDIA_CAMPAIGN_LOCATION_TARGETS, SELECT_CAMPAIGN,
    SELECT_AD_GROUP, UPDATE_AD_GROUP, DELETE_AD_GROUP,
} from '../actions/types';

const initialState = {
    mediaList: [],
    currentMediaPlan: {},
    currentCampaign: {},
    currentAdGroup: {}
}

const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MEDIA_PLAN_LIST: {
            return { ...state, mediaList: action.payload }
        }
        case CREATE_MEDIA_PLAN: {
            return {
                ...state,
                mediaList: [...state.mediaList, action.payload]
            }
        }
        case SELECT_MEDIA_PLAN: {
            return {
                ...state,
                currentMediaPlan: action.payload
            }
        }
        case DELETE_MEDIA_PLAN: {
            const filteredList = state.mediaList.filter(media => media.websafe !== action.payload);

            return {
                ...state,
                mediaList: [...filteredList]
            }
        }
        case UPDATE_MEDIA_PLAN: {
            return {
                ...state,
                currentMediaPlan: action.payload
            }
        }
        case UPDATE_AD_GROUP: {
            return {
                ...state,
                currentAdGroup: action.payload
            }
        }
        case DELETE_AD_GROUP: {
            const filteredAdGroups = state?.currentCampaign?.adGroups?.filter(adGroup => adGroup.websafe !== action.payload);

            return {
                ...state,
                currentCampaign: {...state.currentCampaign, adGroups: filteredAdGroups},
                currentAdGroup: {}
            }
        }
        case UPDATE_MEDIA_CAMPAIGN: {
            return {
                ...state,
                currentCampaign: action.payload
            }
        }
        case DELETE_MEDIA_CAMPAIGN: {
            const filteredCampaigns = state?.currentMediaPlan?.campaigns.filter(campaign => campaign.websafe !== action.payload);

            return {
                ...state,
                currentMediaPlan: {...state.currentMediaPlan, campaigns: filteredCampaigns}
            }
        }
        case SELECT_CAMPAIGN: {
            return {
                ...state,
                currentCampaign: action.payload
            }
        }
        case UPDATE_MEDIA_CAMPAIGN_LOCATION_TARGETS: {
            return {
                ...state,
                currentCampaign: {...state.currentCampaign, locationTargets: action.payload}
            }
        }
        case SELECT_AD_GROUP: {
            return {
                ...state,
                currentAdGroup: action.payload
            }
        }
        default: return state;
    }
}

export default mediaReducer;