import React, { useState } from 'react'
import BuildModal from '../../../../../../../components/Modal/modals/BuildModal'
import { BlackBtn, BtnWithLoader, OutlineBtn } from '../../../../../../../ui/button'
import LinkedInDescStep from './LinkedInDescStep'
import LinkedInAttributesStep from './LinkedInAttributesStep'
import useLinkedInAudience from '../../hooks/useLinkedInAudience'

export const LinkedInAudienceContext = React.createContext()

const LinkedAudienceModal = ({ onClose, adAccounts, configId, liAudiences, isEditingAudience, selectedAudience, selectedIndex, isEditMode }) => {
    // * General State
    const [step, setStep] = useState(1)

    // * Hooks
   const linkedInAudience = useLinkedInAudience({
        initialData: liAudiences,
        liConfigId: configId,
        onClose,
        isEditingAudience,
        selectedAudience,
        adAccounts,
        selectedIndex
    })
   const { audienceName, adAccount, audienceDesc, onSubmit, btnLoader, onEdit } = linkedInAudience

   // * Create Context Values
    const audienceContextValues = {
        ...linkedInAudience,
        adAccounts,
    }

    const nextStep = () => setStep(step + 1)
    const prevStep = () => setStep(step - 1)

    // * Steps
    const steps = () => {
        switch (step) {
            case 1: return <LinkedInDescStep />
            case 2: return <LinkedInAttributesStep />
            default:
                console.log(`No step selected`);
        }
    }

    const decideButtons = () => {
        switch (step) {
            case 1: return [
                <OutlineBtn onClick={onClose}> Cancel </OutlineBtn>,
                <BtnWithLoader type='blackBtn' btnText={isEditMode ? 'Save & Exit' : 'Add & Exit'} onClick={isEditingAudience ? onEdit : onSubmit} loader={btnLoader} isDisabled={!audienceName  || !adAccount || !audienceDesc} />,
                <BlackBtn onClick={nextStep} isDisabled={!audienceName  || !adAccount || !audienceDesc}> Next </BlackBtn>
            ]
            case 2: return [
                <OutlineBtn onClick={prevStep}> Back </OutlineBtn>,
                <BtnWithLoader type='blackBtn' btnText={isEditMode ? 'Save' : 'Done'} onClick={isEditingAudience ? onEdit : onSubmit} loader={btnLoader} />
            ]
            default: return []
        }
    }

    return (
        <BuildModal
            header={isEditMode ? 'Edit LinkedIn Target Audience' : 'Create LinkedIn Target Audience'}
            height={'780px'}
            minWidth={'736px'}
            onClose={onClose}
            buttons={decideButtons()}
        >
            <LinkedInAudienceContext.Provider value={audienceContextValues}>
                {steps()}
            </LinkedInAudienceContext.Provider>
        </BuildModal>
    )
}

export default LinkedAudienceModal