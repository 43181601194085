import React from 'react';
import Modal from '../../../../components/Modal/Modal';

// Styles
import { BasicModalText } from '../../../../components/Modal/styles';
import { BlackBtnLg, OutlineBtn, CenterButtons } from '../../../../ui/button';
import { CenteredWarning } from '../../../../ui/icons';

const NoRunnableCampaignModal = ({onClose, hardClose, channel}) => {
    const isFacebook = channel === 'facebook';
    const facebookText = <> and/or <span className='bold'>missing catalog</span> on the ad account</>
    return (
        <Modal
            header='Channel Setup Error'
            onClose={onClose}
            style={{maxWidth: '580px'}}
        >
        <CenteredWarning />
        <BasicModalText>
            Based on the <span className='bold'>selections for channel Setup</span>{isFacebook && facebookText}, some or all campaigns cannot succesfully run.  Please reselect the options for channel setup or reconfigure ad groups in LOB to run campaigns.
        </BasicModalText>

        {CenterButtons(
            <>
                <OutlineBtn onClick={hardClose}> Cancel </OutlineBtn>
                <BlackBtnLg onClick={onClose}> Go Back to Channel Setup </BlackBtnLg>
            </>
        )}
        </Modal>
    )
}

export default NoRunnableCampaignModal;