import React from 'react'
import { generateStatusText, getToolTipHeader } from './status.utils';
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css';
import { StyledStatusTag } from './styles'

const StatusTag = ({ data, showTooltips }) => {
    const { approval } = data

    const generateReason = () => {
        if (approval?.status === "DISAPPROVED") {
            return `<div style='margin-top: 8px;line-height: 16px;'>
                        <strong style='font-family: GothamMedium'>Reason: </strong>
                        <span>${approval?.reason || ''}<span>
                    </div>`
        }

        return ''
    }

    return (
        <StyledStatusTag status={approval?.status}>
            <p>{approval?.status || 'DRAFT'}</p>
            {
                (approval?.status !== 'DRAFT' && showTooltips) &&
                <>
                    <IoMdInformationCircleOutline
                        size={18}
                        data-tooltip-id='my-tooltip'
                        data-tooltip-variant='light'
                        data-tooltip-html={`<div>
                                                <h3 style="font-size: 14px">Media Plan ${getToolTipHeader(approval?.status)}</h3>
                                                <br/>
                                                <p style="line-height: 16px;">${generateStatusText(data)}</p>
                                                ${generateReason()}
                                            </div>`
                                        }
                    />
                    <Tooltip
                        id='my-tooltip'
                        className='tool-tip'
                        classNameArrow='arrow'
                        border={true}
                        effect='solid'
                    />
                </>
            }
        </StyledStatusTag>
    )
}

export default StatusTag