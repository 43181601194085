import React, { useState, useEffect, useContext } from 'react';
import AdGroupsModal from '../modal/GoogleSearchAdGroups/AdGroupsModal';
import Loader from '../../../../ui/Loader';
import EditContext from '../Context/EditContext';
import NamingConfiguration from '../NamingConfiguration';

// Redux
import { selectGoConfig, updateNetworkConfig } from '../../../../actions/lobActions';

//Helpers
import { shouldUpdateString } from '../../../../utils/payload';

// Styles
import { SubHeader } from '../../../../ui/headers';
import { EditSaveBtn } from '../../../../ui/button';
import { FcGoogle } from 'react-icons/fc';
import { FieldColContainer } from '../../styles';
import { FieldContainer, StyledFieldsWrapper } from '../../../../ui/containers';
import { Input } from '../../../../ui/input/input';
import { toast } from 'react-toastify';
import { createDeepCopy } from '../../../../utils/objects';
import GoogleAdAccountModal from '../modal/SocialAccounts/GoogleAdAccountModal';
import GoogleNetworkTable from '../tables/GoogleNetworkTable';
import { useDispatch, useSelector } from 'react-redux';
import GoogleAdGroupTable from '../tables/GoogleAdGroupTable';
import BiddingFocusModal from '../modal/BiddingFocusModal';

const GoogleNetworkConfig = () => {
    const _D = useDispatch();
    const currNetworkConfig = useSelector(s => s.lob.currentNetworkConfig)
    const currLob = useSelector(s => s.lob.currentLineOfBusiness)

    const [goConfigId, setGoConfigId] = useState();

    // * Google Config Fields
    const [searchSite, setSearchSite] = useState('');
    const [adGroupNamingConvention, setAdGroupNamingConvention] = useState('');
    const [campaignNamingConvention, setCampaignNamingConvention] = useState();
    const [adAccounts, setAdAccounts] = useState();

    // * Editing Google Ad Accounts State
    const [selectedAdAccount, setSelectedAdAccount] = useState();

    // * Editing Google Ad Group State
    const [editedAdGroupData, setEditedAdGroupData] = useState(null);
    const [isEditingAdGroup, setIsEditingAdGroup] = useState(false);
    const [editedIndex, setEditedIndex] = useState(null);

    // Modal
    const [isAdAccountsModalOpen, setIsAdAccountsModalOpen] = useState(false);
    const [isAdGroupsModalOpen, setIsAdGroupsModalOpen] = useState(false);
    const [isEditingAdAccount, setIsEditingAdAccount] = useState(false);


    const [loader, setLoader] = useState(true);

    const { isEdit, setIsEdit } = useContext(EditContext);

    useEffect(() => {
        const goKey = currLob?.networkConfigs.find(network => network.keyParam === 'goconfig');
        setGoConfigId(goKey?.websafe);
    }, [])

    useEffect(() => {
        if (!goConfigId) {
            setLoader(true);
            return;
        }

        _D(selectGoConfig({
            goConfigId,
            callback: () => setLoader(false)
        }))

    }, [goConfigId]);

    useEffect(() => {
        setAdAccounts(currNetworkConfig?.adAccounts);
        setSearchSite(currNetworkConfig?.searchSite);
        setAdGroupNamingConvention(currNetworkConfig?.adGroupNamingConvention);
        setCampaignNamingConvention(currNetworkConfig?.campaignNamingConvention);
    }, [goConfigId, currNetworkConfig])

    const handleOnCloseAdGroupModal = () => {
        if(isEditingAdGroup) {
            setIsEditingAdGroup(false);
            setEditedAdGroupData(null)
        }
        setIsAdGroupsModalOpen(false);
    }

    const handleEditAdGroup = (data) => {
        let index = currNetworkConfig?.audienceTargets.findIndex(obj => obj === data)
        setEditedIndex(index);

        const cloneData = createDeepCopy(data)
        setEditedAdGroupData(cloneData)

        setIsEditingAdGroup(true)
        setIsAdGroupsModalOpen(true)
    }

    // * Editing Ad Accounts
    const handleEditAdAccount = (adAccount) => {
        setIsEditingAdAccount(true)
        setSelectedAdAccount(adAccount)
    }

    const handleResetEdit = () => {
        setIsEditingAdAccount(false)
        setSelectedAdAccount(null)
    }

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};

            if (shouldUpdateString(campaignNamingConvention, currNetworkConfig?.campaignNamingConvention)) updateObj.campaignNamingConvention = campaignNamingConvention;
            if (shouldUpdateString(searchSite, currNetworkConfig?.searchSite)) updateObj.searchSite = searchSite;
            if (adGroupNamingConvention !== currNetworkConfig?.adGroupNamingConvention) updateObj.adGroupNamingConvention = adGroupNamingConvention;

            if (Object.keys(updateObj).length > 0) {
                _D(updateNetworkConfig({
                    obj: updateObj,
                    socialConfig: `goconfig=${goConfigId}`,
                    callback: () => {
                        toast.success('Updating Google Network Config');
                    }
                }))
            }
        }

        setIsEdit(!isEdit);
    }

    if (loader) return <Loader size={55}/>;

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                <span>
                    <FcGoogle size={30}/>
                    Configurations for Google Search Campaigns
                </span>
                {EditSaveBtn(isEdit, handleEdit)}
            </SubHeader>

            <FieldColContainer>
                <FieldContainer>
                    <h3> <FcGoogle /> Google Search Business Website </h3>
                    {
                        isEdit ?
                            <Input value={searchSite} placeholder={'Enter Business Website'} onChange={e => setSearchSite(e.target.value)} /> :
                            <p> {searchSite || 'N/A'} </p>
                    }
                </FieldContainer>
            </FieldColContainer>

            <FieldColContainer>
                <GoogleNetworkTable
                    isEdit={isEdit}
                    tableData={adAccounts?.sort((a,b) => a?.displayName.localeCompare(b?.displayName))}
                    updateTableData={setAdAccounts}
                    handleOnCreate={() => setIsAdAccountsModalOpen(true)}
                    handleOnEdit={handleEditAdAccount}
                    goConfigId={goConfigId}
                />
                <GoogleAdGroupTable
                    tableData={currNetworkConfig?.audienceTargets}
                    isEdit={isEdit}
                    handleOnCreate={() => setIsAdGroupsModalOpen(true)}
                    handleOnEdit={handleEditAdGroup}
                    goConfigId={goConfigId}
                />
            </FieldColContainer>

            <NamingConfiguration
                network='Google'
                isEdit={isEdit}
                namingConvention={campaignNamingConvention}
                setNamingConvention={setCampaignNamingConvention}
                groupNamingConvention={adGroupNamingConvention}
                setGroupNamingConvention={setAdGroupNamingConvention}
            />
            {
                isAdAccountsModalOpen &&
                <GoogleAdAccountModal
                    onClose={() => setIsAdAccountsModalOpen(false)}
                    configId={goConfigId}
                    data={adAccounts}
                    setData={setAdAccounts}
                />
            }
            {
                isAdGroupsModalOpen &&
                <AdGroupsModal
                    onClose={handleOnCloseAdGroupModal}
                    audienceTargets={currNetworkConfig?.audienceTargets}
                    adAccounts={adAccounts}
                    audienceDescriptions={currLob?.audienceDescriptions}
                    isEditingAdGroup={isEditingAdGroup}
                    editedAdGroupData={editedAdGroupData}
                    editedIndex={editedIndex}
                    goConfigId={goConfigId}
                />
            }
            {
                isEditingAdAccount &&
                    <BiddingFocusModal
                        selectedAccount={selectedAdAccount}
                        goConfigId={goConfigId}
                        onClose={handleResetEdit}
                        adAccounts={adAccounts}
                        setAdAccounts={setAdAccounts}
                    />
            }
        </StyledFieldsWrapper>
    )
}

export default GoogleNetworkConfig;