import React from 'react';
import Modal from './Modal';
import { OutlineBtn, BtnWithLoader, CenterButtons } from '../../ui/button';
import { ModalText, DeleteModalContainer } from './styles';

const DeleteModal = ({ header, modalText, deleteBtnText, setIsDeleteModalOpen, handleDelete, network, loading, style }) => {

	return (
		<Modal withIcon={network} network={network} header={header} onClose={() => setIsDeleteModalOpen(false)} style={style}>
			<DeleteModalContainer>
				<ModalText> {modalText} </ModalText>
				{CenterButtons(<>
					<BtnWithLoader btnText={deleteBtnText} loader={loading} onClick={handleDelete} type="redBtn" color='#FFFFFF' />
					<OutlineBtn onClick={() => setIsDeleteModalOpen(false)}> Cancel </OutlineBtn>
				</>)}
			</DeleteModalContainer>
		</Modal>
	);
};

export default DeleteModal;