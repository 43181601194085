export const CATEGORY_TYPES = {
    OWNER: 'owner',
    LABEL: 'label',
    GOAL: 'goal',
    PRIORITY: 'priority',
    LOB: 'lob',
    STATUS: 'status',
    NETWORK: 'network'
}

export const CATEGORY_TAB_NAME = {
    OWNER: 'OWNER',
    LABEL: 'LABEL',
    GOAL: 'GOAL',
    PRIORITY: 'PRIORITY',
    LOB: 'LOB',
    STATUS: 'STATUS',
    NETWORK: 'NETWORK'
}
