import { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateAdGroup, deleteAdGroup, linkedInObjectiveMap } from '../../../../../actions/mediaActions';
import { toast } from 'react-toastify';

const useLinkedInCampaign = ({}) => {
    const campaign = useSelector(state => state.media.currentAdGroup);
    const adGroups = useSelector(state => state.media.currentCampaign.adGroups);
    const currency = useSelector(state => state.media.currentCampaign?.adAccount?.currency);
    const accountId = useSelector(state => state.media.currentCampaign.adAccount.accountId);
    const socialLoginId = useSelector(state => state.media.currentCampaign.adAccount.socialLoginId);

    const [isEdit, setIsEdit] = useState(false);
    const [campaignObj, setCampaignObj] = useState(campaign);
    const [isDeleteLoader, setIsDeleteLoader] = useState(false);
    const [objectiveMap, setObjectiveMap] = useState({});
    const [showPlacements, setShowPlacements] = useState(false);
    const { objective, optimization} = campaignObj || {};

    const dispatch = useDispatch();

    useEffect(() => {
        setCampaignObj(campaign);
        linkedInObjectiveMap({
            callback: (res) => {
                setObjectiveMap(res);
                setShowPlacements(res?.[objective]?.optimizations?.[optimization]?.placements);
            }
        })
    }, [campaign?.websafe])

    const handleUpdate = ({key, value}) => {
        const updatedCampaignObj = {
            ...campaignObj,
            [key]: value
        }

        setCampaignObj(updatedCampaignObj);
    }

    const handleDelete = () => {
        setIsDeleteLoader(true);
        dispatch(deleteAdGroup({
            mediaPlanAdGroup: campaign?.websafe,
            callback: () => {
                setIsDeleteLoader(false);
                toast.success('Successfully Deleted');
            },
            errorCallback: () => {
                setIsDeleteLoader(false);
                toast.error('Something went wrong');
            }
        }))
    }

    const handleEdit = () => {
        if (isEdit) {
            const updateObj = {};
            if (!campaignObj?.languageTarget?.name) return toast.error('Language is required!')

            for (const key in campaignObj) {
                if (campaign[key] !== campaignObj[key]) {
                    updateObj[key] = campaignObj[key];
                }
            }

            dispatch(updateAdGroup({
                mediaPlanAdGroup: campaign.websafe,
                obj: updateObj,
                callback: () => toast.success('Successfully Updated Campaign Setup'),
                errorCallback: () => toast.error('Something went wrong')
            }))
        }

        setIsEdit(prev => !prev);
    }

    return {
        isEdit, handleEdit, campaignObj, objectiveMap, handleUpdate, currency, accountId, socialLoginId,
        isDeleteLoader, handleDelete, showPlacements
    }
}

export default useLinkedInCampaign;