import React from 'react'
import { ModalOverlay } from '../styles'
import { LOB_NAV } from '../../../constants/lob';

import { ModalContainer, ModalContent, ModalHeader, ModalScrollableBody, ButtonWrapper, ModalHeaderText, ModalSeparator, ModalFooter } from './styles'

// * Modal Icons
import { FacebookI, GoogleI } from '../../../ui/icons';
import { CgClose } from 'react-icons/cg'


// * DOCS
/*
    BuildModal - This component is designed to keep specific multi-step uniform in size
    children - actual content in the body of the modal
    header - What the modal header will say
    useIcon - Boolean value, to know if a image should be included in the header
    network - Will decide which icon to use (relies on useIcon)
    onClose - Function to run when "X" is clicked
    buttons - dynamic array of buttons to handle steps through specific build flows
    height - height of entire modal (IMPORTANT - as if we want to avoid jumpy modals they would all need to have a consistent height)
    minwidth - minimum width of entire modal
    maxwidth - maximum width of entire modal
*/

const BuildModal = ({ children, header, useIcon, network, onClose, buttons, width, height, minWidth, maxWidth }) => {
    return (
        <ModalOverlay>
            <ModalContainer height={height} width={width} minWidth={minWidth} maxWidth={maxWidth}>
                <ModalContent>
                    <ModalHeader>
                        <div className='header__left'>
                            { useIcon &&
                                <div className='header__image__wrapper'>
                                    {
                                        network?.toLowerCase() === LOB_NAV.NETWORK_CONFIG.FACEBOOK ? <FacebookI size='30px'/> : <GoogleI size='30px'/>
                                    }
                                </div>
                            }
                            <div className='header__title__wrapper'>
                                <ModalHeaderText>{header}</ModalHeaderText>
                            </div>
                        </div>
                        <div className='header__right' onClick={onClose}>
                            <CgClose size='30px' />
                        </div>
                    </ModalHeader>
                    <ModalSeparator />
                    <ModalScrollableBody>
                        {children}
                    </ModalScrollableBody>
                    <ModalFooter>
                        <ButtonWrapper>
                            {
                                buttons.map((button, index) => <div key={index}>{button}</div>)
                            }
                        </ButtonWrapper>
                    </ModalFooter>
                </ModalContent>
            </ModalContainer>
        </ModalOverlay>
    )
}

export default BuildModal