import React, { useState, useMemo, useContext } from 'react';
import Search from '../../../../../ui/search';
import WideCard from '../../../../../ui/card/WideCard';
import WarningModal from '../../modal/WarningModal';

// Styles
import { TableModalContainer } from '../../../../../ui/table/styles';
import { ModalTextSecondary } from '../../../../../components/Modal/styles';

// * Functions
import useInitilizeOptions from '../../../../../features/AdvancedFilter/hooks/useInitializeOptions';
import { CATEGORY_TAB_NAME, CATEGORY_TYPES } from '../../../../../features/AdvancedFilter/constants';
import { doesTemplateMatch } from '../../../../../features/AdvancedFilter/utils/filters.utils';
import { ChannelSetUpContext } from './ChannelSetupModal';

const SelectNetworkTemplates = () => {
    const { channel, selectedTemplate, setSelectedTemplate, goal, priority, goalOptions, priorityOptions, templateList, setShowWarningModal, showWarningModal, handleSave } = useContext(ChannelSetUpContext)
    const { initOwnerNameList, initTemplateLabelList, initGoalList, initPriorityList } = useInitilizeOptions();
    const [searchTerm, setSearchTerm] = useState('')

    const handleSearchFilter = (filteredOptions) => {
        return filteredOptions.filter(template => {
            const name = template.name.toLowerCase();
            const hasLabel = template.labels.find(label => label.toLowerCase().includes(searchTerm));
            return name.includes(searchTerm) || hasLabel;
        })
    }

    // * Default Filter
    const DEFAULT_FILTERS = {
        [CATEGORY_TYPES.OWNER]: [],
        [CATEGORY_TYPES.LABEL]: [],
        [CATEGORY_TYPES.GOAL]: [goal],
        [CATEGORY_TYPES.PRIORITY]: [priority]
    }

    // * Filter Categories
    const categories = [
        {
            categoryName: CATEGORY_TAB_NAME.OWNER,
            options: initOwnerNameList(templateList)
        },
        {
            categoryName: CATEGORY_TAB_NAME.LABEL,
            options: initTemplateLabelList()
        },
        {
            categoryName: CATEGORY_TAB_NAME.GOAL,
            options: initGoalList(goalOptions)
        },
        {
            categoryName: CATEGORY_TAB_NAME.PRIORITY,
            options: initPriorityList(priorityOptions)
        }
    ]

    // * Active Filter State
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

    // * Filter Config (obj b/c search component will crash if we try to destructure)
    const filterConfig = {
        selectedFilters: filters,
        setSelectedFilters: setFilters,
        categories
    }

    // * Template Options (will update as filters change)
    const filteredTemplateOptions = useMemo(() => {
        let doesMatch = true;
        return templateList.filter(template => {
            doesMatch = doesTemplateMatch(template, filters)
            return doesMatch
        })
    }, [filters, templateList])


    return (
        <>
        <TableModalContainer>
            <ModalTextSecondary> Select a network template of Goal: <span className='importantText'>{goal}</span> and Priority: <span className='importantText'>{priority}</span>. </ModalTextSecondary>
            <ModalTextSecondary> To change the goal and/or priority, click on the filter. </ModalTextSecondary>
                <div className='search-filter-wrapper'>
                    <Search
                        text='Search for a network template'
                        handleFilter={(e) => setSearchTerm(e.target.value.toLowerCase())}
                        filterConfig={filterConfig}
                        useFilter
                    />
                </div>
                <h3 className='network-header'>Network Templates</h3>
                <div className='header-seperator' />
                <div className='network-cards-container'>
                    {
                        handleSearchFilter(filteredTemplateOptions).filter(template => template.network === channel).map(temp =>
                            <WideCard
                                data={temp}
                                key={temp.id}
                                setSelectedCard={setSelectedTemplate}
                                isSelected={selectedTemplate?.id === temp.id}
                            />)
                    }
                </div>
        </TableModalContainer>
        { showWarningModal && <WarningModal onClose={() => setShowWarningModal(false)} goal={goal} priority={priority} handleSave={handleSave}/>}
        </>
    )
}

export default SelectNetworkTemplates;