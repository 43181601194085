import React from 'react'
import PercentInput from '../../../../../ui/input/fields/PercentInput'
import CurrencyInput from '../../../../../ui/input/fields/CurrencyInput'

// Styles
import gIcon from '../../../../../assets/images/socialMediaIcons/GoogleIcon.png'
import { CpaAccountItem, TargetCpaContainer } from '../styles'
import { ModalTextSecondary } from '../../../../../components/Modal/styles'

const BiddingFocusStep = ({selectedAccounts}) => {
    const handleTargetCpa = (e, account) => {
        account.targetCpaMicros = e.target.value
    }

    const handleTargetRoas = (e, account) => {
        account.targetRoas = e.target.value
    }

    const handleTargetMaxCpc = (e, account) => {
        account.cpcBidLimit = e.target.value
    }

    const handleTargetImpTarget = (e, account) => {
        account.targetPercentImpressionShare = e.target.value
    }


    const accountsList = selectedAccounts.map(account => {
        return (
            <CpaAccountItem key={account.accountId}>
                <div className='ad-account-item'>
                    <h3 style={{ marginBottom: '10px' }}>Ad Account: {account.displayName}</h3>
                    <p style={{ marginBottom: '20px' }}>ID: {account.accountId}</p>
                    <div className='field-container'>
                        <div style={{marginTop: '0'}} className='field-item'>
                            <div className='field-header'>
                                <img src={gIcon} alt='google icon' />
                                <p>Target CPA</p>
                            </div>
                            <div className='field-content'>
                                <CurrencyInput
                                    onChange={(e) => handleTargetCpa(e, account)}
                                    currency={account?.currency}
                                />
                            </div>
                        </div>
                        <div className='field-item'>
                            <div className='field-header'>
                                <img src={gIcon} alt='google icon' />
                                <p>Target ROAS</p>
                            </div>
                            <div className='field-content'>
                                <PercentInput onChange={(e) => handleTargetRoas(e, account)} min='0' max='100'/>
                            </div>
                        </div>
                        <div className='field-item'>
                            <div className='field-header'>
                                <img src={gIcon} alt='google icon' />
                                <p>Maximum CPC Bid Limit</p>
                            </div>
                            <div className='field-content'>
                                <CurrencyInput
                                    onChange={(e) => handleTargetMaxCpc(e, account)}
                                    currency={account?.currency}
                                />
                            </div>
                        </div>
                        <div className='field-item'>
                            <div className='field-header'>
                                <img src={gIcon} alt='google icon' />
                                <p>Percent Impression Share To Target</p>
                            </div>
                            <div className='field-content'>
                                <PercentInput onChange={(e) => handleTargetImpTarget(e, account)} min='0' max='100'/>
                            </div>
                        </div>
                    </div>
                </div>
            </CpaAccountItem>
        )
    })

    return (
        <TargetCpaContainer>
            <ModalTextSecondary style={{ marginTop: '20px' }}> These bidding focus specific values will be used as a reference when the relevant bidding focus is used on a search campaign. </ModalTextSecondary>

            <div className='ad-account-list-container'>
                {accountsList}
            </div>
        </TargetCpaContainer>
    )
}

export default BiddingFocusStep