import React, { useEffect, useLayoutEffect, useState } from "react";
import Search from "../../ui/search";

// Utils
import { serveImage } from "../../pages/systemConfig/components/labelManagement/label.utils";

// Styles
import { OverviewSearchContainer, StyleHiddenDropdown, StyleMainBtnWrapper} from "./styles";
import { BlackIconBtn } from "../../ui/button";
import { FaPlus } from "react-icons/fa";
import { FacebookI, GoogleI, LinkedinI } from "../../ui/icons";

// Library
import gsap from "gsap";
import { NETWORKS } from "../../constants/networks";
gsap.config({ nullTargetWarn: false });


const TopOverView = ({ handleClick, handleFilter, searchText, buttonText, withDropdown, dummyNetworks, useFilter, filterConfig }) => {
  const [dropdown, setDropDown] = useState(null);

  useLayoutEffect(() => {
    setDropDown(gsap.timeline().fromTo(".dr-cl", { autoAlpha: 0, opacity: 0, y: "-12px" }, { autoAlpha: 1, opacity: 1, y: 0,  duration: 0.3, ease: 'back'  }).reverse());
  }, []);

  useEffect(() => {
    if (!withDropdown && !dropdown) return;
    let onClick = (event) => (event.target.id === "m-target" ? null : dropdown.reverse());
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  const dropdownContoller = () => {
    dropdown.reversed() ? dropdown.play() : dropdown.reverse();
  };

  const renderDummyNetworks = () => {
    if (dummyNetworks?.length < 1) return;
    return dummyNetworks?.map(network => {
      return (
        <div className="tm-item tm-item-1" onClick={handleClick.bind(null, network?.name)} key={network.websafe}>
          <img src={serveImage(network?.logo)} alt="logo" />
          <p>{network.name}</p>
        </div>
      )
    })
  }

  return (
    <OverviewSearchContainer>
      <div className="search-filter-wrapper">
        <Search text={searchText} handleFilter={handleFilter} useFilter={useFilter} filterConfig={filterConfig}/>
      </div>
      <StyleMainBtnWrapper>
        <BlackIconBtn onClick={withDropdown ? dropdownContoller : handleClick} id="m-target">
          <FaPlus id="m-target" />
          {buttonText}
        </BlackIconBtn>
        {withDropdown && (
          <StyleHiddenDropdown className="dr-cl">
            <div className="tm-item tm-item-1" onClick={() => handleClick(NETWORKS.FACEBOOK)}>
                <FacebookI size="22px"  />
                <p>Facebook</p>
            </div>
            <div className="tm-item tm-item-1" onClick={() => handleClick(NETWORKS.GOOGLE)}>
                <GoogleI size="22px" />
              <p>Google Search</p>
            </div>
            <div className="tm-item tm-item-1" onClick={() => handleClick(NETWORKS.LINKEDIN)}>
              <LinkedinI size="22px" />
              <p>Linkedin</p>
            </div>
            {renderDummyNetworks()}
          </StyleHiddenDropdown>
        )}
      </StyleMainBtnWrapper>
    </OverviewSearchContainer>
  );
};

export default TopOverView;
