import React from 'react'
import { Input } from '../../../../../ui/input/input'
import { StyleDummyNetworkUnit } from './styles'

const DummyCampaignUnitLink = ({campaign, handleOnInputChange, url}) => {

    return (
        <StyleDummyNetworkUnit>
            <h3>Campaign Name:</h3>
            <p>{campaign?.name}</p>

            <div className='input-seperator'>
                <p className='input-seperator-title'>Campaign URL</p>
                <Input defaultValue={url} onChange={(e) => handleOnInputChange(campaign?.websafe, e.target.value)}/>
            </div>
        </StyleDummyNetworkUnit>
    )

}


export default DummyCampaignUnitLink