import React, { useState, useEffect } from 'react';

import Overview from './components/Overview';
import EditMediaPlans from './components/EditMediaPlans';
import Loader from '../../ui/Loader';

// Redux
import { useDispatch } from 'react-redux';
import { getMediaPlanList } from '../../actions/mediaActions';

// Helpers


// Styles
import { MainHeader } from '../../ui/headers';
import { HiClipboardList } from 'react-icons/hi';
import { MediaPlansContainer } from './styles';
import { toast } from 'react-toastify';
import { getLobList } from '../../actions/lobActions';

const MediaPlans = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    const getMediaPlanListCallback = (err) => {
        if (err) toast.error('Somthing went wrong!');
        setLoader(false)
    }

    useEffect(() => {
        setLoader(true)
        dispatch(getMediaPlanList(getMediaPlanListCallback));
        // * The filter's lob category will be empty unless until this endpoint is called
        dispatch(getLobList((err) => {
            if (err) toast.error('Unable to fetch LoB data')
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MediaPlansContainer>
            <MainHeader>
                <HiClipboardList />
                <h2> Media Plans </h2>
            </MainHeader>

            {
              loader ? <Loader size='55' fullViewHeight /> :  isEdit ?
                    <EditMediaPlans setIsEdit={setIsEdit} /> :
                    <Overview setIsEdit={setIsEdit} setLoader={setLoader} />
            }
        </MediaPlansContainer>
    )
}

export default MediaPlans;