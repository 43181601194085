import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { shouldUpdateString } from '../../../utils/payload';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isDuplicateName } from '../../../utils';
import { isObjArraysEqual } from '../../../utils/arrays';

// Redux
import { getAdvertiser } from '../../../actions/systemConfigActions';

// Styles
import { Input, Textarea } from '../../../ui/input/input';
import { FieldContainer, StyledFieldsWrapper, OneThirdGridColContainer } from '../../../ui/containers';
import { SubHeader } from '../../../ui/headers';
import { EditSaveBtn } from '../../../ui/button';
import { selectTheme } from '../../../ui/themes';

const TempDetails = ({ handleUpdate, setIsEditingDetails, isEditingDetails }) => {
    const [name, setName] = useState('');
    const [goal, setGoal] = useState('');
    const [priority, setPriority] = useState('');
    const [goalOptions, setGoalOptions] = useState([]);
    const [priorityOptions, setPriorityOptions] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState('');
    const [description, setDescription] = useState('');
    const [advertiser, setAdvertiser] = useState({});


    const template = useSelector(state => state.template.currentNetworkTemplate);
    const networkTemplateList = useSelector(state => state.template.templateList);
    const org = useSelector(state => state.org.organization);

    useEffect(() => {
        setGoalOptions(org?.goals.map(goal => { return {label: goal, value: goal}}))
        setPriorityOptions(org?.priorities.map(priority => { return {label: priority, value: priority}}))
    }, [org])

    useEffect(() => {
        if (template?.name) setName(template?.name);
        if (template?.goal) setGoal(template?.goal);
        if (template?.priority) setPriority(template?.priority);
        if (template?.labels) setSelectedLabels(template?.labels);
        if (template?.description) setDescription(template?.description);
        if (template?.advertiser?.websafe) {
            getAdvertiser({
                websafe: template.advertiser.websafe,
                callback: (res) => {
                    setAdvertiser(res);
                }
            })
        }
    }, [template])

    const handleEdit = () => {
        if (isEditingDetails) {
            const updateObj = {}

            if (shouldUpdateString(name, template.name)) {
                if (isDuplicateName(name, networkTemplateList, template.websafe)) {
                    toast.error(`A template with the name ${name} already exists`);
                    return;
                }
                updateObj.name = name;
            }
            if (shouldUpdateString(goal, template.goal)) updateObj.goal = goal;
            if (shouldUpdateString(priority, template.priority)) updateObj.priority = priority;

            if (shouldUpdateString(description, template.description)) updateObj.description = description;
            if (selectedLabels && !isObjArraysEqual(selectedLabels, template.labels)) updateObj.labels = selectedLabels;

            if (Object.keys(updateObj).length > 0) handleUpdate(updateObj);
        }
        setIsEditingDetails(!isEditingDetails);
    }

    const renderLabels = () => {
        return template?.labels?.map(label => {
            return (
                {
                    label: label,
                    value: label
                }
            )
        })
    }
    const handleLabelChange = selectedOptions => {
        const labels = selectedOptions?.map(opt => opt.value) || [];
        setSelectedLabels(labels);
    }

    return (
        <StyledFieldsWrapper>
            <SubHeader>
                Network Details/Config
                <div>
                    {EditSaveBtn(isEditingDetails, handleEdit)}
                </div>
            </SubHeader>

            <OneThirdGridColContainer>
                <FieldContainer>
                    <h3> Template Name {<span className='asterisk'>*</span>} </h3>
                    {
                        isEditingDetails ?
                        <Input
                            placeholder='Enter Template Name'
                            value={name}
                            onChange={e => setName(e.target.value)}
                        /> :
                        <p> {name} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> Advertiser </h3>
                    {
                        isEditingDetails ?
                        <Input
                            disabled
                            value={advertiser?.name || 'All Advertisers'}
                        /> :
                        <p> {advertiser?.name || 'All Advertisers'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> Labels {<span className='asterisk'>*</span>} </h3>
                    {
                        isEditingDetails ?
                            <CreatableSelect
                                isClearable
                                isMulti
                                options={renderLabels()}
                                styles={selectTheme}
                                defaultValue={renderLabels()}
                                onChange={e => handleLabelChange(e)}
                            />
                            :
                            <p> {selectedLabels?.length ? selectedLabels.join(', ') : 'N/A'} </p>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <FieldContainer>
                    <h3> What is the goal of this template? {<span className='asterisk'>*</span>} </h3>
                    {
                        isEditingDetails ?
                        <Select
                            isClearable
                            options={goalOptions}
                            defaultValue={{label: template?.goal, value: template?.goal}}
                            styles={selectTheme}
                            onChange={e => setGoal(e.label)}
                        />
                        :
                        <p> {goal || 'N/A'} </p>
                    }
                </FieldContainer>

                <FieldContainer>
                    <h3> What is the priority of this template? {<span className='asterisk'>*</span>} </h3>
                    {
                        isEditingDetails ?
                        <Select
                            isClearable
                            options={priorityOptions}
                            defaultValue={{label: template?.priority, value: template?.priority}}
                            styles={selectTheme}
                            onChange={e => setPriority(e.label)}
                        />
                        :
                        <p> {priority || 'N/A'} </p>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>

            <OneThirdGridColContainer>
                <FieldContainer>
                    <h3> Description </h3>
                    {
                        isEditingDetails ?
                        <Textarea rows={5} placeholder='Enter description for Template' value={description} onChange={e => setDescription(e.target.value)}/> :
                        <p> {description || 'N/A'} </p>
                    }
                </FieldContainer>
            </OneThirdGridColContainer>
        </StyledFieldsWrapper>
    )
}

export default TempDetails;