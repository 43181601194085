import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal/Modal";
import { BtnContainer } from "../../../../components/Modal/styles";
import { BlackBtn, OutlineBtn } from "../../../../ui/button";
import { StyleGoogleSheets } from "../../styles";
import Loader from '../../../../ui/Loader'
import useGoogleSheets from "../../useGoogleSheets";

const GoogleSheetsModal = ({onClose, mediaPlan, setMediaPlan}) => {
    const {updateMediaPlans} = useGoogleSheets()
    const [loader, setLoader] = useState(false)

    const proceed = async () => {
        setLoader(true)
        try {
            await updateMediaPlans(mediaPlan, setMediaPlan)
        } catch (error) {
            toast.error("Something went wrong!")
        } finally {
            setLoader(false)
            onClose()
        }
    }

    const handleOpenWindow = () => {
        window.open(mediaPlan?.sheetsUrl)
        onClose()
    }

  return (
    <Modal onClose={onClose} header='Google Sheet Warning' >
      <StyleGoogleSheets>
        <div className="google-sheets_warmningText">You have already exported this media plan to Google Sheets. Exporting again will overwrite the existing data on the google sheet. Are you sure you want to proceed?</div>
        <BtnContainer>
            <OutlineBtn onClick={proceed} style={{width: "150px"}}>{loader ? <Loader /> :'Proceed Anyway'}</OutlineBtn>
            <BlackBtn   onClick={handleOpenWindow}   style={{width: "max-content"}}>View Existing in Google Sheet</BlackBtn>
        </BtnContainer>
      </StyleGoogleSheets>
    </Modal>
  );
};

export default GoogleSheetsModal;
